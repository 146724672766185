import React, { useState, useEffect, useContext } from "react";

import { Card } from "react-bootstrap";

import { useCollection, useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";



import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";
import { useNavigate } from "react-router";
import OnboardForm from "./OnboardForm";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { StudentOnboardingContext } from "onboarding/studentOnboarding/contexts/StudentOnboardingContext";
import { useUserId } from "hooks/user/useUserId";
import { CourseContext } from "contexts/CourseContext";

/**
 * In this component, students are simply chosing the *time* of their section
 */

export const StudentSectionTime = (props) => {
  document.title = "Section Time";
  const courseId = useCourseId();
  const userId= useUserId()
  const db = getFirestore(getApp());
  const {userData} = useContext(ProfileContext)
  const { sectionTimeDelta } = useContext(CourseContext);

  // we dont use useDocumentData because we fear it will change too often.
  const [chosenTimesMap, chosenTimesLoading, chosenTimesError] =useDocumentDataOnce(doc(db, 
    `course/${courseId}/sectionSignups/main`))
  const [minorsTimesMap, minorsLoading, minorsError] =useDocumentDataOnce(doc(db, 
    `course/${courseId}/sectionSignups/minor`))
  const [sectionSlotCounts, sectionSlotsLoading, sectionSlotsError] = useDocumentData(doc(db, 
    `/course/${courseId}/sectionSignups/mainTimes`))
  const [minorsSlotCouts, minorsSlotsLoading, minorsSlotsError] = useDocumentData(doc(db, 
    `/course/${courseId}/sectionSignups/minorTimes`))

  const {onboardingData, editOnboarding} = useContext(StudentOnboardingContext)

  // different fields for minors and adults
  const sectionTimeIndexAdult = onboardingData?.sectionTimeIndex ?? "none"
  const sectionTimeIndexMinor = onboardingData?.sectionTimeIndexMinor ?? "none"


  if(chosenTimesLoading || sectionSlotsLoading || minorsLoading || minorsSlotsLoading) {
    return <></>
  }
  if(chosenTimesError || sectionSlotsError || minorsError || minorsSlotsError) {
    return <div>There was an error loading section availibility, please refresh</div>
  }

  const isMinor = ('isMinor' in userData) ? userData.isMinor : false


  const sectionTimeIndex = isMinor ? sectionTimeIndexMinor : sectionTimeIndexAdult
  const timesMap = isMinor ? minorsTimesMap : chosenTimesMap
  const slotCounts = isMinor ? minorsSlotCouts : sectionSlotCounts
  console.log('slotCounts',slotCounts)
  console.log('timesMap',timesMap)
  const availableTimes = computeAvailableTimes(timesMap, slotCounts, sectionTimeIndex, sectionTimeDelta)

  const handleSectionChange =  async (e) => {
    const sectionStr = e.target.value
    const isNone = sectionStr === "none";
    const newSection = isNone ? "none" : parseInt(e.target.value);
    if(isMinor){
      editOnboarding({sectionTimeIndexMinor:newSection})
    } else {
      editOnboarding({sectionTimeIndex:newSection})
    }
    
  };

  return (
    <div>
      <div
        style={{
          width: "720px",
          maxWidth: "90vw",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Card style={{ margin: "auto", padding: "20px" }}>
          <h1>Section Time {isMinor && "HS"}</h1>
          <div>
            <OnboardForm
              availableTimes={availableTimes}
              sectionTimeIndex={sectionTimeIndex}
              handleSectionChange={handleSectionChange}
              onSubmit={()=>{}}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

function computeAvailableTimes(chosenTimesMap, sectionSlotCounts, currTimeIndex, sectionTimeDelta) {
  const usedTimes = countUsedTimes(chosenTimesMap)
  console.log("usedTimes", usedTimes)
  const availableTimes = []
  // I want to be sure they come in order
  for(let i = 0; i < sectionTimeDelta; i++) {
    const numStudents = usedTimes[i] ?? 0
    const numSlots = sectionSlotCounts[i] ?? 0
    
    let isValidTime = numStudents < numSlots || i === currTimeIndex

    if(isValidTime){
      availableTimes.push(i)
    }
  }
  return availableTimes
}

function countUsedTimes(chosenTimesMap) {
  const counts = {}
  for(let studentId in chosenTimesMap) {
    const timeIndex = chosenTimesMap[studentId]
    if(timeIndex === "none") continue
    if(!counts[timeIndex]) counts[timeIndex] = 0
    counts[timeIndex] += 1
  }
  return counts
}
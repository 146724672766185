import { deepCopy } from "@firebase/util";
import { useEffect, useRef, useState, useContext } from "react"
import { useComponentSize } from "react-use-size";
import { getCornerSize, isValidWorldState, makeRowColKey, xyToRowCol, getValidKarelIdx } from "./util";
import { Agent } from "./worldComponents/Agent";
import { Beepers } from "./worldComponents/Beepers";
import { EditableWalls } from "./worldComponents/EditableWalls";
import { GridBackground } from "./worldComponents/GridBackgroud";
import { Paint } from "./worldComponents/Paint";
import { IDEContext } from "ide/contexts/IDEContext";

export const WorldEditor = ({worldState,setWorldState}) => {

    const [editType, setEditType] = useState('moveKarel')

    if(!worldState){
        return <></>
    }

    function setState(key, value) {
        setWorldState((oldWorldState) => {
            const newWorldState = {...oldWorldState};
            newWorldState[key] = value
            newWorldState["karelCol"] = getValidKarelIdx(newWorldState["nCols"] , newWorldState["karelCol"] )
            newWorldState["karelRow"] = getValidKarelIdx(newWorldState["nRows"] , newWorldState["karelRow"] )



            if(isValidWorldState(newWorldState)){
                return newWorldState
            } else {
                return oldWorldState
            }
        })

    }

    function rowColClick(row, col){
        if(editType == 'moveKarel') {
            const change = row != worldState.karelRow || col != worldState.karelCol
            if(change){
                setWorldState((oldWorldState) => {
                    const newWorldState = {...oldWorldState};
                    newWorldState['karelRow'] = row
                    newWorldState['karelCol'] = col
                    if(isValidWorldState(newWorldState)){
                        return newWorldState
                    } else {
                        return oldWorldState
                    }
                })
            } else {
                setState('karelDir', rotateLeft(worldState.karelDir))
            }
        }
        if(editType == 'addBeeper') {
            editBeepers(setWorldState, row, col, +1)
        }
        if(editType == 'removeBeeper') {
            editBeepers(setWorldState, row, col, -1)
        }
    }

    return <div className="d-flex flex-column">
        <EditableCanvas
            rowColClick={rowColClick}
            worldState={worldState}
            setWorldState={setWorldState}
        />
        <EditButtons
            editType={editType}
            setEditType={setEditType}
            worldState={worldState}
            setState={setState}
        />
    </div>
}

export const EditButtons = ({editType, setEditType,worldState,setState}) => {
    return <div className="d-flex flex-row align-items-center" >
        <div className="mr-1 d-flex align-items-center">
        <IntInput label="rows" key="rows" value={worldState.nRows} setValue={(e) => {
          console.log(e)
          setState('nRows', e)}}/>  x
        <IntInput label="cols" key="cols" value={worldState.nCols} setValue={(e) => setState('nCols', e)}/>
        </div>

        <EditTypeRadio
            editType={editType}
            setEditType={setEditType}
            value = 'moveKarel'
            label={<>Karel</>}
        />
        <EditTypeRadio
            editType={editType}
            setEditType={setEditType}
            value = 'addBeeper'
            label={<BeeperIcon text="+"/>}
        />
        <EditTypeRadio
            editType={editType}
            setEditType={setEditType}
            value = 'removeBeeper'
            label={<BeeperIcon text="-"/>}
        />
    </div>
}



const EditableCanvas = ({rowColClick, worldState, setWorldState}) => {
    const { karelType } = useContext(IDEContext);


    useEffect(()=> {
    }, [worldState])

    const canvasState = useComponentSize();
    const canvasRef = canvasState.ref

    const [worldHeight, setWorldHeight] = useState(0)

    // react to change in the dimension of the "canvas"
    useEffect(() => {
        let cornerSize = getCornerSize(state)
        setWorldHeight(worldState.nRows * cornerSize)
    }, [canvasState])

    // package the state into a variable
    const state = {
        // worldState is karel relevant variables. This is currently
        // a fixed example :-)
        worldState,
        // canvas state are {width, height, ref} of the canvas
        canvasState
    }

    function onClick(e) {
        var rect = canvasRef.current.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.
        const [row, col] = xyToRowCol(state, x, y)
        rowColClick(row, col)
    }

    if(!isValidWorldState(worldState)) return <>Invalid Karel state</>

    return <div
        onClick= {(e) => onClick(e)}
        ref={canvasRef}
        className="w-100"
        style={{
            height:worldHeight,
            backgroundColor:'white',
            position:'relative',
            cursor:'pointer',
            userSelect: 'none'
        }}
        >
        <GridBackground state={state}/>
        <Paint state={state}/>
        <EditableWalls state={state} setWorldState={setWorldState}/>
        <Beepers state={state}/>
        <Agent state={state} karelType={karelType} />
    </div>
}

const IntInput = ({value, setValue, label}) => {
    return <>
        <div className="input-group input-group-sm" style={{maxWidth:50}}>
            {/* <span className="input-group-text" id="inputGroup-sizing-sm">{label}</span> */}
            <input key={label} onChange={(e) => setValue(Number(e.target.value))}value = {value} type="number" min={1} max={100} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
        </div>
    </>
}
const EditTypeRadio = ({value, label, editType, setEditType}) => {
    function onClick(){
        setEditType(value)
    }

    const btnColor = (editType === value) ? 'btn-outline-secondary' : 'btn-light'
    return <button
        onClick = {() => onClick()}
        className={`btn btn-sm ${btnColor}`}>{label}</button>
}

function rotateLeft(dir) {
    switch(dir){
        case "East": return "North"
        case "North": return "West"
        case "West": return "South"
        case "South": return "East"
        default: return "East"
    }
}

function editBeepers(setWorldState, row, col, delta){
    const rowColKey = makeRowColKey(row, col)
    setWorldState((oldWorldState) => {
        const newWorldState = deepCopy(oldWorldState)
        // careful about getting the new beepers state
        if(!("beepers" in newWorldState)){
            newWorldState["beepers"] = {}
        }
        const beepers = newWorldState["beepers"]
        const oldCount = (rowColKey in beepers) ? beepers[rowColKey] :0
        const newCount = oldCount + delta
        if(newCount >= 0) {
            beepers[rowColKey] = newCount
        }

        return newWorldState
    })
}

const BeeperIcon = ({text}) =>{
    const size = 24
    return <div
    className="karel-stone"
    style={{
      width: size,
      height: size,
      marginLeft: 0,
      marginTop: 0,
      position:'relative'
    }}
  >
    <div className="karel-stoneDiamond" />
    <span
      className="karel-stoneText"
      style={{
        fontSize: size * 0.75,
        marginTop: -size * 1.05,
        width: size,
        marginLeft:-size * 0.5
      }}
    >
      {text}
    </span>
  </div>
}

import styled from "styled-components";

const Greeting = styled.h1`
  font-size: 27px; // Adjust the size as needed
  font-weight: bold; // Makes the text bolder
`;

const Emoji = styled.span`
  font-size: 3.5rem; /* Adjust size as needed
  display: flex; /* This ensures that the emoji centers vertically */
  align-items: center; /* Aligns vertically */
  justify-content: center; /* Centers horizontally in the column if there's extra space */
  margin-right: 20px; /* Adds space to the right of the emoji */
`;

const StudentFeedbackHeader = ({ studentName, courseWeek }) => {
  function sanitize(input) {
    // turn "Week 0" into "Week 1" (just add one!)
    const weekNumber = parseInt(input.split(" ")[1]);
    const sanitizedWeekNumber = weekNumber + 1;
    return `Week ${sanitizedWeekNumber}`;
  }


  const sanitizedCourseWeek = sanitize(courseWeek);

  return (
    <div>
      <div className="d-flex">
        <div className="text-center">
          <Emoji role="img" aria-label="waving hand" className="emoji">
            🥳
          </Emoji>
        </div>
        <div>
          <Greeting>Hi, {studentName}!</Greeting>
          <p className="subtext">
            Congratulations on finishing your {sanitizedCourseWeek} section!{" "}
          </p>
        </div>
      </div>
      <div>
        <div>
          <p className="feedback-text mt-3" style={{ fontStyle: "italic" }}>
            This page displays automated feedback from your section transcript.
            We focus on your section’s curiosity and participation. This
            feedback is personalized to your Zoom classroom and will not be used
            for evaluating your performance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentFeedbackHeader;

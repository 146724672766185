import { FAQAccordion } from "components/FAQ/FAQ";


const sectionLeaderFaqContent = [
  {
    q: "What skills do you learn by teaching?",
    a:
      'Teaching is a profound learning experience. It\'s perhaps the best way to really master a subject. But beyond that you get practice with the sort of "soft" skills that can be so crucial in life: how to manage a group, how to communicate, and more.',
  },
  {
    q: "Can we teach my friends and family?",
    a:
      "Yes! Each section leader is allowed to recommend two students to join, per section they teach. The recommended students still need to complete an application to be in the course. By default the students you recommend will not be in your section. ",
  },
  {
    q:
    "Can I count this as volunteer work?",
    a: 
      <span>
        Probably! As an example, for Stanford students it counts towards a 
        <a href="https://cardinalservice.stanford.edu/programs/cardinal-commitment">
          Cardinal Commitment
        </a>.
      </span>
    ,
  },
  {
    q: "Is this really the course with the most teachers?",
    questionShort: "Really the most teachers?",
    a:
      "To the best of our knowlege! We could be the single course, with the biggest team of teachers. But it is not about records, it is about the beautiful experience that real world teachers bring.",
  },
  {
    q: "Why do section leaders matter?",
    a:
      "Weekly in-person meetings with a section leader like you seems to have a huge impact on student learning and commitment. One hypothesis for why is that the motivation and sense of responsibility that you create in students plays a suprisingly large factor in learning.",
  },
  {
    q: "Are only Stanford students and alumni teaching this course?",
    questionShort: "Only Stanford?",
    a:
      "No! Stanford hosts the course and provides materials, but the beauty of Code in Place is that great section leaders come together from around the world. Join us!",
  },
  {
    q: "Will we make history?",
    a:
      "Hopefully! We hope that this course inspires others to realize how many people there are who have amazing potential to be great teachers, and that these teachers could have a big impact on students. Code in Place is a collaborative effort, and together we could build something special.",
  },
  {
    q: "When will my section be?",
    a:
      <>Our students are all over the world, so there's always someone learning in Code in Place. If you are chosen as a section leader, you can choose to schedule your section any time between Wednesday and Friday.</>
  },
];

export const FAQ = () => {
  return (<>
  <h1>Frequently Asked Questions</h1>
  <FAQAccordion content={sectionLeaderFaqContent}/>
  </>)
};

import { FaPlus } from "react-icons/fa"
import { FinalProjectContext } from "./FinalProjectContext"
import { useContext } from "react"

export const NewProjectProposalButton = () => { 

  const {createProjectAndNavigate} = useContext(FinalProjectContext)

  const onClick = () => {
    createProjectAndNavigate()
  }

  return <button onClick={onClick} className="btn btn-sm btn-light"><FaPlus/> Create a new proposal</button>
}
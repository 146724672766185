import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { extractText } from "./utils";



export const CanvasAssnModal = ({ assnTitle, assnId, canvasAssnId }) => {
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);

    return (
        <>

            <button className="btn btn-primary" onClick={() => setShow(true)}>{canvasAssnId ? "Edit Assignment in Canvas": "Add Assignment to Canvas"}</button>
            <CanvasAssnForm 
                show={show} 
                handleClose={handleClose} 
                assnTitle={assnTitle}
                assnId={assnId}
                canvasAssnId={canvasAssnId}
            />
        </>
    )
}

const CanvasAssnForm = ({ assnTitle, assnId, show, handleClose, canvasAssnId }) => {
    const [linkToCanvasAssn, setLinkToCanvasAssn] = useState(false);
    const [canvasAssnTitle, setCanvasAssnTitle] = useState(assnTitle);
    const [canvasAssnDescription, setCanvasAssnDescription] = useState("");
    const [canvasAssnDueDate, setCanvasAssnDueDate] = useState(null);
    const [canvasAssnMaxPoints, setCanvasAssnMaxPoints] = useState(0);
    const [canvasAssnGroup, setCanvasAssnGroup] = useState(null);
    const [canvasVisible, setCanvasVisible] = useState(true);
    const [upToDateCanvasAssnId, setUpToDateCanvasAssnId] = useState(canvasAssnId);
    const [isAdding, setIsAdding] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [canvasAssnsList, setCanvasAssnsList] = useState([]);
    const [canvasAssnGroups, setCanvasAssnGroups] = useState([]);
    const [selectedLinkedCanvasAssn, setSelectedLinkedCanvasAssn] = useState(null);
    const functions = getFunctions();
    const createCanvasAssn = httpsCallable(functions, 'createCanvasAssn');
    const updateCanvasAssn = httpsCallable(functions, 'updateCanvasAssn');
    const getCanvasAssn = httpsCallable(functions, 'getCanvasAssn');
    const getCanvasAssns = httpsCallable(functions, 'getCanvasAssns');


    const courseId = useCourseId();
    const db = getFirestore();



    useEffect(() => {
        const fetchCanvasAssn = async () => {
            setIsLoading(true);
            if(!show) {
                setIsLoading(false);
                return;
            }
            if(canvasAssnId) {
                try {
                    const canvasAssnProm =  getCanvasAssn({
                        assnId: upToDateCanvasAssnId,
                        courseId: courseId
                    }) as any;
                    const canvasAssnsProm = getCanvasAssns({
                        courseId: courseId
                    }) as any;
                    const [canvasAssn, allAssns] = await Promise.all([canvasAssnProm, canvasAssnsProm]);

                    if(canvasAssn.data && canvasAssn.data.success) {
                        const assnData = canvasAssn.data.resp;
                        setCanvasAssnTitle(assnData.title);
                        setCanvasAssnDescription(extractText(assnData.description));
                        setCanvasAssnDueDate(assnData.dueDate.substring(0, 16));
                        setCanvasAssnMaxPoints(assnData.totalPoints);
                        setCanvasVisible(assnData.published);
                        setCanvasAssnGroup(assnData.group);
                    }
                    if(allAssns.data && allAssns.data.success) {
                        const { assns, assnGroups } = allAssns.data.resp;
                        setCanvasAssnsList(assns);
                        setCanvasAssnGroups(assnGroups);
                    }
                } catch (e) {
                    console.error(e);
                }
            } else {
                try {
                    const result = await getCanvasAssns({
                        courseId: courseId
                    }) as any;

                    if(result.data && result.data.success) {
                        const { assns, assnGroups } = result.data.resp;
                        setCanvasAssnsList(assns);
                        setCanvasAssnGroups(assnGroups);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            setIsLoading(false);
        }
        fetchCanvasAssn();

                    
    }, [show])



    const updateAssigment = async () => {
        setIsAdding(true);
        try {
            let dueDateIso;
            if(canvasAssnDueDate) {
                dueDateIso = new Date(canvasAssnDueDate).toISOString();
            }

            if(linkToCanvasAssn) {
                const assnDoc = doc(db, "assns", courseId, "assignments", assnId);
                await setDoc(assnDoc, {
                    canvasAssnId: selectedLinkedCanvasAssn
                }, {merge: true});
                setUpToDateCanvasAssnId(selectedLinkedCanvasAssn);
                setLinkToCanvasAssn(false);
            } else if(!upToDateCanvasAssnId) {
                const result = await createCanvasAssn({
                    assnTitle: canvasAssnTitle,
                    assnDescription: canvasAssnDescription,
                    dueDate: dueDateIso,
                    totalPoints: canvasAssnMaxPoints,
                    published: canvasVisible,
                    assnId: assnId,
                    courseId: courseId
                }) as any;

                if(result.data && result.data.success) {
                    setUpToDateCanvasAssnId(result.data.resp.id);
                }
            } else {
                const result = await updateCanvasAssn({
                    assnTitle: canvasAssnTitle,
                    assnDescription: canvasAssnDescription,
                    dueDate:  dueDateIso,
                    totalPoints: canvasAssnMaxPoints,
                    published: canvasVisible,
                    canvasAssnId: upToDateCanvasAssnId,
                    courseId: courseId
                }) as any;
            }
            handleClose();
        } catch (e) {
            console.error(e);
        }
        setIsAdding(false);
    }

    if(isLoading) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Loading Assignment...</Modal.Title>
                </Modal.Header>
            </Modal>
        )
    }

    if(linkToCanvasAssn) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Link to Canvas Assignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Link to Canvas Assignment</label>
                        <input type="checkbox" checked={linkToCanvasAssn} onChange={(e) => setLinkToCanvasAssn(e.target.checked)} />
                    </div>
                    <div className="form-group">
                        <label>Select Assignment</label>
                        <select className="form-control" value={selectedLinkedCanvasAssn} onChange={(e) => setSelectedLinkedCanvasAssn(e.target.value)}>
                            {canvasAssnsList.map((assn) => {
                                return (
                                    <option key={assn.id
                                    } value={assn.id}>{assn.title}</option>
                            )})
                            }
                        </select>
                    </div>
                    <button className="btn btn-primary" onClick={updateAssigment}>Update Assignment</button>
                </Modal.Body>
            </Modal>

        )
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add and Edit Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isAdding && <div className="alert alert-info">Adding Assignment...</div>}
                { !upToDateCanvasAssnId &&  <div className="form-group">
                    <label>Link to Canvas Assignment</label>
                    <input type="checkbox" checked={linkToCanvasAssn} onChange={(e) => setLinkToCanvasAssn(e.target.checked)} />
                </div>}
                <div className="form-group">
                    <label>Title</label>
                    <input type="text" className="form-control" value={canvasAssnTitle} onChange={(e) => setCanvasAssnTitle(e.target.value)} disabled={isAdding} />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea className="form-control" value={canvasAssnDescription} onChange={(e) => setCanvasAssnDescription(e.target.value)} disabled={isAdding} />
                </div>
                <div className="form-group">
                    <label>Due Date</label>
                    <input type="datetime-local" className="form-control" value={canvasAssnDueDate} onChange={
                        (e: any) => {
                            // convert to ISO string
                            setCanvasAssnDueDate(e.target.value);
                        }
                    } disabled={isAdding} />
                </div>
                <div className="form-group">
                    <label>Max Points</label>
                    <input type="number" className="form-control" value={canvasAssnMaxPoints} onChange={(e: any) => setCanvasAssnMaxPoints(e.target.value)} disabled={isAdding} />
                </div>
                <div className="form-group">
                    <label>Group</label>
                    <select className="form-control" value={canvasAssnGroup} onChange={(e) => setCanvasAssnGroup(e.target.value)} disabled={isAdding}>
                        <option value={null}>None</option>
                        {canvasAssnGroups.map((group) => {
                            return (
                                <option key={group.id} value={group.id}>{group.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>Visible to Students (Published)</label>
                    <input type="checkbox" checked={canvasVisible} onChange={(e) => setCanvasVisible(e.target.checked)} disabled={isAdding} />
                </div>
                <button className="btn btn-primary" onClick={updateAssigment}>Update Assignment</button>
            </Modal.Body>
        </Modal>
    )

}




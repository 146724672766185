import { doc, getFirestore } from "firebase/firestore";
import StudentReflection from "./StudentReflection";
import { useUserId } from "hooks/user/useUserId";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { useDocumentData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { submitDataToFirestore } from "../StudentFeedbackComponents";
import { CompletionContext } from "contexts/CompletionContext";
import { is } from "date-fns/locale";
import { useParams } from "react-router";

const StaticStudentFeedback = () => {

  const { sectionWeek, sectionId } = useParams();
  const week = sectionWeek
  const db = getFirestore();
  const { userData } = useContext(ProfileContext);
  const studentName = userData?.displayName || "Student";
  const { isSectionCompleted } = useContext(CompletionContext);
  const userId = useUserId()
  const courseId = useCourseId();
  const feedbackDataPath = `/transcriptFeedback/${courseId}/${week}/feedback/${sectionId}/data`;
  const feedbackDataDocRef = doc(db, `${feedbackDataPath}`);
  const [feedbackData, feedbackLoading] = useDocumentData(feedbackDataDocRef);

  if (feedbackLoading) return <></>

  const weekStr = week.replace("week", "");
  
  const isStudentPresent = isSectionCompleted(weekStr);

  return (
    <div className="mt-5">
      {isStudentPresent ? <>
        <div>
          <h2>{studentName}, thank you for showing up to section!</h2>
          <p>
            Coming to sections, sharing your ideas, and asking questions can
            maximize you and your peers’ learning experience.
          </p>
          <p>
            We recognize all the time and hard work that you’re putting in. You
            rock!
          </p>
        </div>
        <SectionContainer>
          <StudentReflection
            hideFeedbackSection={true}
            submittedLesson={
              feedbackData?.studentReflection?.[userId]?.["lessonLearnt"] || ""
            }
            submittedFeedback={
              feedbackData?.studentFeedback?.[userId]?.["feedback"] || ""
            }
            onLessonSubmit={(lessonLearnt) => {
              submitDataToFirestore(
                feedbackDataDocRef,
                {
                  studentReflection: {
                    [userId]: {
                      studentName: userData.displayName,
                      lessonLearnt: lessonLearnt,
                    },
                  },
                },
                "Submitted!"
              );
            }}
            onFeedbackSubmit={(feedback) => {
              submitDataToFirestore(
                feedbackDataDocRef,
                {
                  studentFeedback: {
                    [userId]: {
                      studentName: userData.displayName,
                      feedback: feedback,
                    },
                  },
                },
                "Feedback Submitted!"
              );
            }}
          />
        </SectionContainer>
      </> : (
        <div>
          <h2>{studentName}, We noticed you didn’t come to section!</h2>
          <p>
            Coming to sections, sharing your ideas, and asking questions can
            maximize you and your peers’ learning experience.
          </p>
          <p>
            Showing up is the first step. We look forward to seeing you next
            week!
          </p>
        </div>
      )}


    </div>
  );
};

const SectionContainer = styled.div`
  margin-bottom: 50px;
`;

export default StaticStudentFeedback;

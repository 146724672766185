import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { getApp } from "firebase/app";
import {
  collection,
  doc,
  DocumentData,
  FirestoreDataConverter,
  getFirestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useState, useEffect } from "react";
import { ForumContext } from "../ForumContext";
import { useFirebaseDocOnce } from "hooks/database/useFirebaseDocOnce";

export type Tag = {
  name: string;
  bgColor: string;
  textColor?: string;
};

export type TagMap = { [tagname: string]: Tag };

export type TagDoc = {
  tags: TagMap;
};

const TAG_CONVERTER: FirestoreDataConverter<TagDoc> = {
  toFirestore: (tagDoc: TagDoc): DocumentData => tagDoc,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): TagDoc => snapshot.data() as TagDoc,
};

const get_static_data_collection_ref = (courseId: string) => {
  return collection(
    getFirestore(getApp()),
    "forumStaticData",
    courseId,
    "data"
  );
};

export const get_tag_doc_ref = () => {
  const courseId = useCourseId();
  return doc(get_static_data_collection_ref(courseId), "tags").withConverter(
    TAG_CONVERTER
  );
};

const get_tag_doc_path = () => {
  const courseId = useCourseId();
  return `forumStaticData/${courseId}/data/tags`;
}


export function useTags() {
  const [ isLoading, data, refresh ] = useFirebaseDocOnce(
    get_tag_doc_path(), {
      tags: {}
    }
  );
  const [safeTags, setSafeTags] = useState<TagMap>({});
  const [safeTagsLoading, setSafeTagsLoading] = useState(true);

  useEffect(() => {
    setSafeTags(data?.tags ?? {});
    setSafeTagsLoading(isLoading);

  }, [data, isLoading]);

  return { tags: safeTags, isLoading: safeTagsLoading };
}


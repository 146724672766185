import { useNavigate } from "react-router-dom"
import { useCourseId } from "hooks/router/useUrlParams"

export const SectionButton = ({ name, sectionId, large=false }) => {
    // button which says the section  name and, when clicked, navigates to the section page
    const navigate = useNavigate()
    const courseId = useCourseId()
    const onCopy = () => {
    navigate(`/${courseId}/section/${sectionId}`)
    }
    return <button onClick={onCopy} className={large ? "btn btn-lg btn-light" : "btn btn-sm btn-light"}>{name}</button>

}

import React, { useContext, useEffect, useRef, useState } from "react";
import "firebase/compat/auth";
import { firestore, functions } from "firebaseApp";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router";


const defaultData = {
  projects: {},
  projectsLoading: true,
  createProjectAndNavigate: () => {},
  ideateRef: null,
  reflectRef: null,
  milestoneRef: null
};

export const finalProjectCollection = (courseId, userId) => {
  const collectionPath = `finalProjects/${courseId}/${userId}`;
  const collectionRef = collection(getFirestore(), collectionPath);
  return collectionRef;
}

export const FinalProjectContext = React.createContext(defaultData);
export const FinalProjectProvider = ({children}) => {

  const courseId = useCourseId()
  const userId = useUserId();
  const navigate = useNavigate()
  const collectionRef = finalProjectCollection(courseId, userId)
  const [projectProposals, projectsLoading, errorLoadingProjects] = useCollection(collectionRef)
  
  const ideateRef = useRef(null)
  const reflectRef = useRef(null)
  const milestoneRef = useRef(null)
  

  let projects = null
  if(projectProposals) {
    projects = {}
    projectProposals.docs.forEach((doc) => {
      projects[doc.id] = doc.data()
    })
  }

  function createProjectAndNavigate() {
    const emptyProjectData = {
      phase:0,
      createdAt: serverTimestamp(),
    }
    
    addDoc(collectionRef, emptyProjectData).then((newDoc) => {
      // navigate to the new doc
      navigate(`/${courseId}/finalProjectProposal/${newDoc.id}`)
      return newDoc.id;
    });
  }
  
  


  return (
    <FinalProjectContext.Provider
      value={{
        projects,
        projectsLoading,
        createProjectAndNavigate,
        ideateRef,
        reflectRef,
        milestoneRef
      }}
    >
      {children}
    </FinalProjectContext.Provider>
  );
};


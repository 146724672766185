import { useState } from "react"
import { FaBell, FaSeedling } from "react-icons/fa"
import { NotificationDrawerWithData } from "course/components/notification/drawer/NotificationDrawer"
import { useComponentSize } from "react-use-size"
import { useNotifications } from "./NoteHooks"
import { acknowledgeNotification } from "./Types"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"



export const NotificationsNav = () => {
    const [showNotifications, setShowNotifications] = useState(false)
    const pageSize = useComponentSize();
    const isBroken = pageSize.width < 650;
    const { notifications, unreadNotifications } = useNotifications()
    const courseId = useCourseId()
    const userId = useUserId()



    const markAllRead = async () => {
        for (let note of notifications) {
            if (note.acknowledged) continue
            await acknowledgeNotification(courseId, note.forumId, note.id, userId)
        }
    }


    return (
        <div style={{ position: "relative" }} title="notifications button"> {/* Added relative positioning here */}
            <button className="btn" onClick={() => setShowNotifications(!showNotifications)} >
                <FaSeedling className="mainNavButton" />
                <div className="badge bg-danger p-1" style={{
                    position: "absolute",
                    top: "0",
                    right: "0", // Adjusted from 10px to 0
                }}>
                    {unreadNotifications > 0 ? unreadNotifications : ""}
                </div>
            </button>
            <NotificationDrawerWithData
                notifications={notifications}
                open={showNotifications}
                closeHandler={() => setShowNotifications(false)}
                width={isBroken ? 400 : 500}
                markAllRead={markAllRead}
                emailSwitch={false} />
        </div>
    )
}

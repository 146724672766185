import { getErrorMessage, getStdYellowMessage } from "../../ErrorMessage/errorhint.js";
import DOMPurify from "dompurify";
// import ansiHTML from "ansi-html"
// import stripAnsi from 'strip-ansi';
import Convert from "ansi-to-html"


// Init terminal term



/**
 * @name TerminalView
 * @description TerminalView class that handles all interactions with the terminal
 */

const MAX_OUTPUT_LINES = 1000
export class TermModel {
  constructor(userSpecifiedShellprompt, runCode, onEnd, raiseStop, isKarel,setReplMode, runReplCode) {
    this._currCmd = "";
    this._shellprompt = `${userSpecifiedShellprompt} `;
    this._input = false;
    this._stdinPromise = {};
    this._runCode = runCode;
    this._onEnd = onEnd;
    this._code = "";
    this._raiseStop = raiseStop;
    this._deleteBuffer = this._shellprompt.length;
    this.isKarel = isKarel;
    this._cmdBuffer = []
    this._cmdPtr = 0;
    this._setReplMode = setReplMode;
    this.replMode = false
    this.runReplCode = runReplCode;
    this.replCode = ""
    this.content = ""
    this.flushTimer = null
    this.buffer = ''
    this.convert = new Convert()
    this.stepTimer = null
    this.stepBuffer = ""
    this.preStepContent = ""
    this.writePromise = null;
  }

  /**
   * @name initTerm
   * @description Initializes the terminal
   * @returns {void}
   */
  initTerm() {
    const terminalInput = this.getTerminalInput();
    this.setTerminalHtml("")
    if (!terminalInput) { return; }
    terminalInput.onkeydown = this._onKey.bind(this);
    this.writeAndScroll(this._shellprompt);

  }


  getTerminalInput() {
    return document.getElementById("terminal-input");
  }

  getTerminal() {
    return document.getElementById("terminal");
  }

  setTerminalHtml(html) {
    const term = this.getTerminal();
    if(!term) return
    term.innerHTML = html;
  }

  getTerminalHtml() {
    const term = this.getTerminal();
    if(!term) return ""
    return term.innerHTML;
  }

  /**
   * @name focusTerm
   * @description Focuses the terminal - useful for instances like when the user is prompted for input
   * @returns {void}
   */
  focusTerm() {
    const term = this.getTerminal();
    if(!term) return
    term.focus();
  }

  /**
   * @name blur
   * @description Blurs the terminal element - useful for after running python via the terminal
   * @returns {void}
   */
  blur() {
    const term = this.getTerminal();
    if(!term) return
    term.blur();
  }


    /**
   * @name scheduleFlush
   * @description This function schedules a flush of the buffer to the terminal
   * @returns {void}
   */
    scheduleFlush() {
      if(!this.flushTimer) {
        this.writePromise = new Promise((resolve, reject) => {
          this.flushTimer = setTimeout(() => {
            this.flushBufferToTextArea(true)
            resolve()
          }, 100)
        })

          // this.flushTimer = setTimeout(this.flushBufferToTextArea.bind(this)  , 100)
      }
    }


      /**
   * @name flushBufferToTextArea
   * @description This function flushes the buffer to the terminal
   * @param {boolean} isEnd - Whether or not this is the end of the buffer
   * @returns {void}
   */
  flushBufferToTextArea(isEnd=false) {
    this._write(this.buffer, !isEnd)
    this.buffer = ''
    this.flushTimer = null
    // scroll to bottom
    this.scrollToBottom()
  }

  flushStep(isEnd=false) {

    this.setTerminalHtml(this.stepBuffer)
    this.stepTimer = null
    // if(isEnd) {
    //   this._prompt()
    // }
    this.scrollToBottom()
  }



  /**
   * @name _write
   * @description This function writes to the terminal
   * @param {string} str - The string to be written to the terminal
   * @param {boolean} _ - this is a dummy variable, added to maintain consistency with the other terminal model
   * @returns {void}
   */
  _write(str, _=true) {
    const term = this.getTerminal();
    if(!term) return;
    // get value of terminal (a pre)
    let termVal = term.innerHTML;
    let buffer = termVal.split("\n");
    if(buffer.length > MAX_OUTPUT_LINES) {
        buffer = buffer.slice(-MAX_OUTPUT_LINES); 
        termVal = buffer.join("\n");
    }
    const sanitizedStr = DOMPurify.sanitize(str)
    const ansiHtml = this.convert.toHtml(sanitizedStr)
    const content = termVal + ansiHtml;
    term.innerHTML = content;
    term.scrollTop = term.scrollHeight;
  }


  step(terminalContent) {
    let fullContent = ""
    for(let line of terminalContent) {
      const pureContent = DOMPurify.sanitize(line)
      fullContent += this.convert.toHtml(pureContent) + "\n"
    }
    this.stepBuffer = fullContent;
    if(!this.stepTimer) {
      this.stepTimer = setTimeout(this.flushStep.bind(this), 100)
    }
  }

  enterStep() {
    this.preStepContent = this.getTerminalHtml()
  }

  leaveStep() {
    this.setTerminalHtml(this.preStepContent)
  }



  /**
   * @name refreshTerm
   * @description Refreshes the terminal
   * @returns {void}
   */
  refreshTerm() {
    this.setTerminalHtml("")
    this.writeAndScroll(this._shellprompt);
    const termInput = this.getTerminalInput()
    if(!termInput) return
    termInput.onkeydown = this._onKey.bind(this);
  }


  /**
   * @name _prompt
   * @description Prompts the user for input
   * @param {boolean} pt - If in repl mode, whether or not the user is in the middle of a command or just beginning one
   * @returns {void}
   */
  _prompt(pt = true) {
    this._currCmd = "";
    if(this.replMode) {
      if(pt) {
        this.writeAndScroll("\r\n>>> ");
      } else {
        this.writeAndScroll("\r\n... ");

      }
    } else {
      this.writeAndScroll("\r\n" + this._shellprompt);
    }
  }

  /**
   * @name _prepareOutput
   * @description Prepares the terminal for output by adding a newline
   */
  _prepareOutput() {
    this.writeAndScroll("\r\n");
  }

  /**
   * @name _onKey
   * @description Handles keypresses in the terminal
   * @param {*} ev - event object
   */
  async _onKey(ev) {
    const printable = !ev.altKey && !ev.ctrlKey && !ev.metaKey;
    const ctrlPressed = ev.ctrlKey;
    if (ev.keyCode === 67 && ctrlPressed) {
      this._raiseStop();
    }
    let input = this.getTerminalInput();
    switch (ev.keyCode) {
      // enter
      case 13:
        if(input) {
          this._currCmd = input.value;
          input.value = "";
        }
        this.writeAndScroll(this._currCmd);

        if (this._input && this._stdinPromise.promise) {
          this._stdinPromise.resolve();
        } else {
          this._cmdBuffer.push(this._currCmd);
          if(this._cmdBuffer.length > 20) {
            this._cmdBuffer.shift();
          }
          this._cmdPtr = this._cmdBuffer.length;
          if(this.replMode) {
            await this._handleReplEnter(this._currCmd);
          } else {
            this._handleEnter(this._currCmd);
          }
          this._currCmd = "";
        }
        break;
      case 40:
        if(this._cmdPtr < this._cmdBuffer.length - 1) {
          this._cmdPtr++;
          if(input) {
            input.value = this._cmdBuffer[this._cmdPtr];
          }
        }
        break;
      case 38:
        if(this._cmdPtr > 0) {
          this._cmdPtr--;
          if(input) {
            input.value = this._cmdBuffer[this._cmdPtr];
          }
        }
        break;
    }
  }

  async handleEnter (currCmd) {
    if(this.replMode) {
      await this._handleReplEnter(currCmd);
    } else {
      this._handleEnter(currCmd);
    }
  }

  /**
   * @name _handleEnter
   * @description Handles the enter key being pressed
   * @param {*} cmd - command to be handled
   * @returns {Promise<void>}
   */
  async _handleEnter(cmd) {
    // Split into args
    const args = cmd.split(/\s+/);
    const firstArg = args[0];

    this._currCmd = "";

    // Check for keywords, else print command unknown
    if (firstArg === "python") {
      if (args[1]) {
        this._runCode();
      } else {
        this.writeAndScroll("\r\nPython 3.9");
        this.writeAndScroll("\r\nCode In Place 2023 Repl Mode");
        this.writeAndScroll(`\r\nType "quit()" to quit repl mode`);
        await this.toggleRepl();
      }
    } else if (firstArg === "clear") {
      this.clear();
      this._prompt();
    }else if (firstArg === "cd") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("No file directory here!");
      this._prompt();
    }else if (firstArg === "ls") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("What files do we have ");
      this._prompt();
    } else if (firstArg === "pip") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("You can't install packages, but you can import numpy!");
      this._prompt();
    } else if (firstArg === "echo") {
      this.writeAndScroll("\r\n");
      args.shift()
      for(var i of args) {
        this.writeAndScroll(i + " ");

      }
      this._prompt();
    }else {
      for (let unknownArg of args) {
        if (unknownArg.trim().length !== 0) {
          this.writeAndScroll("\r\n");
          this.writeAndScroll("command not found: ");
          this.writeAndScroll(unknownArg);
        }
      }
      this._prompt();
    }
  }

  /**
   * @name _handleReplEnter
   * @description Handles the enter key being pressed in repl mode
   * @param {*} cmd 
   */
  async _handleReplEnter(cmd) {
    if(cmd === "quit()") {
      await this.toggleRepl();
    }
    else {
      this.replCode = `\n${cmd}`
      let promptType = await this.runReplCode(this.replCode)
      if(this.replMode) {
        if(this.writePromise) {
          await this.writePromise
        }
        this._prompt(promptType);
      }
    }
    this._currCmd="";

  }

  /**
   * @name endStdin
   * @description resolves the stdin promise
   */
  endStdin() {
    if(this._input && this._stdinPromise) {
      this._stdinPromise.resolve()
    }
  }


  /**
   * @name handleStdin
   * @description Handles stdin
   * @param {*} _ 
   * @returns 
   */
  async handleStdin(_) {
    this._stdinPromise = this.getNewPromise();
    this._input = true;
    await this._stdinPromise.promise;
    this._input = false;
    // Reset promise
    this._stdinPromise = this.getNewPromise();
    const returnedInput = this._currCmd;
    this._currCmd = "";
    return returnedInput;
  }


  /**
   * @name handleStdout
   * @description Writes stdout to terminal
   * @param {string} stdout 
   * @param {boolean} nl 
   */
  handleStdout(stdout, nl=true) {
    if (stdout !== "Python initialization complete") {
      this.buffer += `${nl ? "\n": ""}${stdout}` 
      this.scheduleFlush()
      
    }
  }

  /**
   * @name handleStderr
   * @description Handles stderr by delegating to the getErrorMessage (a proxy for a cloud func) and writing the output to the terminal
   * @param {string} code 
   * @param {string} stderr 
   * @returns {Promise<string>} error message to be shown to user
   */
  async handleStderr(code, stderr, isStandard=false) {
    // Use the selectedErrorMessageType to get an error message
    let error_message;
    if(isStandard) {
      error_message = getStdYellowMessage(stderr, code);
    } else {
      error_message = await getErrorMessage(
        code,
        stderr,
        this
      );
    }

    // Output error message to the terminal
    this._prepareOutput();
    this.writeAndScroll(error_message);
    
    return error_message;
  }

  /**
   * @name handleEnd
   * @description Handles the end of a script run
   * @returns {void}
   */
  handleEnd() {
    this.flushBufferToTextArea()
    this._prompt();
    this._onEnd();
  }

  /**
   * @name getNewPromise
   * @returns {object} - object with promise and resolve members
   */
  getNewPromise() {
    let resolveFunc;
    // Returns object with promise member and resolve member
    return {
      promise: new Promise(function (resolve, reject) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
  }

  /**
   * @name updateCols
   * @description Updates the number of cols in the terminal
   * @param {*} newWidth 
   */
  updateCols(newWidth) {}

  /**
   * @name updateRows
   * @description Updates the number of rows in the terminal
   * @param {*} newHeight 
   */
  updateRows(newHeight) {}


  /**
   * @name writeAndScroll
   * @description Writes to the terminal and scrolls to the bottom
   * @param {string} newContent - content to be written to terminal 
   */
  writeAndScroll(newContent) {
    this._write(newContent)
    this.scrollToBottom();
  }



  scrollToBottom() {
    const input = this.getTerminalInput();
    if (input) {
      // scroll to bottom slowly
      // input.scrollIntoView({ behavior: "smooth", block: "end" });
      input.scrollIntoView();
    }
    return
  }

  /**
   * @name toggleRepl
   * @description Toggles repl mode
   * @returns {Promise<void>}
   */
  async toggleRepl() {
    this.replMode = !this.replMode;
    if(this.replMode) {
      this._deleteBuffer = 4
    } else {
      this._deleteBuffer = this._shellprompt.length;
    }
    await this._setReplMode(this.replMode);
    this._prompt()
  }


  /**
   * @name dispose
   * @description Disposes of the terminal
   * @returns {void}
   */
  dispose() {

  }

  clear() {
    this.setTerminalHtml("")
  }

  
}

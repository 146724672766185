const MessageBoard = ({ message }) => {
  return (
    <div className="row mt-5">
      <div className="col">
        <div className="text-bg-secondary rounded">
          <p className="p-3 text-center">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default MessageBoard;

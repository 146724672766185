import { useState, useEffect } from 'react'
import { database } from "firebaseApp.js";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useCourseId } from 'hooks/router/useUrlParams';
import { getApp } from "firebase/app"
import { collection, getFirestore, where, query, getDocs, orderBy, FieldPath } from 'firebase/firestore';
import { get } from 'firebase/database';
import { time } from 'console';
import { getRoomMembersUidToDisplayName } from './Rooms';

const functions = getFunctions();

const _joinTeachnow = httpsCallable(functions, 'joinTeachNow');
const _leaveTeachnow = httpsCallable(functions, 'leaveTeachNow');

export const joinTeachNow = async () => {
    // log that the SL joined the queue
    const result = await _joinTeachnow()
    return result
}

export const leaveTeachNow = async () => {
    // log that the SL left the queue
    await _leaveTeachnow()
}


const _setStudentTeachNowResponse = async (uid, courseId, response, projId = null) => {
    const studentTeachNowStatusRef = database.ref(`${courseId}/teachnow/queues/students/${uid}`);

    const resp = await studentTeachNowStatusRef.transaction((currentData) => {
        if (currentData === null) {
            return currentData; // Initialize if null
        }

        if (currentData.studentStatus !== 'nudged') {
            // Do nothing if status has already been updated e.g. timeout, un-nudge etc.
            return currentData;
        }

        if (response === 'accepted') {
            return { ...currentData, studentStatus: response, projId: projId };
        } else {
            return { ...currentData, studentStatus: response };
        }
    })

    if (!resp.committed) {
        console.error(`Transaction not committed whe updating ${uid} status to ${response}. Something went wrong`);
    }

}

export const acceptTeachNowRequest = async (uid, courseId, projId) => {
    await _setStudentTeachNowResponse(uid, courseId, 'accepted', projId);
}

export const rejectTeachNowRequest = async (uid, courseId) => {
    await _setStudentTeachNowResponse(uid, courseId, 'rejected');
}

export const timeoutTeachNowRequest = async (uid, courseId) => {
    await _setStudentTeachNowResponse(uid, courseId, 'timeout');
}

export const useTeachNowNumSessions = (uid, courseId) => {
    const [numSessions, setNumSessions] = useState(undefined);

    useEffect(() => {
        if (uid && courseId) { // Ensures that both uid and courseId are provided
            const roomsPath = `${courseId}/teachnow/learners/${uid}/rooms`;

            const roomsRef = database.ref(roomsPath);
            const listener = roomsRef.on('value', (snap) => {
                if (snap.exists()) {
                    setNumSessions(snap.numChildren());
                } else {
                    setNumSessions(0);
                }
            }, (error) => {
                console.error("Error fetching number of sessions", error);
                setNumSessions(undefined); // Handle error by resetting state or setting to error state
            });

            return () => {
                roomsRef.off('value', listener);
            };
        }
    }, [uid, courseId]); // Dependency array to re-run the effect when uid or courseId changes

    return numSessions;
};


export const useTeachNowStudentStatus = (uid, courseId) => {
    const studentQueuePath = `${courseId}/teachnow/queues/students/${uid}`
    console.log("studentQueuePath", studentQueuePath)
    const [studentTeachNowState, setStudentTeachNowState] = useState(undefined);

    useEffect(() => {
        if(uid) {
            const studentTeachNowStatusRef = database.ref(studentQueuePath);
            studentTeachNowStatusRef.on("value", (snap) => {
                const val = snap.exists() ? snap.val() : null;
                if(val) {
                    const studentStatus = val['studentStatus']
                    const studentTimeJoined = val['timeJoinedMS']
                    const nudgeId = val['nudgeId']
                    setStudentTeachNowState({studentStatus, studentTimeJoined, nudgeId})
                } else {
                    setStudentTeachNowState(null)
                }
            })
            return () => {
                studentTeachNowStatusRef.off();
            }
        }
    }, [uid, courseId])

    return studentTeachNowState
}

export const useTeachNowTeacherStatus = (uid, courseId) => {
    const teacherQueuePath = `${courseId}/teachnow/queues/teachers/${uid}`
    console.log("teacherQueuePath", teacherQueuePath)
    const [teacherTeachNowStatus, setTeacherTeachNowStatus] = useState(undefined);

    useEffect(() => {
        if(uid) {
            const teacherTeachNowStatusRef = database.ref(teacherQueuePath);
            console.log("teacherTeachNowStatusRef", teacherTeachNowStatusRef)
            teacherTeachNowStatusRef.on("value", (snap) => {
                console.log('print inside listener!!!')
                const val = snap.exists() ? snap.val() : null;
                console.log("teacherTeachNowStatus", val)
                if(val) {
                    const teacherStatus = val['teacherStatus']
                    setTeacherTeachNowStatus(teacherStatus)
                } else {
                    setTeacherTeachNowStatus(null)
                }
            })
            return () => {
                teacherTeachNowStatusRef.off();
            }
        }
    }, [uid, courseId])

    return teacherTeachNowStatus
}

// This function gets from firestore not the realtime database
// export const getNumTeachNowSessionsAndGratitude = async (uid, courseId) => {
//     const db = getFirestore(); // Assumes Firebase has been initialized elsewhere
//     const ticketsRef = collection(db, "teachnow", courseId, "tickets");
//     // needs to be where teacherId == uid AND matched == true
//     const ticketsQuery = query(ticketsRef, where("teacherId", "==", uid), where("matched", "==", true));
//     try {
//         const querySnapshot = await getDocs(ticketsQuery);
//         const numSessions = querySnapshot.size;
//         const gratitudeResponses = [];

//         for (const doc of querySnapshot.docs) {
//             const gratitudeRef = collection(doc.ref, "gratitude");
//             const gratitudeSnapshot = await getDocs(gratitudeRef);
//             gratitudeSnapshot.forEach((gratitudeDoc) => {
//                 const docId = gratitudeDoc.id;
//                 gratitudeResponses.push({ ...gratitudeDoc.data(), docId });
//             });
//         }

//         return { numSessions, gratitudeResponses };
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         return { numSessions: 0, gratitudeResponses: [] }; // Return default in case of error
//     }
// };

export const getNumTeachNowSessionsAndGratitude = async (uid, courseId) => {
    const db = getFirestore(); // Assumes Firebase has been initialized elsewhere
    const ticketsRef = collection(db, "teachnow", courseId, "tickets");
    const ticketsQuery = query(ticketsRef, where("teacherId", "==", uid), where("matched", "==", true)) //, orderBy(new FieldPath("matchedInfo", "timeMatchedServer"), "desc"))

    try {
        const querySnapshot = await getDocs(ticketsQuery);
        const numSessions = querySnapshot.size;

        let sessionInfo = []

        for (const doc of querySnapshot.docs) {
            const roomId = doc.id;

            const roomUidToDisplayName = await getRoomMembersUidToDisplayName(courseId, roomId);

            // get a list of all display names except for the one with the key of uid param
            const otherDisplayNames = Object.keys(roomUidToDisplayName).filter(key => key !== uid).map(key => roomUidToDisplayName[key]);


            const ticketDocData = doc.data();
            const sessionTime = ticketDocData?.matchedInfo?.timeMatchedServer;
            const gratitudeRef = collection(doc.ref, "gratitude");
            const gratitudeSnapshot = await getDocs(gratitudeRef);

            let currSessionInfo = {
                roomId: roomId,
                sessionTime: sessionTime,
                gratitudeResponses: [],
                studentDisplayNames: otherDisplayNames,
            }

            let gratitudeResponses = []
            for (const gratitudeDoc of gratitudeSnapshot.docs) {
                const docId = gratitudeDoc.id; // This is the student userId
                const senderDisplayName = roomUidToDisplayName[docId];
                const gratitudeData = gratitudeDoc.data();
                if (gratitudeData.gratitude == 0) {
                    continue;
                }

                const messageToSL = gratitudeData.messageToSL;
                gratitudeResponses.push({
                    gratitudeMessageToSL: messageToSL,
                    senderDisplayName: senderDisplayName,
                });
            }

            currSessionInfo.gratitudeResponses = gratitudeResponses;
            sessionInfo.push(currSessionInfo);
        }

        sessionInfo = sessionInfo.sort((a, b) => b.sessionTime - a.sessionTime);

        return { numSessions, sessionInfo };
    } catch (error) {
        console.error("Error fetching data:", error);
        return { numSessions: 0,  sessionInfo: [] }; // Return default in case of error
    }
};
// import firebase from "firebase";
import { Suspense, lazy, useContext, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useParams,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// set up the style
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.scss";

import {
  Authenticated,
} from "./components/auth/Authenticated";
import ErrorBoundary from "components/errors/ErrorBoundary";
import PyodideProvider from "./components/pyodide/PyodideProvider";
import { Loading } from "components/loading/Loading";
import { EmailActionHandler } from "course/emailActionHandler/EmailActionHandler";
import { BlogPage } from "blog/BlogPage";
import { CourseProvider } from "contexts/CourseContext";
import { ProjectShowcase } from "course/finalproject/showcase/ProjectShowcase";

import { WelcomeChoice } from "landing/welcome";
import { Unsubscribe } from "components/auth/Unsubscribe";
import { InterestForm } from "forms/Interest";
import { CustomAuth } from "components/auth/CustomAuth";
import CourseRoutes from "./CourseRoutes"

// Lazy imports load only when the page is visited, reducing initial load time
const TermsOfService = lazy(() => import("./landing/termsOfService/default.js"));
const Landing = lazy(() => import("./landing/home/default.js"));
const Team = lazy(() => import("./landing/team/default.js"));
const ContactUs = lazy(() => import("./landing/contactUs/default.js"));
// const CourseRoutes = lazy(() => import("./CourseRoutes"));
const AlumRoutes = lazy(() => import("./alumni/AlumniRoutes"));
const AlumniHome = lazy(() => import("./alumni/pages/Home"));
const AlumPageContainer = lazy(() => import("./alumni/AlumniPage"));


// TODO: @piech. Refactor: pull Authenticated for the course page to the outer router

const queryClient = new QueryClient();

/*
 * All course routes are in this component.
 * This allows us to conditionally render routes based on the course features.
 * Course features can be selected in the admin panel.
 * If a route belongs to a course, put it here
 */


/*
 * This contains the broader route initialization.
 * If a route does not belong to a course, put it here.
 */

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      {/* Landing website */}
      <Route
        index
        element={
          <Suspense fallback={<Loading />}>
            <Landing />
          </Suspense>
        }
      />
      <Route
        path="terms-of-service"
        element={
          <Suspense>
            <TermsOfService />
          </Suspense>
        }
      />
      <Route
        path="team"
        element={
          <Suspense>
            <Team />
          </Suspense>
        }
      />
      <Route
        path="2023/showcase"
        element={
          <QueryClientProvider client={queryClient}>
            <ProjectShowcase 
            courseId={"cip3"}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        }
      />
      <Route
        path="2024/showcase"
        element={
          <QueryClientProvider client={queryClient}>
            <ProjectShowcase 
            courseId={"cip4"}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        }
      />
      <Route path="emailactionhandler" element={<EmailActionHandler />} />
      <Route
        path="welcome"
        element={<Authenticated component={WelcomeChoice} />}
      ></Route>


      <Route
        path="unsubscribe"
        element={<Authenticated component={Unsubscribe} />}
      ></Route>
      <Route
        path="interest"
        element={<InterestForm/>}
      ></Route>
      <Route
        path="oauth2"
        element={<CustomAuth />}
      ></Route>

      {/* <Route
        path="welcome/choice"
        element={<PyodideProvider><Authenticated component={WelcomeChoice}/></PyodideProvider>}
      ></Route>

      <Route
        path="welcome/lesson1"
        element={<PyodideProvider><Authenticated component={WelcomeLessonOne}/></PyodideProvider>}
      ></Route> */}

      {/* <Route
        path="admitted"
        element={<Navigate to="/cip3/onboarding/student" />}
      ></Route> */}
      <Route
        path="blog/:blogId"
        element={<Authenticated component={BlogPage} />}
      />
      {/*
      <Route path="jitsi/:meetingId" element={<JitsiTest />} /> */}

      {/* <Route
        path="zoomtest/:meetingId"
        element={<ZoomTest/> }
        /> */}

      <Route path="2021/showcase" element={<ShowcaseRedirect />} />
      <Route path="2021/showcase/:showcaseId" element={<ShowcaseRedirect />} />
      <Route
        path="contact"
        element={
          <Suspense>
            <ContactUs />
          </Suspense>
        }
      />

      <Route
        path="alumni"
        element={
          <Authenticated component={AlumPageContainer} />
        }
      >
        <Route path="*" element={<AlumRoutes queryClient={queryClient} />} />
        <Route index element={<AlumniHome  />} />

      </Route>
      <Route
        path=":courseId"
        element={
          <CourseProvider>
            <PyodideProvider>
                <Outlet />
            </PyodideProvider>
          </CourseProvider>

        }
      >
        <Route path="*" element={<CourseRoutes  queryClient={queryClient}/>} />
        <Route index element={<Navigate to="home" replace />} />
      </Route>
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};

function ShowcaseRedirect() {
  const showcaseId = useParams().showcaseId || "";
  const path = `https://codeinplace-2021.netlify.app/2021/showcase/${showcaseId}`;
  useEffect(() => {
    window.location.href = path;
  }, []);

  return <></>;
}

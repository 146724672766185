function Karel() {
}

Karel.instructions = {
   move: 1, turn_left: 1, put_beeper: 1, pick_beeper: 1, paint_corner:1
};

Karel.predicates = {
   front_is_clear:1, front_is_blocked:1,
   left_is_clear:1, left_is_blocked:1,
   right_is_clear:1, right_is_blocked:1,
   beepers_present:1, no_beepers_present:1,
   facing_north:1, not_facing_north:1,
   facing_east:1, not_facing_east:1,
   facing_south:1, not_facing_south:1,
   facing_west:1, not_facing_west:1,
   beepers_in_bag:1,
   no_beepers_in_bag:1,
   corner_color_is:2, random:2
};

export default Karel


import { useWindowSize } from "react-use-size";
import { useIsMobile } from "utils/general";

const MINIMIZED_BREAKPOINT = 500

export const Brand = ({stanfordTo, university, title}) => {

  const isMobile = useIsMobile();

  if(isMobile) {
    title = title.replace('Code in Place', 'CIP')
    title = title.replace('Code In Place', 'CIP')
  }
  
  
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Stanford university={university}/>
      <span className="coursename" style={{color:'white'}}>{title}</span>

    
    </div>
  );
};

const Stanford = ({university}) => {
  const windowSize = useWindowSize();
  const isMinimized = windowSize.width < MINIMIZED_BREAKPOINT;
  if(isMinimized) return <></>
  return <span className="su-lockup__wordmark">{university}</span>
}

const StanfordWhite = () => {
  return <img src = "http://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/seal-watermark.png" alt="Stanford" width="100" height="100" />
}

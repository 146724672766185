import { LoadingOutlined } from "@ant-design/icons";
import {
  Drawer,
  Empty,
  List,
  Skeleton,
  Spin,
  Switch,
} from "antd";
import { ForumContext } from "course/forum/ForumContext";
import {
  getDocFromServer,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./NotificationDrawer.css";
import { CourseNotification } from "../Types";
import { useNotificationDataFetcher } from "../NoteHooks";
import { UserIcon } from "../../../forum/components/UserChip/UserChip";
import { formatDistance, set } from "date-fns";
import { acknowledgeNotification } from "../Types";
import { useUserId } from "hooks/user/useUserId";
import { FaSeedling } from "react-icons/fa";


const TEACHNOW_STUDENT_NOTIF_TYPE = 'teachNowStudent';



const EmailSwitch = () => {
  const [isEmailSwitchLoading, setIsEmailSwitchLoading] = useState(false);
  const courseId = useCourseId();
  const { forumId } = useContext(ForumContext);
  const functions = getFunctions();
  const getForumPreferences = httpsCallable(functions, "getForumPreferences");
  const [preferences, setPreferences] = useState({});


  useEffect(() => {
    const getPreferences = async () => {
      const forumPreferencesQuery = await getForumPreferences();
      setPreferences(forumPreferencesQuery.data)
    }

    getPreferences()
  }, [])


  const changeEmailPreference = async () => {
    setIsEmailSwitchLoading(true);
    try {
      const updateEmailPreference = httpsCallable(functions, "changeEmailPreferenceForForum");
      const updatedEmailPref = await updateEmailPreference({ courseId, forumId })
    } catch (e) { }
    setIsEmailSwitchLoading(false);
  }



  return (
    <div className="emailPreferenceContainer">
      <strong>Enable email notifications for this forum?</strong>
      {isEmailSwitchLoading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Switch
          checked={preferences[courseId]?.[forumId]?.sendEmails}
          onChange={changeEmailPreference}
          disabled={isEmailSwitchLoading}
          checkedChildren="on"
          unCheckedChildren="off"
        />
      )}
    </div>
  );
};



const Notification = (props: {
  notification: CourseNotification;
  closeHandler: () => void;
}) => {
  const { notification, closeHandler } = props;
  const notificationFetcher = useNotificationDataFetcher();
  const [postsLoading, setPostsLoading] = useState(true);
  const [senderData, setSenderData] = useState<any>(null);
  const [shortTitle, setShortTitle] = useState("");
  const [shortenedBody, setShortenedBody] = useState("");
  const [postNotFound, setPostNotFound] = useState(false)
  const [replyNotFound, setReplyNotFound] = useState(false)
  const courseId = useCourseId();
  const userId = useUserId();
  const navigate = useNavigate();
  const { acknowledged, id, timestamp, metaData, type } = notification;
  const { from, postId, replyId, forumId } = metaData;

  const getPost = async postId => await getDocFromServer(notificationFetcher.getPostDocRef(postId, forumId, courseId))
  const getReply = async replyId => await getDocFromServer(notificationFetcher.getReplyDocRef(replyId, forumId, courseId))
  const getSender = async senderId => await getDocFromServer(notificationFetcher.getUserDocRef(senderId))


  const onAcknowledge = async () => {
    if (!notification.acknowledged) {
      await acknowledgeNotification(courseId, forumId, notification.id, userId)
    }
    navigate(`/${courseId}${getForumPath(forumId)}?post=${postId}`);
    closeHandler();
  }


  useEffect(() => {

    const getPosts = async () => {
      let postQuery;
      let replyQuery;

      try {
        postQuery = await getPost(postId)
        replyQuery = await getReply(replyId)
      } catch (e) {
        return;
      }


      let sBody = ""
      let sTitle = ""


      if(!postQuery.exists()) {
        sTitle = "Post not found"
        setPostNotFound(true)
      } else {
        const post = postQuery.data()
        const title = post.title ?? "";
        sTitle = title.length > 40 ? `${title.substring(0, 50)}...` : title;
      }

      if(!replyQuery.exists()) {
        sBody = "Reply not found"
        setReplyNotFound(true)
      } else {
        const reply = replyQuery.data()
        const replyBody = reply.contents.text ?? "";
        sBody = replyBody.length > 50 ? `${replyBody.substring(0, 50)}....` : replyBody;
      }


      setShortTitle(sTitle)
      setShortenedBody(sBody)
      setPostsLoading(false)
    }

    const getSenderData = async () => {
      const senderData = await getSender(from)
      setSenderData(senderData.data())
    }

    getPosts()
    getSenderData()
  }, [metaData])


  if (postsLoading) {
    return (
      <List.Item className="notification">
        <Skeleton paragraph={false} />
      </List.Item>
    );
  }


  return (
    <div
      className={`notification${acknowledged ? "" : "-new"} p-1 border`}
      onClick={onAcknowledge}
      key={id}
      tabIndex={0}
    >
      <div className={`d-flex justify-content-left p-1 `}  >
        <div className="d-flex align-items-center">
          <UserIcon photoURL={senderData?.photoURL ?? ""} name={senderData?.displayName ?? "A"} />

        </div>
        <div className="ml-2">
          <div style={{
            height: "40px"
          }}>
            <span className="notificationHead" ><strong>{senderData?.displayName ?? "Anonymous"}</strong> replied on {postNotFound ? <span><i>{shortTitle}</i></span>: <span>{shortTitle}</span>}</span>
          </div>
          {replyNotFound ? <span><i>{shortenedBody}</i></span> : <span>{shortenedBody}</span>}

        </div>

      </div>
      <div className="d-flex justify-content-between">
        <p className="notificationTime">
          {formatDistance(timestamp, new Date(), {
            addSuffix: true,
          })}
        </p>
        <OriginBadge originType={type} metaData={metaData} />
      </div>
    </div>
  );
};


const TeachNowStudentNotif = ({ notification, closeHandler }) => {
    const { id, acknowledged, timestamp } = notification;
    const navigate = useNavigate();
    const courseId = useCourseId();
    const userId = useUserId();
  
    const onAcknowledge = async () => {
      if (!notification.acknowledged) {
        await acknowledgeNotification(courseId, TEACHNOW_STUDENT_NOTIF_TYPE, notification.id, userId)
      }
      navigate('/cip4/studentteachnow');
      closeHandler();
    };
  
    return (
      <div
        className={`notification${acknowledged ? "" : "-new"} p-1 border`}
        onClick={onAcknowledge}
        key={id}
        tabIndex={0}
      >
        <div className="d-flex justify-content-left p-1">
          <div className="d-flex align-items-center">
            <FaSeedling size={24} style={{ color: 'green', marginBottom: '10px' }} /> {/* Adjust size and color as needed */}
          </div>
          <div className="ml-2">
            <div style={{ height: "40px" }}>
              <span className="notificationHead">
                <strong>Special Invitation to be a Peer Helper</strong>
                <p>Get a chance to help real CIP students one-on-one.</p>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
        <p className="notificationTime">
          {formatDistance(timestamp, new Date(), {
            addSuffix: true,
          })}
        </p>
        <p className="badge" style={{ backgroundColor: '#019750' }}>
          {'Code in Place Team'}
        </p>
      </div>
        
      </div>
    );
  };


export const NotificationDrawerWithData = (props: {
  notifications: CourseNotification[];
  open: boolean;
  closeHandler: () => void;
  width?: number;
  markAllRead: any;
  emailSwitch: boolean;
}) => {
  const { notifications, open, closeHandler, width, markAllRead, emailSwitch } = props;


  return (
    <Drawer
      title="Notifications"
      placement="right"
      width={width}
      onClose={closeHandler}
      open={open}
      extra={
        <button className="btn btn-primary" onClick={markAllRead}>
          Mark all as read
        </button>
      }
      footer={emailSwitch ? <EmailSwitch /> : null}
      bodyStyle={{
        margin: 0,
        padding: 0
      }}
    >
      {notifications?.length === 0 ? (
        <Empty description={"Nothing to see here!"} />
      ) : (
        <div title="notifications list">
          {notifications.map((notification) => {
            // Check if the type of the notification is 'teachNowStudent'
            if (notification.type === TEACHNOW_STUDENT_NOTIF_TYPE) {
              // Render the TeachNowStudentNotif component for this notification
              return <TeachNowStudentNotif notification={notification} closeHandler={closeHandler} key={notification.id} />
            } else {
              // Render the regular Notification component for other types
              return <Notification notification={notification} closeHandler={closeHandler} key={notification.id} />
            }
          })}
        </div>
      )}
    </Drawer>
  )
}



const OriginBadge = ({ originType, metaData }) => {
  let text = "Other";
  let backgroundColor = "#f0f0f0";

  switch (originType) {
    case "forum":
      text = getForumString(metaData.forumId);
      backgroundColor = "#1890ff";
      break;
    default:
      break;
  }


  return (
    <p className="badge" style={{ backgroundColor }}>
      {text}
    </p>
  )
}



const getForumString = (forumId) => {
  switch (forumId) {
    case "main":
      return "Forum - Main"
    case "mainx":
      return "Forum - MainX"
    case "teacherslounge":
      return "Forum - Teachers Lounge"
    case "report":
      return "Forum - Report"
    default:
      return "Forum - Section"
  }
}

const getForumPath = (forumId) => {
  // TODO: Later switch to naming convention, if last n letters are teacherslounge, then it is teacherslounge

  switch (forumId) {
    case "main":
      return "/forum"
    case "mainx":
      return "/forumx"
    case "report":
      return "/report"
    case "foothill-cs49-teacherslounge":
    case "cip4-teacherslounge":
    case "cip3-teacherslounge":
      return "/teacherslounge"
    default:
      return `/sectionforum/${forumId}`
  }
}
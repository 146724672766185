import { TextAreaInput, TextInput, CheckboxBoolean } from "components/Forms/FormixComponents";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaQuestionCircle } from 'react-icons/fa';

export const NameInput = ({name}) => <TextInput
  label="Display Name"
  description="This is the name that will be displayed to other students in the course. Be sure to keep in mind your own privacy. As a best practice, we recommend using your first name and last initial."
  name={name}
  type="text"
  placeholder="Jane Doe"
/>

export const AboutMeInput = ({name}) => <TextAreaInput
  label="About me"
  name={name}
  description="Anything goes. What do you do for fun? Want to tell us a quick anecdote about something that makes you unique—a talent, an unusual experience, or anything of that sort?"
/>

export const ConnectionsConsentCheckbox = ({name}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      We will soon be launching a feature that allows you to connect with other course participants and build your own community of co-learners. If you would like to participate in this feature, please check this box!
    </Tooltip>
  );
  return <>
    <CheckboxBoolean
      label={<>
      Connections
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <span style={{ marginLeft: "5px", cursor: "pointer" }}>
          <FaQuestionCircle
            style={{ backgroundColor: "transparent" }}
          />
        </span>
      </OverlayTrigger>
      </>}
      description="&nbsp; I'd like to receive and send connection requests to other students in the course."
      name={name}
    />
  </>
}

export const GoalsInput= ({name}) => <TextAreaInput
  label="Learning goals"
  name={name}
  description="Tell us about what you’re hoping to use your new coding skills for. If you have a final project idea in mind, tell others about it here!" // TODO: different for sls?
/>
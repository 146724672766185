import { useState, useEffect } from "react"
import {
    DropdownButton,
    Dropdown,
    Button,
    FormControl,
    InputGroup,
    Badge,
    CloseButton,
} from "react-bootstrap"
import { FaTrash, FaPlus, FaEraser } from "react-icons/fa"
import { MonacoTipTap } from "components/richTextEditor/TipTap/extensions/runnableKarel/TipTapCodeEditor"

export const HiddenExample = ({
    hiddenExampleData,
    editHiddenExample,
    deleteHiddenExample
}) => {
    const [exampleName, setExampleName] = useState(hiddenExampleData?.name ?? '')
    const [correctness, setCorrectness] = useState(hiddenExampleData?.correctness ?? "correct") // "correct" | "slightly wrong" | "medium wrong" | "very wrong"
    const [code, setCode] = useState(hiddenExampleData?.code ?? '')

    const updateName = (val) => {
        setExampleName(val)
    }

    function updateCode(newCode) {
        setCode(newCode)
    }

    function updateCorrectness(newCorrectness) {
        setCorrectness(newCorrectness)
    }

    useEffect(() => {
        editHiddenExample("name", exampleName)
        editHiddenExample("correctness", correctness)
        editHiddenExample("code", code)
    }, [exampleName, correctness, code])

    return (
        <div className="border rounded p-2 mb-3 gap-2">
            <div className="d-flex flex-row ">
                <input
                    type={"text"}
                    className="form-control mb-2"
                    placeholder="Example Name"
                    value={exampleName}
                    onChange={(e) => {
                        updateName(e.target.value)
                    }}
                />
                <button onClick={deleteHiddenExample} className="btn btn-light mb-2 ml-2" >
                    <FaTrash />
                </button>
            </div>
            

            <b className="mt-2">Example correctness:</b>
            <DropdownButton title={correctness} variant="light">
                <Dropdown.Item onClick={() => updateCorrectness("correct")}>
                    correct
                </Dropdown.Item>
                <Dropdown.Item onClick={() => updateCorrectness("slightly wrong")}>
                    slightly wrong
                </Dropdown.Item>
                <Dropdown.Item onClick={() => updateCorrectness("medium wrong")}>
                    medium wrong
                </Dropdown.Item>
                <Dropdown.Item onClick={() => updateCorrectness("very wrong")}>
                    very wrong
                </Dropdown.Item>
            </DropdownButton>
            
            <b className="mt-2">Example code:</b>
            <MonacoTipTap
                mode="python"
                value={code}
                onChange={(e) => updateCode(e)}
                readOnly={false}
                style={{ width: "100%" }}
            />
        </div>
    )
}

import { useCourseId } from "hooks/router/useUrlParams"
import styled from "styled-components"
import { signOut, getAuth } from "firebase/auth";
import { useNavigate } from "react-router";




export const WaitPage = () => {
    const courseId = useCourseId()
    const auth = getAuth()
    const navigate = useNavigate()

    const signOutAndNav = () => {
        signOut(auth).then(() => {
            navigate(`/${courseId}`)
        })
    }


    return (
        <div className="d-flex justify-content-center align-items-center flex-column">

        <StanfordLogo
            src={process.env.PUBLIC_URL + "/stanford.png"}
            alt="Stanford Logo"
            />
        <h1>Code in Place</h1>
            <h1>Thank you for signing up {courseId}</h1>
            <h2>You will be added to the course shortly</h2>
            <p>If you are using the wrong email account, <button className="btn btn-sm btn-primary" onClick={()=>signOutAndNav()}>Sign Out</button> and try signing in with a different email.</p>
        </div>
    )

}

const StanfordLogo = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  flex-grow:1;
  max-height:300px;
`;

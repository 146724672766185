export function getChapterLocation(textbookData, chapterId) {
  const outline = textbookData.outline;
  for (let i = 0; i < outline.length; i++) {
    const section = outline[i];
    for (let j = 0; j < section.chapters.length; j++) {
      const chapter = section.chapters[j];
      if (chapter.url === chapterId) {
        return [i, j];
      }
    }
  }
  return null;
}
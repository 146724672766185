import { getAuth } from "firebase/auth";
import { useRoomMembers } from "../../firebase/realtime/Rooms";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const PeopleInRoom = (props) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const { courseId, roomId } = useParams()

  const roomMemberInfo = useRoomMembers(courseId, roomId)

  if (roomMemberInfo === undefined) return null;

  const uidToDisplayName = roomMemberInfo?.uidToDisplayName
  const uidToPresenceData = roomMemberInfo?.uidToPresenceData

  if (uidToDisplayName === undefined || uidToPresenceData === undefined) return null;

  // filter to only the uids that are connected (including you - how fun!)
  const uidsWithPresenceData = Object.keys(uidToPresenceData).filter((id) => uidToPresenceData[id]?.status === 'connected') //; && id != user.uid);


  const renderStateFromPresenceData = (presenceData) => {
    if (presenceData === undefined) {
      console.error("Error: Presence data is undefined");
      return null;
    }
    const status = presenceData.status;
    let meetingViewState = presenceData?.data?.meetingViewState;

    if (meetingViewState === undefined) {
      console.log("Error: meetingViewState is undefined")
      return 'prejoin';
    } else if (meetingViewState === 'prejoin') {
      return 'prejoin';
    } else if (presenceData.isIdle) {
      return 'idle';
    } else if (meetingViewState === 'colab') {
      return 'colab';
    } else {
      console.error("Error: User is in an invalid state")
    }
  }

  const usersInRoom = uidsWithPresenceData.map((uid) => {
    return {
      uid: uid,
      displayName: uidToDisplayName[uid],
      state: renderStateFromPresenceData(uidToPresenceData[uid]),
      lastSeen: new Date(uidToPresenceData[uid].timestampMS),
    }
  });

  return (
    <div
      style={{
        display: "flex",
        height: "24px",
        margin: "auto",
        marginRight: "10px"
      }}>
      {
        usersInRoom.map((userInfo, index) =>
          <UserBubble userInfo={userInfo} index={index} key={userInfo.uid} />)
      }
    </div>
  );
}


const UserBubble = ({ userInfo, index }) => {
  const { uid, displayName, state, lastSeen } = userInfo;

  const getColor = (uid) => {
    const colors = ['#0c9a70', 'rgb(253, 126, 151)', 'rgb(31, 108, 250)',];
    const idx = index % colors.length;
    return colors[idx];
  };

  // Function to get the first letter of the userid
  const getInitials = (name) => {
    if (name) {
      return name.charAt(0).toUpperCase();
    }
  }

  const initials = getInitials(displayName);

  const toolTipMessage = state === 'prejoin' ? "joining..." : state === "idle" ? "idle" : "active";

  const avatarStyle = {
    marginRight: "5px",
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    textAlign: "center",
    fontSize: "15px",
    backgroundColor: getColor(uid),
    color: "white",
    opacity: state === 'colab' ? 1 : 0.3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  return <>
    <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 100 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            {displayName} <div style={{ opacity: 0.7 }}>({toolTipMessage})</div>
          </Tooltip>
        )}
      >
        <div style={avatarStyle}>
         {initials}
       </div>
      </OverlayTrigger>
  </>

  // return (
  //   <OverlayTrigger
  //     placement="bottom"
  //     overlay={
  //       <Tooltip>
  //         <span>
  //           {displayName} <div style={{ opacity: 0.7 }}>({toolTipMessage})</div>
  //         </span>

  //       </Tooltip>
  //     }
  //   >
  //     <div style={avatarStyle}>
  //       {initials}
  //     </div>
  //   </OverlayTrigger>
  // )
};
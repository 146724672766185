import { CoursePageBodyContainer } from 'components/layout/CoursePageBodyContainer';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaCopy, FaClipboard } from "react-icons/fa"
import { Link } from "react-router-dom";
import { useCourseId } from "../../hooks/router/useUrlParams";
import { CopyButton } from "../../components/reusableButtons/CopyButton"
import { SectionButton } from "../../components/reusableButtons/SectionButton"
import {
  getSectionTimeStrNoHook,
  getLocalUTCTimezone,
} from "components/timezones/timezoneHelper";
import { PopoverTable } from './PopoverTable'
import { useContext, useEffect, useState } from 'react';
import { CourseContext } from 'contexts/CourseContext';

const TOTAL_COURSE_WEEKS = 6 // constant for now, but should maybe be some sort of global state somewhere?

export const AttendanceTable = ({ sectionCollection, attendanceData, slNames}) => {
  const userTz = getLocalUTCTimezone()
  const courseId = useCourseId()
  const { sectionTimeDelta, firstSectionTimestamp, getNextSectionIndex, getFirstDayOfSection } = useContext(CourseContext)
  // figure out which week of the course we are on, 
  // in other words what section number (e.g. the second section -> section 2) is this week
  const [sectionNumber, setSectionNumber] = useState(-1)
  useEffect(()=> {
    if (!firstSectionTimestamp || !sectionTimeDelta) return
    setSectionNumber(getNextSectionIndex(userTz, sectionTimeDelta) + 1)
  },[firstSectionTimestamp])

  const columns = [{
    dataField: 'sectionId',
    text: 'Section ID',
    },{
    dataField: 'sectionPage',
    text: 'Section Page',
  }, {
    dataField: 'timeIndex',
    text: 'Time',
    formatter: (cell, row) => getSectionTimeStrNoHook(userTz, cell, getFirstDayOfSection),
    sort: true
  }, {
    dataField: 'sectionLeaderUID',
    text: 'SL UID',
  }, {
    dataField: 'displayName',
    text: 'SL Name',
  }, {
    dataField: 'totalAttendance',
    text: 'Total Attendance',
    formatter: attendanceDetailsFormatter,
    sort: true,
  }, {
    dataField: 'totalCovers',
    text: 'Covers by this SL',
    sort: true,
  },{
    dataField: 'manage',
    text: 'Manage'
  }]

  const attendanceRows = []
  let coverData = {}
  if (!sectionCollection) return <></>
  for (const sectionDoc of sectionCollection.docs) {
    // make row (other than covers)
    const row = makeTableRow(
      sectionDoc, 
      sectionNumber, 
      slNames[sectionDoc.id], 
      attendanceData[sectionDoc.id],
      courseId)
    if (row) {
      attendanceRows.push(row)
    }

    // get covers
    const section = sectionDoc.data()
    if (section?.covers) {
      for (let coverWeek in section.covers) {
          // increment the cover count for the SL who covered this section
          const coverer = section.covers[coverWeek].userId
          if (!(coverer in coverData)) {
            coverData[coverer] = 0
          }
          coverData[coverer] += 1
      }
    }
  }

  // update covers for each row
  for (const attendanceRow of attendanceRows) {
    const slId = attendanceRow.sectionLeaderUID.props.toCopy
    attendanceRow.totalCovers = coverData[slId] ?? 0
  }
  return <BootstrapTable 
    bordered={false} 
    striped={true} 
    hover={true} 
    bootstrap4={true} 
    keyField='id' 
    data={attendanceRows} 
    columns={columns} />
}

const makeTableRow = (sectionDoc, sectionNumber, slName, attendanceDocs, courseId) => {
  const sectionId = sectionDoc.id
  const section = sectionDoc.data();
  const name = section?.name ?? 'Unknown'
  const sectionLeaderId = section?.sectionLeader ?? ''
  const slRSVP = section?.sectionLeaderRSVP ?? {}
  let totalAttendance = 0
  if(!section.isActive) {
    return null
  }

  let timeIndex = section?.timeIndex ?? ''
  // try and convert it to a number, otherwise make it -1
  timeIndex = Number(timeIndex)
  // skip sections without a time
  if (timeIndex !== timeIndex) {
    return null
  }

  // make rows for attendance details table, which is only visible when this section's Total Attendance button is clicked
  let attendanceDetails = []

  // for each week of the course so far, fetch data
  for (let i = 1; i <= sectionNumber; i++) {

    // so that we don't add a bunch more rows after the course ends!
    if (i > TOTAL_COURSE_WEEKS){break}

    // did this SL RSVP to section?
    let rsvpStatus = '?'
    if (i in slRSVP) {
      rsvpStatus = slRSVP[i] ? 'y' : 'n'
    }

    // did some other SL cover this section?
    let sectionCoverStatus = 'n'
    if (section?.covers) {
      if (i in section.covers) {
        sectionCoverStatus = 'y'
      }    
    }

    // did this SL attend this section?
    let attendanceStatus = '?'
    let attendees = -1
    if (attendanceDocs && attendanceDocs[i-1]) {
      if (sectionLeaderId in attendanceDocs[i-1]) {
        attendanceStatus = 'y'
        totalAttendance += 1
      } else {
        attendanceStatus = 'n'
      }
      attendees = Object.keys(attendanceDocs[i-1]).length
    }
    
    const detailsRow = {
      'id': sectionId,
      'week': i,
      'rsvpstatus': rsvpStatus,
      'attendancestatus': attendanceStatus,
      'coverstatus': sectionCoverStatus,
      'attendees': attendees
    }
    attendanceDetails.push(detailsRow)
    
  }

  // make row for this section
  let row = {
    id: sectionId,
    sectionId: <CopyButton toCopy={sectionId} icon={<FaCopy />} />,
    sectionPage: <SectionButton name={name} sectionId={sectionId}/>,
    sectionLeaderUID: <CopyButton toCopy={sectionLeaderId} icon={<FaCopy />} />,
    displayName: slName,
    timeIndex,
    totalAttendance,
    attendanceDetails,
    manage: 
            <Link to={`/${courseId}/manage/${sectionId}`} className="btn btn-light btn-sm">
              <FaClipboard /> 
            </Link>
  }

  return row
}

const attendanceDetailsFormatter = (cell, row) => {
  // format the cell into a popover with attendance details
  // this formatter fn allows us to still sort the totalAttendance col by totalAttendance
  return <PopoverTable
    totalAttendance = {cell}
    attendanceDetails = {row.attendanceDetails}
  />
}
/*
* Last Modified: 1/26/24
* TJ Jefferson
* Changing data models so that Drafts are a seperate collection from posts
*/


import React, { useContext, useRef, useState } from "react";
import { PostViewer } from "../../components/PostViewer/PostViewer";
import { PostList } from "../../components/PostList/PostList";
import { PostSplash } from "../../components/SplashPages/PostSplash/PostSplash";
import {
  deleteDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./forum.css";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";

import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "react-bootstrap";
import { ForumSearch } from "../../components/ForumSearch/ForumSearch";
import { useCourseId } from "../../../../hooks/router/useUrlParams";
import { FaRocket, FaSearch } from "react-icons/fa";
import { ForumContext, ForumType } from "course/forum/ForumContext";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { useComponentSize } from "react-use-size";
import Gate from "contexts/Gate";
import { ProfileContext } from "contexts/ProfileContext";
import { useIsMobile } from "utils/general";
import { DraftViewer } from "course/forum/components/PostViewer/DraftViewer";
import { FilterModal } from "course/forum/components/PostList/FiltersModal";
import { FiltersProvider } from "course/forum/FiltersContext";
import { PostProvider } from "./PostContext";
import styled from "styled-components";
import { TimeContext } from "contexts/TimeContext";

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Forum() {
  const dataFetcher = useForumDataFetcher();
  const { forumType, forumId } = useContext(ForumContext);
  const { userData } = useContext(ProfileContext);
  const pageSize = useComponentSize();
  const isSlOrAbove = Gate.hasSectionLeaderRole(userData);

  const queryParams = useQueryParams();
  const postSearchParam = queryParams.get("post");
  const draftSearchParam = queryParams.get("draft");
  const isDraft = !!(draftSearchParam)
  const currentPostId = isDraft ? draftSearchParam : postSearchParam;
  const { getServerTimeMs } = useContext(TimeContext)
  const auth = getAuth(getApp());
  const [user, loading, error] = useAuthState(auth);

  const [showSearchModal, setShowSearchModel] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();


  /**
   * 
   * @param isPrivate 
   * @param isPinned 
   */
  const createPostDraft = async (
    isPrivate: boolean = false,
    isPinned: boolean = false,
  ) => {
    const postUuid = uuidv4();
    const newPost = {
      author: user?.displayName || "Anonymous",
      authorUid: user.uid,
      id: postUuid,
      title: "",
      time: new Date(getServerTimeMs()),
      contents: { text: "", html: "" },
      isResolved: false,
      isPrivate,
      isPinned,
      isFlagged: false,
      isPosted: false,
    };
    await setDoc(dataFetcher.get_draft_doc_ref(postUuid), newPost);
    navigate({
      pathname: location.pathname,
      search: `draft=${postUuid}`,
    });
  };

  const openSearch = () => {
    setShowSearchModel(true);
  };

  const closeSearch = () => {
    setShowSearchModel(false);
  };

  const deletePost = async (uid: string) => {
    // Delete the draft post
    try { (await deleteDoc(dataFetcher.get_draft_doc_ref(uid))) } catch (e) { }
    // Try to delete the public post, if it exists
    try { (await deleteDoc(dataFetcher.get_post_doc_ref(uid))) } catch (e) { }

    navigate({ pathname: location.pathname, search: "" });
  };

  // this boolean represents if you need to show the
  // version of the forum where the post list is full page
  const isBroken = useIsMobile();

  // collect the props so that the broken view, and the standard
  // view can have the same information
  const forumProps = {
    openSearch,
    createPostDraft,
    deletePost,
    forumType,
    currentPostId,
    isDraft
  };


  return (
    <>
      <FiltersProvider>
        <PostProvider>
          <ForumOuter ref={pageSize.ref}>
            {!isBroken ? (
              <StandardView {...forumProps} />
            ) : (
              <BrokenView {...forumProps} />
            )}
          </ForumOuter>

          <Modal
            show={showSearchModal}
            onHide={closeSearch}
            size="xl"
            contentClassName="searchContent"
          >
            <ForumSearch clickHandler={closeSearch} />
          </Modal>
          <FilterModal isSlOrAbove={isSlOrAbove} />
        </PostProvider>
      </FiltersProvider>
    </>
  );
}

const ForumOuter = styled.div`
  width: 100%;
  height: 100%;
`

const BrokenView = (props: {
  openSearch: () => void;
  createPostDraft: () => void;
  deletePost: (uid: string) => void;
  forumType: ForumType;
  currentPostId: string | null;
  isDraft: boolean;
}) => {
  const { openSearch, createPostDraft, deletePost, forumType, currentPostId, isDraft } =
    props;

  const navigate = useNavigate();
  const goToForumHome = () => {
    navigate({ search: "" });
  };


  // if currentPostId is null, then we are on the forum splash page
  if (currentPostId === null) {
    // width of the postList should now be 100% and we should remove margins
    return (
      <div className="pageContainerNoMargins">
        <div className="postList w-100 h-100" >
          <div className="postListHeader">
            <AskQuestion createPost={createPostDraft} forumType={forumType} />
            <Search openSearch={openSearch} />
          </div>
          <PostList
            currentPostId={currentPostId}
          />
        </div>
      </div>
    );
  }

  // if currentPostId is not null, then we are on the post viewer page
  return (
    <MobileViewOuter>
      <button onClick={() => goToForumHome()} className="btn btn-light mt-2">
        Back to Forum
      </button>
      {isDraft ? <DraftViewer draftId={currentPostId} /> : <PostViewer postId={currentPostId} />}
    </MobileViewOuter>
  );
};

const MobileViewOuter = styled.div`
  padding-left: 10px;
  margin: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
`;

const StandardView = (props: {
  openSearch: () => void;
  createPostDraft: () => void;
  deletePost: (uid: string) => void;
  forumType: ForumType;
  currentPostId: string | null;
  isDraft?: boolean;
}) => {
  const { openSearch, createPostDraft, deletePost, forumType, currentPostId, isDraft } =
    props;


    const { isReporting } = useContext(ForumContext);

  return (
    <div>
      <div className="forum">
        <div className="postList">
          <div className="postListHeader">
            <AskQuestion createPost={createPostDraft} forumType={forumType} />
            {isReporting ? null : <Search openSearch={openSearch} />}
          </div>
          <PostList
            currentPostId={currentPostId}
          />
        </div>

        {currentPostId ? (
          isDraft ? <DraftViewer draftId={currentPostId} /> : <PostViewer postId={currentPostId} />
        ) : (
          <PostSplash createPost={createPostDraft} />
        )}
      </div>
    </div>
  );
};

const AskQuestion = (props: {
  createPost: (isPrivate: boolean) => void;
  forumType: ForumType;
}) => {
  const { createPost, forumType } = props;
  const { userData, loading: userIsLoading } = useContext(ProfileContext);

  const navigate = useNavigate();
  const courseId = useCourseId();
  const { sectionId } = useParams();

  switch (forumType) {
    case "Course":
      return (
        <Button
          className="btn btn-primary w-100 mr-1"
          onClick={() => createPost(false)}
        >
          <FaRocket className="mr-1" /> New Post
        </Button>
      );
    case "Section":
      return (
        <div>
          <div>
            {!Gate.hasSectionLeaderRole(userData) && (
              <Button
                className="btn btn-primary w-100 m-1"
                onClick={() => createPost(true)}
              >
                Message your section leader
              </Button>
            )}
            {Gate.hasSectionLeaderRole(userData) && (
              <Button
                className="btn btn-primary w-100 m-1"
                onClick={() =>
                  navigate(`/${courseId}/section/${sectionId}/email`)
                }
              >
                Email your section
              </Button>
            )}
            <Button
              className="btn btn-primary w-100 m-1"
              onClick={() => createPost(false)}
            >
              Message the section
            </Button>
          </div>
        </div>
      );
    case "Reporting":
      return (
        <Button
          className="btn btn-danger w-100 m-1"
          onClick={() => createPost(true)}
        >
          <FaRocket className="mr-1" /> New Report
        </Button>
      );
  }
};

const Search = ({ openSearch }) => {




  return (
    <button onClick={openSearch} className="btn btn-primary ml-1">
      <label className="visually-hidden">Click to search forum</label>
      <FaSearch />
    </button>
  );
};

import { useContext } from "react";
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { useParams } from "react-router-dom"
import { CourseContext } from "contexts/CourseContext";

const LINK_TO_SECTION1_ASSN = "cip3/ide/a/hospital"

function getSectionWeek() {
  const { sectionWeek } = useParams()
  const { nCourseWeeks } = useContext(CourseContext)
  // check if sectionWeek is one of 1,2,3,4,5,6
  if (parseInt(sectionWeek) <= nCourseWeeks) {
    return sectionWeek
  } else {
    throw new Error("Invalid section week")
  }
}


export const CIPSectionHandout = () => {
  // depricated style. use HandoutPage for similar pages
  const Content = () => {
    const sectionWeek = getSectionWeek()
    const { courseId } = useParams()
    return <div style={{ marginTop: 20 }}><AdminRichTextEditor firebaseDocPath={`/course/${courseId}/handouts/section/${sectionWeek}/handout`} />
    </div>
  }

  return (
    <CoursePageBodyContainer
      mainColumn={<Content />}
      rightColumn={<></>}
      singleColumn={<Content />}
    />
  )
}

export const CIPSectionSoln = () => {
  // depricated style. use HandoutPage for similar pages
  const sectionWeek = getSectionWeek()
  const { courseId } = useParams()

  const Content = () => {
    return <div style={{marginTop:20}}><AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/section/${sectionWeek}/solution`} /></div>
  }

  return (
    <CoursePageBodyContainer
      mainColumn={<Content />}
      rightColumn={<></>}
      singleColumn={<Content />}
    />
  )
}


export const CIPLessonPlan = () => {
  // depricated style. use HandoutPage for similar pages
  const sectionWeek = getSectionWeek()
  const { courseId } = useParams()

  const Content = () => {
    return <div style={{marginTop:20}}><AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/section/${sectionWeek}/lessonplan`} /></div>
  }

  return (
    <CoursePageBodyContainer
      mainColumn={<Content/>}
      rightColumn={<></>}
      singleColumn={<Content/>}
    />
  )
}
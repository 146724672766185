import { useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { getDoubleValue } from 'course/editors/OnEventAlerts';

export const EditableLinkList = ({ currValue, setItemValue }) => {

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = currValue.findIndex(item => item.id === active.id);
      const newIndex = currValue.findIndex(item => item.id === over.id);
      const newArray = arrayMove(currValue, oldIndex, newIndex);
      setItemValue(newArray);
    }
  };

  const handleAddItem = async () => {
    const newValueRaw = await getDoubleValue('Edit List Item', 'Title', 'Url',false, '', '');
    if(!newValueRaw) return;
    const newValue = {title: newValueRaw.item1, url: newValueRaw.item2}
    if(!newValue.title || !newValue.url) return;
    const newItemObj = { id: Date.now().toString(), value: newValue};
    setItemValue([...currValue, newItemObj]);
  };

  const handleDeleteItem = (id) => {
    console.log('delete', id)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setItemValue(currValue.filter(item => item.id !== id));
      }
    });
  };

  const handleEditItem = async (id) => {
    const itemIndex = currValue.findIndex(item => item.id === id);
    if (itemIndex === -1) return; // Item not found

    const oldValue = currValue[itemIndex].value;
    const newValueRaw = await getDoubleValue('Edit List Item', 'Title', 'Url', false, oldValue.title, oldValue.url);
    if(!newValueRaw) return;
    const newValue = {title: newValueRaw.item1, url: newValueRaw.item2}

    if(!newValue.title || !newValue.url) return;
    const updatedItems = [...currValue];
    updatedItems[itemIndex] = { ...updatedItems[itemIndex], value: newValue };

    setItemValue(updatedItems);
  };


  return (
    <div>
      <div className="input-group mb-3">
        
          <div className="input-group-append">
            <button 
              className="btn btn-outline-secondary" 
              type="button"
              onClick={handleAddItem}
            >Add Item</button>
          </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext
          items={currValue.map(item => item.id)}
          strategy={verticalListSortingStrategy}>
          {currValue.map((item, index) => {
            console.log(item, index)
            return <DraggableItem
              key={item.id}
              id={item.id}
              value={item.value}
              handleDeleteItem={() => handleDeleteItem(item.id)}
              handleEditItem={() => handleEditItem(item.id)}
            />
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

const DraggableItem = ({ id, value, handleDeleteItem, handleEditItem }) => {
  const [editedValue, setEditedValue] = useState(value);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return <div className='d-flex justify-content-between' >
    <div key={id} ref={setNodeRef} {...attributes} {...listeners} style={style} >
      <a href={value.url} target='_blank'>{value.title}</a>
    </div>
    <div>
      <RowButtons onDelete={handleDeleteItem} onEdit={handleEditItem} />
    </div>
  </div>
};

const RowButtons = (props) => {
  const {
    onDelete,
    onEdit
  } = props

  return (
    <span className='d-flex'>
      <button
        onClick={onDelete}
        className='btn  btn-sm '
      >
        <FaTrash />
      </button>
      <button
        className='btn  btn-sm '
        onClick={onEdit}
      >
        <FaEdit />
      </button>


    </span>
  )

}
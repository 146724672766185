import Flags from "country-flag-icons/react/3x2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import COUNTRY_CODE_TO_NAMES from "../../data/countries/country_code_to_names"
import FaKarel from "../pyodide/KarelLib/images/classic/karelEast.png";

export const LocationInfo = ({country, height=12}) => {
  // country is the 2-letter code
  if (country) {
    return (
      <span>
        <FlagWithOverlay country={country} height={height}/>
      </span>
    );
  } else {
    return <span></span>;
  }
};

export const FlagWithoutOverlay = ({ country, height }) => {
  if(!country) return null;
  const isRussia = country == "RU";
  const isCIP = country == "CIP";
  const displayHeight = height ? height : 12
  const Flag = Flags[country];
  const width = height * 1.5;
  // if(isRussia){
  //   return <span style={{display: "inline-block", border: "1px solid darkgrey", width, height, backgroundColor: "white"}}></span>
  // }
  if(isCIP){
    return <img src={FaKarel} style={{border: "0.2px solid lightgray", width: width, height: height, objectFit: 'contain'}}/>
  }
  return <Flag style={{ border: "0.2px solid lightgray" }} height={displayHeight } />
}
  

export const FlagWithOverlay = ({ country, height }) => {
  if(!country) return null;
  const isRussia = country == "RU";
  const isCIP = country == "CIP";
  const displayHeight = height ? height : "12";
  const Flag = Flags[country];
  const width = height * 1.5;

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {COUNTRY_CODE_TO_NAMES[country].eng_name}
        </Tooltip>
      )}
    >
      <span>
        <FlagWithoutOverlay country={country} height={height}/>
      </span>
    </OverlayTrigger>
  );
  //     return <OverlayTrigger
  //     placement="bottom"
  //     delay={{ show: 0, hide: 0 }}
  //     overlay={(props) => (
  //       <Tooltip id="button-tooltip" {...props}>
  //         {country.eng_name}
  //       </Tooltip>
  //     )}
  //   >
  //     <><Flag height="12"/>!!</>
  //   </OverlayTrigger>
};

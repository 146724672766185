import { getFirestore, collection, getDocs, doc, updateDoc, setDoc } from "firebase/firestore"
import { useEffect, useState, useContext } from "react"
import { alertError } from "components/completion/Alerts"
import { timezones } from "./utils"
import Swal from "sweetalert2"
import { isValidId } from "../OnEventAlerts"
import { useParams } from "react-router"
import { SortableMenuItems } from "./SideBarEditor"
import { Form } from "react-bootstrap"
import { ProfileContext } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import { CourseContext } from "contexts/CourseContext"



export const AdminEditor = () => {
    const { userData } = useContext(ProfileContext)
    const isAdmin = Gate.hasAdminRole(userData)
    if (!isAdmin) {
      return <></>
    }
    const db = getFirestore()
    const [courseId, setCourseId] = useState(useParams().courseId)
    const [courseMap, setCourseMap] = useState({})
    const [loading, setLoading] = useState(false)
    const [isStudentFeatures, setIsStudentFeatures] = useState(true)



    const studentFeatures = [
        'home',
        'code-pages',
        'learn',
        'section',
        'forums',
        'stories',
        'events',
        'chatgpt',
        'aboutcourse',
        'connections',
        'grades'
      ]

    const slFeatures = [
        'home',
        'training',
        'section',
        'studenthome',
        'code-pages',
        'learn',
        'forums',
        'stories',
        'events',
        'chatgpt',
        'aboutcourse',
        'connections',
        'grades'
      ]


    useEffect(() => {
        const getCourseDetails = async () => {
            const coursesRef = collection(db, 'course')
            const coursesSnapshot = await getDocs(coursesRef)
            const courseMap = {}
            coursesSnapshot.docs.forEach(course => {
                courseMap[course.id] = course.data()
            })
            for(var key in courseMap) {
                if(!courseMap[key].timezone) {
                    courseMap[key].timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            }
            setCourseMap(courseMap)
        }
        getCourseDetails();
    }, [])


    const updateCourse = async () => {
        setLoading(true)
        const courseDocRef = doc(db, `course/${courseId}`)
        try {
            await updateDoc(courseDocRef, courseMap[courseId])
        } catch (error) {
            console.log(error)
            alertError(error.message)
        }
        setLoading(false)
        Swal.fire(
            'Success!',
            'Course Updated',
            'success'
        )
    }


    const createNewCourse = async () => {
        // Ask for new courseCode in input
        const result = await Swal.fire({
            title: 'Enter the new course code',
            input: 'text',
            inputLabel: 'Course Code',
            inputPlaceholder: 'Enter the new course code',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter a course code!'
                }
                else if (!isValidId(value)) {
                    return 'Course code must be alphanumeric and lowercase!'
                }   
            }
        })
        


        if(!result.isConfirmed) {
            return;
        }
        const newCourseId = result.value
        const confirmation = await Swal.fire(
            'Are you sure?',
            'This will create a new course with the code: ' + newCourseId,
            'warning',
        )
        if(!confirmation.isConfirmed) {
            return;
        }
        const courseDocRef = doc(db, 'course', newCourseId)
        try {
            await setDoc(courseDocRef, {
                code: newCourseId,
                published: false
            })

            setCourseMap(prevCourseMap => {
                return {
                    ...prevCourseMap,
                    [newCourseId]: {
                        code: newCourseId,
                        published: false
                    }
                }
            })
            setCourseId(newCourseId)
        } catch (error) {
            alertError(error)
        }

    }


    const editCourseState = (key, value) => {
        setCourseMap(prevCourseMap => {
            return {
                ...prevCourseMap,
                [courseId]: {
                    ...prevCourseMap[courseId],
                    [key]: value
                }
            }
        }
        )
    }

    const publishCourse = async () => {
        const isPublished = courseMap[courseId]?.published ;
        let result;
        if(isPublished) {
            result = await Swal.fire({
                title: 'Are you sure?',
                text: "Students and staff will no longer be able to access the course page!",
                icon: 'warning',
            });

        } else {
            result = await Swal.fire({
                title: 'Are you sure?',
                text: "This will make the course page public, and viable to students!",
                icon: 'warning',
            });
        }
        if(result.isConfirmed) {
            const courseDocRef = doc(db, `course/${courseId}`)
            try {
                await updateDoc(courseDocRef, {published: !isPublished})
                editCourseState("published", !isPublished)
            } catch (error) {
                alertError(error.message)
            }
        }

    }


    const getFeatures = () => {
        const critFeatures = isStudentFeatures ? studentFeatures : slFeatures;
        return (<>{
            critFeatures.map(feature => { 
                return <FeaturesSelect 
                toggleFeature={(checked) => {
                    const listString = isStudentFeatures ? "features" : "slFeatures";
                    let existingFeatures;
                    if(isStudentFeatures) {
                        existingFeatures = courseMap[courseId]?.features || [];
                    } else {
                        existingFeatures = courseMap[courseId]?.slFeatures || [];
                    }
    
                    if(! checked) {
                        editCourseState(listString, existingFeatures.filter(f => f !== feature))
                    } else {
                        editCourseState(listString, [...existingFeatures, feature])
                    }
                }}
                feature={feature}
                checked={(isStudentFeatures ? courseMap[courseId]?.features?.includes(feature) : courseMap[courseId]?.slFeatures?.includes(feature)) ?? false}
                />
            })
        }</>)

       
    }




    return (
        <div>
            <h1 className="m-3">Code In Place</h1>
            <h2 className="m-3">Course Admin Panel</h2>

            <button className="btn border m-3" onClick={createNewCourse}>Create New Course</button>
            {/* A form that accepts a Code, name, start date, end date, type, and  timezone*/}
            <select className="rounded border p-2" value={courseId} onChange={(e) => { setCourseId(e.target.value)}}>
                {Object.keys(courseMap).map(courseId => <option key={courseId} value={courseId}>{courseId}</option>)}
            </select>
            <div className="border rounded m-3 p-3">
                <button className="btn btn-danger" onClick={publishCourse}>{courseMap[courseId]?.published ? "Unpublish" : "Publish"}</button>
                <br/>
                <label className="mt-1">Course Code</label>
                <input className="form-control m-1 text-muted" type="text" name="Course Code" value={courseId} readOnly  />
                <label className="mt-1">Course Name</label>
                <input className="form-control m-1" type="text" name="Course Name" value={courseMap[courseId]?.name ?? ""} onChange={(e) => editCourseState("name", e.target.value)} />
                <label className="mt-1">Start Date</label>
                <input className="form-control m-1" type="date" name="Start Date" value={courseMap[courseId]?.startDate ?? ""} onChange={(e) => editCourseState("startDate", e.target.value)} />
                <label className="mt-1">End Date</label>
                <input className="form-control m-1" type="date" name="End Date" value={courseMap[courseId]?.endDate ?? ""} onChange={(e) => editCourseState("endDate", e.target.value)} />
                <label className="mt-1">Home Page Video ID</label>
                <input className="form-control m-1" type="text" name="End Date" value={courseMap[courseId]?.videoId ?? ""} onChange={(e) => editCourseState("videoId", e.target.value)} />
                <label className="mt-1">Course Type</label>
                <select className="form-control m-1" value={courseMap[courseId]?.type ?? "live"} onChange={(e) => editCourseState("type", e.target.value)}> 
                    <option value="asynchronous">Asynchronous</option>
                    <option value="live">Live</option>
                </select>
                <label className="mt-1">Minimum Role</label>
                <select className="form-control m-1" value={courseMap[courseId]?.minRole} onChange={(e) => editCourseState("minRole", e.target.value)}> 
                    <option value="">Unregistered/No Role</option>
                    <option value="student">Student</option>
                    <option value="sl">SL</option>
                    <option value="ta">TA</option>
                    <option value="admin">Admin</option>
                    <option value="instructor">Instructor</option>
                </select>
                <label className="mt-1" >Timezone</label>
                <select className="form-control m-1" value={courseMap[courseId]?.timezone ?? "live"} onChange={(e) => editCourseState("timezone", e.target.value)}> 
                {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
                </select>
                <hr/>
                <AuthAdder courseMap={courseMap[courseId]}  editCourseState={editCourseState}/>
                <hr/>
                <h3>Features</h3>
                <Form.Switch className="mt-1" label={`Editing ${isStudentFeatures ? "Student" : "Staff"} feature list`} checked={isStudentFeatures} onChange={(e) => setIsStudentFeatures(!isStudentFeatures)} />
                <div>
                    <div className="row">
                        {getFeatures()}
                    </div>
                </div>
                <h3>Customize {isStudentFeatures? "Student" : "Staff"} Sidebar</h3>
                <SortableMenuItems menuList={isStudentFeatures ? courseMap[courseId]?.features : courseMap[courseId]?.slFeatures} setMenuList={(newList => {let featureKey = isStudentFeatures ? "features" : "slFeatures"; editCourseState(featureKey, [...newList])})} courseId={courseId} isSl={false} />
                <button className="btn btn-success m-3"  type="button" onClick={() => updateCourse()} disabled={loading} >Update Course</button>
            </div>
        </div>
    )
}



const FeaturesSelect = ({toggleFeature, feature, checked}) => {

    return (
        <div className="ml-3 col-4" key={feature}>
            <input className="form-check-input" 
                type="checkbox" 
                checked={checked} 
                onChange={(e) => toggleFeature(e.target.checked)}
            />
        <label className="form-check-label">{feature}</label>
    </div>
    )
}





const AuthAdder = ({courseMap, editCourseState}) => {
    const [editiableCanvasAuthLink, setEditableCanvasAuthLink] = useState("")

    useEffect(() => {
        if(!courseMap || ! courseMap.canvasAuthLink) {
            return
        }
        setEditableCanvasAuthLink(courseMap.canvasAuthLink)
    }, [courseMap])

    useEffect(() => {
        editCourseState("canvasAuthLink", editiableCanvasAuthLink)
    }, [editiableCanvasAuthLink])

    return (
        <div>
            <h3>Configure additional authentication methods</h3>
            <label>Canvas Auth Link</label>
            <input className="form-control" value={editiableCanvasAuthLink}  onChange={
                (e) => {
                    setEditableCanvasAuthLink(e.target.value)
                }
            }/>
        </div>
    )
}
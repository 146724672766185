import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, doc, getDoc, getDocs, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2"
import { useState } from "react"


export const DisbandSectionButton = ({ sectionId, courseId, className=''}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [disbandIsLoading, setDisbandIsLoading] = useState(false);
  const functions = getFunctions()
  const disbandSectionFirebase = httpsCallable(functions, 'disbandSection')
  const onDisband = async() => {
    setDisbandIsLoading(true)
    try{
      const res = await disbandSectionFirebase({sectionId, courseId})
      // @ts-ignore
      if (!!res && res.data.success){
        Swal.fire('Section disbanded!', `Section ${sectionId} has been disbanded.`, 'success')
        handleClose()
        setDisbandIsLoading(false)
        return
      }
      else{
        // @ts-ignore
        throw new Error(res.data.reason)
      }
    } catch (e) {
      Swal.fire('Something went wrong!', `Could not disband section ${sectionId}. ${e.message}`, 'error')
      handleClose()
      setDisbandIsLoading(false)
      return
    }
  }

  return (
    <>
      <Button className={className} variant="danger" onClick={handleShow} >Disband</Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Heads up!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are about to disband section {sectionId}, which is an irreversible action. These students will be automatically distributed into other sections at the same time. Are you really sure you want to disband this section?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={onDisband} disabled={disbandIsLoading}>
            {disbandIsLoading ? "Disbanding in progress, please wait..." : "Yes, disband this section"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

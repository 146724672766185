import { useEffect, useState } from "react";

export const Breakpoints = {
  XS: "(min-width: 470px)",
  SM: "(min-width: 640px)",
  TAB: "(min-width: 768px)",
  MD: "(min-width: 1024px)",
  LG: "(min-width: 1280x)",
  XL: "(min-width: 1536px)",
};

const useMediaQuery = (query, fallback) => {
  const [matches, setMatches] = useState(true);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [query]);

  return matches ?? fallback;
};

export default useMediaQuery;

// @ts-disable
import { useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions"
import { useState } from 'react';
import { FormixFormWithData, HandleSubmitFormixFormWithData, getFormErrorMessage } from "components/Forms/FormixFormWithData";
import * as Yup from "yup";
import { TextAreaInput, TextInput } from 'components/Forms/FormixComponents';
import Swal from "sweetalert2";

export const ViewRequestWidget = ({
    viewerId,
    connectionData, 
    userId, 
    userData,
    status,
    setStatus,
    setConnectionData,
    setToggle
}) => {
    const functions = getFunctions();
    const acceptConnectionRequest = httpsCallable(functions, "acceptConnectionRequest");
    const dismissConnectionRequest = httpsCallable(functions, "dismissConnectionRequest");
    const formRef = useRef();
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [dismissLoading, setDismissLoading] = useState(false)
    const [showErrors, setShowErrors] = useState(false)

    const acceptRequest = async (e) => {
        setAcceptLoading(true)
        const res = await acceptConnectionRequest({
            viewerId,
            userId,
            viewerId_msg: e.message,
            viewerId_contact: e.contact,
            request: connectionData,
            request_status: status // 'receivedRequest' or 'dismissedRequest'
        })
        // @ts-ignore
        if (res.data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'You are now connected!',
            })
            setStatus('connected')
            // @ts-ignore
            setConnectionData(() => {return res.data.newConnectionData})
            setToggle('')
            setAcceptLoading(false)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setAcceptLoading(false)
        }
    }

    const handleAccept = () => {
        if (formRef.current){
            // @ts-ignore
            const errors = formRef.current.errors
            if (errors && Object.keys(errors).length !== 0){
                // if there are errors, show them (e.g. fields unpopulated)
                setShowErrors(true)
            }
            // @ts-ignore
            formRef.current.submitForm()
        }
    }
    
    const dismissRequest = async () => {
        setDismissLoading(true)
        const res = await dismissConnectionRequest({
            viewerId,
            userId,
            request: connectionData,
        })
        // @ts-ignore
        if (res.data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Request dismissed!',
                text: 'Review it again at any time by navigating to this profile page.',
            })
            // no need to update connectionData, it should be the same request as before
            setStatus('dismissedRequest')
            setToggle('')
            setDismissLoading(false)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setDismissLoading(false)
        }
    }

    const MessageInput = ({name}) => {
        const description = <>
            Write back to {userData.displayName}!
        </>
        return <TextAreaInput
            label="Your Response"
            name={name}
            description={description}
            placeholder={`Nice to meet you! I'd like to learn more about...`}
            style={{backgroundColor: 'white'}}
        />
    }

    const ContactInfoInput = ({name}) => <TextInput
        label="Contact Info"
        description={<>We will only share this with {userData.displayName} if they accept your request.<br/>For your own privacy, we recommend sharing a Linkedin link or making an email alias.</>}
        name={name}
        type="text"
        placeholder="linkedin.com/in/your-username"
        style={{backgroundColor: 'white'}}
    />

    const formFormat = [
        {
            name: "message",
            input: MessageInput,
            default:'',
            validation: Yup.string()
            .required("Message is required"),
        },
        {
            name: "contact",
            input: ContactInfoInput,
            default:'',
            validation: Yup.string()
            .required("Contact info is required"),
        },
    ]

    return <>
        <Alert className='mt-3' style={{backgroundColor:"#EFF1FB"}}>
            <Alert.Heading>{userData.displayName} wants to connect!</Alert.Heading>
            <label className="form-label">Their Message</label>
            <p>
                {connectionData[`${userId}_msg`]}
            </p>
            <HandleSubmitFormixFormWithData
                ref={formRef}
                serverData={connectionData}
                backUrl={null}
                formFormat={formFormat}
                onSubmit={(e, cb) => acceptRequest(e)}
                showGlobalError={showErrors}
            />
            <hr />
            <div className="d-flex">
                <Button 
                    variant="success" 
                    className="mr-2" 
                    onClick={handleAccept} 
                    disabled={acceptLoading || dismissLoading}
                >
                    {acceptLoading ? 'Accepting...' : 'Accept'}
                </Button>
                <Button 
                    variant="secondary" 
                    onClick={dismissRequest} 
                    disabled={acceptLoading || dismissLoading}
                >
                    {dismissLoading ? 'Dismissing...' : 'Dismiss'}
                </Button>
            </div>
        </Alert>
    </>
}
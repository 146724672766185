import { getFirestore, doc, setDoc, Firestore, updateDoc, writeBatch} from 'firebase/firestore';
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { CollabSession } from '../Session';
import Swal from 'sweetalert2';
import * as Y from 'yjs';




/**
 * @class FirestoreLogger
 * @description handles code logging to firestore database
 */
export class FirestoreCodeLogger {
    private session: CollabSession;
    private db: Firestore;

    constructor(session: CollabSession) {
        this.session = session;
        this.db = getFirestore();
    }


    logCode(debouncedChanges: string, firestorePath: string, isSafe: boolean = false) {
            if(!firestorePath || debouncedChanges === null) { return; }
            // if the new changes are the same as the last code recieved, ignore them
            if(debouncedChanges === this.session.getLastUpdateCode()) { return; }

            const projectPath = firestorePath.split("/code/")[0];

            // copy ydoc
            let docArray = Y.encodeStateAsUpdateV2(this.session.ydoc);
            let docBlob =  firebase.firestore.Blob.fromUint8Array(docArray);

            // Send to firebase
            const fileDocRef = doc(this.db, firestorePath);
            const projectDocRef = doc(this.db, projectPath);
            try {
                const batch = writeBatch(this.db);
                const isFileLogger = this.isFileLogger();
                if(isFileLogger || isSafe) {
                    batch.update(projectDocRef, {
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                        ydoc: docBlob
                    });
                    batch.set(fileDocRef, {
                        content: debouncedChanges,
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
                        updatingClient: this.session.ydoc.clientID
                    }, {merge: true});
                    batch.commit();
                }
            } catch(e) {
                Swal.fire({
                    icon: "error",
                    title: "Failed to save to server",
                    position: "top-end",
                    text: e.message,
                  });
            }
    }





    isFileLogger() {
        // Check if client's id is lowest of all collaborators with the same fileId
        const userState = this.session.provider.awareness.getLocalState();
        if(!userState || !userState.file) { return false; }

        const allCollaboratorIds = this.session.provider.awareness.getStates().keys();
        const allCollaboratorIdsArray = Array.from(allCollaboratorIds);
        const clientId = this.session.ydoc.clientID;
        const fileId = userState.file;
        let lowestClientId = clientId;
        const states = this.session.provider.awareness.getStates();
        if(!states) { return true; }
        for(let id of allCollaboratorIdsArray) {
            const state = states.get(id)
            if(!state) { continue; }
            if(id < lowestClientId && state.file === fileId) {
                lowestClientId = id;
            }
        }
        if(lowestClientId === clientId) {
            return true;
        }

        return false;
    }

}
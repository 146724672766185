import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { getApp } from "firebase/app"
import { collection, getFirestore } from "firebase/firestore"
import { useCollectionDataOnce, useCollectionOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router-dom"

export const MonitorApplications = () => {
  return <CoursePageBodyContainer
      mainColumn={<MonitorAppsMain />}
      rightColumn={<></>}
      singleColumn={<MonitorAppsMain />}
    />
}

const MonitorAppsMain = ({  }) => {
  // ok to assume that the user is an admin
  // only admin can download apps from server
  // and only admin have a button to this page

  return <>
    <h3>Student Apps</h3>
    <DisplayAppProgress collectionId={"applications-student"}/>
    <h3>SL Apps</h3>
    <DisplayAppProgress collectionId={"applications-sl"}/>
  </>
}

const DisplayAppProgress = ({ collectionId }) => {
  const {targetCourseId} = useParams()
  const db = getFirestore(getApp())
  const path = `/course/${targetCourseId}/${collectionId}/`
  const [applications, loading, error] = useCollectionDataOnce(
    collection(db, path)
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const countMap = parseShards(applications)

  // sort the keys in the table alphabetically
  const sortedKeys = Object.keys(countMap).sort()
  
  // render the countMap in a bootstrap table
  return <>
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th>Status</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {sortedKeys.map((status, i) => {
          return <tr key={i}>
            <td>{status}</td>
            <td>{countMap[status]}</td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}

function parseShards(applications) {
  const countMap = {}
  applications.forEach(shard => {
    // shard is a dictionary from studentId -> applicationStatus (string)
    // we want to make a countMap of applicationStatus -> count
    Object.values(shard).forEach(status => {
      if (countMap[status]) {
        countMap[status] += 1
      } else {
        countMap[status] = 1
      }
    })
  })
  return countMap
}
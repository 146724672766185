// NavigationContext.js - A context to keep track of navigation history
import React, { createContext, useContext, useState } from 'react';

const defaultValue = {
  pushToHistory: (location) => {},
  popHistory: () => {},
  getPreviousLocation: (e=[]) => '/',
  history: [],
};

export const NavigationHistoryContext = createContext(defaultValue);

export function NavigationHistoryProvider({ children }) {
  const [history, setHistory] = useState([]);

  const pushToHistory = (location) => {
    setHistory((prevHistory) => [...prevHistory, location]);
  };

  const popHistory = () => {
    setHistory((prevHistory) => prevHistory.slice(0, -1));
  };

  const getPreviousLocation = (excluding=[]) => {
    // Do not return any of the locations in the excluding array
    for (let i = history.length - 1; i >= 0; i--) {
      if (!excluding.includes(history[i])) {
        return history[i];
      }
    }
    return undefined;
  };

  return (
    <NavigationHistoryContext.Provider value={{ 
      pushToHistory, 
      popHistory, 
      getPreviousLocation,
      history,
    }}>
      {children}
    </NavigationHistoryContext.Provider>
  );
}

export function useNavigationHistory() {
  return useContext(NavigationHistoryContext);
}


import { getApp } from "@firebase/app"
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore"
import { useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { FaUpload } from "react-icons/fa"
import Swal from "sweetalert2"
import { uploadPDF } from "utils/uploadFile"

const DEFAULT = ''

/**
 * You either need to pass in a firebaseDocPath or a url and setUrl function
 */

export const EditablePDF = ({
  firebaseDocPath = null,
  serverUrl = null,
  setServerUrl = null,
  pdfStoragePath,
  editable
}) => {
  if(firebaseDocPath != null) {
    return <EditablePDFFirebase
      firebaseDocPath={firebaseDocPath}
      pdfStoragePath={pdfStoragePath}
      editable={editable}
    />
  }

  return <EditablePDFInner
    serverUrl={serverUrl}
    setServerUrl={setServerUrl}
    pdfStoragePath={pdfStoragePath}
    editable={editable}
  />
   
}

const EditablePDFFirebase = ({
  firebaseDocPath,
  pdfStoragePath,
  editable,
}) => {
  const db = getFirestore(getApp())
  const docRef = doc(db, firebaseDocPath)
  const [serverData, loading, error] = useDocumentData(docRef)
  const serverUrl = serverData?.url ? serverData.url : DEFAULT
  return <EditablePDFInner
    serverUrl={serverUrl}
    setServerUrl={(url) => updateDoc(docRef, { url })}
    pdfStoragePath={pdfStoragePath}
    editable={editable}
  />
}
  

const EditablePDFInner = ({
  serverUrl,
  setServerUrl,
  pdfStoragePath,
  editable,
}) => {

  
  const [isUploading, setIsUploading] = useState(false)

  

  const onpdfUploaded = (url) => {
    setServerUrl(url)
    setIsUploading(false)
  }

  const updateLoading = (progress) => {
    if (progress > 0 && progress < 100) {
      setIsUploading(true)
    }
  }

  const setErrorState = (error) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'File was not uploaded',
    })
    setIsUploading(false)
  }

  const changePdf = () => {
    setIsUploading(false)
    uploadPDF(pdfStoragePath, onpdfUploaded, updateLoading, setErrorState)
  }



  const editButtonStyle = {
    position: 'absolute',
    top: 10,
    right: 10,
  }

  return <>
    <div>
      {serverUrl && <a className="btn btn-secondary" href={serverUrl} target="_blank" rel="noreferrer">Slides</a>}
      {isUploading && <div className="spinner-grow" style={{ backgroundColor: 'white' }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
      {editable && <button onClick={() => changePdf()} className="btn btn-light" ><FaUpload /> Upload New Slides</button>}
    </div>

  </>
}

import { Field, useField, useFormikContext } from "formik";

// inputs that correspond to survey components: (see SurveyComponent.jsx)
export const TextInput = ({ name, label,...props }) => {
  // creates a text input for short response
  const [field, meta] = useField({name, ...props});
  let description = props.description ? props.description : "";
  let descriptionDiv =
    description === "" ? (
      <span />
    ) : (
      <div className="description">{description}</div>
    );
  
  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      {descriptionDiv}

      <input className="about-text-input" {...field} {...props} />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const TextAreaInput = ({ name, label, ...props }) => {
  // creates a text area input for longer text
  const [field, meta] = useField({name, ...props});

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field
        className="text-area-input"
        as="textarea"
        rows="10"
        {...field}
        {...props}
      />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const DescriptionField = ({ label, ...props }) => {
  // no input, just a description
  let description = props.description ? props.description : "";
  let descriptionDiv =
    description === "" ? (
      <span />
    ) : (
      <div className="description">{description}</div>
    );
  return (
    <div>
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      {descriptionDiv}
    </div>
  );
};

export const LikertScale = ({name, label, ...props })=>{
  // automatically creates a likert scale with 5 options
  // values are 1-5 (strings)
  // you can only select one at a time
  const [field, meta] = useField({name, ...props});
  return (
    <div className="question-spacing" role="group">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="likert-scale">
        <label className="likert-option">
          Strongly disagree
          <Field type="radio" name={name} value="1" />
        </label>
        <label className="likert-option">
          Disagree
          <Field type="radio" name={name} value="2" />
        </label>
        <label className="likert-option">
          Neutral 
          <Field type="radio" name={name} value="3" />
        </label>
        <label className="likert-option">
          Agree
          <Field type="radio" name={name} value="4" />
        </label>
        <label className="likert-option">
          Strongly agree
          <Field type="radio" name={name} value="5" />
        </label>
      </div>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
      </div>
  )
}

export const Dropdown = ({ name, label, children, ...props }) => {
  // children become options in a select dropdown; you can select only one at a time
  // this is a lot like Select below, except that we expect that "children" is an array of objects with "value" and "label" keys
  // we map over the children to create the options
  const [field, meta] = useField({ name, ...props, type: "select" });

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field as="select" className="select-input" {...field} {...props}>
        {/* default option */}
        <option value=""></option> 
        {children.map((child, index) => {
          return <option key={index} value={child.value}>{child.label}</option>
        })
        }
      </Field>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const MultiCheckbox= ({name, children, ...props}) => {
  // children become checkboxes which are displayed vertically; you can select multiple at once (or none!)
  // this is a lot like MultipleCheckbox below, except that we expect that "children" is an array of objects with "value" and "label" keys
  // we map over the children to create the checkboxes 
  const [field, meta] = useField({ name, ...props });
  const msg = props.description ? props.description : "Select all that apply";

  return (
    <div className="question-spacing" style={props.style}>
      <label className="form-label">{props.label}</label>
      <div className="description">{msg}</div>
        {children.map((child, index) => {
          return <Checkbox
            key={index}
            name={name}
            value={child.value}
            label={child.label}
          />
        })
        }
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const MultipleChoice = ({name, label, children, ...props })=>{
  // children become radios which are displayed vertically; you can select only one at a time
  const [field, meta] = useField({name, ...props});
  return (
    <div className="question-spacing" role="group">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div>
        {children.map((child, index) => {
          return <div key={index}>
          <Field type="radio" name={name} value={child.value} key={index}/>
          &nbsp;{child.label} 
          </div>
        })
      }
          
      </div>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
      </div>
  )
}

// --------------------------------------------
// below are inputs that do not correspond to survey components

export const SelectInput = ({ name, label, ...props }) => {
  const [field, meta] = useField({ name, ...props, type: "select" });

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field as="select" className="select-input" {...field} {...props}>
        {props.children}
      </Field>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};


export const MultipleCheckbox = ({name, ...props}) => {
  const [field, meta] = useField({ name, ...props });
  const msg = props.description ? props.description : "Select all that apply";

  return (
    <div className="question-spacing" style={props.style}>
      <label className="form-label">{props.label}</label>
      <div className="description">{msg}</div>
      {props.children}
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const AgreeCheckbox = (props) => {
  /* eslint-disable no-unused-vars */
  const [field, meta] = useField({ ...props });
  /* eslint-enable no-unused-vars */
  return (
    <div className="question-spacing">
      <label className="form-label">{props.label}</label>
      <div style={{ color: "gray", marginBottom: "0.6rem" }}>
        {props.description ? props.description : ""}
      </div>

      {props.children}
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const Checkbox = (props) => {
  /* eslint-disable no-unused-vars */
  const [field, meta] = useField({ ...props });
  /* eslint-enable no-unused-vars */
  return (
    <div className="checkbox">
      <label className="form-label">
        <Field type="checkbox" {...props} />
        {props.label}
      </label>
    </div>
  );
};

export const CheckboxBoolean = (props) => {
  return (
    <div>
      <label className="form-label">{props.label}</label>

      <div style={{ color: "gray", marginBottom: "0.6rem" }}>
      <Field type="checkbox" {...props}/>
        &nbsp; {props.description ? props.description : ""}
      </div>
    </div>
  );
}

export const DateOfBirth = (props) => {
  // assumes the dob is governed by the following fields
  
  /* eslint-disable no-unused-vars */
  const [field, meta] = useField({ ...props });
  const {touched} = useFormikContext()
  // get a single error to show
  let error = undefined
  const fields = ['year', 'month', 'day']
  if(meta.error && touched.dateOfBirth){
    for(let fieldKey of fields) {
      if(meta.error[fieldKey] && touched.dateOfBirth[fieldKey]) {
        error = meta.error[fieldKey]
        break
      }
    }
  }
  
  const dobFormStyle = {
    maxWidth: "80px",
    marginRight: "0.5rem",
    border: "1px solid #e0e0e0",
    padding: "8px",
    borderRadius: "5px"
  }
  
  return <div className="question-spacing">
    <label className="form-label" >
        What is your date of birth?
    </label>
    <div className="d-flex">
      <div>
        Year<br/>
        <Field
            id="dateOfBirth.year"
            name="dateOfBirth.year"
            placeholder="YYYY"
            type="number"
            style={dobFormStyle}
        />
      </div>
      <div>
        Month<br/>
        <Field
            id="dateOfBirth.month"
            name="dateOfBirth.month"
            placeholder="MM"
            type="number"
            style={dobFormStyle}
        />
      </div>
      <div>
        Day<br/>
        <Field
          id="dateOfBirth.day"
          name="dateOfBirth.day"
          placeholder="DD"
          type="number"
          style={dobFormStyle}
      />
      </div>
      
      
    </div>
    {meta.touched && error && <div className="error">{error}</div>}
  </div>

}
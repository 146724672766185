import { useCallback, useContext, useEffect, useState } from "react";
import {
  getDoc,
  getDocFromServer,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Post, PostValidationError } from "../../firebase/ForumDataModels";
import "./PostViewer.css";
import {
  notification,
  Skeleton,
  message,
  Alert,
} from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TipTap } from "../../../../components/richTextEditor/TipTap/TipTap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NewReplyEditor } from "../NewReplyEditor/NewReplyEditor";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { ResolveButton } from "../ResolveButton/ResolveButton";
import { FlagButton } from "../FlagButton/FlagPostButton";
import { GhostUserChip, UserChip } from "../UserChip/UserChip";
import { ForumContext } from "course/forum/ForumContext";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { PostTags } from "../PostTags/PostTags";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "../../../../contexts/Gate";
import { PrivatePostSplash } from "../SplashPages/PrivatePostSplash";
import { PostNotFoundSplash } from "../SplashPages/PostNotFoundSplash/PostNotFoundSplash";
import { DiscussionButtonBar } from "../../../../components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { FaEdit, FaEyeSlash, FaGhost, FaFlag } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";
import { DateTime } from "luxon";
import { LikeButtonFast } from "../LikeButton/LikeButtonFast";
import { validatePostUpdate, getPostPaddingLeft, getPostWidth, postEntriesStyle } from "./utils";
import { ViewFlaggedPostButton } from "../FlagButton/ViewFlaggedPost";
import { popLoadingAlert } from "../forumGeneral";
import { PostRepliesRoot } from "../PostReplies/PostRepliesRoot";
import { RankButton } from "../RankButton/RankButton";


export function PostViewer(props: { postId: string }) {
  const { postId } = props;
  return <PostViewerSafe key={postId} postId={postId} />;
}



function PostViewerSafe(props: { postId: string }) {
  const { postId } = props;

  const courseId = useCourseId();
  const dataFetcher = useForumDataFetcher();

  const { userData, loading: userIsLoading } = useContext(ProfileContext);
  const { forumId, forumType, privatePostThreshold } =
    useContext(ForumContext);

  const [postTitle, setPostTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [postTags, setPostTags] = useState<string[]>([]);
  const [isReplying, setIsReplying] = useState(false);
  const [offsetPixels, setOffsetPixels] = useState(0);
  const [isViewed, setIsViewed] = useState(false);

  const [notificationCreator, notificationContextHolder] =
    notification.useNotification();
  const [messageCreator, messageContextHolder] = message.useMessage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const auth = getAuth(getApp());
  const [user, loading, error] = useAuthState(auth);
  const functions = getFunctions();
  const toggleLike = httpsCallable(functions, "likeEvent");
  const toggleForumPostFlag = httpsCallable(functions, "toggleForumPostFlag");

  const postQuery = useQuery(["post", postId], () => getDoc(dataFetcher.get_post_doc_ref(postId)));

  const isStaff = Gate.hasStaffRole(userData);


  /** @todo - refactor. This is only needed for when a staff member resolves a post */
  const updatePost = useMutation(
    async (data: {
      postChange: Partial<Post>;
      reloadPostList?: boolean;
      stopEditing?: boolean;
      validateContentsLength?: boolean;
    }) => {
      const { postChange, validateContentsLength } = data;

      const validated = validatePostUpdate(postChange, postText, validateContentsLength);
      if (!validated) return;
      return await updateDoc(dataFetcher.get_post_doc_ref(postId), postChange);
    },
    {
      onSuccess: (result, variables, context) => {
        const promises = [queryClient.invalidateQueries(["post", postId])];
        if (variables.reloadPostList) {
          promises.push(queryClient.invalidateQueries(["posts"]));
        }
        return Promise.all(promises);
      },
      onError: (errors: PostValidationError[], variables, context) => {
        errors.forEach((error) => {
          notificationCreator.error({
            message: error.error,
            description: error.detail,
          });
        });
      },
    }
  );


  const likeHandler = useMutation(
    async (data: { newLike: boolean }) => {
      const { newLike } = data;
      await toggleLike({
        courseId,
        forumId,
        targetType: "Post",
        targetId: postId,
        isLike: newLike,
      });

      return getDocFromServer(dataFetcher.get_post_doc_ref(postId));
    },
    {
      onSuccess: (result, variables, context) => {
        return queryClient.invalidateQueries(["post", postId], { exact: true });
      },
    }
  );

  // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
  const handleRect = useCallback((node) => {
    setOffsetPixels(node?.getBoundingClientRect().top);
  }, []);

  // Load data from the post the first time it gets loaded
  useEffect(() => {
    if (postQuery.isFetched) {
      if (postQuery.data.data()) {
        const { title, tags, contents } = postQuery.data.data();
        setPostTitle(title);
        setPostTags(tags);
        setPostText(contents.text);
      }
    }
  }, [postQuery.isFetched, postQuery.isFetchedAfterMount]);




  if (postQuery.isLoading || userIsLoading) {
    return (
      <div className="postContainer" style={postEntriesStyle(offsetPixels)}>
        <div
          className="post"
          style={{ width: getPostWidth(forumType), paddingLeft: getPostPaddingLeft(forumType) }}
        >
          <Skeleton avatar />
        </div>
      </div>
    );
  }

  if (postQuery.isFetched && !postQuery.data.data()) {
    return <PostNotFoundSplash />;
  }

  const {
    author,
    authorUid,
    time,
    isResolved,
    isPrivate,
    likedBy,
    isFlagged,
    isPinned,
    isAnonymous
  } = postQuery.data.data();

  if (isFlagged && !isViewed) {
    return (
      <div ref={handleRect} className="postContainer" style={postEntriesStyle(offsetPixels)}>
        {notificationContextHolder}
        {messageContextHolder}
        <div
          className="post"
          style={{ width: getPostWidth(forumType), paddingLeft: getPostPaddingLeft(forumType) }}
        >
          <div className="postContent">
            <div className="post-header">
              <h2 className="postTitle">[REMOVED]</h2>
              <div className="postActions">
                {Gate.hasStaffRole(userData) && (<ViewFlaggedPostButton
                  onClick={() => setIsViewed(!isViewed)}
                  isViewing={isViewed}
                />)}
              </div>
            </div>
            <ModeratedPostSplash />
          </div>

          <h4 className="repliesHeading">Replies</h4>

          <PostRepliesRoot
            postId={postId}
          />
          <div style={{ height: "30px" }} />
          <hr />
          <div style={{ height: "20px" }} />
        </div>
      </div>
    )
  }




  const canClickEdit = Gate.hasSectionLeaderRole(userData) || authorUid === user.uid;

  const editPostHandler = () => {
    navigate({
      pathname: location.pathname,
      search: `draft=${postId}`,
    });
  };


  const handleUpdate = (json, html, text) => {
    const contents = { html, text };
    setPostText(text);
    updateDoc(dataFetcher.get_post_doc_ref(postId), { contents });
  };


  const updateFlagged = async () => {
    if (!isStaff) return;
    const title = isFlagged ? "Unflag post?" : "Flag post?";
    const text = isFlagged
      ? "Please confirm that you would like to unflag this post. It will be restored to the forum."
      : "Please confirm that you would like to flag this post for being innapropriate and remove it from the forum.";
    const result = await Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    })
    if (!result.isConfirmed) return;
    const resolveLoading = popLoadingAlert("Processing flag request...")
    const cloudFuncResult = await toggleForumPostFlag({
      courseId,
      forumId,
      postId,
      isFlagged: !isFlagged
    }) as any;
    const cloudFuncResultData = cloudFuncResult?.data;
    resolveLoading();
    if (cloudFuncResultData?.success) {
      await Swal.fire("Success!", "Your action succeeded.", "success");
    } else {
      await Swal.fire("Error", `An error occurred while processing your request: ${cloudFuncResultData?.error}`, "error");
    }
    postQuery.refetch();

  }

  const aboveThreshold = Gate.hasRole(privatePostThreshold)(userData);
  const allowedToViewPost =
    aboveThreshold ||
    (isPrivate && authorUid == user.uid) ||
    !isPrivate;

  if (!allowedToViewPost) {
    return <PrivatePostSplash />;
  }

  const canMarkResolved =
    authorUid === user.uid || Gate.hasSectionLeaderRole(userData);


  const dt = DateTime.fromJSDate(time);

  const replyContent = isReplying ? (
    <NewReplyEditor
      parent={postId}
      postId={postId}
      setIsReplying={setIsReplying}
    />
  ) : (
    <button
      className="btn btn-sm btn-outline-secondary"
      onClick={() => setIsReplying(true)}
    >
      Reply to Post
    </button>
  );


  return (
    <div ref={handleRect} className="postContainer" style={postEntriesStyle(offsetPixels)}>
      {notificationContextHolder}
      {messageContextHolder}
      <div
        className="post"
        style={{ width: getPostWidth(forumType), paddingLeft: getPostPaddingLeft(forumType) }}
      >
        <div className="postContent">
          {isPrivate && (
            <Alert
              description={
                <span>
                  <strong>This post is private</strong>. Only you and the course
                  staff can see it.
                </span>
              }
              type="info"
              icon={<FaEyeSlash />}
              style={{ marginBottom: "15px" }}
              showIcon
            />
          )}
          {
              isAnonymous && authorUid===user.uid &&
              <Alert
              description={
                <span>
                This post is anonymous, only you and the teaching staff will be able to see your display name
                </span>
              }
              icon={<FaGhost />}
              style={{ marginBottom: "15px" }}
              showIcon
              />
            }
            {
              isAnonymous && isStaff &&
              <Alert
              description={
                <span>
                This post is anonymous, but you can see the author because you are a member of the teaching staff.
                </span>
              }
              icon={<FaGhost />}
              style={{ marginBottom: "15px" }}
              showIcon
              />
            }
          <div className="post-header">
            <h2 className="postTitle">{postTitle}</h2>

            <div className="postActions">
              {isStaff && isFlagged && (
                <ViewFlaggedPostButton
                  onClick={() => setIsViewed(!isViewed)}
                  isViewing={isViewed}
                />
              )}
              {canClickEdit && (
                <>
                  <EditButton onClick={editPostHandler} />
                </>
              )}
              {canMarkResolved && (
                <ResolveButton
                  canMarkResolved={canMarkResolved}
                  onClick={async () =>
                    await updatePost.mutate({
                      postChange: {
                        isResolved: !isResolved,
                      },
                    })
                  }
                  isResolved={isResolved}
                />
              )}
              {isStaff && (
                <>
                  <FlagButton
                    isFlagged={isFlagged}
                    onClick={updateFlagged}
                  />
                  { isPinned && <RankButton
                    postId={postId}
                    postAuthor={author}
                    postTitle={postTitle}
                  />}
                </>
              )}

              <LikeButtonFast
                likedBy={likedBy}
                likeHandler={async (newLike) => {
                  likeHandler.mutate({ newLike });
                }}
                iconSize={32}
                showNumber
              />
            </div>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <PostTags
              currentTags={postTags}
              editable={false}
            />
          </div>
          <div className="userAndTime">
            { !isAnonymous || aboveThreshold || authorUid===user.uid  ?  
            <UserChip showAvatar={true} uid={authorUid} name={author} /> : 
            <GhostUserChip showAvatar={true}/>
            
            }
            <div className="date">
              {dt.toLocaleString({
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              })}
            </div>
          </div>
          <div className="editorContainer">
            <TipTap
              editable={false} // only admins can edit post contents
              firebaseDocPath={dataFetcher.get_tiptap_doc_path(postId)}
              onServerWrite={() => { }}
              handleUpdate={handleUpdate}
              buttonBar={DiscussionButtonBar}
              showLoadingSkeleton={false}
            />
          </div>

        </div>
        <>
          {replyContent}
          <h4 className="repliesHeading">Replies</h4>

          <PostRepliesRoot postId={postId} />
        </>
        <div style={{ height: "30px" }} />
        <hr />
        <div style={{ height: "20px" }} />
      </div>
    </div>
  );
}

const EditButton = ({ onClick }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Edit Post
        </Tooltip>
      )}
    >
      <span>
        {" "}
        <FaEdit size={32} className="editButton" onClick={onClick} />
      </span>
    </OverlayTrigger>
  );
};





const ModeratedPostSplash = () => {


  return (
    <div>
      <div className="editorContainer">
        <div>
          <Alert
            description={
              <span>
                This post has been flagged and removed by a moderator.
              </span>
            }
            type="info"
            icon={<FaFlag />}
            showIcon
          />
        </div>
      </div>
    </div>
  );
}


import NoAccess from "components/errors/NoAccess";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { isAdmin, isInstructor, isStudent } from "contexts/profile_util";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { useContext } from "react";
import { Outlet } from "react-router";
import {
  StudentOnboardingContext,
  StudentOnboardingProvider,
} from "./contexts/StudentOnboardingContext";
import { StudentJoinNav } from "./StudentJoinNav";

export const StudentOnboardingPage = () => {
  return (
    <ProfileProvider>
      <Onboarding />
    </ProfileProvider>
  );
};

const Onboarding = () => {
  const { loading, userData } = useContext(ProfileContext);
  if (loading) return <></>;
  return (
    <StudentOnboardingProvider>
      <Inner />
    </StudentOnboardingProvider>
  );
};

const Inner = () => {
  const userId = useUserId();
  const courseId = useCourseId();
  const { loading, userData } = useContext(ProfileContext);
  const { onboardingLoading,chosenTimesMap, onboardingError } = useContext(StudentOnboardingContext);

  if (loading || onboardingLoading) {
    return <></>;
  }

  if(onboardingError) {
    return <div>There was an error loading the onboarding data, please refresh</div>
  }

  console.log(chosenTimesMap)

  const isAllowed = isStudent(userData.courseRole) 
    || isAdmin(userData.courseRole)
    || isInstructor(userData.courseRole)

  // it only makes sense to show the onboarding if you are a student!
  if (isAllowed) {
    return (
      <>
        <StudentJoinNav />
        <Outlet />
      </>
    );
  }

  return <NoAccess />;
};

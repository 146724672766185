import { WorldEditor } from "components/pyodide/KarelLib/WorldEditor"
import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa"

const KarelUnitTest = ({
    unitTestData,
    serverAssnData,
    unitTests,
    deleteUnitTest,
    editUnitTest,
    index,
    setUnitTestIndex,
}) => {
    console.log(unitTestData)

    const setPreState = (updateFn) => {
        const editedState = updateFn(unitTestData.pre)
        editUnitTest("pre", editedState)
    }

    const setPostState = (updateFn) => {
        const editedState = updateFn(unitTestData.post)
        editUnitTest("post", editedState)
    }

    const updateName = (newName) => {
        editUnitTest("name", newName)
    }

    const moveUp = () => {
        if (index > 0) {
            setUnitTestIndex(index - 1)
        }
    }

    const moveDown = () => {
        if (index < unitTests.length - 1) {
            setUnitTestIndex(index + 1)
        }
    }

    return (
        <>
            <b>Pre:</b>
            <WorldEditor worldState={unitTestData.pre} setWorldState={setPreState} />
            <b>Post:</b>
            <WorldEditor
                worldState={unitTestData.post}
                setWorldState={setPostState}
            />
            <b>Name:</b>
            <input
                type="text"
                className="form-control"
                placeholder="Enter title"
                value={unitTestData.name}
                onChange={(e) => {
                    updateName(e.target.value)
                }}
            />
            <div>
                <button onClick={moveUp} className="btn btn-light">
                    <FaArrowUp />
                </button>
                <button onClick={moveDown} className="btn btn-light">
                    <FaArrowDown />
                </button>
                <button onClick={deleteUnitTest} className="btn btn-light">
                    <FaTrash />
                </button>
                <hr />
            </div>
        </>
    )
}

const makeDefaultWorld = () => {
    return {
        nRows: 3,
        nCols: 4,
        karelRow: 0,
        karelCol: 0,
        karelDir: "East",
    }
}

export { KarelUnitTest, makeDefaultWorld }

import React, { useState, useEffect, useContext } from "react";

import { Card } from "react-bootstrap";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";



import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";
import { useNavigate } from "react-router";
import OnboardForm from "./OnboardForm";
import { SLOnboardingContext } from "onboarding/sectionLeaderOnboarding/contexts/SLOnboardingContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { ResponsiveCard, SetupOuter } from "onboarding/components/SetupOuter";



export const SLSectionTime = (props) => {
  document.title = "Section Time";
  const courseId = useCourseId();
  const {sectionData, setSectionData} = useContext(ProfileContext)
  const {onboardingData, editOnboarding} = useContext(SLOnboardingContext)
  const user = props.user;
  // sectionData is a map of sectionId to sectionData
  const firstSectionId = Object.keys(sectionData)[0]
  const firstSectionData  = sectionData[firstSectionId]
  console.log(firstSectionData)
  const sectionTimeIndex = "timeIndex" in firstSectionData ? firstSectionData.timeIndex : 'none'
  const needsSmallGroup = onboardingData?.needsSmallGroup || false;
  
  const [currentSlSmallGroup, setCurrentSlSmallGroup] = useState({
    time: "",
    size: 0,
    day: "",
  });
  const [allSmallGroups, setAllSmallGroups] = useState({});
  

  const navigate = useNavigate();
  const smallGroupServerData=[]
  const smallGroupLoading = false



  const submitForm = function (newValue) {
    console.log('submitted!')
  };

  const handleSectionChange =  async (e) => {
    const isNone = e.target.value === "none";
    const newSection = isNone ? "none" : parseInt(e.target.value);
    const db = getFirestore(getApp())
    const sectionDocPath = `sections/${firstSectionId}`
    const sectionDocRef = doc(db, sectionDocPath)
    await updateDoc(sectionDocRef, {timeIndex: newSection})

    // you also need to update the cached section data
    setSectionData((prev) => {
      const oldSectionData = prev[firstSectionId]
      const newSectionData = {...oldSectionData, timeIndex: newSection}
      return {
        ...prev,
        [firstSectionId]: newSectionData,
      };
    });
  };

  


  

  

  return (
     <SetupOuter>
        <ResponsiveCard style={{ margin: "auto", padding: "20px" }}>
          <h1>Section Time</h1>
          <div>
            <OnboardForm
              courseId={courseId}
              sectionTimeIndex={sectionTimeIndex}
              onSubmit={submitForm}
              handleSectionChange={handleSectionChange}
              needsSmallGroup={needsSmallGroup}
              slSmallGroup={currentSlSmallGroup}
              allSmallGroups={allSmallGroups}
            />
            {/* <SweetAlert
              show={submitAlert}
              title="Success"
              text="Your form has been successfully submitted!"
              type="success"
              onConfirm={() => {
                setSubmitAlert(false);
                history.goBack();
              }}
            /> */}
          </div>
        </ResponsiveCard>
        </SetupOuter>
    
  );
};

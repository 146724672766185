import { ViewRequestWidget } from './ViewRequestWidget';

export const DismissedRequestWidget = ({
    viewerId,
    connectionData, 
    userId, 
    userData,
    toggle,
    status,
    setStatus,
    setConnectionData,
    setToggle
}) => {
    if (toggle === 'viewDismissedRequest') {
        return <ViewRequestWidget 
            viewerId={viewerId}
            connectionData={connectionData} 
            userId={userId} 
            userData={userData} 
            status={status}
            setStatus={setStatus} 
            setConnectionData={setConnectionData} 
            setToggle={setToggle}
        />
    }
    return <></>
}
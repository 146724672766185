
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getRoomsPath, useRole, useRoomInfoOnce, useRoomStatus, setRoomStatus, ROOM_EXITED, ROOM_FORCE_EXITED } from "../../firebase/realtime/Rooms";

import { useActivePresence } from "components/presence/ActivePresence";
import { PyodideContext } from "components/pyodide/PyodideProvider";
import { ProfileContext } from "contexts/ProfileContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { IDEWithData } from "ide/IDE";
import { IDEContext } from "ide/contexts/IDEContext";
import { PeerSessionProvider } from "ide/contexts/PeerSessionContext";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { EndOfSessionContainer } from "ide/EndOfPeerSesson";


const ZOOM_MEETING_SDK = "vz2vapx4Tfm_riIymGMquQ"
const IDLE_TIMEOUT_MINS = 5;

export const PairProgramWithZoom = () => {
  const courseId = useCourseId()
  const userId = useUserId();
  const { roomId } = useParams();
  const { userData } = useContext(ProfileContext)
  const roomStatus = useRoomStatus(courseId, roomId, userId);
  const roomInfo = useRoomInfoOnce(courseId, roomId)
  const meetingRole = useRole(courseId, roomId, userId)

  if(!roomInfo) {
    return <></>
  }


  if(roomStatus === ROOM_EXITED || roomStatus === ROOM_FORCE_EXITED) {
    return <EndOfSessionContainer />
  }


  return (
    <PeerSessionProvider
      meetingType={roomInfo.meetingType}
      onEndSession={async () => {
        await setRoomStatus(userId, courseId, roomId, ROOM_EXITED)
      }}
      meetingRole={meetingRole}
      roomInfo={roomInfo}
    >
      <PairProgramWithZoomWithData roomInfo={roomInfo} />
    </PeerSessionProvider>
  )
}

const PairProgramWithZoomWithData = ({roomInfo}) => {
  const courseId = useCourseId()
  const userId = useUserId();
  const { roomId } = useParams();
  const presencePath = `${getRoomsPath(courseId)}/members/${roomId}/${userId}/presence`
  const { projectData, assnData } = useContext(IDEContext);
  const { isPyodideLoading } = useContext(PyodideContext);



  useActivePresence({
    data: { meetingViewState: 'colab', },
    idleTiemoutMS: 60 * 1000 * IDLE_TIMEOUT_MINS,   // 5 mins
    firebasePath: presencePath,
  })

  if (!projectData) return null;

  return <ZoomIdeOuter>
      {/* <ZoomMeeting roomInfo={roomInfo}/> */}
    <IDEWithData
      projectData={projectData}
      assnData={assnData}
      isPyodideLoading={isPyodideLoading}
    />
  </ZoomIdeOuter>

};




const ZoomIdeOuter = styled.div`
  height: 100%;
  overflow: hidden;
`;


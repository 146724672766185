import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import { useDebounce } from "use-debounce";
import { CourseContext } from "contexts/CourseContext";
import { ProfileContext } from "contexts/ProfileContext";
import {
  GetUserRoleRequest,
  SetUserRoleRequest,
  useGetUserRole,
  useSetUserRole,
} from "../../firebase/functions/set_role";
import { Alert, Button } from "react-bootstrap";
import {
  useDoesUserExistInFirestore,
  useGetUserDisplayName,
  useGetUserEmail,
  useGetUserIdFromEmail,
} from "../../firebase/functions/users";
import { isValidEmailAddress } from "utils/general";
import { ALL_FIREBASE_ROLES, RoleFirebaseString } from "types/role";
import { isAdmin } from "contexts/profile_util";
import { CopyButton } from "components/reusableButtons/CopyButton";
import {
  SelectDropdown,
  SelectOption,
} from "components/reusableButtons/SelectDropdown";

const PERMISSIONS_DEBOUNCE_TIME_MS = 600;

export const SetPermissionsPage = () => {
  const { userData } = useContext(ProfileContext);
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(
    searchText,
    PERMISSIONS_DEBOUNCE_TIME_MS
  );
  if (!userData || !userData.courseRole || !isAdmin(userData.courseRole)) {
    return (
      <Alert variant="danger">You must be an admin to view this page.</Alert>
    );
  }
  return (
    <>
      <SearchBox searchText={searchText} setSearchText={setSearchText} />
      <SearchResults searchText={debouncedSearchText} />
    </>
  );
};

const SearchBox = ({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="form-group row">
      <label className="col-form-label col-md-auto">
        Enter an email address or uid...
      </label>
      <div className="col-md-auto">
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="form-text"
        />
      </div>
    </div>
  );
};

const SearchResults = ({ searchText }: { searchText: string }) => {
  const { courseId } = useContext(CourseContext);
  const [getUserRole, getUserRoleLoading, getUserRoleError] = useGetUserRole();
  const [showGetUserRoleError, setShowGetUserRoleError] =
    useState<boolean>(false);
  const [setUserRole, setUserRoleLoading, setUserRoleError] = useSetUserRole();
  const [showSetUserRoleError, setShowSetUserRoleError] =
    useState<boolean>(false);
  const [
    getUserIdFromEmail,
    getUserIdFromEmailLoading,
    getUserIdFromEmailError,
  ] = useGetUserIdFromEmail();
  const [showGetUserIdFromEmailError, setShowGetUserIdFromEmailError] =
    useState<boolean>(false);
  const [getUserEmail, getUserEmailLoading, getUserEmailError] =
    useGetUserEmail();
  const [showGetUserEmailError, setShowGetUserEmailError] =
    useState<boolean>(false);
  const [
    doesUserExistInFirestore,
    doesUserExistInFirestoreLoading,
    doesUserExistInFirestoreError,
  ] = useDoesUserExistInFirestore();
  const [
    showDoesUserExistInFirestoreError,
    setShowDoesUserExistInFirestoreError,
  ] = useState<boolean>(false);
  const [
    getUserDisplayName,
    getUserDisplayNameLoading,
    getUserDisplayNameError,
  ] = useGetUserDisplayName();
  const [showGetUserDisplayNameError, setShowGetUserDisplayNameError] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [displayedRole, setDisplayedRole] = useState<RoleFirebaseString>();
  const [currentRole, setCurrentRole] = useState<RoleFirebaseString>();
  const [email, setEmail] = useState<string>();
  const [displayName, setDisplayName] = useState<string>();

  // Tests the search text to see if it is an email address. If it is, it will
  // attempt to fetch the user ID associated with that email address. Otherwise,
  // hides any stale errors and exits.
  useEffect(() => {
    if (!isValidEmailAddress(searchText)) {
      setShowGetUserIdFromEmailError(() => false);
      return;
    }
    const tryFetchUserId = async () => {
      const response = await getUserIdFromEmail(searchText);
      if (!response) {
        setUserId(() => undefined);
        return;
      }
      const foundUserId = await response.data;
      setUserId(() => foundUserId);
    };
    setEmail(() => searchText);
    setShowGetUserIdFromEmailError(() => true);
    tryFetchUserId().catch((error) => {
      console.error("Error fetching user id from email:", error);
    });
  }, [searchText, getUserIdFromEmail]);

  // Tests the search text to see if it is a user ID currently in Firestore. If
  // it is, it will update the user ID state (consumed by other effects).
  // Otherwise, it will hide any stale errors and exit.
  useEffect(() => {
    if (!searchText || isValidEmailAddress(searchText)) {
      setShowDoesUserExistInFirestoreError(() => false);
      return;
    }
    const tryCheckUserExists = async () => {
      const response = await doesUserExistInFirestore(searchText);
      if (!response) {
        setUserId(() => undefined);
        return;
      }
      const userExists = await response.data;
      if (!userExists) {
        console.error("User does not exist in Firestore");
        setUserId(() => undefined);
        return;
      }
      setUserId(() => searchText);
    };
    setShowDoesUserExistInFirestoreError(() => true);
    tryCheckUserExists().catch((error) => {
      console.error("Error checking if user exists in Firestore:", error);
    });
  }, [searchText]);

  // Attempts to fetch the email associated with the current user id.
  // If the input is an email address, it will not attempt to fetch the email.
  // If the user id is not set, it will reset the displayed email and hide any
  // error messages.
  useEffect(() => {
    if (!userId || isValidEmailAddress(searchText)) {
      setShowGetUserEmailError(() => false);
      return;
    }
    const tryFetchUserEmail = async () => {
      const response = await getUserEmail({ uid: userId });
      if (!response) return;
      const foundEmail = await response.data;
      setEmail(() => foundEmail);
    };
    setShowGetUserEmailError(() => true);
    tryFetchUserEmail().catch((error) => {
      console.error("Error fetching user email:", error);
    });
  }, [userId, getUserEmail]);

  // If the user ID is set, it will attempt to fetch the user display name
  useEffect(() => {
    if (!userId) {
      setShowGetUserDisplayNameError(() => false);
      setDisplayName(() => undefined);
      return;
    }
    const tryGetUserDisplayName = async () => {
      const response = await getUserDisplayName(userId);
      if (!response) {
        setDisplayName(() => undefined);
        return;
      }
      const userDisplayName = await response.data;
      setDisplayName(() => userDisplayName);
    };
    setShowGetUserDisplayNameError(() => true);
    tryGetUserDisplayName().catch((error) => {
      console.error("Error getting user display name:", error);
    });
  }, [userId]);

  // If the user ID is set, it will attempt to fetch the user role associated
  // with the user ID for the current course. If the user ID is not set, it will
  // reset the displayed role and hide any error messages.
  useEffect(() => {
    if (!userId) {
      setShowGetUserRoleError(() => false);
      setDisplayedRole(() => undefined);
      return;
    }
    const tryGetUserRole = async () => {
      const request: GetUserRoleRequest = {
        userId,
        courseId,
      };
      const response = await getUserRole(request);
      if (!response) {
        console.error("No response from getUserRole");
        return;
      }
      const foundRole = await response.data;
      setDisplayedRole(() => foundRole);
      setCurrentRole(() => foundRole);
    };
    setShowGetUserRoleError(() => true);
    tryGetUserRole().catch((error) => {
      console.error("Error getting user role:", error);
    });
  }, [userId, getUserRole]);

  const handleUpdateRole = async () => {
    if (!userId || !displayedRole) {
      console.error("User ID or role not set");
      setShowSetUserRoleError(() => false);
      return;
    }
    if (displayedRole === currentRole) {
      console.error("Role is already set to", displayedRole);
      setShowSetUserRoleError(() => false);
      return;
    }
    const request: SetUserRoleRequest = {
      userId,
      courseId,
      role: displayedRole,
    };
    setShowSetUserRoleError(() => true);
    await setUserRole(request);
    setCurrentRole(() => displayedRole);
  };

  if (!courseId) {
    return <Alert variant="danger">No course ID found</Alert>;
  }

  if (!searchText) {
    return (
      <Alert variant="info">Enter an email address or uid to search</Alert>
    );
  }

  if (
    getUserRoleLoading ||
    setUserRoleLoading ||
    getUserIdFromEmailLoading ||
    getUserEmailLoading ||
    doesUserExistInFirestoreLoading ||
    getUserDisplayNameLoading
  ) {
    return <Alert variant="info">Loading...</Alert>;
  }
  if (!userId) {
    return (
      <Alert variant="warning">No user found with that user id or email.</Alert>
    );
  }
  let allErrorDescriptions: string[] = [];
  if (showGetUserIdFromEmailError && getUserIdFromEmailError) {
    allErrorDescriptions.push(
      `Error getting user id: ${getUserIdFromEmailError}`
    );
  }
  if (showGetUserRoleError && getUserRoleError) {
    allErrorDescriptions.push(`Error getting user role: ${getUserRoleError}`);
  }
  if (showGetUserEmailError && getUserEmailError) {
    allErrorDescriptions.push(`Error getting user email: ${getUserEmailError}`);
  }
  if (showSetUserRoleError && setUserRoleError) {
    allErrorDescriptions.push(`Error setting user role: ${setUserRoleError}`);
  }
  if (showDoesUserExistInFirestoreError && doesUserExistInFirestoreError) {
    allErrorDescriptions.push(
      `Error checking if user exists in Firestore: ${doesUserExistInFirestoreError}`
    );
  }
  if (showGetUserDisplayNameError && getUserDisplayNameError) {
    allErrorDescriptions.push(
      `Error getting user display name: ${getUserDisplayNameError}`
    );
  }
  if (allErrorDescriptions.length) {
    return (
      <>
        <Alert variant="danger">
          The following errors occurred:
          <br />
          {allErrorDescriptions.join("; ")}
        </Alert>
      </>
    );
  }
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Firebase User ID</th>
            <th>Email</th>
            <th>Display Name</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {userId + " "} <CopyButton toCopy={userId} />
            </td>
            <td>
              {email + " "} <CopyButton toCopy={email} />
            </td>
            <td>
              {displayName + " "} <CopyButton toCopy={displayName} />
            </td>
            <td>
              <RolePicker
                displayedRole={displayedRole}
                setDisplayedRole={setDisplayedRole}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      {displayedRole !== currentRole && (
        <Button onClick={handleUpdateRole}>Update Role</Button>
      )}
    </>
  );
};

interface RolePickerProps {
  displayedRole: RoleFirebaseString;
  setDisplayedRole: Dispatch<SetStateAction<RoleFirebaseString>>;
}
const RolePicker = ({ displayedRole, setDisplayedRole }: RolePickerProps) => {
  if (!displayedRole) return;
  const selectableOptions = ALL_FIREBASE_ROLES.map((role) => {
    return { label: role, value: role };
  });
  const selectedOption = selectableOptions.find(
    (option) => option.value === displayedRole
  );
  if (!selectedOption) {
    console.error("Role not found in selectable options");
    return <Alert variant="danger">Role not found</Alert>;
  }
  const setSelectedOption = (option: SelectOption<RoleFirebaseString>) => {
    setDisplayedRole(() => option.value);
  };
  return (
    <SelectDropdown<RoleFirebaseString>
      selectableOptions={selectableOptions}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
    />
  );
};

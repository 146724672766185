import React, { useContext, useEffect, useState } from "react";

import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { query, setDoc, updateDoc, where } from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Button, Spin } from "antd";
import { TipTap } from "../../../../components/richTextEditor/TipTap/TipTap";

import "./NewReplyEditor.css";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { DiscussionButtonBar } from "../../../../components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams";
import { ForumContext } from "course/forum/ForumContext";
import { popLoadingAlert } from "../forumGeneral";
import { TimeContext } from "../../../../contexts/TimeContext";

export function NewReplyEditor(props: {
  parent: string;
  postId: string;
  onSubmit?: () => void;
  setIsReplying?: (boolean) => void;
}) {

  // Various data
  const courseId = useCourseId();
  const { forumId } = useContext(ForumContext);
  const [docSetting, setDocSetting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [replyText, setReplyText] = useState("");
  const { getServerTimeMs } = useContext(TimeContext);

  const { parent, postId, onSubmit, setIsReplying } = props;
  const dataFetcher = useForumDataFetcher();

  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);

  // Get replies
  const draftQuery = useFirestoreQuery(
    ["replyTo", parent],
    query(
      dataFetcher.get_reply_collection_ref(),
      where("parent", "==", parent),
      where("authorUid", "==", user.uid),
      where("isDraft", "==", true)
    ),
    { subscribe: true }
  );

  // Post to create new draft
  const createNewDraft = async () => {
    const childUuid = uuidv4();
    const newChildReply = {
      author: user?.displayName,
      authorUid: user.uid,
      id: childUuid,
      parent: parent,
      tipTapDoc: dataFetcher.get_tiptap_doc_ref(childUuid),
      time: new Date(getServerTimeMs()),
      contents: { text: "", html: "" },
      isDraft: true,
      likedBy: {},
      postId: postId,
      isFlagged: false
    };
    await setDoc(dataFetcher.get_reply_doc_ref(childUuid), newChildReply);
    return childUuid;
  };

  // If no docs in the draft collection, create a new draft
  useEffect(() => {

    if(draftQuery.isLoading) { return; }
    if (!!!draftQuery.data?.docs || draftQuery.data?.docs.length === 0) {
      setDocSetting(true);
      createNewDraft().then(() => {
        setDocSetting(false);
      });
    }

  }, [draftQuery.isLoading]);

  if (draftQuery.isLoading) {
    return <Spin />;
  }

  // The users last draft reply
  const draft = draftQuery?.data?.docs[0]?.data();

  const handleReplyUpdate = (json, html, text) => {
    const contents = { html, text };
    if(!draft) return;
    setReplyText(text);
    updateDoc(dataFetcher.get_reply_doc_ref(draft.id), { contents });
  };

  const publishReply = async () => {
    if(replyText.length === 0) return;
    const resolve = await popLoadingAlert("Publishing reply...");
    setIsPreviewing(true);
    const functions = getFunctions();
    // wait for reply data to be handled
    try {
      await httpsCallable(
        functions,
        "newReply"
      )({
        courseId,
        forumId,
        postId,
        replyId: draft.id,
        postUrl: window.location.href,
      });
      await updateDoc(dataFetcher.get_reply_doc_ref(draft.id), { isDraft: false })
    } catch (e) {
      setIsPreviewing(false);
    }


    await createNewDraft();
    setIsPreviewing(false);
    if (onSubmit) {
      onSubmit();
    }
    resolve();
    setIsReplying(false);
  };


  return (
    <>
          {draft && draft.id && !docSetting ? <TipTap
              editable={!isPreviewing}
              firebaseDocPath={dataFetcher.get_draft_tiptap_doc_path(draft.id)}
              onServerWrite={false}
              handleUpdate={handleReplyUpdate}
              buttonBar={DiscussionButtonBar}
              showLoadingSkeleton={false}
            ></TipTap> : null}

        {
          isPreviewing ? null : (
            <div className="new-reply-actions">
              <button className="btn btn-light" onClick={() => setIsReplying(false)}>
                Cancel
              </button>
              <Button type="primary" onClick={() => publishReply()}>
                Add Reply
              </Button>
            </div>
          )
        }
    </>
  );
}

import { useContext, useEffect, useState } from "react";
import { CourseContext } from "contexts/CourseContext";
import { ProfileContext } from "contexts/ProfileContext";
import { OverviewTable } from "./components/OverviewTable";
import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";


export const SectionOverview = () => {
    const userTz = getLocalUTCTimezone()
    const { userData, sectionData } = useContext(ProfileContext)
    const { sectionTimeDelta, nCourseWeeks, firstSectionTimestamp, getNextSectionIndex } = useContext(CourseContext)

    const [numSections, setNumSections] = useState(-1)
    const [membersList, setMembersList] = useState([]);

    useEffect(() => {
        if (!userData || !sectionData || !firstSectionTimestamp) return;
        const sectionId = userData?.courseSections?.[0]?.id
        const section = sectionData[sectionId]

        let nextSectionIdx
        if (section){
            nextSectionIdx = getNextSectionIndex(userTz, section.timeIndex)
        } else {
            nextSectionIdx = getNextSectionIndex(userTz, sectionTimeDelta) // if we don't know section time assume latest possible
        }
        setNumSections(nextSectionIdx + 1 < nCourseWeeks ? nextSectionIdx + 1 : nCourseWeeks)

        if (section && section.hasOwnProperty('members')) {
            let sectionMembers = section.members;
            // remove any duplicates
            sectionMembers = Array.from(new Set(sectionMembers));
            // filter out this user from section members
            sectionMembers = sectionMembers.filter(memberId => memberId !== userData.id);
            setMembersList(sectionMembers);
        }

    }, [userData, sectionData, firstSectionTimestamp]);

    
    return (
        <>
        <h4>Section Overview</h4>
        <OverviewTable
            membersList={membersList}
            numSections={numSections}
        />
        </>
    );
}
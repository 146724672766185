import { serverTimestamp } from "firebase/database";
import { database, auth, functions} from "firebaseApp.js";
import { useState, useEffect } from 'react';
import { ERROR_CODES } from "./Errors";
import { FBReturn, Message } from "./types";
import { getRoomsPath } from "./Rooms";

export const useMessages = (roomId: string, courseId: string): Message[] => {
    const [messages, setMessages] = useState(undefined);

    useEffect(() => {
        // TODO: pagination and make this only on child added
        if(roomId) {
            const roomPath = `${getRoomsPath(courseId)}/messages/${roomId}`
            const messagesRef = database.ref(roomPath);
            messagesRef.on("value", (snap) => {
                const val = snap.exists() ? Object.values(snap.val()) : [];
                setMessages(val);
            })
            return () => {
                messagesRef.off();
            }
        }
    }, [roomId, courseId])
    return messages;
}

const _processText = (text: string): string => {
    // Process text by stripping whitespace
    // return text.trim();
    return text;
}

export const addMessage = async (message: Message, roomId: string, courseId: string) : Promise<FBReturn> => {
    const msg = _processText(message.text)
    if(msg.length === 0) {
        return {
            success: false,
            code: ERROR_CODES.CHAT.EMPTY_MESSAGE,
            error: "Message is empty",
        }
    }

    try {
        const roomPath = `${getRoomsPath(courseId)}/messages/${roomId}`
        const messagesRef = database.ref(roomPath);
        // lol we should fix timespamp to timestamp but keeping it for now for consistency
        await messagesRef.push({...message, text: msg, timespamp: serverTimestamp(), timestamp: serverTimestamp()});

        return {
            success: true
        }
    } catch (error) {
        return {
            success: false,
            code: ERROR_CODES.GENERIC,
            error: "Generic error: " + error,
        }
    }
}
import { useServerTimeOnce } from "contexts/TimeContext"
import { Timestamp, collection, getDocs, getFirestore, limit, orderBy, query, startAfter, where } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"



export const useSharedProjects = () => {
    const serverTime = useServerTimeOnce()
    const courseId = useCourseId()
    const sharedCollectionPath = `published/${courseId}/studentPublished`
    const db = getFirestore()
    const sharedCollectionRef = collection(db, sharedCollectionPath)
    const [topProjectsAll, setTopProjectsAll] = useState([])
    const [lastTopProjectDoc, setLastTopProjectDoc] = useState(null)
    const [topProjectsDaily, setTopProjectsDaily] = useState([])
    const [lastTopDailyProjectDoc, setLastTopDailyProjectDoc] = useState(null)
    const [newProjects, setNewProjects] = useState([])
    const [lastNewProjectDoc, setLastNewProjectDoc] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const MAX_PROJECTS = 20


    useEffect(() => {
        if(!serverTime) {
            return
        }
        
        const getData = async () => {
            const rightNowLess24 = new Date(serverTime - 24 * 60 * 60 * 1000);
            const rnFs = Timestamp.fromDate(rightNowLess24);
            const allTimeQuery = query(sharedCollectionRef, orderBy("numLikes", "desc"), limit(MAX_PROJECTS))
            const dailyQuery = query(sharedCollectionRef, where("date", ">", rnFs), orderBy("date", "desc"), orderBy("numLikes", "desc"), limit(MAX_PROJECTS))
            const newProjects = query(sharedCollectionRef,  orderBy("date", "desc"), limit(MAX_PROJECTS))
            const allTimeDocs = getDocs(allTimeQuery)
            const dailyDocs = getDocs(dailyQuery)
            const newProjectsDocs = getDocs(newProjects)
            const [allTimeSnap, dailySnap, newProjectsSnap] = await Promise.all([allTimeDocs, dailyDocs, newProjectsDocs])
            const allTimeData = allTimeSnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
            const dailyData = dailySnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
            const sortedDailyData = dailyData.sort((a, b) => b.doc.numLikes - a.doc.numLikes)
            const newProjectsData = newProjectsSnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
            setLastTopProjectDoc(allTimeSnap.docs[allTimeSnap.docs.length - 1])
            setTopProjectsAll(allTimeData)
            setLastTopDailyProjectDoc(dailySnap.docs[dailySnap.docs.length - 1])
            setTopProjectsDaily(sortedDailyData)
            setLastNewProjectDoc(newProjectsSnap.docs[newProjectsSnap.docs.length - 1])
            setNewProjects(newProjectsData)

            setIsLoading(false) 
        }
        getData()
    }, [serverTime])


    const pullMoreAllTime = async () => {
        const allTimeQuery = query(sharedCollectionRef, orderBy("numLikes", "desc"), limit(MAX_PROJECTS), startAfter(lastTopProjectDoc))
        const allTimeDocs = getDocs(allTimeQuery)
        const allTimeSnap = await allTimeDocs
        const allTimeData = allTimeSnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
        setLastTopProjectDoc(allTimeSnap.docs[allTimeSnap.docs.length - 1])
        setTopProjectsAll([...topProjectsAll, ...allTimeData])
    }

    const pullMoreDaily = async () => {
        if(!lastTopDailyProjectDoc) {
            Swal.mixin({
                title: "No more projects today",
                icon: "info",
                confirmButtonText: "Ok",
                showCancelButton: false
            }).fire({
                showConfirmButton: false,
                showCancelButton: false,
                toast: true,
                timer: 5000,
            
            })
            return
        }
        const rightNowLess24 = new Date(serverTime - 24 * 60 * 60 * 1000);
        const rnFs = Timestamp.fromDate(rightNowLess24);
        const dailyQuery = query(sharedCollectionRef, where("date", ">", rnFs), orderBy("date", "desc"), orderBy("numLikes", "desc"), limit(MAX_PROJECTS), startAfter(lastTopDailyProjectDoc))
        const dailyDocs = getDocs(dailyQuery)
        const dailySnap = await dailyDocs
        const dailyData = dailySnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
        const sortedDailyData = dailyData.sort((a, b) => b.doc.numLikes - a.doc.numLikes)
        setLastTopDailyProjectDoc(dailySnap.docs[dailySnap.docs.length - 1])
        setTopProjectsDaily([...topProjectsDaily, ...sortedDailyData])
    }

    const pullMoreNew = async () => {
        const newProjectsRef = query(sharedCollectionRef,  orderBy("date", "desc"), limit(MAX_PROJECTS), startAfter(lastNewProjectDoc))
        const newProjectsDocs = getDocs(newProjectsRef)
        const newProjectsSnap = await newProjectsDocs
        const newProjectsData = newProjectsSnap.docs.map(doc => ({id: doc.id, doc: doc.data()}))
        setLastNewProjectDoc(newProjectsSnap.docs[newProjectsSnap.docs.length - 1])
        setNewProjects([...newProjects, ...newProjectsData])
    }

    const viewMore = (type) => {    
        switch(type) {
            case 'newest':
                pullMoreNew()
                break
            case 'topNew':
                pullMoreDaily()
                break
            case 'topAll':
                pullMoreAllTime()
                break
            default:
                break
        }
    }



    return {
        topProjectsAll,
        topProjectsDaily,
        newProjects,
        isLoading,
        viewMore
    }
}
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ClearFiltersButton } from 'components/reusableButtons/ClearFiltersButton';
import { useEffect, useState } from 'react'
import {Button} from 'react-bootstrap'
import { useIsMobile } from 'utils/general';
import { filterDefaults } from './filterDefaults';

export const checkIsDefault = (activeFilters) => {
    let answer = true
    for (let key of Object.keys(filterDefaults)) {
        const activeFilterKey = `${key}Filter`
        if (
            activeFilters[activeFilterKey] !== undefined // don't check filters that are not active
            && activeFilters[activeFilterKey] !== null
            ) {
                const update = activeFilters[activeFilterKey] === filterDefaults[key]
                answer = answer && update
                
        }
    }
    return answer
}

export const PracticeProblemsFilters = ({
    filterDefaults=null,
    activeFilters,
    setActiveFilters,
    assignments=null,
}) => {
    const [isDefault, setIsDefault] = useState(true)
    const [completedFilter, setCompletedFilter] = useState(activeFilters['completedFilter'])
    const [typeFilter, setTypeFilter] = useState(activeFilters['typeFilter'])
    const [groupFilter, setGroupFilter] = useState(activeFilters['groupFilter'])
    const [lessonFilter, setLessonFilter] = useState(activeFilters['lessonFilter'])

    useEffect(() => {
        setIsDefault(checkIsDefault({completedFilter, typeFilter, groupFilter, lessonFilter}))
    }, [completedFilter, typeFilter, groupFilter, lessonFilter])

    const capitalizeFirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClearFilters = () => {
        for (let key of Object.keys(filterDefaults)) {
            if (eval(`set${capitalizeFirst(key)}Filter !== null && filterDefaults.${key} !== undefined`)) {
                eval(`set${capitalizeFirst(key)}Filter(filterDefaults.${key})`)
            }
        }
    }

    const handleApply = () => {
        setActiveFilters({
            "completedFilter": completedFilter,
            "typeFilter": typeFilter,
            "groupFilter": groupFilter,
            "lessonFilter": lessonFilter
        })
    }

    const filters = (<>{
        assignments !== null && groupFilter !== null && groupFilter !== undefined ?
        <GroupFilter
            assignments={assignments}
            groupFilter={groupFilter}
            setGroupFilter={setGroupFilter}
            filterDefault={filterDefaults.group}
        /> : <></>}
        {assignments !== null && lessonFilter !== null && lessonFilter !== undefined ?
        <LessonFilter
            assignments={assignments}
            lessonFilter={lessonFilter}
            setLessonFilter={setLessonFilter}
            filterDefault={filterDefaults.lesson}
        /> : <></>}
       {completedFilter !== null && completedFilter !== undefined ? 
       <CompletedFilter 
            completedFilter={completedFilter}
            setCompletedFilter={setCompletedFilter}
            filterDefault={filterDefaults.completed}
        /> : <></>}
        {typeFilter !== null && typeFilter !== undefined ?
            <TypeFilter
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
            filterDefault={filterDefaults.type}
        /> : <></>}
        <Button style={{width: "max-content", margin: filterStyle.margin}} onClick={handleApply}> Apply</Button>
        {!isDefault && <ClearFiltersButton onClick={handleClearFilters} style={{width: "max-content", margin: filterStyle.margin}}/>}
        </>)

    return (
    <div className='px-2'>
        {useIsMobile() ? 
            <Col>
                {filters}
            </Col>
            :
            <Row> 
                {filters} 
            </Row>
            }
        
    </div>)
}


const CompletedFilter = ({
    setCompletedFilter,
    completedFilter,
    filterDefault
}) => {
    const handleChange = (e) => {
        setCompletedFilter(e.target.value)
    }

    return <Form.Select 
        aria-label="Status filter" 
        onChange={handleChange} 
        value={completedFilter}
        style={{
            ...filterStyle,
            backgroundColor: completedFilter !== filterDefault ? '#eff1fb': 'white',
        }}>
        <option value={filterDefaults.completed}>Status</option>
        <option value="0">Not completed</option>
        <option value="1">Completed</option>
    </Form.Select>
}

const TypeFilter = ({
    typeFilter,
    setTypeFilter,
    filterDefault
}) => {
    const handleChange = (e) => {
        setTypeFilter(e.target.value)
    }

    return <Form.Select 
        aria-label="Type filter" 
        onChange={handleChange} 
        value={typeFilter}
        style={{
            ...filterStyle,
            backgroundColor: typeFilter != filterDefault ? '#eff1fb': 'white'
        }}>
        <option value={filterDefaults.type}>Type</option>
        <option value="karel">Karel</option>
        <option value="console">Console</option>
        <option value="graphics">Graphics</option>
    </Form.Select>
}

const GroupFilter = ({
    assignments,
    groupFilter,
    setGroupFilter,
    filterDefault
}) => {
    const handleChange = (e) => {
        setGroupFilter(e.target.value)
    }

    // get unique assn groups
    var assnGroups = [];
    var assnIdSet = {};

    for (const assn of assignments) {
        var assnId = assn.assnGroup.id
        if (!(assnId in assnIdSet)) {
            assnIdSet[assnId] = true;
            assnGroups.push(assn.assnGroup);
        }
    }

    return <Form.Select 
        aria-label="Group filter" 
        onChange={handleChange} 
        value={groupFilter}
        style={{
            ...filterStyle,
            backgroundColor: groupFilter != filterDefault ? '#eff1fb': 'white',
        }}>
        <option key="default" value={filterDefaults.group}>All units</option>
        {assnGroups.map(assnGroup => <option key={assnGroup.assnId} value={assnGroup.title}>{assnGroup.title}</option>)}
    </Form.Select>
}


const LessonFilter = ({
    assignments,
    lessonFilter,
    setLessonFilter,
    filterDefault
}) => {
    const handleChange = (e) => {
        setLessonFilter(e.target.value)
    }
    // get unique assn groups
    var lessons = [];
    var lessonSet = {};

    for (const assn of assignments) {
        var lesson = assn.lessonTitle
        if (!(lesson in lessonSet)) {
            lessonSet[lesson] = true;
            lessons.push(lesson);
        }
    }

    return <Form.Select 
        aria-label="Lesson filter" 
        onChange={handleChange} 
        value={lessonFilter}
        style={{
            ...filterStyle,
            backgroundColor: lessonFilter != filterDefault ? '#eff1fb': 'white',
        }}>
        <option key="default" value={filterDefaults.lesson}>All lessons</option>
        {lessons.map(lesson => <option key={lesson} value={lesson}>{lesson}</option>)}
    </Form.Select>
}

const filterStyle = {
    width: 'min-content',
    margin: "0px 1rem 0.5rem 0px"
}
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

// const popover = (
//   <Popover id="popover-basic" style={{width:400}}>
//         <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23F6BF26&ctz=America%2FLos_Angeles&showTz=1&showCalendars=1&title=Head%20TA%20Office%20Hours%20for%20CIP%20SLs!&src=NDU5ODZmYTBhMzJlYTFhZjIyNjQ5Mjc1ZjdkZTQ0ZTAzNDExMjdmMjY3YTI4NjIyNzllMjEyOWY1NmViNGVkY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=MWRhNTk3MjNmNGE3ZTBkMmFlMGZjYzQ5MzE4NjVkNjk1MTU5MWNlYTNjODNjNjhiZmMxM2UxMTNlMjgwNzI2Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688&color=%23EF6C00" style={{border:"solid 1px #777"}} width="400" height="400" frameBorder="0" scrolling="no"></iframe>
//   </Popover>
// );

// export const HeadTAOHButton= () => (
//   <OverlayTrigger trigger="click" placement="left"  overlay={popover}>
//     <Button variant="success">See schedule</Button>
//   </OverlayTrigger>
// );

export const HeadTAOHButton = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const calendarSrc = `https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23F6BF26&ctz=${encodeURIComponent(userTimezone)}&showTz=1&showCalendars=1&title=Head%20TA%20Office%20Hours%20for%20CIP%20SLs!&src=NDU5ODZmYTBhMzJlYTFhZjIyNjQ5Mjc1ZjdkZTQ0ZTAzNDExMjdmMjY3YTI4NjIyNzllMjEyOWY1NmViNGVkY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=MWRhNTk3MjNmNGE3ZTBkMmFlMGZjYzQ5MzE4NjVkNjk1MTU5MWNlYTNjODNjNjhiZmMxM2UxMTNlMjgwNzI2Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23009688&color=%23EF6C00`
  
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          View schedule
        </Button>
  
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{width:"700px"}}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Head TA office hours schedule</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p>The Head TAs are experienced teachers and, for several, CIP alumni, whose role it is to support you, the SLs!</p>
            <p>Check out <b>group office hours</b> to meet other SLs, prepare for section, practice teaching, reflect on section, or just hang out, in a space facilitated by a Head TA.</p>
            
         <iframe src={calendarSrc} style={{border:"0"}} width="650" height="500" frameBorder="0" scrolling="no"></iframe>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
import { useState, useEffect } from "react"
import { FaTrash, FaPlus, FaEraser } from "react-icons/fa"

export const ConsoleUnitTest = ({
    unitTestData,
    serverAssnData,
    unitTests,
    deleteUnitTest,
    editUnitTest,
    index,
    setUnitTestIndex,
}) => {
    //unitTestData.dialogue ??
    const [dialogueList, setDialogueList] = useState(unitTestData.dialogue ?? [])
    const [dialogueIndex, setDialogueIndex] = useState(
        unitTestData.dialogue
            ? unitTestData.dialogue.reduce((max, item) => {
                return Math.max(max, item.order)
            }, Number.MIN_VALUE) + 1
            : 0
    )
    const [testName, setTestName] = useState(unitTestData.name ?? "")
    const [testDescription, setTestDescription] = useState(
        unitTestData.description ?? ""
    )

    const editConsoleUnitTest = () => {
        const outputList = dialogueList
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((val) => val.output)
        const inputList = dialogueList
            .filter((data) => data.type === "input")
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((val) => val.input)
        editUnitTest("post", outputList)
        editUnitTest("pre", inputList)
        editUnitTest("dialogue", dialogueList)
        editUnitTest("description", testDescription)
        editUnitTest("name", testName)
    }

    const addDialogue = (isPrint) => {
        const data = {
            order: dialogueIndex,
            output: "",
            input: "",
            type: isPrint ? "print" : "input",
        }

        dialogueList.push(data)
        setDialogueIndex(dialogueIndex + 1)
        editConsoleUnitTest()
    }

    const removeDialogue = (idx) => {
        dialogueList.splice(idx, 1)
        setDialogueList(dialogueList)
        editConsoleUnitTest()
    }

    const updateDialogue = (idx, value, key) => {
        dialogueList[idx][key] = value
        editConsoleUnitTest()
    }

    const updateName = (val) => {
        setTestName(val)
    }

    const updateDescription = (val) => {
        setTestDescription(val)
    }

    useEffect(() => {
        editConsoleUnitTest()
    }, [testName, testDescription])

    const dialogue = dialogueList.map((val, idx) => {
        if (val.type === "print") {
            return (
                <PrintUnit
                    data={val}
                    idx={idx}
                    key={val.order}
                    remove={removeDialogue}
                    update={updateDialogue}
                />
            )
        } else {
            return (
                <InputUnit
                    data={val}
                    idx={idx}
                    key={val.order}
                    remove={removeDialogue}
                    update={updateDialogue}
                />
            )
        }
    })

    return (
        <div className="border rounded p-2 mb-3">
            <input
                type={"text"}
                className="form-control mb-2"
                placeholder="Test Name"
                value={testName}
                onChange={(e) => {
                    updateName(e.target.value)
                }}
            />
            <input
                type={"text"}
                className="form-control mb-2"
                placeholder="Test Description"
                value={testDescription}
                onChange={(e) => {
                    updateDescription(e.target.value)
                }}
            />
            {dialogue}
            <button className="btn btn-light mb-3" onClick={() => addDialogue(true)}>
                <FaPlus /> Print Prompt
            </button>
            <button
                className="btn btn-light mb-3 ml-3"
                onClick={() => addDialogue(false)}
            >
                <FaPlus /> Input Prompt
            </button>
            <button onClick={deleteUnitTest} className="btn btn-light ml-3 mb-3">
                <FaTrash />
            </button>
        </div>
    )
}

const PrintUnit = ({ data, idx, remove, update }) => {
    const [output, setOutput] = useState(data.output ?? "")

    const updateOutput = (val) => {
        setOutput(val)
        update(idx, val, "output")
    }

    return (
        <div className="d-flex mt-3">
            <strong style={{ minWidth: "80px" }}>Print</strong>
            <input
                type={"text"}
                className="form-control mb-2"
                placeholder={`Place expected output here!`}
                value={output}
                onChange={(e) => {
                    updateOutput(e.target.value)
                }}
            />
            <button className="btn btn-light mb-2" onClick={() => remove(idx)}>
                <FaEraser />
            </button>
        </div>
    )
}

const InputUnit = ({ data, idx, remove, update }) => {
    const [output, setOutput] = useState(data.output ?? "")
    const [input, setInput] = useState(data.input ?? "")

    useEffect(() => {
        update(idx, input, "input")
    }, [input])

    useEffect(() => {
        update(idx, output, "output")
    }, [output])

    const updateOutput = (val) => {
        setOutput(val)
    }

    const updateInput = (val) => {
        setInput(val)
    }

    return (
        <div className="d-flex mt-3">
            <strong style={{ minWidth: "80px" }}>Input</strong>
            <input
                type={"text"}
                className="form-control mb-2"
                placeholder="Place Prompt!"
                value={output}
                onChange={(e) => {
                    updateOutput(e.target.value)
                }}
            />

            <input
                type={"text"}
                className="form-control mb-2"
                placeholder="Place input data!"
                value={input}
                onChange={(e) => {
                    updateInput(e.target.value)
                }}
            />
            <button className="btn btn-light mb-2" onClick={() => remove(idx)}>
                <FaEraser />
            </button>
        </div>
    )
}

/*
 * File: KarelPythonParser.js
 * --------------------
 * This file implements a parser for the KarelPy language.
 */
import Parser from "../parser/Parser.js";
import XParser from "../parser/XParser.js";
import TokenScanner from "../scanner/TokenScanner.js";

const MAIN_METHOD = "main";

function KarelPythonParser() {
  Parser.call(this);
  this.scanner.addWordCharacters("_");
  this.scanner.setCommentStructure("python");
  this.operators = {};
  this.defineOperators();
}
KarelPythonParser.prototype = new XParser();
KarelPythonParser.prototype.constructor = KarelPythonParser;

KarelPythonParser.prototype.defineOperators = function () {
  this.definePrefixOperator("(", this.parenOperator, 0, "RIGHT");
  this.definePrefixOperator("!", this.prefixOperator, 100);
  this.defineInfixOperator("(", this.applyOperator, 110, "RIGHT");
  this.defineInfixOperator("and", this.infixOperator, 30);
  this.defineInfixOperator("or", this.infixOperator, 20);
};

KarelPythonParser.prototype.raiseCompileError = function (token, msg) {
  let error = {
    msg: msg,
    lineNumber: token.lineNumber,
  };
  throw new Error(JSON.stringify(error));
};

KarelPythonParser.statementForms = {};

KarelPythonParser.statementForms["pass"] = function (parser) {
  parser.verifyNewline();
  let token = parser.nextToken();
  // line number is ignored by syntax highligher.
  let lineNumber = token.lineNumber;
  parser.saveToken(token);

  return ["stmt", ["call", "front_is_clear"], ["lineNumber", lineNumber]];
};

KarelPythonParser.statementForms["if"] = function (parser) {
  let ifIndentLevel = parser.nextTokenIndentLevel();
  parser.blockIndentStack.push(ifIndentLevel);
  var exp = parser.readPredicate();
  parser.verifyTokenNoReturn(":");
  parser.verifyNewline();
  var s1 = parser.readBlock();
  if (parser.hasMoreTokens()) {
    let maybeElseIndentLevel = parser.nextTokenIndentLevel();
    var token = parser.nextToken();

    if (token.text === "else" && maybeElseIndentLevel === ifIndentLevel) {
      parser.verifyTokenNoReturn(":");
      parser.verifyNewline();
      return ["if", exp, s1, parser.readBlock()];
    } else {
      parser.saveToken(token);
    }
  }

  return ["if", exp, s1];
};

KarelPythonParser.statementForms["paint_corner"] = function (parser) {
  parser.verifyTokenNoReturn("(");
  var colorToken = parser.nextToken();
  var colorStr = colorToken.text;
  var lineNumber = colorToken.lineNumber;
  parser.verifyTokenNoReturn(")");

  return [
    "stmt",
    ["call", "paint_corner"],
    ["lineNumber", lineNumber],
    ["color", colorStr],
  ];
};

KarelPythonParser.statementForms["while"] = function (parser) {
  let headerIndentLevel = parser.nextTokenIndentLevel();
  var exp = parser.readPredicate();
  parser.verifyTokenNoReturn(":");
  parser.verifyNewline();
  parser.blockIndentStack.push(headerIndentLevel);
  return ["while", exp, parser.readBlock()];
};

KarelPythonParser.statementForms["for"] = function (parser) {
  let headerIndentLevel = parser.nextTokenIndentLevel();

  var iteratorToken = parser.nextToken();
  var iteratorName = iteratorToken.text;
  if (iteratorName in parser.localVars) {
    parser.raiseCompileError(
      iteratorToken,
      'Two for loops use the same iterator "' + iteratorName + '"'
    );
  } else {
    parser.localVars[iteratorName] = true;
  }
  var lineNumber = iteratorToken.lineNumber;

  parser.verifyTokenNoReturn("in");
  parser.verifyTokenNoReturn("range");
  parser.verifyTokenNoReturn("(");
  var numberToken = parser.nextToken().text;
  var N = TokenScanner.getNumber(numberToken);
  parser.verifyTokenNoReturn(")");
  parser.verifyTokenNoReturn(":");
  parser.verifyNewline();
  var body = parser.readBlock(headerIndentLevel);

  // at this point we can remove the name from localVars
  delete parser.localVars[iteratorName];

  return ["repeat", N, body, ["lineNumber", lineNumber]];
};

KarelPythonParser.prototype.readImport = function () {
  var hasImport = false;
  // there may be multiple
  while (true) {
    var token = this.nextToken();
    let txt = token.text;
    var isImport = txt === "import" || txt === "from";
    if (!isImport) {
      this.saveToken(token);
      break;
    }
    if (txt === "from") {
      this.verifyToken("karel");
      this.verifyToken(".");
      this.verifyToken("stanfordkarel");
      this.verifyToken("import");
      this.verifyToken("*");
      this.verifyNewline();
    } else {
      this.verifyToken("karel");
      this.verifyNewline();
    }
    hasImport = true;
  }
  if (!hasImport) {
    var msg = `You are missing the import statement. The correct import line is 'from karel.stanfordkarel import *'`;
    throw new Error(JSON.stringify({
      msg: msg,
      lineNumber: 0,
    }));
  }
};

KarelPythonParser.prototype.readClass = function () {
  // even though python doesnt have classes, this is still
  // the entry point of the parse...

  let baseClass = "SuperKarel";
  let name = "MyKarel";
  let hasBoilerplate = false

  var functionMap = {};
  while (true) {
    var token = this.nextToken();
    if (token.text === "") break;
    this.saveToken(token);

    if (token.text === MAIN_METHOD) {
      // we are executing the main method!!
      this.verifyToken(MAIN_METHOD);
      this.verifyToken("(");
      this.verifyToken(")");
      this.verifyNewline();
      break; // you are done!
    } else if (token.text === "def") {
      // we are defining a new method
      var fn = this.readFunction();
      var fnName = fn[1];
      if (fnName in functionMap) {
        this.raiseCompileError(token, "Method " + fnName + " already defined");
      }
      functionMap[fnName] = fn;
    } else if (token.text == "if") {
      var nextToken = this.nextToken();
      this.verifyToken("__name__")
      // == doesn't exist yet
      this.verifyToken("=")
      this.verifyToken("=")
      this.verifyMainToken()
      this.verifyToken(":")
      const fnToken = this.nextToken()
      const fnName = fnToken.text
      const validNames = ['main', 'run_karel_program']
      if (!validNames.includes(fnName)) {
        var msg = `The boilerplate code is incorrect -- the two lines which start with if __name__ == "__main__". Found ${fnName} when expecting main. Consider restarting the problem.`
        this.raiseCompileError(fnToken, msg);
      }
      this.verifyToken('(')
      this.verifyToken(')')
      hasBoilerplate = true


      // only valid case if if __name__ == "__main__"
    } else {
      var msg = "Found " + token.text + " when expecting def";
      this.raiseCompileError(token, msg);
    }
  }
  return ["class", name, baseClass, functionMap, hasBoilerplate];
};

KarelPythonParser.prototype.isPythonReservedWord = function (name) {
  let reserved = [
    "False",
    "await",
    "else",
    "import",
    "pass",
    "None",
    "break",
    "except",
    "in",
    "raise",
    "True",
    "class",
    "finally",
    "is",
    "return",
    "and",
    "continue",
    "for",
    "lambda",
    "try",
    "as",
    "def",
    "from",
    "nonlocal",
    "while",
    "assert",
    "del",
    "global",
    "not",
    "with",
    "async",
    "elif",
    "if",
    "or",
    "yield",
  ];
  return reserved.includes(name);
};

KarelPythonParser.prototype.readFunction = function () {
  let headerIndentLevel = this.nextTokenIndentLevel();
  this.localVars = {};
  this.verifyToken("def");
  var token = this.nextToken();
  var name = token.text;

  if (!this.scanner.isValidIdentifier(name)) {
    let msg = '"' + name + '" is not a legal function name';
    this.raiseCompileError(token, msg);
  }
  if (this.isPythonReservedWord(name)) {
    let msg =
      "You can't name a function \"" +
      name +
      '". It is a reserved word in python';
    this.raiseCompileError(token, msg);
  }

  this.verifyTokenNoReturn("(");
  this.verifyTokenNoReturn(")");
  this.verifyTokenNoReturn(":");
  this.verifyNewline();

  this.blockIndentStack = [headerIndentLevel];
  return ["function", name, this.readBlock()];
};

KarelPythonParser.prototype.readBlock = function () {
  let functionIndent = this.blockIndentStack[0];

  if (functionIndent === undefined) {
    console.error("header indent: " + functionIndent);
    console.error("must specify a header indent level");
  }
  let bodyIndentLevel = this.nextTokenIndentLevel();

  if (bodyIndentLevel <= functionIndent) {
    var token = this.nextToken();
    this.saveToken(token);
    let lineNumber = token.lineNumber;
    let e = {
      msg: "Function body must be indentented. One possible reason for this error is that you don't have a function body.",
      lineNumber: lineNumber,
    };
    throw new Error(JSON.stringify(e));
  }
  var block = ["block"];
  while (true) {
    // you may have reached the end of the program
    if (!this.hasMoreTokens()) {
      break;
    }

    // decide on whether the block continues based on indent
    let nextIndentLevel = this.nextTokenIndentLevel();
    if (nextIndentLevel > bodyIndentLevel) {
      var token = this.nextToken();
      this.saveToken(token);
      let lineNumber = token.lineNumber;
      let e = {
        msg: "Indentation increased unexpectedly",
        lineNumber: lineNumber,
      };
      throw new Error(JSON.stringify(e));
    }
    if (nextIndentLevel < bodyIndentLevel) {
      break;
    }
    var stmt = this.readStatement();
    block.push(stmt);
  }
  return block;
};

KarelPythonParser.prototype.readStatement = function () {
  var token = this.nextToken();
  var tokenText = token.text;
  var lineNumber = token.lineNumber;
  var prop = KarelPythonParser.statementForms[tokenText];
  if (prop) return prop(this);
  this.verifyTokenNoReturn("(");
  this.verifyTokenNoReturn(")");
  this.verifyNewline();
  return ["stmt", ["call", tokenText], ["lineNumber", lineNumber]];
};

KarelPythonParser.prototype.readPredicate = function () {
  let predicate = this.readE(0);
  // If this is not a list, then you don't have a proper expression
  if (!Array.isArray(predicate)) {
    let text = predicate.text;
    let lineNumber = this.nextToken().lineNumber;
    let e = {
      msg: `Invalid condition found ${text}. Perhaps you are missing ()s?`,
      lineNumber: lineNumber,
    };
    throw new Error(JSON.stringify(e));
  }


  // I switch to && and || so that the interpreter doesn't have to
  // tell the difference between java and python
  if (predicate[0] === "and") {
    predicate[0] = "&&";
  }
  if (predicate[0] === "or") {
    predicate[0] = "||";
  }
  recReplacePred(predicate)
  return predicate;
};

function recReplacePred(pred) {
  if (!pred) { return }
  if (pred[0] === "and") {
    pred[0] = "&&";
  }
  if (pred[0] === "or") {
    pred[0] = "||";
  }

  if (pred[1]) {
    recReplacePred(pred[1])
  }
  if (pred[2]) {
    recReplacePred(pred[1])
  }
}

export default KarelPythonParser;

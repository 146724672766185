import { useParams } from 'react-router-dom';
import {FaPython } from 'react-icons/fa';
import { LessonsContext } from 'course/contexts/LessonsContext';
import { RoadmapContext } from 'course/contexts/RoadmapContext';

import { CoursePageBodyContainer } from '../../components/layout/CoursePageBodyContainer';
import styled from 'styled-components'
import Button from "react-bootstrap/Button";
import { useContext } from 'react';
import { ProfileContext } from 'contexts/ProfileContext';
import { Checkmark } from "react-checkmark";
import { useCourseId } from 'hooks/router/useUrlParams';
import { CompletionContext } from 'contexts/CompletionContext';
import { useIsMobile } from 'utils/general';
import { useHistoryNavigate } from 'hooks/router/useHistoryNavigate';
import { CourseContext } from 'contexts/CourseContext';


export const LearnSplash = () => {

  const { userData } = useContext(ProfileContext)
  const courseRole = userData?.courseRole



  return <CoursePageBodyContainer
    mainColumn={<LearnSplashMain />}
    rightColumn={<LearnRightColumn />}
    singleColumn={<LearnSplashSingle />}
  />
}

const LearnSplashSingle = () => {
  return <>
    <LearnRightColumn />
    <LearnSplashMain />
  </>
}

export const LearnSplashMain = () => {

  const { lessonsMap } = useContext(LessonsContext)
  const { getLessonsInRoadmap } = useContext(RoadmapContext)
  const roadmapLessons = getLessonsInRoadmap()
  const sortedLessons = roadmapLessons.map(lesson => {
    console.log(lesson)
    const key = lesson.lessonId
    // Sort the non-header objects by the index attribute
    // no longer needed because we aren't displaying the first item
    // const nonHeaderItems = Object.values(lessonsMap[key].items).filter(item => item.type !== 'header')
    // const sortedNonHeaderItems = nonHeaderItems.sort((a, b) => a.index - b.index);
    return {
      title: lessonsMap[key]?.title || "",
      id: key,
      lessonNum: lessonsMap[key]?.lessonNum || 0,
      lessonType: lessonsMap[key]?.type ? lessonsMap[key].type: 'other',
      published: lessonsMap[key]?.publish
    }
  })
  
  /**@TODO - Remember to adjust this when we scale content types */
  const types = { // mapping of type field to correct title on web page
    'karel': 'Karel Lessons',
    'console': 'Console Lessons',
    'graphics': 'Graphics and Functions',
    'data': 'Data'
  }

  // Gets lessons from sortedLessons by lesson type
  function LessonModule({ type }) {
    return (
      <>
        <h3>{types[type]}</h3>
        <div>
          <ModuleSectionContents>

            {
              sortedLessons.map((lesson) => {
                if (lesson.lessonType == type && lesson.published) {
                  return <LessonButton
                    title={lesson.title}
                    key={lesson.id}
                    lessonId={lesson.id}
                  />
                }

              })
            }
          
          </ModuleSectionContents>
        </div>
      </>
    )
  }

  return (
    <div >
      <div style={{ fontSize: "16px", paddingTop: 46 }}>
        <p style={{ marginBottom: 36 }}>Welcome to the Learning Center. Each lesson consists of lecture videos,
          broken down into smaller chunks by subtopic.
          We have included optional challenges with solutions called "worked examples".
          Finally, each lesson includes the lecture slides as well as optional
          readings that you may find helpful.</p>
      </div>

      {
        Object.keys(types).map(key => {
          if (sortedLessons.filter((lesson) => lesson.lessonType == key).length > 0) {
            return (
              <LessonModule type={key} />
            )
          } else {
            return <></>
          }
        })}

      <hr />

      <div style={{ height: 60 }} />

    </div>
  )
}

const LearnRightColumn = () => {
  const courseId = useCourseId()
  const isMobile = useIsMobile();
  const { usesKarel } = useContext(CourseContext) 

  return <>
    <div style={{ marginTop: 30 }} className="splashCardRightTitle">Readers</div>
    <div className='d-flex flex-wrap gap-3 justify-content-left'>
  { usesKarel && <a href="https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html"
      target="_blank"
      className={'btn btn-outline-primary'}
      style={{width: isMobile ? "46%" : 180}}
      >
      <div>
        <div>
          <img src="/karel192.png" className='splashButtonIcon'/>
        </div>
        Karel Reader
      </div>
    </a>}

    <a href={`/${courseId}/textbook`}
      target="_blank"
      className={'btn btn-outline-primary'}
      style={{width: isMobile ? "46%" : 180}}
    >
      <div>
        <div>
          <FaPython className='splashButtonIcon' />
        </div>
        Python Reader
      </div>
    </a>
    </div>
  </>
}



const LessonButton = ({ title, lessonId }) => {
  const { courseId } = useParams();
  const { isLessonCompleted } = useContext(CompletionContext)
  let lessonAddress = `/${courseId}/learn/${lessonId}`
  const navigate = useHistoryNavigate()
  return (
    <ModuleLink onClick={() => navigate(lessonAddress)}>
      <ModuleLinkButton variant="light" size="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {title}
          </div>
          {isLessonCompleted(lessonId) ?
            <Checkmark size="medium" /> :
            <></>}

        </div>
      </ModuleLinkButton>
    </ModuleLink>
  )
}



const ModuleLink = styled.a`
  margin-bottom: 10px;
  display: block;
`;

const ModuleLinkButton = styled(Button)`
  width: 100%;
  max-width:700px;
  height: 60px;
  text-align: left;
  background-color: #eff1fc;
  font-size: 1rem;
`;

const ModuleSectionContents = styled.div`
  text-align: left;
  margin-bottom: 20px; 
`;

import { useContext, useState } from "react";
import { FaSort } from "react-icons/fa";
import { RankPostModal } from "./RankPostModal";
import { ForumContext } from "course/forum/ForumContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";



export const RankButton = ({ postId, postAuthor, postTitle }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { forumId } = useContext(ForumContext)


    return (
        <>
            {/* <button className="btn btn-primary" onClick={() => setModalOpen(true)}>
                <FaSort/>
            </button> */}
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 400, hide: 0 }}
                overlay={(props) => (
                <Tooltip id="resolve-tooltip" {...props}>
                    Order post in pinned posts
                </Tooltip>
                )}
            >
                <span>
                <FaSort
                    onClick={() => setModalOpen(true)}
                    size={32}
                    className="markResolvedButton"
                    color={"#bbbbbb"}
                />{" "}
                </span>
            </OverlayTrigger>
            <RankPostModal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                forumId={forumId}
                currPostId={postId}
                currPostAuthor={postAuthor}
                currPostTitle={postTitle}
            />
        </>
    )

}
import { doc, getFirestore, setDoc, getDoc, serverTimestamp } from "firebase/firestore";

export const logStudentGivingDiagnosticFeedback = (feedbackVal, userId, qId, rubricId) => {
  const loggingLocation = `studentDiagnosticFeedback/${userId}`
  const db = getFirestore();
  const studentDiagFeedbackRequest = doc(db, loggingLocation);
  const questionFeedback = {[qId]: {[rubricId]: feedbackVal, studentFeedbackTmestamp: serverTimestamp() }};
  setDoc(studentDiagFeedbackRequest, { questionFeedback }, { merge: true });
}

export const hasStudentGivenDiagnosticFeedback = (userId, qid) => {
  const loggingLocation = `studentDiagnosticFeedback/${userId}`
  const db = getFirestore();
  const studentDiagFeedbackRequest = doc(db, loggingLocation);
  return getDoc(studentDiagFeedbackRequest).then((doc) => {
    if (doc.exists()) {
      const data = doc.data();
      return data.questionFeedback[qid] !== undefined;
    } else {
      return false;
    }
  }).catch((error) => {
    console.log("Error getting document:", error);
  });
}

import { useCourseId } from "hooks/router/useUrlParams";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { bookMenuStyles, chapterMenuStyles, titleStyle } from "./menuItemStyles";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Link, useLocation, useParams } from "react-router-dom";

// Many icons come from https://icons8.com/icons/


const getTitle = (subpage) => {
  switch (subpage) {
    case "studenthome": return "Student";
    case "home": return "Home";
    case "code": return "Code";
    case "learn": return "Learn";
    case "section": return "Section";
    case "forum": return "Forum";
    case "stories": return "Stories";
    case "events": return "Events";
    case "aboutcourse": return "About";
    case "training": return "Training";
    case "assnEditor": return "Assn Editor";
    case "teacherslounge": return "Lounge";
    case "lessoneditor": return "Lesson Edit";
    default: return "";
  }
}

export const TextbookSidebar = ({ chapterData, textbookId }) => {
  const { broken } = useProSidebar();
  const { userData } = useContext(ProfileContext);
  const courseId = useCourseId()
  const outline = chapterData?.outline
  const currentChapter = useParams()?.chapterId
  const { collapsed } = useProSidebar();


  const title = chapterData?.title || 'Python Reader'
  // put breaklines <br/> between each word
  const titleWords = title.split(" ")
  const titleWithBreaks = titleWords.map((word, index) => {
    return <span key={index}>{word} <br /></span>
  })

  const textbookSubpage = textbookId ? textbookId : "textbook"

  return (
    <Sidebar
      breakPoint="lg"
      backgroundColor="#707ec6"
      width="250px"
      collapsedWidth="70px"
      style={{
        borderRight: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Menu menuItemStyles={titleStyle}>

          <MenuItem
            active={false}
            component={<Link to={`/${courseId}/${textbookSubpage}`} />}
          ><h1 className="mt-3">{titleWithBreaks}</h1>
          </MenuItem>
        </Menu>
        <Menu menuItemStyles={bookMenuStyles}>

          {outline.map((section, sectionIndex) => {
            const title = section.sectionTitle
            const chapters = section.chapters

            return <div>
              <CourseMenuItem
                title={title}
                icon={null}
                subpage={null}
              />
              <Menu menuItemStyles={chapterMenuStyles} key={sectionIndex} title={title}>
                {chapters.map((chapter, index) => {

                  return <MenuItem
                    key={index}
                    component={<Link to={`/${courseId}/${textbookSubpage}/${chapter.url}`} />}
                    active={chapter.url == currentChapter}
                  >
                    {chapter.url == currentChapter && <span className="visually-hidden">Current Page: </span>}
                    {chapter.title}
                  </MenuItem>
                })}
              </Menu>
            </div>

          })}



        </Menu>


      </div>
    </Sidebar>
  );
};





const CourseMenuItem = ({ title, icon, subpage }) => {
  const courseId = useCourseId();
  const location = useLocation();
  const { collapsed } = useProSidebar();

  const currSubpage = location.pathname.split("/")[2];
  const active = currSubpage == subpage;
  return (
    <MenuItem
      active={false}
    >
      <span>{title}</span>
    </MenuItem>
  );
};

const SidebarFooter = ({ children, collapsed, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      (
      <div {...rest}>
        <div style={{ marginBottom: "12px" }}></div>
        <Typography fontWeight={600}>Pro Sidebar</Typography>
        <Typography
          variant="caption"
          style={{ letterSpacing: 1, opacity: 0.7 }}
        >
          V 23
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <button>
            <Typography variant="caption" color="#607489" fontWeight={600}>
              View code
            </Typography>
          </button>
        </div>
      </div>
      )
    </div>
  );
};

const Divider = () => {
  return <hr style={{ margin: "10px", backgroundColor: 'blue' }} />
}

const Typography = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};

import { Loading } from "components/loading/Loading"
import { CourseContext } from "contexts/CourseContext";
import { getAuth } from "firebase/auth"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";


/*
* This component is meant to be used directly from Canvas
* It will send the user directly to either the canvas auth page, or the student home if they are already signed in
*/
export const CanvasAuthRedirect = () => {
    const courseId = useCourseId();
    const navigate = useNavigate();

    // check if logged in
    const auth = getAuth()
    const user = auth.currentUser;
    const { courseCanvasAuthLink } = useContext(CourseContext);

    useEffect(() => {
        if(!courseCanvasAuthLink) {
            return;
        }
        if (!user) {
            let authLink = courseCanvasAuthLink;
            const returnUrl = encodeURIComponent(`${window.location.origin}/${courseId}/studenthome`)
            const returnType = 'canvas'
            const stateObj = {
              returnUrl,
              returnType,
              courseId
            }
            // add state param to authLink
            const state = encodeURIComponent(JSON.stringify(stateObj))
            authLink += `&state=${state}`
            window.location.href = authLink;
        } else {
            navigate(`/${courseId}/studenthome`)
        }
    }, [user, courseCanvasAuthLink])




    return (
        <Loading />
    )

}
export const defaultChatGPTQuestions = {
  Coding: [
    // "In Python, how do I sort elements in a list in descending order (from largest to smallest)?",
    // "In Python, how do I write a function to simulate dice roll?",
    "My code keeps crashing. How can I figure out what's wrong?",
    // 'I\'m getting a "___ exception" in my code:\n```\ndef mycode():\n   //add your code here\n```\nCan you help me identify the issue?',
    "```\ndef loop(x):\n  for i in range(10):\n    print(i)\n```\nWhy is this program only printing 0 to 9? Why is not outputting 10?",
    '```\nx = 10\nwhile x != 0:\n  print("go")\n```\nWhy is my while loop not terminating?',
    // "Can you make my code more efficient for me? Also explain why your code is more efficient.\n```\ndef mycode():\n   //add your code here\n```",
    "Can you translate this for loop:\n```\ndef loop(x):\n  for i in range(x):\n    print(i)\n```\ninto a while loop and teach me why you did each step?",
    "Can you translate this while loop:\n```\ndef loop(x):\ncount = 0\nwhile count <= x:\n    print(count)\n    count += 1\n```\ninto a for loop and teach me why you did each step?",
    "What is the correct way to declare a function in Python?",
    // "Can you teach me what is list comprehension in python?",
    // "How do I access a global variable inside a Python function?",
    "What are some best practices for naming variables in Python?",
    // "Should I use single or double quotes for strings in Python?",
    // "What is a variable in programming?",
    // "What is a function in programming?",
    // "What is a class in programming?",
    // "Can you provide an example of a recursive function?",
    // "How can I use a loop to iterate over a list in Python? Explain the step-by-step process.",
    // "What is an example of a data structure in Python?",
    "What's the difference between a for loop and a while loop?",
    "What's the difference between a dictionary and a list in Python?",
    // "What's the difference between a function and method in Python?",
    "How do parameters work in function?",
    "What does 'return' do in a function?",
    "What is the difference between a paramter and a return value?",
    "How do I use if statements to make decisions in my code?",
    "What is the difference between '==' and '=' ?",
    "What does it mean to decompose a problem?",
    "How do I get a random number in python?",
    // "My loop says range(10), but when I print out i it only goes from 0 to 9. Why is this?",
    "What is a constant and when should I use one?",
    "What is a magic number in programming?",
    // "What is a boolean?",
    // "Why should I add comments to my code?",
    "How do I create shapes other than an oval? Can I create a polygon?",
    "How do I get the location of the mouse?",
    "How do I do animation in python?",
    "How do I add text to the Canvas?",
    "How long do I need to sleep for in animation?",
    // "How do I detect a collision on Canvas?",
    "How should I draw the player and the goal?",
    "How can I animate the player going right?",
    "How can I capture keystrokes on Canvas?",
    "How should I check if the player hits the goal?",
    "How should I move the goal to a new location after the player reaches it?"
  ],
  Resources: [
    "What are the most important Python libraries that I should be aware of as a beginniner?",
    // "What are some popular web development frameworks for Python?",
    "Is Python used by any big companies?",
    "How can Python be used to help me build a web app?",
    "How is Python used in real world applications?",
    "What are some recommended books or courses for learning Python?",
    "What are some reputable online resources for learning programming?",
    "What other programming languages can I learn after Python?",
    // "Where can I find tutorials on implementing data structures in Python?",
    // "How do I use an external Python module in my code?",
    // "Can you provide a cheat sheet for common terminal commands?",
    "Where can I find a list of built-in Python functions and their documentation?",
    "What is a built-in function in Python?",
    "What does it mean when someone says 'python library'?",
    // "What are some popular programming forums where I can ask questions and learn from others?",
    // "What are some emerging trends in web development that I should be aware of?",
    // "What is JavaScript and how is it used in web development and what are its advantages and disadvantages compared to Python?",
    // "What is artificial intelligence and what are some ways to apply artificial intelligence to my interests in gardening?",
    "How can I find open-source projects to contribute to as a beginner?",
    "What do I need to know to get started contributing to open-source projects?",
    // "What are some popular open-source proects that use Python?",
    // "Are there any online platforms to find other beginners to collaborate on projects?",
    // "What are the benefits of participating in hackathons or coding competitions?",
    // "How can I find collaboration opportunities related to learning programming and programming?",
    "What are some companies that offer internships for computer science students?",
    "How can I search for entry-level job opportunities in the software development field?",
    "What are some in-demand programming languages and skills that would be helpful for job seekers?",
    "How can I build an impressive programming portfolio as a beginner?",
    // "How can I make my resume more aligned with computer science related roles?",
    // "What types of projects should I include in my programming portfolio to showcase my skills?",
    "What are some common technical interview questions for a software engineer position?",
    "What knowledge is required to succeed in a technical interview?",
    "How can I prepare for behavioral questions in a programming job interview?",
    "How can I find computer science-related events or conferences to attend?",
    "How can I effectively network with professionals in the software development field?",
    "What are some popular online events or webinars in the tech industry?",
    "What does the term 'programming language' mean?",
    "What do the terms 'front end' and 'back end' mean?"
  ],
  Emotional: [
    "My code always has so many bugs. Am I just a bad programmer?",
    "I'm feeling frustrated with my progress in the class. What should I do?",
    "How can I manage my time effectively while learning programming?",
    "How can I set achieveable goals related to learning programming?",
    "I'm feeling overwhelmed by programming concepts. How can I stay motivated and overcome this?",
    "I'm feeling behind on some programming concepts in the class, what should I do?",
    'How can I get "unstuck" when I\'m not making progress on my programming homework assignment?',
    "I'm feeing pretty stressed about taking this class and doing a full-time job at the same time. How can I deal with this?",
    "How can I connect with other computer science students to share experiences and learn together?",
    "I feel like I'm the only one struggling this much in the class. What should I do to not feel so alone?",
  ],
};

import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GenericBackdrop, GenericCenteredContainer } from "./BackgroundStyles"


export const NoCertificatePage = ({certificateInfo } ) => {
    const { isSelf } = certificateInfo

    let component = isSelf ? (
        <StudentNoCertificatePage certificateInfo={certificateInfo} />
    ) : (
        <h1 className="text-center">This user has not completed Code in Place</h1>
    )


    return <GenericBackdrop>
            <GenericCenteredContainer>
                {component}
            </GenericCenteredContainer>
        </GenericBackdrop>

}


const StudentNoCertificatePage = ({certificateInfo}) => {
    const {certificateCompletionMap } = certificateInfo

    if ("attendance" in certificateCompletionMap && !certificateCompletionMap["attendance"].isComplete) {
        return <div>
            <h1>You have not completed the section requirement to recieve a certificate from Code in Place</h1>
        </div>
    }


    else if ("units" in certificateCompletionMap) {
        const units = certificateCompletionMap["units"]
        const incompleteUnits = certificateCompletionMap["units"].filter(unit => !unit.isComplete)
        const missingAssns = incompleteUnits.map(unit => unit.missedAssns)
        const flatMissingAssns = [].concat.apply([], missingAssns)

        return <div>
            <h1>You have not completed the following challenges, which are required to recieve a certificate from Code in Place</h1>
            <AssignmentsLeftList assnList={flatMissingAssns}/>
        </div>
    }

    else {
        return <h1>There is no certificate available for your role</h1>
    
    }

}



const AssignmentsLeftList = ({assnList}) => {
    const [assnDataList, setAssnDataList] = useState([])
    const db = getFirestore()
    const courseId = useCourseId()

    useEffect(() => {
        const fetchAssnList = async () => {
            const dataList = []
            for(let i = 0; i < assnList.length; i++) {
                const assn = assnList[i]
                const assnDoc = doc(db, `assns/${courseId}/assignments/${assn}`)
                const assnData = await getDoc(assnDoc)
                dataList.push({
                    ...assnData.data(),
                    id: assn
                })
            }
            console.log(dataList)
            setAssnDataList(dataList)
        }
        fetchAssnList()
    }, [assnList])


    return <div>
        <ul className="list-group">
            {
                assnDataList.map(assn => {
                    return <li className="list-group-item">
                        <Link to={`/${courseId}/ide/a/${assn.id}`}>{assn.title}</Link>
                    </li>
                })
            }
        </ul>
    </div>

}
import { useContext, useState } from "react";
import { RoadmapContext } from "course/contexts/RoadmapContext";
import { FaArrowAltCircleRight, FaEdit, FaTrash, FaGripVertical } from "react-icons/fa";
import { EditModuleModal } from "../RoadmapAlerts";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { confirmDeleteAlert } from "course/editors/OnEventAlerts";
import { TodoIcon } from "components/completion/TodoIcon";
import { Link, useParams } from "react-router-dom";

export const RoadmapModuleHeader = ({ moduleId, todoModule }) => {

    const { deleteModuleFromDB, editModuleInDB } = useContext(RoadmapContext)
    const [isEditing, setIsEditing] = useState(false)

    const onEditClick = async (e) => {
        e.stopPropagation();
        setIsEditing(true)

    }


    const onFormSubmit = async (data) => {
        if(!data || !data.title) { return; }
        await editModuleInDB(moduleId, data);
        setIsEditing(false)


    }
    
    

    const onDeleteItemClick = async (e) => {
        e.stopPropagation();
        const checkDelete = await confirmDeleteAlert()
        if(!checkDelete) { return; }
        await deleteModuleFromDB( moduleId)
    }

    return (
        <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-baseline justify-content-between">
            <div className="d-flex justify-content-between" key={moduleId}>
                <h4>
                    {todoModule.title}
                </h4>
            </div>
            <div className="ml-4">
                <p className="opacity-25">Release Date: {todoModule.startDate ? todoModule.startDate: "none" }</p>
            </div>

        </div>
    
        <div className="d-flex">
            <div onClick={onEditClick} className="d-flex align-items-center justify-content-center  p-2 m-1 rounded">
                <FaEdit/>
            </div>
            <div className="d-flex align-items-center justify-content-center p-2 m-1 rounded text-danger" onClick={onDeleteItemClick}>
                <FaTrash/>
            </div>
            <EditModuleModal 
                defaultValues={todoModule}
                isModule={true}
                showModal={isEditing}
                onSubmit={onFormSubmit}
                onCancel={() => {  setIsEditing(false) }}
            />
        </div>
    </div>
    )
}




export const RoadmapModuleItem = ({ moduleId, item, relevantSelectionList }) => {
    const { deleteItemFromDB, editItemInDB } = useContext(RoadmapContext)
    const {courseId} = useParams();
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: item.id });
    const [isModalShown, setIsModalShown] = useState(false)
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%"
    };

    const editItem = async (e) => {
        e.stopPropagation();
        setIsModalShown(true)
    }

    const onModalSubmit = async (newData) => {
        setIsModalShown(false)
        if(!newData) { return; }
        await editItemInDB(moduleId, newData)
    }


    const getItemEditLink = () => {
        switch(item.itemType) {
            case "Assignment":
                return `/${courseId}/assneditor/${item.completionId}`
            case "Lesson":
                return `/${courseId}/lessoneditor/${item.completionId}`
            default:
                return null;
        }
    }


    const deleteItem = async(e) => {
        e.stopPropagation();
        const checkDelete = await confirmDeleteAlert()
        if(!checkDelete) { return; }
        await deleteItemFromDB(item.id, moduleId)
    }

    return (
        <div className="d-flex justify-content-between align-items-center" key={item.id}>
            <div ref={setNodeRef} {...attributes} {...listeners} style={style} className="d-flex sidebarButton">
                <FaGripVertical size={24}/>
                <TodoIcon icon={item.itemType}  isComplete={false}/>
                <p className="ml-1">{item.title}</p>
            </div>
            <div className="d-flex">
                {
                    getItemEditLink() ? <Link to={getItemEditLink()} className="btn p-1 m-1"><FaArrowAltCircleRight/></Link> : null
                }
                <button className="btn btn-primary p-1 m-1" onClick={editItem}>
                    <FaEdit/>
                </button>
                <button className="btn btn-danger p-1 m-1" onClick={deleteItem}>
                    <FaTrash/>
                </button>
                <EditModuleModal
                    itemType={item.itemType}
                    defaultValues={item}
                    isModule={false}
                    showModal={isModalShown}
                    onSubmit={onModalSubmit}
                    onCancel={() => {  setIsModalShown(false)}}
                    idList={relevantSelectionList}
                    key={item.id}
                />
            </div>
        </div>
    )
}







import { LeftColTitle } from "ide/LeftCol";
import { IDEContext } from "ide/contexts/IDEContext";
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Switch from "react-switch";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useUserId } from "hooks/user/useUserId";
import styled from "styled-components";

export const SettingsView = () => {
  const { stepSaveOn, setStepSaveOn, screenReadableEditorOn, setScreenReadableEditorOn } = useContext(IDEContext);




  return (
    <div className="h-100">
      <LeftColTitle title="Settings" />
      <SettingsContainer>

        <EditorFontSize />
        <KarelSelectSetting />
        <div className="row mt-4">

          <div className="col-8">

            <p><strong>Save Replay: {stepSaveOn ? "On" : "Off"}</strong></p>
          </div>
          <div className="col-4 text-right" aria-describedby="save-replay-setting-description">
            <Switch
              checked={stepSaveOn}
              onChange={(checked) => setStepSaveOn(checked)}
              height={14}
              width={35}
              onColor={"#085ED6"}
              checkedIcon={false}
              uncheckedIcon={false}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setStepSaveOn(!stepSaveOn);
                }
              }}
            />
          </div>
          <p id="save-replay-setting-description"> Saving Replays allows you to replay an execution after you have finished running. This can be very helpful for learning, but it takes time, and can cause graphics programs with animation
            to appear slower.
            Turning Save Replay off will make your program faster, but won't allow you to use the replay stepper.</p>
        </div>

        <ScreenReadableEditorSwitch />


      </SettingsContainer>
    </div>

  )
}

const EditorFontSize = () => {
  const minFontSize = 8
  const maxFontSize = 36

  // separate the editorFontSize from what we show in the box
  // this allows for the box to hold invalid font sizes (eg if you have 1, as you change from 12 to 14)
  // only update the editorFontSize when the tempEditorFontSize is a valid number

  const { editorFontSize, setEditorFontSize } = useContext(IDEContext);
  const [tempEditorFontSize, setTempEditorFontSize] = useState('' + editorFontSize)

  function isValidFontSize(value) {
    try {
      const num = parseInt(value)
      return num >= minFontSize && num <= maxFontSize
    } catch (e) {
      return false
    }
  }

  useEffect(() => {
    if (isValidFontSize(tempEditorFontSize)) {
      // save it to the editors state
      const newFontSize = parseInt(tempEditorFontSize)
      setEditorFontSize(newFontSize)

      // save it to the database
      const db = getFirestore()
      const userId = useUserId()
      const docRef = doc(db, "users", userId, "settings", "ide");
      setDoc(docRef, {
        editorFontSize: newFontSize
      }, { merge: true })
    }
  }, [tempEditorFontSize])

  const onTextEditorChange = (e) => {
    const value = e.target.value
    setTempEditorFontSize(value)
  }

  return (
    <div className="row mt-4">
      <div className="col-8">
        <p><strong>Editor Font Size</strong></p>
      </div>
      <div className="col-4 text-right ">
        <input
          className="w-100"
          style={{ minWidth: 50, maxWidth: 80 }}
          value={tempEditorFontSize}
          onChange={onTextEditorChange}
          aria-describedby="font size"
        />
      </div>
    </div>

  )
}


const ScreenReadableEditorSwitch = ({ }) => {

  const { screenReadableEditorOn, setScreenReadableEditorOn } = useContext(IDEContext);

  const onChange = async (checked) => {
    const db = getFirestore()
    const userId = useUserId()
    const docRef = doc(db, "users", userId, "settings", "ide");
    await setDoc(docRef, {
      screenReadableEditorOn: checked
    }, { merge: true })
    setScreenReadableEditorOn(checked)
  }


  return (
    <div className="row mt-4">
      <div className="col-8">
        <p ><strong>Screen Readable Editor: {screenReadableEditorOn ? "On" : "Off"}</strong></p>
      </div>
      <div className="col-4 text-right" aria-labelledby="text-box-setting-description">
        <Switch
          checked={screenReadableEditorOn}
          onChange={onChange}
          height={14}
          width={35}
          onColor={"#085ED6"}
          checkedIcon={false}
          uncheckedIcon={false}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(!screenReadableEditorOn);
            }
          }}
          aria-label="editor-switch"
          id="editor-switch"
        />
      </div>
      <p id="text-box-setting-description"> This setting changes the code editor and the terminal to a standard text area, which can be more friendly for screen readers.</p>

    </div>
  )


}


const KarelSelectSetting = () => {
  const { karelType, isKarelSelectOpen, setIsKarelSelectOpen } = useContext(IDEContext)

  return (
    <div className="row mt-4">
      <div className="col-8">
        <p id="karel-setting-description"><strong>Choose your Karel: {karelType}</strong></p>
      </div>
      <div className="col-4 text-right">
        <button className="btn btn-primary" onClick={() => setIsKarelSelectOpen(true)} aria-describedby="karel-setting-description">Change</button>
      </div>

    </div>
  )
}


const SettingsContainer = styled.div`
  overflow-y: auto;
  margin-left:10px;
  height:100%;
`
import Swal from "sweetalert2"
import { FaCopy } from "react-icons/fa"

export const CopyButton = ({ toCopy, icon=<FaCopy/> }) => {
  const onCopy = () => {
    navigator.clipboard.writeText(toCopy)
    // I want a tiny swal
    Swal.fire({
      title: 'Copied to clipboard',
      text: toCopy,
      icon: 'success',
    })
  }
  return <button onClick={onCopy} className="btn btn-sm btn-light">{icon}</button>
}
export const UserLookup = () => {
  return <></>
}

// import React, { useState } from 'react';
// import { Button, Form, FormControl, Alert } from 'react-bootstrap';
// import { getAuth } from "firebase/auth";

// export const UserLookup = () => {
//   const [userId, setUserId] = useState('');
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState('');

//   const handleInputChange = (event) => {
//     setUserId(event.target.value);
//   };

//   const handleLookup = async () => {
//     try {
//       // call a function which can look up the email...
//       const userRecord = await 
//       setEmail(userRecord.email);
//       setError('');
//     } catch (error) {
//       setEmail('');
//       setError(error.message);
//     }
//   };

//   return (
//     <div>
//       <Form inline>
//         <FormControl
//           type="text"
//           placeholder="Enter User ID"
//           className="mr-sm-2"
//           value={userId}
//           onChange={handleInputChange}
//         />
//         <Button variant="outline-success" onClick={handleLookup}>
//           Lookup
//         </Button>
//       </Form>
//       {email && (
//         <Alert variant="success">
//           <strong>Email:</strong> {email}
//         </Alert>
//       )}
//       {error && (
//         <Alert variant="danger">
//           <strong>Error:</strong> {error}
//         </Alert>
//       )}
//     </div>
//   );
// };
import { collection, deleteDoc, doc, getDocs, getFirestore } from "firebase/firestore";
import { addCommentBox } from "./EditorZones";
import Gate from "contexts/Gate";

/**
 * Load inline comments from Firestore and display them in the editor.
 * Each docID represent a line number which is passed to addCommentBox function
 * to add a tiptap comment box at that line number with that firebaseDocPath.
 */
export const loadFeedbacks = async (
  courseId, 
  projectData, 
  editorRef, 
  viewZoneIds, 
  domNodeRefs, 
  userData,
  visibility
) => {
  if (!Gate.hasSectionLeaderRole(userData) && !visibility) { return []; }
  const db = getFirestore();
  const projectId = projectData.uid;
  const lastOpenedFileId = projectData.lastOpenedFile.id;
  const assnId = projectData.assnId;
  const authorId = projectData.editors[0];
  const inlineCommentsCollectionPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/inlineComments/${lastOpenedFileId}`
  const colRef = collection(db, inlineCommentsCollectionPath);  

  try {
    const docList = [];
    const docSetResponse = await getDocs(colRef);
    docSetResponse.docs.forEach(doc => {
      // Listing all doc ids as line numbers
      docList.push(Number(doc.id));
    });
    // Sorting comments so that they are added from the top
    docList.sort((a, b) => a - b);

    for (var i = 0; i < docList.length; i++) {
      // This commentbox takes the line number and adds a tiptap editor at that line
      addCommentBox(false, editorRef, Number(docList[i]), viewZoneIds, domNodeRefs, projectData, userData, courseId);

      // Adding delay to make sure the tiptap comments are loaded and added
      await new Promise(resolve => setTimeout(resolve, 300));
    }
  } catch (e) {
    console.error("Error loading feedbacks:", e);
    return [];
  }
};

/**
 * Delete the feedback from Firestore
 */
export const deleteFeedback = async (inlineCommentDocPath) => {
  const db = getFirestore();
  const docRef = doc(db, inlineCommentDocPath);
  deleteDoc(docRef);
}

import styled from "styled-components";
import { GPTeachChatContainer } from "gpteach/GPTeachChatContainer";

export const GPTeachLesson = ({ data }) => {
  console.log('data', data)
  return <FullContent>
    <CenteredContent>
        <p className="px-3">Practice your teaching with this virtual student. {data.scenario}</p>
        <GPTeachChatContainer data={data} />
    </CenteredContent>
  </FullContent>
}

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width:100%;
`;
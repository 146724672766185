import React, { useState, useEffect, useContext, useRef } from "react"; // every page needs to import react

// if you want to use the auth state
// @ts-ignore

// if you want to interact with the database
import { useParams } from "react-router-dom";
import { NavIDE } from "./NavIDE";
import "react-tabs/style/react-tabs.css";

import {
  PyodideClient,
  PyodideContext,
} from "../components/pyodide/PyodideProvider";
import { IDEContext, IDEProvider } from "./contexts/IDEContext";
import { IDEView } from "./IDEView";
import { MobileIDEView } from "./MobileIDEView";

import {
  createAssnStarterCodeFiles,
  getAllImages,
  getProjectFilesCode,
  publishProject,
  checkIsProjectKarel,
  checkIsProjectConsole,
  checkIsProjectGraphics
} from "./utils/general";
import { getAuth } from "firebase/auth";
import "intro.js/introjs.css";
import { useUserId } from "hooks/user/useUserId";
import { Loading } from "components/loading/Loading";
import { areWorldsEqual } from "components/pyodide/KarelLib/util";
import { getApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useStandardIdeProjectLoader } from "./hooks/loadIdeData";
import { useCodeFuncs, useGetTerminalRef, useStepFunctions } from "./hooks/ideHooks";
import { UiTraining } from "./UiTraining";
import { CollabProvider } from "./sync/CollabContext";
import { useCollabSession } from "./sync/hooks/useCollab";
import { TeachNowStudentNudge } from "course/carehours/TeachNowStudentNudge";
import { useIDETitle } from "./hooks/ideHooks";

export const IDEWithProvider = () => {
  return <IDEProvider useProject={useStandardIdeProjectLoader}>
    <CollabProvider>
      <IDE />
    </CollabProvider>
  </IDEProvider>
}

export const IDE = (props) => {

  /*
  * Props may include the following:
  * files
  * onFileChange
  * editable
  * mainFile
  */

  // Need to wait for pyodide to load before we can render the IDE
  const { isPyodideLoading } = useContext(PyodideContext);
  const {
    projectData,
    assnData,
    loadingError,
    isProjectLoading,
  } = useContext(IDEContext);


  if (loadingError) {
    return <>Error: {loadingError}</>;
  }


  if (isProjectLoading) {
    return <Loading />;
  }

  return <IDEWithData
    {...props}
    projectData={projectData}
    assnData={assnData}
    isPyodideLoading={isPyodideLoading}
  />
};





export const IDEWithData = (props) => {
  const [filesCode, setFilesCode] = useState(null);
  const projectData = props.projectData;
  const assnData = props.assnData;
    // set the tab title to the project title

  useIDETitle(assnData);

  // Fetch code from files
  useEffect(() => {
    const getFilesCode = async () => {
      const data = await getProjectFilesCode(projectData.uid);
      setFilesCode(data);
    };
    getFilesCode().catch(console.error);
  }, []);


  return filesCode ? (
    <>
      <TeachNowStudentNudge projId={projectData.uid} />
      <IDEWithFiles
        isPyodideLoading={props.isPyodideLoading}
        assnData={assnData}
        projectData={projectData}
        filesCode={filesCode}
        setFilesCode={setFilesCode}
      />
    </>
  ) : null;
};





const IDEWithFiles = ({
  filesCode,
  setFilesCode,
  projectData,
  assnData,
  isPyodideLoading,
}) => {

  const { courseId } = useParams();
  const userId = useUserId();

  const [selectedMobileTab, setSelectedMobileTab] = useState(
    assnData ? "Problem" : "Code"
  );

  // File that is currently open; you can only open a single file at any given time
  const [currentFile, setCurrentFile] = useState(
    projectData.lastOpenedFile ?? getDefaultFile(projectData)
  );
  // Current file structure in nested dictionary format
  const [fileStructure, setFileStructure] = useState(projectData.files);
  const ideContext = useContext(IDEContext);
  const {
    setStepPtr,
    setHasHistory,
    terminalRef,
    pyodideClientRef,
    setKarelWorldState,
    setStepMode,
    setStepList,
    setStepLogs,
    karelWorldState,
    defaultKarelWorldState,
    terminalViewState,
    setTerminalViewState,
    isRunning,
    setIsRunning,
    isTabletOrLarger,
    stepSaveOn,
  } = ideContext;

  useCollabSession()
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isGraphics = checkIsProjectGraphics(projectData, assnData);

  const { handleError, runCode } =  useCodeFuncs(userId, projectData, currentFile, filesCode, fileStructure, isKarel, isConsole, isGraphics);
  const { step, stepListSize } = useStepFunctions(); 


  const endRepl = () => {
    const term = terminalRef.current;
    term.toggleRepl();
  };
  
  const getTerminalRef = useGetTerminalRef(
    runCode,
    isKarel,
    endRepl);

  useEffect(() => {
    // this code only runs once per component
    pyodideClientRef.current = new PyodideClient();
    const pyodideClient = pyodideClientRef.current;
    if (terminalRef.current) terminalRef.current.dispose()
    terminalRef.current = getTerminalRef()
    const terminal = terminalRef.current;

    pyodideClient.setHandlers(
      onRunEnd,
      (stdout, nl = true) => {
        if (terminalViewState === "minimized") {
          setTerminalViewState("standard");
        }
        terminal.handleStdout(stdout, nl);
      },
      (stderr, code) => handleError(stderr, code),
      (pmt) => terminal.handleStdin(pmt),
      (filePath) => {
        const images = getAllImages(fileStructure);
        return images[filePath];
      }
    );
    terminalRef.current.initTerm();
  }, []);


  const onRunEnd = () => {
    endTerminal();
    setHasHistory(stepSaveOn);
    if (stepSaveOn) {
      const { list, logs } = pyodideClientRef.current.getStepInfo();
      setStepList(list);
      setStepLogs(logs);
      const stepsLength = list.length - 1;
      setStepPtr(stepsLength);
    }
  };

  const endTerminal = () => {
    const terminal = terminalRef.current;
    terminal.handleEnd();
  };






  // When you edit the contents of a file, update the local 'files'
  // data structure to reflect these changes
  const onFileCodeUpdate = (fileId, newContent) => {
    const newFiles = { ...filesCode };
    newFiles[fileId] = {
      content: newContent,
    };

    setFilesCode(newFiles);
  };

  // When we press 'revert to starter code', pull the default starter code files
  // in assnData!
  const revertToStarterCode = async () => {
    // @Joeseph Tey, there is a bug here. If someone has the project
    // open in two places this breaks!
    const starterFiles = await createAssnStarterCodeFiles(assnData, projectData.uid);
    setFileStructure([
      {
        type: "folder",
        name: assnData?.metaData.title,
        files: starterFiles,
      },
    ]);
    setCurrentFile({
      id: starterFiles[0].id,
      name: starterFiles[0].name,
    });

    const data = await getProjectFilesCode(projectData.uid);
    setFilesCode(data);
  };



  const startStopButtonClicked = async () => {
    if (isKarel) {
      if (!isRunning && !isPyodideLoading) {
        if (areWorldsEqual(defaultKarelWorldState, karelWorldState)) {
          // RUN CODE
          setIsRunning(true);
          if (!isTabletOrLarger) {
            setSelectedMobileTab("Output");
          }
          await runCode();
        } else {
          // RESET
          setKarelWorldState(defaultKarelWorldState);
          setHasHistory(false);
          setStepMode(false);
        }
      } else {
        // STOP
        pyodideClientRef.current.raisePyStopFlag();
      }
    } else {
      if (!isRunning) {
        // RUN CODE
        setIsRunning(true);
        if (!isTabletOrLarger) {
          setSelectedMobileTab("Output");
        }
        await runCode();
      } else {
        // STOP
        if (
          terminalRef &&
          terminalRef.current &&
          terminalRef.current.replMode
        ) {
          terminalRef.current.toggleRepl();
        }
        if (terminalRef.current._input) {
          terminalRef.current.endStdin()
        }
        pyodideClientRef.current.raisePyStopFlag();
      }
    }
  };



  // publishing a project
  const onPublish = async (mainFile, showTerminal, title) => {
    const auth = getAuth();
    const user = auth.currentUser;

    const userFirebaseDoc = (
      await getDoc(doc(getFirestore(getApp()), "users", user.uid))
    ).data();

    const userData = {
      id: user.uid,
      // email: user.email,
      displayName: userFirebaseDoc.displayName,
      photoURL: user?.photoURL || "",
    };

    return await publishProject(
      projectData.uid,
      mainFile,
      fileStructure,
      filesCode,
      courseId,
      userData,
      assnData,
      showTerminal,
      defaultKarelWorldState,
      title
    );
  };

  return (
    <>
      <UiTraining />
      <div className="vh-100 vw-100 d-flex flex-column">
        <NavIDE
          isRunning={isRunning}
          startStopButtonClicked={startStopButtonClicked}
          projectData={projectData}
          assnData={assnData}
          stepFunc={step}
          stepListSize={stepListSize}
          onPublish={onPublish}
          fileStructure={fileStructure}
        />

        {isTabletOrLarger ? (
          <IDEView
            onFileCodeUpdate={onFileCodeUpdate}
            currFile={currentFile}
            terminalRef={terminalRef}
            projectData={projectData}
            assnData={assnData}
            revertToStarterFn={revertToStarterCode}
            onChangeFile={(newFile) => {
              setCurrentFile(newFile);
            }}
            fileStructure={fileStructure}
            setFileStructure={setFileStructure}
          />
        ) : (
          <MobileIDEView
            onFileCodeUpdate={onFileCodeUpdate}
            currFile={currentFile}
            selectedMobileTab={selectedMobileTab}
            setSelectedMobileTab={setSelectedMobileTab}
            projectData={projectData}
            assnData={assnData}
            revertToStarterFn={revertToStarterCode}
            onChangeFile={(newFile) => {
              setCurrentFile(newFile);
            }}
            fileStructure={fileStructure}
            setFileStructure={setFileStructure}
          />
        )}
      </div>

    </>
  );
};

const getDefaultFile = (projectData) => {
  const files = projectData?.files;
  if (!files) return null;

  const rootFolder = files[0];
  for (let file of rootFolder.files) {
    const name = file.name;
    const isPython = name.endsWith(".py");
    if (isPython) {
      return file;
    }
  }
};

export default IDE;

import React, { useContext } from "react";
import { Formik, Form, useField, Field } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";


import {
  convertTimeToLocalTimezoneForSmallGroup,
  smallGroupTimeToDateTime,
  ALL_TIMEZONES,
  getSectionTimeStr,
  getTimezoneName,
  getTimeInTimezone,
  getLocalUTCTimezone,
} from "components/timezones/timezoneHelper";
import { ShowLocalTime } from "./ShowLocalTime";
import { CourseContext } from "contexts/CourseContext";


const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: "select" });

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field as="select" className="select-input" {...field} {...props}>
        {props.children}
      </Field>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};


// get the label for the ith option given user is in timeZoneStr (eg "UTC+4:00")

const TranslatedTimes = (props) => {

  let tz = getLocalUTCTimezone()
  let i = props.sectionTimeIndex;
  console.log(tz)
  let timeZones = [
    { city: "San Francisco", timeZone: "UTC-7:00" },
    { city: "New York", timeZone: "UTC-4:00" },
    { city: "London", timeZone: "UTC+1:00" },
    { city: "Istanbul", timeZone: "UTC+3:00" },
    { city: "Singapore", timeZone: "UTC+8:00" },
  ];
  const listItems = timeZones.map((value) => (
    <li key={value.city}>
      {value["city"]}: {getSectionTimeStr(value["timeZone"], i)}
    </li>
  ));
  return (
    <div className="card mb-5">
      <div className="card-body">
        <p>
          In case you are curious, here is the local time of your section in
          different cities:
        </p>
        <ul>
          {listItems}
          <li key="tz">Your timezone: {getSectionTimeStr(tz, i)}</li>
        </ul>
      </div>
    </div>
  );

}

const sortAllSmallGroups = (allSmallGroups) => {
  return Object.keys(allSmallGroups).sort((sg1Id, sg2Id) => {
    const sg1 = allSmallGroups[sg1Id];
    const sg2 = allSmallGroups[sg2Id];

    const sg1Date = smallGroupTimeToDateTime(sg1.time, sg1.day);
    const sg2Date = smallGroupTimeToDateTime(sg2.time, sg2.day);
    return sg1Date - sg2Date;
  });
};

const OnboardForm = (props) => {
  let initValues = {};
  let initIndex = props.sectionTimeIndex
  initValues.sectionTimeIndex = initIndex;
  const timeZone = getLocalUTCTimezone();
  const { sectionTimeDelta } = useContext(CourseContext);

  console.log(initValues)

  const validationSchema = null

  const isClosed = true

  return (
    <div>
      <h5>Section times</h5>
      <p className="mb-5">
        You will teach a 50 minute section once a week for 6 weeks. Your first
        section will be the week of April 24th, and your last section will be
        the week of May 29th. Sections are during the week between
        {" "}{getSectionTimeStr(timeZone, 0)} and {getSectionTimeStr(timeZone, sectionTimeDelta)}.
      </p>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values);
        }}
      >
        {({ errors, touched, validateField, validateForm }) => {
          const userTz = getLocalUTCTimezone()
          return <Form style={{ width: "100%" }}>
            <ShowLocalTime />


            <SelectInput
              label="Section time"
              name="sectionTimeIndex"
              disabled={isClosed}
              description={
                isClosed ?
                  <span>
                    This is the time you selected. If you can no longer make that time contact the course staff asap (codeinplace@stanford.edu):
                  </span> :
                  <span>
                    This is the time you will teach your section each week. Please select a time that works for you.
                  </span>
              }
              onChange={props.handleSectionChange}
            >
              <option value="none">Please select a time</option>
              {Array.from(Array(46).keys()).map((i) => (
                <option value={i} key={i}>
                  {getSectionTimeStr(userTz, i)}
                </option>
              ))}
            </SelectInput>

            <TranslatedTimes
              sectionTimeIndex={props.sectionTimeIndex}
            />

            <hr />

            {props.slSmallGroup.time && (
              <p>
                In your local timezone ({props.slAboutData.userTimezone}
                ), your small group will be on{" "}
                <strong>
                  {convertTimeToLocalTimezoneForSmallGroup(
                    props.slSmallGroup.time,
                    props.slSmallGroup.day,
                    props.slAboutData.userTimezone
                  )}
                </strong>
                .
              </p>
            )}





            <Link className="btn btn-secondary" to={`/${props.courseId}/onboarding/teach`}>
              Back
            </Link>
          </Form>
        }}
      </Formik>
    </div>
  );

}

export default OnboardForm;

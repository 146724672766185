import Swal from "sweetalert2"

export const dataValidationError = async () => {
  await Swal.fire(
    'Not Saved',
    'Make sure all parts of the form are filled out correctly',
    'error'
  )
}


export const duplicateDocId = async () => {
  await Swal.fire({
    title: "You used a pre-existing ID for your item",
    text: "Please try again with a unique ID :)"
  })
}

export const successfulDataSave = async () => {
  await Swal.fire(
    'Saved',
    'Your action was a success :)',
    'success'
  )
}

export const unsuccessfulDataSave = async () => {
  await Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Could not add data to the database',
  })
}

export const getSingleValue = async (alertTitle, inputLabel, inputValue) => {
  const formResult = await Swal.fire({
    title: alertTitle,
    input: 'text',
    inputLabel: inputLabel,
    inputValue: inputValue,
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to write something!'
      }
    }
  })
  if (formResult.isConfirmed) {
    return formResult.value;
  } else {
    return null;
  }
}

export const getDoubleValue = async (title, label1, label2, label2IsId = true, currValue1 = '', currValue2 = '') => {
  const { value: formValues } = await Swal.fire({
    title: title,
    width: 700,
    html:
      `<strong>${label1}</strong>
          <input id="it-1" value="${currValue1}" class="swal2-input" style="width: min(70%, 400px)">
          <br></br>
          <strong>${label2}</strong>
          <input id="it-2" value="${currValue2}" class="swal2-input" style="width: min(70%, 400px)">`,
    focusConfirm: false,
    inputValidator: (value) => {
      if (!document.getElementById('it-2').value || !document.getElementById('it-1').value) {
        return 'You need to write something for both boxes!'
      }
    },
    preConfirm: () => {
      return {
        item1: document.getElementById('it-1').value,
        item2: document.getElementById('it-2').value
      }
    }
  })
  return formValues;
}


export const confirmDeleteAlert = async () => {
  const result = await Swal.fire({
    title: 'Are you sure you want to delete this data?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  })
  return result.isConfirmed;
}


export const successfulDeletionAlert = async () => {
  await Swal.fire(
    'Deleted!',
    'This data has been deleted.',
    'success'
  )
}


export const unsuccessfulDeletionAlert = async () => {
  await Swal.fire(
    'Error!',
    'There was an error deleting your lesson',
    'error'
  )
}


export const invalidIdSwal = async () => {
  await Swal.fire(
    'Error!',
    'the ID you entered is invalid',
    'error'
  )
}




export const isValidId = (str) => {
  return /^[a-z0-9-]+$/.test(str);
}
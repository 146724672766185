// Toast box with react-bootstrap to display in the top-left corner

import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';


const ToastButton = (props) => {
    const {className, text, onClick} = props
    return (
        <button
            className={className}
            onClick={onClick}
            style={{
                fontSize: 'inherit',

            }}
            >
            {text}
        </button>
    )
}


export const ToastBox = (props) => {
    // A box that can be used to display a message to the user
    const [isDismissed, setIsDismissed] = useState(false);
    const {show=true, dismissIsCancel=true, title, text, successText, cancelText, onSuccess, onCancel, timeoutSeconds=0, onTimeout=null } = props;

    // If a timeout is specified, set a timer to dismiss the toast
    if (timeoutSeconds > 0) {
        const timeoutId = setTimeout(() => {
            setIsDismissed(true);
            if (onTimeout) {
                onTimeout();
            }
        }, timeoutSeconds * 1000);
        // clearTimeout(timeoutId);
    }

    return (
        <Toast
        style={{maxWidth: '300px'}}
        show={show && !isDismissed} onClose={() => {
            // hide the toast and set timer to show toast again
            setIsDismissed(true);
            if(dismissIsCancel) {
                // if dismiss is same as cancel, call the onCancel function
                onCancel();
            } else {
                // otherwise have this show up again
                setTimeout(() => setIsDismissed(false), 3000);
            }
        }}>
            <Toast.Header>
                <strong className="mr-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>
                    <div>
                    <p>{text}</p>
                    <div>
                        {props.children}
                    </div>
                    <div className="d-flex justify-content-around">
                        {successText !== null ?
                            <ToastButton className="btn btn-primary" text={successText} onClick={onSuccess}/>
                            : null
                        }
                        {cancelText !== null ?
                            <ToastButton className="btn btn-warning" text={cancelText} onClick={onCancel}/>
                            : null
                        }
                    </div>
                    </div>
            </Toast.Body>
        </Toast>
      );


}

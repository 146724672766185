import { TimezoneLink } from "components/timezones/TimezoneLink";
import { Card } from "react-bootstrap"

export const ClassNotOpen = ({ }) => {
  const dateStr = "2023-04-24T09:00:00-07:00"
  // i want to display this in local time
  const date = new Date(dateStr)
  const day = date.getDate();
  const suffix = getDaySuffix(day);
  const dateStrLocal = date.toLocaleString('en-US', { 
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric', 
      hour12: true 
  })

  let formattedDate = dateStrLocal.replace(day, day + suffix);
  formattedDate = dateStrLocal.replace(" AM", "am");
  formattedDate = dateStrLocal.replace(" PM", "pm");

  console.log(formattedDate);

  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100vh' }}>
    <Card>
      <Card.Body>
        <h3 style={{textAlign:'center'}}>Code in Place starts<br/>{formattedDate}</h3>
        <h5>In your local time zone, <TimezoneLink/></h5>
      </Card.Body>
    </Card>
    <div style={{ height: '5px' }} />
  </div>
}

export const SectionLeadingNotOpen = ({ }) => {
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100vh' }}>
    <Card>
      <Card.Body>
        <h4>Section Leader Training starts on April 20th, 9am Pacific Time</h4>
      </Card.Body>
    </Card>
    <div style={{ height: '5px' }} />
  </div>
}

export const StoriesNotOpen = ({ }) => {
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', height: '100vh' }}>
    <Card>
      <Card.Body>
        <h4>Stories are coming soon!</h4>
      </Card.Body>
    </Card>
    <div style={{ height: '5px' }} />
  </div>
}


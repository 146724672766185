import React, { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';

const navHeight = 57


export const Takeaways = () => {

  return <div>
    <Fullpage 
      backgroundColor="#4496e5" 
      offset={navHeight}
      text="What will I get out of Code in Place 2024?"
      explanation=""
      scrollText="Scroll to find out..."
    />
    <Fullpage 
      backgroundColor="#ed6a50" 
      offset={navHeight}
      text="1. Meet once a week with a section leader and a small group"
      explanation={`A special thing about Code in Place: the teachers. Our course will have a massive number of teachers who are here to help you learn. One of those teachers will be your section leader! You will be part of in a small team of about 10 students who learn together. You will meet once a week for an hour guided by one of our selected section leaders. Sections are a cornerstone of the Stanford experience.`}
      scrollText="Tell me more..."
    />
    <Fullpage 
      
      backgroundColor="#e54496" 
      offset={navHeight}
      text="2. Create a final project"
      explanation="One of the joys of programming that you get to dream up and create your own projects. You will have the opportunity to create a final project, supported by your peers, that you can share your project with  the world."
      scrollText="Is that all?"
    />

<Fullpage 
      backgroundColor="#68b319" 
      offset={navHeight}
      text="3. A festival of learning!"
      explanation="It is a special experience to be part of a group of thousands of students who are learning the same thing at the same time, supported by thousands of teachers who care about learning. 
       "
      scrollText="Ok sure, but what else?"
    />
    
    <Fullpage 
      
      backgroundColor="#4496e5" 
      offset={navHeight}
      text="4. Be part of something new"
      explanation="There must be a better way to learn online! We're doing this 
      because we love teaching, but also because we want to improve free online learning globally. 
      Code in Place is a first-of-its-kind type of course and we are using it as a way to imagine a brighter future for learning. We are trying to build novel community and novel tools to make learning more fun and more effective.
      We invite you to join in on this exciting conversation."
      scrollText="Wahoo! Sign me up!"
    />
  </div>
}

const Fullpage = ({ backgroundColor, offset, text, explanation, scrollText}) => {
  let heightStr = '100vh'
  if(offset > 0) {
    heightStr = `calc(100vh - ${offset}px)`
  }

  return <div className="d-flex flex-column justify-content-between align-items-center" style={{ backgroundColor:backgroundColor, height: heightStr, width: '100vw', color:'white', textAlign:'center' }}>
    <span></span>
    <h1 style={{maxWidth:700}}>{text}</h1>
    <Explanation explanation={explanation} />
    <h4>{scrollText}</h4>
  </div>
}

const Explanation = ({ explanation }) => {
  if(explanation === '') {
    return <></>
  }
  return <div style={explanationStyle}>
    {explanation}
  </div>
}
const blackWithAlpha = (alpha) => {
  return `rgba(0,0,0,${1-alpha})`
}

const explanationStyle = {
  maxWidth: '700px',
  fontSize: '1.2em',
  backgroundColor:blackWithAlpha(0.8),
  padding: '20px',
  borderRadius: '10px',
  textAlign: 'left' as const
}

import { makeListOfNouns, shuffleArray } from "./primitiveManipulation";
import { getAllStudents, getScenarios } from "./database_reading";

// TODO: make a random choice (not scalable)
// Maps scenarioNum to learning goals
function getLearningGoals(scenarioNum) {
  switch (scenarioNum) {
    case 1:
      return [
        "Understand student misconceptions and hidden blockers",
        "Create a safe, inclusive learning environment",
      ];
    case 2:
      return ["Facilitate students helping each other"];
    // case 3:
    // 	return [
    // 		"Understand student misconceptions and hidden blockers",
    // 		"Create a safe, inclusive learning environment",
    // 	];
    // case 4:
    // case 5:
    // case 6:
    // 	return ["Facilitate students helping each other"];
    // This really shouldn't happen, but...
    default:
      return ["Help the students understand"];
  }
}

/* ################################ Exported Functions ############################## */

export async function selectStudents(num) {
  // Randomly pick n students by shuffling and picking the first n
  return shuffleArray(await getAllStudents()).slice(0, num);
}

export async function selectScenario() {
  // Randomly pick a scenario by shuffling and picking the first one
  return shuffleArray(await getScenarios())[0];
}

// Get the data for ONE scenario
export function getStaticData(Participant) {
  const students = Participant.students;

  const studentNames = students.map((student) => {
    return student.name;
  });

  // Make the context (left side bar)
  const context = `${makeListOfNouns(
    studentNames
  )} go to office hours with their very kind TA (whom they call SL, for section leader). ${
    Participant.scenario["text"]
  }`;

  // Make the HTML tags (for GPT)
  const htmlTags = `<span className=${students
    .map((student, i) => {
      return `${student.name}-${student["keywords"].join("-")}`;
    })
    .join(" ")} style="${Participant.scenario["keywords"].join(
    "-"
  )}" context="intro-cs-class-python" ${students
    .map((student) => {
      return "id='" + student.name + "-goes-to-office-hours'";
    })
    .join(" ")}></span>`;

  // Put the scenario info in the format GPT expects
  let gptPrompt = students
    .map((student) => {
      return `Student Persona:\n${student["studentBio"]}\n`;
    })
    .concat(
      // context +
      // 	" The students are discreet about their personalities, but still act in character. Every student message ends with <EOM>. The students put code inside <CODE_EDITOR></CODE_EDITOR> tags. \n\n"
      context +
        " The students are discreet and subtle about their personalities, but still act in character. The students interact to try to help each other, though sometimes are wrong. Every student message ends with <EOM>.\n\n"
    )
    .concat(htmlTags);

  return {
    studentBios: students.map((student) => student["StudentBios"]),
    context: context,
    title: `Online Office Hours`,
    scenario: Participant.scenario["text"],
    learningGoals: getLearningGoals(1),
    startingPrompt: gptPrompt,
    gptRecap: htmlTags,
    studentNames: studentNames,
  };
}

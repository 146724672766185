import { getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import { SLOnboardingContext } from "onboarding/sectionLeaderOnboarding/contexts/SLOnboardingContext";
import VolunteerAgreementForm from "./VolunteerAgreementForm";
import { useNavigate } from "react-router";
import { ProfileContext } from "contexts/ProfileContext";
import { ResponsiveCard, SetupOuter } from "onboarding/components/SetupOuter";

export const VolunteerAgreement = () => {
  const navigate = useNavigate()
  const courseId = useCourseId()
  const {userData} = useContext(ProfileContext)
  const {onboardingData,editOnboarding} = useContext(SLOnboardingContext)
  const displayName = userData.displayName
  const agreementData = onboardingData?.agreementData || {}



  const onSubmit = async (values)=> {
    const newData = {...values}
    newData.signed = values?.signature1 === displayName
    await editOnboarding({
      agreementData:newData
    })
    navigate(`/${courseId}/onboarding/teach`)

  }

    return <SetupOuter>
        <ResponsiveCard style={{ margin: "auto", padding: "20px" }}>
          <div>
            <VolunteerAgreementForm
              displayName={displayName}
              agreementData={agreementData}
              onSubmit={onSubmit}
              courseId = {courseId}
            />
            
          </div>
        </ResponsiveCard>
      </SetupOuter>
}
//   return <div
//         style={{
//           width: "720px",
//           maxWidth: "90vw",
//           margin: "auto",
//           marginTop: "50px",
//           marginBottom: "50px",
//         }}
//       >
//         <Card style={{ margin: "auto", padding: "20px" }}>
//           <div>
//             <VolunteerAgreementForm
//               displayName={displayName}
//               agreementData={agreementData}
//               onSubmit={onSubmit}
//               courseId = {courseId}
//             />
            
//           </div>
//         </Card>
//       </div>
// }


import { createContext, useEffect, useState } from "react";
import { setPyodide, PyodideApi } from "./PyodideRunner";

// this is akin to a global variable state
export const PyodideContext = createContext(null);

export class PyodideClient {
  constructor() {
    this.pyapi = new PyodideApi();
  }

  async runCode(code, activeFile={name: "main.py"}, stepMode = true, uninterrupted = 2000, canvasId="canvas") {
    return await this.pyapi.runPython(code, activeFile, stepMode, uninterrupted, 0, canvasId);
  }

  async testCode(code, input = [], filename = { name: "test.py" }) {
    return this.pyapi.test(code, input, filename);
  }

  setHandlers(
    endFunc,
    outputFunc,
    errorFunc,
    inputFunc = () => console.log("input"),
    getImageUrl = () => {}
  ) {
    this.pyapi.setUserDefinedFuncs(
      endFunc,
      outputFunc,
      errorFunc,
      inputFunc,
      getImageUrl
    );
  }

  isPyRunning() {
    return this.pyapi.getRunningFlag();
  }

  raisePyStopFlag() {
    this.pyapi.setRunningFlag();
  }

  getStepInfo() {
    return {
      list: self.stepInfo.frames,
      logs: self.stepInfo.stdout,
    };
  }

  setKarelSleepTime(time) {
    self.karelInfo.sleepTime = time;
  }

  stepKarel(state) {
    if (self.karelInfo.client) {
      self.karelInfo.client.setKarelState(state);
    }
  }

  stepGraphics(state) {
    if (self.canvasInfo.client) {
      self.canvasInfo.client.redrawStep(state);
    }
  }

  async loadFiles(fileStructure, fileCode) {
    return await this.pyapi.loadFiles(fileStructure, fileCode);
  }

  async loadFile(path, contents) {
    return await this.pyapi.loadFile(path, contents);
  }

  setKarelInfo(startState, setWorldState, sleepTime) {
    this.pyapi.setKarelInfo(startState, setWorldState, sleepTime);
  }

  async setReplSession(exitRepl) {
    return await this.pyapi.enterReplMode(exitRepl)
  }

  async handleReplCommand(command) {
    return await this.pyapi.interpreter(command)
  }

  async endRepl() {
    return await this.pyapi.endRepl();
  }
}

const loadCodeInPlacePyodide = async (setPyodideLoadingState) => {
  console.log("Loading Pyodide");
  setPyodideLoadingState("Pyodide Loading ...");
  await setPyodide();
};

export default function PyodideProvider({ children }) {
  const [isPyodideLoading, setIsPyodideLoading] = useState(true);
  const [pyodideLoadingState, setpyodideLoadingState] = useState(true);

  useEffect(() => {
    loadCodeInPlacePyodide(setpyodideLoadingState).then(() => {
      setIsPyodideLoading(false);
    });
  }, []);

  return (
    <PyodideContext.Provider
      value={{
        isPyodideLoading,
      }}
    >
      {children}
    </PyodideContext.Provider>
  );
}

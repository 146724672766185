import { useCourseId } from 'hooks/router/useUrlParams';
import { useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ReviewContext } from 'review/contexts/ReviewContext';
import { isGradeScore, gradeToNumber } from 'review/contexts/ReviewUtil';
export const ReviewStats = () => {
  const courseId = useCourseId()
  return <div className='container-lg mt-3'>
    <h2><Link
      className="btn btn-light btn-lg"
      role="button"
      to={`/${courseId}/review`}
    ><FaHome /></Link> Review Stats</h2>
    <hr/>
    <br />
    <GraderTable />
  </div>
}

const GraderTable = () => {
  const {
    gradebook,
    graders,
    reviewLoading,
    gradeDataError } = useContext(ReviewContext)




  const graderStats = {}
  for (const studentId in gradebook) {
    const grades = gradebook[studentId];
    for (const graderId in grades) {
      const grade = grades[graderId];
      if (isGradeScore(grade)) {
        if (!(graderId in graderStats)) {
          graderStats[graderId] = {
            totalScore: 0,
            count: 0
          }
        }
        graderStats[graderId].totalScore += gradeToNumber(grade);
        graderStats[graderId].count += 1;
      }
    }
  }

  const graderRows = [];
  for (const graderId in graderStats) {
    const stats = graderStats[graderId];
    const avgScore = stats.totalScore / stats.count;
    graderRows.push({
      id: graderId,
      name: graders[graderId] ? graders[graderId] : 'Unknown',
      count: stats.count,
      avgScore: avgScore.toFixed(2)
    })
  }
  const columns = [{
    dataField: 'id',
    text: 'Grader ID',
    sort: true
  }, {
    dataField: 'name',
    text: 'Grader Name',
    sort: true
  }, {
    dataField: 'count',
    text: 'Num Graded',
    sort: true
  }, {
    dataField: 'avgScore',
    text: 'Average Score',
    sort: true
  }];
  return <BootstrapTable bordered={false} striped={true} hover={true} bootstrap4={true} keyField='id' data={graderRows} columns={columns} />
}


import { Alert, Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useCourseId } from 'hooks/router/useUrlParams';
import { getFunctions, httpsCallable } from "firebase/functions"
import Swal from "sweetalert2";
import { EditInfoModal } from './EditInfoModal';

export const ConnectedWidget = ({ 
    connectionData, 
    userId, 
    viewerId, 
    userData,
    status,
    setStatus,
    setToggle,
    setConnectionData
}) => {
    const courseId = useCourseId()
    const functions = getFunctions();
    const removeConnection = httpsCallable(functions, "removeConnection");

    const [showRemoveConnectionModal, setShowRemoveConnectionModal] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false)

    const handleClose = () => setShowRemoveConnectionModal(false);
    const handleShow = () => setShowRemoveConnectionModal(true);

    const handleRemoveConnection = async () => {
        setRemoveLoading(true)

        const res = await removeConnection({
            viewerId, 
            userId,
        })
        // @ts-ignore
        if (res.data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Connection removed.',
            })
            setRemoveLoading(false)
            setStatus('notConnected')
            setConnectionData(() => {return null})
            setToggle('')
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setRemoveLoading(false)
        }
        handleClose()
    }

    const senderId = connectionData.senderId; // the person who initiated the connection by sending a request
    const receiverId = userId === senderId ? viewerId : userId; // the other person in the connection

    const senderMessage = connectionData[`${senderId}_msg`];
    const senderContact = connectionData[`${senderId}_contact`];
    
    const receiverMessage = connectionData[`${receiverId}_msg`];
    const receiverContact = connectionData[`${receiverId}_contact`];


    return <>
        <Alert className='mt-3' style={{backgroundColor:"#EFF1FB"}}>
            <Alert.Heading>You are connected!&nbsp;🌱</Alert.Heading>
            <hr />
            <label className="form-label">Their Message</label>
            <p>
                {senderMessage}
            </p>
            <label className="form-label">Their Contact Info</label>
            <p>
                {senderContact}
            </p>
            <hr />
            <label className="form-label">Your Message</label>
            <p>
                {receiverMessage}
            </p>
            <label className="form-label">Your Contact Info</label>
            <p>
                {receiverContact}
            </p>
            <div className="d-flex justify-content-end">
            <hr />
                <EditInfoModal
                    connectionData={connectionData}
                    userId={userId}
                    viewerId={viewerId}
                    userData={userData}
                    status={status}
                    setConnectionData={setConnectionData}
                    buttonDisabled={removeLoading}
                />
                <Button variant="outline-danger" onClick={handleShow}className={"ml-2"}>
                    Remove connection
                </Button>
            </div>
        </Alert>

        <Modal show={showRemoveConnectionModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove connection to {userData.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Removing your connection hides both of your messages and contact information. You can always request to connect again later.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleRemoveConnection} disabled={removeLoading}>
            {removeLoading ? 'Removing...' : 'Confirm removal'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
}
import { useContext, useState } from "react";
import { FaFastForward, FaPlay } from "react-icons/fa";
import styled from "styled-components";
import { YouTubeLesson } from "./YoutubeLesson";
import { useNavigate } from "react-router";
import { LessonContext } from "../LessonContext";

const ENGLISH = 'English'
const SPANISH = 'Español'
const languages = [SPANISH, ENGLISH]

export const SkippableVideo = ({ onComplete, data }) => {
  // 'splash', 'video'
  const [isVideo, setIsVideo] = useState(false)

  if (isVideo) {
    return <YouTubeLesson onComplete={onComplete} data={data} />
  }
  return <SkippableSplash data={data} setIsVideo={setIsVideo} onComplete={onComplete} />
}

const SkippableSplash = ({ data, setIsVideo }) => {
  const {nextSlide} = useContext(LessonContext)
  return <FullContent>
    <CenteredContent>
      <button
        className="btn btn-primary btn-lg"
        style={{padding:30}}
        onClick={() => setIsVideo(true)}
      >
        <FaPlay /> {data.skippableMessage}
      </button>
      <button
        className="btn btn-light btn-lg mt-3"
        style={{padding:30}}
        onClick={() => nextSlide()}
      >
        <FaFastForward /> Skip video
      </button>


    </CenteredContent>
  </FullContent>
}

const ButtonCard = ({ text, icon, to }) => {

  const state = {
    from: '/welcome/choice'
  }
  // on click you should navigate to the href passed as "to"
  const navigate = useNavigate();
  return <ButtonCardOuter className="card mb-3">


    <ButtonCardInner>

      <div style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
        {icon}
      </div>
      <CardText>
        <h5 className="card-text">{text}</h5>
      </CardText>
    </ButtonCardInner>


  </ButtonCardOuter>
}

const CardText = styled.div`
  flex: 1;
  padding: 10px;
  `


const ButtonCardOuter = styled.div`
  max-width: 500px;
  text-align:left;
  width: 100%;
  `;

const ButtonCardInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;


const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width:100%;
`;

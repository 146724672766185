import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
// import { TeachingSplashRightCol } from './TeachingHome';
// import { ModuleTodosSection } from './components/ModuleTodosSection';
import { CurrentTeachingModule } from './components/CurrentTeachingModule';
import { NextSectionWidget, NextTrainingWidget, NextOrientationWidget } from 'course/section/NextSectionWidget';
import { SectionOverview } from 'course/section/SectionOverview';
import { Calendar } from "./calendar/Calendar";
import { useContext, useEffect, useState } from "react";
import { isMentor, isMinimumRole, isSectionLeader, isStaffRole } from "contexts/profile_util";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { Role } from "types/role";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useUserId } from "hooks/user/useUserId";
import { HeadTAOHButton } from "./HeadTACalendar";
import { calcCurrWeek, CourseContext } from "contexts/CourseContext";
import { TimeContext } from "contexts/TimeContext";
import { TeachNowButtonWrapper } from "course/carehours/CareHoursPage";
import { Link } from "react-router-dom";

export const NewTeachingHome = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<TeachingSplashMain />}
      rightColumn={<TeachingAnnouncements />} // TODO: add right column for CIP -- for foothill deleted it because no JITH / head TA OH
      singleColumn={<TeachingSplashMain />}
    />
  );
}

export const TeachNowImpactButtonLink = () => {
  const courseId = useCourseId()
  const { userData } = useContext(ProfileContext);

  const role = userData?.courseRole
  const isStaff = isStaffRole(role)

  const isMentorRole = isMentor(role)

  const isSLRole = isSectionLeader(role)

  let isMinor = true
  if (userData && ('isMinor' in userData)) {
    isMinor = userData.isMinor
  }
  if (isMinor) {
    return null
  }

  return (
    <div className="mt-4">
    <div className="splashCardRight">
      <div className="splashCardRightTitle">
        Teach Now History
      </div>
      <div className="d-flex flex-column">
        <Link to={`/${courseId}/teachnowstatistics`}>
          <button className="btn" style={{backgroundColor: "violet", color: "white", width: '100%'}}>View Session History</button>
        </Link>
      </div>
    </div>
  </div>
  )
}


const TeachingAnnouncements = () => {
const { usesTeachNow } = useContext(CourseContext)

  return <div>
    <NextOrientationWidget />
    <NextTrainingWidget />
    <NextSectionWidget />

    <div className="mt-4">
      <div className="splashCardRight">
        <div className="splashCardRightTitle">
          Head TA Office HRS
        </div>
        <div className="d-flex flex-column">
          <HeadTAOHButton />
        </div>
      </div>
    </div>
    {usesTeachNow && 
    <>
      <TeachNowButtonWrapper/>
      <TeachNowImpactButtonLink/>
    </>
    }
  </div>
}

const TeachingSplashMain = () => {
  const userId = useUserId()
  const courseId = useCourseId()
  const onboardingRef = doc(getFirestore(), `users/${userId}/${courseId}/slonboarding`)
  const [onboardingData, onboardingDataLoading, onboardingDataError] = useDocumentDataOnce(onboardingRef)


  const smallGroupTime = onboardingData?.trainingTime
  return <div className="mt-3">
    {/* <ModuleTodosSection isStudent={false}/> */}
    <CurrentTeachingModule />
    <TeacherCalendar smallGroupTime={smallGroupTime} />
    <hr />
    <SectionOverview />
  </div>
}

const TeacherCalendar = ({ smallGroupTime }) => {
  const courseId = useCourseId()
  const {getServerTimeMs} = useContext(TimeContext)
  const { userData } = useContext(ProfileContext)
  const initEvents = []
  const [events, setEvents] = useState(initEvents)
  let sectionStartWeek = 0
  if (isMinimumRole(userData.courseRole, Role.SECTION_LEADER)) {
    sectionStartWeek = 1
  }

  const currWeek = calcCurrWeek("2024-04-21T00:00", getServerTimeMs())

  useEffect(() => {
    const newEvents = [...events]
    if (smallGroupTime) {
      newEvents.push({
        titleShort: "Live Practice",
        titleMobile: "Practice",
        title: "Your Live Section Practice",
        start: smallGroupTime,
        tooltip: "Live practice",
        key: 'live-practice',
        to: ''
        //   to: `/${courseId}/events`
      })

      const oneWeekLater = new Date(smallGroupTime)
      oneWeekLater.setDate(oneWeekLater.getDate() + 7)

      newEvents.push({
        titleShort: "Live Practice",
        titleMobile: "Practice",
        title: "Your Live Section Practice",
        start: oneWeekLater.toString(),
        tooltip: "Live practice",
        key: 'live-practice',
        to: ''
        //   to: `/${courseId}/events`
      })

      setEvents(newEvents)

    }


  }, [smallGroupTime])

  return <Calendar
    firstCalendarDay={14}
    nWeeks={8}
    events={events}
    startWeek={currWeek}
    isStudentCal={false}
  />

}
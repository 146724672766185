import React, { useState, useEffect, useContext } from 'react';
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import { ProfileContext } from "contexts/ProfileContext";
import { useUploadedVideo, claimReview, submitStudentEvalTN, updateDecisionStatus, getMostRecentVideoUrl, getMostRecentSubmissionDocId, useStudentApplicationStatus, getTNTStudentDisplayName, getMostRecentDocumentsByPuzzleIndex, getPuzzleCodes, submitInitialReaction } from "../teacherPuzzles/TNFirebase";
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { Link, useParams } from "react-router-dom";
import SplitPane from "react-split-pane";
import { Breadcrumb, Button } from "react-bootstrap";
import { Loading } from 'components/loading/Loading';
import styled from 'styled-components';

export const ReviewStudent = ({students}) => {
  const userId = useParams().userId;
  const studentIds = students.map(student => student.userId);
  return (
    <div style={{ height: '100vh' }}>
      <SplitPane split="horizontal" allowResize={false} primary="second" overflow="auto" defaultSize={54}>
        <ReviewStudentSafe key={userId} />
        <BottomNavBar 
          studentIds={studentIds}
        />
      </SplitPane>
    </div>
  );
};

const ReviewStudentSafe = () => {
  const userId = useParams().userId;
  const courseId = useCourseId();
  const reviewerId = useUserId();
  const [studentDisplayName, setStudentDisplayName] = useState(null);
  const [applicationStatus, studentLoading, studentError] = useStudentApplicationStatus(courseId, userId);
  const { userData } = useContext(ProfileContext);
  const reviewerDisplayName = userData?.displayName;
  const [path, setPath] = useState(null);
  const [submissionDocId, setSubmissionDocId] = useState(null);
  const [puzzleList, setPuzzleList] = useState(null);
  const [puzzleCodes, setPuzzleCodes] = useState(null);
  const [viewMode, setViewMode] = useState('video');

  async function handleInitialReaction(e, reactionData) {
    // e.preventDefault();
    await submitInitialReaction(courseId, userId, reviewerId, reviewerDisplayName, reactionData);
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMostRecentDocumentsByPuzzleIndex(courseId, userId);
      const puzzleCodes = await getPuzzleCodes(courseId);
      setPuzzleList(data);
      setPuzzleCodes(puzzleCodes);
    };

    fetchData();
  }, [courseId, userId]);

  useEffect(() => {
    if (studentLoading) return;
    // if (applicationStatus === 'accepted' || applicationStatus === 'rejected') return;
    async function fetchStudentDisplayName() {
      const studentName = await getTNTStudentDisplayName(courseId, userId);
      setStudentDisplayName(studentName);
    }
    fetchStudentDisplayName();
  }, [userId, courseId, studentLoading, applicationStatus]);

  useEffect(() => {
    if (studentLoading) return;
    // if (applicationStatus === 'accepted' || applicationStatus === 'rejected') return;
    async function fetchVideoUrl() {
      const videoPath = await getMostRecentVideoUrl(userId);
      const submissionDocId = await getMostRecentSubmissionDocId(courseId, userId);
      setPath(videoPath);
      setSubmissionDocId(submissionDocId);
      claimReview(courseId, userId, reviewerId, reviewerDisplayName, 'pending');
    }
    fetchVideoUrl();
  }, [userId, courseId, reviewerId, reviewerDisplayName, studentLoading, applicationStatus]);

  const [videoUrl, videoLoading, videoError] = useUploadedVideo(userId, path);

  const handleSubmit = async (e, evalData) => {
    e.preventDefault();
    await submitStudentEvalTN(courseId, userId, reviewerId, reviewerDisplayName, evalData, evalData.overallReadiness, submissionDocId);
    const updatedAppStatus = evalData.overallReadiness === 'ready' ? 'accepted' : 'rejected';
    await updateDecisionStatus(courseId, userId, evalData.overallReadiness, updatedAppStatus);
  };

  if (videoLoading || studentLoading || !studentDisplayName) {
    return <Loading />;
  }

  if (puzzleList === null || puzzleCodes === null) {
    return <Loading />;
  }

  const isAlreadyReviewed = applicationStatus === 'accepted' || applicationStatus === 'rejected'
  if (applicationStatus !== 'submitted' && applicationStatus !== 'in_review' && !isAlreadyReviewed) {
    return (
      <div className="container mt-4">
        <h3>Review Student: {studentDisplayName}</h3>
        <div>This student has not submitted an application for review.</div>
      </div>
    );
  }

  return (
    <SplitPane size={300} minSize={300} primary="second">
      <LeftSide 
        name={studentDisplayName}
        videoUrl={videoUrl}
        puzzleList={puzzleList}
        puzzleCodes={puzzleCodes}
        viewMode={viewMode}
        isAlreadyReviewed={isAlreadyReviewed}
      />
      <RightSide 
        submissionDocId={submissionDocId} 
        handleSubmit={handleSubmit} 
        puzzleList={puzzleList} 
        viewMode={viewMode}
        setViewMode={setViewMode}
        handleInitialReaction={handleInitialReaction}
        courseId={courseId}
        userId={userId}
        isAlreadyReviewed={isAlreadyReviewed}
      />
    </SplitPane>
  );
};

const BottomNavContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 10;
`;

const BottomNavBar = ({ studentIds }) => {
  const { userId } = useParams();
  const courseId = useCourseId();

  const currentIndex = studentIds.indexOf(userId);
  const prevStudentId = currentIndex > 0 ? studentIds[currentIndex - 1] : null;
  const nextStudentId = currentIndex < studentIds.length - 1 ? studentIds[currentIndex + 1] : null;

  return (
    <BottomNavContainer>
      {prevStudentId && (
        <Button as={Link} to={`/${courseId}/reviewteachnowstudents/${prevStudentId}`} style={{backgroundColor: 'violet', border: 'none'}}>
          Previous
        </Button>
      )}
      <BreadcrumbStyled>
        <Breadcrumb.Item style={{ margin: 0 }}>
          <Link style={{ margin: 0 }} to={`/${courseId}/reviewteachnowstudents`}>All Applications</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ margin: 0 }} active>Applicant: {userId}</Breadcrumb.Item>
      </BreadcrumbStyled>
      {nextStudentId && (
        <Button as={Link} to={`/${courseId}/reviewteachnowstudents/${nextStudentId}`} style={{backgroundColor: 'limegreen', border: 'none'}}>
          Next
        </Button>
      )}
    </BottomNavContainer>
  );
};

const BreadcrumbStyled = styled(Breadcrumb)`
  margin: 0 !important;
  & > ol {
    margin: 0 !important;
  }
`;

import { PilotToggle } from "./PilotToggle";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaPhone, FaVideo } from "react-icons/fa";
import { PeerSessionContext } from "../contexts/PeerSessionContext";
import { useParams } from "react-router";
import "firebase/compat/auth";
import { PilotRequestNotification } from "./Notifications";
import { useRoomMembers } from "../../firebase/realtime/Rooms";
import { isMinimumRole } from "contexts/profile_util";
import { Role } from "types/role";
import { PeopleInRoom } from "./PeopleInRoom";
import { useUserId } from "hooks/user/useUserId";
import { ProfileContext } from "contexts/ProfileContext";
import { TimeContext } from "contexts/TimeContext";
import Swal from "sweetalert2";
import { addMessage } from "../../firebase/realtime/Chat";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebaseApp";



export const MeetingButtons = () => {
  const { endSession, roomInfo, meetingRole } = useContext(PeerSessionContext);
  // @ts-expect-error
  const [user] = useAuthState(auth());
  const { roomId } = useParams()
  const { courseId } = useParams()
  const memberInfo = useRoomMembers(courseId, roomId)

  if (memberInfo === undefined) return <>Loading...</>


  return (
    <>
      <ZoomCountdown roomInfo={roomInfo} />
      <PeopleInRoom />
      <AudioVideoIssueButton meetingRole={meetingRole} user={user} zoomInfo={roomInfo.zoomInfo} roomId={roomId} courseId={courseId} />
      <Button
        className="btn btn-danger btn-sm mr-2"
        onClick={() =>{
          Swal.fire({
            title: 'End Session',
            icon: "warning",
            text: 'Are you sure? This will end the session and Zoom call for all participants.',
            showCancelButton: true,
            confirmButtonText: 'End Session',
            cancelButtonText: 'Cancel',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await endSession()
            }
          })
        }}
      >
        <FaPhone style={{ transform: "rotate(225deg)" }} /> &nbsp;
        End Session
      </Button>
    </>
  )
}


export const AudioVideoIssueButton = ({ meetingRole, zoomInfo, roomId, courseId, user }) => {
    // display this button only if meetingRole is "sl". It
    // should initially show "Having audio/video issues?" and when clicked
    // should show the zoomUrl in zoomInfo and change the button text to "Join Zoom Meeting"


    if(!zoomInfo || !zoomInfo.joinUrl) {
      return <></>
    }
    const isSl = meetingRole === "sl";

    // if(!isSl) {
    //   return <></>
    // }

    return (
      <Button
        className="btn btn-primary btn-sm mr-2"
        onClick={() =>
          Swal.fire({
            title: 'Launch Zoom Meeting',
            html: `
            <p style="text-align: left">
              You are about to join a Zoom meeting. The meeting will open in a new tab and launch your Zoom app. Feel free to use
              the Zoom to communicate with each other, but make sure to work on your code in this IDE window.
            </p>
            <p style="text-align: left">
              <strong>Important</strong>: Please continue to use the IDE for coding and do not hit the red End Session button until you are done with the meeting.
              If you hit End Session, you will not be able to rejoin the meeting and it will end the Zoom meeting for all participants.
            </p>
            `,
            showCancelButton: true,
            confirmButtonText: 'Launch Zoom',
            cancelButtonText: 'Cancel',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              // if(isSl) {
              //   await addMessage(
              //     {
              //       text: `You can directly join the Zoom meeting using the following link:\n\n[${zoomInfo.joinUrl}](${zoomInfo.joinUrl})`,
              //       senderId: user.uid,
              //       senderName: user?.displayName,
              //       timestamp: null, // gets replaced by firebase
              //     },
              //     roomId,
              //     courseId
              //   );
              // }
              window.open(zoomInfo.joinUrl, '_blank')

            }
          })
        }
      >
        <FaVideo style={{marginRight: "3px", marginBottom: "2px"}}/> Launch Zoom
      </Button>
    )

}


const ZoomCountdown = ({ roomInfo }) => {
    const { getServerTimeMs, serverTimeDelta } = useContext(TimeContext)
    const [timeLeft, setTimeLeft] = useState(undefined)

    useEffect(() => {
      if(serverTimeDelta === undefined) {
        return
      }

      const interfulToUn = setInterval(() => {
        if(!roomInfo || !roomInfo.zoomInfo || !roomInfo.zoomInfo.expiryTimeISO) {
          return
        }
        const expiryTime = new Date(roomInfo.zoomInfo.expiryTimeISO).getTime()
        const currTimeLeft = expiryTime - getServerTimeMs()
        const deltaMinsSecsString = new Date(currTimeLeft).toISOString().substr(14, 5)
        setTimeLeft(deltaMinsSecsString)
        if(currTimeLeft < 0) {
          clearInterval(interfulToUn)
        }
      }, 1000)

      return () => {
        clearInterval(interfulToUn)
      }


    }, [serverTimeDelta, roomInfo])

    if(serverTimeDelta === undefined || !roomInfo || !roomInfo.zoomInfo || !roomInfo.zoomInfo.expiryTimeISO || timeLeft === undefined) {
      return <></>
    }

    // if time is not less than 5 mins
    if(new Date(roomInfo.zoomInfo.expiryTimeISO).getTime() - getServerTimeMs() > 5 * 60 * 1000) {
      return <></>
    }

    return (
      <div className="d-flex justify-content-center align-items-center mr-3">
        <span>Time Left: {timeLeft}</span>
      </div>
    )


}
import React from "react";
import { useEffect, useRef, useState } from "react";
import { FaPaperPlane, FaUndo } from "react-icons/fa";

export const InputField = ({ disabled, onSend, undoMessage }) => {
  const [myMsg, setMyMsg] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("auto");

  const inputRef = useRef(null);

  // submit TA message
  const handleSubmit = (event) => {
    event.preventDefault();
    onSend(myMsg);
    setMyMsg("");
    inputRef.current.focus();
  };

  useEffect(() => {
    if (!disabled) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const handleKeypress = (event) => {
    setMyMsg(event.target.value);

    // Enter submits the form
    if (event.nativeEvent.inputType === "insertLineBreak") {
      setTextareaHeight("auto");
      handleSubmit(event);
    } else {
      // Resize the textarea based on what the user has typed
      setTextareaHeight(`${inputRef.current.scrollHeight}px`);

      // If the user deletes all the text, reset the textarea height
      if (event.target.value === "") {
        setTextareaHeight("auto");
      }
    }
  };

  return (
    <>
      <div className="d-flex">
        <form
          id="msg-form"
          style={{ display: "flex", width: "100%" }}
          onSubmit={handleSubmit}
        >
          <div
            className="d-flex flex-column"
            style={{
              border: "1px",
              borderColor: "#ccc",
              borderStyle: "solid",
              borderRadius: "10px",
              padding: "5px",
              width: "100%",
            }}
          >
            <textarea
              disabled={disabled}
              value={myMsg}
              style={{
                flexGrow: "2",
                resize: "none",
                border: "none",
                outline: "none",
                height: textareaHeight,
                marginBottom: "2px",
              }}
              onChange={handleKeypress}
              autoFocus={true}
              ref={inputRef}
            />

            <div className="d-flex flex-row-reverse justify-content-between align-items-flex-end">
              {/* <button
                title="Undo"
                className="btn btn-outline-secondary"
                disabled={disabled}
                onClick={undoMessage}
              >
                <FaUndo />
              </button> */}

              <button
                title="Send"
                className="btn btn-primary"
                disabled={disabled}
                onClick={undoMessage}
              >
                <FaPaperPlane />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

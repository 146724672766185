import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { EVENT_TYPES, useEvents, EventsByType } from "../../firebase/realtime/Events";
import { Event } from "../../firebase/realtime/types";

const defaultData: [EventsByType, (event: Event) => void] = [
  Object.fromEntries(Object.values(EVENT_TYPES).map((eventType) => [eventType, []])),
  (e: Event) => {}
];

export const EventsContext = React.createContext(defaultData);

export const EventsProvider = ({courseId, children}) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const [eventsByType, removeEvent] = useEvents(user.uid, courseId)

  return (
    <EventsContext.Provider
      value={[eventsByType, removeEvent]}
    >
      {children}
    </EventsContext.Provider>
  );
};

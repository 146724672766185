import { FormixFormWithData } from "components/Forms/FormixFormWithData"
import { CountryInput } from "components/Forms/FormixInputs"
import { ProfileContext } from "contexts/ProfileContext"
import { AboutMeInput, ConnectionsConsentCheckbox, NameInput } from "course/profile/ProfileInputs"
import { ProfilePicEditor } from "course/profile/editors/ProfilePicEditor"
import { useCourseId } from "hooks/router/useUrlParams"
import { ResponsiveCard, SetupOuter } from "onboarding/components/SetupOuter"
import { useContext } from "react"
import { Card } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { generateUsernames } from "utils/generateUsernames"
import * as Yup from "yup";

export const SetupProfile = () => {
  const { userData, setUserData } = useContext(ProfileContext);

  const location = useLocation().pathname
  
  // remove the final path, after the final /
  const homeLocation = location.substring(0, location.lastIndexOf("/"))

  const courseId = useCourseId()
  if(userData.displayName === "Anon") {
    userData.displayName = generateUsernames()
  }

  async function handleSubmit(values) {
    console.log('values', values)
    await setUserData(values)
  }

  return <SetupOuter>
    <ResponsiveCard style={{ margin: "auto", padding: "20px" }}>
      <div><h1>Setup Your Public Profile</h1>
      <label className="form-label">Profile Picture</label>
      <ProfilePicEditor />
      <FormixFormWithData
        serverData={userData}
        backUrl="/"
        formFormat={profileForm}
        onSubmit={(e, cb) => handleSubmit(e)}
        autoSave={true}
      />
      </div>
      <Link className="btn btn-secondary" to={homeLocation}>
        Back
      </Link>
    </ResponsiveCard>
  </SetupOuter>
}

const profileForm = [{
  name: "displayName",
  input: NameInput,
  default: '',
  validation: Yup.string()
    .min(3, "Display Name must be at least 3 characters long")
    .required("Display Name is required"),
},
{
  name: "wantToConnect",
  default: true,
  input: ConnectionsConsentCheckbox,
  validation: Yup.boolean()
},
{
  name: "aboutMe",
  default: '',
  input: AboutMeInput,
  validation: Yup.string()
}]




  
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { doc, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { UserRow } from "./UserTableEntry"
import { useState } from "react"

export const ForumStats = () => {
  return <CoursePageSingleCol
    column={<ForumStatsContent />}
  />
}

const MAX_DISPLAY = 300

const ForumStatsContent = () => {
  console.log("Forum stats content")
  const defaultFilters = {
    "admin": false,
    "sl": true,
    "student": true
  }

  const courseId = useCourseId()
  const userId = useUserId()

  const [shownK, setShownK] = useState(100)
  const [filters, setFilters] = useState(defaultFilters)

  const [overall, loadingOverall, errorOverall] = useDocumentDataOnce(doc(getFirestore(), `course/${courseId}/usageStats/forum`))
  const [usage, loadingUsage, errorUsage] = useDocumentDataOnce(doc(getFirestore(), `course/${courseId}/usageStats/forum/shards/1`))

  console.log(loadingOverall, loadingUsage)
  if (loadingOverall || loadingUsage) return <></>

  console.log(overall, usage)

  const timeStampSeconds = overall.timestamp.seconds
  const total = overall.total

  const yourStats = usage[userId] || { posts: 0, replies: 0 }

  const filtered = filter(usage, filters)

  const top100 = getTopK(filtered, shownK)


  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    day: 'numeric',
    month: 'short',
    weekday: 'short'
  };

  const lastUpdateDateTime = new Date(timeStampSeconds * 1000)
  const lastUpdateLocalTimeStr = lastUpdateDateTime.toLocaleString(navigator.language, options);

  return <div className="mt-4">


    <h3>Class Total</h3>
    <p>Total posts: {total}</p>

    <h3>Your Stats </h3>
    <p> Your posts: {yourStats.posts}<br />
      Your replies: {yourStats.replies}</p>

    <h3 className="mt-4">Leader Board</h3>

    <Filters filters={filters} setFilters={setFilters} />
    <table className="table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Posts</th>
          <th>Replies</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {top100.map(([userId, stats]) => {
          return <UserRow
            key={userId}
            userId={userId}
            stats={stats}
            filters={filters}
          />
        })}
      </tbody>
    </table>
    <button disabled={MAX_DISPLAY == shownK} className="btn btn-primary" onClick={() => setShownK(shownK + 100)}>Load more</button>
    <hr />
    <p>Showing only the top {shownK}. Last updated {lastUpdateLocalTimeStr}</p>
  </div>
}

const Filters = ({ filters, setFilters }) => {
  const toggleFilter = (filter) => {
    setFilters({ ...filters, [filter]: !filters[filter] })
  }

  // bootstrap check boxes
  return <div className="mt-3 d-flex ">
    
    <div className="form-check form-switch mr-4">
      <input className="form-check-input" type="checkbox" id="sl" checked={filters.sl} onChange={() => toggleFilter("sl")} />
      <label className="form-check-label" htmlFor="sl">Section Leaders</label>
    </div>
    <div className="form-check form-switch mr-4">
      <input className="form-check-input" type="checkbox" id="student" checked={filters.student} onChange={() => toggleFilter("student")} />
      <label className="form-check-label" htmlFor="student">Student</label>
    </div>
    <div className="form-check form-switch mr-4">
      <input className="form-check-input" type="checkbox" id="admin" checked={filters.admin} onChange={() => toggleFilter("admin")} />
      <label className="form-check-label" htmlFor="admin">Admins</label>
    </div>
  </div>
}

export function getTotal(stats) {
  const nPosts = stats.posts || 0
  const nReplies = stats.replies || 0
  return nPosts + nReplies
}

function filter(usage, filters) {
  const filterOut = ["QPhosvxOc5WNRWMd7EMYezLjOoi2"]
  return Object.fromEntries(Object.entries(usage).filter(([key, value]) => !filterOut.includes(key)))
}

function getTopK(people, k) {
  function comparisonFunction(a, b) {
    const totalA = getTotal(a)
    const totalB = getTotal(b)
    return totalB - totalA
  }

  // usage is a dictionary with key -> {posts, replies}
  // we want to sort by the sum of posts and replies
  // and return the top k

  const sorted = Object.entries(people).sort((a, b) => comparisonFunction(a[1], b[1]))
  const topK = sorted.slice(0, k)

  return topK
}
import styled from "styled-components";

export const ViewOnlyProfilePicture = ({ userData }) => {

  if(!userData) {
    return <DefaultProfilePicture/>
  }
  const photoURL = userData?.photoURL
  const displayName = userData?.displayName

  const firstInitial = displayName ? displayName[0] : ""

  return (
    <div className="d-flex flex-column">
      {photoURL ? 
        <ViewProfileImg src={photoURL} /> : 
        <DefaultProfilePicture aria-label="Profile picture" className="fullSizeProfile">{firstInitial}</DefaultProfilePicture>
    }
    </div>
  );
}

const DefaultProfilePicture = styled.div`
  width: 150px;
  height: 150px;
  background-color: salmon;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

`;

const ViewProfileImg = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
`;

import { useContext, useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { RoadmapContext } from "course/contexts/RoadmapContext";
import { SectionMember } from "./SectionMember";
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from "react-router-dom";
import { useCourseId } from "hooks/router/useUrlParams";
import { DetailsPopoverTable } from "./DetailsPopoverTable";
import { FaCheck, FaLink, FaWindowClose} from 'react-icons/fa';
import { GradeDetailsPopover } from "./GradeDetailsPopover";

export const OverviewTable = ({membersList, numSections, courseHasGrades=false, membersGrades={}, assnInfo={}, assnSubmissions={}}) => {
    console.log("assnInfo", assnInfo)
    const db = getFirestore()
    const courseId = useCourseId()
    const { getAssignmentsInRoadmap, getLessonsInRoadmap } = useContext(RoadmapContext)
    const [membersProgress, setMembersProgress] = useState([]);

    const releasedAssns = getAssignmentsInRoadmap(true);
    const releasedLessons = getLessonsInRoadmap(true);

    useEffect(() => {
        if (membersList.length === 0 || numSections === -1) return;

        const fetchMembersProgress = async () => {
            const fetchPromises = membersList.map(userId => {
                const trackablesPath = `/users/${userId}/${courseId}`;

                const lessonsProgressDocRef = doc(db, `${trackablesPath}/lessonsProgress`);
                const assnProgressDocRef = doc(db, `${trackablesPath}/assnProgress`);
                const assnMapRef = doc(db, `${trackablesPath}/assnMap`);
                const sectionProgressDocRef = doc(db, `${trackablesPath}/sectionAttendance`);

                return Promise.all([
                    getDoc(lessonsProgressDocRef),
                    getDoc(assnProgressDocRef),
                    getDoc(assnMapRef),
                    getDoc(sectionProgressDocRef)
                ]).then(([lessonDoc, assnDoc, assnMap, sectionDoc]) => ({
                // ]).then(([assnDoc, sectionDoc]) => ({
                    userId,
                    lessonProgress: lessonDoc.exists() ? lessonDoc.data() : {},
                    assnProgress: assnDoc.exists() ? assnDoc.data() : {},
                    assnMap: assnMap.exists() ? assnMap.data() : {},
                    sectionProgress: sectionDoc.exists() ? sectionDoc.data() : {},
                })).catch((error) => console.log(`Error fetching data for user ${userId}: `, error));
            });
            try {
                const results = await Promise.all(fetchPromises);
                setMembersProgress(prev => results.filter(result => result !== null));
            } catch(error) {
                console.error('Error fetching member progress: ', error);
            }
        };

        fetchMembersProgress();

    }, [membersList, numSections]);

    if (membersProgress.length == 0) {
        return <></>;
    }

    const memberData = makeMemberData(membersProgress, membersGrades);
    
    let columns = [
        {
            dataField: 'memberId', 
            text: 'Member ID', 
            sort:false, 
            hidden:true
        },{
            dataField: 'member', 
            text: 'Student', 
            sort:false
        },{
            dataField: 'assnMap', 
            text: 'assnMap', 
            sort:false,
            hidden: true
        },{
            dataField: 'assnProgress', 
            text: 'Assns Done', 
            formatter: (cell, row) => {
                const assnsDone = getNumAssnsDone(cell, releasedAssns);

                const getSubmissionInfo = (assnId) => {
                    const userId = row.memberId;
                    if (assnSubmissions[assnId] && userId in assnSubmissions[assnId]) {
                        return assnSubmissions[assnId][userId];
                    }
                    return {};
                }
                const getPointsPossible = (assnId) => {
                    console.log("points possible")
                    console.log(assnId)
                    if (assnId in assnInfo) {
                        console.log("assnInfo[assnId].points_possible", assnInfo[assnId].points_possible)
                        return assnInfo[assnId].points_possible;
                    }
                    return '-';
                }

                var assnCols = [
                    { dataField: 'id', text: 'key', hidden: true },
                    { dataField: 'title', text: 'Assn' },
                    { dataField: 'done', text: 'Done', formatter: (cell, row) => cell ? <FaCheck style={{color:'green'}}/> : <FaWindowClose/> },
                    { dataField: 'lastSubmissionRef', text: 'Link', formatter: (cell, row) => cell ? <Link to={`/${courseId}/ide/p/${cell}`}><FaLink /></Link> : <></>}
                ]
                if (courseHasGrades){
                    assnCols.push({ dataField: 'status', text: 'Status' })
                    
                    assnCols.push({ 
                        dataField: 'score', 
                        text: 'Score',
                        formatter: (cell, row) =>  cell ? <>{cell} / {getPointsPossible(row.id)}</> : <>-</>
                    })
                }

                const assnData = releasedAssns.map(assn => {
                    const assnId = assn.assnId
                    return {
                        id: assnId,
                        title: assn.title,
                        done: cell[assnId] ?? false,
                        // link: <Link to={row.assnMap.assnId ? `/${courseId}/ide/p/${row.assnMap.assnId}` : ''}><FaLink /></Link>
                        ...getSubmissionInfo(assnId),
                    }
                })
                return <DetailsPopoverTable 
                    buttonText={`${assnsDone}/${releasedAssns.length}`} 
                    columns={assnCols} 
                    detailsData={assnData} />
            },
            sort: true,
            // Perform a reverse sorting here
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const numAssnsDoneA = getNumAssnsDone(a, releasedAssns);
                const numAssnsDoneB = getNumAssnsDone(b, releasedAssns);
                if (order === 'asc') {
                    return numAssnsDoneA - numAssnsDoneB; // asc
                }
                return numAssnsDoneB - numAssnsDoneA; // desc
            }
        },{
            dataField: 'lessonProgress', 
            text: 'Lessons Done', 
            formatter: (cell, row) => {
                const lessonsDone = getNumLessonsDone(cell, releasedLessons)
                const lessonCols = [
                    { dataField: 'id', text: 'key', hidden: true },
                    { dataField: 'title', text: 'Lesson' },
                    { dataField: 'done', text: 'Done', formatter: (cell, row) => cell ? <FaCheck style={{color:'green'}}/> : <FaWindowClose/> },
                ]
                const lessonData = releasedLessons.map(lesson => {
                    const lessonId= lesson.lessonId
                    return {
                        id: lessonId,
                        title: lesson.title,
                        done: cell[lessonId] ?? false,
                    }
                })
                return <DetailsPopoverTable 
                    buttonText={`${lessonsDone}/${releasedLessons.length}`} 
                    columns={lessonCols} 
                    detailsData={lessonData} />
            },
            sort: true,
            // Perform a reverse sorting here
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const numLessonsDoneA = getNumLessonsDone(a, releasedLessons);
                const numLessonsDoneB = getNumLessonsDone(b, releasedLessons);
                if (order === 'asc') {
                    return numLessonsDoneA - numLessonsDoneB; // asc
                }
                return numLessonsDoneB - numLessonsDoneA; // desc
            }
        },{
            dataField: 'sectionProgress', 
            text: 'Sections Attended', 
            formatter: (cell, row) => {
                const sectionsAttended = Object.keys(cell).length
                const sectionCols = [
                    { dataField: 'id', text: 'key', hidden: true },
                    { dataField: 'week', text: 'Week' },
                    { dataField: 'done', text: 'Attended', formatter: (cell, row) => cell ? <FaCheck style={{color:'green'}}/> : <FaWindowClose/> },
                ]
                const sectionData = Array.from({ length: numSections }, (_, sectionIdx) => {
                    return {
                        id: sectionIdx,
                        week: sectionIdx + 1,
                        done: cell[sectionIdx] ?? false,
                    };
                });
                
                return <DetailsPopoverTable 
                    buttonText={`${sectionsAttended}/${numSections}`} 
                    columns={sectionCols} 
                    detailsData={sectionData} />
            },
            sort: true,
            // Perform a reverse sorting here
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const numSectionsAttendedA = Object.keys(a).length;
                const numSectionsAttendedB = Object.keys(b).length;
                if (order === 'asc') {
                    return numSectionsAttendedA - numSectionsAttendedB; // asc
                }
                return numSectionsAttendedB - numSectionsAttendedA; // desc
            }
        }, 
    ]

    if (courseHasGrades && Object.keys(membersGrades).length > 0) {
        columns.push({
            dataField: 'memberGrades',
            text: 'Grades',
            formatter: (cell, row) => {
                return <GradeDetailsPopover gradeObject={cell} />
            },
            sort: false,
            sortFunc: undefined // TODO @miranda add grade sorting
        });
    }

    return (
        <>
        <BootstrapTable 
            bordered={false} 
            striped={true} 
            hover={true} 
            bootstrap4={true} 
            keyField='memberId' 
            data={memberData} 
            columns={columns} 
        />
        </>
    );
}

const makeMemberData = (membersProgress, membersGrades) => {
    return membersProgress.map((memberProgress) => {
        const { userId, assnProgress, assnMap, lessonProgress, sectionProgress } = memberProgress;
        return {
            memberId: userId,
            member: <SectionMember memberId={userId} />,
            assnProgress,
            assnMap,
            lessonProgress,
            sectionProgress,
            memberGrades: membersGrades[userId] ? membersGrades[userId] : {}
        };
    });
}

const getNumAssnsDone = (assnProgress, releasedAssns) => {
    let numAssnsDone = 0;
    releasedAssns.forEach(assn => {
        const assnId = assn.assnId;
        if (assnProgress[assnId]) {
            numAssnsDone++;
        }
    });
    return numAssnsDone;
}

const getNumLessonsDone = (lessonProgress, releasedLessons) => {
    let numLessonsDone = 0;
    releasedLessons.forEach(lesson => {
        const lessonId = lesson.lessonId;
        if (lessonProgress[lessonId]) {
            numLessonsDone++;
        }
    });
    return numLessonsDone
}
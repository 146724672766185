import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useState } from "react"

export const DetailsPopoverTable = ({ buttonText, columns, detailsData }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  

  return (
    <>
      <Button onClick={handleClick} variant="light">{buttonText}</Button>
      <Overlay
        show={show}
        target={target}
        placement="right"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShow(false)}
      >
        <Popover 
          id="popover-trigger-click-root-close"
          style={{ maxWidth: 'unset' }}
        >
          <Popover.Body>
            <BootstrapTable
              bordered={false}
              striped={true}
              hover={true}
              bootstrap4={true}
              keyField='id'
              data={detailsData}
              columns={columns} />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}
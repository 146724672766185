import { useContext } from "react";
import { KarelWorld } from "../../components/pyodide/KarelLib/KarelWorld";
import { IDEContext } from "../contexts/IDEContext";
import { checkIsProjectConsole, checkIsProjectKarel } from "../utils/general";
import { GraphicsCanvas } from "./GraphicsCanvas";
import { TestList } from "ide/RightPane/TestView";
import { WorldEditor } from "components/pyodide/KarelLib/WorldEditor";
import styled from "styled-components";

export const Canvas = (props) => {
  const {
    assnData,
    projectData,
    karelWorldState,
    defaultKarelWorldState,
    setDefaultKarelWorldState,
    setKarelWorldState } = useContext(IDEContext);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  

  const isKarel = checkIsProjectKarel(projectData, assnData);
  if (isKarel) {
    if (props.karelWorldEditMode) {
      return (
        <KarelWorldOuter>
          <WorldEditor
            worldState={defaultKarelWorldState}
            setWorldState={(karelState) => {
              setKarelWorldState(karelState)

              setDefaultKarelWorldState(karelState)
            }}

          />
        </KarelWorldOuter>
      )
    }
    return (
      <KarelWorldOuter>
        <KarelWorld worldState={karelWorldState} />
      </KarelWorldOuter>
    );
  }


  if (isConsole) {
    return (
      <div>
        <TestList />
      </div>
    );
  }
  return <GraphicsCanvas {...props} />;
};

const KarelWorldOuter = styled.div`
  width: 100%;
  display: flex;
`;

import React, { useState, useEffect, useContext } from "react";
import { useComponentSize, useWindowSize } from "react-use-size";
// WARNING: this was breaking styles all over the website (especially redefining btn-info)
// import "../style/ZoomMeeting.css";
import { Messages } from "../components/Messages";
import {
  saveMessageToFirebase,
  setUpNewParticipant,
} from "gpteach/utils/database_writing";
import { getStaticData } from "../utils/getStaticData";
import { callGPT3 } from "../utils/gpt3.js";
import { ProfileContext } from "contexts/ProfileContext";
import { FaInfoCircle, FaRedoAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const ASPECT_RATIO = 9 / 16;
const DEFAULT_HISTORY = [];

export const ZoomMeeting = () => {
  const { userData } = useContext(ProfileContext);

  const [staticData, setStaticData] = useState(null);
  const [history, setHistory] = useState(DEFAULT_HISTORY);
  const [isQuerying, setIsQuerying] = useState(false);
  const [cipUser, setCipUser] = useState(null);
  const [participantWidth, setParticipantWidth] = useState(0);

  const innerSize = useComponentSize();
  const windowWidth = useWindowSize().width;

  function constructor() {
    // Get the data from Firebase
    const getAllTheData = async () => {
      let u = await setUpNewParticipant(userData);
      setStaticData(getStaticData(u));
      setCipUser(u);
    };
    getAllTheData();

    // Set session history to be blank
    setHistory(DEFAULT_HISTORY);
    setIsQuerying(false);
  }
  const showSwal = () => {
    Swal.fire({
      title: 'Welcome to Section Practice!',
      html: '<div style="text-align: left;"> <p>This is a simulated section environment where you can get some practice section leading. \
      You are in a practice zoom section, with 3 simulated students who have some questions. </p>  \
      <p> It is<b> entirely optional</b>, experimental, and intended to just be fun and helpful, so use it as you wish! \
      You will in no way be evaluated on your responses, though we do ask that you continue to respect CIP community guidelines \
      even as you use this tool. </p> </div>'
    });
  }

  useEffect(showSwal,[]);

  // Set up the participant, and don't render anything until we have it
  useEffect(constructor, []);

  // Resize the video boxes when things move around
  useEffect(() => {
    staticData &&
      setParticipantWidth(
        getParticipantWidth(
          innerSize.width,
          staticData.studentNames.length + 1
        ) - 11
      );
  }, [staticData, innerSize, windowWidth]);

  // Assemble the prompt that will go to GPT
  const composePrompt = (newHistory) => {
    let myPrompt = staticData.startingPrompt.join("") + "\n\n";

    // Turn messages into script form
    newHistory.forEach(
      (msg) =>
        (myPrompt = myPrompt
          .concat("\n")
          .concat(msg.agent + ": ")
          .concat(msg.text.replace(/[\n]/gm, ""))
          .concat("\n"))
    );

    return myPrompt;
  };

  // add messages / adjust history
  const addWrittenResponse = (msgText) => {
    // deep copy and push
    let newHistory = [...history];

    const newMessage = {
      // TODO: can make this the TA's actual name (may break parsing of GPT - pass to callGPT3?)
      agent: "TA",
      text: msgText,
      timestamp: new Date().valueOf(),
    };

    newHistory.push(newMessage);
    saveMessageToFirebase(cipUser, newMessage);

    // compose prompt, clean up and stuff
    const prompt = composePrompt(newHistory);

    // Call this here so that the messages update while we wait on a response
    setHistory(newHistory);
    setIsQuerying(true);

    callGPT3(prompt, (gptResponse) => {
      // Regex using colons and EOM: https://regex101.com/r/Y9WXQP/1
      const allMessages = Array.from(
        gptResponse.matchAll(/\n([A-Z][^:]*): ([^<]*)/g)
      );

      let completedTimeouts = 0;

      // Parse chat messages
      for (let i = 0; i < allMessages.length; i++) {
        const message = allMessages[i];
        const agent = message[1];
        const content = message[2];

        const newMessage = {
          agent: agent,
          text: content,
          timestamp: new Date().valueOf(),
        };

        // Delay showing the responses to increase realism
        setTimeout(() => {
          newHistory.push(newMessage);
          // Add the student response
          setHistory([...newHistory]);
          saveMessageToFirebase(cipUser, newMessage);

          completedTimeouts++;
          // Check if all timeouts have completed
          if (completedTimeouts === allMessages.length) {
            setIsQuerying(false);
          }
        }, i * 3000 + Math.random() * 3000);
      }
    })
  };
 

  // undo !! pop pop
  const undoMessage = () => {
    let newHistory = [...history]; // deep copy
    newHistory.pop(); // pop student response
    //newHistory.pop(); // pop teacher response
    setHistory(newHistory);
  };

  return (
    <div className="d-flex" style={{ backgroundColor: "darkgrey" }}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center">
          {/* The Zoom part */}
          <div
            ref={innerSize.ref}
            className="d-flex flex-wrap align-items-center justify-content-center"
          >
            {staticData &&
              staticData.studentNames.map((name, i) => {
                return (
                  <div style={{ margin: "5px" }} key={i}>
                    {" "}
                    <Participant
                      width={participantWidth}
                      imgURL={`${process.env.PUBLIC_URL}/GPTeach-characters/${name}.png`}
                      name={name}
                      key={i}
                    />{" "}
                  </div>
                );
              })}
            <div style={{ margin: "5px" }}>
              {" "}
              <Participant
                width={participantWidth}
                name={userData?.firstName}
                imgURL={userData?.photoURL}
                key="TA"
              />
            </div>
          </div>
        </div>
      </div>

      {/* The Zoom Chat */}
      <div className="zoomChat">
        {" "}
        <div className="d-flex flex-column" style={{ height: "100%" }}>
          
          {/* The buttons */}
          <div
            className="controlButtonContainer d-flex justify-content-between align-items-center" 
            style={{ 
              width: "100%", 
              padding: "10px 20px",
              borderBottom: "1px solid #eee"
           }}
          >
            <h2 style={{marginTop:"5px"}}>Section Chat</h2>
            <div className="d-flex" style={{marginTop:"-5px"}} >
              <button
                type="button"
                className="btn-new-sesh"
                style={{marginRight:"5px"}}
                onClick={constructor}
              >
                <FaRedoAlt />
              </button>
              <button onClick={showSwal} type="button" 
              className="btn-info"
              style={{marginLeft:"5px"}}>
                <FaInfoCircle />
              </button>
            </div>
          </div>

          <div style={{ flex: 3 }}></div>{" "}
          {/* empty div to push Messages to bottom */}
          <div>
            <Messages
              history={history}
              isWaitingOnStudent={isQuerying}
              onMessageSend={addWrittenResponse}
              undoMessage={undoMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Participant = ({ width, name, imgURL }) => {
  const height = width * ASPECT_RATIO;

  return (
    <div
      className="participantBox flex-column"
      style={{
        width,
        height,
      }}
    >
      <img
        src={imgURL}
        alt={`photo of ${name}`}
        style={{ height: height * 0.5 }}
      />
      <span style={{ fontSize: 15 + height / 10 }}>{name}</span>
    </div>
  );
};

function getParticipantWidth(outerWidth, nParticipants) {
  const nRows = getNRows(nParticipants);
  let nTopRow = nRows > 1 ? 3 : nParticipants;
  if (nParticipants == 4) {
    nTopRow = 2;
  }
  return outerWidth / nTopRow;
}

function getNRows(nParticipants) {
  return Math.ceil(nParticipants / 3);
}

import { useHttpsCallable } from "react-firebase-hooks/functions";
import { getFunctions, HttpsCallableResult } from "firebase/functions";

/**
 * The result of calling `useCipHttpsCallable`. Matches the signature of
 * `useHttpsCallable` from `react-firebase-hooks`. The first element of the
 * tuple is the callable function, the second element is a boolean indicating if
 * the function is loading, and the third element is an error if one occurred or
 * undefined.
 */
type UseCipHttpsCallableResult<TRequest, TResponse> = readonly [
  (data: TRequest) => Promise<HttpsCallableResult<TResponse>>,
  boolean,
  Error
];

/**
 * A hook to be used in place of `useHttpsCallable` that automatically discovers
 * the functions instance.
 *
 * TODO: #971 - Expand usage of this function.
 * @param name The name of the callable function to call.
 * @returns Returns: {@inheritDoc UseCipHttpsCallableResult}
 */
export function useCipHttpsCallable<TRequest, TResponse>(
  name: string
): UseCipHttpsCallableResult<TRequest, TResponse> {
  const functions = getFunctions();
  return useHttpsCallable<TRequest, TResponse>(functions, name);
}

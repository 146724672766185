import { useContext, useEffect, useState } from 'react';
import { FaCertificate, FaCalendarAlt } from 'react-icons/fa';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { RoadmapContext } from 'course/contexts/RoadmapContext';
import { useCompletionMap } from 'hooks/user/useCompletion';
import { useWindowSize } from "react-use-size";

import "./module-styles.css"
import { useParams } from 'react-router';
import { useCourseId } from 'hooks/router/useUrlParams';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';

export const TreasureMap = ({ certificateUrl, certificateActive, roadmap, currModuleId, goToCard, containerRef }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  const { courseId } = useParams()
  const db = getFirestore();
  const [courseReleaseData, courseReleaseLoading, courseReleaseError] = useDocumentData(doc(db,`course/${courseId}/docs/releases`))
  const [certificateReleased, setCertificateReleased] = useState(false)

  useEffect(() => {
    if(courseReleaseData && courseReleaseData.certificate){
      console.log(courseReleaseData.certificate)
      setCertificateReleased(true)
    }
  }, [courseReleaseData])



  // var nextModuleId = useNextIncompleteModuleId()
  // console.log(nextModuleId)


  return (
    <div className={`${isMobile ? 'treasure-map-mobile' : 'treasure-map-desktop'}`} title="Module overview directory">
      { // TODO: Determine if its just CIP or if its all sync classes
        courseId === "cip3" || courseId === "cip4" ?       <CalendarTab isMobile={isMobile} containerRef={containerRef}/>: null
      }

      {
        roadmap.map((moduleId, index) => {

          return <TreasureMapButton 
            key={moduleId}
            moduleId={moduleId}
            goToCard={goToCard}
            isCurrent={moduleId === currModuleId}
            isMobile={isMobile}
            index={index}
          />
        })
      }

     {certificateReleased ? <CertificateButton certificateUrl={certificateUrl} certificateActive={true} isMobile={isMobile}/> : <></>}
    </div>
  )

}

const TreasureMapButton = ({moduleId, goToCard, isCurrent, isMobile, index}) => {
  const { roadmapItemMap } = useContext(RoadmapContext);
  const { completionMap } = useCompletionMap(roadmapItemMap[moduleId]["items"].filter((item) => !item.isOptional))


  

  const styling = isCurrent ? "border-3 border-info" : ""
  let bg = "bg-grey"
  if(Object.keys(completionMap).every((itemId) => completionMap[itemId])) {
    bg = "bg-greencheck"
  } 


  return (
    <button 
    aria-label={`Scroll to module ${index}`}
    key={moduleId} 
    className={`btn ${bg} p-2 ${ isMobile ? '' : 'mr-2 ml-2'} ${styling} `} 
    style={{ width: "24px", height: "24px", borderRadius:"100%"}}
    onClick={() => goToCard(moduleId)}
    >
    </button>
  )
}

const CertificateButton = ({certificateUrl, certificateActive, isMobile}) => {
    const courseId = useCourseId()

    const disabledTooltip = (<Tooltip id="tooltip">
        You must complete all modules to unlock your certificate!
    </Tooltip>)

    // for now there is no certificate for the public course
    if(courseId === "public"){
      return null
    }

    if (certificateActive){
        // all modules complete, show certificate icon
        return <Button 
        variant="link" 
        href={certificateUrl} 
        style={{ width: "24px", height: "24px", borderWidth: "0px"}}
        className={`${ isMobile ? '' : 'mr-2 ml-2'} p-0`}>
            <FaCertificate className='certificate-icon'/>
        </Button>
    }

    return <OverlayTrigger placement="bottom" overlay={disabledTooltip}>
    <Button 
        variant="link" 
        href=''
        className={`${ isMobile ? '' : 'mr-2 ml-2'} p-0`}
        style={{ width: "24px", height: "24px", borderWidth: "0px"}}
        aria-label="Open Certificate"
        >
            <FaCertificate className='certificate-icon'/>
        </Button>
    </OverlayTrigger>
    
}


const CalendarTab = ({isMobile, containerRef}) => {



  const scrollToCalendar = () => {
    const headerHeight = 110
    const container = containerRef.current
    const calendar = document.getElementById("calendar-home")
    if(!container || !calendar) return
    container.scroll({
      top: calendar.offsetTop - headerHeight,
      behavior: "smooth"
    })
  }

  return <button  style={{ width: "24px", height: "24px", borderWidth: "0px", borderRadius:"100%", backgroundColor: '#ac3ff5'}}
  className={`btn ${ isMobile ? '' : 'mr-2 ml-2'} p-1  d-flex align-items-center justify-content-center text-light`} onClick={scrollToCalendar} aria-label="Scroll to calendar" >
  <FaCalendarAlt/>
</button>

}

function getNextModuleId(completionMap, roadmapItemMap) {
  /**
   * roadmapItemMap is a map where keys are moduleId and value is map.
   * Each map has keys "startDate" and "isStudentModule". We want the
   * next incomplete module that is a student module with the smallest
   * startDate.
   */
  let nextModuleId = null
  let nextStartDate = Infinity
  for (const moduleId in roadmapItemMap) {
    const isComplete = isModuleComplete(completionMap, moduleId)
    if (isComplete) {
      continue
    }
    const module = roadmapItemMap[moduleId]
    if (!module.isStudentModule) {
      continue
    }
    if (module.startDate < nextStartDate) {
      nextStartDate = module.startDate
      nextModuleId = moduleId
    }
  }

  return nextModuleId
}

function isModuleComplete(completionMap, moduleId) {
  return Object.keys(completionMap).every((itemId) => completionMap[itemId])
}
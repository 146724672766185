import { Accordion } from "react-bootstrap"

/**
 * Content should be a list of dictionaries. Each dictionary has a question "q" and an answer "a"
 * 
 * Eg [
 *   {
 *     q:'this is a question',
 *     a:'this is an answer'
 *   }
 * ]
 */

export const FAQAccordion = ({content}) => {
    return <Accordion defaultActiveKey="-1" alwaysOpen={false}>
        {content.map((item, i) => {
            return <FAQItem questionData={item} key={i} index={i}/>
        })}
    </Accordion>
}

const FAQItem = ({questionData,index}) => {
    const question = questionData.q
    const answer = questionData.a
    return <Accordion.Item eventKey={index}>
        <Accordion.Header>{question}</Accordion.Header>
        <Accordion.Body><div className="text-muted ml-1">{answer}</div></Accordion.Body>
    </Accordion.Item>
}
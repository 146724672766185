import { getApp } from "firebase/app";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { useUserId } from "hooks/user/useUserId";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { 
  hashString
} from 'react-hash-string'
import { CompletionContext } from "contexts/CompletionContext";

export const useAppStatusServerRecorder = (appData, appDataPath, appType) => {
  const {setSelfCheckCompleted} = useContext(CompletionContext)

  const db = getFirestore(getApp());
  const userId = useUserId();
  const {targetCourseId} = useParams()


  const [appStatusCache, setAppStatusCache] = useState(appData?.appStatus)
  useEffect(() => {
    if(!appStatusCache) {
      return
    }
    if(appStatusCache === appData?.appStatus) {
      return
    }
    if(appStatusCache === "notStarted") {
      return
    }

    console.log('update the app status cache on the server!!')
    console.log()

    // 1. save it to the users appData
    const appDocRef = doc(db, appDataPath);
    setDoc(appDocRef, { appStatus: appStatusCache }, { merge: true });

    // 2. if the app is complete, record the complete date
    if (appStatusCache === "complete") {
      recordCompleteDate(appData, appDataPath);
      const completeKey = `applied-${targetCourseId}`;
      // this will only write if it changes the server value
      setSelfCheckCompleted(completeKey);
    }

    // 3. record the app status to a special document that tracks all app statuses
    saveToCourseAppStatus(db, targetCourseId, appType, userId, appStatusCache)



  }, [appStatusCache])

  const onAppStatusUpdate = (newAppStatus) => {
    if(!newAppStatus) {
      return
    }
    if(newAppStatus != appStatusCache) {
      setAppStatusCache(newAppStatus)
    }
  }

  return [onAppStatusUpdate]
}

function saveToCourseAppStatus(db, courseId, appType, userId, appStatus) {
  // shard is based on their userId. Each doc can have max 20,000 fields
  // by sharding, we can trakc k * 20,000 applications
  const nShards = 50

  // userId is a firebase auth uid, we need to hash it to a number. Do not use a custom hash function, use a library
  const userHash = Math.abs(hashString(userId))
  const shardIndex = userHash % nShards
  console.log('shardIndex', shardIndex)

  const docPath = `course/${courseId}/applications-${appType}/shard-${shardIndex}`
  console.log('docPath', docPath)
 
  // // save the app status to the course app status
  const courseAppStatusRef = doc(db, docPath)
  setDoc(courseAppStatusRef, {
    [userId]: appStatus
  }, {merge: true})
}

function recordCompleteDate(appData, appDataPath){
  if (!appData?.completedDate) {
    const db = getFirestore();
    const appDocRef = doc(db, appDataPath);
    updateDoc(appDocRef, { completedDate: new Date() });
  }
};


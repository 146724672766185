

import "./PrivatePostSplash.css";
import { FaLock } from "react-icons/fa";

export function PrivatePostSplash(props: {}) {
  return (
    <div className="postSplash">
      <FaLock
        className="fa-10x"
        color="rgb(89, 64, 203)"
      />
      <div className="spiel">
        <h1>This post is private!</h1>
        <h2>Select another post on the left</h2>
      </div>
    </div>
  );
}


import { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { CoursePageBodyContainer } from "../../../components/layout/CoursePageBodyContainer"
import { AssnContext } from "../../contexts/AssnContext"
import { createNewAssignment } from "./AssnEditor"
import { Loading } from "components/loading/Loading";
import { AssignmentsTable } from "./AssignmentsTable"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';


export const AssnEditorSplash = () => {
  return <CoursePageBodyContainer
    mainColumn={<AssnSplashMain />}
    rightColumn={<></>}
    singleColumn={<AssnSplashMain />}
  />
}

export const AssnSplashMain = () => {
  const { courseId } = useParams()

  const { assnMetaData } = useContext(AssnContext)

  return <>
    <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">Create new assignment</div>
    <AssnCreationForm courseId={courseId} navigateToAssn={true}/>
    <AssignmentsTable courseId={courseId} assnMetaData={assnMetaData}/>
  </>
}

export const AssnCreationForm = ({courseId, onCompletionCallback=(newAssnId)=>{} ,navigateToAssn=true}) => {
  const [newAssnId, setNewAssnId] = useState('');
  const [newAssnTitle, setNewAssnTitle] = useState('');
  const [newAssnType, setNewAssnType] = useState('karel');
  const navigate = useNavigate()

  const { assnMetaData, assnLoaded } = useContext(AssnContext)
  const allAssnIds = new Set(Object.keys(assnMetaData))

  if (!assnLoaded) return <Loading/>

  const onAssnCreated = (newAssnId) => {
    const assnEditUrl = `/${courseId}/assnEditor/${newAssnId}`
    // Because we navigate to the new assignment's page on creation, we do not need to manually tell the assignment editor to reload to reflect the new assignment.
    if(navigateToAssn) {    
      navigate(assnEditUrl)
    }

    onCompletionCallback(newAssnId);
  }


  return (
    <Stack direction="vertical" gap={3}>
      <Form.Control className="me-auto" placeholder="Enter the new assignment Title" value={newAssnTitle} onChange={(e) => setNewAssnTitle(e.target.value)} />
      <Form.Control className="me-auto" placeholder="Enter the new assignment ID" value={newAssnId} onChange={(e) => setNewAssnId(e.target.value)} />
      <select value={newAssnType} onChange={(e) => setNewAssnType(e.target.value)} className="form-control">
        <option value="karel">Karel</option>
        <option value="graphics">Graphics</option>
        <option value="console">Console</option>
      </select>
      <Button variant="primary" disabled={newAssnId === ''} onClick={() => verifyAndAddAssn(courseId, newAssnId, newAssnTitle, newAssnType, allAssnIds, onAssnCreated)}>Submit</Button>
    </Stack>
  )
}

async function verifyAndAddAssn(
  courseId,
  newAssnId,
  newAssnTitle,
  newAssnType,
  allAssnIds,
  callback
) {

  if (newAssnId === ''){
    Swal.fire('Oops...', "You can't have an empty assignment ID!", 'error')
    return
  }

  if (newAssnTitle === ''){
    Swal.fire('Oops...', "You can't have an empty assignment Title!", 'error')
    return
  }

  if (allAssnIds.has(newAssnId)){
    Swal.fire('Oops...', "There is already an assignment with this ID! Please pick another.", 'error')
    return
  }

  await createNewAssignment(
    courseId,
    newAssnId, 
    newAssnTitle,
    newAssnType,
    () => { }
  )

  callback(newAssnId)
}
import { useState, useEffect } from "react"
import Gate from "contexts/Gate"
import { CopyButton } from "components/reusableButtons/CopyButton";
import { FaCopy, FaLink } from 'react-icons/fa';
import { useCourseId } from "hooks/router/useUrlParams";
import { Link } from "react-router-dom";
import { useGetUserEmail } from "../../../firebase/functions/users";

export interface UserInfoRowProps {
  /**  The Firebase ID of the user. */
  userId: string;
  userData: any;
  viewerUserData: any;
}
export const UserInfoRow = ({ userId, userData, viewerUserData }: UserInfoRowProps) => {
    // admin-only information about this profile's user
    // userId + email
    const courseId = useCourseId()
    const [userEmail, setUserEmail] = useState('')
    const [userSection, setUserSection] = useState('')
  const [getUserEmail, getUserEmailLoading, getUserEmailError] = useGetUserEmail(); 

    useEffect(() => {
        getUserEmail({ uid: userId })
        .catch(console.error)
        .then((response) => setUserEmail(response?.["data"] ?? ''))
    }, [userId]);

    useEffect(() => {
        if (!userData || !userData.sections || !userData.sections[courseId]) return
        setUserSection(userData.sections[courseId][0].id ?? '')
    }, [userData, courseId]);

    const isTa = Gate.hasStaffRole(viewerUserData)
    const isAdmin = Gate.hasAdminRole(viewerUserData)
    // This code is redundant, but I wrote it for clarity
    if (!isTa && !isAdmin) {
      return <></>
    }

    if (isTa && !isAdmin) {
        return <div className="mb-2">
            <Link to={`/${courseId}/section/${userSection}`} className="btn btn-sm btn-light">Section <FaLink /></Link>
        </div>
    }

    return <div className="mb-2">
        <div className="mr-2" style={{display:'inline'}}>
            <CopyButton toCopy={userEmail} icon={<>Email <FaCopy /></>}/>
        </div>
        <div className="mr-2" style={{display:'inline'}}>
            <CopyButton toCopy={userId} icon={<>UID <FaCopy /></>} />
        </div>
        <Link to={`/${courseId}/section/${userSection}`} className="btn btn-sm btn-light">Section <FaLink /></Link>

    </div>
}
import { Formik, Form, useField, Field } from "formik";
import { Link } from "react-router-dom";


import {
  convertTimeToLocalTimezoneForSmallGroup,
  smallGroupTimeToDateTime,
  getSectionTimeStr,
  getTimezoneName,
  getTimeInTimezone,
  getLocalUTCTimezone,
} from "components/timezones/timezoneHelper";
import { ShowLocalTime } from "../../../studentOnboarding/schedule/ShowLocalTime";
import { useCourseId } from "hooks/router/useUrlParams";
import { CourseContext } from "contexts/CourseContext";


export function SchedulingForm({ currSelection, onSubmit, handleChange, availableTimes }) {
  const courseId = useCourseId();
  let initValues = {};
  let initValue = currSelection;
  initValues.time = initValue;


  const validationSchema = null;

  const timeZone = getLocalUTCTimezone();

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, validateField, validateForm }) => {
          const userTz = getLocalUTCTimezone();
          return <Form style={{ width: "100%" }}>


            <SelectInput
              label="Live Practice Time"
              name="time"
              description={''}
              onChange={handleChange}

            >
              <option value="none">Please select a time</option>
              {availableTimes.map((isoDateStr) => {
                let date = new Date(isoDateStr);
                let datePretty = date.toLocaleString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                });
                return <option value={isoDateStr} key={isoDateStr}>
                  {datePretty}
                </option>
            })}
            </SelectInput>

            <p className="description">If you can not make any of the available times, please email codeinplace@stanford.edu with all available times you could make a training in the next week.</p>

            {/* <TranslatedTimes
              sectionTimeIndex={sectionTime} /> */}

            <hr />

            <Link className="btn btn-secondary" to={`/${courseId}/onboarding/teach`}>
              Back
            </Link>
          </Form>;
        } }
      </Formik>
    </div>
  );
}

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: "select" });

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field as="select" className="select-input" {...field} {...props}>
        {props.children}
      </Field>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};


// get the label for the ith option given user is in timeZoneStr (eg "UTC+4:00")

const TranslatedTimes = (props) => {

  let tz = getLocalUTCTimezone()
  let i = props.sectionTimeIndex;
  console.log(tz)
  let timeZones = [
    { city: "San Francisco", timeZone: "UTC-7:00" },
    { city: "New York", timeZone: "UTC-4:00" },
    { city: "London", timeZone: "UTC+1:00" },
    { city: "Istanbul", timeZone: "UTC+3:00" },
    { city: "Singapore", timeZone: "UTC+8:00" },
  ];
  const listItems = timeZones.map((value) => (
    <li key={value.city}>
      {value["city"]}: {getSectionTimeStr(value["timeZone"], i)}
    </li>
  ));
  return (
    <div className="card mb-5">
      <div className="card-body">
        <p>
          In case you are curious, here is the local time of your section in
          different cities:
        </p>
        <ul>
          {listItems}
          <li key="tz">Your timezone: {getSectionTimeStr(tz, i)}</li>
        </ul>
      </div>
    </div>
  );

}




const TERMINAL_THEME  = {
  foreground: '#F8F8F8',
  background: '#2D2E2C',
  selectionBackground: '#5DA5D533',
  black: '#1E1E1D',
  brightBlack: '#262625',
  red: '#CE5C5C',
  brightRed: '#FF7272',
  green: '#5BCC5B',
  brightGreen: '#72FF72',
  yellow: '#CCCC5B',
  brightYellow: '#FFFF72',
  blue: '#5D5DD3',
  brightBlue: '#7279FF',
  magenta: '#BC5ED1',
  brightMagenta: '#E572FF',
  cyan: '#5DA5D5',
  brightCyan: '#72F0FF',
  white: '#F8F8F8',
  brightWhite: '#FFFFFF'
};

// If I write something in bold, it might show up in a color accoring to drawBoldInBrightColors
export const TERMINAL_SETTINGS = {
  theme: TERMINAL_THEME,
  fontFamily: 'Menlo, monospace',
  fontSize: 14,
  cursorBlink: true,
  title: "Terminal",
  wordWrap: true,
}


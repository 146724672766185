import React, { useEffect, useState } from "react";

import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { query, where, orderBy, limit } from "firebase/firestore";
import { Button, Skeleton } from "antd";
import { PostReply } from "../PostReply/PostReply";

import "./PostReplies.css";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";

export function PostReplies(props: {
  parent: string;
  postId: string;
  level: number;
  condensed: boolean;
}) {
  const { parent, postId, level, condensed } = props;
  const dataFetcher = useForumDataFetcher();

  const [showReplies, setShowReplies] = useState(!condensed);
  const toggleShowReplies = () => setShowReplies(!showReplies);

  const repliesQuery = useFirestoreQuery(
    ["repliesto", parent],
    query(
      dataFetcher.get_reply_collection_ref(),
      where("parent", "==", parent),
      where("isDraft", "==", false),
      orderBy("time", "desc"),
      limit(100)
    ),
    { subscribe: true }
  );

  useEffect(() => {
    if (repliesQuery.data) {
      // show replies IF we are at level less than or eq to 3 OR there are no replies
      setShowReplies(level <= 3 || repliesQuery.data.docs.length === 0);
    }

  }, [repliesQuery.data])

  if (repliesQuery.isLoading) {
    return <Skeleton />;
  }

  const replyIds = repliesQuery.data.docs.map((doc) => doc.id);

  return (
    <div className="repliesContainer">
      <div className="replyBorder" onClick={toggleShowReplies} />
      <div className="replies">
        {showReplies ? (
          replyIds.map((id, i) => (
            <PostReply replyId={id} level={level} key={i} postId={postId} />
          ))
        ) : (
          <Button
            onClick={toggleShowReplies}
            type="text"
            className="showRepliesButton"
          >
            Show Replies
          </Button>
        )}
      </div>
    </div>
  );
}

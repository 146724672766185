// write a custom hook that will fetch the current week from firestore
// firestore path: /courses/{courseId}/releaseFlags/section/currentWeek

import { useState, useEffect } from "react";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";

export const useSectionWeekIdePath = (courseId, sectionNum) => {
    const [idePath, setIdePath] = useState(undefined);
    const db = getFirestore()
    // get the current week from firestore path above and return using a use effect
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "course", courseId, "sectionIDEPaths"), (snapshot) => {
            if(snapshot.docs.length === 0) {
                console.log("No IDE Path found for this week's section, leaving IDE path undefined")
            } else {
                const data = snapshot.docs[0].data()
                setIdePath(data[sectionNum])
            }  
        })
        return unsubscribe
    }
    , [courseId])
    return idePath
}

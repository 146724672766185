import styled from 'styled-components';
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import { useIsMobile } from "utils/general";
import Confetti from 'react-confetti'
import Swal from "sweetalert2";
import { useContext, useState } from "react";
import { TeachNowTrainingContext } from "./TeachNowTrainingContext";
import { CompletionContext } from "contexts/CompletionContext";
import { useWindowSize } from "react-use-size";
import { TeacherPuzzles } from "./TeacherPuzzles";
import { useUserId } from 'hooks/user/useUserId';
import { useCourseId } from 'hooks/router/useUrlParams';
import { TimeContext } from 'contexts/TimeContext';
import { ProfileContext } from 'contexts/ProfileContext';
import { copyVideo, studentConfirmTrainingComplete } from './TNFirebase';
import { EndOfTraining } from './TrainingSlides';



const NavigationOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  max-width: 1200px;
`;


const OuterBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100vh;
  overflow: hidden;
  // padding-bottom:20px;
  
  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
  @media (max-height: 800px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
`;

const Scrollable = styled.div`
  flex: 1;
  display: flex;
  width:100%;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 1200px;
  flex-direction: column;
  `

const Footer = styled.div`
  height:80px;
  width: 100%;
  // border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  justify-content: center;

  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    align-items: center; /* Aligns the buttons to the center */
  }
`;


const Navigation = () => {

    const isMobile = useIsMobile();

    const { lessonData, closeLesson, prevSlide, slides, currSlideIndex } = useContext(TeachNowTrainingContext);
  
    // you start at 0, but skip to considering the current page as
    // complete from the first slide
    let now = (currSlideIndex + 1) / slides.length * 100;
    if (currSlideIndex == 0) {
      now = 0;
    }
  
    const displayTitle = isMobile ? lessonData.shortTitle : lessonData.title;
  
    return <NavigationOuter>
      <button
        aria-label="exit lesson"
        onClick={closeLesson}
        className="btn btn-light mr-1"
      ><FaTimes /></button>
  
      {displayTitle}
  
      <Button variant={"light"} className="mr-1 ml-1" disabled={currSlideIndex == 0} onClick={prevSlide} aria-label="go back"><FaChevronLeft /></Button>
  
      <ProgressBar now={now} className="flex-grow-1 mr-1" />
  
    </NavigationOuter>
  }
  

  const ContinueButton = ({markCompleteAndSubmit=false}) => {
    const isMobile = useIsMobile();
    const { currSlideData, nextSlide, currSlideId } = useContext(TeachNowTrainingContext);
    const { setTeachNowTrainingSlideCompleted } = useContext(CompletionContext);
    const { getServerTimeMs } = useContext(TimeContext)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { userData } = useContext(ProfileContext);
    const displayName = userData.displayName;

    const userId = useUserId();
    const courseId = useCourseId();

    async function submitForReview () {
      setIsSubmitting(true)
      // first copy the video to the submitted file pth and store the new path 
      const timestamp = getServerTimeMs()
      const videoPath = await copyVideo(userId, timestamp)
      // Make a submitted student document with displayName, userId, and videopath 
      await studentConfirmTrainingComplete(userId, courseId, displayName, videoPath)
      setIsSubmitting(false)
    }
  
    const onContinue = async () => {
      if (markCompleteAndSubmit) {
        await submitForReview()
      }
      setTeachNowTrainingSlideCompleted(currSlideId)
      nextSlide()
    }
  
    let canSkip = true;
  
    const text = currSlideData?.continueButtonText || "Continue";
  
    const hideFooter = !canSkip;
    const showFooter = !(hideFooter);
  
    if (hideFooter) {
      return <></>
    }
  
    const size = isMobile ? "md" : "lg";

    if (isSubmitting) {
      return <Footer>
        <Button
          disabled={true}
          // @ts-ignore
          size={size}><Spinner/></Button>
      </Footer>
    }
  
    return <Footer>
      {showFooter && <Button
        onClick={onContinue}
        disabled={false}
        // @ts-ignore
        size={size}>{text}</Button>}
    </Footer>
  };

const LastSlide = () => {
  const [showContinueButton, setShowContinueButton] = useState(false);

  return (
    <OuterBody>
        <Navigation />
        <Scrollable>
            <EndOfTraining
              setShowContinueButton={setShowContinueButton}
            />
        </Scrollable>
        {
          showContinueButton && <ContinueButton 
          markCompleteAndSubmit={showContinueButton}
        />
        }
        
    </OuterBody>
    )
  }

export const TeachNowTrainingSlide = () => {
  const { currSlideData } = useContext(TeachNowTrainingContext);
  const { isAssnCompleted } = useContext(CompletionContext);
  const assnId = currSlideData?.data?.assnId;
  
  // Remove continute button in slide data has priority. Then check the assnId logic which gates the continue button for IDE pages
  const markCompleteAndSubmit = currSlideData?.data?.markCompleteAndSubmit;
  const isLastSlide = currSlideData?.isLastSlide;
  const removeContinueButton = currSlideData?.data?.removeContinueButton;
  let showContinueButton = true;
  if (removeContinueButton) {
    showContinueButton = false;
  } else {
    showContinueButton = (assnId && isAssnCompleted(assnId)) || (isLastSlide && markCompleteAndSubmit) || (!isLastSlide && !assnId)
  }

  const ContinueButtonComponent = showContinueButton ? ContinueButton : () => <></>;
  if(currSlideData && currSlideData.type === 'teacherPuzzle') {
    return <OuterBody>
        <Navigation />
          <TeacherPuzzles />
      </OuterBody>
  } else if (isLastSlide) {
    return <LastSlide />
  } 
  
  else {
    return (
    <OuterBody>
        <Navigation />
        <Scrollable>
            <TeachNowContent />
        </Scrollable>
        <ContinueButtonComponent />
    </OuterBody>
    )
  }
}

const TeachNowContent = () => {
    const { setTeachNowTrainingSlideCompleted } = useContext(CompletionContext);
    const { armId, currSlideIndex, currSlideData, nextSlide, currSlideId } = useContext(TeachNowTrainingContext);
    const [confettiIndex, setConfettiIndex] = useState(0);
    const windowSize = useWindowSize();
    if (!currSlideData) {
      return <>No content</>
    }
  
    const onComplete = () => {
      if (currSlideData?.data?.confetti) {
        setConfettiIndex(confettiIndex + 1);
      }
      setTeachNowTrainingSlideCompleted(currSlideId)
      setTimeout(() => {
        Swal.fire({
          title: 'Good job!',
          icon: 'success',
          confirmButtonText: 'Next'
        }).then(() => {
          nextSlide()
        })
      }, 500)
    }

    return <>
      <Confetti
        run={confettiIndex > 0}
        key={confettiIndex}
        width={windowSize.width}
        height={windowSize.height}
        recycle={false}
        numberOfPieces={500}
  
      />
      <currSlideData.component
        key={currSlideIndex}
        data={currSlideData.data}
        onComplete={onComplete}
        nextSlide={nextSlide}
      />
    </>
}

import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import Transcript from "course/teach/components/Transcripts";
import { doc, getFirestore } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router";
import styled from "styled-components";

export const SectionTranscript = () => {
  const { sectionId, sectionWeek } = useParams();
  const courseId = useCourseId();

  const transcriptPath = `transcriptFeedback/${courseId}/${sectionWeek}/feedback/${sectionId}/transcript`;
  console.log(transcriptPath);
  const [transcriptData, transcriptLoading, transcriptError] =
    useDocumentDataOnce(doc(getFirestore(), transcriptPath));

  if (transcriptLoading) return <></>;

  let content = <SectionTranscriptInner transcriptData={transcriptData} />;

  return (
    <CoursePageBodyContainer
      mainColumn={content}
      rightColumn={<></>}
      singleColumn={content}
    />
  );
};

const SectionTranscriptInner = ({ transcriptData }) => {
  const transcript = transcriptData?.transcript;
  if (!transcript) return <>Transcript not found</>;

  return (
    <>
      <div className="mb-5">
        <div className="mt-5">
          <h1>Transcript of the section</h1>
        </div>
        {transcript.map((item, index) => {
          return <TranscriptItem key={index} item={item} />;
        })}
      </div>
    </>
  );
};

const TranscriptContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
`;

const SpeakerDetails = styled.p`
  margin-bottom: 0;
`;

const SpeakerLabel = styled.span`
  font-weight: bold;
  color: #666;
  margin-right: 0.25rem;
`;

const TranscriptText = styled.div`
  margin-top: 0.25rem;
  color: #666;
`;

const TimeStampOuter = styled.span`
  color: #999;
  font-size: 0.9rem;
`;

const TranscriptItem = ({ item }) => {
  const { chat, data: text, speaker, time: timeStamp } = item;

  let speakerTag = speaker;
  if (chat) speakerTag += " [In Chat]";

  return (
    <TranscriptContainer>
      <SpeakerDetails>
        <SpeakerLabel>{speakerTag}</SpeakerLabel>
        <TimeStamp msSinceStart={timeStamp} />
      </SpeakerDetails>
      <TranscriptText>{text}</TranscriptText>
    </TranscriptContainer>
  );
};

const TimeStamp = ({ msSinceStart }) => {
  // format the time stamp (m)
  const minutes = Math.floor(msSinceStart / 60000);
  const seconds = ((msSinceStart % 60000) / 1000).toFixed(0);
  return (
    <TimeStampOuter>
      {minutes}mins : {seconds}seconds
    </TimeStampOuter>
  );
};

import { UnitTestButton } from 'ide/UnitTest/RunUnitTestButton';
import styled from 'styled-components';

export const GraphicsCanvas = (props) => {
  return (
    <>
      <CanvasOuter
        className="d-flex justify-content-end w-100 h-100"
      >
        <div>
          <CanvasPane />
          <div className="d-flex" style={{ justifyContent: "end" }}>
            <UnitTestButton />
          </div>
        </div>
      </CanvasOuter>
    </>
  );
};

const CanvasPane = (props) => {

  return (
    <>
      <div
        style={{}}
      >
        <div>
          <canvas
            style={{
              backgroundColor: 'white',
              border: "solid 1px",
            }}
            width={400}
            height={400}
            id="canvas"
            role='image'
          ></canvas>
          <br />
        </div>
      </div>
    </>
  );
};


const CanvasOuter = styled.div`
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0px;
  }
`
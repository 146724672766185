import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
import {
arrayMove,
SortableContext,
sortableKeyboardCoordinates,
verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { CourseMenuItem, Divider, } from 'course/components/sidebar/CourseSidebar';
import { ForumsSubMenu } from 'course/components/sidebar/SubMenus'
import { getIcon, getTitle } from 'course/components/sidebar/courseSidebarUtils';
import { Menu } from 'react-pro-sidebar';
import { menuItemStyles } from 'course/components/sidebar/menuItemStyles';
import { useParams } from 'react-router';

export const SortableMenuItems = ({ menuList, setMenuList, courseId, isSl }) => {
    if(!menuList) {
        return null;
    }   
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 100, // delay in ms
                tolerance: 3 // minimum movement to start the drag operation
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = menuList.indexOf(active.id);
            const newIndex = menuList.indexOf(over.id);
            const newList = arrayMove(menuList, oldIndex, newIndex);
            setMenuList(newList)
        }
    }





    return (
        <div>
            <Menu menuItemStyles={menuItemStyles}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd} 
            >
                <SortableContext
                    items={menuList}
                    strategy={verticalListSortingStrategy}
                >
                    {
                        menuList.map((item) => <SortableMenuItem
                            key={item}
                            menuItem={item}
                            courseId={courseId}
                            isSl={isSl}
                        />
                        )
                    }

                </SortableContext>
            </DndContext>
            </Menu>
        </div>
    )
}



const SortableMenuItem = ({menuItem, courseId, isSl}) => {


    const Component = () => {
        if(menuItem === "-" ) {
            return <Divider/>
        }

        if(menuItem === "forums") {
            return <ForumsSubMenu key={menuItem} courseId={courseId} collapsed={false} isSectionLeader={isSl}                 isLinkActive={false}
            />
        }

        return (
            <CourseMenuItem
                key={menuItem}
                courseId={courseId}
                collapsed={false}
                subpage={menuItem}
                icon={getIcon(menuItem)}
                title={getTitle(menuItem)}
                isLinkActive={false}
            />

        )
    }


    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: menuItem });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%"
    };

    return (
        <div key={menuItem} ref={setNodeRef} {...attributes} {...listeners} style={style} className='d-flex justify-content-between sidebarButton'>
            <Component />
        </div>
    )


}



import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { useCourseId } from "hooks/router/useUrlParams";

export const GradesRedirect = () => {
    const { userData } = useContext(ProfileContext);
    const navigate = useNavigate();
    const isAdmin = Gate.hasAdminRole(userData);
    const isSL = Gate.hasSectionLeaderRole(userData);
    const isStudent = Gate.hasStudentRole(userData);
    const courseId = useCourseId();

    useEffect(() => {
        if (isAdmin){
            navigate(`/${courseId}/gradingsplash`)
            return
        }
        if (isSL) {
            return
        } else if (isStudent) {
            navigate(`/${courseId}/studentgrades`)
            return
        }
    }, [isSL, isStudent, navigate]);

    if (isSL){
        // navigate("mentorgrades");
        return <>SL grading is a work in progress... coming soon!</>
    }

    return null;
}
import { PythonHighlighter } from "../../components/syntaxHighlighter/PythonHighlighter";

export const ToneVideoInstructions = () => {
  return <div className="p-3" style={instructionsStyle}>
    <Content/>
    </div>
}

const Content = () => (<div>


  <h4>Instructions</h4>

  <hr />

  <p>As part of your training, prepare a short, informal <strong>1 minute</strong> video. This will simulate the start of a TeachNow session. In the video, introduce yourself and set a welcoming tone for the meeting.</p>
  
  <p>Here is an example script we recommend basing your video off of:</p>
  <blockquote><i><b>Hello! I’m [your_name]! I am also a student in the course, and I’ve recently started helping out as a section leader in training. I’m here to see how the course is going for you and to tackle any challenges together. How is this assignment going so far?</b></i></blockquote>

  <h4 id="logistics">Logistics</h4>

  <ul>
    <li>
      <p><strong>Recording</strong> You can record using whatever tools you have available: webcam, screen capture, smart phone, etc. One option is to record using Zoom (instructions below). However you record, it should be a "single take", and you shouldn't do any post-production.</p>
    </li>
    <li>
      <p><strong>Length of the video</strong> We ask for a video of around 1 minute. Anything longer is too long! Please cut the video at a maximum of 1 minute.</p>
    </li>
    <li>
      <p><strong>Uploading the video</strong> After completing your recording, save the video file. You will upload your video using the button to the right.</p>
    </li>
    <li>
      <p><strong>Using Zoom to record (optional)</strong></p>

      <p>Here are instructions for using Zoom to make your recording. (You do not need to use Zoom; it is just an option)</p>

      <ul>
        <li>Open Zoom and press "New Meeting" (or "Host a Meeting").</li>
        <li>Make sure that your microphone is unmuted and if possible, that your video is on.</li>
        <li>When you're ready to record, bring up the menu and press "Record" and then "Record on this computer".</li>
        <li>Once you're finished, hit the square Stop symbol to stop the recording, and then "End Meeting."</li>
        <li>After ending the meeting, Zoom will save the recording and should automatically open up the folder where the file was saved (if not, go to "Documents/Zoom/[insert date of recording + name of Zoom meeting room]").</li>
        <li>The saved mp4 file (which should be called zoom_0.mp4 or something similar) is the one you will upload with your section leader application.</li>
        <li>For more information troubleshooting, see this support article from Zoom <a href="https://support.zoom.us/hc/en-us/articles/201362473-Local-Recording" target="_blank" rel="noopener noreferrer">https://support.zoom.us/hc/en-us/articles/201362473-Local-Recording</a>
        </li>
      </ul>
    </li>
  </ul>

</div>)




const instructionsStyle = {
  overflow:'auto',
  maxWidth:'700px',
}
import Swal from "sweetalert2";


export const postFlaggedAlert = async () => {
    await Swal.fire({
        title: "Post flagged",
        html: "Your post has been flagged for review by a staff member. You will be notified when it is approved.",
        icon: "warning",
        allowOutsideClick: false,
        showConfirmButton: true,
    })
}


export const postNotFlaggedAlert = async () => {
const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    allowOutsideClick: true,
    });

    await Toast.fire({
    icon: "success",
    title: "Post Succeeded",
    });
}


export const popLoadingAlert = (loadingMessage="Loading") => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        allowOutsideClick: true,
    });

    Toast.fire({
        icon: "info",
        title: loadingMessage,
    });


    return Toast.close;
}


export const errorToast = async (message="error", time=1000) => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: time,
        allowOutsideClick: true,
    });

    await Toast.fire({
        icon: "error",
        title: message,
    });
}


export const confirmDelete = async (message="Are you sure you want to delete this post? This action cannot be undone.") => {
    const val =  await Swal.fire({
        title: "Delete Post",
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
    });

    return val.isConfirmed;
}
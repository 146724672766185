
const WrittenResponseScores = ({ noticing, setNoticing, responseToStudent, setResponseToStudent }) => (
  <div>
    <div className="mb-3">
      <label htmlFor="noticing" className="form-label" style={{fontSize: '1rem'}}>
        Noticing:
      </label>
      <select 
        id="noticing"
        className="form-select"
        value={noticing}
        onChange={(e) => setNoticing(e.target.value)}
      >
        <option value="" disabled>Select score</option>
        {['None', 'Some', 'All'].map((score, index) => (
          <option key={index} value={score}>{score}</option>
        ))}
      </select>
      <label htmlFor="responseToStudent" className="form-label" style={{fontSize: '1rem'}}>
        Response to Student:
      </label>
      <select 
        id="responseToStudent"
        className="form-select"
        value={responseToStudent}
        onChange={(e) => setResponseToStudent(e.target.value)}
      >
        <option value="" disabled>Select score</option>
        {['Needs Improvement', 'Satisfactory', 'Excellent' ].map((score, index) => (
          <option key={index} value={score}>{score}</option>
        ))}
      </select>
    </div>
  </div>
);

export default WrittenResponseScores;

import { CompletionContext } from "contexts/CompletionContext"
import { ROADMAP } from "course/editors/roadmapEditor/RoadmapSchema"
import { useState, useEffect, useContext } from "react"

export const useNextIncompleteModuleId = (moduleId) => {
}

export const useCompletionMap = (todoItemList) => {
  const { isAssnCompleted, isLessonCompleted, isSelfCheckCompleted, isSectionCompleted } = useContext(CompletionContext)

  const completionMap = {}

  const checkIsComplete = (todo) => {
    switch (todo.itemType) {
      case ROADMAP.AUTO_CHECK.key:
        return true
      case ROADMAP.LESSON.key:
        return isLessonCompleted(todo.completionId)
      case ROADMAP.ASSIGNMENT.key:
        return isAssnCompleted(todo.completionId)
      case ROADMAP.UNTIED_LINK.key:
      case ROADMAP.COMPLETION_LINK.key:
        return isSelfCheckCompleted(todo.completionId)
      case ROADMAP.SECTION.key:
        return isSectionCompleted(todo.completionId)
    }
  }

  if (todoItemList) {
    for (let todo of todoItemList) {
      const isComplete = checkIsComplete(todo)
      completionMap[todo.id] = isComplete
    }
  }

  


  return { completionMap }

}
import { useState, useEffect } from "react";

const ProgressChart = ({
  benchmarkValue = 10,
  benchmarkText = "Benchmark",
  progressValue = 50,
  progressText = "Progress",
  goalText = "Goal",
  goalValue = 100,
  unit = "%",
}) => {
  const [progress, setProgress] = useState(0);
  const [benchmark, setBenchmark] = useState(0);
  useEffect(() => {
    if (progressValue > goalValue) {
      setProgress(goalValue);
    } else {
      setProgress(progressValue);
    }
  }, [progressValue, benchmarkValue]);
  return (
    <div style={{ marginTop: "25px", marginBottom: "30px" }}>
      <div
        style={{
          width: "100%",
          height: "20px",
          display: "block",
          position: "relative",
          backgroundColor: "lightgray",
        }}
      >
        <div
          style={{
            width: `${(progress / goalValue) * 100}%`,
            backgroundColor: "#8f43f2",
            height: "inherit",
            display: "inline-block",
          }}
        ></div>
        {progressValue < goalValue && (
          <div
            style={{
              position: "absolute",
              left: `calc(${(progress / goalValue) * 100}% - 10px)`,
              top: "18px",
              fontSize: "0.9em",
            }}
          >
            <p style={{ textAlign: "right" }}>
              {progressText}: {progressValue}
              {unit}
            </p>
          </div>
        )}

        <div
          style={{
            textAlign: "right",
            fontSize: "0.9em",
            position: "absolute",
            right: "0px",
            bottom: "0px",
          }}
        >
          <p>
            {goalText}: {goalValue}
            {unit}
          </p>
        </div>
        {benchmarkValue < goalValue && (
          <div>
            {" "}
            <div
              style={{
                position: "absolute",
                left: `${(benchmarkValue / goalValue) * 100}%`,
                top: "0px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "2px",
                  backgroundColor: "black",
                  height: "26px",
                  bottom: "3px",
                  left: "-1px",
                  position: "relative",
                }}
              ></div>
              <div
                style={{
                  display: "inline-block",
                  bottom: "26px",
                  left: "-4px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <p
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "600",
                    overflow: "hidden",
                    margin: "0px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {benchmarkText}: {benchmarkValue}
                  {unit}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {progressValue > goalValue && (
        <div>
          <p>Congratulations, you have exceeded the goal!</p>
        </div>
      )}
    </div>
  );
};
export default ProgressChart;

import { AccordionBody } from "../components/SortableLessonTable"
import { LessonsContext, createExample, createReading } from "course/contexts/LessonsContext"
import { TrainingContext } from "course/contexts/TrainingContext"
import { useContext } from "react"
import { getNewLessonElementInputs } from "../LessonAlerts"
import { duplicateDocId, dataValidationError,  isValidId, invalidIdSwal } from "course/editors/OnEventAlerts"
import { Loading } from "components/loading/Loading"
import { getSingleValue } from "course/editors/OnEventAlerts"
import { FaBookOpen, FaChevronLeft, FaEdit, FaPlay, FaPlus, FaPython, FaUpload } from "react-icons/fa"
import { useCourseId } from "hooks/router/useUrlParams"
import { Link, useParams } from "react-router-dom"
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { ResourcesTable } from "../components/ResourcesTable"
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate"


export const LessonItemsEditor = () => {
  return <CoursePageBodyContainer
  mainColumn={<LessonItemEditorColumn />}
  rightColumn={<></>}
  singleColumn={<LessonItemEditorColumn />}
/>
}

const LessonItemEditorColumn = () => {
  const { editorType, lessonId } = useParams()
  const navigate = useHistoryNavigate()
  const { lessonsMap, appendSlide, docIdToLessonId, editLessonTitleInDB } = useContext(editorType == 'student' ? LessonsContext : TrainingContext)
  const courseId = useCourseId()

  if (!lessonsMap) return (<Loading />)

  const slidesIds = lessonsMap[lessonId]?.slides || []

  const editTitleBtn = async (e) => {
    const inputValue = lessonsMap[lessonId].title
    const lessonTitle = await getSingleValue('Change the Lesson Title', "New Title", inputValue)
    if (lessonTitle) {
      editLessonTitleInDB(lessonId, lessonTitle)
    }
  }

  if (!Object.keys(lessonsMap).includes(lessonId)) return (<div className="m-3 p-3">This lesson does not exist!</div>)

  const createItem = async () => {

    const resourceInfo = await getNewLessonElementInputs(slidesIds)
    if (!resourceInfo) return
    if (resourceInfo.docId in docIdToLessonId) {
      await duplicateDocId()
      return;
    } else if (!resourceInfo.docId || !resourceInfo.title || !resourceInfo.type) {
      await dataValidationError()
      return
    } else if (!isValidId(resourceInfo.docId)) {
      await invalidIdSwal();
      return
    } else {
      appendSlide(lessonId, resourceInfo.docId, resourceInfo.title, resourceInfo.type)
    }
  }
  return (
    <div className="m-3 p-4">
      <div className="mt-4" >
        <h3>Lesson: {lessonsMap[lessonId].title}</h3>
        <Link
          to={`/${courseId}/lessoneditor/${editorType}`}
          className="btn btn-light ml-1 mr-1"
        ><FaChevronLeft /> Back</Link>
        <button onClick={editTitleBtn} className="btn btn-light ml-1 mr-1"><FaEdit /></button>
        <button
          onClick={()=> navigate(`/${courseId}/${editorType == 'student' ? 'learn' : 'training'}/${lessonId}`)}
          className="btn btn-light ml-1 mr-1"
        ><FaPlay /></button>

      </div>

      <button className='btn btn-primary mt-4 mb-4 mr-1' onClick={(createItem)}>
        <FaPlus /> Add Slide
      </button>
      <button className='btn btn-secondary mt-4 mb-4 ml-1 mr-1' onClick={() => createReading(courseId, lessonId)}>
        <FaPlus /> Add Reading
      </button>
      <button className='btn btn-secondary mt-4 mb-4 ml-1 mr-1' onClick={() => createExample(courseId, lessonId)}>
        <FaPlus /> Add Example
      </button>
      <button className='btn btn-secondary mt-4 mb-4 ml-1' onClick={()=>{}}>
        <FaUpload /> Upload Slides
      </button>
      <h4>Lesson Outline</h4>
      <AccordionBody lessonId={lessonId} />
      <h4 className="mt-3">Readings</h4>
      <ResourcesTable 
        lessonId={lessonId}
        resourceKey={'readings'}
        valueKey={'url'}
        icon={<FaBookOpen/>}
        />
      <h4 className="mt-3">Extra Examples</h4>
      <ResourcesTable 
        lessonId={lessonId}
        resourceKey={'examples'}
        valueKey={'assnId'}
        icon={<FaPython/>}
        />
    </div>
  )
}
import { getAuth, signOut } from "firebase/auth";

export const NoAccess = () => {

  return (
    <div className="landingContainer">
      <div
        className="splashBackground"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/error/noentry.jpg')`,
        }}
      >
        <div className="card">
          <div className="card-body">
            <h1>Unable to access this page</h1>
            
          </div>
        </div>
        <button onClick={() => signOut(getAuth())}className="btn btn-light mt-3">Sign Out</button>
      </div>
    </div>
  );
};

export default NoAccess;

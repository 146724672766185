import { Loading } from "components/loading/Loading"
import { getApp } from "firebase/app"
import { doc, getFirestore, updateDoc } from "firebase/firestore"
import { functions } from "firebaseApp"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useEffect } from "react"
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { useNavigate } from "react-router"
import Swal from "sweetalert2"
import { GenericBackdrop, GenericCenteredContainer } from "./BackgroundStyles"

export const CertificateRedirect = () => {
  const courseId = useCourseId()
  const navigate = useNavigate()
  const db = getFirestore(getApp())
  const userId = useUserId()
  const certificateDocPath = `users/${userId}/${courseId}/certificate`
  const certificateDoc = doc(db, certificateDocPath)
  const [certificateData, certificateLoading, certificateError] = useDocumentData(certificateDoc)
  const [courseReleaseData, courseReleaseLoading, courseReleaseError] = useDocumentDataOnce(doc(db,`course/${courseId}/docs/releases`))
  


  const error = <>Missing certificate for this user. Please contact the course staff codeinplace@stanford.edu</>

  if (certificateLoading || courseReleaseLoading) return <Loading />

  if (!courseReleaseData || !("certificate" in courseReleaseData) || !courseReleaseData.certificate) {
    return <GenericBackdrop>
      <GenericCenteredContainer>
        <h1>Certificate is not released yet</h1>
      </GenericCenteredContainer>
    </GenericBackdrop>
  }


  if (certificateData == undefined) {
    console.log('create!!!')
    return <CreateCretificate />
  }

  const certificateId = certificateData.certificateId
  if (certificateId == undefined) {
    return <>Certificate is malformed</>
  }

  navigate(`/${courseId}/certificate/${certificateId}`)

}

const CreateCretificate = () => {
  const navigate = useNavigate()
  const db = getFirestore(getApp())
  const userId = useUserId()
  const courseId = useCourseId()
  const applicationDocPath = `users/${userId}/${courseId}/studentApplication`
  const appDoc = doc(db, applicationDocPath)
  const [appData, appLoading, appError] = useDocumentDataOnce(appDoc)

  

  const onComplete = (certificateId) => {
    navigate(`/${courseId}/certificate/${certificateId}`)
  }

  useEffect(() => {
    if (!appLoading) {
      const initialName = appData?.name
      if (!initialName) {
        changeName('',courseId, onComplete)
      } else {
        initialNameCheck(appData.name,courseId,onComplete)
      }
    }

  }, [appLoading])

  if (appLoading) return <></>

  return <></>
}

function initialNameCheck(name, courseId, onComplete) {
  Swal.fire({
    title: 'Certificate Creation',
    html: `Is this the name you would like on your certificate?<br/><br/>${name}`,
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      finalConfirmation(name, courseId, onComplete)
    } else {
      changeName(name, courseId, onComplete)
    }
  })
}

function changeName(name, courseId, onComplete) {
  Swal.fire({
    title: 'Set Certificate Name',
    input: 'text',
    inputValue: name,
    showCancelButton: true,
    confirmButtonText: 'Change Name',
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      finalConfirmation(result.value, courseId, onComplete)
    } else {
      initialNameCheck(name, courseId, onComplete)
    }
  })
}

function finalConfirmation(name, courseId, onComplete) {
  // make them type their name to confirm
  Swal.fire({
    title: 'Are you sure?',
    html: `We are about to create your certificate with name<br/><br/>${name}<br/><br/>This cannot be changed later. Are you sure you want to continue?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes! Create Certificate',
    cancelButtonText: 'Cancel',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      createCertificate(name, courseId, onComplete)
    } else {
      initialNameCheck(name, courseId, onComplete)
    }
  })

}

async function createCertificate(name, courseId, onComplete) {

  const createCertificate = functions.httpsCallable('createCertificate');

  Swal.fire({
    title: 'Creating certificate...',
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
    }
  })


  const response = await createCertificate({ 
    nameOnCertificate:name,
    courseId:courseId
  });
  if ('errorMessage' in response.data) {
    Swal.fire({
      title: 'Error',
      text: `Unable to create certificate: ${response.data.errorMessage}`,
      icon: 'error',
    })
    return
  }

  const { certificateId } = response.data;

  // Close the Swal after receiving the response
  Swal.close();

  // go to the certificate page
  onComplete(certificateId)
  

}
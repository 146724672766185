
import { ProfileContext } from "contexts/ProfileContext"
import { useContext } from "react"
import { Navigate, useNavigate } from "react-router"
import {useCourseId} from "hooks/router/useUrlParams"
import { Link } from "react-router-dom"
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"

export const SectionSplash = ({}) => {
  const {userData} = useContext(ProfileContext)

  const navigate = useNavigate()
  const courseId = useCourseId()
  const courseRole = userData.courseRole

  // this is a dictionary of courseId: list of sectionRefs
  // I want to get the list of sectionRefs
  const sectionRefs = userData.sections[courseId]
  const nSections= sectionRefs ? sectionRefs.length : 0

  // useEffect(()=> {
  //   if(!sectionDataIsLoading){
  //     console.log(nSections)
  //     if(nSections === 1){
  //       const sectionId = Object.keys(sectionData)[0]
  //       const sectionUrl = `/${courseId}/section/${sectionId}`
  //       console.log(sectionUrl)
  //       navigate(`/${courseId}/section/${sectionId}`)
  //     }
  //   }
  // }, [sectionDataIsLoading])

  // if(!isMinimumRole(courseRole, Role.SECTION_LEADER)){
  //   return <ClassNotOpen />
  // }

  if(nSections > 1){
    return <>You are in multiple sections!</>
  }

  if(nSections === 1){
    const sectionRef = sectionRefs[0]
    const sectionId = sectionRef.id
    // don't add to the browser history
    return <Navigate to={`/${courseId}/section/${sectionId}`} replace />
  }

  

  return <CoursePageSingleCol 
    column={<>
      <h3 className="mt-4">You are not in a section yet!</h3>
       Go to the <Link to="/cip4/sectionswitch">Section Switch</Link> page to enroll in a section
      </>}
    />
}

import { Node } from "@tiptap/core"
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import { useEffect } from 'react';
import useState from 'react-usestateref';
import styled from "styled-components";
import Swal from "sweetalert2";

export const inputRegex = /https:\/\/www.youtube.com\/[a-z?=A-Z\-0-7&_]+/

export default Node.create({
	name: "iframe",
	group: "block",
	defaultOptions: {
		allowFullscreen: true,
		HTMLAttributes: {
			class: "iframe-wrapper"
		}
	},
	addAttributes() {
		return {
			src: {
				default: null,
			},
			title: {
				default: null,
			},
			frameborder: {
				default: 0,
			},
			allowfullscreen: {
				default: this.options.allowFullscreen,
				parseHTML: () => this.options.allowFullscreen,
			},
		}
	},
	parseHTML() {
		return [{
			tag: "iframe",
			getAttrs: dom => ({
				src: dom.getAttribute('src'),
				title: dom.getAttribute('title'),
			}),
		}]
	},
	renderHTML({ HTMLAttributes }) {
		return ["div", this.options.HTMLAttributes, ["iframe", HTMLAttributes]]
	},
	addCommands() {
		return {
			setIframe: (options) => ({ tr, dispatch }) => {
				const { selection } = tr
				const node = this.type.create(options)

				if (dispatch) {
					tr.replaceRangeWith(selection.from, selection.to, node)
				}

				return true
			},
		}
	},
	addNodeView() {
		return ReactNodeViewRenderer(iFrameView)
	},
})


const iFrameView = (props) => {
	// we should not need this. workaround beacuse "editable" is not reactive
	const [isEditable, setIsEditable, editableRef] = useState(props.editor.isEditable)
	useEffect(() => {
		props.editor.on('transaction', () => {
			if (props.editor.isEditable != editableRef.current) {
				setIsEditable(props.editor.isEditable)
			}
		})
	}, [])

	if (!isEditable) {
		return (
			<NodeViewWrapper>
				<iframe
					src={props.node.attrs.src}
					title={props.node.attrs.title}
					style={{ width: '100%', height: '100%' }}
				/>
			</NodeViewWrapper>
		)
	}

	const handleChangeAltText = async (event) => {
		/* iframes do not have an alt attribute, 
		instead the title attribute behaves as the alt text */
		const { value: newAltText } = await Swal.fire({
			title: "Change Alt Text",
			input: "text",
			inputValue: props.node.attrs.title ?? '',
			showCancelButton: true,
		})
		props.updateAttributes({
			title: newAltText,
		});
	}

	return (
		<NodeViewWrapper>
			<iframe
				src={props.node.attrs.src}
				title={props.node.attrs.title}
				style={{ width: '100%', height: '100%' }}
			/>
			<AltTextButton
				onClick={handleChangeAltText}
				className="btn btn-primary btn-sm my-1">
				Change Alt Text
			</AltTextButton>
		</NodeViewWrapper>
	);
};

const AltTextButton = styled.button`
`

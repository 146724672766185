import { useState } from "react";
import { RandomRegex } from "./RandomRegex";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";

export const EditRandomRegex = props => {

    const [dirtyBit, setDirtyBit] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [branches, setBranches] = useState(props.serverAssnData?.randomTestData?.branches || []);
    const courseId = useCourseId();

    const addBranch = () => {
        setBranches([...branches, {name: "New Branch", regex: "/Input Regex Here/", vars: [], pyodideInput: [], validation: ""}]);
        setDirtyBit(true);
    };
    
    const editBranch = (i, newBranch) => {
        
        setBranches(branches.map((reg, idx) => idx === i ? newBranch : reg));
        setDirtyBit(true);
    };

    const deleteBranch = (i) => {
        setBranches(branches.filter((reg, idx) => idx !== i));
        setDirtyBit(true);
    }

    const saveBranches = async() => {
        setIsSaving(true);
        console.log("Saving Branches!")
        console.log(branches);

        await saveRandomDataToDB(branches);

        setDirtyBit(false);
        setIsSaving(false);
        
    }

    const saveRandomDataToDB = async(branches, assnId) => {
        const db = getFirestore();
        const path = `assns/${courseId}/assignments/${assnId}/docs/randomTestData`

        const assnRef = doc(db, path);
        
        // !!! WRITE TO DB
        await setDoc(assnRef, {branches}, {merge: true});
    }

    const saveButtonTxt = () => {
        if(isSaving) return "Saving...";
        if(dirtyBit) return "Save";
        
        return "Saved";
    }

    return (
        <div>
            <h3>Output Regex Editor</h3>
            <p>These regexes will be used to extract the parts of the output that are based on randomness.</p>
            {
                branches.map((b, i) => (
                    <RandomRegex key={i} index={i} branch={b} {...props} addBranch={addBranch} editBranch={editBranch} deleteBranch={deleteBranch} />
                ))
            }
            <button className="btn btn-secondary my-2 mr-2" onClick={addBranch}>Add New Regex</button>
            <button
                disabled={!dirtyBit || isSaving}
                onClick={() => saveBranches()}
                style={{ width: 100 }}
                className="btn btn-primary"
                >{saveButtonTxt()}</button>
        </div>
    );
}
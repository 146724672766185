import React, { useEffect, useState } from 'react';
import CollapsiblePane from './CollapsiblePlane';
import WrittenResponseScores from './WrittenResponseScores';
import VideoFeedbackOptions from './VideoFeedbackOptions';
import OverallReadiness from './OverallReadiness';
import { Button, Form } from 'react-bootstrap';
import { getReviewDataFromDatabase, readInitialReactionEmoji, submitInitialReaction, useInitialReactionEmoji } from '../teacherPuzzles/TNFirebase';



const ReviewedFeedbackDisplay = ({ courseId, userId, submissionDocId, puzzleList, viewMode, setViewMode, formSubmitted }) => {
  const [reviewData, setReviewData] = useState({
    tone: '',
    content: '',
    overallReadiness: '',
    puzzleResponses: puzzleList.map(() => ({ noticing: '', responseToStudent: '' }))
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviewData = async () => {
      // Replace with the actual function to fetch review data from the database
      const data = await getReviewDataFromDatabase(courseId, userId, submissionDocId);
      setReviewData(data);
      setLoading(false);
    };

    fetchReviewData();
  }, [submissionDocId, courseId, userId, formSubmitted]);

  if (loading || !reviewData) {
    return <div>Loading...</div>;
  }

  console.log("Review data", reviewData)

  return (
    <div className="container mt-4 mb-4">
      <h3>Reviewed Feedback Display</h3>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <select onChange={(e) => setViewMode(e.target.value)} value={viewMode} className='form-select'>
          {puzzleList.map((_, index) => (
            <option key={index} value={index}>{`Puzzle ${index}`}</option>
          ))}
          <option value="video">Video</option>
          <option value="summary">Summary</option>
        </select>
      </div>
      {viewMode === 'video' && (
        <CollapsiblePane>
          <div>
            <h5>Video Feedback</h5>
            <p>🔊 Tone: {reviewData.tone}</p>
            <p>💬 Content: {reviewData.content}</p>
          </div>
        </CollapsiblePane>
      )}
      {viewMode !== 'video' && viewMode !== 'summary' && (
        <CollapsiblePane>
          <div>
            <h5>Puzzle {viewMode}</h5>
            <p>👁️ Noticing: {reviewData.puzzleResponses[parseInt(viewMode)].noticing}</p>
            <p>🗨️ Response: {reviewData.puzzleResponses[parseInt(viewMode)].responseToStudent}</p>
          </div>
        </CollapsiblePane>
      )}
      {viewMode === 'summary' && (
        <div>
          <h5>Overall Readiness</h5>
          <p>🎯 Readiness: {reviewData.overallReadiness}</p>
        </div>
      )}
    </div>
  );
};

const InitialReaction = ({ courseId, userId, handleInitialReaction }) => {
  const [emoji, setEmoji] = useState('');
  const [notes, setNotes] = useState('');
  
  const [initialReaction, reactionLoading, error] = useInitialReactionEmoji(courseId, userId);
  

  const handleIRSubmit = (e) => {
    console.log("Child is submitting initial reaction")
    e.preventDefault();
    handleInitialReaction(e, { emoji, notes });
  }

  console.log("Initial reaction", initialReaction)


  if (reactionLoading) {
    return <div>Loading...</div>;
  }

  // If the student already has an initial reaction, show it here
  if (initialReaction.emoji) {
    return (
      <div>
        <h4>Initial Reaction</h4>
        <p>Emoji: {initialReaction.emoji}</p>
        <p>Notes: {initialReaction.notes}</p>
      </div>
    );
  }

  return (
    <Form onSubmit={handleIRSubmit}>
      <Form.Group controlId="initialReactionEmoji">
        <Form.Label>Initial Reaction</Form.Label>
        <Form.Control as="select" value={emoji} onChange={e => setEmoji(e.target.value)}>
          <option value="">Select Reaction</option>
          <option value="🌟🌟">🌟🌟 </option>
          <option value="🌟">🌟</option>
          <option value="😐">😐</option>
          <option value="👎">👎</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="initialReactionNotes">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </Form.Group>
      <Button type="submit">Submit Initial Reaction</Button>
    </Form>
  );
};


const RightSide = ({ 
  submissionDocId, 
  handleSubmit, 
  puzzleList, 
  viewMode, 
  setViewMode, 
  handleInitialReaction , 
  courseId, 
  userId,
  isAlreadyReviewed
}) => {
  const [tone, setTone] = useState('');
  const [content, setContent] = useState('');
  const [overallReadiness, setOverallReadiness] = useState('');
  const [overallFeedback, setOverallFeedback] = useState('');
  const [puzzleResponses, setPuzzleResponses] = useState(
    puzzleList.map(() => ({ noticing: '', responseToStudent: '' }))
  );
  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitButtonDisabled = !tone || !content || !overallReadiness || puzzleResponses.some((response) => !response.noticing);

  const handleSelect = (eventKey) => {
    setViewMode(eventKey);
  };

  const handlePuzzleResponseChange = (index, field, value) => {
    const updatedResponses = puzzleResponses.map((response, i) => {
      if (i === index) {
        return { ...response, [field]: value };
      }
      return response;
    });
    setPuzzleResponses(updatedResponses);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e, { tone, content, overallReadiness, puzzleResponses });
    setViewMode('summary'); // or any valid puzzle index like '0'
    setFormSubmitted(true);
  };

  if (!submissionDocId) {
    return (
      <div className="container mt-4">
        <h3>Evaluation Pane</h3>
        <div>We do not have a recent submission for this student. This is concerning. Please let Juliette know.</div>
      </div>
    );
  }

  if (isAlreadyReviewed || formSubmitted) {
    return (
      <ReviewedFeedbackDisplay
        submissionDocId={submissionDocId}
        puzzleList={puzzleList}
        courseId={courseId}
        userId={userId}
        viewMode={viewMode}
        setViewMode={setViewMode}
        formSubmitted={formSubmitted}
      />
    )
  }

  return (
    <div className="container mt-4 mb-4">
      <h3>Evaluation Pane</h3>
      <div className="d-flex justify-content-end align-items-center mb-2">
        <select onChange={(e) => handleSelect(e.target.value)} value={viewMode} className='form-select'>
          {puzzleList.map((_, index) => (
            <option key={index} value={index}>{`Puzzle ${index}`}</option>
          ))}
          <option value="video">Video</option>
          <option value="submit">Submit</option>
        </select>
      </div>
      {viewMode === 'video' && (
        <>
          <form onSubmit={handleFormSubmit}>
            <CollapsiblePane>
              <VideoFeedbackOptions tone={tone} setTone={setTone} content={content} setContent={setContent} />
            </CollapsiblePane>
          </form>
          <CollapsiblePane>
            <InitialReaction courseId={courseId} userId={userId} handleInitialReaction={handleInitialReaction} />
          </CollapsiblePane>
        </>
      )}
      {viewMode !== 'video' && viewMode !== 'submit' && (
        <CollapsiblePane>
          <WrittenResponseScores
            noticing={puzzleResponses[parseInt(viewMode)].noticing}
            setNoticing={(value) => handlePuzzleResponseChange(parseInt(viewMode), 'noticing', value)}
            responseToStudent={puzzleResponses[parseInt(viewMode)].responseToStudent}
            setResponseToStudent={(value) => handlePuzzleResponseChange(parseInt(viewMode), 'responseToStudent', value)}
          />
        </CollapsiblePane>
      )}
      {viewMode === 'submit' && (
        <div>
          <h3>Feedback Summary</h3>
          {puzzleResponses.map((response, index) => (
            <div key={index}>
              <h5>Puzzle {index}</h5>
              <p>👁️: {response.noticing}</p>
              <p>💬: {response.responseToStudent}</p>
            </div>
          ))}
          {tone && content && (
            <div>
              <h5>Video Feedback</h5>
              <p>🔊 Tone: {tone}</p>
              <p>💬 Content: {content}</p>
            </div>
          )}
          <CollapsiblePane>
            <OverallReadiness
              overallReadiness={overallReadiness} 
              setOverallReadiness={setOverallReadiness} 
              feedbackForStudent={overallFeedback}
              setFeedbackForStudent={setOverallFeedback}
            />
          </CollapsiblePane>
          <Button onClick={handleFormSubmit} disabled={submitButtonDisabled}>
            Submit All Feedback
          </Button>
        </div>
      )}
    </div>
  );
};

export default RightSide;

import {useContext} from "react";
import { Formik, Form, useField, Field } from "formik";
import { Link } from "react-router-dom";


import {
  getSectionTimeStr,
  getLocalUTCTimezone,
} from "components/timezones/timezoneHelper";
import { ShowLocalTime } from "./ShowLocalTime";
import { useCourseId } from "hooks/router/useUrlParams";
import { CourseContext } from "contexts/CourseContext";


const OnboardForm = ({ sectionTimeIndex, onSubmit, handleSectionChange,availableTimes }) => {
  const courseId = useCourseId()
  let initValues = {};
  let initIndex = sectionTimeIndex
  initValues.sectionTimeIndex = initIndex;

  const { sectionTimeDelta } = useContext(CourseContext);

  const validationSchema = null

  const timeZone = getLocalUTCTimezone();
  const timeZoneLink = `https://dayspedia.com/time/zones/${timeZone.toLowerCase()}/`

  const signupsClosed = true
  const signupDescription = signupsClosed ? "Section signups are closed. Please use the manual signup form to get put into a section for next week " : ""
  const signupTitle = signupsClosed ? "Section Signups Closed" : "Section Signups"
  return (
    <div>
      <h5>Section times</h5>
      <p className="mb-5">
        You will attend a 50 minute section once a week for 6 weeks. Your first
        section will be the week of April 22nd, and your last section will be
        the week of May 27th. Sections are during the week between
        {" "}{getSectionTimeStr(timeZone, 0)} and {getSectionTimeStr(timeZone, sectionTimeDelta)}.
      </p>
      <Formik

        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, validateField, validateForm }) => {
          const userTz = getLocalUTCTimezone()
          return <Form style={{ width: "100%" }}>
            <ShowLocalTime />


            <SelectInput
              label={signupTitle}
              name="sectionTimeIndex"
              disabled={signupsClosed}
              description={signupDescription}
              onChange={handleSectionChange}
            >
              <option value="none">Please select a time</option>
              {availableTimes.map((i) => (
                <option value={i} key={i}>
                  {getSectionTimeStr(userTz, i)}
                </option>
              ))}
              {/* {Array.from(Array(46).keys()).map((i) => (
                <option value={i} key={i}>
                  {getSectionTimeStr(userTz, i)}
                </option>
              ))} */}
            </SelectInput>

            <p className="description">If you can not make any of the available times, please email codeinplace@stanford.edu. If you can't find a section time that works we <b>may not</b> be able to put you in a section.</p>

            <TranslatedTimes
              sectionTimeIndex={sectionTimeIndex}
            />

            <hr />







            <Link className="btn btn-primary" to={`/${courseId}/onboarding/student`}>
              Save
            </Link>
          </Form>
        }}
      </Formik>
    </div>
  );
}

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: "select" });

  return (
    <div className="question-spacing">
      <label className="form-label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="description">
        {props.description ? props.description : ""}
      </div>

      <Field as="select" className="select-input" {...field} {...props}>
        {props.children}
      </Field>
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};


// get the label for the ith option given user is in timeZoneStr (eg "UTC+4:00")

const TranslatedTimes = (props) => {

  let tz = getLocalUTCTimezone()
  let i = props.sectionTimeIndex;
  console.log(tz)
  let timeZones = [
    { city: "San Francisco", timeZone: "UTC-7:00" },
    { city: "New York", timeZone: "UTC-4:00" },
    { city: "London", timeZone: "UTC+1:00" },
    { city: "Istanbul", timeZone: "UTC+3:00" },
    { city: "Singapore", timeZone: "UTC+8:00" },
  ];
  const listItems = timeZones.map((value) => (
    <li key={value.city}>
      {value["city"]}: {getSectionTimeStr(value["timeZone"], i)}
    </li>
  ));
  return (
    <div className="card mb-5">
      <div className="card-body">
        <p>
          In case you are curious, here is the local time of your section in
          different cities:
        </p>
        <ul>
          {listItems}
          <li key="tz">Your timezone: {getSectionTimeStr(tz, i)}</li>
        </ul>
      </div>
    </div>
  );

}

export default OnboardForm;


import { getCornerSize, getCornerX, getCornerY, getKarelImg, KAREL_IMG_PCT } from "../util";

export const Agent = ({state, karelType="classic"})=>{
    let row = state.worldState.karelRow;
    let col = state.worldState.karelCol;
    let cornerSize = getCornerSize(state);
    let size = KAREL_IMG_PCT * getCornerSize(state);
    let offset = (cornerSize - size) / 2;
    let x = getCornerX(state,row, col) + offset;
    let y = getCornerY(state,row, col) + offset;
    return (
      <img
        className="karel"
        style={{ marginLeft: x, marginTop: y }}
        width={size}
        height={size}
        src={getKarelImg(state, karelType)}
        alt="Karel"
      ></img>
    );
}
import { FaUser } from "react-icons/fa";
import { transcriptFeedbackColors } from "../utils/colors";

const LearnerEngagement = ({
  engagedLearners,
  totalLearners,
  engagedLearnersLastSession = null,
  totalLearnersLastSession = null,
}) => {
  return (
    <div className="border rounded p-3 bg-light">
      <div>
        <LearnerVisualization
          engagedLearners={engagedLearners}
          totalLearners={totalLearners}
        />
      </div>
      <div>
        <p
          style={{ fontSize: "1.3em", fontWeight: "600", marginBottom: "5px" }}
        >
          {engagedLearners} of your {totalLearners} students engaged at least
          once.
        </p>
        {/* {engagedLearnersLastSession !== null && (
          <p
            style={{
              fontSize: "1em",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            Last session, {engagedLearnersLastSession} out of{" "}
            {totalLearnersLastSession} students engaged.
          </p>
        )} */}
      </div>
    </div>
  );
};

export default LearnerEngagement;

const LearnerVisualization = ({ engagedLearners, totalLearners }) => {
  return (
    <div className="flex">
      {Array.from({ length: engagedLearners }).map((_, i) => (
        <FaUser key={i} color={transcriptFeedbackColors.student} />
      ))}
      {Array.from({ length: totalLearners - engagedLearners }).map((_, i) => (
        <FaUser key={i} color="lightGrey" />
      ))}
    </div>
  );
};

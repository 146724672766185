import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button' 
import Alert from 'react-bootstrap/Alert';
import { 
    verifyPasswordResetCode, 
    confirmPasswordReset 
} from "firebase/auth";

export const ResetPasswordHandler = ( { auth, actionCode } ) => {
    const [ accountEmail, setAccountEmail ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ passwordResetSuccess, setPasswordResetSuccess ] = useState(false)

    useEffect(() => {
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            setAccountEmail(email)
        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            setErrorMessage('Invalid or expired action code! Please try to reset your password again.')
        });
    }, [])

    const onPasswordInputChange = (e) => {
        setNewPassword(e.target.value)
    }

    const submitResetPassword = (e) => {
        e.preventDefault()
        confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Password reset has been confirmed and new password updated.
            setPasswordResetSuccess(true)
          }).catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            setErrorMessage('Error occurred during confirmation. The code might have expired or the password is too weak.')
          });
    }

    if (errorMessage !== ''){
        return <Alert variant={'danger'}>{errorMessage}</Alert>
    }

    if (passwordResetSuccess) {
        return <Alert variant={'success'}> Success! Your password has been reset. </Alert>
    }

    return <>
    <Form>
    <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>New password for account with email {accountEmail}</Form.Label>
        <Form.Control type="password" placeholder="Password" value={newPassword} onChange={onPasswordInputChange}/>
      </Form.Group>
      <Button variant="primary" type="submit" onClick={submitResetPassword}>
        Submit
      </Button>
    </Form>
    </>
}
import { useState, useContext, useRef, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { MeetingsContext } from "course/contexts/MeetingsContext"
import { CoursePageBodyContainer } from "../../../components/layout/CoursePageBodyContainer"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom"
import { Stack } from "react-bootstrap"
import { ProfileContext } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import NoAccess from "components/errors/NoAccess"
import { PracticeProblemsTable } from "course/code/PracticeProblemsTable"
import { FaTrash } from "react-icons/fa"



export const EventsEditorSplash = () => {
  const { userData } = useContext(ProfileContext)
  const isAdmin = Gate.hasAdminRole(userData)
  if (!isAdmin) {
    return <NoAccess />
  }

  return <CoursePageBodyContainer
    mainColumn={<EventsEditorMain />}
    rightColumn={<></>}
    singleColumn={<EventsEditorMain />}
  />
}

const EventsEditorMain = () => {
  const { courseId } = useParams();
  const navigate = useNavigate()
  const { addMeetingToDB, deleteMeetingFromDB, meetingsMap } = useContext(MeetingsContext)
  const onSubmit = async (eventTitle, eventKey, zoomLink, recordingLink, length, startDate, startTime, minimumRole) => {
      const combinedString = `${startDate}T${startTime}:00`
      const date = new Date(combinedString)
      const localizedDate = date.toISOString()

      await addMeetingToDB(eventTitle, eventKey, zoomLink, recordingLink, length, localizedDate, minimumRole)
      const url = `/${courseId}/eventseditor/${eventKey}`
      navigate(url)
  }

  return <>
      <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">Create new event</div>
      <AddEventForm onSubmit={onSubmit} />
      <EventsTable meetingsMap={meetingsMap} deleteMeetingFromDB = {deleteMeetingFromDB} courseId={courseId}/>
  </>
}

export const AddEventForm = ({ onSubmit, isCreate = true }) => {
  const [eventTitle, setEventTitle] = useState('')
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [zoomLink, setZoomLink] = useState('')
  const [length, setLength] = useState('')
  const [eventKey, setEventKey] = useState('')
  const [recordingLink, setRecordingLink] = useState('')
  const [minimumRole, setMinimumRole] = useState('')

  return <>
    <Stack direction="vertical" gap={3}>
      <Form.Label>Event Information</Form.Label>
      {isCreate && <Form.Control
        className="me-auto"
        type="text"
        id="eventKey"
        value={eventKey}
        onChange={(e) => { setEventKey(e.target.value) }}
        placeholder="Enter the new Event Key"
      />}
      <Form.Control
        className="me-auto"
        type="text"
        id="eventTitle"
        value={eventTitle}
        onChange={(e) => { setEventTitle(e.target.value) }}
        placeholder="Enter the new Event Title"
      />
      <Form.Control
        className="me-auto"
        type="text"
        id="zoomLink"
        value={zoomLink}
        onChange={(e) => { setZoomLink(e.target.value) }}
        placeholder="Enter the new Event meeting link"
      />
      <Form.Control
        className="me-auto"
        type="text"
        id="recordingLink"
        value={recordingLink}
        onChange={(e) => { setRecordingLink(e.target.value) }}
        placeholder="Enter the new Event recording link"
      />
      <Form.Label>Event Timing</Form.Label>
      <Form.Control
        className="me-auto"
        type="date"
        id="startDate"
        value={startDate}
        onChange={(e) => { setStartDate(e.target.value) }}
        placeholder="Enter the new Event Start Date"
      />
      <Form.Control
        className="me-auto"
        type="time"
        id="startTime"
        value={startTime}
        onChange={(e) => { setStartTime(e.target.value) }}
        placeholder="Enter the new Event Start Time"
      />
      <Form.Control
        className="me-auto"
        type="number"
        id="length"
        value={length}
        onChange={(e) => { setLength(e.target.value) }}
        placeholder="Enter the new Event duration, in hours"
      />
      <Form.Label>Event Permissions</Form.Label>
      <Form.Select id="minimumRole" value={minimumRole} onChange={(e) => { setMinimumRole(e.target.value) }}>
        <option value="">Select a minimum role</option>
        <option value="student">Student</option>
        <option value="sl">Section Leader</option>
        <option value="admin">Admin</option>
      </Form.Select>
      <Button 
      className='mt-3' 
      onClick={(e) => { 
          onSubmit(eventTitle, eventKey, zoomLink, recordingLink, length, startDate, startTime, minimumRole)
      }} 
      disabled={eventTitle === ''|| isCreate && eventKey === ''|| zoomLink === '' || startDate === '' || startTime  === ''|| minimumRole  === ''}>Submit</Button>
    </Stack>
  </>
}

const EventsTable = ({meetingsMap, deleteMeetingFromDB, courseId}) => {
  const [tableItems, setTableItems] = useState([])
  const url = new URL(window.location.href)
  const subpath = "/" + url.pathname.slice(1)

  const columns = [
    {
      dataField: 'id',
      text: 'Event Key'
    },
    {
      dataField: 'title',
      text: 'Event Title'
    },
    {
      dataField: 'start',
      text: 'Start Time'
    },
    
    {
      dataField: 'actions',
      text: 'Actions'
    }
  ]

  useEffect(() => {
    const eventsList = []
    Object.keys(meetingsMap).map((id)=>{
      eventsList.push({
        title: <Link
            to={`/${courseId}/eventseditor/${id}`}
            state={{ from: subpath }}
        >
     {meetingsMap[id].title}</Link>,
        start: meetingsMap[id].start,
        id,
        actions: 
          <button className="btn" onClick={() => deleteMeetingFromDB(id)} aria-label="Delete">
            <FaTrash />
          </button>
      })
    })
    setTableItems(eventsList)
  }, [meetingsMap])

  return(
    <>
      <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">All Events</div>
      <PracticeProblemsTable practiceProblemData={tableItems} columns={columns} />
    </>
  )
}
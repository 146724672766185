import "firebase/compat/firestore";
import { useContext, useState } from "react";
import { FaCamera } from "react-icons/fa";
import Swal from "sweetalert2";

import { ProfileContext } from "contexts/ProfileContext";
import { useUserId } from "hooks/user/useUserId";
import { uploadPhoto } from "utils/uploadFile";
import { ProfilePicture } from "../ProfilePicture";

export const ProfilePicEditor = ({editable = true}) => {
  const userId = useUserId();
  const { userData, setUserData } = useContext(ProfileContext);

  const [isUploading, setIsUploading] = useState(false);
  let photoURL = "";
  if (userData?.photoURL) {
    photoURL = userData.photoURL;
  }

  const onUploadError = () => {
    setIsUploading(false);
  };

  const onProgress = (progress) => {
    setIsUploading(true);
  };

  const onUploadComplete = async (newUrl) => {
    await setUserData({ photoURL: newUrl });
    Swal.fire({
      title: `Success!`,
      toast: true,
      showClass: {
        popup: "none",
      },
      icon: "success",
      timer: 1000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
    setIsUploading(false);
  };

  const onProfilePicClicked = () => {
    if (!isUploading) {
      const storagePath = `profilePics/${userId}`;
      uploadPhoto(storagePath, onUploadComplete, onProgress, onUploadError);
    }
  };

  const height = 150 + 38;
  const photoOuterStyle = {
    position: "relative",
    width: "150px",
    borderRadius: "5px",
  };

  // make it cover and norepeat
  const photoStyle = {
    width: "150px",
    height: "150px",
    maxWidth: "150px",
    maxHeight: "150px",
    objectFit: "cover",
    borderRadius: "5px",
  };

  return (
    <div className="d-flex flex-column" style={photoOuterStyle}>
      {isUploading && (
        <div
          className="spinner-grow text-primary"
          style={{ position: "absolute" }}
        />
      )}
      {photoURL ? <img src={photoURL} style={photoStyle} /> : <ProfilePicture/>}
      
      {editable && <button onClick={onProfilePicClicked} className="btn btn-light">
        <FaCamera /> Change{" "}
      </button>}
    </div>
  );
};

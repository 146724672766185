import styled from "styled-components";


export const MobileTerminalShell = styled.div`
    background-color: #2d2e2c;
    position: relative;
    z-index: 1;
    color: #fff;
    max-width: 100%;
    text-wrap: wrap;
    height: 70vh;
    display: flex;
    flex-direction: column;
    font-family: "Fira Code", monospace;
`


export const TerminalShell = styled.div`
    background-color: #2d2e2c;
    position: relative;
    z-index: 1;
    color: #fff;
    max-width: 100%;
    text-wrap: wrap;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Fira Code", monospace;
`;

// should extend when user types further
// width should be 100% - the space filled by the pre tag
export const ConsoleInput = styled.input`
  background-color: #2d2e2c;
  color: white;
  border: gray 1px solid;
  border-radius: 5px;
  flex: 1;
  margin: 0;
  font-size: 1em;
  &:focus {
    outline: none;
  }
`;

export const TerminalPre = styled.pre`
  text-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  color: #fff;
  background-color: #2d2e2c;
  font-family: "Fira Code", monospace;
  max-width: 100%;
  max-height: 100%;
  line-height: 1em;
`;
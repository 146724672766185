import { useContext } from "react";
import styled from 'styled-components';
import { LessonContext } from "../LessonContext";

export const SplashCoverpage = ({ data }) => {
  const title = data.lectureName
  const authors = data.authors
  const course = data.course


  const { currSlideId, lessonData } = useContext(LessonContext);
  // const lessonData = lessonsMap[currSlideId]
  const lessonLength = computeLength(lessonData)
  console.log('lessonLength', lessonLength)
  return <SplashOuter>
    <ImageOuter>
      <BannerImg src={'/stanford.png'} alt="Stanford Logo"/>
    </ImageOuter>
    <BannerText>
      <h1>{title}</h1>
      <h2>{authors}</h2>
      <h2>{course}</h2>
    </BannerText>
    <p>Estimated Time: {lessonLength} minutes</p>
  </SplashOuter>
}

const ImageOuter = styled.div`
  width: 100%;
  height: auto; /* Adjust this to ensure the container fits content but does not exceed its max height */
  flex-grow: 1;
  flex-basis: 0; /* new */
  max-height: 350px;
  min-height: 50px; /* Ensure there's a minimum height */
  display: flex;
  align-items: center;
  justify-content: center;

`


const BannerImg = styled.img`
  max-width: 100%;
  max-height: 100%; /* This respects the container's max-height */
  object-fit: contain; /* Keeps aspect ratio without dictating container size */
`


const BannerText = styled.div`
  margin-top: 20px;
  border: 3px solid black;
  padding: 40px;
  min-width: 600px;
  margin-bottom: 40px;
  border-radius: 10px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-height: 800px) {
    padding: 20px;
  }
`


const SplashOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


function computeLength(data) {
  // for slide in data.slides
  let totalMins = 0
  for (var i = 0; i < data.slides.length; i++) {
    const slide = data.slides[i]
    const slideData = slide?.data
    const slideLength = slideData?.lengthMins
    if (slideLength) {
      // parse string
      totalMins += parseInt(slideLength)
    }
  }

  return totalMins
}

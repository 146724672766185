import { database } from "firebaseApp.js";
import { useCourseId } from "hooks/router/useUrlParams";
import { useEffect, useState } from "react";

import { useDebounce } from "use-debounce";


export const useNumOnline = () => {
    const [numPeopleOnline, setNumPeopleOnline] = useState(undefined)
    const courseId = useCourseId()

  useEffect(() => {
    const ref = database.ref(`/${courseId}/numOnline`);
    const listener = ref.on("value", function (snap) {
        // debounceNumOnline(snap.numChildren())
        if(!snap.exists()) {
          setNumPeopleOnline(0);
        } else {
          setNumPeopleOnline(snap.val());
        }
      });

    return () => {
      ref.off("value", listener);
    }

  }, [courseId]);

    return numPeopleOnline;
}

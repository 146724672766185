import { FaEye, FaEyeSlash } from "react-icons/fa"
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export const ViewFlaggedPostButton = (props: {
    onClick: () => any;
    isViewing: boolean;
    iconSize?: number;
}) => {
    const { onClick, isViewing, iconSize } = props;

    return (
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 400, hide: 0 }}
        overlay={(props) => (
          <Tooltip id="resolve-tooltip" {...props}>
            {isViewing ? "Hide Post" : "View Moderated Post" }
          </Tooltip>
        )}
      >
        { isViewing ?          <span>
          <FaEyeSlash
            onClick={onClick}
            size={iconSize ?? 32}
            className="markResolvedButton"
          />{" "}
        </span>:<span>
          <FaEye
            onClick={onClick}
            size={iconSize ?? 32}
            className="markResolvedButton"
          />{" "}
        </span>}
      </OverlayTrigger>
    )

}
import { useCourseId } from "hooks/router/useUrlParams";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { menuItemStyles, onlineMenuStyles } from "./menuItemStyles";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { useNumOnline } from "../../../firebase/realtime/NumOnline";
import { CourseContext } from "contexts/CourseContext";
import { isActive, getIcon, getTitle } from "./courseSidebarUtils"
import { HomeSubMenu, CodeSubMenu, ForumsSubMenu } from "./SubMenus";
import { isExperiencedStudent, isMentor } from "contexts/profile_util";


export const CourseSidebar = () => {
  const { broken } = useProSidebar();
  const { userData } = useContext(ProfileContext);
  const { courseFeatures, slFeatures } = useContext(CourseContext);
  const courseId = useCourseId();
  const isFoothill = courseId === "foothill-cs49" || courseId === "cs49-f24";

  const isSectionLeader = Gate.hasMentorRole(userData);
  const isExperienced = isExperiencedStudent(userData.courseRole);
  const isMentorRole = isMentor(userData.courseRole);
  const isAdmin = Gate.hasAdminRole(userData);
  let studentCourseFeatures = courseFeatures;
  let filteredSlFeatures = slFeatures
  // @TJ This is a silly way to remove section for experienced students
  // We need to remove this later
  if (isExperienced) {
    studentCourseFeatures = studentCourseFeatures.filter(item => item !== "section");
  }
  
  if(isMentorRole){
    filteredSlFeatures = slFeatures.filter(item => item !== "section");
  }

  const menu = isSectionLeader ? filteredSlFeatures : studentCourseFeatures;
  const isMinor = userData?.isMinor;



  const { collapsed, collapseSidebar } = useProSidebar();
  const numOnline = useNumOnline();

  let paddingTop = 10;
  let paddingX= broken ? 0 : 10;

  return (
    <div style={{ paddingTop: 10, paddingLeft:paddingX, paddingRight:paddingX}}>
      <Sidebar
        breakPoint="sm"
        backgroundColor="white"
        width="190px"
        collapsedWidth="70px"
        style={{
          borderRight: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Menu menuItemStyles={menuItemStyles}>
            {menu.map((item, index) => {
              if (item === "-") {
                return <Divider key={index} />;
              }
              if (item === "home") {
                if (isSectionLeader) {
                  return <HomeSubMenu
                    isAdmin={isAdmin}
                    isMentorRole={isMentorRole}
                    key={index}
                    courseId={courseId}
                    collapsed={collapsed} />
                }
                else {
                  return (
                    <CourseMenuItem
                      key={index}
                      courseId={courseId}
                      collapsed={collapsed}
                      subpage={"studenthome"}
                      icon={getIcon(item)}
                      title={getTitle(item)}
                    />
                  );
                }
                // else return home nav as usual
              }
              if (item === "forums") {
                return <ForumsSubMenu key={index} courseId={courseId} collapsed={collapsed} isSectionLeader={isSectionLeader} />
              }
              if (item === "connections" && isMinor) {
                return <></>
              }
              if (item === "code-pages") {
                return <CodeSubMenu
                  key={index}
                  courseId={courseId}
                  collapsed={collapsed}
                />
              }
              return (
                <CourseMenuItem
                  key={index}
                  courseId={courseId}
                  collapsed={collapsed}
                  subpage={item}
                  icon={getIcon(item)}
                  title={getTitle(item)}
                />
              );
            })}
          </Menu>
          {/* disable num online for foothill  */}
          {isFoothill ? <></> : <Menu menuItemStyles={onlineMenuStyles}>

            {/* <MenuItem
              onClick={()=>collapseSidebar(!collapsed)}
            >
              {collapsed ? <div style={{ textAlign: 'center' }}>
                <FaChevronRight/></div> : <span style={{ paddingLeft: '20px', paddingRight: '20px' }}><FaChevronLeft /> Collapse</span>}
            </MenuItem> */}
            <MenuItem>
              {collapsed ?
                <div style={{ textAlign: 'center' }}>
                  <FaUser /><span style={{}}>{numOnline}</span>
                </div> :
                <span style={{ paddingLeft: '20px', paddingRight: '20px' }}><FaUser /> {numOnline} online </span>
              }
            </MenuItem>
          </Menu>}
        </div>
      </Sidebar>
    </div>
  );
};

export const CourseMenuItem = ({ courseId, collapsed, title, icon, subpage, isLinkActive = true }) => {
  const active = isActive(subpage)

  return (
    <MenuItem
      component={isLinkActive ? <Link to={`/${courseId}/${subpage}`} /> : null}
      active={active}
      id={`nav-${subpage}`}
    >
      {icon}
      <span>{collapsed ? "" : <>&nbsp;&nbsp;{title}</>}</span>
    </MenuItem>
  );
};


export const SidebarFooter = ({ children, collapsed, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      (
      <div {...rest}>
        <div style={{ marginBottom: "12px" }}></div>
        <Typography fontWeight={600}>Pro Sidebar</Typography>
        <Typography
          variant="caption"
          style={{ letterSpacing: 1, opacity: 0.7 }}
        >
          V 23
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <button>
            <Typography variant="caption" color="#607489" fontWeight={600}>
              View code
            </Typography>
          </button>
        </div>
      </div>
      )
    </div>
  );
};

export const Divider = () => {
  return <hr style={{ margin: "10px" }} />;
};

const Typography = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};

import { firestore } from "firebaseApp";
import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const updateKarmaFirebase = httpsCallable(functions, "updateKarma");


// TODO: If we add more karma, update this with the event to eventName mapping
const updateEventNames = (karmaDocs) => {
  // For each document in karmaDocs, update the event name to be more human readable
  // If doc.event == 'peerGratitude', then doc.eventName = 'Peer Gratitude'
  const karmaDocsUpdated = karmaDocs.map((doc) => {
    if (doc.event == "peerGratitude") {
      doc.eventName = "Peer Gratitude";
    }
    return doc;
  });
}

export const markKarmaAsViewed = async (uid, courseId, karmaId) => {
  const ref = firestore
    .collection("karma")
    .doc(courseId)
    .collection("karmaByUser")
    .doc("users")
    .collection(uid)
    .doc(karmaId);

  await ref.update({
    hasBeenViewed: true,
  });
};

export const useGetTotalKarmaScore = (uid, courseId) => {
  const [totalKarmaScore, setTotalKarmaScore] = useState(0);

  useEffect(() => {
    const getTotalKarmaScore = async () => {
      const ref = firestore
        .collection("karma")
        .doc(courseId)
        .collection("karmaByUser")
        .doc("users")
        .collection(uid);

      const karmaDocs = await ref.get();
      setTotalKarmaScore(karmaDocs.docs.length);
    };

    // Call getTotalKarmaScore whenever the uid or courseId argument changes
    getTotalKarmaScore();
  }, [uid, courseId]);

  return totalKarmaScore;
};

export const useAllKarma = (uid, courseId, maxLen=null) => {
  const [allKarmaDocs, setAllKarmaDocs] = useState([]);

  useEffect(() => {
    const ref = firestore
      .collection("karma")
      .doc(courseId)
      .collection("karmaByUser")
      .doc("users")
      .collection(uid);

    const unsubscribe = ref
      // .where("hasBeenViewed", "==", false)
      .orderBy("timestamp", "desc")
      .onSnapshot((querySnapshot) => {
        const karmaDocsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return { ...data, id: doc.id };
        });

        setAllKarmaDocs(karmaDocsData);
      });

    return () => unsubscribe();
  }, [uid, courseId]);

  const numKarma = allKarmaDocs.length;


  if (maxLen === null) {
    return [allKarmaDocs, numKarma];
  } else {
    return [allKarmaDocs.slice(0, maxLen), numKarma];
  }
};


export const updateKarma = async (courseId, senderId, roomMembersDict, event) => {
    // Call firebase function to update karma
    console.log("Where are the missing perms!!")
    updateKarmaFirebase({
      courseId: courseId,
      senderId: senderId,
      roomMembersDict: roomMembersDict,
      event: event,
    }).then(() => {
      console.log("Updated Karma for users :)");
    });
};
import { ForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { Post, DraftPost, PostValidationError } from "course/forum/firebase/ForumDataModels";
import { getDoc, getFirestore, writeBatch } from "firebase/firestore";



export const validatePostUpdate = (postChange: Partial<DraftPost> | Partial<Post>, postText: string, validateContentsLength?: boolean) => {
    // Check title
    const errors: PostValidationError[] = [];

    if ("title" in postChange) {
      const { title } = postChange;
      if (title === "") {
        errors.push({
          error: "Invalid Title",
          detail: "Title must not be empty",
        });
      }
    }

    // Check post 
    if (validateContentsLength) {
      if (postText === "") {
        errors.push({
          error: "Invalid Post",
          detail: "Post should not be empty",
        });
      }
    }

    if (errors.length !== 0) {
      // throw errors;
      return false;
    }

  return true
}


export const getPostWidth = (forumType) => {
  switch (forumType) {
    case "Course":
      return "100%";
    case "Section":
      return "100%";
  }
};

export const getPostPaddingLeft = (forumType) => {
  switch (forumType) {
    case "Course":
      return "0px";
    case "Section":
      return "8px";
  }
};



export const postEntriesStyle = (offsetPixels) => {return {
  // height: `calc(100vh - ${offsetPixels}px)`,
  // maxHeight: `calc(100vh - ${offsetPixels}px)`,
  // flexGrow: 1,
  // flexBasis: 0,
  maxHeight: `calc(100vh - ${offsetPixels}px)`,
  overflow: "scroll",
}};



export const transferPostToDraft = async (postId: string, dataFetcher: ForumDataFetcher): Promise<DraftPost> => {
  const postDocRef = dataFetcher.get_post_doc_ref(postId);
  const tipTapDocRef = dataFetcher.get_tiptap_doc_ref(postId);
  const postDoc = await getDoc(postDocRef);
  const tipTapDoc = await getDoc(tipTapDocRef);
  if(!postDoc.exists() || !tipTapDoc.exists()) {
    return null;
  }
  try {
    const db = getFirestore();

    const postData = postDoc.data();
    const tipTapData = tipTapDoc.data();

    const draftPost: DraftPost = {
      author: postData.author || "Anonymous",
      authorUid: postData.authorUid,
      id: postData.id,
      title: postData.title,
      time: postData.time,
      contents: postData.contents,
      isPrivate: postData.isPrivate,
      tags: postData.tags,
      isResolved: postData.isResolved,
      isPinned: postData.isPinned,
      isFlagged: false,
      isPosted: true,
      isAnonymous: postData.isAnonymous
    }


    const draftDocRef = dataFetcher.get_draft_doc_ref(postId);
    const tipTapDraftDocRef = dataFetcher.get_draft_tiptap_doc_ref(postId);
    const batch = writeBatch(db)
    batch.set(draftDocRef, draftPost, {merge: true});
    batch.set(tipTapDraftDocRef, tipTapData, {merge: true});
    await batch.commit();
    return draftPost;
  } catch(e) {
    return null;
  }
  

}

import { useState, useEffect } from "react";
import { useCourseId } from "hooks/router/useUrlParams";
import { Link, useParams } from "react-router-dom";
import { FaFile, FaLink } from "react-icons/fa";

const currentWeekInfo = {
  weekName: "Week 1-2",
  themeName: "Getting Students to Talk",
  pointers: [
    "Ask a student to share their thinking.",
    "Ask a student why they took a particular approach.",
    "Ask a student how they figured out their answer.",
  ],
  example:
    "Can you explain how you approached this problem? Why is this problem difficult?",
};

const nextWeekInfo = {
  weekName: "Week 3-4",
  themeName: "Building on Ideas",
  pointers: [
    "Ask a student to clarify or elaborate on their response.",
    "Follow up on a student comment by pointing out its significance.",
    "Refer back to a student’s idea.",
  ],
  example: "What do you think would happen if...?",
};

const TalkMovesComponent = ({
  weekName,
  themeName,
  talkMoveDemonstrations,
  transcriptDownloadLink = null,
}) => {
  const weekKey = weekName.replace("Week ", "week");

  const { sectionId } = useParams();
  const courseId = useCourseId();

  const [infoOnShow, setInfoOnShow] = useState("currentWeek");
  const [weekInfo, setWeekInfo] = useState(currentWeekInfo);

  useEffect(() => {
    if (infoOnShow === "nextWeek") {
      setWeekInfo(nextWeekInfo);
    } else {
      setWeekInfo(currentWeekInfo);
    }
  }, [infoOnShow]);

  return (
    <div>
      <h1>Theme of the Week</h1>

      <div
        style={{ backgroundColor: "lavender" }}
        className="alert alert-primary"
      >
        <TalkMoveExplanationUnit
          week={weekInfo.weekName}
          title={
            <>
              The theme of the week is <b>{weekInfo.themeName}</b>
            </>
          }
          pointers={weekInfo.pointers}
          example={weekInfo.example}
          moduleLink={`/${courseId}/training`}
        />
        <div className="text-center">
          <button
            className="btn btn-info btn-sm"
            onClick={() =>
              setInfoOnShow(
                infoOnShow === "nextWeek" ? "currentWeek" : "nextWeek"
              )
            }
          >
            {infoOnShow === "nextWeek"
              ? "Show current week"
              : "What's coming up next week?"}
          </button>
        </div>
      </div>

      <p>
        Aside: This feedback is private to you, is for your own benefit, and
        will not be used for evaluating your performance as a Section Leader.{" "}
        <Link
          to={`/${courseId}/transcriptfeedback/${weekKey}/${sectionId}/transcript`}
          target="_blank"
        >
          <FaFile /> View your full transcript
        </Link>
        .
      </p>

      <div>
        <p>
          Here are some auto-selected highlights from your Zoom transcript when
          you did a great job <strong>{themeName}</strong>. 👏
        </p>
      </div>
      <div className="border rounded p-2 bg-light">
        <TalkMoveListing talkMoveDemonstrations={talkMoveDemonstrations} />
      </div>
      {transcriptDownloadLink && (
        <a href={transcriptDownloadLink} target="_blank">
          <small>Access transcript</small>
        </a>
      )}
    </div>
  );
};

export default TalkMovesComponent;

const TalkMoveListing = ({ talkMoveDemonstrations }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  if (!talkMoveDemonstrations) return null;
  if (!talkMoveDemonstrations[currentIndex]?.excerpts) return null;

  return (
    <div>
      {talkMoveDemonstrations[currentIndex].excerpts.map((item, index) => (
        <div key={index}>
          <p style={{ margin: 0, fontWeight: 600, color: "gray" }}>
            {item.speaker}
          </p>
          <p>{item.data}</p>
        </div>
      ))}
      <div style={{ display: "flex", gap: 5, justifyContent: "center" }}>
        <button
          className="btn btn-sm btn-primary"
          disabled={currentIndex === 0}
          onClick={() =>
            setCurrentIndex(
              currentIndex === 0
                ? talkMoveDemonstrations.length - 1
                : currentIndex - 1
            )
          }
        >
          Previous
        </button>
        <button
          className="btn btn-sm btn-primary"
          disabled={talkMoveDemonstrations.length === currentIndex + 1}
          onClick={() =>
            setCurrentIndex(
              currentIndex === talkMoveDemonstrations.length - 1
                ? 0
                : currentIndex + 1
            )
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

const TalkMoveExplanations = () => {
  const courseId = useCourseId();
  return (
    <div className="container">
      <div className="row">
        <TalkMoveExplanationUnit
          week={"Week 1-2"}
          title={"Getting Ideas on the Table"}
          pointers={[
            "Ask a student to share their thinking.",
            "As a student why they took a particular approach.",
            "Ask a student how they figured out their answer.",
          ]}
          example={"Can you explain how you approached this problem?"}
          moduleLink={`/${courseId}/training`}
        />
        <TalkMoveExplanationUnit
          week={"Week 3-4"}
          title={"Building on Ideas"}
          pointers={[
            "Ask a student to clarify or elaborate on their response.",
            "Follow up on a student comment by pointing out its significance.",
            "Refer back to a student’s idea.",
          ]}
          example={"What do you think would happen if...?"}
          moduleLink={`/${courseId}/training`}
        />
        <TalkMoveExplanationUnit
          week={"Week 5-6"}
          title={"Connecting Ideas"}
          pointers={[
            "Ask a student to restate another student’s idea.",
            "Ask students whether (and why!) they agree with another student’s solution.",
          ]}
          example={"Who has a different solution from Sarah?"}
          moduleLink={`/${courseId}/training`}
        />
      </div>
    </div>
  );
};

const TalkMoveExplanationUnit = ({
  week,
  title,
  pointers,
  example,
  moduleLink,
}) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "10px" }} className="col-sm">
      <h5 style={{ textAlign: "center" }}>{week}</h5>
      <div className="p-2  rounded">
        <h5 style={{ fontSize: "1.1em", fontWeight: "600" }}>{title}</h5>
        <ul>
          {pointers.map((pointer, index) => (
            <li key={index}>{pointer}</li>
          ))}
        </ul>
        <p
          style={{
            fontStyle: "italic",
            fontWeight: "700",
            marginBottom: "5px",
          }}
        >
          Example: {example}
        </p>
        <p style={{ margin: 0 }}>
          <Link to={moduleLink} target="_blank">
            <FaLink /> Link to Section Leader Training Module
          </Link>
        </p>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";

export const XTermTerminal = ({style={}}) => {



  return (
    <div
      className={`w-100 h-100`}
      style={{ backgroundColor: "#2D2E2C", ...style }}
      id="termshell"
    >
      <div id="terminal" className="w-100 h-100"></div>
    </div>
  );
};

import React, { Component } from "react";
// import { Router, Link } from "@reach/router"
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCourseId } from "hooks/router/useUrlParams";
import Navbar from "components/Navbar";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { LandingNav } from "landing/components/LandingNav";
import { OnboardingNav } from "onboarding/components/OnboardingNav";

export const ExpStudentJoinNav = () => {
  return <OnboardingNav onboardingKey={"student"} title={"Experienced Student Onboarding"} />
}

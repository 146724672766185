import { useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FaChevronLeft, FaTimes } from "react-icons/fa";
import styled from 'styled-components';
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import Confetti from 'react-confetti'
import { useWindowSize } from "react-use-size";
import { useIsMobile } from "utils/general";
import { HorizontalSpeechBubble } from "./lessontypes/SpeechBubbles";

export const DuoLesson = ({ 
    pages, 
    exitPath,
    completePath,
    prevPath,
    includeSignOut = false
  }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const pageData = pages[pageIndex];
  const speechText = pageData?.speechText;
  const progress = pageIndex / pages.length * 100;

  let canSkip = true;
  if (pageData?.canSkip === false) {
    canSkip = false;
  }

  const hideFooter = isMobile && !canSkip;
  const showFooter = !(hideFooter);

  const nextPage = () => {
    const nextIndex = pageIndex + 1;
    if (nextIndex >= pages.length) {
      navigate(completePath)
    } else {
      setPageIndex(pageIndex + 1);
    }
  }

  const prevPage = () => {
    setPageIndex(pageIndex - 1);
  }

  return <OuterBody>

    <InnerBody>
      <WelcomeProgress 
        now={progress} 
        prevPage={prevPage} 
        exitPath={exitPath}
        pageIndex={pageIndex} 
        prevPath={prevPath}
        includeSignOut={includeSignOut}
      />
      {speechText && <HorizontalSpeechBubble text={speechText} />}
      <Content
        pageIndex={pageIndex}
        pageData={pageData}
        nextPage={nextPage}
        prevPage={prevPage}
      />
    </InnerBody>
    {showFooter && <hr />}
    {showFooter &&
      <Footer>
        <ContinueButton disabled={!canSkip} nextPage={nextPage} />
      </Footer>
    }
  </OuterBody>
}

const Content = ({ pageIndex, pageData, prevPage, nextPage }) => {
  const [confettiIndex, setConfettiIndex] = useState(0);
  const windowSize = useWindowSize();
  if (!pageData) {
    return <>No content</>
  }


  const onComplete = () => {

    setConfettiIndex(confettiIndex + 1);
    setTimeout(() => {
      Swal.fire({
        title: 'Success!',
        icon: 'success',
        confirmButtonText: 'Next'
      }).then(() => {
        nextPage()
      })
    }, 700)


  }

  return <>
    <Confetti
      run={confettiIndex > 0}
      key={confettiIndex}
      width={windowSize.width}
      height={windowSize.height}
      recycle={false}
      numberOfPieces={500}

    />
    <CenteredContent>
      <pageData.component key={pageIndex} data={pageData.data} onComplete={onComplete} />
    </CenteredContent>
  </>
}


const WelcomeProgress = ({ now, prevPath, prevPage, exitPath, pageIndex, includeSignOut }) => {


  return <WelcomeProgressOuter>
    <ProgressButton 
      prevPage={prevPage} 
      exitPath={exitPath} 
      pageIndex={pageIndex} 
      prevPath={prevPath}
    />
    <ProgressBar now={now} className="flex-grow-1" />
  </WelcomeProgressOuter>
}

const ProgressButton = ({ prevPage, prevPath, exitPath, pageIndex }) => {
  const navigate = useNavigate();
  if (exitPath) {
    return <button
      aria-label="exit lesson"
      onClick={() => { navigate(exitPath) }}
      className="btn btn-light mr-1"
    ><FaTimes /></button>
  }

  const onClick = () => {
    if (pageIndex === 0) {
      navigate(prevPath)
    } else {
      prevPage()
    }
  }

  return <button
    aria-label="previous page"
    onClick={onClick}
    className="btn btn-light mr-1"
  ><FaChevronLeft /></button>
}


const ContinueButton = ({ disabled, nextPage }) => {
  return <Button onClick={nextPage} disabled={disabled} size="lg">Continue</Button>
};

const WelcomeProgressOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;


const OuterBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  
  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
  padding: 0px; /* Padding for mobile */
  }
`;

const InnerBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width:100%;
`;

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const Footer = styled.div`
  height:145px;
  width: 100%;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: end;
  flex-direction: column;
  max-width: 1200px;
  justify-content: center;

  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    height: 90px; /* Height for mobile */
    align-items: center; /* Aligns the buttons to the center */
  }
`;

import Editor, { useMonaco} from '@monaco-editor/react';
import {  areWorldsEqual } from 'components/pyodide/KarelLib/util';
import { PyodideClient, PyodideContext } from 'components/pyodide/PyodideProvider';
import { ResizableSplit } from 'components/split/ResizableSplit';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaPlay, FaStop, FaSyncAlt, FaUndo } from 'react-icons/fa';
import styled from 'styled-components';
import { useIsMobile } from 'utils/general';
import { useComponentSize } from "react-use-size";
import { Window } from './Windows';
import { CodeExampleContext, CodeExampleProvider } from './CodeExampleContext';


const runBtnColor = "#bb34fe"


export const EX_TYPES = {
  KAREL: "karelrunnable",
  CONSOLE: "consolerunnable",
  GRAPHICS: "graphicsrunnable",
}



export const RunnableLesson = ({
  onComplete,
  nextSlide,
  data }) => {

    return (
      <CodeExampleProvider>
        <RunnableLessonInner
          onComplete={onComplete}
          nextSlide={nextSlide}
          data={data}
        />
      </CodeExampleProvider>
    )
}


export const RunnableLessonInner = ({
  onComplete,
  nextSlide,
  data }) => {


  const { starterCode } = data
  const [dynamicStarterCode, setDynamicStarterCode] = useState(starterCode)
  const isMobile = useIsMobile()
  const [isRunning, setIsRunning] = useState(false)
  const pyodideClientRef = useRef(null);
  const dirtyBit = dynamicStarterCode !== starterCode
  const { 
    setIsStepping,
    stepPtr,
    stepList,
    setStepLine,
    setStepList,
    setStepPtr,
    setStepLogs
  } = useContext(CodeExampleContext)
  const onRunEnd = () => {
    setIsRunning(false)
  }
  const [preExec] = useParseData(data, pyodideClientRef, onRunEnd)



  useEffect(() => {
    if (stepList.length > 0) {
      const currentStep = stepList[stepPtr]
      const lineno = currentStep.lineno
      setStepLine(lineno)
    }

  }, [stepPtr])

  useEffect(() => {
    pyodideClientRef.current = new PyodideClient();
    pyodideClientRef.current.setHandlers(
      onRunEnd,
      (stdout) => console.log(stdout),
      (stdout) => console.log(stdout)
    )
  }, [])



  const resetCode = () => {
    setDynamicStarterCode(starterCode)
  }


  const runCode = async () => {
    setIsStepping(false)
    preExec()
    const activeFile = "main.py"
    if(data.type === EX_TYPES.KAREL) { 
      pyodideClientRef
    }
    setIsRunning(true)
    await pyodideClientRef.current.loadFile(activeFile, dynamicStarterCode)

    await pyodideClientRef.current.runCode(dynamicStarterCode, activeFile, true)
    const { list, logs } = pyodideClientRef.current.getStepInfo();
    if(list && list.length > 0) {
      setStepList(list)
      setStepPtr(list.length - 1)
      setStepLogs(logs)
    }
  }


  const stop = () => {
    pyodideClientRef.current.raisePyStopFlag()
  }


  const viewData = { isRunning, runCode, dynamicStarterCode, setDynamicStarterCode, stop, onComplete, type: data.type, resetCode,dirtyBit }

  if (isMobile) {
    return <MobileView {...viewData}  />
  }
  return <DesktopView {...viewData} />
}

const MobileView = ({
  isRunning, runCode, dynamicStarterCode, setDynamicStarterCode, stop,  onComplete, type, resetCode, dirtyBit
}) => {
  const { stepLine } = useContext(CodeExampleContext)

  // worlds are half the max size (to save space, which is a premium)
  return <MobileOuter>
    <Window
      type={type} 
    />
    <div className='d-flex'>
      <ButtonRow
        onComplete={onComplete}
        isRunning={isRunning}
        runCode={runCode}
        stopExecution={stop}
        type={type}
      />
    </div>
    <MobileEditorOuter>
      <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1
      }}>
        <ResetCodeButton resetCode={resetCode} dirtyBit={dirtyBit}/>
      </div>
      <LessonCodeEditor starterCode={dynamicStarterCode} setStarterCode={setDynamicStarterCode} editable={true} hightlightedLine={stepLine} />
    </MobileEditorOuter>
  </MobileOuter>
}

const DesktopView = ({
  isRunning, runCode, dynamicStarterCode, setDynamicStarterCode, stop, onComplete, type, resetCode, dirtyBit
}) => {


  const { stepPtr, stepList, stepLine, setStepPtr, setIsStepping } = useContext(CodeExampleContext)


  const outerSize = useComponentSize();
  return <DesktopOuter>
      <ButtonRow
        isRunning={isRunning}
        runCode={runCode}
        stopExecution={stop}
        onComplete={onComplete}
        type={type}
      />
    <ResizableOuter ref={outerSize.ref}>
      <ResizableSplit
        minimizedState={'standard'}
        setMinimizedState={() => { }}
        outerSize={outerSize}
        minSize={[500, 200]}
        defaultPercent={[60, 40]}
      >
        <div
          className="h-100"
          style={{ marginRight: "0px", backgroundColor: "white", width: 700 }}
        >
          <div className='d-flex justify-content-between'> 
            <h4>Example Code</h4>
            <ResetCodeButton resetCode={resetCode} dirtyBit={dirtyBit}/> 
          </div>
          <LessonCodeEditor starterCode={dynamicStarterCode} setStarterCode={setDynamicStarterCode} editable={true} hightlightedLine={stepLine}/>
        </div>
        <Window
          type={type} 
        />
          
      </ResizableSplit>
    </ResizableOuter>
    {/* <div className='d-flex mt-2 w-100 justify-content-center'>
      <ResourcesRow />
    </div> */}
  </DesktopOuter>
}

const ResizableOuter = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`


const LessonCodeEditor = ({ starterCode, setStarterCode, editable, hightlightedLine=-1 }) => {
  const [editorHeight, setEditorHeight] = useState('400px'); // Default height
  const [decorations, setDecorations] = useState([]);
  const isMobile = useIsMobile()

  const handleEditorDidMount = (editor, monaco) => {
    const lineCount = editor.getModel()?.getLineCount() || 1;
    const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
    const newHeight = lineCount * lineHeight;
    setEditorHeight(`${newHeight + 5}px`);
  };
  const editorRef = useMonaco();


  useEffect(() => {
    if (editorRef && hightlightedLine > 0) {
      const editors = editorRef.editor.getEditors()
      if(!editors || editors.length < 1) { return }
      const editor = editors[0]
      editor.removeDecorations(decorations)
      const newDecorations = [
        {
          range: new editorRef.Range(hightlightedLine, 1, hightlightedLine, 1),
          options: { isWholeLine: true, className: "line-highlight" }
        }
      ]
      const decs = editor.createDecorationsCollection(newDecorations);
      setDecorations((_) => decs._decorationIds);
    }
  }, [hightlightedLine, editorRef]);
  

  return <Editor
    className={`${isMobile ? "" : "mb-3"}`}
    width={"100%"}
    value={starterCode}
    onChange={(e) => { 
      if(editable) {
        setStarterCode(e)
      }
    }}
    defaultLanguage={"python"}
    ref={editorRef}
    
    options={{
      readOnly: !editable,
      fontSize: 14,
      padding: "0",
      scrollBeyondLastColomn: false,
      scrollBeyondLastLine: false,
      scrollbar: {
        vertical: 'auto',
        horizontal: 'hidden',
      },
      minimap: {
        enabled: false
      }
    }}

    onMount={handleEditorDidMount}
  />
}

const ButtonRow = ({ isRunning, runCode, stopExecution, onComplete, type }) => {
  const { worldState, startState, setWorldState, setStepPtr, stepPtr, stepList, setIsStepping } = useContext(CodeExampleContext)

  const startStopButtonClicked = () => {
    if (isRunning) {
      stopExecution()
    } else {
      if(type === EX_TYPES.KAREL) {
        if (areWorldsEqual(worldState, startState)) {
          runCode()
        } else {
          setStepPtr(0)
          setWorldState(startState)
        }
      } else {
        runCode()
      }
    }
  }

  return (<div className='d-flex justify-content-center align-items-center'>

    <StartStopButton
      isRunning={isRunning}
      startStopButtonClicked={startStopButtonClicked}
      defaultKarelWorldState={startState}
      karelWorldState={worldState}
      type={type}
    />
     <StepScroll
        stepPtr={stepPtr}
        setStepPtr={setStepPtr}
        stepMin={0}
        stepMax={stepList.length - 1}
        disabled={isRunning || !(stepList.length > 0)}
        setIsStepping={setIsStepping}
      />

    <button 
      className='btn btn-small btn-primary btn mr-1 ml-1 mb-2'
      onClick={onComplete}
    >
      Continue
    </button>
    </div>
  );
};


const StartStopButton = ({
  isRunning,
  startStopButtonClicked,
  defaultKarelWorldState,
  karelWorldState,
  type
}) => {
  const { isPyodideLoading } = useContext(PyodideContext)
  const atStarterWorld = areWorldsEqual(defaultKarelWorldState, karelWorldState)
  const startStopRef = createRef();
  let buttonText = "";
  let buttonIcon;
  if (type===EX_TYPES.KAREL) {
    buttonText = isRunning
      ? "Stop"
      : !atStarterWorld
        ? "Reset"
        : "Run";

    buttonIcon = isRunning ? (
      <FaStop />
    ) : !atStarterWorld ? (
      <FaSyncAlt />
    ) : (
      <FaPlay />
    );
  } else {
    buttonText = isRunning ? "Stop" : "Run";
    buttonIcon = isRunning ? <FaStop /> : <FaPlay />;
  }

  const isDisabled = isPyodideLoading

  if (isPyodideLoading) {
    buttonText = "Loading"
    buttonIcon = <></>
  }


  return (
    <div>
      <button
        id="start-stop-button"
        style={{
          width: 100,
          backgroundColor: runBtnColor,
          borderColor: runBtnColor,
        }} // keep a constant width as you change text
        className="btn btn-primary mr-2 mb-2"
        disabled={isDisabled}
        onClick={() => {
          startStopRef.current.blur()
          startStopButtonClicked()
        }}
        ref={startStopRef}
      >
        {buttonIcon} {buttonText}
      </button>
    </div>
  );
};


const StepScroll = ({
  stepPtr,
  setStepPtr,
  setIsStepping,
  stepMin,
  stepMax,
  disabled
}) => {

  const stepBack = () => {
    if(disabled) return;
    if (stepPtr > stepMin) {
      setIsStepping(true)
      setStepPtr(stepPtr - 1);
    }
  }

  const stepForward = () => {
    if(disabled) return;
    if (stepPtr < stepMax) {
      setIsStepping(true)
      setStepPtr(stepPtr + 1);
    }
  }


  const slide = (e) => {
    if(disabled) return;
    const stepIdx = e.nativeEvent.target.value;
    setIsStepping(true)
    setStepPtr(parseInt(stepIdx));
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        onClick={stepBack}
        style={{ marginTop: "2px", cursor: "pointer" }}
        className="d-flex justify-content-center align-items-center"
      >
        <FaArrowAltCircleLeft className={`${disabled ? "text-light" : "text-primary"}`} />
      </div>
      <input
        id="stepslider"
        type="range"
        min={stepMin}
        max={stepMax}
        value={stepPtr}
        onChange={slide}
        disabled={disabled}
      ></input>
      <div
        onClick={stepForward}
        style={{ marginTop: "2px", cursor: "pointer" }}
        className="d-flex justify-content-center align-items-center"
      >
        <FaArrowAltCircleRight className={`${disabled ? "text-light" : "text-primary"}`} />
      </div>
    </div>
  )

}


const useParseData = (data, pyodideClientRef, onRunEnd) => {
  const { setWorldState, startState, setStartState, karelSleepTime, terminalRef, graphicsState } = useContext(CodeExampleContext)


  const preExec = () => {
    if(!pyodideClientRef || !pyodideClientRef.current) { return }
    if(data.type === EX_TYPES.KAREL) {
      pyodideClientRef.current.setKarelInfo(startState, (state) => {
        setWorldState((_) => { return { ...state } })
      }, karelSleepTime)
    } else if (data.type === EX_TYPES.CONSOLE) {
      if(!pyodideClientRef || !pyodideClientRef.current) { return }
      if(!terminalRef || !terminalRef.current) { return }
      pyodideClientRef.current.setHandlers(
        () => {
          onRunEnd()
          terminalRef.current._prompt()
        },
        (stdout) => terminalRef.current.handleStdout(stdout),
        (stdout) => terminalRef.current.handleStderr(stdout)
      )
      terminalRef.current.writeAndScroll("python main.py")
    }
  }


  useEffect(() => {

    if(data.type === EX_TYPES.GRAPHICS && pyodideClientRef && pyodideClientRef.current) {
      pyodideClientRef.current.stepGraphics(graphicsState)
    }
  }, [graphicsState])


  useEffect(() => {
    if(data.type === EX_TYPES.KAREL) {
      setWorldState((_) => { return data.startState})
      setStartState((_) => { return data.startState})
    } else if (data.type === EX_TYPES.CONSOLE) {
      if(!pyodideClientRef || !pyodideClientRef.current) { return }
      pyodideClientRef.current.setHandlers(
        onRunEnd,
        (stdout) => console.log(stdout),
        (stdout) => console.log(stdout)
      )
    }
  }, [pyodideClientRef.current])


  useEffect(() => {
    if(!pyodideClientRef || !pyodideClientRef.current) { return }
    pyodideClientRef.current.setKarelSleepTime(karelSleepTime)
  }, [karelSleepTime])



  return [preExec];
}


const ResetCodeButton = ({ resetCode, dirtyBit }) => {

  if(!dirtyBit) {
    return <></>
  }

  return <button       
      onClick={resetCode}
      className="btn btn-light btn-sm ml-2"
    >
    <FaUndo />
    </button>

}


const CodeWorldSplit = styled.div`
  display: flex;
  flex-direction: row;
  width:'100%';
  background-color: white;
  overflow: hidden;
  `

const DesktopOuter = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
`

const MobileOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const MobileEditorOuter = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
`
import { useComponentSize } from "react-use-size";
import { TerminalToolbar } from "../TerminalPane";
import styled from "styled-components";
import { useContext, useEffect, useRef } from "react";
import { IDEContext } from "ide/contexts/IDEContext";
import { ConsoleInput, MobileTerminalShell, TerminalPre, TerminalShell } from "./TerminalStyles";

export const IDETerminalView = (props) => {
  const { stepSaveOn, stepMode, stepPtr, stepList, stepLogs, isTabletOrLarger } = useContext(IDEContext)
  const terminalRef = props.terminalRef

  
  useEffect(() => {
    if (stepMode && stepSaveOn) {
      if(terminalRef.current) {
        terminalRef.current.step(stepLogs.slice(0, stepList[stepPtr]["logptr"]))
      }
    }
  }, [stepPtr]);

    // let outerSize = useComponentSize();

    if(!isTabletOrLarger) {
      return <MobileTerminalShell>
            <TerminalToolbar {...props} />
            <TerminalPre id="terminal-pre" aria-label="Terminal Content">
              <span id="terminal"></span>
              {!stepMode && <ConsoleInput
                autoFocus
                type="text"
                id="terminal-input"
                name="input"
                autoComplete="off"
                aria-label="Terminal Input"
              />}
            </TerminalPre>
      </MobileTerminalShell>
    }
    return (
        <TerminalShell>
            <TerminalToolbar {...props} />
            <TerminalPre id="terminal-pre" aria-label="Terminal Content">
              <span id="terminal"></span>
              {!stepMode && <ConsoleInput
                autoFocus
                type="text"
                id="terminal-input"
                name="input"
                autoComplete="off"
                aria-label="Terminal Input"
              />}
            </TerminalPre>

        </TerminalShell>
    )
}

import { FaFlag } from "react-icons/fa"
import { OverlayTrigger, Tooltip } from "react-bootstrap";




export function FlagButton(props: {
    isFlagged: boolean;
    onClick: () => Promise<void>;
    iconSize?: number;
  }) {
    const { onClick, isFlagged, iconSize } = props;
    const redColor = "#dc3545";


    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 400, hide: 0 }}
        overlay={(props) => (
          <Tooltip id="resolve-tooltip" {...props}>
            {isFlagged ? "This post has been flagged. Click to unflag." : "Flag post"}
          </Tooltip>
        )}
      >
        <span>
          <FaFlag
            onClick={onClick}
            size={iconSize ?? 32}
            className="markResolvedButton"
            color={isFlagged ? redColor : "#bbbbbb"}
          />{" "}
        </span>
      </OverlayTrigger>
    );
  }
  
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useEffect } from "react"
import { useNavigate } from "react-router"

export const DiagnosticFinished = () => {
  const navigate = useNavigate()
  const courseId = useCourseId()
  const userId = useUserId()
  const goHome = () => {
    navigate(`/${courseId}`)
  }

  const db = getFirestore()

  useEffect(() => {
    const writeDiagnosticComplete = async () => {
      await setDoc(doc(db, `users/${userId}/${courseId}/selfCheckProgress`), {
        diagnosticComplete: true
      }, {merge: true})
    }
    writeDiagnosticComplete()
  }, [db, userId])

  
  return <div className="d-flex justify-content-center mt-4">
    <div style={{maxWidth:700, flexGrow:2}}>
      <h1>Diagnostic Complete!</h1>
      <hr/>
      <button onClick={goHome} className="btn btn-primary btn-lg mb-3">Back to the course</button>
    <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/diagnosticcomplete`}/>
    
    </div>
  </div>
}
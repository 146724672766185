import Editor from '@monaco-editor/react';
import { useEffect, useRef, useState } from 'react';



/*
          width="100%"
          minHeight="200px"
          fontSize="14px"
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          enableSnippets={false}
          highlightActiveLine={false}
          showGutter={true}
          wrapEnabled={true}
          maxLines={Infinity}
          spellCheck="false"
*/

export const MonacoTipTap = ({ value, onChange, readOnly, mode = "python" , style={}}) => {
  const [editorHeight, setEditorHeight] = useState('400px'); // Default height
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor, monaco) => {
      const lineCount = editor.getModel()?.getLineCount() || 1;
      const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
      const newHeight = lineCount * lineHeight;
      setEditorHeight(`${newHeight + 5}px`);

  };




  return <Editor 
    ref={editorRef}
    width={"100%"}
    height={readOnly ? editorHeight : "200px"}
    value={value}
    onChange={(e) => onChange(e)}
    defaultLanguage={mode}
    style={style}
    options={{
      readOnly: readOnly,
      fontSize: 14,
      padding: "0",
      scrollBeyondLastColomn: false,
      scrollBeyondLastLine: false,
      scrollbar: {
        vertical: 'hidden',
        horizontal: 'hidden',
        alwaysConsumeMouseWheel: false,
      },
      
      minimap: {
        enabled: false
      },
    }}
    
    onMount={handleEditorDidMount}
  />;
};

import React, { useCallback, useEffect, useState, useContext } from 'react';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { CourseContext } from 'contexts/CourseContext';
import _debounce from 'lodash/debounce'
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { InstantSearch, SearchBox, InfiniteHits, Configure, useHits } from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import {CoursePageBodyContainer} from "../../components/layout/CoursePageBodyContainer"
import { LocationInfo } from "../../components/countries/LocationInfo"
import { StoryPicEditor } from "./StoryPicEditor"
import { Role } from "types/role";

import {
    doc,
    setDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    Timestamp
  } from 'firebase/firestore';

import "./stories.css";
import { useCourseId } from '../../hooks/router/useUrlParams';
import { typesenseServerConfig } from 'config';
import { FaTimes } from 'react-icons/fa';

export const StoriesWithData = (props) => {
    const { 
        user, 
        db, 
        userPath, 
        userData, 
        storyPath, 
        storyData, 
        privateStoryDataPath, 
        privateStoryData,
        privacySettingsPath,
        privacySettingsData,
        logPath,
        // logData
    } = props
    const { storiesTypesenseIndex } = useContext(CourseContext)
    const [savedStories, setSavedStories] = useState(privateStoryData.myFavorites)
    const [featuredStories, setFeaturedStories] = useState(privateStoryData.myFeatured)
    const courseId = useCourseId()
    let serverStoryContent = storyData.content
    const [localStoryContent, setLocalStoryContent] = useState(serverStoryContent)
    const [isSaving, setIsSaving] = useState(false)
    const [dirtyBit, setDirtyBit] = useState(false)
    const saveBtnText = isSaving ? 'Publishing...' : 'Publish changes'

    const [radioValue, setRadioValue] = useState("all");
    let serverNameAnonChecked = privacySettingsData.displayNameAnon
    let serverCountryAnonChecked = privacySettingsData.countryAnon
    const [nameAnonChecked, setNameAnonChecked] = useState(serverNameAnonChecked); 
    const [countryAnonChecked, setCountryAnonChecked] = useState(serverCountryAnonChecked);

    let serverContactInfo = storyData.contactInfo ? storyData.contactInfo : ""
    const [localContactInfo, setLocalContactInfo] = useState(serverContactInfo)
    const MAX_STORY_LEN = 500

    const radios = [
        { name: 'All stories', value: 'all' },
        { name: 'My saved stories', value: 'saved' },
        { name: 'Edit my story', value: 'edit' },
    ];

    async function addSavedStoryIdFirestore(storyId){
        await updateDoc(doc(db, privateStoryDataPath), {
            myFavorites: arrayUnion(storyId)
        });
        // logging save
        await setDoc(doc(db, logPath, "save_clicks", nowTimestampStr()), {
            author_uid: storyId,
            save: true
        });
    }

    async function removeSavedStoryIdFirestore(storyId){
        await updateDoc(doc(db, privateStoryDataPath), {
            myFavorites: arrayRemove(storyId)
        });
        // logging unsave
        await setDoc(doc(db, logPath, "save_clicks", nowTimestampStr()), {
            author_uid: storyId,
            save: false 
        });
    }

    const handleSave = (e) => {
        const hitID = e.target.getAttribute("id")
        if (savedStories.includes(hitID)){
            // story saved, need to unsave (remove from saved stories list)
            setSavedStories(savedStories.filter(storyID => storyID !== hitID))
            removeSavedStoryIdFirestore(hitID)
            e.preventDefault() // prevent page reload
        } else {
            // story unsaved, need to save (add to saved stories list)
            setSavedStories([...savedStories, hitID])
            addSavedStoryIdFirestore(hitID)
            e.preventDefault()
        }
    }

    const truncateStory = (story) => {
        if (story.length <= MAX_STORY_LEN){
            return story
        }
        return story.slice(0,story.lastIndexOf(' ', MAX_STORY_LEN)).concat("...")
    }

    const nowTimestampStr = () => {return Timestamp.now().toMillis().toString()}

    async function saveUserStory() {
        setIsSaving(true)
        if (localStoryContent !== serverStoryContent){
            await updateDoc(doc(db, storyPath), {
                content: localStoryContent
              });
            // logging story change
            await setDoc(doc(db, logPath, "story_change", nowTimestampStr()), {
                new_content: localStoryContent
            });
            serverStoryContent = localStoryContent
        }
        if (nameAnonChecked != serverNameAnonChecked || countryAnonChecked != serverCountryAnonChecked){
            await updateDoc(doc(db, privacySettingsPath), {
                displayNameAnon: nameAnonChecked,
                countryAnon: countryAnonChecked
            });
            serverNameAnonChecked = nameAnonChecked
            serverCountryAnonChecked = countryAnonChecked
        }
        if (localContactInfo !== serverContactInfo){
            await updateDoc(doc(db, storyPath), {
                contactInfo: localContactInfo 
              });
            serverContactInfo = localContactInfo 
        }
        
        setIsSaving(false)
        setDirtyBit(false)
    }

    function confirmDeleteStory(){
        Swal.fire({
            title:"Are you sure you want to delete your story?",
            text: 'Others will not be able to see your story, name, photo, or country on the Stories page, and your previous story will not show up on the "Edit story" page. If you want to save your story for any reason, please store it somewhere for your own records before deleting your story.',
            confirmButtonText: 'Confirm delete',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            icon:"warning",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                // delete story -- clear story field in firebase
                setLocalStoryContent("")
                serverStoryContent = localStoryContent
                await updateDoc(doc(db, storyPath), {
                    content: ""
                  });
                // logging story change
                await setDoc(doc(db, logPath, "story_change", nowTimestampStr()), {
                    new_content: ""
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `Your story was deleted!`,
          })
        }
      })
    }

    async function logReadMore(author_uid){
        await setDoc(doc(db, logPath, "read_more_clicks", nowTimestampStr()), {
            author_uid
        });
    }

    async function logSearches(search_terms, searching_saved_stories){
        if (!!search_terms)
        await setDoc(doc(db, logPath, "searches", nowTimestampStr()), {
            search_terms,
            searching_saved_stories
        });
    }

    const debouncedLogSearches = useCallback(_debounce(logSearches, 1000), [])

    async function logContactInfo(author_uid){
        await setDoc(doc(db, logPath, "contact_info_clicks", nowTimestampStr()), {
            author_uid
        });
    }

    useEffect(() => {
        const same = (localStoryContent == serverStoryContent && 
            nameAnonChecked == serverNameAnonChecked &&
            countryAnonChecked == serverCountryAnonChecked) &&
            localContactInfo == serverContactInfo
        setDirtyBit(!same)
    }, [localStoryContent, nameAnonChecked, countryAnonChecked, localContactInfo])

    const [device_type, setDeviceType] = useState('desktop')
    // cutoff sizes in pixels
    const MOBILE_MAX_WIDTH = 600;
    const TABLET_MAX_WIDTH = 915;
    function handleWindowSizeChange() {
        let width = window.innerWidth;
          if(width > TABLET_MAX_WIDTH) {
            setDeviceType('desktop')
          } else if(width > MOBILE_MAX_WIDTH) {
            setDeviceType('tablet')
          } else if(width < MOBILE_MAX_WIDTH){
            setDeviceType('mobile')
          }
    }

    async function logPageView(){
        await setDoc(doc(db, logPath, "page_visits", nowTimestampStr()), {
            device_type
        });
    }

    async function logTabView(tab){
        await setDoc(doc(db, logPath, "tab_visits", nowTimestampStr()), {
            tab
        });
    } 

    const handleTabSwitch = (e) => {
        logTabView(e.target.value)
        setRadioValue(e.target.value)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            logPageView()
            logTabView("all")
        }
    }, []);

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: typesenseServerConfig, 
        additionalSearchParameters: {
            queryBy: "story",
            filterBy: "storyPresent:true",
            sortBy: "randomSortIdx:asc"
        },
    });
    const searchClient = typesenseInstantsearchAdapter.searchClient;

    const explanatoryAlertProps = {
        courseId,
        userData,
        localStoryContent
    }

    const storiesRightColumnProps = {
        radios,
        radioValue,
        handleTabSwitch,
        explanatoryAlertProps,
    }

    const storyCardProps = {
        courseId,
        savedStories,
        handleSave,
        truncateStory,
        MAX_STORY_LEN,
        logReadMore,
        logContactInfo
    }

    const allStoriesViewProps = {
        storyCardProps,
        debouncedLogSearches
    }

    const savedStoriesViewProps = {
        savedStories,
        storyCardProps,
        debouncedLogSearches
    }

    const editStoryFieldProps = {
        userData,
        localStoryContent,
        setLocalStoryContent
    }

    const editStoryAlertProps = {
        courseId,
        userData,
        localStoryContent
    }

    const storyPhotoEditorProps = {
        user,
        db,
        storyData,
        storyPath
    }

    const editStoryViewProps = {
        userData,
        dirtyBit,
        nameAnonChecked,
        setNameAnonChecked,
        countryAnonChecked,
        setCountryAnonChecked,
        localContactInfo,
        setLocalContactInfo,
        saveBtnText,
        confirmDeleteStory,
        saveUserStory,
        editStoryFieldProps,
        editStoryAlertProps,
        storyPhotoEditorProps
    }

    const selectedViewProps = {
        radioValue,
        allStoriesViewProps,
        savedStoriesViewProps,
        editStoryViewProps
    }

    return (
    <InstantSearch
        indexName={storiesTypesenseIndex}
        searchClient={searchClient}
    >
    <CoursePageBodyContainer
        mainColumn={ <SelectedView {...selectedViewProps}/> }
        rightColumn={<StoriesRightColumn {...storiesRightColumnProps}/>}
        singleColumn={<SingleColumnView selectedViewProps={selectedViewProps} storiesRightColumnProps={storiesRightColumnProps} />}
    />
    </InstantSearch>
    );
};

const TutorialModal = () => {
    var tutorialSeen = localStorage.getItem("tutorialSeen");
    if(!tutorialSeen) {
        // first time loaded!
        localStorage.setItem("tutorialSeen","1");
    }

    const [show, setShow] = useState(!!!parseInt(tutorialSeen));
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="link" onClick={handleShow}>
          What is this page?
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Welcome to Code in Place Stories!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Code in Place is a community of people from around the world who have come together to teach and learn! On this page, you can read about the journeys of your fellow students and SLs through CS. Hopefully you will find some of these inspiring! You can edit your own story on this page for others to read, and you can save stories you like by clicking on "Save" beneath each story.</Modal.Body>
        </Modal>
      </>
    );
  }

const StoriesRightColumn = ({radios, radioValue, handleTabSwitch, explanatoryAlertProps}) => {
    return <div className="stories-nav">
        {radios.map((radio, idx) => (
                <ToggleButton
                    className='margin-top'
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-primary'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={handleTabSwitch}
                >
                    {radio.name}
                </ToggleButton>
                ))}
        {/* <SearchBox /> */}
            <TutorialModal />
            {/* <ExplanatoryAlert {... explanatoryAlertProps}/> */}
    </div>
}

const BottomNav = ({radios, radioValue, handleTabSwitch, explanatoryAlertProps}) => {
    return <div className='bottom-nav'>
        {radios.map((radio, idx) => (
                <ToggleButton
                    className='margin-top'
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={radioValue === radio.value ? 'primary' : 'light'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={handleTabSwitch}
                >
                    {radio.name}
                </ToggleButton>
                ))}
    </div>   
}

const SingleColumnView = ({selectedViewProps, storiesRightColumnProps}) => {
    return <>
        <SelectedView {...selectedViewProps}/>
        <BottomNav {...storiesRightColumnProps}/>
    </>
}

const SelectedView = ({radioValue, allStoriesViewProps, savedStoriesViewProps, editStoryViewProps}) => {
    if (radioValue == 'all'){
        // all stories
        return <AllStoriesView {...allStoriesViewProps}/>
    } else if (radioValue == 'saved'){
        // saved stories
        return <SavedStoriesView {...savedStoriesViewProps}/>
    } else {
        // radioValue == 'edit' -- edit my story
        return <EditStoryView {...editStoryViewProps}/>
    }
}

const AllStoriesView = ({storyCardProps, debouncedLogSearches}) => {
        const queryHook = (query, search) => {
            debouncedLogSearches(query, false)
            search(query);
        };

        return <div className="position-relative">
        <SearchBox
            queryHook={queryHook}
            submitIconComponent={({ classNames }) => (
                <></>
            )}
            resetIconComponent={({ classNames }) => (
                <FaTimes/>
            )}
            classNames={{
            root: 'p-3 shadow-sm mt-3',
            form: 'relative',
            input: 'searchbox-input block w-full pl-9 pr-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md focus:ring-1',
            // submit: 'btn btn-primary searchbox-btn',
            // reset: 'btn btn-primary searchbox-btn',
            }}
        />
        <InfiniteHits
        hitComponent={({hit}) => <StoryCard hit={hit} {...storyCardProps}/>}
        showPrevious={false}
        classNames={{
            list: 'story-list',
            root: 'story-list-root',
            item: 'story-list-item',
          }}
        />
        </div>
}

const SavedStoriesView = ({savedStories, storyCardProps, debouncedLogSearches}) => {
        const queryHook = (query, search) => {
            debouncedLogSearches(query, true)
            search(query);
        };

        return <div>
            <SearchBox
                queryHook={queryHook}
                submitIconComponent={({ classNames }) => (
                    <></>
                )}
                resetIconComponent={({ classNames }) => (
                    <FontAwesomeIcon icon={faTimes} />
                )}
                classNames={{
                root: 'p-3 shadow-sm mt-3',
                form: 'relative',
                input: 'searchbox-input block w-full pl-9 pr-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md focus:ring-1',
                // submit: 'btn btn-primary searchbox-btn',
                // reset: 'btn btn-primary searchbox-btn',
                }}
            />
            <Configure
            filters={`_id:[${savedStories}]`}
            />
            <InfiniteHits
            hitComponent={({hit}) => <StoryCard hit={hit} {...storyCardProps}/>}
            showPrevious={false}
            classNames={{
                list: 'story-list',
                root: 'story-list-root',
                item: 'story-list-item',
                loadMore: 'btn btn-primary',
                disabledLoadMore: 'btn btn-primary',
            }}
            />
        </div>
}

const ReadMoreButton = ({id, story, MAX_STORY_LEN, expanded, setExpanded, logReadMore}) => {    
    const toggleExpand = (e) =>{
        if (!expanded){
            // "read more" clicked
            logReadMore(e.target.getAttribute("id"))
        }
        setExpanded(!expanded)
    }

    if (story.length <= MAX_STORY_LEN){
        // no expanding necessary; story is short
        return <></>
    }
    return <Card.Link id={id} onClick={toggleExpand}>{expanded ? "Show less" : "Read More"}</Card.Link>
}

const StoryCard = ({hit, courseId, savedStories, handleSave, truncateStory, MAX_STORY_LEN, logReadMore, logContactInfo}) => {
    if (!(hit.story.trim())){
        // don't show blank stories
        return <></>
    }

    const [expanded, setExpanded] = useState(false)
    const [contactInfoShowing, setContactInfoShowing] = useState(false)
    const handleContactInfoClick = () => {
        if (!contactInfoShowing){
            logContactInfo(hit.id) // only log contact info click when contact info is revealed, not when it is closed
        }
        setContactInfoShowing(!contactInfoShowing)
    }

    const popover = (
        <Popover id="popover-basic">
          {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
          <Popover.Body>
            {!!hit.contactInfo ? hit.contactInfo : "This user has not provided any contact info."}
          </Popover.Body>
        </Popover>
      );
    
    const roleString = (role) => {
        if (role === 'instructor'){
            return 'Instructor'
        }
        if (role === 'admin'){
            return 'Course Admin'
        }
        if (role === 'ta'){
            return 'Head TA'
        }
        if (role === 'sl'){
            return 'Section Leader'
        }
        if (role === 'student'){
            return 'Student'
        }
        return 'Unregistered'
    }

    return (
        <Card className="storycard">
            {/* this is hacky but in order to avoid having to back-update peoples' photo urls, if they have the default i am going to not show it */}
            {hit.photoUrl == "https://firebasestorage.googleapis.com/v0/b/codingplace2023.appspot.com/o/placeholder-image.png?alt=media&token=8e03aa2b-0de3-4e0c-b304-5c9ecdd7a40b" ? <></> : <Card.Img variant="top" src={hit.photoUrl} className="storycard-img-top"/>}
            <Card.Body>
            <div className="storycard-content-container">
                <div>
                    <Card.Title>
                        {hit.displayNameAnon ? "Anonymous" : hit.displayName}
                        &nbsp;
                        {hit.countryAnon ? <></> : <LocationInfo country={hit.country}/>}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{roleString(hit.role)}</Card.Subtitle>
                    <Card.Text>{expanded ? hit.story : truncateStory(hit.story)}</Card.Text>
                </div>
                <div>
                    {/* {hit.story.length <= MAX_STORY_LEN ? <></> : <Card.Link href={`/${courseId}/user/${hit.id}`} target="_blank">Read More</Card.Link>} */}
                    <ReadMoreButton 
                        id={hit.id} 
                        story={hit.story} 
                        MAX_STORY_LEN={MAX_STORY_LEN} 
                        expanded={expanded} 
                        setExpanded={setExpanded} 
                        logReadMore={logReadMore}
                    />
                    <Card.Link id={hit.id} onClick={handleSave}>{savedStories.includes(hit.id) ? "Unsave" : "Save"}</Card.Link>
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Card.Link onClick={handleContactInfoClick}>Contact info</Card.Link>
                    </OverlayTrigger>
                </div>
            </div>
            </Card.Body>
        </Card>
    )
}

const EditStoryView = ({
    userData, 
    dirtyBit, 
    nameAnonChecked, 
    setNameAnonChecked, 
    countryAnonChecked, 
    setCountryAnonChecked, 
    localContactInfo,
    setLocalContactInfo,
    saveBtnText, 
    confirmDeleteStory,
    saveUserStory, 
    editStoryFieldProps, 
    editStoryAlertProps, 
    storyPhotoEditorProps}) => {
    
    const onContactInfoChange = (e) =>{
        setLocalContactInfo(e.target.value)
    }
    return <Card className="edit-story-card mt-3">
           {/* <img className="profileEditImg" src={userData.photoURL}></img> */}
           <StoryPicEditor {...storyPhotoEditorProps}/>
           <Card.Body className="edit-story-card-body">
           <div className="storycard-content-container">
                <div>
                    <Card.Title as="h2">
                        {nameAnonChecked ? "Anonymous" : userData.displayName}
                        &nbsp;
                        {countryAnonChecked ? <></> : <LocationInfo country={userData.country}/>}
                    </Card.Title>
                </div>
           <Form.Group>
            <Form.Label>Privacy</Form.Label>
            <Form.Check type={"checkbox"}>
                <Form.Check.Input
                type={"checkbox"}
                defaultChecked={nameAnonChecked}
                onClick={(e) => {
                    setNameAnonChecked(e.target.checked);
                }}
                />
                <Form.Check.Label><Form.Text>Don't show my name to others</Form.Text></Form.Check.Label>
            </Form.Check>
            <Form.Check type={"checkbox"}>
                <Form.Check.Input
                type={"checkbox"}
                defaultChecked={countryAnonChecked}
                onClick={(e) => {
                    setCountryAnonChecked(e.target.checked);
                }}
                />
                <Form.Check.Label><Form.Text>Don't show my country to others</Form.Text></Form.Check.Label>
            </Form.Check>
            </Form.Group>
           {/* <EditStoryAlert {...editStoryAlertProps}/> */}
           {/* <p className="w-75">{storyData.content}</p> */}
           <EditStoryField {...editStoryFieldProps}/>
           <Form.Group>
            <Form.Label>Contact info</Form.Label>
            <Form.Text><p>
                <b>Optionally</b> you can provide your contact information so that if anyone feels inspired by your story and wants to connect with you, they can! We think most Code in Place participants are well-meaning, but we cannot protect you against unwanted contact. In order to protect your privacy <em>we strongly recommend setting up an email alias and providing that alias here instead of your actual email.</em> If you’re having trouble figuring out how to do so, please reach out to course staff letting us know your email provider and we can help you find instructions!
                </p>
            </Form.Text>
            <Form.Control type="text" value={localContactInfo} onChange={(e) => onContactInfoChange(e)}/>
           </Form.Group>
            </div>
            </Card.Body>
            <Card.Footer className='edit-story-footer'>
            <button onClick={()=>confirmDeleteStory()} className="btn btn-secondary">Delete story</button>
            <button onClick={()=>saveUserStory()}disabled={!dirtyBit} className="btn btn-primary">{saveBtnText}</button>
            </Card.Footer>
        </Card>
}

const EditStoryField = ({userData, localStoryContent, setLocalStoryContent, disabled=false}) => {

    const onChange = (e) => {
        const newStory = e.target.value
        setLocalStoryContent(newStory)
    }

    let formText
    if (userData.courseRole == Role.STUDENT){
        formText = <>
        <p>Tell your story about why you’re taking Code in Place and what you hope to do with your new CS skills! 🌱 </p>
        <p>Please note that the story you write is publicly visible to all Code in Place participants, alongside your display name (if you choose not to be anonymous)!</p>
        </>
    }
    else{
        // SL, admin, instructor
        formText = <>
        <p>Tell your story about how you became interested in computer science and your journey learning to code! How did you get to where you are today? We believe your story will inspire students who are just starting their own coding journeys! 🌱 </p>
        <p>Please note that the story you write is publicly visible to all Code in Place participants, alongside your display name (if you choose not to be anonymous)!</p>
        </>
    }

    // return <div className="form-group row">
    return <Form.Group>
        <Form.Label>My story</Form.Label>
        <Form.Text>{formText}</Form.Text>
        <Form.Control as="textarea" rows={8} className="edit-story-field form-control" disabled={disabled} onChange={(e) => onChange(e)} value={localStoryContent}/>
    </Form.Group>
        {/* <textarea className="edit-story-field form-control profile-editor-field-input" disabled={disabled} onChange={(e) => onChange(e)} value={localStoryContent}/> */}
    // </div>
}

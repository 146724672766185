import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { DiagnosticNav } from "./DiagnosticNav"
import { useCourseId } from "hooks/router/useUrlParams"
import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext"

export const DiagnosticIntro = () => {
  const courseId = useCourseId()
  const { loading: userIsLoading, userData } = useContext(ProfileContext)

  if (userIsLoading) {
    return <></>
  }
  return <div className="vh-100 vw-100 d-flex flex-column">
    <DiagnosticNav />
    <div className="vw-100 d-flex justify-content-center">
      <div style={{ marginTop: 100, maxWidth: 700 }}>
        <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/diagnosticintro`} />
      </div>
    </div>
  </div >
}
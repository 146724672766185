import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form'
import { SectionButton } from "../../components/reusableButtons/SectionButton"
import { CopyButton } from "components/reusableButtons/CopyButton";
import { LocationInfo } from "components/countries/LocationInfo";
import { FaCopy } from "react-icons/fa"
import { getSectionTimeStr, getLocalUTCTimezone } from 'components/timezones/timezoneHelper';
import { DisbandSectionButton } from './DisbandSectionButton';
import { EditSectionMembersTable } from './EditSectionMembersTable'
import { AddMemberField } from './AddMemberField';

export const ManageSectionForm = ({sectionId, courseId, sectionLeaderId, sectionData, slData, membersData}) => {
    const userTz = getLocalUTCTimezone()
    
    
    return <>
    <Form>
        <Form.Group>
            <Form.Label>Section information</Form.Label>
            <Stack direction="horizontal" gap={3}>
                <SectionButton name={sectionData.name} sectionId={sectionId} large={true}/>
                <CopyButton toCopy={sectionId} icon={<><FaCopy/> Section ID</>}/>
                <Form.Text>{getSectionTimeStr(userTz, sectionData.timeIndex)}</Form.Text>
            </Stack>

        </Form.Group>
        <Form.Group>
            <Form.Label>SL information</Form.Label>
            <Stack direction="horizontal" gap={3}>
                <>{slData.displayName} <LocationInfo country={slData.country}/></>
                <CopyButton toCopy={sectionLeaderId} icon={<><FaCopy/> SL ID</>}/>
                <Form.Text>{slData.email}</Form.Text>
            </Stack>
        </Form.Group>

        <Form.Group>
            <Form.Label>Members</Form.Label>
            <AddMemberField sectionId={sectionId} courseId={courseId}/>
            <EditSectionMembersTable membersData={membersData} />
        </Form.Group>

        <Form.Group>
            <Form.Label>Disband section</Form.Label>
            <Form.Text>This will distribute all the students into sections at the same time and then mark the section as inactive.</Form.Text>
            <br />
            <DisbandSectionButton className='mt-2' sectionId={sectionId} courseId={courseId}/>
        </Form.Group>

    </Form>
        
    </>
}
import { TextAreaInput } from "components/Forms/FormixComponents"
import { FormixFormWithData } from "components/Forms/FormixFormWithData";
import { useCourseId } from "hooks/router/useUrlParams";
import { ResponsiveCard, SetupOuter } from "onboarding/components/SetupOuter";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { SLOnboardingContext } from "onboarding/sectionLeaderOnboarding/contexts/SLOnboardingContext";
import * as Yup from "yup";
import { Link } from "react-router-dom";

export const SLSectionPreferences = () => {
  const navigate = useNavigate()
  const courseId = useCourseId();
  const { onboardingData, editOnboarding } = useContext(SLOnboardingContext)

  const sectionPreferences = onboardingData?.sectionPreferences || {
    accommodationsForStudents: '',
    accommodationsForSectionLeaders: '',
  }
  const backUrl = `/${courseId}/onboarding/teach`

  const handleSubmit = async (values) => {
    console.log(values)
    await editOnboarding({
      sectionPreferences: values
    })
    // navigate(backUrl)
  }

  return <SetupOuter>
    <ResponsiveCard style={{ margin: "auto", padding: "20px" }}>
      <div>
      <h3 className="mt-1">Section Preferences</h3>
        <FormixFormWithData
          serverData={sectionPreferences}
          backUrl={''}
          formFormat={preferencesFormStructure}
          onSubmit={(e, cb) => handleSubmit(e)}
          autoSave={true}
        />

      </div>
      <Link className="btn btn-secondary" to={`/${courseId}/onboarding/teach`}>
        Back
      </Link>
    </ResponsiveCard>
  </SetupOuter>
}

const AccommodationsForStudentsInput = ({ name }) => <TextAreaInput
  label="Accommodations for Students"
  name={name}
  description={<p>
    Code in Place has students from many different
    backgrounds, including those who might require
    accommodations in section such as closed captions in
    section videos, text-based versions of graphical
    assignments, ensuring color compatibility of graphics for
    colorblindness, repeating student questions before
    answering them or teaching audio-only sections. Are you
    willing to teach a section with such special
    accommodations? If there are any accommodations you are particularly
    suited for, please describe them here, otherwise, just say "yes".
  </p>}
/>

const AccommodationsForSLsInput = ({ name }) => <TextAreaInput
  label="Accommodations for You"
  name={name}
  description={<><p>
    If you would like to highlight a need you have that would
    otherwise prevent you from being able to teach, or engage
    with the material fully and might require an
    accommodation, please let us know here — we’ll do our very
    best to meet your needs!
  </p>
    <p>
      Example requests include closed captioning on course
      videos, text-based versions of graphical assignments,
      ensuring color compatibility of graphics for
      colorblindness, etc. Please note that you do not have to
      disclose the reason for your request; just make sure to
      include enough information so that we’re able to provide
      you with sufficient accommodations as quickly and
      effectively as possible.
    </p></>}
/>

const preferencesFormStructure = [
  {
    name: "accommodationsForSL",
    input: AccommodationsForSLsInput,
    default:'',
    validation: Yup.string()
  },
  {
    name: "accommodationsForStudents",
    input: AccommodationsForStudentsInput,
    default:'',
    validation: Yup.string()
  },
]




import React from "react";
import styled from "styled-components";

interface TranscriptEntry {
  data: string;
  time: string;
}

interface TranscriptProps {
  currentTimestamp: number;
  seekVideo: (time: number) => void;
  transcriptRaw: any;
}

const TranscriptContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  padding: 1rem;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  width: 90%;
  border: 0.1px solid;
`;

const TranscriptEntryContainer = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 8px;
  color: ${(props) => (props.isHighlighted ? "blue" : "initial")};
  cursor: pointer;
  background-color: ${(props) => (props.isHighlighted ? "#f0f0f0" : "initial")};
  &:hover {
    background-color: #f0f0f0;
  }
  border-radius: 5px;
  padding: 5px;
`;

const TranscriptEntryParagraph = styled.p`
  margin: 0;
`;

export async function fetchSrtFile(path: string): Promise<string> {
  const response = await fetch(path);

  if (!response.ok) {
    throw new Error(`Error fetching SRT file: ${response.statusText}`);
  }

  return response.text();
}

function parseSrtToTranscriptData(srtString: string): TranscriptEntry[] {
  const srtLines = srtString.split("\n");

  const transcriptData: TranscriptEntry[] = [];

  let i = 0;
  while (i < srtLines.length) {
    if (!isNaN(parseInt(srtLines[i]))) {
      const timeString = srtLines[i + 1];
      const timeParts = timeString.split(" --> ");
      const startTime = timeParts[0].replace(",", ".").slice(3, -4);

      let dataLines: string[] = [];
      i += 2;
      while (srtLines[i] !== "" && i < srtLines.length) {
        dataLines.push(srtLines[i]);
        i++;
      }

      const data = dataLines.join(" ");

      transcriptData.push({
        data,
        time: startTime,
      });
    } else {
      i++;
    }
  }

  return transcriptData;
}

const Transcript: React.FC<TranscriptProps> = ({
  transcriptRaw,
  currentTimestamp,
  seekVideo,
}) => {

  if(transcriptRaw === undefined) {
    return <div>Loading...</div>;
  }
  const handleTranscriptItemClick = (time: string, currentTimestamp) => {
    const timeConverted = timestampToSeconds(time);
    if (timeConverted > currentTimestamp) {
      return;
    }
    seekVideo(timeConverted);
  };

  const transcriptData = parseSrtToTranscriptData(transcriptRaw);

  const timestampToSeconds = (time: string): number => {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  };
  const isHighlighted = (entryTime: string): boolean => {
    const entrySeconds = timestampToSeconds(entryTime);
    const previousEntry = transcriptData.reduce((previous, current) => {
      const currentSeconds = timestampToSeconds(current.time);
      return currentSeconds <= currentTimestamp &&
        currentSeconds > timestampToSeconds(previous.time)
        ? current
        : previous;
    }, transcriptData[0]);
    return entrySeconds === timestampToSeconds(previousEntry.time);
  };
  return (
    <TranscriptContainer>
      {transcriptData.map((entry, index) => (
        <TranscriptEntryContainer
          key={index}
          isHighlighted={isHighlighted(entry.time)}
          onClick={() =>
            handleTranscriptItemClick(entry.time, currentTimestamp)
          }
        >
          <div>
            <strong>{entry.time}</strong>
          </div>
          <TranscriptEntryParagraph>{entry.data}</TranscriptEntryParagraph>
        </TranscriptEntryContainer>
      ))}
    </TranscriptContainer>
  );
};

export default Transcript;

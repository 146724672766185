import { ForumContext } from "course/forum/ForumContext";
import "../forum/forum.css";
import { Role } from "types/role";
import { ForumPage } from "../forum/ForumPage";
import { QueryClientProvider } from "react-query";





export const ReportingForum = ({queryClient}) => {


    return (
      <ForumPage />
    )

}



import { onAuthStateChanged, getAuth } from "firebase/auth"
import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { FaHome } from "react-icons/fa"
import { useNavigate } from "react-router"
import styled from "styled-components"


const interestedRoleIdToLabel = {
    student: "Becoming a Student",
    sl: "Becoming a Section Leader",
    updates: "Receiving Updates About Code in Place",
    other: "Other"
}



export const InterestForm = () => {
    const db = getFirestore()
    const interestsColRef = collection(db, "forms", "generalInterest", "responses")
    const [responseDocId, setResponseDocId] = useState(undefined)
    const [editingMode, setEditingMode] = useState(true)
    const [email, setEmail] = useState("")
    const [authEmail , setAuthEmail] = useState("")
    const [interestedRole, setInterestedRole] = useState("student")
    const [otherReason, setOtherReason] = useState("")
    const auth = getAuth()


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in
            setAuthEmail(user.email)
          } 
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
      }, []);


    const onSubmit = async () => {
        // validate 
        let userId = auth.currentUser ? auth.currentUser.uid : ""
        let correctEmail = authEmail ? authEmail : email
        if(!correctEmail.includes("@")) {
            alert("Please enter a valid email address")
            return
        }

        if(!interestedRole) {
            alert("Please fill out all fields")
            return
        }

        if(!responseDocId) {
            const response = await addDoc(interestsColRef, {
                correctEmail,
                interestedRole,
                userId,
                timestamp: serverTimestamp(),
                otherReason
            })
            setResponseDocId(response.id)
        } else {
            const interestsDocRef = doc(interestsColRef, responseDocId)
            await updateDoc(interestsDocRef, {
                correctEmail,
                interestedRole,
                userId,
                timestamp: serverTimestamp(),
                otherReason
            })
        }
        setEditingMode(false)
    }


    if(!editingMode) {
        return <FormContainer>
            <HomeButton />
            <InterestFormOuter className="form" >
                <h2>Your response was submitted!</h2>
                {authEmail ? <></> : <p>Email: {email}</p>}
                <p>I am interested in <strong>{interestedRoleIdToLabel[interestedRole]}{interestedRole === "other" ? `: ${otherReason}` : ""}</strong></p>
                
                {/* <p>I am a/an <strong>{whoRoleIdToLabel[who]}</strong></p> */}
                <p>If you made a mistake, you can <button className="badge bg-primary border" onClick={() => {
                    setEditingMode(true)
                }}>edit</button></p>
            </InterestFormOuter>

        </FormContainer>
    }



    return <FormContainer>
        <HomeButton />
        <InterestFormOuter className="form" >
            <div className="form-group">
                <label htmlFor="interest">Code in Place Interest Form</label>
                {authEmail ? <></> : <div className="p-2">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>}
                <div className="p-2">
                    <label htmlFor="interested-role">I am interested in</label>
                    <select className="form-select" id="interested-role" 
                        defaultValue={interestedRole}
                        onChange={(e) => setInterestedRole(e.target.value)}
                    >
                        {Object.keys(interestedRoleIdToLabel).map((role) => {
                            return <option value={role} key={role}>{interestedRoleIdToLabel[role]}</option>
                        })}
                    </select>
                    {
                        interestedRole === "other" ? < >
                            <label htmlFor="other">Please specify</label>
                            <input type="text" value={otherReason} className="form-control" id="other" name="other" placeholder="Hello World" onChange={(e) => {
                                setOtherReason(e.target.value)
                            }}/>
                        </> : <></>
                    }
                </div>
                {/* <div className="p-2">
                    <label htmlFor="who">I am a/an</label>
                    <select className="form-select" id="who"
                        defaultValue={who}
                        onChange={(e) => setWho(e.target.value)}
                    >
                        {Object.keys(whoRoleIdToLabel).map((role) => {
                            return <option value={role} key={role}>{whoRoleIdToLabel[role]}</option>
                        })}
                    </select>
                </div> */}
            </div>
            <button className="btn btn-primary"
                onClick={onSubmit}
            >Submit</button>
        </InterestFormOuter>
    </FormContainer>
}



const HomeButton = () => {
    const navigate = useNavigate()
    return <button className="btn btn-primary" onClick={() => navigate("/")} style={{
        position: "absolute",
        top: "20px",
        left: "20px"
    }}><FaHome/></button>
}


const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background-image: url('splash4.jpg');
    background-size: cover;
    background-position: center;
`


const InterestFormOuter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: white;
`




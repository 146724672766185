import { useEffect, useState } from "react";
import Split from "react-split";

/**
 * This is an insanely useful component with some important UX logic.
 * It creates a split, like the ones in the IDE. It currently needs 
 * to be generalized to allow for:
 *  - maximization buttons
 *  - vertical splits
 *  - splits where the collapsable component is not on the right.
 * 
 * Contact @chrispiech if you would like to use this component with one
 * of the features above.
 * 
 * Parameters:
 *  - minimizedState can either be "minimized", 
 *   "standard", or "maximized"
 * - you can have buttons which set that state, 
 *   and this component will react,
 * - outerSize is the size of the outer component. 
 *   This can be hard to track...
 */

const defaultMinSize = [800, 0]; // pixels
const defaultStartPercent = [80, 20]; // percent

export const ResizableSplit = ({
  minimizedState,
  setMinimizedState,
  minSize = defaultMinSize,
  defaultPercent = defaultStartPercent,
  outerSize,
  direction = "horizontal",
  children
}) => {

  // a few constants that are important
  const gutterSize = 5;
  const collapsedWidth = 0; // px

  // this is the representation that Split needs
  const [collapsedIndex, setCollapsedIndex] = useState(1);
  // we need to keep track of the actual percentages too
  const [cachedSize, setCachedSize] = useState([100, 0]);

  // if the user drags a component to be small, react
  useEffect(() => {
    // ignore this if the window hasn't loaded yet
    const dimension = direction === "horizontal" ? outerSize.width : outerSize.height;
    if (dimension == 0) {
      return;
    }
    const canvasPercent = cachedSize[1];
    const canvasSize = dimension * (canvasPercent / 100);
    // console.log(canvasWidth)
    if (canvasSize <= collapsedWidth + gutterSize) {
      setMinimizedState("minimized");
    } else {
      setMinimizedState("standard");
    }
  }, [cachedSize]);

  // if the minimize button is pressed, react
  useEffect(() => {
    console.log(minimizedState)
    if (minimizedState === "minimized") {
      setCollapsedIndex(1);
    }
    if (minimizedState === "standard") {
      setCollapsedIndex(undefined);
      setCachedSize(defaultPercent);
    }
  }, [minimizedState]);

  // the key will force a rerender if any of the critical parameters change
  const key = `resizable-split-${direction}`

  return <Split
    key={key}
    className={`vh-100 w-100 d-flex ${direction === "vertical" ? 'flex-column' : ''}`}
    sizes={cachedSize} // this is in percentages
    expandToMin={false}
    minSize={minSize}
    snapOffset={[0, 0]}
    gutterSize={gutterSize} // must be a whole number
    dragInterval={1} // must be a whole number
    direction={direction === "vertical" ? "vertical" : "horizontal"} // Ensure direction is either "horizontal" or "vertical"
    onDrag={(sizes) => setCachedSize(sizes)}
    onDragStart={() => { }}
    onDragEnd={() => { }}
    collapsed={collapsedIndex}
    style={{
      overflow: "hidden" // Not sure if this is the best place to put this - Sierra
    }}>
    {children}
  </Split>
}
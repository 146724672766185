import { useRef, useEffect, useState, useCallback } from "react";
import { getAuth } from "firebase/auth";
import { IDEContext } from "ide/contexts/IDEContext";
import { TerminalView } from "ide/TerminalPane/Terminal/XTermModel";
import { PyodideClient } from "components/pyodide/PyodideProvider";
import { getAllImages, getAllFileNames } from "ide/utils/general";
import KarelPythonCompiler from "ide/utils/karelCompiler/karelPythonCompiler";
import { deepCopy } from "@firebase/util";
import { onRun } from "./MetricTracker";
import { useCourseId } from "hooks/router/useUrlParams";
import { TermModel } from "ide/TerminalPane/GeneralTerminal/Model";

export const useUserInfo = (projectData) => {
    const [userInfo, setUserInfo] = useState(false);
    const [isOwner, setIsOwner] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
          // Get current user
          const auth = getAuth();
          const authUser = auth.currentUser;
    
          if (authUser && authUser.uid === projectData.user.id) {
            setIsOwner(true);
          }
    
          setUserInfo(projectData.user);
        };
    
        fetchUserData();
      }, []);


      return {userInfo, isOwner}
}



export const usePyodideOnPublished = (projectData, projectCode, projectId, codeToRun, isKarel , mainFileID, karelWorldState, setKarelWorldState) => {
    const [isRunning, setRunning] = useState(false);
    const pyodideClientRef = useRef(null);
    const terminalRef = useRef(null);
    const courseId = useCourseId();



    useEffect(() => {
        pyodideClientRef.current = new PyodideClient();
        const pyodideClient = pyodideClientRef.current;
    
        terminalRef.current = new TermModel(
          "%",
          () => runCode(),
          () => onEnd(),
          () => pyodideClient.raisePyStopFlag()
        );
        const terminal = terminalRef.current;
    
        pyodideClient.setHandlers(
          endTerminal,
          (stdout) => terminal.handleStdout(stdout),
          (stderr, code) => handleError(stderr),
          (pmt) => terminal.handleStdin(pmt),
          (filePath) => {
            const images = getAllImages(projectData.files);
            return images[filePath];
          },
          () => {}
        );
        terminal.initTerm();
      }, []);
    
      const onEnd = () => {
        setRunning(false);
      };
    
      const handleError = async (stderr) => {
        setRunning(false);
        const terminal = terminalRef.current;
        await terminal.handleStderr(codeToRun, stderr);
      };

      const endTerminal = useCallback(() => {
        const terminal = terminalRef.current;
        terminal.handleEnd();
      }, []);


      const runCode = async () => {
        // Important stuff
        let code = codeToRun.current;
        let pyodideClient = pyodideClientRef.current;
    
        // Set terminal
        let terminal = terminalRef.current;
        terminal.focusTerm();
    
        if (isKarel) {
          const compiler = new KarelPythonCompiler(karelWorldState);
          let compileResult = compiler.compile(code);
    
          // If fail...
          if (compileResult.status === "error") {
            compileResult = JSON.parse(
              compileResult.error.toString().split("Error: ")[1]
            );
            await handleError(
              "Line " + compileResult.lineNumber + ": " + compileResult.msg
            );
            terminal._prompt();
            onEnd();
            return;
          }
    
          pyodideClient.setKarelInfo(
            deepCopy(karelWorldState),
            (state) => {
              setKarelWorldState(deepCopy(state));
            },
            0.1
          );
        } else {
          pyodideClient.setKarelInfo({}, () => {});
        }
    
        // Run Code!
        const fileNames = getAllFileNames(projectData.files);
        await pyodideClient.loadFiles(fileNames, projectCode);
        await pyodideClient.runCode(
          code,
          {
            name: fileNames.find((file) => file.id === mainFileID).name,
            id: mainFileID,
          },
          false
        );
        await onRun(projectId, courseId);
        return;
      };

      return { runCode, isRunning, setRunning, pyodideClientRef }

}
export enum ProjectGuidePhase {
  "GET_PROJECT_IDEA_FROM_USER",
  "GET_SCOPE_DECISION_FROM_USER",
  "PROJECT_CONVERSATION",
  "SHOW_PROJECT_GUIDE",
}

export type QuestionAndAnswer = {
  question: string;
  answer?: string;
};

export type GuideStepResource = {
  title: string;
  url: string;
};

export type JargonTerm = {
  term: string;
  definition: string;
};

export type GuideStep = {
  step: number;
  title: string;
  description: string;
  courseResources: GuideStepResource[];
  completed: boolean;
  jargon: JargonTerm[];
};

export type FeasabilityFeedback = {
  feasible: boolean;
  feedback: string;
  alternativeideas: string[];
};

export type ProjectGuideState = {
  phase: ProjectGuidePhase;
  projectIdea?: string;
  questions?: QuestionAndAnswer[];
  guide?: GuideStep[];
  name?: string;
  feasability?: FeasabilityFeedback;
};

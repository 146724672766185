import React, { useContext } from "react";
import { IDEContext } from "../contexts/IDEContext";
import { LeftColTitle } from "../LeftCol";

// a lot of these are just modules
const blacklist = ["___arg", "run_karel_program", "graphics", "random"];

export const StepperView = () => {
  const { stepData, stepMode, hasHistory } = useContext(IDEContext);

  const tableData = createTableData(stepData);
  return (
    <>
      <LeftColTitle title="Replay Mode" />

      {!hasHistory ? (
        <p className="m-3">
          You have to <b>run</b> your program first.
        </p>
      ) : (
        <>
          {!stepMode ? (
            <p className="m-3">
              Scrub through the <b>Replay Timeline</b> next to the 'Run' button!
            </p>
          ) : null}
        </>
      )}
      <div className="m-2 p-2 d-flex flex-column gap-2 overflow-auto">
        <div className="d-flex flex-row gap-2 flex-fill w-100">
          <table className="table" style={stepperTableStyle}>
            <thead>
              <tr>
                <th scope="col">Variable</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              {
                tableData.map((row, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{row.variable}</th>
                      <td>{row.value}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </>
  );
};

const isRenderable = (v) => {
  if (typeof v === "string") return true;
  if (typeof v === "number") return true;
  if (typeof v === "boolean") return true;
  if (typeof v === "object") {
    const toString = Object.prototype.toString.call()
    if(toString === "[object Array]") return true
  }
  if(typeof v === "function") return false;
  return true;
};

function isIterable(obj) {
  return typeof obj === 'object' && obj !== null && Symbol.iterator in obj
}

export function createTableData(stepData){
  if(!stepData) return []

  if(!isIterable(stepData)) return []


  // Save the original toString method
  let originalToString = Array.prototype.toString;

  // Override the toString method
  Array.prototype.toString = function() {
    return '[' + this.join(', ') + ']';
  };

  const rows = []
  for(let keyValye of stepData) {
    const key = keyValye[0]
    const value = keyValye[1]
    const canShow = isRenderable(value)

    // some objects are not renderable
    if(!canShow || blacklist.includes(key)) continue

    if(!value) continue
    let str_v = value.toString()
    if(str_v.includes("graphics.Canvas")) {
      // str_v = str_v.replace("graphics.graphics.Canvas object", "Canvas")
      str_v = "<Canvas>"
    } else if(str_v === "[object Map]") {
      let entries = Array.from(value.entries());
      let readableString = entries.map(entry => `${entry[0]}: ${entry[1]}`).join(', ');
      str_v = `{${readableString}}`
    }

    if(typeof value === "boolean") {
      str_v = str_v.replace('true', 'True')
      str_v = str_v.replace('false', 'False')
    }
    rows.push({
      variable: key,
      value: str_v
    })
  }

  // Restore the original toString method
  Array.prototype.toString = originalToString;

  return rows
}

const stepperTableStyle = {
  fontSize: '12px',
  fontFamily: 'Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace'
}

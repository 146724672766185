import { Input, Spin } from "antd";
import { useContext, useState } from "react";
import { Thinking } from "./thinking";
import { FinalProjectContext } from "./FinalProjectContext";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { useCourseId } from "hooks/router/useUrlParams";

export const SubmitProjectIdea = (props: {
  recordProjectIdea: (projectIdea) => void;
  loading: boolean;
}) => {
  const { recordProjectIdea, loading } = props;
  const { ideateRef } = useContext(FinalProjectContext);
  const [projectIdea, setProjectIdea] = useState("");
  const courseId = useCourseId();

  return (
    <div className="mt-3">
      
      <h3 ref={ideateRef}>Step 1: Think of an idea</h3>
      <p>
        One of the most exciting steps when learning to program is solving a
        problem of your choice! 
        On this page, we'll help you through this process, starting with your
        project idea. Try to think of a project that can be done in 10-20 hours of work,
        based on what you know.
      </p>

      <p>Describe your project in a few sentences. Give enough detail that we can give you feedback:</p>
      <Input.TextArea
        style={{ width: "100%"}}
        size="large"
        onPressEnter={(e) => {
          e.preventDefault();
          recordProjectIdea(projectIdea);
        }}
        placeholder="What do you want to make?"
        value={projectIdea}
        onChange={(e) => setProjectIdea(e.target.value)}
        disabled={loading}
      />

      {/* <p className="mt-3">Are you having trouble thinking of a project? Creativity isn't easy! The default project is to create the game of <a target="_blank" href="http://localhost:3000/cip4/ide/a/babysnake">Baby Snake</a> after learning about animation. You could also take one of the class assignments and make it more impressive.</p> */}
      {loading ? <div className="mt-2"><Thinking interval={5} /></div> : <span />}

      <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/finalProjectProposalDoc`}/>
    </div>
  );
};

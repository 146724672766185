import { useContext, useEffect } from "react";
import { LessonsContext } from "course/contexts/LessonsContext";
import { TrainingContext } from "course/contexts/TrainingContext"
import { PlaceLesson } from "components/duolessons/PlaceLessons";
import { filterLessonData } from "components/duolessons/server/LessonServerHelper";

export const Lesson = ({}) => {
  // get the current navigation location
  const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
  const isStudent = pathSegments[1] === 'learn'
  const lessonId = pathSegments[2];
  const slideId = pathSegments[3]; // can be undefined
  

  const { lessonsMap } = useContext(isStudent ? LessonsContext : TrainingContext)
  const rawData = lessonsMap[lessonId]

  const lessonData = {...filterLessonData(rawData), id: lessonId }


  return <PlaceLesson lessonData={lessonData} startSlide={slideId} />
}


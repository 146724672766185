import { Card } from "react-bootstrap";
import styled from "styled-components";

export const SetupOuter = styled.div`
  width: 720px;
  max-width: 90vw;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 10px;
    width: 100%;
    max-width: 100%;
  }
`

export const ResponsiveCard = styled(Card)`
  @media (max-width: 768px) {
    width: 100%;
    border: none;
    padding: 0 !important;
    margin: 0;
  }
`
export const helpTimeline = [
  {
    title: "Welcome to the Code in Place IDE!",
    intro: "Let me take you around!",
  },
  {
    title: "Editor",
    element: "#code-editor",
    intro: 'The editor is where you write your code!</br>  Note: For accessibility options, press F1 or alt-F1 if you\'re using Internet Explorer. To toggle between using tab to focus and tab to indent, click Ctrl+M on Windows or Ctrl+Shift+M on OSX'
  },
  {
    title: "Run Button",
    element: "#start-stop-button",
    intro: "Click the run button to run your code!",
  },
  {
    title: "Replay Mode",
    element: "#replay-mode-stepper",
    intro:
      "After running your code, you can use the replay slider to step back in time to see how each line of code affects the position and behavior of Karel.",
  },
  {
    title: "Karel World",
    element: "#canvas-pane",
    intro:
      "The World shows Karel and her world. When you hit run, Karel will perform the instructions in your code.",
  },
  {
    title: "Terminal",
    element: "#termshell",
    intro: "You can see your errors in the terminal.",
  },
  {
    title: "Docs",
    element: "#Docs_sidebar_icon",
    intro:
      "Click the Docs button to view the documentation. It's a good reference if you forget any important commands for Karel!",
  },
  {
    title: "Screen Readable Editor",
    element: "#editor-switch",
    intro:
      "Toggle this switch to switch to the screen-reader accessible version of the editor and the terminal.",
  },
];

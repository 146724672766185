import { useEffect, useState } from "react"
import SplitPane from "react-split-pane"
import { TeachApplicationInstructions } from "./TeachApplicationInstructions"
import { uploadVideo, getDemoVideoStoragePath } from "./uploadVideo"
import { ProgressBar } from "react-bootstrap"
import { useWindowSize } from "react-use-size"
import { useUserId } from "hooks/user/useUserId"

export const TeachingDemoView = ({serverData}) => {

  const windowSize = useWindowSize()

  const tooSmall = windowSize.width < 1100
  if(tooSmall) {
    return <div className="container">
      <div className="row">
        <div className="col">
          <VideoUpload serverData = {serverData}/> 
          <TeachApplicationInstructions/>
        </div>
      </div>
    </div>
  }
  return <SplitPane split="vertical" minSize={300} defaultSize={670}>
      <TeachApplicationInstructions/>
      <VideoUpload serverData = {serverData}/>  
  </SplitPane>
}


const VideoUpload = ({serverData}) => {
  const userId = useUserId()
  const [videoUrl, setVideoUrl] = useState(serverData.videoUrl)
  const [errorState, setErrorState] = useState(serverData.error)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    if(videoUrl) {
      setUploadProgress(0)
    }
  }, [videoUrl])

  const uploadClicked = () => {
    setErrorState(null)
    const demoVideoStoragePath = getDemoVideoStoragePath(userId);
    uploadVideo(setVideoUrl, setUploadProgress, setErrorState, demoVideoStoragePath)
  }
  

  return <div className="d-flex flex-column align-items-center w-100 p-3">
    <div style={{maxWidth:550}}>
      <button className="btn btn-lg btn-primary mt-2" onClick={uploadClicked}>Upload Video</button>
      <div style={{height:20}}/>
      
      <VideoPreview 
        url={videoUrl} 
        errorState={errorState}
        uploadProgress={uploadProgress}
      />
      
    </div> 
  </div>
}


const VideoPreview = ({url, errorState, uploadProgress}) => {
  function getErrorMsg(errorState) {
    if(errorState.code == 'storage/unauthorized') {
      return 'Error: File too big (max 100MB)'
    }
    return errorState.message
  }

  if(!url || uploadProgress > 1) {
    // only show errors if there is a video
    if(errorState && errorState.code != 'storage/object-not-found') {
      return <BlankVideo msg={getErrorMsg(errorState)}/>
    }
    if(uploadProgress > 0) {
      return <UploadProgress uploadProgress={uploadProgress}/>
    }
    return <BlankVideo msg={'No Video Yet'}/>
  }

  console.log('url', url)
  return <>
    <h4>Preview</h4>
    <video src={url} className="w-100" controls={true}></video>
    <i>If you can't view this video, we can't either</i>
  </>
}

const UploadProgress = ({uploadProgress}) => {
  const now = Math.min(uploadProgress, 98)
  return <>
    <h4>Uploading...</h4>
    <ProgressBar now={now} />
    </>
}

const BlankVideo = ({msg}) => {
  // returns a component with a grey background and text which says no video
  return <>
  <h4>Preview</h4>
  <div className="d-flex flex-column align-items-center justify-content-center" style={{width:'100%', height:380, backgroundColor:'lightgrey'}}>
    <h4>{msg}</h4>
  </div>
  </>
}


const fixUrl = (url) => {
  if(url.includes('drive.google.com')) {
    // make sure that the link ends in preview, not view
    if(url.endsWith('view')) {
      return url.replace('/view', '/preview')
    }
  }
  return url
}


const reactPlayerConfig = {
  file:{forceVideo:true}
}
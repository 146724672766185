import React, { useState, useEffect, useContext } from 'react';
//import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Route, Routes, useLocation } from 'react-router-dom';
import { LearnSplash } from './LearnSplash';
import { Lesson } from './Lesson';


export const LearnContainer = (props) => {

  return (
    <Routes>
      <Route index element={
        <div style={{ width: "100%", height: "100vh", overflowY: "visible" }}>
          <LearnSplash />
        </div>
      } />
      <Route path=":lessonId/:slideId" element={
        <Lesson/>
      } />
      <Route path=":slideId" element={
        <Lesson/>
      } />

    </Routes>
  )
}




import { useState, useContext } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FaChevronLeft, FaTimes, FaHatWizard } from "react-icons/fa";
import styled from 'styled-components';
import Swal from "sweetalert2";
import Confetti from 'react-confetti'
import { useWindowSize } from "react-use-size";
import { useIsMobile } from "utils/general";
import { LessonContext, LessonProvider } from "./LessonContext";
import { ResizableSplit } from "components/split/ResizableSplit";

import { CHAT_TYPES, isIDE, isNoChat } from "./lessonchats/ChatConstants";
import { LessonDrawer } from "./LessonDrawer";
import { CompletionContext } from "contexts/CompletionContext";
import { NewMessageDot } from "./lessonchats/NewMessageDot";

export const PlaceLesson = ({ lessonData, startSlide }) => {
  return <LessonProvider
    lessonData={lessonData}
    startSlideId={startSlide}
  >
    <PlaceLessonView />
  </LessonProvider>
}

const PlaceLessonView = () => {
  const isMobile = useIsMobile();
  const { setChatViewState, chatViewState, chatType } = useContext(LessonContext);
  const outerSize = useWindowSize();

  // Do not show a chat if the user is in the No Chat or IDE Chat experiment group
  if (isNoChat(chatType) || isIDE(chatType)) {
    return <Slide />
  }

  // The chat 
  if (isMobile) {
    return <ResizableSplit
      minimizedState={chatViewState}
      setMinimizedState={setChatViewState}
      defaultPercent={[60, 40]}
      minSize={[100, 0]}
      outerSize={outerSize}
      direction="vertical"
      >
      <Slide />
      {CHAT_TYPES[chatType].component}
    </ResizableSplit>
  } else {
    return <ResizableSplit
      minimizedState={chatViewState}
      setMinimizedState={setChatViewState}
      outerSize={outerSize}
      direction="horizontal"
    >
      <Slide />
      {CHAT_TYPES[chatType].component}

    </ResizableSplit>
  }
}



const Slide = () => {
  const { currSlideData } = useContext(LessonContext);
  const speechText = currSlideData?.data?.speechText;
  const speechIcon = currSlideData?.data?.speechIcon;
  const isMobile = useIsMobile();

  // get second url path
  const paths = window.location.pathname.split('/');
  let isStudentLearn = true;
  if(paths.length > 2 && paths[2] === 'training') {
    isStudentLearn = false;
  }


  if (isMobile) {
    return (
      <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
        <OuterBody style={{ height: "100%" }}>
          <Navigation />
          <Scrollable style={{ flexGrow: 1, overflowY: "auto" }}>
            <Content />
          </Scrollable>
          <div style={{
            position: "fixed",
            bottom: 0,
            right: "5px",
          }}>
            <ContinueButton />
          </div>
          {

          }
          {isStudentLearn ? <ChatButton isMobile={isMobile} />: <></>}
        </OuterBody>
      </div>)
  }

  return <>
    <OuterBody>
      <Navigation />
      <Scrollable>
        <Content />
      </Scrollable>
      <ContinueButton />
      {isStudentLearn ? <ChatButton isMobile={isMobile} />: <></>}
    </OuterBody>
  </>
}

const ChatButton = ({ isMobile }) => {
  const { chatType, setChatViewState, chatViewState, setChatTimestamp, unreadMessageFlag, setUnreadMessageFlag } = useContext(LessonContext);

  if (isNoChat(chatType) || isIDE(chatType) || chatViewState === "standard") {
    return <></>
  }

  const toggleViewState = () => {
    if (chatViewState === "minimized") {
      setChatViewState("standard");
      setChatTimestamp();
      setUnreadMessageFlag(false);
    } else {
      setChatViewState("minimized");
    }
  }

  if (isMobile) {
    return <div
      style={{
        position: "fixed",
        bottom: 0,
        left: "5px",
      }}
    >
      <Footer>
        <button
          aria-label="toggle chat"
          onClick={() => { toggleViewState() }}
          className="btn btn-light mr-1"
        >
          {unreadMessageFlag && <NewMessageDot topPx={"15px"} rightPx={"0px"} />}
          <FaHatWizard />
        </button>
      </Footer>
    </div>
  } else {
    return <button
      aria-label="toggle chat"
      onClick={() => { toggleViewState() }}
      className="btn btn-light btn-lg mr-1"
      style={{
        position: "absolute",
        bottom: "26px",
        right: "26px",
        alignSelf: "flex-end",
        margin: "0px",
      }}
    >
      {unreadMessageFlag && <NewMessageDot topPx={"-10px"} rightPx={"-10px"} />}
      <FaHatWizard />
    </button>
  }
}

const ContinueButton = () => {
  const isMobile = useIsMobile();
  const { setLessonSlideCompleted } = useContext(CompletionContext);
  const { currSlideId, lessonId, currSlideData, nextSlide } = useContext(LessonContext);

  const onContinue = () => {
    setLessonSlideCompleted(lessonId, currSlideId)
    nextSlide()
  }

  let canSkip = true;
  if (currSlideData?.data?.unskippable) {
    canSkip = false;
  }

  const text = currSlideData?.continueButtonText || "Continue";

  const hideFooter = !canSkip;
  const showFooter = !(hideFooter);

  if (hideFooter) {
    return <></>
  }

  const size = isMobile ? "md" : "lg";

  return <Footer>
    {showFooter && <Button
      onClick={onContinue}
      disabled={false}
      size={size}>{text}</Button>}
  </Footer>
};

const Content = () => {
  const { setLessonSlideCompleted } = useContext(CompletionContext);
  const { currSlideIndex, currSlideId, lessonId, currSlideData, nextSlide } = useContext(LessonContext);
  console.log('currSlideId', currSlideId)
  const [confettiIndex, setConfettiIndex] = useState(0);
  const windowSize = useWindowSize();
  if (!currSlideData) {
    return <>No content</>
  }

  const onComplete = () => {
    if (currSlideData?.data?.confetti) {
      setConfettiIndex(confettiIndex + 1);
    }
    setLessonSlideCompleted(lessonId, currSlideId)
    setTimeout(() => {
      Swal.fire({
        title: 'Good job!',
        icon: 'success',
        confirmButtonText: 'Next'
      }).then(() => {
        nextSlide()
      })
    }, 500)
  }

  const nextSlideWithComplete = () => {
    setLessonSlideCompleted(lessonId, currSlideId)
    nextSlide()
  }
  return <>
    <Confetti
      run={confettiIndex > 0}
      key={confettiIndex + 'confetti'}
      width={windowSize.width}
      height={windowSize.height}
      recycle={false}
      numberOfPieces={500}

    />
    <currSlideData.component
      key={currSlideIndex + 'slide'}
      data={currSlideData.data}
      onComplete={onComplete}
      nextSlide={nextSlideWithComplete}
    />
  </>
}


const Navigation = () => {

  const isMobile = useIsMobile();

  const { slides, currSlideIndex, lessonData, prevSlide, closeLesson } = useContext(LessonContext);

  // you start at 0, but skip to considering the current page as
  // complete from the first slide
  let now = (currSlideIndex + 1) / slides.length * 100;
  if (currSlideIndex == 0) {
    now = 0;
  }

  console.log('lessonData', lessonData)

  const displayTitle = isMobile ? lessonData.shortTitle : lessonData.title;

  return <NavigationOuter>
    <button
      aria-label="exit lesson"
      onClick={closeLesson}
      className="btn btn-light mr-1"
    ><FaTimes /></button>

    {displayTitle}

    <LessonDrawer />

    <Button variant={"light"} className="mr-1 ml-1" disabled={currSlideIndex == 0} onClick={prevSlide} aria-label="go back"><FaChevronLeft /></Button>

    <ProgressBar now={now} className="flex-grow-1 mr-1" />

  </NavigationOuter>
}




const NavigationOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  max-width: 1200px;
`;


const OuterBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100vh;
  overflow: hidden;
  // padding-bottom:20px;
  
  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
  @media (max-height: 800px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
`;

const Scrollable = styled.div`
  flex: 1;
  display: flex;
  width:100%;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 1200px;
  flex-direction: column;
  `

const ScrollableInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  max-width: 1200px;
  height:100%;
  flex-grow:1;
  max-height: calc(100vh - 45px);
  `

const InnerBody = styled.div`
  flex: 1;
  flex-grow:1;
  display: flex;
  flex-direction: column;
  width:100%;
  height:calc(100% - 80px);
  align-items: center;
`;



const ContentOuter = styled.div`
  flex-grow: 1;
  display: flex;
  width:100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
`;




const Footer = styled.div`
  height:80px;
  width: 100%;
  // border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  justify-content: center;

  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    align-items: center; /* Aligns the buttons to the center */
  }
`;



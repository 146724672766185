import { useContext } from "react";

import "firebase/compat/auth";
import { useCourseId } from "../../hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { Button } from "react-bootstrap";
import {
  FaClock, FaInfoCircle, FaScroll, FaUser
} from "react-icons/fa"
import { useNavigate } from "react-router-dom";
import { StudentOnboardingContext } from "./contexts/StudentOnboardingContext";
import { useUserId } from "hooks/user/useUserId";
import { confirmRole } from "onboarding/components/ConfirmRole";
import { TodoStep } from "onboarding/components/TodoStep";

export const StudentOnboardingSplash = (props) => {
  const courseId = useCourseId();
  const userId = useUserId();
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(ProfileContext);
  const { onboardingData, editOnboarding } = useContext(StudentOnboardingContext);


  const sectionTimeIndex = onboardingData?.sectionTimeIndex;
  const sectionTimeIndexMinor = onboardingData?.sectionTimeIndexMinor;
  const isMinor = ('isMinor' in userData) ? userData.isMinor : false
  const agreementDone = onboardingData?.agreementData?.signed;
  const sectionTimeDone =
    isMinor 
      ? sectionTimeIndexMinor !== undefined && sectionTimeIndexMinor != "none"
      : sectionTimeIndex !== undefined && sectionTimeIndex != "none";
  const confirmedStudent = userData?.confirmedStudent?.[courseId];
  const hasSetAccommodations = 'accommodations' in onboardingData
 
  const profileVisited = onboardingData?.visitedProfile


  const confirmStudent = async () => {
    await confirmRole(courseId, userId, "confirmedStudent")
    navigate(`/${courseId}`);
  };

  // @Chris TODO
  const slOnboardingOpen = true;
  return (
    <div>
      <div className="container container-narrow mt-2">
        <div className="row">
          <div className="col mt-4 ">
            {!slOnboardingOpen ? (
              <OnboardingClosed />
            ) : (
              <div className="container container-narrow">
                <ApplicationStatus
                  codeOfConductDone={agreementDone}
                  sectionTimeDone={sectionTimeDone}
                  confirmedStudent={confirmedStudent}
                />
                {confirmedStudent && (
                  <p className="subtleHeading">
                    Welcome back. If you need to change any of your preferences,
                    you can do so here until noon on Sunday April 21st, 2024 <a href="https://time.is/PT">California Time</a>.
                  </p>
                )}

                <TodoStep
                  text="Committing to the Class"
                  successCond={agreementDone}
                  icon={FaScroll}
                  href={`/${courseId}/onboarding/student/committing`}
                />

                <TodoStep
                  text="Choose your section time"
                  successCond={sectionTimeDone}
                  icon={FaClock}
                  href={`/${courseId}/onboarding/student/schedule`}
                />

                <TodoStep
                  text="Section Accommodations"
                  successCond={hasSetAccommodations}
                  icon={FaInfoCircle}
                  href={`/${courseId}/onboarding/student/accommodations`}
                />

                <TodoStep
                  text="Fill in your user profile"
                  successCond={profileVisited}
                  icon={FaUser}
                  href={`/${courseId}/onboarding/student/profile`}
                  onClick={()=>{editOnboarding({visitedProfile:true})}}
                />

                <div className="d-flex align-items-center flex-column mt-4 mb-5">
                  {!confirmedStudent && (
                    <p className="text-secondary">
                      When you click this button, you become a Code in Place student.
                    </p>
                  )}
                  <Button
                    onClick={() => confirmStudent()}
                    size="lg"
                    disabled={
                      !agreementDone || !sectionTimeDone
                    }
                  >
                    {confirmedStudent
                      ? "Enter Course"
                      : "Become a Student"}
                    {/* {getFinishBtnText(confirmedStudent)} */}
                  </Button>
                  <hr />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardingClosed = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 mb-4">
      <h1 className="mb-3" style={{ margin: "auto", textAlign: "center" }}>
        We are no longer accepting new Students.
      </h1>
      <h3 style={{ margin: "auto" }}>We're sorry we missed you!</h3>
    </div>
  );
};


const ApplicationStatus = ({
  codeOfConductDone,
  sectionTimeDone,
  confirmedStudent,
}) => {
  if (confirmedStudent) {
    return <div />;
  }

  if (!sectionTimeDone) {
    return <ApplicationMissingSectionTime />;
  }

  if (!codeOfConductDone) {
    return <ApplicationMissingCommitting />;
  }

  return (
    <div className="alert alert-success" role="alert">
      <b>Onboarding complete!</b> Click 'Become a Student' below to make it
      official.
    </div>
  );
};

const ApplicationMissingSectionTime = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> Not submitted yet. Please choose a section time.
    </div>
  );
};


const ApplicationMissingCommitting = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> Almost there! Please commit to the class.
    </div>
  );
};

const ApplicationMissingDemographics = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> So close!. Please fill out the demographics form.
    </div>
  );
};

const getFinishBtnText = function (confirmedStudent) {
  if (confirmedStudent) {
    return "Enter Course";
  } else {
    return "Generate My Section";
  }
};

export const validateFormatting = (branch, output, capturedVars) => {
    let re = RegExp(branch.regex);
    const captured = output.match(re);
    console.log(captured)
    console.log(branch.vars)

    // Also make sure that captured variables (len - 1) is what we expect
    console.log(captured, branch.vars)
    if(captured && captured.length - 1 === branch.vars.length) {
      console.log(`Pass 1: Found valid regex for branch ${branch.name}`);
      
      // Assign captured vars to their name
      branch.vars.forEach((rv, i) => {
        capturedVars[rv.name] = parseInt(captured[i + 1]);
      });
    } else {
      console.log(`Pass 1: Failed formatting at branch ${branch.name}`);
      return {testsRun: true, testResults: [{isSuccess: false, name: "Random Formatting", usedAi: false, rawResult: {output: output}}]};
    }
}

export const validateVariables = (branch, capturedVars) => {
    const valTokens = branch.validation.split(',');

    if(valTokens[0] === "sum") {
    // ! For now, assuming 3 operands where op1 + op2 = op3
    // Names of operands match names of variables
    const [op1, op2, op3] = valTokens.slice(1);
    console.log("Pass 2: Captured Vars")
    console.log(capturedVars);
    if(capturedVars[op1] + capturedVars[op2] != capturedVars[op3]) {
        console.log(`Pass 2: Failed input validation ${branch.validation}`);
        return {testsRun: true, testResults: [{isSuccess: false, name: "Random Validation", usedAi: false, rawResult: {condition: branch.validation}}]};
    }

    console.log(`Pass 2: Passed validation condition ${branch.validation}!`);
    }
}

export const computeRanges = async(branch, pyodideClient, code, maxTrials, randomVars) => {
    // Run for max trials, and add outputs of each random var
    for(let i = 0; i < maxTrials; i++) {
        const rawResult = await pyodideClient.testCode(code, branch.pyodideInput, { name: "main.py" });
    
        // Extract variables
        const outputJoined = rawResult.output.join("\n");
    
        let re = RegExp(branch.regex);
        const captured = outputJoined.match(re);
          
        // Also make sure that captured variables (len - 1) is what we expect
        console.log("IN max trials", captured && captured.length - 1 === randomVars.length)
        if(captured && captured.length - 1 === randomVars.length) {
          // Append random variables to outputs
          randomVars.forEach((rv, i) => {
            console.log(rv.isRandom, "IS RANDOM? ")
            if(rv.isRandom) {

              rv.outputs.push(parseInt(captured[i + 1]));
            }
          });
        } else {
          console.log(`Pass 3: Failed formatting at branch ${branch.name}`);
          return {testsRun: true, testResults: [{isSuccess: false, name: "Random Formatting", usedAi: false, rawResult: {output: outputJoined}}]};
        }
    }
}

export const checkRanges = (randomVars) => {
    // Check ranges for variables
    let allCorrect = true;
    let ranges = {incorrect: [], all: []};

    randomVars.forEach(rv => {
      console.log(rv)
      if(!rv.isRandom) return;

      const observedMin = Math.min(...rv.outputs);
      const observedMax = Math.max(...rv.outputs);

      ranges.all.push({min: observedMin, max: observedMax});

      if(observedMin !== rv.min || observedMax !== rv.max) {
        allCorrect = false;
        ranges.incorrect.push({min: observedMin, max: observedMax});
      }
    });

    return {allCorrect, ranges};
}

export const computeStats = (branch, varMap) => {
    if(!varMap.isRandom) return varMap;

    const numValues = varMap.max - varMap.min + 1;
    const p = 1 / numValues;
    const q = 1 - p;

    const numTrials = Math.ceil(Math.log(branch.tolerance) / Math.log(q));

    return {...varMap, numValues, p, q, numTrials, outputs: []};
}
import { doc, getDoc, setDoc, getFirestore, deleteDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";




export const usePublishedProjectMetrics = (projectId, userId, courseId) => {
    const [isLiked, setIsLiked] = useState(false);
    const db = getFirestore()

    const userLikedDocRef = !!(userId) ? doc(db, "users", userId, courseId, "liked", "projects", projectId): null;




    const fetchDidUserLike = async () => {
        if(!userId) return;
        const userLikedDoc = await getDoc(userLikedDocRef);
        if (userLikedDoc.exists()) {
            setIsLiked(true);
        }
    }

    const unlikeProject = async () => {
        if(!userId) return;
        try {
            await deleteDoc(userLikedDocRef);
            setIsLiked(false);
        } catch (error) { }
    }

    const likeProject = async () => {
        if(!userId) return;
        try {
            await setDoc(userLikedDocRef, {
                liked: true
            });
            setIsLiked(true);
        } catch (error) { }
    }

    useEffect(() => {
        fetchDidUserLike();
    }, [])

    return { isLiked, unlikeProject, likeProject } 
}


export const onRun = async (projectId, courseId) => {
    const functions = getFunctions();
    const updateRunCount = httpsCallable(functions, "updateRunCount");
    await updateRunCount({ projectId, courseId });
}
    

    

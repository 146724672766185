import { Spin } from "antd";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useEffect, useState } from "react";

const CenterAlignedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const Text = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  color: black;
`;

export const Thinking = (props: { interval: number }) => {
  const { interval } = props;

  const [showThinking, setShowThinking] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowThinking(true);
    }, interval * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [interval]);

  return (
    <CenterAlignedDiv>
      <Spin size="large" spinning={true} style={{ paddingRight: "15px" }} />
      {showThinking && (
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: "auto", opacity: 100 }}
          transition={{
            ease: "easeIn",
            duration: 0.7,
          }}
        >
          <Text>Thinking...</Text>
        </motion.div>
      )}
    </CenterAlignedDiv>
  );
};

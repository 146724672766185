// react component which has two bootstrap dropdowns

import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { ReviewButtonBar } from "components/richTextEditor/TipTap/buttonbars/ReviewButtonBar"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useContext, useEffect } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { FaCheck, FaInfoCircle } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { ReviewContext } from "review/contexts/ReviewContext"
import { instructionURL } from "review/contexts/ReviewUtil"

export const GradingPane = () => {
  const graderId = useUserId()
  const userId = useParams().userId
  const courseId = useCourseId()
  

  const path = `course/${courseId}/slReview/${userId}/ratings/${graderId}`
  const docRef = doc(getFirestore(), path)

  const [ratings, ratingsLoading, ratingsError] = useDocumentData(docRef)

  if(ratingsLoading){
    return <></>
  }

  if(ratingsError){
    return <>Error loading ratings, please refresh</>
  }

  return <GradingPaneView key={userId} userId={userId} ratings={ratings}/>
}

const GradingPaneView = ({userId, ratings}) => {
  const {secondReviewRequests} = useContext(ReviewContext)
  const graderId = useUserId()
  const courseId = useCourseId()
  const staffNotesPath = `course/${courseId}/slReview/${userId}/docs/staffNotes`
  const applicantNotesPath = `course/${courseId}/slReview/${userId}/docs/applicantNotes`

  const secondReviewRequested = userId in secondReviewRequests && secondReviewRequests[userId]

  const saveField = (key, newValue) => {
    const path = `course/${courseId}/slReview/${userId}/ratings/${graderId}`
    const docRef = doc(getFirestore(), path)
    setDoc(docRef, 
      {[key]: newValue}
    , { merge: true });
  }

  const saveSecondReviewRequested = (newValue) => {
    const path = `/course/${courseId}/slReview/secondReview`
    const docRef = doc(getFirestore(), path)
    setDoc(docRef, 
      {[userId]: newValue}
    , { merge: true });
  }

  useEffect(() => {
    // turn on the grading lock for this student
    if(!ratings || !ratings.overall || ratings.overall === 'Released'){
      saveField('overall', 'Select')
    }
  }, [])

    
  const releaseLock = () => {
    if(ratings.overall === 'Select'){
      saveField('overall', 'Released')
    }
  }

  const keys = ['overall', 'python', 'teaching', 'atmosphere']

  const isComplete = () => {
    if(!ratings) {
      return false
    }
    for (const key of keys) {
      if (!ratings[key] || ratings[key] === 'Select' || ratings[key] === 'Released') {
        return false
      }
    }
    return true
  }
  

  

  const complete = isComplete()

  return <div className="p-3">
    <h3>Review {complete && <FaCheck className="text-success"/>}</h3>
    <a href={instructionURL} target="_blank"><FaInfoCircle/> Instructions for applicant review</a>
    <hr/>
    <b>Overall Evaluation:</b>
    <GradingDropdown 
      variant="primary"
      selectedOption={ratings?.overall}
      options={['Strong Accept', 'Some Handholding', 'Heavy Handholding', 'Do Not Hire']}
      onUpdate={(option) => saveField('overall', option)}
    />
    <Form.Check
        type="checkbox"
        label="Needs extra review"
        checked={secondReviewRequested}
        onChange={() => {saveSecondReviewRequested(!secondReviewRequested)}}
      />
    <br/>

    <b>Python Ability:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.python}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('python',option)}
    />
    <br/>

    <b>Teaching Ability:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.teaching}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('teaching',option)}
    />
    <br/>

    <b>Atmosphere:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.atmosphere}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('atmosphere',option)}
    />
    <br/>

    <b>Notes for course staff:</b>
    <TipTap
      firebaseDocPath = {staffNotesPath}
      editable = {true}
      collaborative = {true}
      buttonBar = {ReviewButtonBar}
    />
    <br/>

<b>Feedback for applicant (be kind):</b>
    <TipTap
      firebaseDocPath = {applicantNotesPath}
      editable = {true}
      collaborative = {true}
      buttonBar = {ReviewButtonBar}
    />

      

    {
      ratings?.overall === 'Select' &&
      <button onClick={()=>{releaseLock()}}className="btn btn-primary mt-3">Release Grading Lock</button>
    }
  </div>
}



export const GradingDropdown = ({ variant, selectedOption, options, onUpdate }) => {

  const handleOptionSelect = (option) => {
    onUpdate(option);
  };

  const dropdownVariant = variant || 'secondary';

  const isCleared = !selectedOption || selectedOption === 'Released' || selectedOption === 'Select';
  const label = isCleared ? 'Select' : selectedOption;

  return (
    <Dropdown >
      <Dropdown.Toggle variant={dropdownVariant} id="dropdown-basic">
        {label}
      </Dropdown.Toggle>

      <Dropdown.Menu >
          <Dropdown.Item
            key={'Select'}
            onClick={() => handleOptionSelect('Select')}
          >
            Select
          </Dropdown.Item>
          {/* hr */}
          <Dropdown.Divider />
        {options.map((option) => (
          <Dropdown.Item
            key={option}
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

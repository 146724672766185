import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import StudentCommittingForm from "./StudentCommittingForm";
import { useNavigate } from "react-router";
import { ProfileContext } from "contexts/ProfileContext";
import { StudentOnboardingContext } from "onboarding/studentOnboarding/contexts/StudentOnboardingContext";

export const StudentCommitting = () => {
  const navigate = useNavigate()
  const courseId = useCourseId()
  const {userData} = useContext(ProfileContext)
  const {onboardingData,editOnboarding} = useContext(StudentOnboardingContext)
  console.log(userData)
  const displayName = userData?.displayName || "Student"
  const agreementData = onboardingData?.agreementData || {}



  const onSubmit = async (values)=> {
    const newData = {...values}
    newData.signed = values?.signature1 === displayName
    await editOnboarding({
      agreementData:newData
    })
    if (courseId === 'cs49-f24') {
      navigate(`/${courseId}/studenthome`)
      return
    }
    navigate(`/${courseId}/onboarding/student`)

  }

    return <div
        style={{
          width: "720px",
          maxWidth: "90vw",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Card style={{ margin: "auto", padding: "20px" }}>
          <div>
            <StudentCommittingForm
              displayName={displayName}
              agreementData={agreementData}
              onSubmit={onSubmit}
              courseId = {courseId}
            />
            
          </div>
        </Card>
      </div>
}


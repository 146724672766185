import { useState, useEffect } from "react";
import { getDemoVideoStoragePath } from "./uploadVideo";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

// WARNING: this will not properly react to a
// change in the userId. You will get the video
// of the original userId  

export const useUploadedVideo = (userId) => {


    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    
    useEffect(() => {
        if(videoUrl) {
            setIsLoading(false)
        }
    }, [videoUrl])

    useEffect(() => {
        if(error) {
            setIsLoading(false)
        }
    }, [error])

    useEffect(() => {
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const path = getDemoVideoStoragePath(userId)
        console.log(path)
        const videoRef = storageRef.child(path);

        videoRef.getDownloadURL().then((url) => {
            setVideoUrl(url)
        }).catch((error) => {
            setError(error)
        })
    }, [videoUrl])
    return [videoUrl, isLoading, error]
}
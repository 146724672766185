import React from "react";

import { Card } from "react-bootstrap";

import { studentAppStructure } from "./AboutForm.js";
import { LandingNav } from "landing/components/LandingNav";
import { FormixFormWithData } from "components/Forms/FormixFormWithData";

export class AboutWithData extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    this.props.setAboutData(values);
  }

  render() {
    const courseId = this.props.courseId;
    return (
      <>
  
  <LandingNav applicationType={"student"} subpage={"About Form"}/>
        <div
          style={{
            width: "720px",
            maxWidth: "90vw",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <Card style={{ margin: "auto", padding: "20px" }}>
            <h1>Tell us about yourself</h1>
            <p>
              We want to get to know you better; tell us a little about
              yourself! We will use the answers in this form to figure out your
              background and determine whether this class is a good fit for you.
            </p>
            <div>
              <FormixFormWithData
                serverData={this.props.aboutData}
                backUrl={this.props.appSplashPath}
                formFormat={studentAppStructure}
                onSubmit={(e, cb) => this.handleSubmit(e)}
              />
            </div>
          </Card>
        </div>
      </>
    );
  }
}


import { useState } from "react";

const StudentReflection = ({
  submittedLesson,
  submittedFeedback,
  onLessonSubmit,
  onFeedbackSubmit,
  hideFeedbackSection = false,
}) => {
  const [lesson, setLesson] = useState(submittedLesson);
  const [feedback, setFeedback] = useState(submittedFeedback);
  const handleLessonChange = (e) => {
    setLesson(e.target.value);
  };
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const handleLessonSubmit = () => {
    onLessonSubmit(lesson);
  };
  const handleFeedbackSubmit = () => {
    onFeedbackSubmit(feedback);
  };

  return (
    <div>
      <h2>Your Personal Reflection</h2>
      <div className="mb-4">
        <p>
          Take a moment to think about this session's content. What is the{" "}
          <strong>most important lesson and/or skill</strong> that you’ll walk
          away from this session? This is a private reflection (we will not share it with your section leaders).
        </p>
        <textarea
          className="form-control"
          value={lesson}
          onChange={handleLessonChange}
        ></textarea>
        <button className="btn btn-primary mt-2" onClick={handleLessonSubmit}>
          Submit
        </button>
      </div>
      {!hideFeedbackSection && (
        <div className="mb-2">
          <h2>Have feedback for your Section Leader?</h2>
          <p>
            Share either positive or constructive feedback here! We’ll pass this
            anonymous feedback to the SL promptly. Keep in mind that your SL is
            a volunteer!
          </p>
          <textarea
            className="form-control"
            value={feedback}
            onChange={handleFeedbackChange}
          ></textarea>
          <button
            className="btn btn-primary mt-2"
            onClick={handleFeedbackSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default StudentReflection;

import { ProfileContext } from "contexts/ProfileContext";
import { useContext } from "react";

export const ProfilePicture = () => {
  const { userData } = useContext(ProfileContext);
  if (!userData) return <></>
  if (!userData.photoURL && !userData.displayName) return <></>
  // if the photoURL is defined, show that image. Otherwise show the initials
  if (userData.photoURL) {
    return <img aria-label="Profile picture" className="fullSizeProfile" style={{ width: 150, height: 150 }} src={userData.photoURL}></img>
  } else {
    return <div aria-label="Profile picture" className="fullSizeProfile" style={{ width: 150, height: 150, backgroundColor: 'salmon', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span style={{ fontSize: '2rem' }}>{userData.displayName[0]}</span>
    </div>
  }
}
import { getCornerSize, getCornerX, getCornerY, isValidRowCol, parseRowColString } from "../util";

export const Paint = ({state}) => {
    const paintData = state.worldState.paint

    // do nothing if beepers are not defined
    if(!paintData) {
        return <></>
    }
    return (
      <div>
        {Object.keys(paintData).map((rowColKey, index) => {
          const [row, col] = parseRowColString(rowColKey)
          if(!isValidRowCol(state,row,col)){
            return <></>
          }
          const color = paintData[rowColKey]
          return <PaintedCorner
            r = {row}
            c = {col}
            color = {color}
            state = {state}
            key = {index}
          />
        })}
      </div>
    );
  }

  const PaintedCorner = ({r,c,color,state})=>{
    let size = getCornerSize(state);
    let offset = (getCornerSize(state) - size) / 2;
    let x = getCornerX(state,r, c) + offset;
    let y = getCornerY(state,r, c) + offset;


    return <div
    className="karel-stone"
    style={{
      width: size,
      height: size,
      marginLeft: x,
      marginTop: y,
      backgroundColor:color
    }}
  >
  </div>
}
import { deepCopy } from "@firebase/util";
import { useEffect, useState } from "react"
import { useComponentSize } from "react-use-size";
import { getCornerSize, isValidWorldState, makeRowColKey, xyToRowCol } from "../../../../pyodide/KarelLib/util";
import { Agent } from "../../../../pyodide/KarelLib/worldComponents/Agent";
import { Beepers } from "../../../../pyodide/KarelLib/worldComponents/Beepers";
import { EditableWalls } from "../../../../pyodide/KarelLib/worldComponents/EditableWalls";
import { GridBackground } from "../../../../pyodide/KarelLib/worldComponents/GridBackgroud";
import { Paint } from "../../../../pyodide/KarelLib/worldComponents/Paint";
import { KarelWorld } from "components/pyodide/KarelLib/KarelWorld";


export const RunnableKarelWorld = ({worldState,isEditable,setWorldState,editType}) => {

    

    if(!worldState){
        return <></>
    }

    function setState(key, value) {
        setWorldState((oldWorldState) => {
            const newWorldState = {...oldWorldState};
            newWorldState[key] = value
            if(isValidWorldState(newWorldState)){
                return newWorldState
            } else {
                return oldWorldState
            }
        })
    }

    function rowColClick(row, col){
        if(editType == 'moveKarel') {
            const change = row != worldState.karelRow || col != worldState.karelCol
            if(change){
                setWorldState((oldWorldState) => {
                    const newWorldState = {...oldWorldState};
                    newWorldState['karelRow'] = row
                    newWorldState['karelCol'] = col
                    return newWorldState
                })
            } else {
                setState('karelDir', rotateLeft(worldState.karelDir))
            }
        }
        if(editType == 'addBeeper') {
            editBeepers(setWorldState, row, col, +1)
        }
        if(editType == 'removeBeeper') {
            editBeepers(setWorldState, row, col, -1)
        }
    }

    if(!isEditable) {
        return <div className="d-flex flex-row w-100" style={{width:300}}>
            <KarelWorld worldState={worldState} />
            </div>
    }

    return <div className="d-flex flex-row" style={{width:300}}>
        <EditableCanvas 
            rowColClick={rowColClick}
            worldState={worldState}
            setWorldState={setWorldState}
        />
    </div>
}




const EditableCanvas = ({rowColClick, worldState, setWorldState}) => {

    useEffect(()=> {
    }, [worldState])

    const canvasState = useComponentSize();
    const canvasRef = canvasState.ref

    const [worldHeight, setWorldHeight] = useState(0)

    // react to change in the dimension of the "canvas"
    useEffect(() => {
        let cornerSize = getCornerSize(state)
        setWorldHeight(worldState.nRows * cornerSize) 
    }, [canvasState])

    // package the state into a variable
    const state = {
        // worldState is karel relevant variables. This is currently
        // a fixed example :-)
        worldState,
        // canvas state are {width, height, ref} of the canvas
        canvasState
    }

    function onClick(e) {
        var rect = canvasRef.current.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.
        const [row, col] = xyToRowCol(state, x, y)
        rowColClick(row, col)
    }

    if(!isValidWorldState(worldState)) return <>Invalid Karel state</>

    return <div 
        onClick= {(e) => onClick(e)}
        ref={canvasRef} 
        className="w-100 karel-editable-canvas" 
        style={{
            height:worldHeight, 
            backgroundColor:'white', 
            position:'relative',
            cursor:'pointer',
            userSelect: 'none'
        }}
        >
        <GridBackground state={state}/>
        <Paint state={state}/>
        <EditableWalls state={state} setWorldState={setWorldState}/>
        <Beepers state={state}/>
        <Agent state={state} />
    </div>
}

const IntInput = ({value, setValue, label}) => {
    return <>
        <div className="input-group input-group-sm">
            {/* <span className="input-group-text" id="inputGroup-sizing-sm">{label}</span> */}
            <input onChange={(e) => setValue(Number(e.target.value))}value = {value} type="number" min={1} max={100} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
        </div>
    </>
}

function rotateLeft(dir) {
    switch(dir){
        case "East": return "North"
        case "North": return "West"
        case "West": return "South"
        case "South": return "East"
        default: return "East"
    }
}

function editBeepers(setWorldState, row, col, delta){
    const rowColKey = makeRowColKey(row, col)
    setWorldState((oldWorldState) => {
        const newWorldState = deepCopy(oldWorldState)
        // careful about getting the new beepers state
        if(!("beepers" in newWorldState)){
            newWorldState["beepers"] = {}
        }
        const beepers = newWorldState["beepers"]
        const oldCount = (rowColKey in beepers) ? beepers[rowColKey] :0
        const newCount = oldCount + delta
        if(newCount >= 0) {
            beepers[rowColKey] = newCount
        } 
        
        return newWorldState
    })
}

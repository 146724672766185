import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { EditorRow } from "./EditorRow.jsx"
import { LessonsContext } from 'course/contexts/LessonsContext';
import { TrainingContext } from 'course/contexts/TrainingContext'
import Swal from 'sweetalert2';





export const AccordionBody = ({ lessonId }) => {
  const {editorType} = useParams()
  const { onSlidesOrderChange, deleteSlide, lessonsMap } = useContext(editorType == 'student' ? LessonsContext : TrainingContext)
  const slides = lessonsMap[lessonId]?.slides || []
  const slidesInfo = lessonsMap[lessonId]?.slidesInfo
  const [slidesList, setSlidesList] = useState(slides)

  // Reset keys if map or id change
  useEffect(() => {
    const slides = lessonsMap[lessonId]?.slides || []
    setSlidesList(slides)
  }, [lessonsMap, lessonId]);


  // Sensors for dragging
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100, // delay in ms
        tolerance: 3 // minimum movement to start the drag operation
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );



  function handleDragEnd(event) {
    // if its a published lesson, we want to be careful about reordering
    const isPublished = lessonsMap[lessonId].publish
    if (isPublished) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This lesson is published. Changing the order of slides will affect students. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes! Change Order',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          changeOrder(event)
        }
      })
    } else {
      // if its not published, no worries.
      changeOrder(event)
    }
  }

  function changeOrder(event) {


    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setSlidesList((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newList = arrayMove(items, oldIndex, newIndex);
        onSlidesOrderChange(newList, lessonId)
        return newList
      });
    }
  }


  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={
            Object.keys(slidesList)
          }
          strategy={verticalListSortingStrategy}
        >
          {
            slidesList.map((slideId) => {
              const slideData = slidesInfo[slideId]
              return <EditorRow
                key={slideId}
                type={slideData?.type}
                title={slideData?.title}
                docId={slideId}
                id={slideId}
                deleteItem={deleteSlide}
              />
            })
          }

        </SortableContext>
      </DndContext>
    </div>
  )
}

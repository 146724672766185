import { collection, doc, getDocs, getFirestore, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";



const defaultData = {
    meetingsMap: {},
    addMeetingToDB: (eventTitle, eventKey, zoomLink, recordingLink, length, start , minimumRole) => {},
    deleteMeetingFromDB: (eventKey) => {},
    updateMeetingInDB: (eventKey, eventEdits) => {},
  };

export const MeetingsContext = React.createContext(defaultData);

export const MeetingsProvider = ({ courseId, children }) => {
    const [meetingsMap, setMeetingsList] = useState({})
    const db = getFirestore()

    //get current meetings
    const meetingsDocsRef = collection(db, `course/${courseId}/events`);
    useEffect(() => {
        const fillMeetingsList = async() => {
          const meetingsData = {}
          const meetingsSnapshot = await getDocs(meetingsDocsRef);
          meetingsSnapshot.forEach((doc) => {
            meetingsData[doc.id] =doc.data()
          });
          setMeetingsList(meetingsData)
          console.log(meetingsData)
        }

        fillMeetingsList()    
    }, [])

    const addMeetingToDB = async (eventTitle, eventKey, zoomLink, recordingLink, length, date, minimumRole) => {
      const newMeetingData = {
          title: eventTitle,
          zoomLink,
          recording: recordingLink,
          start: date,
          length,
          minimumRole
      }
      await setDoc(doc(db, `course/${courseId}/events/${eventKey}`), newMeetingData);
      setMeetingsList((prev) => {
          return {
              ...prev,
              [eventKey]: newMeetingData
          }
      })
    }

    const updateMeetingInDB = async (eventKey, eventEdits) => {
      const newMeetingData = {...meetingsMap[eventKey], ... eventEdits}
      await updateDoc(doc(db, `course/${courseId}/events/${eventKey}`), eventEdits);
      
      setMeetingsList((prev) => {
          return {
              ...prev,
              [eventKey]: newMeetingData
          }
      })

    }

    const deleteMeetingFromDB = async (meetingId) => {
        const meetingRef = doc(db, `course/${courseId}/events/${meetingId}`)
        await deleteDoc(meetingRef)
        setMeetingsList((prev) => {
            const newMeetings = {...prev}
            delete newMeetings[meetingId]
            return newMeetings
        })
        /*const batch = writeBatch(db)
    
        for(var itemId of Object.keys(lessonsMap[lessonId].items)) {
          batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}`))
          if(typesWithDescriptions.includes(lessonsMap[lessonId].items[itemId].type)) {
            batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}-description`))
          }
        }
        batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`))
        batch.commit()*/
        return
    }

    return (
        <MeetingsContext.Provider
          value={{
           meetingsMap,
           addMeetingToDB,
           deleteMeetingFromDB,
           updateMeetingInDB
          }}
        >
          {children}
        </MeetingsContext.Provider>
    )
}

import { doc, getFirestore, updateDoc, deleteDoc } from "firebase/firestore";
import "react-folder-tree/dist/style.css";
import Tree from "../../components/Tree/Tree";
import { removeProps, createCodeFile } from "../utils/general";

const FileTree = ({
  projectId,
  fileStructure,
  setFileStructure,
  onChangeFile,
  currFile,
  isMobile = false,
  switchToCodeTab = () => {},
  isCreative,
  onFileCodeUpdate
}) => {
  const onUpdate = async (state) => {
    const { currentStructure, lastAction } = state;
    // Update File Structure
    removeProps(currentStructure, ["parentNode"]);
    const db = getFirestore();
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, {
      files: currentStructure,
    });
    setFileStructure(currentStructure);

    // Create New File
    if (lastAction.type === "FILE_CREATE" && lastAction.payload) {
      const docDocRef = doc(
        db,
        `/projects/${projectId}/code`,
        lastAction.payload.id
      );
      await createCodeFile(docDocRef, lastAction.payload.content);
      onFileCodeUpdate(lastAction.payload.id, lastAction.payload.content)

      onChangeFile(lastAction.payload);
    }

    // File Deletion
    if (lastAction.type === "FILE_DELETE" && lastAction.payload) {
      if (currFile?.id === lastAction.payload.id) {
        onChangeFile(null);
      }
    }

    // File Editing
    if (lastAction.type === "FILE_EDIT" && lastAction.payload) {
      onChangeFile(lastAction.payload);
    }

    // TODO: Folder Deletion
  };

  const onClick = (node) => {
    if (node.node.type === "file") {
      onChangeFile({
        id: node.node.id,
        url: node.node.url ?? null,
        format: node.node.format ?? null,
        name: node.node.name,
      });

      if (isMobile) {
        switchToCodeTab();
      }
    }
  };

  return (
    <Tree
      fileStructure={fileStructure}
      onUpdate={onUpdate}
      onNodeClick={onClick}
      onChangeFile={onChangeFile}
      currFile={currFile}
      projectId={projectId}
      fullControl={isCreative}
    />
  );
};

export default FileTree;

import { getApp, initializeApp } from "firebase/app";
import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  getFirestore,
  doc,
} from "firebase/firestore";
import { useContext } from "react";
import { ForumContext } from "../ForumContext";
import { Post, Reply, DraftPost } from "./ForumDataModels";

import { getUserDocumentRef } from "../../../firebase/models";
import { useCourseId } from "hooks/router/useUrlParams";
import { NOTIFICATION_CONVERTER } from "course/components/notification/Types";

// Converts data to and from firestore. 


const POST_CONVERTER: FirestoreDataConverter<Post> = {
  toFirestore: (post: Post): DocumentData => post,
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Post {
    const data = snapshot.data(options);
    return {
      author: data.author,
      authorUid: data.authorUid,
      id: snapshot.id,
      title: data.title,
      time: data.time.toDate(),
      contents: data.contents,
      isDraft: data.isDraft,
      isPrivate: data.isPrivate ?? false,
      isAnonymous: data.isAnonymous ?? false,
      tags: data.tags,
      isResolved: data.isResolved,
      isPinned: data.isPinned,
      likedBy: data.likedBy,
      isFlagged: data.isFlagged
    };
  },
};



const REPLY_CONVERTER: FirestoreDataConverter<Reply> = {
  toFirestore: (reply: Reply): DocumentData => reply,
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Reply {
    const data = snapshot.data(options);

    return {
      author: data.author,
      authorUid: data.authorUid,
      parent: data.parent,
      id: snapshot.id,
      time: data.time.toDate(),
      contents: data.contents,
      isDraft: data.isDraft,
      likedBy: data.likedBy,
      postId: data.postId,
      isFlagged: data.isFlagged,
    };
  },
};


export const DRAFT_CONVERTER: FirestoreDataConverter<DraftPost> = {
  toFirestore: (post: DraftPost): DocumentData => post,
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): DraftPost {
    const data = snapshot.data(options);
    return {
      author: data.author,
      authorUid: data.authorUid,
      id: snapshot.id,
      title: data.title,
      time: data.time.toDate(),
      contents: data.contents,
      isPrivate: data.isPrivate ?? false,
      tags: data.tags,
      isResolved: data.isResolved,
      isPinned: data.isPinned,
      isAnonymous: data.isAnonymous,
      isFlagged: data.isFlagged,
      isPosted: data.isPosted,
    };
  },
};


export class ForumDataFetcher {
  _courseId: string;
  _forumId: string;

  constructor(courseId: string, forumId: string) {
    this._courseId = courseId;
    this._forumId = forumId;
  }

  get courseId() {
    return this._courseId;
  }

  get forumId() {
    return this._forumId;
  }

  get_collection_ref = <T>(
    collectionName: string,
    converter: FirestoreDataConverter<T>,
    sharedCollection: boolean = false
  ) => {
    if (sharedCollection) {
      return collection(getFirestore(getApp()), collectionName).withConverter(
        converter
      );
    }



    return collection(
      getFirestore(getApp()),
      "forumData",
      this.courseId,
      "forums",
      this.forumId,
      collectionName
    ).withConverter(converter);
  };


  // Get Drafts
  get_draft_collection_ref = () => this.get_collection_ref("drafts", DRAFT_CONVERTER);
  get_draft_doc_ref = (id: string) => doc(this.get_draft_collection_ref(), id);


  // Get Posts
  get_post_collection_ref = () => this.get_collection_ref("posts", POST_CONVERTER);
  get_post_doc_ref = (id: string) => doc(this.get_post_collection_ref(), id);

  // Get Replies
  get_reply_collection_ref = () => this.get_collection_ref("replies", REPLY_CONVERTER);
  get_reply_doc_ref = (id: string) => doc(this.get_reply_collection_ref(), id);

  // Get TipTap Docs
  get_draft_tiptap_collection_ref = () => this.get_collection_ref("draftTipTapDocs", DRAFT_CONVERTER)
  get_draft_tiptap_doc_ref = (id: string) => doc(this.get_draft_tiptap_collection_ref(), id)


  get_draft_tiptap_doc_path = (uid: string) => {
    return `forumData/${this.courseId}/forums/${this.forumId}/draftTipTapDocs/${uid}`;


  }


  get_tiptap_doc_ref = (uid: string) =>
    doc(
      collection(
        getFirestore(getApp()),
        "forumData",
        this.courseId,
        "forums",
        this.forumId,
        "tipTapDocs"
      ),
      uid
    );

  get_tiptap_doc_path = (uid: string) => {
    return `forumData/${this.courseId}/forums/${this.forumId}/tipTapDocs/${uid}`;
  };


  get_user_data_ref = (uid: string) => getUserDocumentRef(uid);

  get_user_role_ref = (uid: string, courseId: string) => {
    return doc(getUserDocumentRef(uid), "roles", courseId);
  };

  get_user_notification_collection_ref = (uid: string) => {
    return this.get_collection_ref(
      `notifications/${uid}/userNotifications`,
      NOTIFICATION_CONVERTER
    );
  };
}

export function useForumDataFetcher() {
  const { forumId } = useContext(ForumContext);
  const courseId = useCourseId();
  return new ForumDataFetcher(courseId, forumId);
}






export const useCertificateCompletion = (
    certificateFormat,
    assnProgress,
    sectionAttendance,
    finalProjectDoc,
    diagnosticDoc,
    sessionInfo,
    publicAssnProgressDoc={},
) => {
    let isComplete = false
    let certificateCompletionMap = {...certificateFormat}

    if(!certificateFormat){
        return [certificateCompletionMap, isComplete]
    }
    isComplete = true;

    const badgeToDocMap = {
        "diagnostic": diagnosticDoc,
        "finalproject": finalProjectDoc,
        "sectionattendance": sectionAttendance,
        "teachnow": sessionInfo
    }


    if("units" in certificateFormat){
        const units = certificateFormat['units']
        // enumerate with idx 
        const totalAssnProgress = { ...publicAssnProgressDoc, ...assnProgress }
        for (let i = 0; i < units.length; i++) {
            const unit = units[i]
            if(!totalAssnProgress) {
                certificateCompletionMap["units"][i] = {
                    ...unit,
                    "isComplete": false
                }
                continue
            }
            let { moduleComplete, missedAssns } = checkModuleComplete(totalAssnProgress, unit['reqAssns'])
            isComplete = isComplete && moduleComplete
            certificateCompletionMap["units"][i] = {
                ...unit,
                "isComplete": moduleComplete,
                "missedAssns": missedAssns
            }
        }
    }


    if ("badges" in certificateFormat) {
        const badges = certificateFormat['badges']
        for(let i = 0; i < badges.length; i++){
            const badge = badges[i]
            const badgeOpts =  "options" in badge ?  badge['options']: {}
            const badgeType = badge['type']
            const badgeDoc = badgeToDocMap[badgeType]
            if(!badgeDoc){
                certificateCompletionMap["badges"][i] = {
                    ...badge,
                    "isComplete": false
                }
                continue
            }
            

            const isBadgeComplete = getBadgeCompletion(badgeType, badgeDoc, badgeOpts, sessionInfo)
            certificateCompletionMap["badges"][i] = {
                ...badge,
                "isComplete": isBadgeComplete
            }
        }
    }

    if("attendance" in certificateFormat){
        const  { minimumRequired, includeBadges, type } = certificateFormat["attendance"]
        if(minimumRequired) {
            const badges = certificateCompletionMap['badges']
            const attendanceComplete = checkAttendanceComplete(minimumRequired, sectionAttendance, {
                includeBadges,
                badgeCount: badges.filter(badge => badge.isComplete ).length
            })
            isComplete = isComplete && attendanceComplete
            certificateCompletionMap["attendance"] = {
                ...certificateFormat["attendance"],
                "isComplete": attendanceComplete
            }
        
        }

    }


    return [certificateCompletionMap, isComplete];

    
}




const checkModuleComplete = (assnProgress, reqAssns, publicAssnProgressDoc=undefined) => {
    let moduleComplete = true
    let missedAssns = []
    for(let assn of reqAssns){
        if (!(assn in assnProgress && assnProgress[assn])) {
            if(publicAssnProgressDoc && assn in publicAssnProgressDoc && publicAssnProgressDoc[assn]){
                // If the student has done it in the public course, it still counts
                continue
            }
            moduleComplete = false
            missedAssns.push(assn)
        }

    }

    return { moduleComplete, missedAssns }
}



const checkAttendanceComplete = (attendanceMin, attendanceDoc, options={}) => {
    if (!attendanceDoc) {
        return false
    }
    const sectionWeeks = Object.keys(attendanceDoc)
    const { includeBadges, badgeCount } = options
    let attendanceCount = 0
    
    for(let week of sectionWeeks){
        if(attendanceDoc[week]){
            attendanceCount++
        }
    }

    if(includeBadges){
        attendanceCount += badgeCount
    }

    return attendanceCount >= attendanceMin;
}


const getBadgeCompletion = (badgeType, badgeDoc, options={}, sessionInfo) => {
    switch(badgeType){
        case "diagnostic":
            return "hasStarted" in badgeDoc && badgeDoc["hasStarted"]
        case "finalproject":
            return badgeDoc && badgeDoc.exists()
        case "sectionattendance":
            if ("attendanceMin" in options) {
                return checkAttendanceComplete(options["attendanceMin"], badgeDoc)
            }
            return false
        case "teachnow":
            if (sessionInfo && sessionInfo.length >= 5) {
                return true
            }
            return false
        default:
            return false

    }
}
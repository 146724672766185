import React from 'react';

const VideoFeedbackOptions = ({ tone, setTone, content, setContent }) => (
    <div>
      <div className="mb-3">
        <label htmlFor="tone" className="form-label" style={{fontSize: '1rem'}}>
          Tone:
        </label>
        <select 
          id="tone"
          className="form-select"
          value={tone}
          onChange={(e) => setTone(e.target.value)}
        >
          <option value="" disabled>Select score</option>
          {[
            'Not welcoming/Respectful', 'Neutral', 'Welcoming/Respectful'
          ].map((score, index) => (
            <option key={index} value={score}>{score}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="content" className="form-label" style={{fontSize: '1rem'}}>
          Content / What They Said:
        </label>
        <select 
          id="content"
          className="form-select"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        >
          <option value="" disabled>Select score</option>
          {[
            'Way off script', 'Neutral', 'Hit all points'
          ].map((score, index) => (
            <option key={index} value={score}>{score}</option>
          ))}
        </select>
      </div>
    </div>
  );

export default VideoFeedbackOptions;

import React, { useContext } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { LessonsContext } from 'course/contexts/LessonsContext';
import { TrainingContext } from 'course/contexts/TrainingContext'
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useCourseId } from "../../../../hooks/router/useUrlParams";
import { getDoubleValue } from 'course/editors/OnEventAlerts';


export const ResourcesTable = ({
  lessonId,
  resourceKey,
  valueKey,
  icon
}) => {
  const { editorType } = useParams()
  const { lessonsMap } = useContext(editorType == 'student' ? LessonsContext : TrainingContext)
  // make this safe if lessonsMap is undefined or lessonId is not a key
  if (!lessonsMap || !lessonsMap[lessonId]) return <></>
  const resourceList = lessonsMap[lessonId][resourceKey]

  if(!resourceList) return <></>

  const titlesList = resourceList.map(resource => resource.title)
  const { docId } = useParams()
  const courseId = useCourseId()
  const db = getFirestore()
  const lessonRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)

  // Sensors for dragging
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    // if its a published lesson, we want to be careful about reordering
    const isPublished = lessonsMap[lessonId].publish
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = titlesList.indexOf(active.id);
      const newIndex = titlesList.indexOf(over.id);
      if (oldIndex === newIndex) return
      const newList = arrayMove(resourceList, oldIndex, newIndex);
      const update = {}
      update[resourceKey] = newList
      if (isPublished) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'This lesson is published. Changing the order of slides will affect students. Are you sure you want to continue?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes! Change Order',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            updateDoc(lessonRef, update)
          }
        })
      } else {
        // if its not published, no worries.
        updateDoc(lessonRef, update)
      }
    }
  }

  function deleteResource(index) {
    const newList = [...resourceList]
    newList.splice(index, 1)
    const update = {}
    update[resourceKey] = newList
    updateDoc(lessonRef, update)
  }

  async function editResource(index) {
    const { title} = resourceList[index]
    const oldValue = resourceList[index][valueKey]
    const db = getFirestore()
    const newValues = await getDoubleValue('Edit Reading', 'Title', valueKey, false, title, oldValue)
    if (!newValues) return
    console.log(newValues)
    const newTitle = newValues.item1
    const newValue = newValues.item2
    const lessonRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
    const newResourceList = [...resourceList]
    newResourceList[index] = { title: newTitle}
    newResourceList[index][valueKey] = newValue
    const update = {}
    update[resourceKey] = newResourceList
    updateDoc(lessonRef, update)
  }

  if (!resourceList) return <></>

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={titlesList}
          strategy={verticalListSortingStrategy}
        >
          {
            resourceList.map((resource, idx) => {
              const key = resource.title
              const value = resource[valueKey]
              return <ResourceItem
                key={key}
                title={resource.title}
                index={idx}
                value={value}
                id={key}
                deleteResource={() => deleteResource(idx)}
                editResource={() => editResource(idx)}
                docId={docId}
                icon={icon}
                resourceKey={resourceKey}
              />
            })
          }

        </SortableContext>
      </DndContext>
    </div>
  )
}


const ResourceItem = (props) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100%"
  };


  let className = 'd-flex justify-content-between sidebarButton'



  return (
    <div className='d-flex justify-content-between' >
      <div key={props.id} ref={setNodeRef} {...attributes} {...listeners} style={style} className={className}>
        <RowTitle type={props.type} title={props.title} icon={props.icon} />
      </div>
      <div>
        <RowButtons {...props} />
      </div>
    </div>
  )
}


const RowTitle = ({ type, title, icon }) => {

  if (type === "header") {
    return (
      <div className='d-flex align-items-center'
        style={{ fontWeight: 600 }}>
        {title}
      </div>
    )
  }


  return (
    <div className='d-flex align-items-center'>
      {icon}
      <div style={{ marginLeft: "10px" }}>
        {title}
      </div>
    </div>
  )
}


const RowButtons = (props) => {
  const {
    deleteResource,
    editResource
  } = props

  return (
    <span className='d-flex'>
      <button
        onClick={() => {

          // first make sure they want it deleted
          Swal.fire({
            title: 'Are you sure?',
            text: 'Resources can not be recovered after deletion. Are you sure you want to delete this resource?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes! Delete',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              deleteResource()
            }
          })
        }}
        className='btn  btn-sm '
      >
        <FaTrash />
      </button>
      <button
        className='btn  btn-sm '
        onClick={() => editResource()}
      >
        <FaEdit />
      </button>


    </span>
  )

}








import { useContext, useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { CompletionContext } from 'contexts/CompletionContext';
import { handlePuzzleSubmission, loadTrueShortLabels, logPuzzleError } from './TNFirebase';
import { useUserId } from 'hooks/user/useUserId';
import { useCourseId } from 'hooks/router/useUrlParams';
import { FaFlag } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { DIAG4_UPDATED_INFO } from './UpdatedLabels';


const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const BugButton = styled.button`
  background-color: '#000000' /* Light grey background */
  color: black; /* Black text color */
  border: ${props => props.feedback === "correct" ? "solid 6px mediumseagreen" : props.feedback === "incorrect"  && props.selected ? "solid 6px tomato" : "solid 1px #d0d0d0"}; /* Light grey border */
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  position: relative; /* For positioning the icon absolutely within the button */

  &:hover {
    background-color: ${props => props.selected ? '#553355' : '#e0e0e0'}; /* Darker shades on hover */
  }

  ${props => props.selected &&`
    background-color: #664466; /* Highlighted background color, e.g., a shade of blue */
    color: white; /* White text color for better contrast on the highlighted background */

  `}  
`;

const BugButtonOption = ({ showFeedback, bugOption, selectedBugs, feedback, isSubmitting, handleBugClick }) => {
  // Retrieve the display label and the overlay label from DIAG4_UPDATED_INFO
  const displayLabel = DIAG4_UPDATED_INFO[bugOption]?.fixedShortLabel || 'Unknown';
  const overlayLabel = DIAG4_UPDATED_INFO[bugOption]?.label || 'No Description Available';

  return (
      <OverlayTrigger
          key={bugOption}
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                  {overlayLabel}
              </Tooltip>
          )}
      >
          <BugButton
              key={bugOption}
              onClick={() => handleBugClick(bugOption)}
              selected={selectedBugs.includes(bugOption)}
              feedback={feedback[bugOption]}
              disabled={isSubmitting}
          >
              {displayLabel}
          </BugButton>
      </OverlayTrigger>
  );
}



export const ContinueBugsButton = ({ nextSlide, numPuzzlesCompleted, resetPuzzle, puzzleTypeWording }) => {
  if( numPuzzlesCompleted >= 3 ) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <hr/>
        <p>You have completed {numPuzzlesCompleted} {puzzleTypeWording} puzzles! You need at least 3 to move on to the next part of training. You can continue on in the training or complete more puzzles for practice.</p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', gap: '10px'}}>
        <Button 
            style={{backgroundColor: 'violet', outline: 'none', border: 'none'}}
            onClick={resetPuzzle}
          >
            Another Puzzle
        </Button>
        <Button onClick={() => nextSlide()}>Continue with Training</Button>
          
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <hr/>
        <p>You need to get at least 3 {puzzleTypeWording} puzzles correct to move on with the training. You are at {numPuzzlesCompleted}.</p>
        <Button 
          style={{backgroundColor: 'violet', outline: 'none', border: 'none'}}
          onClick={resetPuzzle}
        >
          Next Puzzle
        </Button>
      </div>
    )
  }
}


const ButtonSelectExperience = ({ bugOptions, showBugFeedback, selectedBugs, feedback, isSubmitting, handleBugClick }) => {
  return (
    <ButtonContainer>
      {bugOptions.map((bugOption) => (
        <BugButtonOption
          key={bugOption}
          showFeedback={showBugFeedback}
          bugOption={bugOption}
          selectedBugs={selectedBugs}
          feedback={feedback}
          isSubmitting={isSubmitting}
          handleBugClick={handleBugClick}
        />
      ))}
    </ButtonContainer>
  )

}

export const RightPuzzlePane = ({ puzzleInfo, numPuzzlesCompleted, moveToNextPuzzle, puzzleLogDocId, nextSlide }) => {
    const { bugOptions } = puzzleInfo;
    const [selectedBugs, setSelectedBugs] = useState([]);
    const [showBugFeedback, setShowBugFeedback] = useState(false);
    const [feedback, setFeedback] = useState({});
    const [numCorrect, setNumCorrect] = useState(0);
    const [numIncorrect, setNumIncorrect] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [puzzlePassed, setPuzzlePassed] = useState(false);
    const [shortLabels, setShortLabels] = useState([]);

    const userId = useUserId();
    const courseId = useCourseId();

    const { updateTeacherPuzzlesCompleted } = useContext(CompletionContext)
  
    const handleBugClick = (bugOption) => {
        if(showBugFeedback) return;
      setSelectedBugs(prevBugs =>
        prevBugs.includes(bugOption) ? prevBugs.filter(bug => bug !== bugOption) : [...prevBugs, bugOption]
      );
    };

    const resetPuzzle = () => {
        setSelectedBugs([]);
        setFeedback({});
        setNumCorrect(0);
        setNumIncorrect(0);
        setShowBugFeedback(false);
        setPuzzlePassed(false);
        moveToNextPuzzle();
    };

    const handleFlagClick = async () => {
      try {
        const result = await Swal.fire({
          title: 'Report a problem with this puzzle',
          input: 'textarea',
          inputLabel: 'What went wrong?',
          inputPlaceholder: 'Please describe the issue you encountered.',
          showCancelButton: true,
          confirmButtonText: 'Submit'
        });
        if (result.isConfirmed) {
          await logPuzzleError(userId, courseId, puzzleInfo.puzzleProblem, result.value, puzzleInfo.puzzleId, puzzleLogDocId);
          await Swal.fire('Thank you!', 'Your feedback has been submitted.', 'success');
        }
      } catch (error) {
        console.error('Error when logging puzzle error:', error);
        // Handle errors if needed
      }
    };
    

    const handleDoneClick = async () => {
        setIsSubmitting(true) // Disable button and show spinner while the puzzle is being submitted
        // Check correctness of puzzle
        const trueShortLabels = await loadTrueShortLabels(courseId, puzzleInfo.puzzleProblem, puzzleInfo.puzzleId);
        setShortLabels(trueShortLabels);
        const newFeedback = {};
        bugOptions.forEach(bug => {
          newFeedback[bug] = trueShortLabels.includes(bug) ? 'correct' : 'incorrect';
        });
        // Update states to show feedback
        setFeedback(newFeedback);

        // Calculate number of correct bugs identified
        const calculatedNumCorrect = trueShortLabels.filter(bug => selectedBugs.includes(bug)).length;
        setNumCorrect(calculatedNumCorrect);

        const numIncorrect = selectedBugs.filter(bug => !trueShortLabels.includes(bug)).length;
        setNumIncorrect(numIncorrect);

        const isComplete = calculatedNumCorrect === trueShortLabels.length && numIncorrect === 0;
        if(isComplete) {
          await updateTeacherPuzzlesCompleted('bugIdentification');
        }
        setPuzzlePassed(isComplete);

        // Prepare data for submission
        const puzzleData = {
          puzzleInfo: {
            ...puzzleInfo,
            trueShortLabels  // Adding trueBugs inside puzzleInfo
          }, 
          selectedBugs,
          numCorrect: calculatedNumCorrect,
          numIncorrect,
          isComplete
        };

        // Submit the puzzle results
        await handlePuzzleSubmission(userId, courseId, puzzleInfo.puzzleType, puzzleData, puzzleLogDocId);
        setIsSubmitting(false) // Now that we have submitted, we can get rid of the spinner 
        setShowBugFeedback(true); // And once we have submitted, show the feedback
    };

    return (
      <div style={{ overflowY: 'auto', maxHeight: '90vh', padding: '10px' }}>
        <p>Select the buttons below that represent bugs in the student's code.</p>
        {showBugFeedback ? (
            <>
            <ButtonSelectExperience 
              bugOptions={bugOptions}
              showBugFeedback={showBugFeedback}
              selectedBugs={selectedBugs}
              feedback={feedback}
              isSubmitting={isSubmitting}
              handleBugClick={handleBugClick}
            />
            <hr/>
            <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div>
                {
                  puzzlePassed ?
                  <div style={{color: 'green'}}>Correct!</div> :
                  <div style={{marginBottom: '5px'}}>Not quite there yet. Keep practicing!</div>
                }
                You correctly identified {numCorrect} out of {shortLabels.length} bugs.
                {numIncorrect > 0 && <div>You also selected {numIncorrect} non-bug(s) incorrectly.</div>}
              </div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 100 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    Click the flag to report a problem with this puzzle.
                  </Tooltip>
                )}>
                <Button
                  onClick={handleFlagClick}
                  style={{backgroundColor: 'orange', border: 'none'}}
                >
                  <FaFlag/>
                </Button>
              </OverlayTrigger>
            </div>
                <ContinueBugsButton 
                  nextSlide={nextSlide}
                  numPuzzlesCompleted={numPuzzlesCompleted}
                  resetPuzzle={resetPuzzle}
                  puzzleTypeWording={"'Noticing Bugs'"}
                />
          </>
        ) : (
            <>
            <ButtonSelectExperience 
              bugOptions={bugOptions}
              showBugFeedback={showBugFeedback}
              selectedBugs={selectedBugs}
              feedback={feedback}
              isSubmitting={isSubmitting}
              handleBugClick={handleBugClick}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                <Button onClick={handleDoneClick} disabled={isSubmitting}>
                    {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Done noticing bugs'}
                </Button>
            </div>
          </>
        )}
      </div>
    );
  };

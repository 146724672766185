import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { useCourseId } from "hooks/router/useUrlParams"
import { Link } from "react-router-dom";
import { useSharedProjects } from "./useProjects";
import { PartialLoading } from "components/loading/Loading";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const SharePage = () => {

  return <CoursePageSingleCol
    column={<ShareCol />}
  />

}

const ShareCol = () => {
    const {
        topProjectsAll,
        topProjectsDaily,
        newProjects,
        isLoading,
        viewMore
    } = useSharedProjects()

    if(isLoading) {
        return <PartialLoading />
    }


  
  return <div className="mt-4">
    <SharesTable newest={newProjects} topNew={topProjectsDaily} topAll={topProjectsAll} viewMore={viewMore} />
  </div>
}

const SharesTable = ({newest, topNew, topAll, viewMore}) => {
    const courseId = useCourseId()
    const [title, setTitle] = useState("")
    const [state, setState] = useState('topAll')

    useEffect(() => {
        switch(state) {
            case 'newest':
                setTitle("Newest")
                break
            case 'topNew':
                setTitle("Top Daily")
                break
            case 'topAll':
                setTitle("Top All Time")
                break
            default:
                setTitle("Newest Projects")
        }
    }, [state])

    const getProjects = () => {
        switch(state) {
            case 'newest':
                return newest
            case 'topNew':
                return topNew
            case 'topAll':
                return topAll
            default:
                return newest
        }
    }


    return <div>
   
        <table className="table">
            <thead 
            style={{
                position: "sticky",
                top: 0,
                width: "100%",
                backgroundColor: "white",
            }}>
                <th colSpan={"3"} >
                    <div className="w-100 d-flex align-items-start justify-content-start">
                    <h3>Shared Projects</h3>

                    <select 
                        className="rounded" 
                        onChange={(e) => setState(e.target.value)}
                        style={{
                            border: "none",
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            outline: 'none',
                            marginLeft: "30px",
                        }}
                        defaultValue={"topAll"}
                    >
                        <option value="topAll">⛰️ Top All Time</option>
                        <option value="topNew">🔥 Top Daily</option>
                        <option value="newest">⏰ Newest</option>
                    </select>
                    </div>

                </th>
            <tr>
                <th>Title</th>
                <th>Likes</th>
                <th>Runs</th>
            </tr>
            </thead>
            <tbody>
            {
                getProjects().map((project, index) => {
                const projectData = project.doc
                const projectId = project.id
                return <tr key={index}>
                    <td><Link to ={`/${courseId}/share/${projectId}`}>{projectData.title}</Link></td>
                    <td>{projectData.numLikes}</td>
                    <td>{projectData.numRuns}</td>
                </tr>
                })
            }
            </tbody>
        </table>
        <div className="d-flex justify-content-center">
            <SeeMoreButton onClick={() => viewMore(state)}>View More</SeeMoreButton>
        </div>
    </div>

}

// A button that looks like a link
const SeeMoreButton = styled.button`
    background: none;
    border: none;
    color: grey;
    text-decoration: underline;
`;


import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer";
import { SLTranscriptFeedback } from "./SLTranscriptFeedback";
import { useParams } from "react-router-dom";
import MessageBoard from "./components/MessageBoard";

function getSectionWeek() {
  const { sectionWeek } = useParams();

  const acceptedWeeks = [
    "week0",
    "week1",
    "week2",
    "week3",
    "week4",
    "week5",
    "week6",
  ];
  if (!acceptedWeeks.includes(sectionWeek)) {
    return undefined;
  }
  return sectionWeek;
}

export const TranscriptFeedbackPage = (props) => {
  return (
    <CoursePageBodyContainer
      title={<span>Talk Moves Feedback</span>}
      mainColumn={<SLFeedbackSingleColumn />}
      rightColumn={<></>}
      singleColumn={<SLFeedbackSingleColumn />}
    />
  );
};


const SLFeedbackSingleColumn = (props) => {
  const sectionWeek = getSectionWeek();
  if (sectionWeek === undefined) {
    return (
      <div>
        <MessageBoard message={"Invalid link. Please check the url"} />
      </div>
    );
  }
  return (
    <>
      <SLTranscriptFeedback week={sectionWeek} />
    </>
  );
};

import { Card, Button, Badge, Row, Col, Container } from 'react-bootstrap';
import { LocationInfo } from "../../../components/countries/LocationInfo";
import { useCourseId } from 'hooks/router/useUrlParams';
import { useNavigate } from 'react-router';
import { useIsMobile } from "utils/general";
import { ViewOnlyProfilePicture } from 'course/profile/components/ViewOnlyProfilePicture';
import { useContext } from 'react';
import { ConnectionsContext, isOtherUserComplete } from 'course/contexts/ConnectionsContext';
import styled from 'styled-components';


// export const ConnectionsList = ({ users, requestsView }) => {
//   return (
//     <>
//       <ConnectionsListOuter>
//         {
//           // connections view, show contact info
//           users.map((userId) =>
//             <NarrowCard
//               userId={userId}
//               key={userId}
//               requestsView={requestsView}
//             />
//           )
//         }
//       </ConnectionsListOuter>
//     </>
//   )
// }


export const ConnectionCard = ({ userId, textKey, btnVariant, btnText }) => {
  const navigate = useNavigate();
  const courseId = useCourseId();
  const { otherUsersInfo } = useContext(ConnectionsContext)
  const user = otherUsersInfo[userId]


  const handleClick = (userId) => {
    navigate(`/${courseId}/user/${userId}`);
  };

  if (!isOtherUserComplete(user)) {
    return <></>
  }

  let text = user[textKey] || ""
  // let text = requestsView ? user.message : user.contact
  text = text.trim()

  return (
    <Card style={{ width: '13.9rem', minWidth: 170 }}>
      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} className="mb-2">
            <ViewOnlyProfilePicture userData={user} />
          </div>
          <b>
            {user.displayName}<br/>
            &nbsp;&nbsp;
            <LocationInfo country={user.country} height={18} />
            &nbsp;&nbsp;
            <Badge bg='secondary'>{user?.courseRole}</Badge>
          </b><br/>
          <DisplayedText>
            {text}
          </DisplayedText>
        </div>
        <div>
        <Button variant={btnVariant} className="mt-2" onClick={() => handleClick(userId)}>{btnText}</Button>
           
          
        </div>
      </Card.Body>
    </Card>
  );
}



 // requestsView ? <Button variant="success" className="mt-2" onClick={() => handleClick(userId)}>
            //   View request
            // </Button> : <Button variant="primary" className="mt-2" onClick={() => handleClick(userId)}>
            //   View profile
            // </Button>

// make it so that its no more than one line
// and if it goes over, it should add an ellipsis
const DisplayedText = styled(Card.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  text-align: left;
  margin-bottom: 10px;
`
import { DateOfBirth } from "components/Forms/FormixComponents";
import { FormixFormWithData } from "components/Forms/FormixFormWithData";
import { CountryInput, DateOfBirthInput, GenderInput } from "components/Forms/FormixInputs";
import { TextInput } from "components/Forms/FormixComponents";
import { ProfileContext } from "contexts/ProfileContext";
import COUNTRY_CODE_TO_NAMES from "data/countries/country_code_to_names";
import { getAuth, signOut } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { generateUsernames } from "utils/generateUsernames";
import styled from "styled-components";
import Swal from "sweetalert2";
import { useIsMobile } from "utils/general";
const MIN_AGE_YEARS = 13



export const CompleteProfile = () => {
  const navigate = useNavigate()
  const close = () => navigate('/welcome')
  const auth = getAuth()

  return <OuterBody>
    <NavigationOuter>
      <FaTimes onClick={() => close()} />
      <Title></Title>
      <button
        className="btn btn-light"
        onClick={() => signOut(auth)}>Sign Out</button>
    </NavigationOuter>
    <Content />
  </OuterBody>
}

const Content = () => {
  const auth = getAuth();
  const [initialValues, setInitialValues] = useState(null)
  const { userData, userProtectedData } = useContext(ProfileContext)
  const userId = useUserId()
  const db = getFirestore()
  const isMobile = useIsMobile()

  useEffect(() => {
    const initialValues = {
      'displayName': userData?.displayName,
      'country': codeToCountry(userData?.country),
      'dateOfBirth': userProtectedData?.dateOfBirth,
    }
    fillInDefaults(initialValues, auth)
    setInitialValues(initialValues)
  }, [])

  if(!initialValues) {
    return <></>
  }

  const onSave = (values) => {
    console.log('values', values)
    const protectedUpdate = {
      dateOfBirth: values.dateOfBirth,
    }
    const publicUpdate = {
      displayName: values.displayName,
      country: values.country.code
    }
    const protectedDoc = doc(db, `users/${userId}/docs/protected`)
    const publicDoc = doc(db, `users/${userId}`)
    setDoc(protectedDoc, protectedUpdate, { merge: true })
    setDoc(publicDoc, publicUpdate, { merge: true })
    Swal.fire({
      title: `Success!`,
      toast: true,
      showClass: {
        popup: "none",
      },
      icon: "success",
      timer: 1000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  }

  // what is the minimum year to allow (so that users are >= 13 years old)
  const minYear = new Date().getFullYear() - MIN_AGE_YEARS

  if (isMobile) {
    return <div style={{ width: '100%', padding: '10px' }}>
      <div className="alert alert-primary" role="alert">
        Welcome! First, let's get your learner profile set up!
      </div>
      <FormixFormWithData
        serverData={initialValues}
        backUrl={null}
        formFormat={getFormStructure(minYear)}
        onSubmit={(e, cb) => onSave(e)}
        showGlobalError={true}
      />
    </div>
  }

  return <ContentOuter>
    <div className="alert alert-primary" role="alert">
      Welcome! First, let's get your learner profile set up!
    </div>
    <FormixFormWithData
      serverData={initialValues}
      backUrl={null}
      formFormat={getFormStructure(minYear)}
      onSubmit={(e, cb) => onSave(e)}
      showGlobalError={true}
    />
  </ContentOuter>

}

function fillInDefaults(initialValues, auth) {
  if (!initialValues.displayName) {
    initialValues.displayName = auth.currentUser.displayName
  }
  if (initialValues.displayName === "Anon") {
    initialValues.displayName = generateUsernames()
  }
  if (!initialValues.country) {
    initialValues.country = auth.currentUser.country
  }
  if (!initialValues.dateOfBirth) {
    initialValues.dateOfBirth = auth.currentUser.dateOfBirth
  }
}

function codeToCountry(countryCode) {
  if (!countryCode) {
    return null
  }
  return {
    ...COUNTRY_CODE_TO_NAMES[countryCode],
    code: countryCode
  }
}

function getFormStructure(minYear) {
  return [
    {
      name: "dateOfBirth",
      input: DateOfBirthInput,
      validation: Yup.object({
        year: Yup.number().integer('Year must be a four digit number.')
          .min(1900, 'Birth year must be four digits long.')
          .max(minYear, 'You must be at least 13 years to create an account')
          .required("Year is required in date of birth"),
        month: Yup.number().integer('Month must be a number.')
          .min(1, 'Month value must be greater than or equal to 1')
          .max(12, 'Month value must be less than or equal to 12')
          .required("Month is required in date of birth"),
        day: Yup.number().integer('Day must be a number.')
          .min(1, 'Day value must be greater than or equal to 1')
          .max(31, 'Day value must be less than or equal to 31')
          .required("Day is required in date of birth")
      }).required("Date of birth is required"),
      default: {}
    },

    {
      name: "country",
      input: CountryInput,
      validation: Yup.object({
        eng_name: Yup.string().required("Country is required")
      }).required('Country is required (and must be selected from the dropdown)'),
    },
    {
      name: "displayName",
      input: DisplayNameInput,
      default: "",
      validation: Yup.string().trim().min(3).required("Display name is required"),
      postProcess: (value) => value.trim(),
    },
  ]
}

const DisplayNameInput = ({ name }) => <TextInput
  label="Display Name"
  description=""
  name={name}
  type="text"
  placeholder="Jane Doe"
/>

const OuterBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  
  width: 100%;
  // padding-bottom:20px;
  
  @media (max-width: 768px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
  @media (max-height: 800px) { /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
`;

const NavigationOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  max-width: 1200px;
`;

const ContentOuter = styled.div`
  width: 500px;

`

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #333;
`;
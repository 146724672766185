import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { getApp } from "firebase/app"
import { collection, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"

export const MonitorOverallCompletions = () => {
  return <CoursePageBodyContainer
      mainColumn={<MonitorAppsMain />}
      rightColumn={<></>}
      singleColumn={<MonitorAppsMain />}
    />
}

const MonitorAppsMain = ({  }) => {
  const courseId = useCourseId()
  const db = getFirestore(getApp())
  const path = `/course/${courseId}/completions/`
  const [applications, loading, error] = useCollectionDataOnce(
    collection(db, path)
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const {nCompleted, assnCompletions} = parseShards(applications)

  console.log(nCompleted)


  // sort keys by values
  const sortedKeys = Object.keys(assnCompletions).sort((a,b) => assnCompletions[b] - assnCompletions[a])  
  
  // render the countMap in a bootstrap table
  return <div className="mt-3">
    <h3>Total Completions: {nCompleted}</h3>
    <h3>Per Assignment:</h3>
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th>Status</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {sortedKeys.map((assnId, i) => {
          return <tr key={i}>
            <td>{assnId}</td>
            <td>{assnCompletions[assnId]}</td>
          </tr>
        })}
      </tbody>
    </table>
  </div>
}

function parseShards(applications) {
  const completionSummary = {
    'nCompleted': 0,
    'assnCompletions':{}
  }
  
  applications.forEach(shard => {
    // shard is a dictionary from studentId -> {[assnId]: assnStatus}
    Object.values(shard).forEach(studentCompleteDict => {
      // console.log(studentCompleteDict)
      // for each key value pair in the studentCompleteDict
      Object.entries(studentCompleteDict).forEach(([assnId, assnStatus]) => {
        if(assnStatus){
          completionSummary['nCompleted'] += 1
          if(!(assnId in completionSummary['assnCompletions'])){
            completionSummary['assnCompletions'][assnId] = 0
          }
          completionSummary['assnCompletions'][assnId] += 1
        } 
      })
    })
  })
  return completionSummary
}
import { useContext, useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { LeftColTitle } from "../LeftCol"
import { IDEContext } from "ide/contexts/IDEContext"
import { getProjectType } from "ide/utils/general"
import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { useParams } from "react-router"
import { getFirestore, getDocs, collection} from "firebase/firestore"

export const DocsSplash = ({bottomBarRef}) => {
    const {projectData,assnData} = useContext(IDEContext)
    const projectType = getProjectType(projectData,assnData);
    const [selectionChoices, setSelectionChoices] = useState([{ id: "karel", name: "Karel"}, {id: "graphics", name: "Graphics"}, {id: "console", name:"Console"}])
    const [selection, setSelection] = useState(projectType)
    const {courseId} = useParams()
    const bottomBarBuffer = bottomBarRef && bottomBarRef.current ? bottomBarRef.current?.clientHeight || 0 : 0;

    useEffect(() => {
      const db = getFirestore()
      const getSelectionChoices = async () => {
        const docSet = await getDocs(collection(db, `docs/${courseId}/libraries`))
        const choices = docSet.docs.map((doc) => ({
          id: doc.id, 
          name: doc.data().name
        }))
        setSelectionChoices(choices)
      }
      getSelectionChoices()
    }, [])




    return <>
    <LeftColTitle title={<DocTitle
        selection = {selection}
        setSelection = {setSelection}
        selectionChoices={selectionChoices}
    />}/>
    <div
      style={{
        flexGrow: 2,
        minWidth: "200px",
        overflow: "auto",
        width:'100%',
        paddingLeft: "10px",
        paddingRight: "10px",
        fontSize:'14px',
        paddingBottom:bottomBarBuffer,
      }}
    >
      <DocsContent selection={selection} courseId={courseId}/>
    </div>
  </>
}

const DocsContent = ({selection, courseId}) => {

    return  <TipTap
      editable={false}
      collaborative = {false}
      firebaseDocPath={`/docs/${courseId}/libraries/${selection}`}
      onServerWrite={() => {}}
      buttonBar={() => {}}
    />
}

const DocTitle = (props) => {
    return <div className="d-flex w-100 justify-content-between">
        <span>Docs </span>
        <DocSelector {...props}/>
    </div>
}

const DocSelector = ({selection, setSelection, selectionChoices}) => {

    const selectionChanged = (e) => {
        const selectedKey = e.target.value
        setSelection(selectedKey)
    }

    return <select
    className="form-select form-select-sm"
    aria-label="Default select example"
    style={{width:200}}
    value = {selection}
    onChange = {(e) => selectionChanged(e)}
    >
      {selectionChoices.map(choice => {
        return <option value={choice.id} key={choice.id} >{choice.name}</option>
      })}
  </select>
}

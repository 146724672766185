import { CROSS_PCT, getCornerSize, getCornerX, getCornerY } from "../util";

export const GridBackground = ({state}) => {
    var lines = [];
    for (var r = 0; r < state.worldState.nRows; r++) {
      for (var c = 0; c < state.worldState.nCols; c++) {
        let cornerSize = getCornerSize(state);
        let x = getCornerX(state,r, c) + 0.5 * cornerSize;
        let y = getCornerY(state,r, c) + 0.5 * cornerSize;
        let crossSize = cornerSize * CROSS_PCT;
        lines.push(
          <div
            className="karel-cross"
            style={{
              marginLeft: x - crossSize / 2,
              marginTop: y - 1,
              width: crossSize,
              height: 2,
            }}
            key={r + "," + c + "1"}
          ></div>
        );
        lines.push(
          <div
            className="karel-cross"
            style={{
              marginLeft: x - 1,
              marginTop: y - crossSize / 2,
              width: 2,
              height: crossSize,
            }}
            key={r + "," + c + "2"}
          ></div>
        );
      }
    }
    return <div style={{position:'absolute'}}>{lines}</div>;
}
import { useState, useEffect } from "react"
import { useUserId } from "hooks/user/useUserId"
import { doc, getDoc, setDoc, getFirestore } from "firebase/firestore"
import { ChatRoom } from "components/teachnowchat/ChatRoom"
import Swal from "sweetalert2"
import { getFunctions, httpsCallable } from "firebase/functions"
import styled from "styled-components"

const functions = getFunctions()
const GPTeachRequest = httpsCallable(functions, 'gpteachRequest')


export const GPTeachChatContainer = ({
  data
}) => {
  
  const userId = useUserId()
  const scenario = data.scenario
  const personas = data.personas
  const [messages, setMessages] = useState([])
  // we are not going to use all the students! just one?
  const [student, setStudent] = useState({name:null, persona:null})
  


  // we select one student to chat with
  useEffect(() => {
    // randomly select one of the students to chat with
    // personas is a dictionary from name -> persona
    const studentNames = Object.keys(personas)
    const randomIndex = Math.floor(Math.random() * studentNames.length)
    const studentName = studentNames[randomIndex]
    const studentPersona = personas[studentName]
    setStudent({ name: studentName, persona: studentPersona })
  }, [data])
  

  const sendMessage = async (msg) => {
    const newMsg = {
      senderId: "SL",
      senderName: "SL",
      text: msg,
      timestamp: Date.now(),
    }

    setMessages(prevMessages => [...prevMessages, newMsg])

    const callParams = {
      teacherId: userId,
      studentName: student.name,
      studentPersona: student.persona,
      scenario: scenario,
      chatMessages: [...messages, newMsg],
    }

    console.log('CallParams', callParams)
    const response = await callGPTeachWrapper(callParams)
    console.log('Response', response)
    if (!response.error) {
      processResponse(response)
    }
  }

  const processResponse = (response) => {
    // expects well-formed error-free responses, error handling happens outside of this fn
    const data = response.data
    const msg = data.choices[0].message.content
    const newMsg = {
      senderId: "student",
      senderName: student.name,
      text: msg,
      timestamp: Date.now(),
    }
    setMessages(prevMessages => [...prevMessages, newMsg])
  }

  if(!data) return <></>

  return (
    <ChatOuter>
      <ChatRoom
        userId={'SL'}
        messages={messages}
        onSendMessage={sendMessage}
      />
    </ChatOuter>
  )
}

const ChatOuter = styled.div`
  width: 100%;
  height: 0;
  flex-grow: 1;

  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`


const callGPTeachWrapper = async (callParams) => {
  let failedOnce, failedTwice = false
  let response, err

  try {
    response = await GPTeachRequest(callParams)
    if (response.data?.error) {
      throw response.data.error
    }
  } catch (error) {
    console.log('Error', error)

    failedOnce = true
    err = error
  }
  if (failedOnce) { // try again
    try {
      response = await GPTeachRequest(callParams)
      if (response.data?.error) {
        throw response.data.error
      }
    } catch (error) {
      failedTwice = true
      err = error
    }
  }
  if (failedTwice) {
    Swal.fire({
      title: "Something went wrong",
      text: "A log has been sent to the course staff. Please refresh and try again"
    })
    return { error: err }
  }
  return response
}
import React, { useContext, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import { firestore } from "firebaseApp.js";
import { useCourseId } from 'hooks/router/useUrlParams';
import Loading from 'react-loading';
import { ReviewContext } from 'review/contexts/ReviewContext';
import { FaCheck } from 'react-icons/fa';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { getNextUngraded, gradeToNumber, isGradeLocked, isGradeScore } from 'review/contexts/ReviewUtil';
import { useUserId } from 'hooks/user/useUserId';
import Swal from 'sweetalert2';
import { getAuth, signOut } from 'firebase/auth';
import { getApp } from 'firebase/app';

export const ReviewSplash = (props)=> {
    const {gradebookLoading} = useContext(ReviewContext)
    const auth = getAuth(getApp());

    if( gradebookLoading){
        return <Loading/>
    }

    
    return <div className='container-lg'>
        <div className='d-flex justify-content-between mt-3'>
            <span style={{fontSize:'1.5em'}}>Section Leader Application Review</span>
            <button className='btn btn-outline-secondary' onClick={() => signOut(auth)}>Sign Out</button>

        </div>
        <hr/>
        <ReviewNext />
        <div style={{height:'20px'}}/>
        <Calibration/>
        <div style={{height:'20px'}}/>
        <StudentTable/>
    </div>
}

const StudentTable = ({}) => {
    const courseId = useCourseId()
    const {applicantList} = useContext(ReviewContext)

    return <><h3>Review Table <Link to={`/${courseId}/review/stats`}className='btn btn-light btn-lg'>Stats</Link></h3><ReviewProgress/><table className='table table-striped'>
        <thead><tr>
            <th>#</th>
            <th>Applicant ID</th>
            <th>Reviewed By</th>
            <th>Average Score</th>
            <th>Second Review</th>
            
            <th>Grading Lock</th>
        </tr></thead>
        <tbody>
        {applicantList.map((item,index)=>{
            const studentId = item
            return <StudentRow
                key={studentId} 
                studentId={studentId}
                index={index}
            />
        })}
       </tbody>
    </table></>
}

const StudentRow = ({studentId, index}) => {
    const courseId = useCourseId()
    const {gradebook, graders, secondReviewRequests} = useContext(ReviewContext)
    // let sunetId = student.email.split('@')[0]
    let studentUrl = `/${courseId}/review/app/${studentId}`
    let gradeStr = ''
    let graderStr = ''
    let lockStr = ''
    const hasSecondReview = studentId in secondReviewRequests && secondReviewRequests[studentId] 
    let secondReviewStr = hasSecondReview ? 'Yes' : ''
    if(gradebook && gradebook[studentId]) {
        let gradeData = gradebook[studentId]
        gradeStr = getAverageScoreStr(gradeData)
        const graderNames = []
        const lockedNames = []
        for(let graderId in gradeData) {
            let score = gradeData[graderId]
            let graderName = graders[graderId]
            console.log(studentId, graderName, score)
            if(isGradeScore(score)) {
                graderNames.push(graderName)
            }
            if(isGradeLocked(score)){
                lockedNames.push(graderName)
            }
            
        }
        graderStr = graderNames.join(', ')
        lockStr = lockedNames.join(', ')
    }

    return <tr>
        <td>{index + 1}</td>
         <td>
            <Link to = {studentUrl}>{studentId}</Link>
        </td> 
        
        <td>{graderStr}</td>
        <td>{gradeStr}</td>
        <td>{secondReviewStr}</td>
        <td>{lockStr}</td> 
    </tr>
}



const getAverageScoreStr = (gradeData) => {
    const scores = []
    for(let graderId in gradeData) {
        let overallStr = gradeData[graderId]
        if(overallStr === 'Select') {
            continue
        }
        if(overallStr === 'Released') {
            continue
        }
        let score = gradeToNumber(overallStr)
        scores.push(score)
    }
    if(scores.length === 0) {
        return ''
    }
    let sum = scores.reduce((a,b)=>a+b,0)
    let avg = sum / scores.length
    return avg.toFixed(1)
}

const ReviewProgress = ({}) => {
    const {applicantList, gradebook} = useContext(ReviewContext)
    let nGraded = 0
    let nTodo = 0
    for(let studentId of applicantList) {
        if(gradebook && gradebook[studentId]) {
            let gradeData = gradebook[studentId]
            // gradeData is a dictionary, get its length
            let nGrades = 0
            for(let graderId in gradeData) {
                let score = gradeData[graderId]
                if(score != 'Released' && score != 'Select') {
                    nGraded += 1
                }
            }
            nGraded += Math.min(2, nGrades)
            
        }
        nTodo += 2
    }
            
    let progress = nGraded/nTodo * 100

    return <ProgressBar now={progress} />;
}

const Calibration = ({}) => {
    const courseId = useCourseId()
    const {calibration} = useContext(ReviewContext)
    const c1 = calibration && calibration[1]
    const c2 = calibration && calibration[2]
    const c3 = calibration && calibration[3]
    const c4 = calibration && calibration[4]
    return <>
    <h3>Calibration</h3>
        <Link to={`/${courseId}/review/calibration/1`}>Calibration 1</Link> {c1 && <FaCheck className="text-success"/>} <br/>
        <Link to={`/${courseId}/review/calibration/2`}>Calibration 2</Link> {c2 && <FaCheck className="text-success"/>} <br/>
        <Link to={`/${courseId}/review/calibration/3`}>Calibration 3</Link> {c3 && <FaCheck className="text-success"/>} <br/>
        <Link to={`/${courseId}/review/calibration/4`}>Calibration 4</Link> {c4 && <FaCheck className="text-success"/>} <br/>
    </>
}

const ReviewNext = ({}) => {
    const {calibration} = useContext(ReviewContext)
    const navigate = useNavigate()
    const courseId = useCourseId()
    const {applicantList, gradebook, secondReviewRequests} = useContext(ReviewContext)
    const graderId = useUserId()
    const isCalibrated = checkCalibrated(calibration)
    const onClick = () => {
        const next = getNextUngraded(applicantList, gradebook, secondReviewRequests, null, graderId) 
        if(!next) {
            Swal.fire({
                icon: 'success',
                title: 'Phase done!',
                showConfirmButton: true,
            });
        } else {
            navigate(`/${courseId}/review/app/${next}`);
        }
        
    }

    return <button 
        title={isCalibrated ? 'Review Applicant' : 'You need to be calibrated to grade'}
        disabled={!isCalibrated} onClick={onClick} className='btn btn-primary btn-lg'>Review Applicant</button>
}

function checkCalibrated(calibration) {
    if(!calibration) {
        return false
    }
    for(let i = 1; i <= 4; i++) {
        if(!calibration[i]) {
            return false
        }
    }
    return true
}
export interface UnitTestResults {
  testsRun: boolean;
  testResults: Array<{
    isSuccess: boolean;
    usedAi: boolean;
    rawResult: any;
    name: string;
  }>;
}

export const emptyTestResult: UnitTestResults = {
  testsRun: false, // Assuming default is false since no tests have been run
  testResults: [], // Empty array since no tests have been run
};



export function allTestsPassed(unitTestResults: UnitTestResults): boolean {
  return unitTestResults.testsRun && unitTestResults.testResults.every((result) => result.isSuccess);
}

export function getFirstTestFailed(unitTestResults: UnitTestResults): string {
  return unitTestResults.testResults.find((result) => !result.isSuccess)?.name;
}

export function checkUsedAi(unitTestResults: UnitTestResults): boolean {
  return unitTestResults.testResults.some((result) => result.usedAi);
}

export function resultsHaveError(unitTestResults: UnitTestResults): boolean {
  for (const result of unitTestResults.testResults) {
    if (!result.isSuccess) {
      // check if there is an error
      const rawResult = result.rawResult;
      const hasError = rawResult?.error && rawResult.error.length > 0;
      if (hasError) {
        return true
      }
    }
  }
  return false
}

export function firstTestHasError(unitTestResults: UnitTestResults): boolean {
  const firstTest = unitTestResults.testResults[0]
  if (!firstTest) {
    return false
  }
  const rawError = firstTest.rawResult?.error
  const hasError = rawError && rawError.length > 0;
  return hasError
}

export function createErrorTestResult(errorMsg): UnitTestResults {
  return {
    testsRun: true,
    testResults: [
      {
        isSuccess: false,
        usedAi: false,
        rawResult: { error: errorMsg },
        name: "Error",
      },
    ],
  }
};
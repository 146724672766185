import { doc, getFirestore, updateDoc } from "firebase/firestore";

export async function confirmRole(courseId, userId, confirmationKey) {
  // edit: this is going to be written to the userData instead
  const docRef = doc(getFirestore(), `users/${userId}`);

  const updateKey = `${confirmationKey}.${courseId}`;
  await updateDoc(docRef, {
    [updateKey]: true
  });
};
import Button from 'react-bootstrap/Button';
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, doc, getDoc, getDocs, getFirestore } from "firebase/firestore"
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2"
import { useState } from "react"
import {  FaWindowClose } from 'react-icons/fa';

export const RemoveMemberButton = ({memberId, memberName, sectionId, courseId}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [removeIsLoading, setRemoveIsLoading] = useState(false);
    const functions = getFunctions()
    const removeMemberFirebase = httpsCallable(functions, 'removeMember')

    const onRemove = async() => {
        setRemoveIsLoading(true)
        try{
        const res = await removeMemberFirebase({memberId, sectionId, courseId})
        // @ts-ignore
        if (!!res && res.data.success){
            Swal.fire('Member removed!', `User ${memberId} has been removed from this section.`, 'success')
            handleClose()
            setRemoveIsLoading(false)
            return
        }
        else{
            // @ts-ignore
            throw new Error(res.data.reason)
        }
        } catch (e) {
        Swal.fire('Something went wrong!', `Could not remove member ${memberId}. ${e.message}`, 'error')
        handleClose()
        setRemoveIsLoading(false)
        return
        }
    }

    return <>
        <Button variant="danger" size="sm" onClick={handleShow}><FaWindowClose color={'white'}/></Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Heads up!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You are about to remove {memberName} (UID {memberId}) from this section, which is an irreversible action. 
                If you have not notified them already you may want to email them first. They will not be automatically reassigned to another section.
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="danger" onClick={onRemove} disabled={removeIsLoading}>
                {removeIsLoading? "Removing in progress, please wait..." : "Yes, remove this member"}
            </Button>
            </Modal.Footer>
        </Modal>
    </>
}
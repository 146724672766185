import firebase from "firebase/compat/app";
import Swal from "sweetalert2";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const uploadPhoto = (storagePath, onComplete, onProgress, onError) => {
  const swalData = {
    title: "Select image",
    input: "file",
    inputAttributes: {
      accept: "image/*",
      "aria-label": "Upload photo",
    },
  }
  uploadFile(swalData, storagePath, onComplete, onProgress, onError)
}


export const uploadPDF = (storagePath, onComplete, onProgress, onError) => {
  const swalData = {
      title: "Select file",
      input: "file",
      inputAttributes: {
      accept: "application/pdf",
      "aria-label": "Upload your PDF",
      },
  }
  uploadFile(swalData, storagePath, onComplete, onProgress, onError)
}



// general function
export const uploadFile = (
    swalData,
    storagePath, 
    onComplete, 
    onProgress,
    onError) => {


    Swal.fire(swalData).then((e) => {
        const file = e.value;
        if (file) {
            uploadFileToDatabase(file, storagePath, onComplete, onProgress, onError);
        }
    });
}

export const uploadFileToDatabase = (file, storagePath, onComplete, onProgress, onError) => {

    // lets get this started
    onProgress(1)
    
    const storage = firebase.storage();
    const storageRef = storage.ref();
    
    const uploadTask = storageRef.child(storagePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        onProgress(progress);
      },
      (error) => {
        onError({
            code: error.code,
            message: error.message
        })
      },
      () => {
        // get a playable url from the snapshop ref
        const ref = uploadTask.snapshot.ref
        ref.getDownloadURL().then((url) => {
            onComplete(url);
        })
      }
    );
  };

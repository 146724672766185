import COUNTRIES from "../../data/countries/country_names";
import { Typeahead } from "react-bootstrap-typeahead";
import { DateOfBirth, SelectInput, TextInput } from "./FormixComponents";

export const CountryInput = ({name, values, touched, errors, setFieldValue, setFieldTouched}) => {
  
  // value for typeahead must be a list
  const currValue = values.country ? [values.country] : []
  let error = errors.country ? errors.country : null
  const isTouched = touched.country ? touched.country : false
  if(error && !(typeof error === 'string' || error instanceof String)) {
    error = error.eng_name
  }
  
  const onChange = (e) => {
    // returns a list of countries
    const newValue = e[0]
    // if its an undefined country, dont save it
    if(!newValue) {
      return
    }
    setFieldValue("country", newValue) 
  }

  const setCountryFromText = (text) => {
    setFieldTouched("country", true)
    const country = COUNTRIES.find((country) => country.eng_name === text)
    if(country) {
      setFieldValue("country", country)
    } else {
      if(currValue) {
        setFieldValue("country", null)
      }
    }
  }

  const onInputChange = (text) => {
    setCountryFromText(text)
  }

  const onBlur = (e) => {
    const text = e.target.value
    setCountryFromText(text)
  }
    
  const countryKey = "eng_name"

  if(name != 'country') {
    return <>Error: name must be country</>
  }
  
  return <div className="question-spacing">
    <label className="form-label">
      What country are you in?
    </label>
    <div>
    <Typeahead
      id="country-input"
      className="about-text-input p-0"
      labelKey={countryKey}
      onChange={onChange}
      options={COUNTRIES}
      placeholder="United States"
      defaultSelected={currValue}
      onInputChange={(text) => onInputChange(text)}
      onBlur={onBlur}
    />
    </div>
    {isTouched && error && <div className="error">{error}</div>}
  </div> 

}

export const GenderInput = ({name}) => <SelectInput label="Gender" name={name}>
    <option value=""></option>
    <option value="female">Female</option>
    <option value="male">Male</option>
    <option value="non-binary">Non-binary</option>
    <option value="other">Other</option>
    <option value="na">Prefer not to say</option>
</SelectInput>

export const DateOfBirthInput = ({name}) => <DateOfBirth name={name}/>

export const DisplayNameInput = ({name}) => <TextInput
  label="Display Name"
  description="This is the name that will be displayed to other students in the course. Be sure to keep in mind your own privacy. As a best practice, we recommend using your first name and last initial."
  name={name}
  type="text"
  placeholder="Jane Doe"
/>
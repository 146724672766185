import { SelectInput, TextInput } from "components/Forms/FormixComponents";
import { AssnContext } from "course/contexts/AssnContext";
import { FieldArray } from "formik";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { FaLink, FaTrash } from "react-icons/fa";

export const PortfolioInput = ({ name, values, touched, errors, setFieldValue, setFieldTouched }) => {
  const courseId = useCourseId()

  const { assignments, assnMap, assnMetaData, creativeMetaData } = useContext(AssnContext)


  const portfolioOptions = composeOptions(assignments, assnMap, assnMetaData, creativeMetaData)

  if (name !== "portfolio") {
    return <>Error key must be "portfolio"</>
  }
  const portfolio = values.portfolio || [];
  const getButtonText = (numProjects) => {
    if (numProjects === 0) {
      return "Add a portfolio project";
    } else {
      return "Add another project";
    }
  };


  return <>
    <label className="form-label" htmlFor="portfolio">
      Portfolio Projects
    </label>
    <div className="description">Projects in your portfolio are visible on your profile and on your course certificate.</div>
    <FieldArray name="portfolio">
      {({ remove, push }) => (
        <div>
          {portfolio.length > 0 &&
            portfolio.map((project, index) => (
              <div className="d-flex align-items-center " key={index}>
                <div className="mr-2">Project {index + 1}:</div>
                <div className="refInput">
                  <SelectInput label="" name={`portfolio[${index}]`}>
                  <option key={'default'} value={''}></option>
                    {portfolioOptions.map((option, index) => {
                      return <option key={index} value={option.projectId}>{option.title}</option>
                    })}
                  </SelectInput>
                </div>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => window.open(`/${courseId}/share/${project}`, '_blank')}
                >
                  <FaLink />
                </button>
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          {portfolio.length < 3 && (
            <Button
              className="addButton"
              variant="light"
              onClick={() => push("")}
            >
              {getButtonText(portfolio.length)}
            </Button>
          )}
        </div>
      )}
    </FieldArray>
  </>

}

function composeOptions(assignments, assnMap, assnMetaData, creativeMetaData) {
  const options = []
  if (!assnMap){
    return []
  }
  for (const assnCollection of assignments) {
    for (const assnId of assnCollection.assnList) {
      if (!(assnId in assnMap)) {
        continue
      }
      const title = assnMetaData[assnId].title
      const projectId = assnMap[assnId]
      options.push({ title, projectId })
    }
  }

  // for (const [key, value] of Object.entries(assnMap)) {
  //   const assnId = key
  //   const projectId = value
  //   if(!(key in assnMetaData)) {
  //     continue
  //   }
  //   const title = assnMetaData[assnId].title
  //   options.push({title, projectId})
  // }
  for (const [key, value] of Object.entries(creativeMetaData)) {
    const projectId = key
    const title = value.title
    options.push({ title, projectId })
  }
  return options
}

import { ProfileContext } from "contexts/ProfileContext";
import { firestore } from "firebaseApp";
import { useCourseId } from "hooks/router/useUrlParams";
import React, {
  Suspense,
  lazy,
  memo,
  useEffect,
  useRef,
  useContext,
  useState,
} from "react";

import { createRoot } from "react-dom/client";
import Loading from "react-loading";
import styled from "styled-components";
import { StyleSheetManager } from "styled-components";
import { defaultChatGPTQuestions } from "./defaultchatgptquestions";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import { ExperimentContext } from "contexts/ExperimentContext";
import { useLocation } from "react-router";
import { getFirestore, doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

const ReactChatGPT = lazy(() =>
  import("react-chatppt").then((module) => ({ default: module.ChatGPT }))
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const getRandomElements = (arr, n) => {
  const result = [];
  const tempArr = [...arr]; // Create a copy of the original array

  for (let i = 0; i < n && tempArr.length > 0; i++) {
    const randomIndex = Math.floor(Math.random() * tempArr.length);
    result.push(tempArr[randomIndex]);
    tempArr.splice(randomIndex, 1); // Remove the selected element
  }
  return result;
};

const sampleStrings = (obj, key, n = 3) => {
  if (key === "" || key === null || key === undefined) {
    return;
  }
  const valueArray = obj[key];

  if (!Array.isArray(valueArray) || valueArray.length === 0) {
    console.warn("Invalid object or key");
    return;
  }

  return getRandomElements(valueArray, n);
};

async function saveChatHistory(chatHistory, courseId, userId) {
  // Traverse the chatHistory JSON object and extract messages
  for (const chatSession of chatHistory) {
    const chatName = chatSession.name.replace(/\//g, "-");

    const chatId = chatSession.id;
    const chatRef = firestore
      .collection("chatgpt")
      .doc(courseId)
      .collection(userId)
      .doc("chats")
      .collection(chatId)
      .doc("messageGroup");

    await chatRef.set({ chatname: chatName }, { merge: true });

    const messagesRef = chatRef.collection("messages");

    const sessionMessages = extractMessages(chatSession);

    // Save messages to Firestore
    for (const message of sessionMessages) {
      const messageId = message.id;
      await messagesRef.doc(messageId).set(message);
    }
  }
}

async function logQuestionData(questionData, courseId, userId) {
  console.log(questionData, courseId, userId);
  const chatId = questionData.topic;
  const chatRef = firestore
    .collection("chatgpt")
    .doc(courseId)
    .collection(userId)
    .doc("chats")
    .collection(chatId)
    .doc("messageGroup");
  await chatRef.set(
    {
      text: questionData.question,
      topicId: questionData.topic,
      was_changed: questionData.changed,
    },
    { merge: true }
  );
}

function changePoolOfQuestions(id, setPoolOfQuestions, questionGroup) {
  if (id === "") {
    setPoolOfQuestions(
      sampleStrings(defaultChatGPTQuestions, questionGroup, 3)
    );
  }
}

function extractMessages(chatSession) {
  const messages = [];

  const processChoices = (choices, parentId) => {
    for (const choice of choices) {
      messages.push({
        id: choice.date.toString(),
        content: choice.content,
        role: choice.role,
        timestamp: new Date(choice.date),
        parentId: parentId,
        vote: choice.vote || null,
      });

      if (choice.choices) {
        processChoices(choice.choices, choice.date.toString());
      }
    }
  };

  processChoices(chatSession.root.choices, null);

  return messages;
}

const ChatGPT = ({
  photoUrl,
  courseId,
  userId,
  questionGroup,
  systemPrompt,
  useChatgpt4,
}) => {
  const STORAGE_KEY = "react_chatgpt_topics";
  const topicsRef = useRef(undefined);
  const activeRef = useRef(undefined);

  // initial sampling of questions
  const [poolOfQuestions, setPoolOfQuestions] = useState(null);

  useEffect(() => {
    setPoolOfQuestions(
      sampleStrings(defaultChatGPTQuestions, questionGroup, 3)
    );
  }, [questionGroup]);

  if (!topicsRef.current) {
    try {
      topicsRef.current = JSON.parse(localStorage.getItem(STORAGE_KEY) || "-");
    } catch (error) {
      topicsRef.current = [];
    }
  }

  if (!activeRef.current) {
    activeRef.current = { id: undefined };

    const id = location.hash.slice(1);

    if (id && topicsRef.current.some((item) => item.id === id)) {
      activeRef.current.id = id;
    }
  }

  function retrieveAndSaveChatHistory(storage_key) {
    const storedData = localStorage.getItem(storage_key);
    if (storedData) {
      const chatHistory = JSON.parse(storedData);
      saveChatHistory(chatHistory, courseId, userId);
    } else {
      console.log("No chat history found in localStorage.");
    }
  }

  useEffect(() => {
    // initial call
    retrieveAndSaveChatHistory(STORAGE_KEY);

    // run every minute
    const intervalId = setInterval(
      () => retrieveAndSaveChatHistory(STORAGE_KEY),
      1000 * 60
    );
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const chatgpt3url = "https://chatgpt-cip-server.herokuapp.com/gpt3";
  const chatgpt4url = "https://chatgpt-cip-server.herokuapp.com/gpt4";
  const fetcher = useChatgpt4 ? chatgpt4url : chatgpt3url;
  console.log('fetcher', fetcher)
  return (
    <Container>
      <Suspense fallback={<Loading />}>
        <ReactChatGPT
          fetcher={fetcher}
          defaultPrompt={systemPrompt}
          placeholderMenus={
            poolOfQuestions
              ? {
                  "Some questions you can ask": poolOfQuestions,
                }
              : {}
          }
          initialTopics={topicsRef.current}
          initialActiveTopic={activeRef.current.id}
          defaultUserAvatar={photoUrl}
          onQuestionSelect={(questionData) =>
            logQuestionData(questionData, courseId, userId)
          }
          onTopicsChange={(data) =>
            localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
          }
          onActiveTopicChange={(id = "") =>
            changePoolOfQuestions(id, setPoolOfQuestions, questionGroup)
          }
        />
      </Suspense>
    </Container>
  );
};

const ChatGPTShadow = memo(() => {
  const { userData } = useContext(ProfileContext);
  const { expData, expLoading } = useContext(ExperimentContext);

  const db = getFirestore();
  const path = `chatgpt/defaultArgs`;
  const expRef = doc(db, path);

  const [defaultDataRaw, defaultLoading, defaultError] =
    useDocumentData(expRef);

  console.log("chatgpt default args", defaultDataRaw);

  const courseId = useCourseId();

  const [user, loading] = useAuthState(firebase.auth());

  if (loading) {
    return <Loading />;
  }

  const photoUrl = userData
    ? userData.photoURL
    : "https://i.imgur.com/ASvhSsQ.jpg";

  const [container, setContainer] = useState(null);
  const [styledSlot, setStyledSlot] = useState(null);
  const [questionGroup, setQuestionGroup] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [useChatgpt4, setUseChatgpt4] = useState(false);
  const [root, setRoot] = useState(null);

  useEffect(() => {
    // Handle the case when expData is undefined
    if (!defaultDataRaw) {
      console.log("No default chatgpt data available");
      return;
    }

    setSystemPrompt(defaultDataRaw.defaultPrompt);
    setUseChatgpt4(defaultDataRaw.useChatgpt4);
  }, [defaultLoading]);

  useEffect(() => {
    // Handle the case when expData is undefined
    if (!expData) {
      console.log("No experiment data available");
      return;
    }

    // Access the values from expData
    const { exp3_QuestionPool } = expData;

    if (exp3_QuestionPool == 1) {
      setQuestionGroup("Coding");
    } else if (exp3_QuestionPool == 2) {
      setQuestionGroup("Emotional");
    } else if (exp3_QuestionPool == 3) {
      setQuestionGroup("Resources");
    }
  }, [expData, expLoading]);

  // Initialize the Shadow DOM
  useEffect(() => {
    const host = document.querySelector("#chat-ppt");

    if (host.shadowRoot) {
      return;
    }

    const shadow = host.attachShadow({ mode: "open" });

    const defaultStyle = "width:100%;height:100%;";

    const container = document.createElement("div");
    const styledSlot = document.createElement("div");
    const styleContainer = document.createElement("div");

    // @ts-ignore
    window.__CHAT_PPT_STYLE = styleContainer;

    shadow.appendChild(styleContainer);
    shadow.appendChild(styledSlot);

    styledSlot.appendChild(container);

    container.setAttribute("style", defaultStyle);
    styledSlot.setAttribute("style", defaultStyle);

    const root = createRoot(container);

    setContainer(container);
    setRoot(root);
    setStyledSlot(styledSlot);
  }, []);

  // Update the ChatGPT component
  useEffect(() => {
    if (!container || !styledSlot || !root || loading) {
      return;
    }

    root.render(
      <StyleSheetManager target={styledSlot}>
        <ChatGPT
          photoUrl={photoUrl}
          courseId={courseId}
          userId={user.uid}
          questionGroup={questionGroup}
          systemPrompt={systemPrompt}
          useChatgpt4={useChatgpt4}
        />
      </StyleSheetManager>
    );
  }, [userData, root, styledSlot]);

  return <></>;
});

export const ChatGPTInterface = memo(() => {
  const location = useLocation();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("hasReloaded")) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      setShouldRender(true);
    }

    return () => {
      sessionStorage.removeItem("hasReloaded");
    };
  }, [location]);

  return shouldRender ? (
    <div
      id="chat-ppt"
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        boxShadow: "0 0 4px #000",
        overflow: "hidden",
      }}
    >
      <ChatGPTShadow />
    </div>
  ) : (
    <Loading />
  );
});

import { TerminalShell, TerminalPre, ConsoleInput } from "./TerminalStyles"



export const SimpleTerminalView = (props) => {


    return (
        <div {...props}>
            <TerminalPre id="terminal-pre" aria-label="Terminal Content">
                <span id="terminal"></span>
                <ConsoleInput
                    autoFocus
                    type="text"
                    id="terminal-input"
                    name="input"
                    autoComplete="off"
                    aria-label="Terminal Input"
                />
            </TerminalPre>
        </div>
    )
}
import { useParams } from "react-router-dom";

export const useCourseId = () => {
  const { courseId } = useParams();

  // special case: if the courseId is "" we default to it being "cs106a"
  // this should probably only stay in the cs106a branch, but shouldn't
  // break anything if it ends up in main

  if (!courseId) {
    const path = window.location.pathname;
    if(path.split("/").length > 1 && path.split("/")[1] === "alumni") {
      return "alumni";
    }
    return "spr23";
  }

  return courseId;
};

export const isSpanishCourse = () => {
  const url = window.location.pathname;
  return url.includes("uniandes");
}
import { doc, collection, getDoc, getDocs, getFirestore, setDoc, deleteDoc } from "firebase/firestore"

export const getDocsList = async (courseId) => {
  const db = getFirestore();
  const colRef = collection(db, `docs/${courseId}/libraries`);
  try {
    const docSetResponse = await getDocs(colRef);
    return docSetResponse.docs;

  } catch (e) {
    console.log(e)
    return []
  }
}


export const loadDocData = async (docsId, courseId) => {
  const db = getFirestore();
  const docRef = doc(db, `docs/${courseId}/libraries/${docsId}`);
  try {
    const docSetResponse = await getDoc(docRef);
    return docSetResponse.data();

  } catch (e) {
    console.log(e)
    return {}
  }
}


export const createNewDoc = async (courseId, id, title) => {
  const db = getFirestore();
  const docRef = doc(db, `docs/${courseId}/libraries/${id}`);
  setDoc(docRef, {
    name: title
  })
}


export const deleteDocSet = async (id, courseId) => {
  const db = getFirestore();
  const docRef = doc(db, `docs/${courseId}/libraries/${id}`);
  deleteDoc(docRef)
}

import { useContext, useEffect, useRef, useState } from "react"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"


import { FaEdit, FaInfoCircle, FaCheck, FaRegCircle, FaBars } from "react-icons/fa"
import { AssnContext } from "course/contexts/AssnContext"
import { CompletionContext } from "contexts/CompletionContext"
import { RoadmapContext } from "course/contexts/RoadmapContext"
import { PracticeProblemsTable } from "./PracticeProblemsTable"
import { PartialLoading } from "components/loading/Loading"
import { checkIsDefault } from "./PracticeProblemsFilters"
import { filterDefaults } from "./filterDefaults"
import { useProSidebar } from "react-pro-sidebar"

const assnColumns = [
  {
    dataField: 'isComplete',
    text: 'Done?',
    formatter: (completed) => {
      return completed ? <FaCheck className='text-success' /> : <FaRegCircle />
    },
    sort: true,
    headerStyle: () => {
      return { width: '100px' };
    },
    style: () => {
      return { width: '100px' };
    }
  },
  {
    dataField: 'assnLink',
    text: 'Challenge',
    style: () => {
      return { width: '400px' };
    }
  },
  {
    dataField: 'assnGroup',
    text: 'Unit',
    sort: true
  },
]



export const collectAssignments = (assignments) => {
  // TODO: sort by last modified date!
  // TODO: @Ali: i think we need a createdOn date.
  // Note: we have a createdOn date now, but I think its fine without sorting
  // we need the current page url



  const [projectsList, setProjects] = useState([])
  const [metaData, setMetaData] = useState({})
  const { courseId } = useParams()

  const url = new URL(window.location.href)
  const subpath = '/' + url.pathname.slice(1) // removes the leading slash

  const {
    // assignments,
    assnMetaData,
    groupProjects
  } = useContext(AssnContext)
  const { isAssnCompleted } = useContext(CompletionContext)



  useEffect(() => {
    const newMetaData = {}
    const newList = []
    collectAssignments(newList, newMetaData)
    collectGroupProjects(newList, newMetaData)
    setProjects(newList)
    setMetaData(newMetaData)
  }, [assignments])

  const collectAssignments = (newList, newMetaData) => {
    for (const assn of assignments) {

      const data = assnMetaData[assn.assnId]
      if (!data) continue
      const assnKey = `assns/${assn.assnId}`
      newList.push(assnKey)
      newMetaData[assnKey] = {
        ...data,
        isAssn: true,
        isGroup: false,
        assnId: assn.assnId,
        assnGroup: assn.assnGroup.title,
        isComplete: isAssnCompleted(assn.assnId),
        assnLink: <Link
          to={`/${courseId}/ide/a/${assn.assnId}`}
          state={{ from: subpath }}
        >{data.title}</Link>,
        edit: (
          <Link
            to={`/${courseId}/ide/a/${assn.assnId}`}
            state={{ from: subpath }}
            className="btn btn-light btn-sm">
            <FaEdit /> Edit
          </Link>
        )

      }
    }
  }



  const collectGroupProjects = (newList, newMetaData) => {

    for (const assnId in groupProjects) {
      const data = Object.fromEntries(Object.entries(newMetaData[`assns/${assnId}`]))
      data.title = data.title + ' (Group)'
      const projectIds = groupProjects[assnId]
      if (!data) continue
      const projectKey = `peer/${assnId}_g`
      newList.push(projectKey)
      newMetaData[projectKey] = {
        ...data,
        isAssn: false,
        isGroup: true,
        projectId: projectIds,
        isComplete: isAssnCompleted(assnId),
        assnGroup: "Group Project"

      }
    }
  }



  return [
    projectsList,
    metaData
  ]
}

export const RoadmapAssignments = ({ narrow }) => {
  const { getAssignmentsInRoadmap } = useContext(RoadmapContext)
  const assignments = getAssignmentsInRoadmap(true)
  return <ChallengesDisplay assignments={assignments} title={"Your Coding Challenges"} narrow={narrow} />
}

const ChallengesDisplay = ({ assignments, title, narrow }) => {
  // filter states
  const [filteredChallengeData, setFilteredChallengeData] = useState([])
  const [activeFilters, setActiveFilters] = useState({
    "completedFilter": filterDefaults.completed, // or bool
    "groupFilter": filterDefaults.group // or group name
  })
  const [isDefault, setIsDefault] = useState(true)
  const [projectsList, metaData] = collectAssignments(assignments)
  if (!metaData || !projectsList) return <></>

  const handleClearFilters = () => {
    setActiveFilters({
      "completedFilter": filterDefaults.completed, // or bool
      "groupFilter": filterDefaults.group // or group name
    })
  }

  function Filters() {
    return (
      <div className="d-flex align-items-center justify-content-between px-1">
        <h3 className="mt-4">{title}<CodingTasksInfo /></h3>
        
        <ToggleAside/>
      </div>
    )
  }
  useEffect(() => {
    if (!metaData) { return }
    // apply filters
    let newFiltered = Object.entries(metaData).map(([key, value]) => value)

    if (activeFilters['completedFilter'] != filterDefaults.completed) {
      newFiltered = newFiltered.filter(
        challengeData => challengeData.isComplete == activeFilters['completedFilter']
      )
    }
    if (activeFilters['groupFilter'] != filterDefaults.group) {
      newFiltered = newFiltered.filter(
        problemData => problemData.assnGroup == activeFilters['groupFilter']
      )
    }
    setFilteredChallengeData(newFiltered)
    setIsDefault(checkIsDefault(activeFilters))
  },
    [
      metaData,
      activeFilters
    ])

  if (!filteredChallengeData) { return <PartialLoading /> }
  return (
    <div className="d-flex flex-column" style={{overflow:'hidden'}}>
      <Filters />
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <PracticeProblemsTable
          practiceProblemData={filteredChallengeData}
          columns={assnColumns}
        />
      </div>
    </div>
  )
}

const ToggleAside = (props) => {
  const { broken, toggleSidebar } = useProSidebar();
  return (
      <>{broken && (
        <button
          className="btn btn-sm btn-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
      </>
  );
};

export const CodingTasksInfo = (props) => {
  const onClick = () => {
    Swal.fire({
      title: 'Coding Challenges?',
      text: `Coding is the most important part of the Code in Place learning experience. 
      Try to complete all of these coding challenges. If you find them hard, ask for help on the forum. If you complete them with time left over, build a creative project and show us what you can do with what you have learned so far!`,
      icon: 'info',

    })
  }

  const infoStyle = {
    marginTop: "-2px"
  }

  return <button
    onClick={onClick}
    className="btn btn-link"
    style={infoStyle} >
    <FaInfoCircle />
  </button>
}
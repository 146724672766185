import { deepCopy } from "@firebase/util"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useState, useEffect } from "react"
import { getProjectType } from "ide/utils/general"
import { HiddenExample } from "./HiddenExample"


export const EditHiddenExamples = ({ serverAssnData, courseId, assnId }) => {
  const serverHiddenExamples = serverAssnData?.hiddenExamples?.hiddenExamples
  const initHiddenExamples = serverHiddenExamples ? serverHiddenExamples : []
  
  const [hiddenExamples, setHiddenExamples] = useState(initHiddenExamples)
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const assnType = getProjectType({}, serverAssnData)

  const saveHiddenExamples = () => {
    console.log("saving hiddenExamples to server: ")
    console.log(hiddenExamples)
    setIsSaving(true)
    saveToDatabase(courseId, assnId, hiddenExamples, () => {
      setDirtyBit(false)
      setIsSaving(false)
    })
  }

  const deleteHiddenExample = (i) => {
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExample = deepCopy(oldHiddenExamples)
      newHiddenExample.splice(i, 1)
      return newHiddenExample
    })
    setDirtyBit(true)
  }

  const editHiddenExample = (i, key, value) => {
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExample = deepCopy(oldHiddenExamples)
      newHiddenExample[i][key] = value  
      return newHiddenExample
    })
    setDirtyBit(true)
  }

  const addHiddenExample = () => {
    switch (assnType) {
      case "karel":
        break
      case 'console':
        setHiddenExamples(oldHiddenExamples => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const starterCode = serverAssnData?.starterCode ? serverAssnData?.starterCode : ''
          const newHiddenExample = deepCopy(oldHiddenExamples)
          newHiddenExample.push({
            'name': "New Hidden Example",
            'correctness': "",
            'code': starterCode["main.py"] ?? "",
          })
          return newHiddenExample
        })
        break
      case 'graphics':
        setHiddenExamples(oldHiddenExamples => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const starterCode = serverAssnData?.starterCode ? serverAssnData?.starterCode : ''
          const newHiddenExample = deepCopy(oldHiddenExamples)
          newHiddenExample.push({
            'name': "New Hidden Example",
            'correctness': "",
            'code': starterCode["main.py"] ?? "",
          })
          return newHiddenExample
        })
        break
    }
    setDirtyBit(true)
  }

  let saveButtonTxt = dirtyBit ? 'Save' : 'Saved'
  if (isSaving) {
    saveButtonTxt = isSaving ? 'Saving...' : saveButtonTxt
  }


  return <div className="">
    <div className='d-flex flex-column mt-3'>
      <h3>Hidden Examples</h3>
      <p>
        These code samples will be used for the autograder and automatic generation of unit tests.
      </p>

      {hiddenExamples.map(function (hiddenExampleData, i) {
        if (!hiddenExamples[i].key) {
          hiddenExamples[i].key = i + Date.now()
        }
        switch (assnType) {
          case "karel":
            break
          case "console":
            return <HiddenExample 
              hiddenExampleData={hiddenExampleData}
              editHiddenExample={(k, v) => editHiddenExample(i, k, v)}
              deleteHiddenExample={(i) => deleteHiddenExample(i)}
            />
          case "graphics":
            return <HiddenExample 
              hiddenExampleData={hiddenExampleData}
              editHiddenExample={(k, v) => editHiddenExample(i, k, v)}
              deleteHiddenExample={(i) => deleteHiddenExample(i)}
            />
        }
      })}

    </div>
    <button onClick={() => addHiddenExample()} className="btn btn-secondary ">Add Hidden Example</button>
    <button
      disabled={!dirtyBit || isSaving}
      onClick={() => saveHiddenExamples()}
      style={{ width: 100 }}
      className="btn btn-primary ml-2"
    >{saveButtonTxt}</button>
  </div>
}

async function saveToDatabase(
  courseId,
  assnId,
  hiddenExamples,
  callbackFn
) {
  const db = getFirestore()
  const path = `assns/${courseId}/assignments/${assnId}/docs/hiddenExamples`
  console.log('saveHiddenExamples', path)
  const assnRef = doc(db, path)
  await setDoc(assnRef, { hiddenExamples: hiddenExamples }, { merge: true })
  callbackFn(hiddenExamples)
}


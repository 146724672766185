import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { EditInfoModal } from './EditInfoModal';
import { getFunctions, httpsCallable } from "firebase/functions"
import Swal from "sweetalert2";

export const SentRequestWidget = ({
    connectionData, 
    userId, 
    viewerId, 
    userData,
    toggle,
    status,
    setStatus,
    setConnectionData,
    setToggle
}) => {
    const functions = getFunctions();
    const unsendConnectionRequest = httpsCallable(functions, "unsendConnectionRequest");
    const [unsendLoading, setUnsendLoading] = useState(false)

    const handleUnsendConnectionRequest= async () => {
        setUnsendLoading(true)

        const res = await unsendConnectionRequest({
            viewerId, 
            userId,
        })
        // @ts-ignore
        if (res.data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Request unsent.',
            })
            setUnsendLoading(false)
            setStatus('notConnected')
            setConnectionData(() => {return null})
            setToggle('')
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setUnsendLoading(false)
        }
    }

    if (toggle === 'showSentRequest') {
        return <>
            <Alert className='mt-3' style={{backgroundColor:"#EFF1FB"}}>
                <Alert.Heading>Your request has been sent!</Alert.Heading>
                <hr />
                <label className="form-label">Your Message</label>
                <p>
                    {connectionData[`${viewerId}_msg`]}
                </p>
                <label className="form-label">Your Contact Info</label>
                <div className="description">
                    {userData.displayName} will not be able to see your contact info until they accept your request.
                </div>
                <p>
                    {connectionData[`${viewerId}_contact`]}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <EditInfoModal
                        connectionData={connectionData}
                        userId={userId}
                        viewerId={viewerId}
                        userData={userData}
                        status={status}
                        setConnectionData={setConnectionData}
                        buttonDisabled={unsendLoading}
                    />
                    <Button variant="outline-danger" className="ml-2" onClick={handleUnsendConnectionRequest} disabled={unsendLoading}>
                        {unsendLoading ? 'Unsending...' : 'Unsend request'}
                    </Button>
                </div>
            </Alert>
        </>
    }
    return <></>
}

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useWindowSize } from 'react-use-size';
import styled from 'styled-components';

export const CoursePageSingleCol = ({ column }) => {

  const windowSize = useWindowSize();



  return (
    <Outer>
      <Container>
          {column}
      </Container>
    </Outer>
  );
};

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  width: 100%;

  @media (max-width: 1075px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 950px;
  padding-right: 0px;
  flex-grow: 1;
`
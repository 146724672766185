import React, { useContext, useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import Swal from 'sweetalert2'
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import { ReactComponent as GoogleIconColor } from "icons/google.svg";
import { ReactComponent as CanvasIcon } from "icons/canvaslms.svg";
import { LoginContext, LoginScreen } from "./LoginContext";
import { SignInButton } from "./SignInButton";
import { CourseContext } from "contexts/CourseContext";
import { useCourseId } from "hooks/router/useUrlParams";

export const AlternateAuth = ({
  auth,
  btnVariant = "outline-primary"
}) => {
  const provider = new GoogleAuthProvider();
  const [loginState, setLoginState] = useContext(LoginContext)
  const { courseCanvasAuthLink } = useContext(CourseContext)
  const [canvasAuthLink, setCanvasAuthLink] = useState(courseCanvasAuthLink)
  const courseId = useCourseId()
  const isCS105 = courseId === 'cs105f24'

  console.log(window.location.hostname)

  useEffect(() => {
    setCanvasAuthLink(courseCanvasAuthLink)
  }, [courseCanvasAuthLink])

  const handleGoogleSignin = () => {
    signInWithRedirect(auth, provider)
    getRedirectResult(auth)
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message,
        });
      });
  }

  const handleCanvasSignin = () => {
    // Check if auth link exists
    if(!canvasAuthLink) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Canvas authentication is not available for this course.",
      });
      return
    }
    // add return type and return url to auth link as state
    let authLink = canvasAuthLink
    const returnUrl = encodeURIComponent(window.location.href)
    const returnType = 'canvas'
    const stateObj = {
      returnUrl,
      returnType,
      courseId,
      isLocalhost: window.location.hostname === 'localhost' ? "true" : "false"
    }
    // add state param to authLink
    const state = encodeURIComponent(JSON.stringify(stateObj))
    authLink += `&state=${state}`
    // go to auth link
    window.location.href = authLink
  }

  return (
    <div className="d-flex flex-column justify-content-center align-content-center">
      <SignInButton
        className="" 
        variant={btnVariant} 
        onClick={handleGoogleSignin}>
        <GoogleIconColor style={{ width: '24px', height: '24px' }} />&nbsp;
        Continue with Google
      </SignInButton>
      { canvasAuthLink && <SignInButton
        className="d-inline-flex align-items-center" 
        variant={btnVariant} 
        onClick={handleCanvasSignin}>
        <CanvasIcon style={{ width: '24px', height: '24px' }} />&nbsp;
        Continue with Canvas
      </SignInButton>}
      {/* TODO: Add sign in removal options to Course Context */}
      {!isCS105 && <SignInButton 
        className="d-inline-flex align-items-center mb-2" 
        variant={btnVariant} 
        onClick={() => setLoginState(LoginScreen.EMAIL_LINK)}>
        <FaEnvelope style={{ width: '24px', height: '24px' }} />&nbsp;
        Continue with One-Time Link
      </SignInButton>}
    </div>
  )
}

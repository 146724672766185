/*
* Last edited by TJ Jefferson (tjj@stanford.edu)
* 9/1/23
*/
import { collection, doc, getDocs, getFirestore, onSnapshot, updateDoc, writeBatch, setDoc, query, where, arrayRemove, deleteField, arrayUnion } from "firebase/firestore";
import React, { useEffect, useState, useContext } from "react";
import "firebase/compat/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getAuth } from "firebase/auth";
import { isMinimumRole } from 'contexts/profile_util';
import { ProfileContext } from 'contexts/ProfileContext';
import { Role } from "types/role";
import { getDoubleValue } from "course/editors/OnEventAlerts";

export type LessonMetadataType = {
  title: string,
  type: string,
  publish: boolean,
  lessonNum: number
}

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */



const defaultData = {
  lessonsMap: {},
  lessonsProgress: {},
  docIdToLessonId: {},
  onLessonsOrderChange: (newLessonOrder) => { },
  onSlidesOrderChange: (newItemOrder, lessonId) => { },
  addLessonToDB: (lessonTitle, lessonId, lessonType) => { },
  appendSlide: (lessonId, slideId, title, type) => { },
  deleteSlide: (itemId, lessonId) => { },
  deleteLessonFromDB: (lessonId) => { },
  editLessonTitleInDB: (lessonId, lessonTitle) => { },
  setLessonPublishedStatus: async (lessonId, publishStatus) => { },
  getAllWorkedExamples: () => []
};


export const LessonsContext = React.createContext(defaultData);


// LessonsProvider - To wrap necessary components
export const LessonsProvider = ({ courseId, children }) => {

  const db = getFirestore()
  const auth = getAuth();
  const user = auth.currentUser;
  const { userData } = useContext(ProfileContext)
  const courseRole = userData?.courseRole



  // Get each lesson doc from lessons collection
  const lessonsDocsRef = collection(db, `lessons/${courseId}/lessonsList`);


  // Frontend check for admin permissions. Fetch only published if not an Admin
  // Yes this check means that students could technically access unpublished course material by querying the db, but we're not particularly worried about that.
  const lessonsQuery = (isAdmin) => isAdmin ? lessonsDocsRef : query(lessonsDocsRef, where("publish", "==", true));

  const typesWithDescriptions = ["resource", "video", "example", "reading"]

  // Mark as Completed data
  const lessonsProgressDocRef = doc(db, `/users/${user.uid}/${courseId}/lessonsProgress`);
  const lessonsColRef = collection(db, `/lessons/${courseId}/lessonsList`);


  // data for sidebar
  // const [lessonsListDocs, lessonsListDocsLoading] = useCollectionData(lessonsListRef)
  const [lessonsMap, setLessonsMap] = useState({})
  const [docIdToLessonId, setDocIdToLessonId] = useState({})
  // const [lessonDataMap, setLessonDataMap] = useState({})


  let unsubscribeFromAdminLessonListner;


  // lessonsProgress
  const [lessonsProgress, lessonsProgressLoading] = useDocumentData(lessonsProgressDocRef);


  // lesson Documents
  // const [lessonDocs, lessonDocsLoading] = useCollectionData(lessonsDocsRef);



  /*
  * When the lessonsContext is first initialized, we fill the lessonsMap by getting all of the docs.
  * If user is an admin, we also subscribe them to the lessonsList, so they get live updates when lessons are edited
  */
  useEffect(() => {
    const fillLessonMap = async (isAdmin) => {
      // Get lessons docs
      const lessonDocs = await getDocs(lessonsQuery(isAdmin));
      convertDocsToLessonMaps(lessonDocs)
    }

    const isAdmin = isMinimumRole(courseRole, Role.ADMIN)

    if (isAdmin) {
      unsubscribeFromAdminLessonListner = onSnapshot(lessonsColRef, (snapshot) => {
        // When lessons are updated, we reset the lesson map.
        // We're not particularly concerned if they're the one that updates it. 
        // This saves us from having to maintain state on frontend, which we are ok with in small cases.
        convertDocsToLessonMaps(snapshot)
      });
    }


    fillLessonMap(isAdmin)

    return () => {
      if (unsubscribeFromAdminLessonListner) {

        unsubscribeFromAdminLessonListner()
      }
    };

  }, [courseRole])


  /*
  *
  */
  const convertDocsToLessonMaps = (lessonDocs) => {
    // init maps
    const lessonsMapUnfilled = {}
    const docIdToLessonIdUnfilled = {}

    // loop through lessons
    for (var lessonDoc of lessonDocs.docs) {
      // add each lesson to map
      lessonsMapUnfilled[lessonDoc.id] = {
        lessonId: lessonDoc.id,
        lessonNum: lessonDoc.data().lessonNum,

        title: lessonDoc.data().title,
        publish: lessonDoc.data().publish,
        items: {},
        data: {},
        loaded: false,
        type: lessonDoc.data().type,

        ...lessonDoc.data()
      }

      // Update docId to LessonId.
      if (!lessonDoc.data().itemsInfo) {
        continue;
      }
      for (var item of lessonDoc.data().itemsInfo) {
        // if(item.type === "header") {
        //   continue;
        // }
        docIdToLessonIdUnfilled[item.docId] = lessonDoc.id
        lessonsMapUnfilled[lessonDoc.id].items[item.docId] = {
          ...item,
          docId: item.docId
        }

      }


    }

    setDocIdToLessonId(docIdToLessonIdUnfilled)
    setLessonsMap(lessonsMapUnfilled)
  }


  // When lessons are swapped, take the new order, and set the lessonNum to their index + 1
  // Updates all lesson docs so we batch it.
  const onLessonsOrderChange = (newOrder) => {
    const batch = writeBatch(db)
    const oldLessonsMap = { ...lessonsMap }
    for (var i = 0; i < newOrder.length; i++) {
      oldLessonsMap[newOrder[i]].lessonNum = i + 1
      batch.update(doc(db, `lessons/${courseId}/lessonsList/${newOrder[i]}`), {
        "lessonNum": i + 1,
      })
    }
    batch.commit()

  }


  // When item orders change, we regenerate the itemsInfo attribute
  const onSlidesOrderChange = (newSlidesList, lessonId) => {
    const lessonsDoc = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
    updateDoc(lessonsDoc, {
      slides: newSlidesList
    })
  }



  // When we add a new lesson to the db, we create and set the lesson doc.
  const addLessonToDB = async (lessonTitle, lessonId, lessonType) => {
    const newLessonData = {
      itemsInfo: [],
      publish: false,
      title: lessonTitle,
      type: lessonType
    }

    await setDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`), newLessonData);
  }


  // When adding an item, we take the critical components, and add them to their parent lesson's itemsInfo
  // Then we create a new item doc
  const appendSlide = (slideLesson, slideId, slideTitle, slideType) => {

    // make this safe even if lessonsMap is undefined
    //lessonsMap[slideLesson]?.slides || []
    var slides = []
    if (lessonsMap && lessonsMap[slideLesson] && lessonsMap[slideLesson].slides) {
      slides = lessonsMap[slideLesson].slides
    }
    slides.push(slideId)

    const slideData = {
      'title': slideTitle,
      'type': slideType,
    }

    const updateData = {
      [`slidesInfo.${slideId}`]: slideData,
      slides: slides
    };

    const docRef = doc(db, `lessons/${courseId}/lessonsList/${slideLesson}`)
    updateDoc(docRef, updateData)
  }

  // To delete an item, we have to reset the item list and delete the item doc.
  const deleteSlide = async (itemId, lessonId) => {


    const updateData = {
      slides: arrayRemove(itemId),
      [`slidesInfo.${itemId}`]: deleteField()
    };

    const docRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
    updateDoc(docRef, updateData)
    // await deleteDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}`))
    // try {
    //   await deleteDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}-description`))
    // } catch(e) {
    //   console.log(e.message)
    // }

    // const updatedLessonsMap = { ... lessonsMap }

    // delete updatedLessonsMap[lessonId].items[itemId]
    // const itemsInfo = Object.keys(updatedLessonsMap[lessonId].items).map((itemKey) =>  updatedLessonsMap[lessonId].items[itemKey])
    // await updateDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`), {
    //   itemsInfo
    // });

  }


  // Delete all child items then delete the lesson. Done in a batch
  const deleteLessonFromDB = async (lessonId) => {
    const batch = writeBatch(db)

    for (var itemId of Object.keys(lessonsMap[lessonId].items)) {
      batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}`))
      if (typesWithDescriptions.includes(lessonsMap[lessonId].items[itemId].type)) {
        batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${itemId}-description`))
      }
    }
    batch.delete(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`))
    batch.commit()

  }




  // Simple update
  const setLessonPublishedStatus = async (lessonId, publishedStatus) => {
    await updateDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`), {
      publish: publishedStatus
    })
  }


  // Check the data map to see if it has already been fetched, if not fetch from the db.
  // const getItemData = async (docId, lessonId) => {
  //   if ('key' in lessonDataMap) {
  //     return lessonDataMap[docId]
  //   }

  //   try {
  //     const prevLessonDataMap = {...lessonDataMap}
  //     const itemRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}/itemsList/${docId}`)
  //     const docResp = await getDoc(itemRef)
  //     prevLessonDataMap[docId] = docResp.data()
  //     setLessonDataMap(prevLessonDataMap)
  //     return prevLessonDataMap[docId]
  //   } catch (e) {
  //     return {}
  //   }

  // }

  // update lesson title
  const editLessonTitleInDB = async (lessonId, lessonTitle) => {
    await updateDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`), {
      title: lessonTitle
    })

  }



  // This is for item titles.
  const saveItemTitleInDB = async (title, docId, lessonId) => {
    const oldLessonsMap = { ...lessonsMap }
    if (oldLessonsMap[lessonId].items[docId].title === title) { return; }


    oldLessonsMap[lessonId].items[docId].title = title
    const newItemsList = Object.keys(oldLessonsMap[lessonId].items).map((itemKey) => oldLessonsMap[lessonId].items[itemKey])
    await updateDoc(doc(db, `lessons/${courseId}/lessonsList/${lessonId}`), {
      itemsInfo: newItemsList
    })
  }


  /*
  *                     assignments.push({
                        moduleId: moduleId,
                        assnGroup: {
                            title: module.title,
                            id: moduleId
                        },
                        assnId: item.urlId,
                        title: item.title,
                    })
  *
  */


  const getAllWorkedExamples = () => {
    const assignments = []
    const lessonKeys = Object.keys(lessonsMap)
    if (!lessonKeys || lessonKeys.length === 0) {
      return assignments
    }
    for (var lessonKey of lessonKeys) {
      const lesson = lessonsMap[lessonKey]
      const examples = lesson.examples
      if (!examples) continue
      for (var example of examples) {
        assignments.push({
          assnGroup: {
            title: lesson.title,
            id: lessonKey
          },
          assnId: example.assnId,
          title: example.title
        })
      }
      /* old way of fetching worked examples */
      // const itemKeys = Object.keys(lesson.items)
      // for(var itemKey of itemKeys) {
      //   const item = lesson.items[itemKey]
      //   if(item.type === "example") {
      //     assignments.push({
      //       assnGroup: {
      //         title: lesson.title,
      //         id: lessonKey
      //       }, 
      //       assnId: item.docId,
      //       title: item.title
      //     })
      //   }
      // }
    }
    return assignments
  }




  return (
    <LessonsContext.Provider
      value={{
        lessonsMap,
        lessonsProgress,
        onLessonsOrderChange,
        onSlidesOrderChange,
        docIdToLessonId,
        addLessonToDB,
        appendSlide,
        deleteLessonFromDB,
        deleteSlide,
        editLessonTitleInDB,
        setLessonPublishedStatus,
        getAllWorkedExamples
      }}
    >
      {children}
    </LessonsContext.Provider>
  );
};

export const editReading = async (oldList, index, title, url, courseId, lessonId) => {
  const db = getFirestore()
  const newValues = await getDoubleValue('Edit Reading', 'Title', 'Url', false, title, url)
  if (!newValues) return
  console.log(newValues)
  const newTitle = newValues.item1
  const newUrl = newValues.item2
  const lessonRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
  const newReadings = [...oldList]
  newReadings[index] = { title: newTitle, url: newUrl }
  setDoc(lessonRef, {
    readings: newReadings
  }, { merge: true })
}

export const createReading = async (courseId, lessonId) => {
  const db = getFirestore()
  const newValues = await getDoubleValue('Create Reading', 'Title', 'Url')
  if (!newValues) return
  console.log(newValues)
  const newTitle = newValues.item1
  const newUrl = newValues.item2
  if (!newTitle || !newUrl) return
  const lessonRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
  setDoc(lessonRef, {
    readings: arrayUnion({ title: newTitle, url: newUrl })
  }, { merge: true })
}

export const createExample = async (courseId, lessonId) => {
  const db = getFirestore()
  const newValues = await getDoubleValue('Add Example', 'Title', 'AssnId')
  if (!newValues) return
  console.log(newValues)
  const newTitle = newValues.item1
  const newAssnId = newValues.item2
  if (!newTitle || !newAssnId) return
  const lessonRef = doc(db, `lessons/${courseId}/lessonsList/${lessonId}`)
  setDoc(lessonRef, {
    examples: arrayUnion({ title: newTitle, assnId: newAssnId })
  }, { merge: true })
}




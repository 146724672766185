const StudentQuestionsForSL = ({ questions }) => {
  return (
    <div>
      <h1>Ongoing Student Questions</h1>
      <p style={{ fontStyle: "italic" }}>
        We checked in with your students to see if they had any extra questions
        after the section. Please get in touch with these students to help them
        with the questions listed below.
      </p>
      <div className="border rounded p-3 bg-light">
        <ol>
          {questions.map((item, index) => (
            <li className="mb-2" key={index}>
              <p className="mb-1">
                {item.studentName} asked: <span>{item.question}</span>
              </p>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default StudentQuestionsForSL;

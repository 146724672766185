import { useState } from "react";

const SLReflection = ({
  previousReflection,
  submittedReflection = "",
  onSubmission,
}) => {
  const [reflection, setReflection] = useState(submittedReflection);
  const handleReflectionChange = (e) => {
    setReflection(e.target.value);
  };
  const handleSubmit = () => {
    onSubmission(reflection);
  };
  return (
    <div>
      <h1>Your Personal Reflection</h1>
      {previousReflection && previousReflection.trim() !== "" && (
        <div>
          <p>Here is what you hoped to improve in your last section:</p>
          <div className="border rounded p-2 bg-light">
            <p>{previousReflection}</p>
          </div>
        </div>
      )}
      <div className="my-2">
        <p
          style={{
            fontSize: "1.1em",
            fontWeight: "600",
            marginBottom: "5px",
          }}
        >
          What are your reflections from this week’s section? We’ll show you
          this next week so you can revisit them.
        </p>
        <textarea
          className="form-control"
          value={reflection}
          onChange={handleReflectionChange}
        ></textarea>
        <button className="btn btn-primary mt-2" onClick={handleSubmit}>
          Submit Reflection
        </button>
      </div>
      <div>
        <p>Keep up the great work, and stay tuned for next week! 👋</p>
      </div>
    </div>
  );
};

export default SLReflection;

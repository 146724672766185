import { and, getDocFromServer, getDocs, limit, or, query, startAfter, where } from "firebase/firestore";
import { useCourseId } from "hooks/router/useUrlParams";

import {
  get_project_collection_ref,
  get_user_project_submission_ref,
} from "../server/ProjectDataFetcher";
import { Loading } from "components/loading/Loading";
import { UserChip } from "course/forum/components/UserChip/UserChip";
import classNames from "classnames";

import { ProjectSubmission } from "../server/ProjectSubmission";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";

import "./styles.css";
import {
  FaEdit,
  FaHome,
  FaLink,
  FaPlus,
  FaShare,
  FaUser,
} from "react-icons/fa";
import { useContext, useEffect, useRef, useState } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Alert, Button, FloatButton, Spin } from "antd";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";



const ProjectListEntry = (props: {
  project: { id: string } & ProjectSubmission;
  selected: boolean;
  clickHandler: () => void;
}) => {
  const { project, selected, clickHandler } = props;
  return (
    <div
      className={classNames({ projectListEntry: true, selected })}
      onClick={clickHandler}
    >
      {project.projectTitle}
      <UserChip uid={project.id} showAvatar={false} name={""} />
    </div>
  );
};

const ProjectSplash = () => (
  <div className="showcaseSplash">
    <img
      src={`${process.env.PUBLIC_URL}/stanford.png`}
      alt="logo"
      width={150}
      height={150}
    />
    <div className="splashTitle">
      <span> Code in Place |</span>
      <span style={{ color: "grey", fontWeight: 30 }}> Project Showcase</span>
    </div>
    <p>
      Code in Place 2023 was an endeavour by a group of educators and volunteer
      teachers to teach introductory programming to over thousands of students
      all over the world. Based on Stanford University's CS 106A, the course
      taught the fundamentals of programming in Python.
    </p>
    <p>
      As part of their coursework, students had the opportunity to work on a
      final project of their own design. The task was to work on a problem that
      interested them using the skills they developed over the course of Code in
      Place. We saw an incredible variety of work demonstrating the breadth of
      perspectives and experiences the students brought with them. This website
      is a celebration of these projects, and of the time and effort our
      students spent getting here.
    </p>
  </div>
);

const ProjectDetails = (props: { projectId: string, courseId: string }) => {
  const { projectId, courseId } = props;
  const { userData } = useContext(ProfileContext);


  const projectQuery = useQuery(["project", projectId], () =>
    getDocFromServer(get_user_project_submission_ref(courseId, projectId))
  );

  if (projectQuery.isLoading) return <Spin />;

  const project = projectQuery.data.data();
  if (!project) return <div>Project not found</div>;

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      Swal.fire({
        title: "Link copied to clipboard",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  return (
    <div className="showcaseDetails">
      {project?.excludeFromPublicGallery && projectId == userData?.id && (
        <div className="excluded">
          <Alert
            message="This project is excluded from the public gallery, but you can still
            share this link with others! Only you will see this message, since this is your project."
            type="info"
          />
        </div>
      )}
      {project?.projectScreenshotLink && projectId == userData?.id && (
        <img
          src={project.projectScreenshotLink}
          alt="project"
          className="projectImage"
        />
      )}
      <h1>{project.projectTitle}</h1>
      <p>{project.projectDescription}</p>
      <a href={project.publishedProjectLink} target="_blank">
        <span className="linkIcon">
          <FaLink />
        </span>
        View Project
      </a>
      <a className="projectAction" onClick={copyLinkToClipboard}>
        <span className="linkIcon">
          <FaShare />
        </span>
        Share Project
      </a>
      {projectId == userData?.id && (
        <Link to={`/${courseId}/projectSubmission`}>
          <span className="linkIcon">
            <FaEdit />
          </span>
          Edit Project
        </Link>
      )}
    </div>
  );
};

export const ProjectShowcase = ({courseId}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useContext(ProfileContext);
  const [hasProject, setHasProject] = useState(false);
  const [lastProjectDoc, setLastProjectDoc] = useState(null);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);

  const projectsQuery = query(
    get_project_collection_ref(courseId),
    and(
      where("published", "==", true),
      or(
        where("excludeFromPublicGallery", "==", false),
        where("excludeFromPublicGallery", "==", null)
      )
    )
  );
  const loader = useRef(null);
  const [projectList, setProjectList] = useState([])
  const [loadingMore, setLoadingMore] = useState(false);

  const loadNextProjects = async () => {
    setLoadingMore(true);
    let projectsQueryPlus = query(projectsQuery, limit(20));
    if (lastProjectDoc) {
      projectsQueryPlus = query(
        projectsQuery,
        startAfter(lastProjectDoc),
        limit(20)
      );
    }
      const projectsDocs = await getDocs(projectsQueryPlus);
      if (projectsDocs.empty) {
        setHasMoreProjects(false);
        setLoadingMore(false);
        return []
      };
      setLastProjectDoc(projectsDocs.docs[projectsDocs.docs.length - 1]);
      const newProjects = projectsDocs.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLoadingMore(false);
      setProjectList((prevProjList) =>{
        return  [...prevProjList, ...newProjects]
        });
    }

  const getProjects = async () => {
    if (loadingMore || projectList.length === 0) {
      return [];
    }
    loadNextProjects();
  }



  useEffect(() => {
    async function checkProject() {
      const project = await getDocFromServer(
        get_user_project_submission_ref(courseId, userData.id)
      );
      setHasProject(!!project?.exists);
    }
    if (userData) checkProject();
  }, [userData, courseId]);
  

  useEffect(() => {
    loadNextProjects()
  }, [])

  useEffect(() => {
    const handleScroll = (e) => {
      if(loader.current) {
        const { scrollTop, scrollHeight, clientHeight } = loader.current;
        if (scrollTop + clientHeight >= scrollHeight && hasMoreProjects) {
          getProjects()
      }
    };

  }
    const projListEl = loader.current;
    if(projListEl) {
      projListEl.addEventListener("scroll", handleScroll);
      return () => {
        projListEl.removeEventListener("scroll", handleScroll);
      }
    }
  }, [
    projectList
  ]);

  


  const projectId = searchParams.get("project");

  return (
    <div
      className="page"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/splash4.jpg')`,
        backgroundRepeat: "round",
      }}
    >
      <div className="showcaseContainer">
        <div className="left">
          <div className="list" ref={loader}>
            {projectList.map((project) => (
              <ProjectListEntry
                project={project}
                selected={project.id === projectId}
                clickHandler={() => setSearchParams({ project: project.id })}
                key={project.id}
              />

            ))}
          </div>
        </div>
        <div className="right">
          {projectId ? (
            <ProjectDetails projectId={projectId} courseId={courseId} />
          ) : (
            <ProjectSplash />
          )}
        </div>
      </div>
      <FloatButton.Group>
        {hasProject && (
          <>
            <FloatButton
              icon={<FaEdit />}
              onClick={() => navigate(`/${courseId}/projectSubmission`)}
              tooltip="Edit your project submission"
            />
            <FloatButton
              icon={<FaUser />}
              onClick={() => setSearchParams({ project: userData.id })}
              tooltip="See your project"
            />
          </>
        )}
        <FloatButton
          icon={<FaHome />}
          onClick={() => setSearchParams({})}
          tooltip="Go to showcase home"
        />
      </FloatButton.Group>
    </div>
  );
}
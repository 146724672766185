import { MonacoTipTap } from "components/richTextEditor/TipTap/extensions/runnableKarel/TipTapCodeEditor";

export const RandomRegex = (props) => {
    
    const editName = name => {
        props.editBranch(props.index, {
            ...props.branch,
            name
        });
    }

    const updateCode = newCode => {
        props.editBranch(props.index, {
            ...props.branch,
            regex: newCode
        });
    }

    const editPyodideInput = input => {
        const arr = input.split("\n");

        props.editBranch(props.index, {
            ...props.branch,
            pyodideInput: arr
        });
    }

    const editValidation = newVal => {
        props.editBranch(props.index, {
            ...props.branch,
            validation: newVal
        });
    }

    const editTolerance = tol => {
        props.editBranch(props.index, {
            ...props.branch,
            tolerance: tol
        });
    }

    const handleAddNewVar = () => {
        props.editBranch(props.index, {
            ...props.branch,
            vars: [
                ...props.branch.vars,
                {name: `var${props.branch.vars.length + 1}`, min: 0, max:99, isRandom: false}
            ]
        });
    };

    const handleEditVar = (i, field, value) => {
        props.editBranch(props.index, {
            ...props.branch,
            vars: props.branch.vars.map((rv, idx) => idx === i ? {...rv, [field]: value} : rv)
        });
    }

    const handleRemoveVar = (i) => {
        props.editBranch(props.index, {
            ...props.branch,
            vars: props.branch.vars.filter((rv, idx) => idx !== i)
        });
    }

    const renderVariableRow = (rv, i) => (
        <div key={i} className="my-2">
            <div className="d-flex flex-row align-items-end my-2">
                <strong className="mr-2">{rv.name}</strong>
                <div className="form-group mr-2">
                    <label>Min Value</label>
                    <input type="number" className="form-control" value={rv.min} onChange={(e) => handleEditVar(i, 'min', parseInt(e.target.value))}/>
                </div>
                <div className="form-group mr-2">
                    <label>Max Value</label>
                    <input type="number" className="form-control" value={rv.max} onChange={(e) => handleEditVar(i, 'max', parseInt(e.target.value))}/>
                </div>
                <div className="form-group mr-2">
                    <label>Is Random?</label>
                    <br />
                    <input type="checkbox" className="form-check-input mx-auto" style={{width: "1rem"}} checked={rv.isRandom} onChange={(e) => handleEditVar(i, 'isRandom', !rv.isRandom)} />
                </div>
                <button className="btn btn-danger" onClick={() => handleRemoveVar(i)}>X</button>
            </div>
        </div>
    )

    return (
        <div className="border rounded p-2 mb-3 gap-2">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="form-group d-flex flex-row align-items-center">
                    <h3 className="mr-2">Name:</h3>    
                    <input type="text" className="form-control" value={props.branch.name} onChange={(e) => editName(e.target.value)}/>
                </div>
                <button className="btn btn-danger" style={{height: "fit-content"}} onClick={() => props.deleteBranch(props.index)}>X</button>
            </div>
            <strong>Input ranges for each random variable</strong>
            <div>
                {
                    props.branch.vars.map((rv, i) => renderVariableRow(rv, i))
                }
                <button className="btn btn-primary my-2" onClick={handleAddNewVar}>New Random Var</button>
                <div className="form-group my-2">
                    <label>Pyodide Input</label>
                    <textarea className="form-control" rows={2} value={props.branch.pyodideInput.join("\n")} onChange={(e) => editPyodideInput(e.target.value)}/>
                </div>
                <div className="form-group my-2">
                    <label>Validation Functions</label>
                    <input type="text" className="form-control" value={props.branch.validation} onChange={(e) => editValidation(e.target.value)}/>
                </div>
                <div className="form-group my-2">
                    <label>Tolerance</label>
                    <input type="number" className="form-control" value={props.branch.tolerance} onChange={(e) => editTolerance(parseFloat(e.target.value))}/>
                </div>
            </div>
            <b className="mt-2">Variable Capture Regex:</b>
            <MonacoTipTap
                mode="python"
                value={props.branch.regex}
                onChange={(e) => updateCode(e)}
                readOnly={false}
                style={{ width: "100%" }}
            />
        </div>
    )
}
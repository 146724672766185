import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { useParams } from "react-router";
import { Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { EVENT_TYPES, useEvents } from "../../firebase/realtime/Events";
import { FaHandshake } from "react-icons/fa";

import { joinTeachNow, leaveTeachNow, useTeachNowTeacherStatus, getNumTeachNowSessionsAndGratitude, useTeachNowNumSessions } from "../../firebase/realtime/TeachNow";
import { useUserId } from "hooks/user/useUserId";
import { useActiveRoom } from "../../firebase/realtime/Rooms";
import { ProfileContext } from "contexts/ProfileContext";
import { isMentor, isSectionLeader, isStaffRole } from "contexts/profile_util";
import { useCourseId } from "hooks/router/useUrlParams";
import styled from 'styled-components';
import { FaStar } from 'react-icons/fa'; // Import icons from 'react-icons' library
import { CoursePageBodyContainer } from "../../components/layout/CoursePageBodyContainer"
import { Loading } from "components/loading/Loading";


const encouragementMessages = [
  { title: "Great job today!", message: "You made a difference in a student's life." },
  { title: "Well done on another session!", message: "Thank you for your hard work and dedication." },
  { title: "Congratulations on your session!", message: "Thank you for your time and effort. Students are lucky to have your help!" },
  { title: "Good work today!", message: "Your work is making a difference in students' lives." },
  { title: "Thank you for teaching!", message: "Your help is important for helping students learn and grow." },
];

const PageContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px;
`;

const Title = styled.h1`
  color: #555;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
`;

const MessageList = styled.div`
  margin-top: 20px;
  // border: 1px solid #ccc;
  // max-height: 500px;
  // overflow-y: scroll;
  // padding: 10px;
`;

const MessageCard = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StudentName = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
  // color: #2a6496;
  font-size: 18px;
`;

const SessionDateTime = styled.span`
  display: inline-block;
  color: #666;
  font-size: 12px;
  margin-top: 5px;
`;

const GratitudeContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-left: 5px solid gold;
  border-radius: 5px;
`;

const formatDate = (timestamp) => {
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  const date = new Date(milliseconds);
  return date.toLocaleString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', hour12: true
  });
};

const SessionCard = ({ session }) => {
  // Select a random message, but consistently for each session
  const sessionId = session.roomId;
  const sessionHash = sessionId.split('-').reduce((acc, val) => acc + parseInt(val, 16), 0);
  const randMessageIdx = sessionHash % encouragementMessages.length;
  const randomMessage = encouragementMessages[randMessageIdx];

  const hasGratitude = session.gratitudeResponses.length > 0;
  const hasMessageToSL = session.gratitudeResponses.some(response => response.gratitudeMessageToSL);

  return (
    <div style={{ padding: '10px' }}>
      <div style={{display: "flex", "justifyContent": "space-between"}}>
        <StudentName>{session.studentDisplayNames.join(', ')}</StudentName>
        <SessionDateTime>{formatDate(session.sessionTime)}</SessionDateTime>
      </div>
      <div style={{marginBottom: "5px"}}>
          <span>{randomMessage.title}</span>
          <span>&nbsp;{randomMessage.message}</span>
      </div>

      {
      hasGratitude ? (
        <SessionGratitude gratitudeResponses={session.gratitudeResponses} />
      ) : null }
    </div>
  );
}

const SessionGratitude = ({ gratitudeResponses }) => {
  const thankYous = gratitudeResponses.map(response => response.senderDisplayName);
  return (
    <>
    <GratitudeContainer>
    {
      gratitudeResponses.map((response, idx) => (
        <div key={idx}>
          {
          response.gratitudeMessageToSL ?
            <>
            <strong><FaStar style={{ color: 'gold', marginBottom: '5px' }} /> {response.senderDisplayName} thanked you and left a message: </strong>
                <blockquote><i>{response.gratitudeMessageToSL}</i></blockquote>
            </>
          :
            <strong><FaStar style={{ color: 'gold', marginBottom: '5px' }} /> {response.senderDisplayName} thanked you! </strong>
          }

        </div>
      ))
    }
    </GratitudeContainer>
    </>
  )
}


const TeachNowSessions = () => {
  const {teacherId} = useParams();
  const courseId = useCourseId();
  // firebase permissions make sure you can only see your own unless you are an admin
  const userId = teacherId ? teacherId : useUserId();
  const [sessionInfo, setSessionInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getNumTeachNowSessionsAndGratitude(userId, courseId);
      setSessionInfo(data.sessionInfo || []);
      setIsLoading(false);
    };
    fetchData();
  }, [userId, courseId]);

  if (isLoading) {
    return <Loading />;
  }

  if (sessionInfo.length === 0) {
    return (
      <PageContainer>
        <Title>Your TeachNow Impact</Title>
        <h5>You have not taught any TeachNow sessions yet.</h5>
        <p>TeachNow is a chance for you to meet with students who are currently online and working in the IDE. Depending on the student, you can help them with coding, debugging, or just be a friendly face! This is a way to support students and create a strong sense of community for Code in Place.</p>
        <p>You can find the TeachNow button on your home page.</p>
      </PageContainer>
    );
  }

  const numThankYouMessages = sessionInfo.reduce((acc, session) => acc + session.gratitudeResponses.length, 0);


  return (
    <PageContainer>
      <Title>Your TeachNow Impact</Title>
      <h5 className="mt-3">You have taught {sessionInfo.length} TeachNow sessions!</h5>
      <MessageList>
        {sessionInfo.map((session, index) => (
          <MessageCard key={index}>
            <SessionCard session={session} />
          </MessageCard>
        ))}
      </MessageList>
    </PageContainer>
  );
};

export const TeachNowSessionsOuter = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<TeachNowSessions />}
      rightColumn={<></>}
      singleColumn={<TeachNowSessions />}
    />
  )
};

// This is a wrapper on the care hours button that only shows if the user is not a minor
// and also has the minimum role that we want
export const TeachNowButtonWrapper = ({ buttonOnly=false, isTeachNowStudent=false }) => {
  const { userData } = useContext(ProfileContext);

  const role = userData?.courseRole
  const isStaff = isStaffRole(role)

  // TODO: we can get rid of the isStaff and isMentorRole check when we release to everyoen
  const isMentorRole = isMentor(role)

  const isSLRole = isSectionLeader(role)

  let isMinor = true
  if (userData && ('isMinor' in userData)) {
    isMinor = userData.isMinor
  }
  if (isMinor) {
    return null
  }

  if (!isStaff && !isMentorRole && !isSLRole && !isTeachNowStudent) {
    return null
  }

  if(buttonOnly) return <CareHoursButton />

  return <div className="mt-4">
    <div className="splashCardRight">
      <div className="splashCardRightTitle">
        Teach Now
      </div>
      <div className="d-flex flex-column">
        <CareHoursButton />
      </div>
    </div>
  </div>
}


const OfficeHoursModal = (props) => {
    const { show, closeAndRemove, isRemoving } = props
    const [isTimerEnd, setIsTimerEnd] = useState(false)
    // radom number in range [3,5]
    const waitTime = Math.floor(Math.random() * 3) + 3;

    return (
      <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Help a Student</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{alignItems: "center"}}>
        <ClipLoader/>
        <span style={{marginLeft: "15px"}}>
          Estimated wait: {waitTime} minutes
        </span>

        </div>
        <p></p>
          We are searching for student for you to check in on. Stay on this page until you are matched.
        <p></p>
      </Modal.Body>
      <Modal.Footer>
        {/* // Don't let people leave the queue during matching */}
        <Button onClick={closeAndRemove} variant="warning" disabled={isRemoving}>
          {isRemoving ? <Spinner animation="border" /> : "Stop searching"}
        </Button>
      </Modal.Footer>
    </Modal>
    );
  }

  export const CareHoursButton = ({ disabled=false }) => {

    const {courseId} = useParams()

    // const [user] = useAuthState(auth());
    const userId = useUserId();
    const [eventsByType, removeEvent] = useEvents(userId, courseId);
    const slNoCareStudents = eventsByType[EVENT_TYPES.slNoCareStudents];

    const [isJoiningQueue, setIsJoiningQueue] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const hasActiveRoom = useActiveRoom(userId, courseId);
    const teacherStatus = useTeachNowTeacherStatus(userId, courseId);
    const teacherInQueue = teacherStatus === "joined";
    const isInSession = hasActiveRoom !== undefined && hasActiveRoom !== null

    // if(slNoCareStudents.length > 0) {
    //   const newEvent = slNoCareStudents[0];
    //   Swal.fire({
    //     title: "No Students Available",
    //     text: "We did not find any students to help at this time. Please try again later.",
    //   }).then(() => {
    //     removeEvent(newEvent);
    //   });
    // }

    const handleButtonClick = async () => {
      const result = await Swal.fire({
        title: 'TeachNow',
        html: `
        <p>
          TeachNow is a chance for you to meet with students who are currently
          online and working in the IDE. Depending on the student, you can help them with
          coding, debugging, or just be a friendly face! This is a way to support students
          and create a strong sense of community for Code in Place.
        </p>
        <p>
          Not all students need support in the same way. Some students will really want help on
          assignments and others might just want to talk to another person in this wonderful community.
        </p>

        `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (result.isConfirmed) {
        setIsJoiningQueue(true);
        const result = await joinTeachNow();
        console.log("joinTeachNow result", result)
        if (result?.data?.status === "no-zoom-accounts") {
          Swal.fire({
            title: "Come Back Later",
            text: "We are at full capacity right now. Please try again later.",
            icon: "error",
          });
        }
        setIsJoiningQueue(false);
      }
    };

    const closeAndRemove = async () => {
      setIsRemoving(true);
      await leaveTeachNow();
      setIsRemoving(false);
    }

    return (
      <>
        <OfficeHoursModal
          show={teacherInQueue}
          closeAndRemove={closeAndRemove}
          isRemoving={isRemoving}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <button
            onClick={handleButtonClick}
            className="btn btn-success mt-1"
            style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}
          >
            <div>
              <FaHandshake size={50} />
            </div>
            Teach Now
          </button> */}
          <OverlayTrigger
          overlay={<Tooltip>{isJoiningQueue ? "Joining Queue" : "Teach Now"}</Tooltip>}
          placement="top"
        >
          <button
            onClick={handleButtonClick}
            className="btn btn-success mt-1"
            style={{ width: '100%', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}
            disabled={isJoiningQueue || isInSession || disabled}
          >
            <div>
              {isJoiningQueue ? <Spinner animation="border" /> : <FaHandshake size={50} />}
            </div>
            {isJoiningQueue ? "Joining..." : "Teach Now"}
          </button>
        </OverlayTrigger>
        </div>
      </>
    );
}

// export const CareHours = () => {
//     const {courseId} = useParams()

//     const [user] = useAuthState(auth());
//     const [eventsByType, removeEvent] = useEvents(user.uid, courseId);
    // const slNoCareStudents = eventsByType[EVENT_TYPES.slNoCareStudents];

    // if(slNoCareStudents.length > 0) {
    //   const newEvent = slNoCareStudents[0];
    //   Swal.fire({
    //     title: "No Students Available",
    //     text: "We did not find any students to help at this time. Please try again later.",
    //   }).then(() => {
    //     removeEvent(newEvent);
    //   });
    // }


//     // TODO: use this to render modal if user is in queue. Can call
//     // removeSLCareTicket(user.uid, courseId) to remove them from the queue
//     const slTicketExists = useSLHasCareTicket(user.uid, courseId)

//     return (
//         <>
//         <OfficeHoursModal show={slTicketExists} closeAndRemove={() => removeSLCareTicket(user.uid, courseId)} />
//         <div style={{paddingTop: "5vh", paddingLeft: "3vw", paddingRight: "3vw"}}>
//             <div className="card text-center mx-auto">
//                 <div className="card-body" >
//                     <h5 className="card-title">SL Help</h5>
//                     <div className="card-text">
//                         Welcome to the SL help page. How would you like to help today?
//                         <div><Button onClick={() => createSLCareTicket(user.uid, courseId)}>I want to live help</Button></div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
//     )
// }
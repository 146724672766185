import {
  Role,
  RoleDisplayString,
  RoleAdminString,
  RoleForumString,
  RoleFirebaseString,
} from "types/role";
import { ProfileContextData } from "./ProfileContext";

export const isStaffRole = (courseRole: Role): boolean => {
  return courseRole in [Role.ADMIN, Role.INSTRUCTOR, Role.TA];
};

export const isInCourse = (courseRole: Role): boolean => {
  return courseRole !== Role.UNREGISTERED;
};

export const isATypeOfStudent = (courseRole: Role): boolean => {
  return courseRole === Role.STUDENT || courseRole === Role.EXPERIENCED_STUDENT;
};

export const isSectionLeader = (courseRole: Role): boolean => {
  return courseRole === Role.SECTION_LEADER;
};

export const isMentor = (courseRole: Role): boolean => {
  return courseRole === Role.MENTOR;
};

export const isExperiencedStudent = (courseRole: Role): boolean => {
  return courseRole === Role.EXPERIENCED_STUDENT;
};

export const isStudent = (courseRole: Role): boolean => {
  return courseRole === Role.STUDENT;
};

export const isInstructor = (courseRole: Role): boolean => {
  return courseRole === Role.INSTRUCTOR;
};

export const isAdmin = (courseRole: Role): boolean => {
  return courseRole === Role.ADMIN;
};

export const isMinimumRole = (
  courseRole: Role,
  requiredRole: Role
): boolean => {
  // Create a priority list of roles
  const priority = [
    Role.UNREGISTERED,
    Role.STUDENT,
    Role.EXPERIENCED_STUDENT,
    Role.MENTOR,
    Role.SECTION_LEADER,
    Role.TA,
    Role.ADMIN,
    Role.INSTRUCTOR,
  ];
  // determine if the roles are in the priority list, and if so compare their indexes
  const courseRoleIndex = priority.indexOf(courseRole);
  const requiredRoleIndex = priority.indexOf(requiredRole);

  if (courseRoleIndex === -1 || requiredRoleIndex === -1) {
    return false;
  }

  return courseRoleIndex >= requiredRoleIndex;
};

export const roleToDisplayString = (courseRole: Role): RoleDisplayString => {
  switch (courseRole) {
    case Role.UNREGISTERED:
      return "Unregistered";
    case Role.STUDENT:
      return "Student";
    case Role.SECTION_LEADER:
      return "Section Leader";
    case Role.TA:
      return "TA";
    case Role.INSTRUCTOR:
      return "Instructor";
    case Role.ADMIN:
      return "Admin";
    case Role.MENTOR:
      return "Section Leader (Mentor)";
    case Role.EXPERIENCED_STUDENT:
      return "Experienced Student";
    default:
      return "Unknown";
  }
};

export const roleToForumString = (courseRole: Role): RoleForumString => {
  switch (courseRole) {
    case Role.SECTION_LEADER:
      return "SL";
    case Role.TA:
      return "TA";
    case Role.INSTRUCTOR:
      return "Instructor";
    case Role.ADMIN:
      return "Admin";
    case Role.MENTOR:
      return "SL";
    default:
      return "";
  }
};

export const roleToAdminString = (courseRole: Role): RoleAdminString => {
  switch (courseRole) {
    case Role.UNREGISTERED:
      return "Unregistered";
    case Role.STUDENT:
      return "Student";
    case Role.SECTION_LEADER:
      return "Section Leader";
    case Role.TA:
      return "TA";
    case Role.INSTRUCTOR:
      return "Instructor";
    case Role.ADMIN:
      return "Admin";
    case Role.MENTOR:
      return "Section Leader (Mentor)";
    case Role.EXPERIENCED_STUDENT:
      return "Experienced Student";
    default:
      return "Unknown";
  }
};

export const roleToFirebaseString = (courseRole: Role): RoleFirebaseString => {
  switch (courseRole) {
    case Role.UNREGISTERED:
      return "unregistered";
    case Role.STUDENT:
      return "student";
    case Role.SECTION_LEADER:
      return "sl";
    case Role.ADMIN:
      return "admin";
    case Role.TA:
      return "ta";
    case Role.INSTRUCTOR:
      return "instructor";
    case Role.MENTOR:
      return "mentor";
    case Role.EXPERIENCED_STUDENT:
      return "experiencedstudent";
    default:
      return "unregistered";
  }
};

export const firebaseStringToRole = (firebaseStr: RoleFirebaseString): Role => {
  switch (firebaseStr) {
    case "student":
      return Role.STUDENT;
    case "sl":
      return Role.SECTION_LEADER;
    case "admin":
      return Role.ADMIN;
    case "ta":
      return Role.TA;
    case "instructor":
      return Role.INSTRUCTOR;
    case "mentor":
      return Role.MENTOR;
    case "experiencedstudent":
      return Role.EXPERIENCED_STUDENT;
    default:
      return Role.UNREGISTERED;
  }
};

export const isProfileComplete = (
  profileContext: ProfileContextData
): boolean => {
  const userData = profileContext.userData;
  const userProtectedData = profileContext.userProtectedData;

  const hasDisplayName =
    userData.displayName &&
    userData.displayName.length > 0 &&
    userData.displayName !== "Anon";
  const hasCountry = userData.country && userData.country.length > 0;
  const hasDob = userProtectedData?.dateOfBirth !== undefined;

  const hasFields = hasDisplayName && hasCountry && hasDob;
  if (!hasFields) {
    return false;
  }

  const requiredDobFields = ["day", "month", "year"];
  const dobFields = userProtectedData.dateOfBirth;
  const hasDobFields = requiredDobFields.every(
    (field) => dobFields[field] !== undefined
  );

  return hasDobFields;
};

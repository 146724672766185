import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import "./LikeButton.css";

export function LikeButton(props: {
  numLikes: number;
  isLiked: boolean;
  likeHandler: (newLike: boolean) => void;
  iconSize: number;
  showNumber?: boolean;
  disabled?: boolean;
}) {
  const { numLikes, showNumber, isLiked, likeHandler, disabled, iconSize } = props;

  let heartColor = isLiked ? "#f5222d" : "#bbbbbb";
  if(disabled){
    const lightPink = "#ffcccc"
    heartColor = lightPink;
  }
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Like
        </Tooltip>
      )}
    >
      <div className="like-button-container">
        <FaHeart
          size={iconSize}
          className="likeButton"
          style={{ color: heartColor }}
          onClick={()=>likeHandler(!isLiked)}
        />

        {showNumber && <span className="num-likes">{numLikes}</span>}
      </div>
    </OverlayTrigger>
  );
}

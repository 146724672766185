import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';

export const Terminal = Node.create({
  name: 'terminal',
  // You can add more configuration here if needed
  content: 'text*',
  code: true,
  group: 'block',
  defining: false,
  draggable: false,

  parseHTML() {
    return [
      {
        tag: 'p[data-type="terminal"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(HTMLAttributes, { 'data-type': 'terminal' }), 0];
  },
});

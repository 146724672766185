export const QUESTION1_RUBRIC = {
    "input":{
        "title": "Input",
        "rubricPreText": "This question requires you to get input from the user, convert it to a number, and save it as a variable. Did you correctly do all of these steps?"
    },
    "conditional_logic" : {
        "title": "Conditional Logic",
        "rubricPreText": "Once you have a user height, you need some conditional logic to check if they are too small, too tall, or just right. It has some tricky edge cases!",
    },
    "printing" : {
        "title":"Printing",
        "rubricPreText": "One of the concepts that this problem explores is your ability to print a message to the user. Did you get your printing mechanics correct?",
    },
    "syntax_errors" : {
        "title":"Syntax Errors",
        "rubricPreText": "You might have had some typos in your code (that is fine! you were not allowed to run the program and test)",
    },
}

export const QUESTION2_RUBRIC = {
    "looping" : {
        "title": "Looping",
        "rubricPreText": "Do you loop over the full range of numbers? The tricky part here is to make sure you loop up to MAX_NUMBER and to know the largest value that range returns.",
    },
    "checking-even-odd" : {
        "title":"Checking Even or Odd",
        "rubricPreText": "The typical way to check if a number is even or odd is to check if remainder is 0 when you divide by 2.",
    },
    "printing": {
        "title":"Printing",
        "rubricPreText":  "This problem requires some printing. Did you get the print mechanics correct?",
    },
    "syntax-errors" : {
        "title":"Syntax Errors", 
        "rubricPreText": "You might have had some typos in your code"
    },
}

export const QUESTION3_RUBRIC = {
    "firstGroup": {
        "title": "Drawing a single wave",
        "rubricPreText": "Did you correctly draw a single wave?",
    },
    "multiple": {
        "title": "Drawing multiple waves",
        "rubricPreText": "Did you correctly use a loop to draw multiple waves?",
    },
    "secondGroup": {
        "title": "Fencepost bug",
        "rubricPreText": "Did you handle drawing the last wave without running into a wall?",
    },
    "cat-1": {
        "title": "Other Errors",
        "rubricPreText": "Did Karel run without any errors?",
    },
    "syntax-errors" : {
        "title":"Syntax Errors", 
        "rubricPreText": "Were there any syntax errors in your code?"
    },
}




export const QUESTION4_RUBRIC = {
    "intro-message" : {
        "title": "Intro message",
        "rubricPreText": "Did you print the intro message correctly?",
    }, 
    "thanks-message" : {
        "title": "Thanks for playing message",
        "rubricPreText": "Did you print the thanks for playing message correctly?",
    },
    "sequence-length-message" : {
        "title": "Sequence Length Message",
        "rubricPreText": "Did you print the sequence length message correctly?",
    },
    "create-sequence-length-variable" : {
        "title": "Creating the sequence length variable",
        "rubricPreText": "This problem required a variable to keep track of the sequence length. Did you properly set up this variable?",
    },
    "update-sequence-length" : {
        "title": "Updating the sequence length variable",
        "rubricPreText": "Did you properly update the sequence length variable througout the program?",
    },
    "looping" : {
        "title": "Looping",
        "rubricPreText": "Do you have proper loop mechanics? One hard part of this problem is determining when to end the loop. It should be as soon as a number is less than the previous number.",
    },
    "user-input" : {
        "title": "Getting user input",
        "rubricPreText": "Did you properly get user input and convert it to a number?",
    },
    "syntax-errors" : {
        "title": "Syntax Errors",
        "rubricPreText": "You might have had some typos in your code."
    }

}

export const QUESTION5_RUBRIC = {
    "draws-two-cars" : {
        "title": "Successfully Draws two Cars",
        "rubricPreText": "Did you successfully draw exactly two cars?",
    },
    "preserves-intention-of-draw-car": {
        "title": "Preserves Intention of draw_car()",
        "rubricPreText": "Do your edits preserve the intention in the comment of draw_car()?",
    }
}
import React, { useContext, useState } from 'react';
import 'firebase/compat/firestore';
import { useParams } from 'react-router-dom';
import { LessonsContext } from "../../contexts/LessonsContext";
import { CoursePageBodyContainer } from '../../../components/layout/CoursePageBodyContainer';
import { lessonEditorNavbarHeight } from './constants';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { SlideEditor } from './editors/SlideEditor';
import { Loading } from 'components/loading/Loading';
import { ProfileContext } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import { lessonSchemas } from 'components/duolessons/server/LessonServerHelper';
import { TrainingContext } from 'course/contexts/TrainingContext'



export const LessonEditor = () => {
  const { userData } = useContext(ProfileContext)
  const isAdmin = Gate.hasAdminRole(userData)
  if (!isAdmin) {
    return <></>
  }

  return <CoursePageBodyContainer
    mainColumn={<LessonItemEditorColumn />}
    rightColumn={<></>}
    singleColumn={<LessonItemEditorColumn />}
  />
}

const LessonItemEditorColumn = () => {
  const { editorType, docId } = useParams();

  const { lessonsMap } = useContext(editorType == 'student' ? LessonsContext : TrainingContext);
  const { lessonId } = useParams();

  // TODO - check that docType in document equals docType in sidebar
  if (Object.keys(lessonsMap).length === 0) {
    return <></>
  }

  const slideData = lessonsMap[lessonId].slidesInfo[docId]

  if (!slideData) {
    return <Loading />
  }
  let schema = [...defaultSchema]

  if (slideData.type in lessonSchemas) {
    let schemaForType = [...lessonSchemas[slideData.type]]
    schema = schema.concat(schemaForType)
  }

  return <>
    <LessonEditorNavBar />
    <SlideEditor
      slideData={slideData}
      slideId={docId}
      lessonId={lessonId}
      schema={schema}
    />
  </>

}


export const LessonEditorNavBar = () => {
  const { editorType, courseId, lessonId } = useParams();
  const navigate = useNavigate()
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: `${lessonEditorNavbarHeight}px`,
        borderBottom: "2px solid #CCC"

      }}>

      <button className="btn btn-light" onClick={() => navigate(`/${courseId}/lessoneditor/${editorType}/${lessonId}`)}>
        <FaChevronLeft size="20px" style={{ marginRight: "5px" }} />
        Back
      </button>

    </div>
  )
}

let defaultSchema = [
  {
    key: 'title',
    type: 'string',
    label: 'Title',
  },
  {
    key: 'lengthMins',
    type: 'number',
    label: 'Length (minutes)'
  },
  {
    key: 'hideFromOutline',
    type: 'boolean',
    label: 'Hide From Outline?'
  },
  {
    key: 'unskippable',
    type: 'boolean',
    label: 'Unskippable?'
  },
  {
    key: 'confetti',
    type: 'boolean',
    label: 'Confetti on Correct?'
  }
]

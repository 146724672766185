import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useCourseId } from "hooks/router/useUrlParams";

const Question = styled.h2`
  text-decoration: underline;
  margin-bottom: 1rem;
`;
const PeerHighlightQuestions = ({
  submittedQuestion,
  onQuestionSubmit,
  questionsAsked,
  numberOfQuestionsAskedByClass,
}) => {
  const courseId = useCourseId()
  const [question, setQuestion] = useState(submittedQuestion);
  return (
    <div>
      <div className="mb-4">
        <h2>Your class asked {numberOfQuestionsAskedByClass} questions.</h2>
        <p
          style={{
            fontSize: "1.1em",
          }}
        >
          Here are all the questions asked during today’s section:
        </p>
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
          {questionsAsked.map((questionAsked, index) => (
            <div key={index} className="mb-2">
              <IndividualQuestion
                time={questionAsked.time}
                question={questionAsked.question}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <p style={{ fontStyle: "italic" }}>
          Remember, if you are struggling with something, some of your peers are
          probably struggling with the same thing! Sharing your questions can
          enhance the section experience for everyone.
        </p>
        <h5>What questions do you still have?</h5>
        <p>You can ask your question on the <Link target="_blank" to={`/${courseId}/forum`}>Class Discussion Forum</Link>, or in your <Link to={`/${courseId}/sectionforum`} target="_blank">Section Forum</Link>.</p>
        {/* <textarea
          className="form-control"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        ></textarea>
        <button
          className="btn btn-primary mt-2"
          onClick={() => onQuestionSubmit(question)}
        >
          Submit
        </button> */}
      </div>
    </div>
  );
};

export default PeerHighlightQuestions;

const IndividualQuestion = ({ time, question }) => {
  return (
    <div
      id="exemplar"
      className="rounded p-2 mb-2"
      style={{ backgroundColor: "#dbf0ff" }}
    >
      <p style={{ margin: 0 }}>
        <span>{new Date(time).toISOString().substring(11, 19)}</span>:{" "}
        {question}
      </p>
    </div>
  );
};

import { MenuItemStyles } from "react-pro-sidebar";

export const onlineMenuStyles: MenuItemStyles = {
    button: ({ active }) => {
        return {
            // border: active ? '3px solid #0d6efd' : '3px solid white',
            borderRadius: '5px',
            height: '35px',
            color: 'grey',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace:'pre-wrap',
            paddingLeft:'0px',
            paddingRight:'0px',
            marginLeft:'0px',
            marginRight:'0px',
            '&:hover': {
                // backgroundColor:'white',
                // color:'blue'
            },
        }
    }
}

export const menuItemStyles: MenuItemStyles = {
        root: {
            borderRightStyle: 'none',
            // textTransform: "uppercase",
            fontWeight: "600",
            height: "100%"
        },
        icon: {},
        SubMenuExpandIcon: {},
        subMenuContent: ({ level }) => ({}),
        button: ({ active }) => {
            return {
                // border: active ? '3px solid #0d6efd' : '3px solid white',
                borderRadius: '5px',
                height: '55px',
                color: 'grey',
                backgroundColor: active ? 'rgb(208, 224, 255)' : 'white',
                '&:hover': {
                    // backgroundColor:'white',
                    // color:'blue'
                    backgroundColor: active ? 'rgb(208, 224, 255)' : 'rgb(240,240,240)',
                },
            }
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };
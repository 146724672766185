import { MenuItemStyles } from "react-pro-sidebar";

export const titleStyle: MenuItemStyles = {
  root: {
    borderRightStyle: 'none',
    height: "100%",
    width: '300px',
  },
  icon: {},
  SubMenuExpandIcon: {},
  subMenuContent: ({ level }) => ({}),
  button: ({ active }) => {
    return {
      color: 'white',
      height:'100%',
      minHeight:'50px',
      backgroundColor: '#707ec6',
      '&:hover': {
        backgroundColor: '#707ec6',
        color:"white",
      },
    }
  },
  label: ({ open }) => ({
    // fontWeight: open ? 600 : undefined,
  }),
};

export const bookMenuStyles: MenuItemStyles = {
  root: {
    borderRightStyle: 'none',
    height: "100%",
    width: '300px',
    backgroundColor: '#111',
  },
  icon: {},
  SubMenuExpandIcon: {},
  subMenuContent: ({ level }) => ({}),
  button: ({ active }) => {
    return {
      color: 'white',
      height:'100%',
      minHeight:'50px',
      backgroundColor: 'darkblue',
      '&:hover': {
        color:"white",
        backgroundColor: 'darkblue'
      },
    }
  },
  label: ({ open }) => ({
    // fontWeight: open ? 600 : undefined,
  }),
};

export const chapterMenuStyles: MenuItemStyles = {
  root: {
    borderRightStyle: 'none',
    height: "100%",
    width: '300px',
    backgroundColor: '#7386D5',
  },
  icon: {},
  SubMenuExpandIcon: {},
  subMenuContent: ({ level }) => ({}),
  button: ({ active }) => {
    return {
      height: '45px',
      color: active? '#00008B' : 'white',
      backgroundColor: active ? 'rgb(208, 224, 255)' : '#7386D5',
      '&:hover': {
        color: "#7386D5",
        backgroundColor: active ? 'rgb(208, 224, 255)' : 'rgb(240,240,240)',
      },
    }
  },
  label: ({ open }) => ({
    // fontWeight: open ? 600 : undefined,
  }),
};
import React, { useContext, useState, useEffect } from 'react';
import { ModuleCard } from './ModuleCard';
import { RoadmapContext } from 'course/contexts/RoadmapContext';
import { useCourseId } from 'hooks/router/useUrlParams';
import { PartialLoading } from 'components/loading/Loading';

export const CurrentTeachingModule = () => {
    const courseId = useCourseId();
    const { getCurrentTeachingModule } = useContext(RoadmapContext);
    const [currentTeachingModule, setCurrentTeachingModule] = useState(null);

    useEffect(() => {
        const fetchCurrentTeachingModule = async () => {
            const module = await getCurrentTeachingModule();
            setCurrentTeachingModule(module);
        };

        fetchCurrentTeachingModule();
    }, [getCurrentTeachingModule]);   

    if (!currentTeachingModule) {
        // NOTE: we rely on the module start and end dates being accurate for choosing this module
        return <PartialLoading/>;
    }
    
    return (
        <ModuleCard
            courseId={courseId}
            moduleId={currentTeachingModule.id}
            moduleItem={currentTeachingModule.data()}
            innerRef={null}
            hasEditPermissions={false}
        />
    );
}
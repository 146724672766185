import { useContext, useEffect, useState } from "react";
import { FeasabilityFeedback } from "./types";
import { Input, Spin } from "antd";
import { motion } from "framer-motion";

import { ProjectIdeaContainer } from "./ProjectIdeaContainer";
import { FinalProjectContext } from "./FinalProjectContext";
import styled from "styled-components";

const AlternativeProjectIdea = (props: {
  idea: string;
  onSelect: () => void;
}) => {
  const { idea, onSelect } = props;

  return (
    <AlernateIdea onClick={() => onSelect()} >
      {idea}
    </AlernateIdea>
  );
};

export const NarrowProjectScope = (props: {
  originalProjectIdea: string;
  feedback: FeasabilityFeedback;
  chooseAlternative: (alternative: string, skipScopeCheck?: boolean) => void;
  loading: boolean;
}) => {
  const { feedback, chooseAlternative, loading } = props;
  const { ideateRef } = useContext(FinalProjectContext);
  const [alternative, setAlternative] = useState<string>("");
  const [showAlternativeTextbox, setShowAlternativeTextbox] =
    useState<boolean>(true);
  const [showAlternativeButtons, setShowAlternativeButtons] =
    useState<boolean>(true);
  const [showOriginalIdeaButton, setShowOriginalIdeaButton] =
    useState<boolean>(true);

  const chooseProposedAlternative = () => {
    setShowAlternativeButtons(false);
    setShowOriginalIdeaButton(false);
    chooseAlternative(alternative);
  };

  const chooseAlternativeFromButton = (alternativeChoice: string) => {
    setAlternative(alternativeChoice);
    setShowAlternativeButtons(false);
    setShowOriginalIdeaButton(false);
    chooseAlternative(alternativeChoice, true);
  };

  const sendItOnOriginalIdea = () => {
    setShowAlternativeButtons(false);
    setShowAlternativeTextbox(false);
    chooseAlternative(props.originalProjectIdea, true);
  };

  useEffect(() => {
    if (!loading) {
      setShowAlternativeTextbox(true);
      setShowAlternativeButtons(true);
      setShowOriginalIdeaButton(true);
      setAlternative("");
    }
  }, [loading]);

  // if no feedback, return null
  if (!feedback) {
    return null;
  }

  return (
    <div className="mt-4">
      <h3 ref={ideateRef}>Step 1: Think of an idea</h3>
      <div className="alert alert-warning"><b>Oops: </b>{feedback.feedback}</div>
      {showAlternativeTextbox && (
        <>

          <h4>What you said</h4>
          <ProjectIdeaContainer idea={props.originalProjectIdea} />
          
          <h4 style={{ marginTop: "30px" }}>Edit the idea?</h4>
        
      <p>Edit your idea based on the feedback, or describe the new idea:</p>
          <Input.TextArea
            style={{ width: "100%"}}
            size="large"
            onPressEnter={(e) => {
              e.preventDefault();
              chooseProposedAlternative();
            }}
            placeholder="What do you want to make?"
            value={alternative}
            onChange={(e) => setAlternative(e.target.value)}
            
            disabled={loading}
          />
          {
           loading ? <Spin /> : <span />
          }
        </>
      )}
      {showAlternativeButtons && (
        <div style={{marginTop:30}}>
          <h4>Alternatively, try these on for size</h4>
          <p style={{ color: "grey", fontStyle: "italic" }}>
            We've brainstormed a few ideas that might be a bit more manageable,
            but seem to be in the same vein as your original idea. Let us know
            if you're interested in any of these by clicking it.
          </p>
          <AlternativeOuter feedback={feedback}/>
        </div>
      )}
      {showOriginalIdeaButton && (
        <div style={{marginTop:30, marginBottom:50}}>
          <h4>Want to do it anyways?</h4>
          <p style={{ color: "grey", fontStyle: "italic" }}>
            Awesome, we love passion. We won't be able to help you
             break down such an ambitious goal. A
            journey of a thousand miles starts with a single step.
          </p>
        </div>
      )}
    </div>
  );
};

const AlternativeOuter = (props: { feedback: FeasabilityFeedback }) => {
  const { feedback } = props;
  
  // if there are no alternative ideas, return null
  if(!feedback.alternativeideas || feedback.alternativeideas.length === 0) {
    return null;
  }

  const alternativeIdeas = feedback.alternativeideas;

  return <>{alternativeIdeas.map((idea) => (
    <AlternativeProjectIdea
      key={idea}
      idea={idea}
      onSelect={() => {}}
    />
  ))}</>
}


const AlernateIdea = styled.div`
border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  `
// import firebase from "firebase";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// set up the style
import "./styles/style.scss";

import {
  Authenticated,
  AuthenticatedOptional,
} from "./components/auth/Authenticated";
import { CoursePage } from "./course/components/CoursePage";
import { Published } from "./publishedCode/Published";

import { IDEWithProvider } from "./ide/IDE";

import { SectionLeaderApplication } from "course/application/sectionLeaderApplication/splash/SectionLeaderApplication";

import { AssnEditor } from "./course/editors/assnEditor/AssnEditor";
import { DocsEditor } from "./course/editors/docsEditor/DocsEditor";
import { CodeSplash } from "./course/code/CodeSplash";
import { PracticeProblemsSplash } from "course/code/PracticeProblemsSplash";
import { LearnContainer } from "./course/learnnew/LearnContainer";
import { LessonEditorContainer } from "./course/editors/lessonEditor/LessonEditorContainer";
import { Stories } from "./course/stories/Stories";
import { ChangeEmailPage } from "course/changeEmail/ChangeEmailPage";
import { ChangePasswordPage } from "course/changePassword/ChangePasswordPage";
import { EmailActionHandler } from "course/emailActionHandler/EmailActionHandler";
import { HelpSplash } from "./course/help/HelpSplash";
import { ViewOnlyProfile } from "./course/profile/ViewOnlyProfile";
import { ForumContext } from "course/forum/ForumContext";
import SectionDiscussion from "./course/section/SectionDiscussion";
import { HomeRedirect } from "course/splash/HomeRedirect";
import { SectionPage } from "./course/section/SectionPage";
import { SectionSplash } from "./course/section/SectionSplash";
import { ForumPage } from "course/forum/pages/forum/ForumPage";
import { ReviewSplash } from "review/sectionLeaders/ReviewSplash";
import { ReviewPage } from "review/sectionLeaders/ReviewPage";
import { ReviewApplicant } from "review/sectionLeaders/ReviewApplicant";
import { StyleSplashPage } from "course/styleFeedback/StyleFeedbackSplash";
import { ReviewStats } from "review/sectionLeaders/ReviewStats";
import { Calibration } from "review/sectionLeaders/Calibration";
import { Role } from "types/role";
import { SectionCovers } from "course/monitorsection/SectionCovers";
import { SLAttendance } from "course/slAttendance/SLAttendance";
import { ManageSectionPage } from "course/managesection/ManageSectionPage";
import { TeachersLounge } from "course/teacherslounge/Lounge";
import { SLOnboardingPage } from "onboarding/sectionLeaderOnboarding/SLOnboardingPage";
import { SLOnboardingSplash } from "onboarding/sectionLeaderOnboarding/SLOnboardingSplash";
import { VolunteerAgreement } from "onboarding/sectionLeaderOnboarding/volunteerAgreement/VolunteerAgreement";
import { SLSectionTime } from "onboarding/sectionLeaderOnboarding/schedule/SLSectionTime";
import { SLSectionPreferences } from "onboarding/sectionLeaderOnboarding/sectionpreferences/SLSectionPreferences";
import {
  CIPSectionHandout,
  CIPSectionSoln,
  CIPLessonPlan,
} from "course/section/handouts/SectionMaterials";
import { CourseEvents } from "course/events/CourseEvents";
import { SLSmallGroupTime } from "onboarding/sectionLeaderOnboarding/smallGroupPreferences/SLSmallGroupTime";
import { AboutCourse } from "course/aboutcourse/AboutCourse";
import { StudentOnboardingPage } from "onboarding/studentOnboarding/StudentOnboardingPage";
import { StudentOnboardingSplash } from "onboarding/studentOnboarding/StudentOnboardingSplash";
import { StudentSectionTime } from "onboarding/studentOnboarding/schedule/StudentSectionTime";
import { StudentAccommodations } from "onboarding/studentOnboarding/accommodations";
import { StudentCommitting } from "onboarding/studentOnboarding/committing/StudentCommitting";
import { TranscriptFeedbackRedirect } from "course/transcriptFeedback/TranscriptFeedbackRedirect";
import { TranscriptFeedbackPage } from "course/transcriptFeedback/TranscriptFeedbackPage";
import { SectionFeedbackPage } from "course/transcriptFeedback/SectionWiseFeedback";
import { HandoutPage } from "course/handout/HandoutPage";
import { MonitorSections } from "course/monitorsection/MonitorSections";
import { CheckJoinPage } from "ide/PairProgram/ForceJoin";
import { UserLookup } from "course/userlookup/UserLookup";
import { GPTeachZoomContainer } from "gpteach/GPTeachZoomContainer";
import { GPTeachChatContainer } from "gpteach/GPTeachChatContainer";
import { FileHistory } from "ide/fileHistory/FileHistory";
import { TextbookPage } from "textbook/TextbookPage";
import { TextbookChapter } from "textbook/TextbookChapter";
import { Profile } from "course/profile/Profile";
import { CareHoursHandout } from "course/carehours/WhatIsCareHours";
import { SectionSwitch } from "course/sectionswitch/SectionSwitch";
import { ChatGPTEditor, ChatGPTSplash } from "components/chatgpt/chatgpt";
import { SectionEmail } from "course/section/SectionEmail";
import { DiganosticPage } from "diagnostic/DiagnosticPage";
import { DiagnosticQuestion } from "diagnostic/DiagnosticQuestion";
import { DiagnosticSplash } from "diagnostic/DiagnosticSplash";
import { DiagnosticFinished } from "diagnostic/DiagnosticFinished";
import { DiagnosticIntro } from "diagnostic/DiagnosticIntro";
import { CertificatePage } from "course/certificate/CertificatePage";

import { DiagnosticFeedback } from "course/diagFeedback/DiagFeedbackQuestion";
import { CertificateRedirect } from "course/certificate/CertificateRedirect";

import { ProjectSubmissionForm } from "course/finalproject/submission/ProjectSubmissionForm";
import { SetPermissionsPage } from "course/set_permissions/SetPermissionsPage";
import { RoadmapEditor } from "course/editors/roadmapEditor/RoadmapEditor";

import { AdminEditor } from "course/editors/adminEditor/AdminEditor";
import { CourseResources } from "course/editors/repository/CourseResources";

import { NewStudentHome } from "course/splash/NewStudentHome";
import { NewTeachingHome } from "course/splash/NewTeachingHome";
import { AdminHome } from "course/splash/AdminHome";

import { StudentApplication } from "course/application/studentApplication/splash/StudentApplication";
import { StudentApplicationAbout } from "course/application/studentApplication/about";
import { Explore } from "course/application/sectionLeaderApplication/aboutJob/Explore";
import { AboutSLPage } from "course/application/sectionLeaderApplication/aboutYou/AboutSLPage";
import { TeachingDemo } from "course/application/sectionLeaderApplication/teachingDemo/TeachingDemo";
import { Debugging } from "course/application/sectionLeaderApplication/debugging/Debugging";
import { ReportingForum } from "course/forum/pages/reporting/ReportingForum";
import { ProjectForum } from "course/finalproject/projectforum/ProjectForum";
import { ExploreCourse } from "course/application/studentApplication/aboutCourse/ExploreCourse";
import { Unsubscribe } from "components/auth/Unsubscribe";
import { MonitorApplications } from "course/application/monitorApplications/MonitorApplications";
import { MonitorOverallCompletions } from "course/stats/MonitorOverallCompletions";
import { EventsEditorContainer } from "course/editors/eventsEditor/EventsEditorContainer";
import { DownloadStudentAssnsPage } from "course/grading/DownloadStudentAssnsPage";
import { GradingSplash } from "course/grading/GradingSplash";
import { SectionForumRedirect } from "course/section/SectionForumRedirect";
import { Connections } from "course/connections/Connections";
import { SetupProfile } from "onboarding/components/SetupProfile";
import { TrainingSplash } from "course/teach/TrainingSplash";
import { Lesson } from "course/learnnew/Lesson";
import { SectionPractice } from "course/section/SectionPractice";
import { MentorHome } from "course/splash/MentorHome";
import { FixCachePage } from "components/fixcache/FixCache";
import { TeachNowTrainingOuter } from "course/teacherPuzzles/TeachNowTraining";
import { ExpStudentOnboardingPage } from "onboarding/experienceStudentOnboarding/ExpStudentOnboardingPage";
import { ExpStudentOnboardingSplash } from "onboarding/experienceStudentOnboarding/ExpStudentOnboardingSplash";
import { ExpStudentCommitting } from "onboarding/experienceStudentOnboarding/committing/ExpStudentCommitting";
import { StudentOverview } from "course/studentoverview/StudentOverview";
import { MakeupSection } from "course/makeupsection/MakeupSection";
import { SectionTranscript } from "course/transcriptFeedback/SectionTranscript";
import { TeachNowDashboard } from "course/teachnowdashboard/TeachNowDash";
import { TeachNowSessionsOuter } from "course/carehours/CareHoursPage";
import { ForumStats } from "course/stats/forumStats/ForumStats";
import { CourseSurvey } from "components/Forms/CourseSurvey";
import { SharePage } from "course/sharepage/SharePage";
import { ReviewStudentTNContainer } from "course/reviewStudentsTeachNow/ReviewStudents";
import { StudentTeachNow } from "course/teacherPuzzles/StudentTeachNowSplash";

import { FinalProjectPortal } from "course/finalproject/portal";

import { ViewJoinedStudents } from "course/reviewStudentsTeachNow/ViewJoiners";
import { ProjectForumRedirect } from "course/finalproject/projectforum/ProjectForumRedirect";
import { FinalProjectSplash } from "course/finalproject/portal/FinalProjectSplash";
import { CourseContext } from "contexts/CourseContext";
import { StudentGradesPage } from "course/studentgrades/StudentGradesPage";
import { GradesRedirect } from "course/grading/GradesRedirect";
import { CanvasAuthRedirect } from "components/auth/direct/CanvasAuthRedirect";
import { WaitPage } from "course/aboutcourse/WaitPage";

const CourseRoutes = ({ queryClient }) => {
  const { slFeatures, courseFeatures } = useContext(CourseContext);
  const featureSet = new Set([...slFeatures, ...courseFeatures]);

  return (
    <Routes>
      {/* Special routes for reviewing */}
      <Route path="review" element={<Authenticated component={ReviewPage} />}>
        <Route path="stats" element={<ReviewStats />} />
        <Route path="calibration/:calibrationId" element={<Calibration />} />
        <Route path="app/:userId" element={<ReviewApplicant />} />
        <Route path="" element={<ReviewSplash />} />
      </Route>

      {/* Textbook Routes */}
      <Route path="textbook" element={<TextbookPage textbookId={null} />}>
        <Route
          path=":chapterId"
          element={<TextbookChapter chapterData={{}} />}
        />
      </Route>

      {/* Teacher Textbook Routes */}
      <Route
        path="teacherhandbook"
        element={<TextbookPage textbookId={"teacherhandbook"} />}
      >
        <Route
          path=":chapterId"
          element={<TextbookChapter chapterData={{}} />}
        />
      </Route>

      {/* Onboarding Routes */}
      <Route
        path="onboarding/student"
        element={
          <QueryClientProvider client={queryClient}>
            <Authenticated component={StudentOnboardingPage} />
          </QueryClientProvider>
        }
      >
        <Route path="committing" element={<StudentCommitting />} />
        <Route path="schedule" element={<StudentSectionTime />} />
        <Route path="profile" element={<SetupProfile />} />
        <Route path="accommodations" element={<StudentAccommodations />} />
        <Route path="" element={<StudentOnboardingSplash />} />
      </Route>

      {/* Onboarding SL Routes */}
      <Route
        path="onboarding/teach"
        element={
          <QueryClientProvider client={queryClient}>
            <Authenticated component={SLOnboardingPage} />
          </QueryClientProvider>
        }
      >
        <Route path="volunteeragreement" element={<VolunteerAgreement />} />
        <Route path="schedule" element={<SLSectionTime />} />
        <Route path="smallGroup" element={<SLSmallGroupTime />} />
        <Route path="profile" element={<SetupProfile />} />
        <Route path="sectionpreferences" element={<SLSectionPreferences />} />
        <Route path="" element={<SLOnboardingSplash />} />
      </Route>

      {/* Onboarding Exp Student Routes */}
      <Route
        path="onboarding/experiencedstudent"
        element={
          <QueryClientProvider client={queryClient}>
            <Authenticated component={ExpStudentOnboardingPage} />
          </QueryClientProvider>
        }
      >
        <Route path="committing" element={<ExpStudentCommitting />} />

        <Route path="profile" element={<SetupProfile />} />
        <Route path="" element={<ExpStudentOnboardingSplash />} />
      </Route>

      {/* Certificate Routes */}
      <Route
        path="certificate"
        element={<Authenticated component={CertificateRedirect} />}
      />
      <Route path="certificate/:certificateId" element={<CertificatePage />} />
      {/* Diagnostic Routes */}
      <Route
        path="diagnostic"
        element={<Authenticated component={DiganosticPage} />}
      >
        <Route index element={<DiagnosticSplash />} />
        <Route path="intro" element={<DiagnosticIntro />} />
        <Route path="finished" element={<DiagnosticFinished />} />
        <Route path=":questionId" element={<DiagnosticQuestion />} />
      </Route>

      <Route
        path="chatgpt/chat"
        element={<Authenticated component={ChatGPTEditor} />}
      />

      {/* The course page (with side bar and navbar) */}
      <Route path="*" element={<Authenticated component={CoursePage} />}>
        <Route path="finalProjectProposal">
          <Route index element={<FinalProjectSplash />} />
          <Route path=":proposalId" element={<FinalProjectPortal />} />
        </Route>

        <Route path="home" element={<HomeRedirect />} />
        <Route path="studenthome" element={<NewStudentHome />} />
        <Route path="teachinghome" element={<NewTeachingHome />} />
        <Route path="mentorhome" element={<MentorHome />} />

        <Route path="teachnowstatistics" element={<TeachNowSessionsOuter />} />
        <Route
          path="teachnowstatistics/:teacherId"
          element={<TeachNowSessionsOuter />}
        />

        <Route path="adminhome" element={<AdminHome />} />
        <Route path="profile" element={<Profile />} />
        <Route path="clearcache" element={<FixCachePage />} />

        <Route path="transcriptfeedback">
          <Route index element={<TranscriptFeedbackRedirect />} />
          <Route path=":sectionWeek" element={<TranscriptFeedbackPage />} />
          <Route
            path=":sectionWeek/:sectionId"
            element={<SectionFeedbackPage />}
          />
          <Route
            path=":sectionWeek/:sectionId/transcript"
            element={<SectionTranscript />}
          />
        </Route>
        <Route path="aboutcourse" element={<AboutCourse />} />
        <Route path="setpermissions" element={<SetPermissionsPage />} />
        <Route path="handout/:handoutId" element={<HandoutPage />} />
        <Route path="survey/:surveyId" element={<CourseSurvey />} />
        <Route path="forumstats" element={<ForumStats />} />
        <Route path="sharedprojects" element={<SharePage />} />
        <Route path="roadmapeditor" element={<RoadmapEditor />} />
        <Route
          path="monitorapps/:targetCourseId"
          element={<MonitorApplications />}
        />
        <Route
          path="overallcompletions"
          element={<MonitorOverallCompletions />}
        />

        {/* IDE Routes */}
        <Route>
          <Route
            path="styleFeedback/:projectId/:feedbackId"
            element={<Authenticated component={StyleSplashPage} />}
          />
          <Route
            path="diagnosticFeedback"
            element={<Authenticated component={DiagnosticFeedback} />}
          />
          <Route
            path="ide/history/:projectId/:fileId"
            element={<FileHistory />}
          />
          <Route
            path="ide/:urlFormat/:urlKey"
            element={
              <QueryClientProvider client={queryClient}>
                <Authenticated component={IDEWithProvider} />
              </QueryClientProvider>
            }
          />
        </Route>

        {/* Code Routes */}
        <Route path="assnEditor/:assnId?" element={<AssnEditor />} />
        <Route path="docsEditor/:docsId?" element={<DocsEditor />} />
        <Route path="code" element={<CodeSplash />} />
        <Route path="create" element={<CodeSplash creative={true} />} />
        <Route path="practice" element={<PracticeProblemsSplash />} />

        {/* Lesson Routes */}
        <Route
          path="lessoneditor/:editorType/*"
          element={<LessonEditorContainer />}
        />
        <Route path="learn/*" element={<LearnContainer />} />

        {/* TeachNow Training Routes */}
        <Route path="teachnowtraining/*" element={<TeachNowTrainingOuter />} />
        <Route
          path="reviewteachnowstudents/*"
          element={<ReviewStudentTNContainer />}
        />

        {/* Student Teach Now Routes */}
        <Route path="studentteachnow" element={<StudentTeachNow />} />
        <Route path="viewteachnowstudents" element={<ViewJoinedStudents />} />

        {/* Student Application Routes */}
        <Route path="join/:targetCourseId">
          <Route index element={<StudentApplication />} />
          <Route path="about" element={<StudentApplicationAbout />} />
          <Route path="aboutCourse" element={<ExploreCourse />} />
        </Route>

        {/* Section Leader Application Routes */}
        <Route path="applyteach/:targetCourseId">
          <Route index element={<SectionLeaderApplication />} />
          <Route path="aboutJob" element={<Explore />} />
          <Route path="aboutYou" element={<AboutSLPage />} />
          <Route path="teachingDemo" element={<TeachingDemo />} />
          <Route path="debugging" element={<Debugging />} />
        </Route>

        {/* Section Routes */}
        {featureSet.has("section") && (
          <Route>
            <Route
              path="section/:sectionWeek/handout"
              element={<CIPSectionHandout />}
            />
            <Route
              path="section/:sectionWeek/solution"
              element={<CIPSectionSoln />}
            />
            <Route
              path="section/:sectionWeek/lessonplan"
              element={<CIPLessonPlan />}
            />
            <Route path="section/:sectionId/email" element={<SectionEmail />} />
            <Route path="sectionforum" element={<SectionForumRedirect />} />
            <Route
              path="sectionforum/:sectionId"
              element={<SectionDiscussion />}
            />
            <Route path="section/:sectionId" element={<SectionPage />} />
            <Route path="section" element={<SectionSplash />} />
            <Route path="sectionswitch" element={<SectionSwitch />} />
            <Route path="makeupsection" element={<MakeupSection />} />
            <Route path="monitorsections" element={<MonitorSections />} />

            <Route path="sectioncovers" element={<SectionCovers />} />
            <Route path="slattendance" element={<SLAttendance />} />
            <Route path="manage/:sectionId" element={<ManageSectionPage />} />
            <Route path="teacherslounge" element={<TeachersLounge />} />
            <Route path="sectionpractice" element={<SectionPractice />} />
          </Route>
        )}

        {/* Teacher Training Routes */}

        {featureSet.has("training") && (
          <Route path="training">
            <Route index element={<TrainingSplash />} />
            <Route path="gpteach/zoom" element={<GPTeachZoomContainer />} />
            <Route path="gpteach/chat" element={<GPTeachChatContainer />} />
            <Route path=":lessonId/:slideId" element={<Lesson />} />
            <Route path=":slideId" element={<Lesson />} />
          </Route>
        )}

        <Route path="teachnow" element={<CareHoursHandout />} />
        <Route path="peer/:roomId" element={<CheckJoinPage />} />

        <Route path="admin" element={<AdminEditor />} />
        <Route path="resources" element={<CourseResources />} />
        <Route path="gradingsplash" element={<GradingSplash />} />
        <Route path="grading/:assnId" element={<DownloadStudentAssnsPage />} />
        <Route path="studentoverview" element={<StudentOverview />} />
        <Route path="teachnowadmin" element={<TeachNowDashboard />} />

        {/* connections */}
        {featureSet.has("connections") && (
          <Route path="connections" element={<Connections />} />
        )}

        {/* Forum Routes */}
        {featureSet.has("forums") && (
          <Route
            path="forum"
            element={
              <ForumContext.Provider
                value={{
                  forumId: "main",
                  forumType: "Course",
                  privatePostThreshold: Role.SECTION_LEADER,
                  setPinnedThreshold: Role.ADMIN,
                }}
              >
                <QueryClientProvider client={queryClient}>
                  <ForumPage />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </ForumContext.Provider>
            }
          />
        )}
        {featureSet.has("forums") && (
          <Route
            path="forumx"
            element={
              <ForumContext.Provider
                value={{
                  forumId: "mainx",
                  forumType: "Course",
                  privatePostThreshold: Role.SECTION_LEADER,
                  setPinnedThreshold: Role.ADMIN,
                }}
              >
                <QueryClientProvider client={queryClient}>
                  <ForumPage />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </ForumContext.Provider>
            }
          />
        )}

        {/* Report Routes */}
        <Route
          path="report"
          element={
            <ForumContext.Provider
              value={{
                forumId: "report",
                forumType: "Reporting",
                privatePostThreshold: Role.TA,
                setPinnedThreshold: Role.ADMIN,
                isReporting: true,
              }}
            >
              <QueryClientProvider client={queryClient}>
                <ReportingForum queryClient={queryClient} />
              </QueryClientProvider>
            </ForumContext.Provider>
          }
        />

        {/* final project forum */}
        <Route path="projectforum" element={<ProjectForumRedirect />} />
        <Route path="projectforum/:forumId" element={<ProjectForum />} />

        {/*Events Routes*/}
        <Route path="eventseditor/*" element={<EventsEditorContainer />} />

        <Route path="stories" element={<Stories />} />
        <Route path="grades" element={<GradesRedirect />} />
        <Route path="studentgrades" element={<StudentGradesPage />} />
        <Route path="changeemail" element={<ChangeEmailPage />} />
        <Route path="changepassword" element={<ChangePasswordPage />} />
        <Route path="events" element={<CourseEvents />} />
        <Route path="userlookup" element={<UserLookup />} />
        <Route path="user/:userId" element={<ViewOnlyProfile />} />
        <Route path="help" element={<HelpSplash />} />

        <Route path="chatgpt" element={<ChatGPTSplash />} />

        <Route
          path="handout/submitfinalproject"
          element={
            <QueryClientProvider client={queryClient}>
              <ProjectSubmissionForm />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          }
        />
      </Route>

      <Route
        path="share/:projectId"
        element={<AuthenticatedOptional component={Published} />}
      />
      <Route path="direct">
        <Route path="canvas" element={<CanvasAuthRedirect />} />
      </Route>
      <Route path="holding" element={<WaitPage />} />
    </Routes>
  );
};

export default CourseRoutes;

import KarelEast from "../../components/pyodide/KarelLib/images/classic/karelEast.png";
import TutleEast from "../../components/pyodide/KarelLib/images/turtle/karelEast.png";
import ArrowEast from "../../components/pyodide/KarelLib/images/arrow/karelEast.png";
import { useContext } from "react";
import { doc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { useUserId } from "hooks/user/useUserId";
import { IDEContext } from "ide/contexts/IDEContext";
import { alertError } from "components/completion/Alerts";
import { FaCross } from "react-icons/fa";


const karelTypes = {
    classic: {
        name: "Classic",
        image: KarelEast,
        id: "classic"
    },
    turtle: {
        name: "Turtle",
        image: TutleEast,
        id: "turtle"
    },
    arrow: {
        name: "Arrow",
        image: ArrowEast,
        id: "arrow"
    }

}

const popupBackdropStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const popupStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
};




export const KarelSelect = () => {
    const { isKarelSelectOpen, setIsKarelSelectOpen, setKarelType, karelType } = useContext(IDEContext)
    const isOpen = isKarelSelectOpen
    if(!isOpen) return <></>

    const userId = useUserId()

    
    const onKarelSelect = async (karelType) => {
        try {
            await setKarelInFirestore(karelType, userId)
        } catch (error) {
            alertError(error)
            return;
        }
        setKarelType(karelType)
        setIsKarelSelectOpen(false)
        return;
    }

    return (
        <div style={popupBackdropStyle} onClick={() => setIsKarelSelectOpen(false)}>
            <div style={popupStyle}>
                <button className="btn" style={{position: "absolute", top: "0px", right: "0px"}} onClick={() => setIsKarelSelectOpen(false)}><FaCross/></button>
                <h3>Choose your Karel!</h3>
                <div className="d-flex justify-content-between">
                    {
                        Object.keys(karelTypes).map(karelOption => {
                            return <SelectKarelButton 
                            karelType={karelOption}
                            selectedKarel={karelType}
                            onKarelSelect={() => onKarelSelect(karelOption)}
                            key={karelOption}
                            
                            />
                        })
                    }
                </div>
            </div>
        </div>
    )
}



const SelectKarelButton = ({karelType, selectedKarel, onKarelSelect}) => {
    const isSelected = karelType === selectedKarel
    const cn =  isSelected ? "btn btn-outline-primary": "btn btn-outline-secondary";


    return <div key={karelType}>
        <button className={cn} onClick={()=>onKarelSelect(karelType.id)} ><img src={karelTypes[karelType].image} width={"50px"} height={"50px"}/></button>
        <p>{karelTypes[karelType].name}</p>
    </div>
}



const setKarelInFirestore = async (karelType, userId) => {
    if(!userId) return;
    const db = getFirestore()
    const docRef = doc(db, "users", userId, "settings", "ide");
    await setDoc(docRef, {
        karelType: karelType
    }, {merge: true})

}
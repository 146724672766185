import { useContext, useEffect, useState } from "react";
import { GuideStep } from "./types";
import { useMutation, useQueryClient } from "react-query";
import { Button, Timeline } from "antd";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import { useCourseId } from "hooks/router/useUrlParams";
import { FinalProjectContext } from "./FinalProjectContext";
import styled from "styled-components";

const FinalProjectStep = (props: { step: GuideStep }) => {
  const { step } = props;

  console.log(step)

  const courseId = useCourseId();

  const [isCompleted, setIsCompleted] = useState(step.completed);

  const { mutate: toggleStepCompletionHandler, isLoading: isToggling } =
    useMutation(
      async () => {
        // TODO: toggle
        // await toggleStepCompletion(step.step, courseId);
      },
      {
        onMutate: () => {
          setIsCompleted(!isCompleted);
        },
      }
    );

  useEffect(() => {
    setIsCompleted(step.completed);
  }, [step.completed]);

  // if the step isn't valid, don't render
  if (!step.title || !step.description) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid lightgray",
        borderRadius: "5px",
        height: "auto",
      }}
    >
      <div
        style={{
          marginBottom: "10px",
          padding: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          background: "white",
          height: "auto",
        }}
      >
        <div>
          <h5>{step.title}</h5>
        </div>

        <p>{step.description}</p>
        <Resources step={step} />
        <Jargon step={step} />
      </div>
      <div style={{ paddingRight: "10px" }}>
        <Button
          onClick={() => toggleStepCompletionHandler()}
          icon={
            isCompleted ? (
              <CheckCircleFilled style={{ color: "green" }} />
            ) : (
              <CheckCircleOutlined />
            )
          }
          disabled={isToggling}
          type="text"
          shape="circle"
        />
      </div>
    </div>
  );
};

const Jargon = (props: { step: GuideStep }) => {
  const { step } = props;
  if(!step.jargon || step.jargon.length === 0) {
    return null;
  }
  return <>
    <span>
      Here are some terms you might need to know for this step:
    </span>
    <ResourceList>
      {step.jargon.map((term) => (
        <li>
          <strong>{term.term}</strong>: {term.definition}
        </li>
      ))}
    </ResourceList>
  </>
}

const Resources = (props: { step: GuideStep }) => {
  const { step } = props;

  if (!step.courseResources || step.courseResources.length === 0) {
    return null;
  }
  return <>
    <span>
      Here are some resources that might help you with this step:
    </span>
    <ResourceList>
      {step.courseResources.map((resource) => (
        <li>
          <a href={resource.url} target="_blank" rel="noreferrer">
            {resource.title}
          </a>
        </li>
      ))}
    </ResourceList>
  </>
}

export const FinalProjectGuide = (props: { guide: GuideStep[] }) => {
  const { guide } = props;
  const { milestoneRef } = useContext(FinalProjectContext);
  useEffect(() => {
    guide.sort((a, b) => a.step - b.step);
  }, [guide]);

  const timelineItems = guide.map((step) => {
    return {
      children: <FinalProjectStep step={step} />,
      color: step.completed ? "green" : "blue",
    };
  });

  return (
    <div style={{ marginTop: 30 }}>
      <h3 ref={milestoneRef}>Step 3: Plan Milestones</h3>
      <p>
        There are many ways to approach your project, but here's one. If you
        need some help getting started, here's a guide to help you through the
        process. You can mark each step as completed as you go.
      </p>
      <p>
        Of course, feel free to take your own path. It's the journey that
        matters, not the destination.
      </p>
      <Timeline items={timelineItems} />
    </div>
  );
};

const ResourceList = styled.ul`
  margin-top: 0;
  padding-top:0;
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
  padding-bottom:10px;
  
`;
import { Carousel } from 'react-bootstrap';

const navHeight = 57


export const Takeaways = () => {

  return <div>
    <Fullpage 
      backgroundColor="#4496e5" 
      offset={navHeight}
      text="What will I get out of teaching?"
      explanation=""
      scrollText="Scroll to find out..."
    />
    <Fullpage 
      backgroundColor="#ed6a50" 
      offset={navHeight}
      text="1. Teaching is a learning experience"
      explanation="We want to make sure Code in Place is as valuable an experience for you 
      as it will be for your students. Learn  a powerful framework for teaching Computer Science, and 
      the confidence to continue teaching after our five weeks together. 
      A  survey of Stanford section leaders showed their experience was overwhelmingly positive with a large increase in leadership confidence. Earn a volunteer section leading certificate."
      scrollText="Tell me more..."
    />
    <Fullpage 
      backgroundColor="#68b319" 
      offset={navHeight}
      text="2. Participate in an amazing community"
      explanation="In Code in Place you will participate in communities large and small. You will find that 
      your most valuable resource as a teacher is your peer group, and you'll build 
      relationships with them that you'll carry far forward. You will form a community in your classroom and you will guide your students on their learning journey.
       "
      scrollText="Ok sure, but what else?"
    />
    <Fullpage 
      
      backgroundColor="#e54496" 
      offset={navHeight}
      text="3. Be part of something new"
      explanation="There must be a better way to teach people online! We're doing this 
      because we love teaching, but also because we want to improve free online learning globally. 
      Code in Place is a first-of-its-kind type of course and we are using it as a way to imagine a brighter future for learning. 
      As a section leader we invite you to join in on this exciting conversation."
      scrollText="Will it help others?"
    />
    <Fullpage 
      backgroundColor="#4496e5" 
      offset={navHeight}
      text="4. Increase the number of students who can take the course"
      explanation="Giving to others is a core part of the human experience. 
      Your section leading will be an act of community service. Every volunteer instructor gives 10 more students the opportunity to join. And the more students who do (and tell their friends how fun and easy it is to code) the more people will have access to this joyful learning experience."
      scrollText="What did past section leaders say?"
    />
  </div>
}

const Fullpage = ({ backgroundColor, offset, text, explanation, scrollText}) => {
  let heightStr = '100vh'
  if(offset > 0) {
    heightStr = `calc(100vh - ${offset}px)`
  }

  return <div className="d-flex flex-column justify-content-between align-items-center" style={{ backgroundColor:backgroundColor, height: heightStr, width: '100vw', color:'white', textAlign:'center' }}>
    <span></span>
    <h1 style={{maxWidth:700}}>{text}</h1>
    <Explanation explanation={explanation} />
    <h4>{scrollText}</h4>
  </div>
}

const Explanation = ({ explanation }) => {
  if(explanation === '') {
    return <></>
  }
  return <div style={explanationStyle}>
    {explanation}
  </div>
}
const blackWithAlpha = (alpha) => {
  return `rgba(0,0,0,${1-alpha})`
}

const explanationStyle = {
  maxWidth: '700px',
  fontSize: '1.2em',
  backgroundColor:blackWithAlpha(0.8),
  padding: '20px',
  borderRadius: '10px',
  textAlign: 'left' as const
}



export const TakeawaysCarousel = () => {
  return <Carousel interval={10000}>
  
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/stanford/teaching1.jpg"
      alt="First slide"
    />
    <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption>
  </Carousel.Item>
  
  
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/stanford/teaching1.jpg"
      alt="Second slide"
    />

    <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption>
  </Carousel.Item>
  
  
  <Carousel.Item>
    <img
      className="d-block w-100"
      src="/stanford/teaching1.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>
        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
      </p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
}

export const TakeawaysPlain = () => {
    return (
      <div>
        <p>I used to think that learning was one of the great joys of life, and then I started teaching! Passing on knowlege is one of the most wonderful experiences.</p>
        <p>    
          <b>1. Teaching is a learning experience</b> We want to make sure Code in Place is as valuable an experience for you 
          as it will be for your students. If you haven't taught programming before, 
          we'll arm you with a powerful framework for teaching Computer Science, and 
          the confidence to continue teaching after our five weeks together. 
          The core teaching skills to both communicate ideas and lead a team are useful for everyone.
          If you're an experienced teacher, you'll encounter alternative approaches to 
          teaching this material that will expand your educational toolkit. 
        </p>
        <p>
          <b>2. Participate in an amazing community</b> In Code in Place you will participate in communities large and small. You will find that 
          your most valuable resource as a teacher is your peer group, and you'll build 
          relationships with them that you'll carry far forward. You will form a community in your classroom and you will guide your students on their learning journey.
           Joining groups, helping others, maintaining relationships and staying hopeful are all proven ways to build resilience. 
           A 2017 survey of Stanford section leaders showed their experience was overwhelmingly positive, with improvements in identity and perceived collaboration 
           in the field of CS, and a large increase in teaching and leadership confidence.
        </p>
        <p>
          <b>3. The thrill of being part of something new</b> There must be a better way to teach people online! We're doing this 
          because we love teaching, but also because we want to improve free online learning globally. 
          Code in Place is a first-of-its-kind type of course and we are using it as a way to imagine a brighter future for learning. 
          As a section leader we invite you to join in on this exciting conversation.
        </p>

        <p>
        <b>4. Increase the number of students who can take the course!</b> Giving to others is a core part of the human experience. 
        Your section leading will be an act of community service. Every volunteer instructor gives 15 more students the opportunity to join. And the more students who do (and tell their friends how fun and easy it is to code) the more people will have access to this joyful learning experience.
        </p>

        <p>And much more! See what past section leaders said about teaching with Code in Place:</p>
      </div>
  );
}
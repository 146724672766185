import { useState } from "react";
import Gate from "contexts/Gate";
import { Row, Button } from "react-bootstrap";
import _ from "lodash";
import { PartialLoading } from "components/loading/Loading";
import Swal from "sweetalert2";
import { PostGradeResponse } from "course/grading/CanvasTypes";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const postGrade= httpsCallable(functions, 'postGrade');


export const ScoreComponent = (props) => {
  const { courseHasGrades, scoreLoading, userData } = props;
  if (!courseHasGrades) {
    return <></>;
  }
  if (scoreLoading) {
    return <PartialLoading />;
  }
  if (Gate.hasAdminRole(userData)) {
    return <ScoreForm {...props} />;
  }
  return <ScoreView {...props} />;
};


const ScoreView = ({currentCanvasScore, canvasAssnInfo}) => {
  if (currentCanvasScore === -1){
    return <div>
      <div className="splashCardRightTitle mt-2" >Score</div>
      <p>No posted score yet!</p>
    </div>
  }
  return <div>
    <div className="splashCardRightTitle mt-2" >Score</div>
    {currentCanvasScore} / {'points_possible' in canvasAssnInfo ? canvasAssnInfo.points_possible : '-'}
  </div>
}


const ScoreForm = ({ 
    score, 
    setScore, 
    canvasAssnInfo,
    currentCanvasScore,
    setCurrentCanvasScore,
    userData,
    authorId,
    assnId,
    courseId
  }) => {
  const [postScoreLoading, setPostScoreLoading] = useState(false)

  return <>
    <div className="splashCardRightTitle mt-2" >Score</div>
    <Row>
      <ScoreInput 
        value={score} 
        setValue={setScore}  
        label="score" 
        pointsPossible={'points_possible' in canvasAssnInfo ? canvasAssnInfo.points_possible : 0} 
        postScoreLoading={postScoreLoading}
      />
      of&nbsp;
      <>
        {'points_possible' in canvasAssnInfo ? canvasAssnInfo.points_possible : '-'}
      </>
    </Row>
    <PostScoreButton
      score={score}
      authorId={authorId}
      assnId={assnId}
      courseId={courseId}
      userData={userData}
      currentCanvasScore={currentCanvasScore}
      setCurrentCanvasScore={setCurrentCanvasScore}
      postScoreLoading={postScoreLoading}
      setPostScoreLoading={setPostScoreLoading}
    />
  </>
}


const ScoreInput = ({value, setValue, label, pointsPossible, postScoreLoading}) => {
    return <>
        <div className="input-group input-group-sm" style={{maxWidth:100}}>
            <input 
              key={label} 
              onChange={(e) => setValue(Number(e.target.value))}
              value = {value}
              type="number"
              min={0}
              max={pointsPossible}
              className="form-control" 
              aria-label="Score input" 
              aria-describedby="inputGroup-sizing-sm"
              disabled={postScoreLoading}
            />
        </div>
    </>
}


const PostScoreButton = ({score, authorId, assnId, courseId, userData, currentCanvasScore, setCurrentCanvasScore, postScoreLoading, setPostScoreLoading}) => {

  const handleSubmit = async (e) => {
    setPostScoreLoading(true)
    const res = await postGrade({
      callerId: userData.id,
      userId: authorId,
      courseId: courseId,
      assnId: assnId,
      score: score,
      visibility: 'true',
    })

    const resData = res.data as PostGradeResponse

    if (resData.success) {
        Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: `Score of ${score} posted to Canvas!`,
        })
        setPostScoreLoading(false)
        setCurrentCanvasScore(score)
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
        })
        setPostScoreLoading(false)
    }
  }

  return <Button variant='primary' onClick={handleSubmit} disabled={postScoreLoading || score === currentCanvasScore} className="mt-2">
      {postScoreLoading ? 'Posting...' : 'Post Score'}
  </Button>
}

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

export const uploadAudioFile = (file, setBlobUrl, setUploadProgress, setErrorState) => {

    // lets get this started
    setUploadProgress(1)
    
    const storage = firebase.storage();
    const storageRef = storage.ref();
    
    const uploadTask = storageRef.child(getAudioStoragePath()).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (error) => {
        setErrorState({
            code: error.code,
            message: error.message
        })
      },
      () => {
        // get a playable url from the snapshop ref
        const ref = uploadTask.snapshot.ref
        ref.getDownloadURL().then((url) => {
            setBlobUrl(url);
        })
      }
    );
  };


function getAudioStoragePath() {
    // only one video per user
    const userId = firebase.auth().currentUser.uid;
    return `nameRecording/${userId}`;
}

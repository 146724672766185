import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const CollapsiblePane = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="mb-3" style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}>
            <div className="collapse-content" style={{ marginTop: '10px' }}>
                {children}
            </div>
        </div>
    );
};

export default CollapsiblePane;

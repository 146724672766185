import { transcriptFeedbackColors } from "../utils/colors";

const SLTalkTime = ({
  learner,
  sl,
  bootcampAverage,
  lastTalkTimeDifference = null,
  isDifferencePositive = null,
}) => {
  return (
    <div className="border rounded p-3 bg-light">
      <div>
        <p
          style={{ fontSize: "1.3em", fontWeight: "600", marginBottom: "5px" }}
        >
          Your students talked {learner}% of the time, and you talked {sl}% of
          the time.
        </p>
        {lastTalkTimeDifference !== null && (
          <p
            style={{
              fontSize: "1em",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            There is a {lastTalkTimeDifference}%{" "}
            {isDifferencePositive ? "increase" : "decrease"} in student talk
            time from last time.
          </p>
        )}
      </div>
      <div>
        <TalkMeter
          learner={learner}
          sl={sl}
          bootcampAverage={bootcampAverage}
        />
      </div>
    </div>
  );
};

export default SLTalkTime;

const TalkMeter = ({ learner, sl, bootcampAverage }) => {
  return (
    <div style={{ marginTop: "30px" }}>
      <div
        style={{
          width: "100%",
          height: "20px",
          display: "block",
          position: "relative",
        }}
      >
        <div
          style={{
            width: `${learner}%`,
            backgroundColor: transcriptFeedbackColors.student,
            height: "inherit",
            display: "inline-block",
          }}
        ></div>
        <div
          style={{
            width: `${sl}%`,
            backgroundColor: transcriptFeedbackColors.sectionLeader,
            height: "inherit",
            display: "inline-block",
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            left: `${bootcampAverage}%`,
            top: "0px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "2px",
              backgroundColor: "black",
              height: "26px",
              bottom: "3px",
              left: "-1px",
              position: "relative",
            }}
          ></div>
          <div
            style={{
              display: "inline-block",
              bottom: "26px",
              left: "-4px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <p
              style={{
                fontSize: "0.8em",
                fontWeight: "600",
                overflow: "hidden",
                margin: "0px",
                whiteSpace: "nowrap",
              }}
            >
              Suggested student goal: {bootcampAverage}%
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.9em",
        }}
      >
        <span>Students {learner}%</span>
        <span>You {sl}%</span>
      </div>
    </div>
  );
};

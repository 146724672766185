import { PartialLoading } from "components/loading/Loading";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import {  useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { FaTimesCircle, FaArrowsAltH } from "react-icons/fa";

export const RankPostModal = ({ isOpen, setIsOpen, forumId, currPostId, currPostAuthor, currPostTitle }) => {
    const db = getFirestore();
    const courseId = useCourseId()
    const rankedPostsRef = doc(db, "forumData", courseId, "forums", forumId, "rank", "postList")
    const [rankedPostData, rankedPostDataLoading, rankedPostDataError] = useDocumentDataOnce(rankedPostsRef)
    const [rankedPostIds, setRankedPostIds] = useState([])
    const [rankedPostDataMap, setRankedPostDataMap] = useState({})
    const [initSwapPost, setInitSwapPost] = useState(false)


    useEffect(() => {
        console.log(rankedPostDataLoading, rankedPostData)

        if(rankedPostDataLoading) return;

        if(!rankedPostData || !rankedPostData.postIds) return;
        for(let id of rankedPostData.postIds) {
            setRankedPostDataMap(prev => {
                return {
                    ...prev,
                    [id]: {
                        title: "loading",
                        author: "loading"
                    }
                }
            })
        }
        setRankedPostIds(rankedPostData.postIds)

        const getRankedPostData = async () => {
            for(let id of rankedPostData.postIds) {
                if(id in rankedPostDataMap && rankedPostData[id].author !== "loading") continue;
                if(id === currPostId) {
                    setRankedPostDataMap(prev => {
                        return {
                            ...prev,
                            [id]: {
                                title: currPostTitle,
                                author: currPostAuthor
                            }
                        }
                    });
                } else {
                    const postRef = doc(db, "forumData", courseId, "forums", forumId, "posts", id)
                    const postDoc = await getDoc(postRef)
                    const postData = postDoc.data()
                    if(!postData) continue;
                    setRankedPostDataMap(prev => {
                        return {
                            ...prev,
                            [id]: {
                                title: postData.title,
                                author: postData.author
                            }
                        }
                    })
                }
            }
        }
        getRankedPostData()
        

    }, [rankedPostDataLoading, rankedPostData])



    const swapPost = async (postId) => {
        if(!initSwapPost) {
            setInitSwapPost(postId)
            return;
        }

        const newPostData = rankedPostIds.map(id => {
            if(id === postId) return initSwapPost;
            if(id === initSwapPost) return postId;
            return id;
        })

        await setDoc(rankedPostsRef, {
            postIds: newPostData
        }, {merge: true})
        setRankedPostIds(newPostData)

        
        setInitSwapPost(null)
    }

    const removePost = async (postId) => {
        const newPostData = rankedPostIds.filter(id => id !== postId)
        await setDoc(rankedPostsRef, {
            postIds: newPostData
        }, {merge: true})
        setRankedPostIds(newPostData)
    }


    const rankPost = async () => {
        const newPostData = [
            ...rankedPostIds,
            currPostId
        ]

        await setDoc(rankedPostsRef, {
            postIds: newPostData
        }, {merge: true})

        setRankedPostIds(newPostData)
        setRankedPostDataMap(prev => {
            return {
                ...prev,
                [currPostId]: {
                    title: currPostTitle,
                    author: currPostAuthor,
                }
            }
        });
    }



    if(rankedPostDataLoading) {
        return <PartialLoading/>
    }


    return (
        <Modal
            show={isOpen}
            onHide={() => setIsOpen(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Rank Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Title</th>
                            <th>Author</th>
                            <th>Swap</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankedPostIds.map((id, index) => {
                            const postData = rankedPostDataMap[id]
                            return (
                                <tr key={id}>
                                    <td>{index + 1}</td>
                                    <td>{postData.title}</td>
                                    <td>{postData.author}</td>
                                    <td>
                                        <SwapButton isCurrentSwap={id===initSwapPost} swapPost={() => swapPost(id)}/>
                                    </td>
                                    <td>
                                        <RemoveButton removePost={() => removePost(id)}/>
                                    </td>

                                    
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {
                    currPostId && !(currPostId in rankedPostDataMap) && (
                        <div>
                            <h3>Rank this post</h3>
                            <p><strong>{currPostAuthor}</strong></p>
                            <p>{currPostTitle}</p>
                            <button onClick={rankPost} className="btn btn-primary btn-sm">Rank</button>
                        </div>
                    )
                }
    
            </Modal.Body>
        </Modal>
    )

}




const SwapButton = ({ swapPost, isCurrentSwap }) => {





    return (
        <button className={`btn ${isCurrentSwap ? "btn-primary" : "btn-light"} `} onClick={swapPost}>
            <FaArrowsAltH/>
        </button>
    )
}


const RemoveButton = ({ removePost }) => {


    return (
        <button className="btn btn-danger" onClick={removePost}>
            <FaTimesCircle/>
        </button>
    )

}
import React, { useState, useEffect, useRef } from "react";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { query, where, orderBy, limit, startAfter, getDocs, startAt } from "firebase/firestore";
import { Button, Skeleton, Spin } from "antd";
import { PostReply } from "../PostReply/PostReply";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";

import "./PostReplies.css";

const limitPerPage = 5;

export function InfiniteScrollReplies(props) {
  const { parent, postId, level, condensed } = props;
  const dataFetcher = useForumDataFetcher();

  const [showReplies, setShowReplies] = useState(!condensed);
  const [replyIds, setReplyIds] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(true);
  const loader = useRef(null);

  // we need to react to a change in the 

  const toggleShowReplies = () => setShowReplies(!showReplies);

  // for infinite scroll
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      loadMoreReplies();
    }
  };

  // also for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    });
    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [lastVisible]);


  const repliesQuery = useFirestoreQuery(
    ["repliesto", parent],
    query(
      dataFetcher.get_reply_collection_ref(),
      where("parent", "==", parent),
      where("isDraft", "==", false),
      orderBy("time", "desc"),
      limit(limitPerPage)
    ),
    { subscribe: true }
  );

  // react to the repliesQuery changing
  useEffect(() => {
    if (repliesQuery.data) {
      const docs = repliesQuery.data.docs;
      const newIds = docs.map((doc) => doc.id);

      // WARNING
      // this really should only update if this
      // is the first reply to a post
      if(replyIds.length == 0) {
        setLastVisible(docs[docs.length - 1]);
      }

      // join the newIds with the old ones (new ids first)
      setReplyIds((prevIds) => {
        // append newIds to the front of the list
        // but only if they are not in the list already
        const newIdsFiltered = newIds.filter((id) => !prevIds.includes(id));
        return [...newIdsFiltered, ...prevIds];
      });

      
      setLoadingMore(false);
    }
  }, [repliesQuery.data]);
  


  const fetchMoreReplies = async (lastVisible) => {
    const repliesQuery = query(
      dataFetcher.get_reply_collection_ref(),
      where("parent", "==", parent),
      where("isDraft", "==", false),
      orderBy("time", "desc"),
      limit(limitPerPage),
      startAfter(lastVisible)
    );
    return await getDocs(repliesQuery); // Use Firestore's getDocs to fetch data
  };

  const loadMoreReplies = async () => {
    if (loadingMore || replyIds.length === 0) {
      return;
    }
    setLoadingMore(true);

    const result = await fetchMoreReplies(lastVisible); // Fetch next set of replies
    const newDocs = result.docs;

    if (newDocs.length === 0) {
      setLoadingMore(false);
      return; // No more data to load
    }

    setReplyIds((prevIds) => [...prevIds, ...newDocs.map((doc) => doc.id)]);
    setLastVisible(newDocs[newDocs.length - 1]);
    setLoadingMore(false);
  };

  if (repliesQuery.isLoading) {
    return <Skeleton />;
  }


  if (replyIds.length === 0 && loadingMore) {
    return <Skeleton />;
  }

  return (
    <div className="repliesContainer">
      <div className="replyBorder" onClick={toggleShowReplies} />

      <div className="replies">
        {showReplies ? (
          replyIds.map((id, i) => (
            <PostReply replyId={id} level={level} key={i} postId={postId} />
          ))
        ) : (
          <Button
            onClick={toggleShowReplies}
            type="text"
            className="showRepliesButton"
          >
            Show Replies
          </Button>
        )}

          <div ref={loader}></div>
      </div>
    </div>
  );
}

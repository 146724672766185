import { useParams } from 'react-router-dom';
import { FaChalkboardTeacher, FaVideo } from 'react-icons/fa';
import { CoursePageBodyContainer } from '../../components/layout/CoursePageBodyContainer';
import styled from 'styled-components'
import Button from "react-bootstrap/Button";
import { useContext } from 'react';
import { ProfileContext } from 'contexts/ProfileContext';
import { Checkmark } from "react-checkmark";
import { useCourseId } from 'hooks/router/useUrlParams';
import { CompletionContext } from 'contexts/CompletionContext';
import { useIsMobile } from 'utils/general';
import { useHistoryNavigate } from 'hooks/router/useHistoryNavigate';
import { RoadmapContext } from 'course/contexts/RoadmapContext';
import { TrainingContext } from 'course/contexts/TrainingContext'
import { joinZoom } from './zoomutil';
import { LearningCenterModulesSection } from 'components/layout/LearningCenterContainer';
import { modules } from './data';
import { lightPurple } from 'styles/colors';


export const TrainingSplash = () => {

  const { userData } = useContext(ProfileContext)
  const courseRole = userData?.courseRole

  return <CoursePageBodyContainer
    mainColumn={<LearnSplashMain />}
    rightColumn={<LearnRightColumn />}
    singleColumn={<LearnSplashSingle />}
  />
}

const LearnSplashSingle = () => {
  return <>
    <LearnRightColumn />
    <LearnSplashMain />
  </>
}

export const LearnSplashMain = () => {

  const { lessonsMap } = useContext(TrainingContext)
  const { getTeacherLessonsInRoadmap } = useContext(RoadmapContext)
  const roadmapLessons = getTeacherLessonsInRoadmap()

  const courseId = useCourseId()
  const showExtraTraining = courseId != "foothill-cs49" && courseId != "cs49-f24"
  
  const sortedLessons = roadmapLessons.map(lesson => {
    console.log(lesson)
    const key = lesson.lessonId
    return key in lessonsMap ? {
      title: lesson.title,
      id: lesson.lessonId,
      lessonNum: lessonsMap[key]?.lessonNum,
      lessonType: lessonsMap[key]?.type,
      published: lessonsMap[key]?.publish
    }: {
      title: "default",
      id: "default",
      lessonNum: 0,
      lessonType: "default",
      published: false
    }
  })

  /**@TODO - Remember to adjust this when we scale content types */
  const types = { // mapping of type field to correct title on web page
    'teacher': 'Basics of Section Leading'
  }

  // Gets lessons from sortedLessons by lesson type
  function LessonModule({ type }) {
    return (
      <>
        <h3>{types[type]}</h3>
        <div>
          <ModuleSectionContents>

            {
              sortedLessons.map((lesson) => {
                if (lesson.lessonType == type && lesson.published) {
                  return <LessonButton
                    title={lesson.title}
                    key={lesson.id}
                    lessonId={lesson.id}
                  />
                }

              })
            }
          
          </ModuleSectionContents>
        </div>

{/* This is old code from the Ed school teacher training. It should eventually be ported over */}
        {showExtraTraining && <LearningCenterModulesSection
        modules={modules}
        generateTitle={(mod) => (
          <>
            <strong>Module {mod.id}:</strong> {mod.title}
          </>
        )}
        generateLink={(mod) => `${mod.id}`}
      />
        }
      </>
    )
  }

  return (
    <div >
      <div style={{ fontSize: "16px", paddingTop: 46 }}>
        <p style={{ marginBottom: 36 }}>Welcome to the Section Leader Training Center. Here you will encounter
          a series of interactive learning modules that will support you in your
          teaching journey. Each module consists of several activities,
          including interactive videos, questions, and more..</p>
      </div>

      {
        Object.keys(types).map(key => {
          return (
            <LessonModule type={key} key={key} />
          )
        })}

      <hr />

      <div style={{ height: 60 }} />

    </div>
  )
}

const LearnRightColumn = () => {
  const courseId = useCourseId()

  const isMobile = useIsMobile();
  return <>
  <div style={{ marginTop: 30 }} className="splashCardRightTitle">Resources</div>
  <div className='d-flex flex-wrap gap-3 justify-content-left'>

  <a href={`/${courseId}/teacherhandbook`}
    target="_blank"
    className={'btn btn-outline-primary'}
    style={{width: isMobile ? "46%" : 180}}
  >
    <div>
      <div>
        <FaChalkboardTeacher className='splashButtonIcon' />
      </div>
      SL Handbook
    </div>
  </a>

  <a onClick={joinZoom}
    className={'btn btn-outline-primary'}
    style={{width: isMobile ? "46%" : 180}}
  >
    <div>
      <div>
        <FaVideo className='splashButtonIcon' />
      </div>
      Test Zoom Room
    </div>
  </a>

  </div>

</>
}



const LessonButton = ({ title, lessonId }) => {
  const { courseId } = useParams();
  const { isLessonCompleted } = useContext(CompletionContext)
  let lessonAddress = `/${courseId}/training/${lessonId}`
  const navigate = useHistoryNavigate()
  return (
    <ModuleLink onClick={() => navigate(lessonAddress)}>
      <ModuleLinkButton variant="light" size="lg">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            {title}
          </div>
          {isLessonCompleted(lessonId) ?
            <Checkmark size="medium" /> :
            <></>}

        </div>
      </ModuleLinkButton>
    </ModuleLink>
  )
}



const ModuleLink = styled.a`
  margin-bottom: 10px;
  display: block;
`;

const ModuleLinkButton = styled(Button)`
  width: 100%;
  max-width:700px;
  height: 60px;
  text-align: left;
  background-color: ${lightPurple};
  font-size: 1rem;
`;

const ModuleSectionContents = styled.div`
  text-align: left;
  margin-bottom: 20px; 
`;

import { ErrorPage } from "components/errors/ErrorPage"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useUserId } from "hooks/user/useUserId"
import { LandingNav } from "landing/components/LandingNav"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import Loading from "react-loading"
import { useNavigate, useParams } from "react-router"
import Swal from "sweetalert2"
import { DebuggingForm } from "./DebuggingForm"

export const Debugging = () => {
    return <div>
    <LandingNav applicationType={"sectionLeader"} subpage={"Debugging"}/>
    <div>
      <DebuggingInner/>
    </div>
  </div>
}

const DebuggingInner = () => {
    
  const navigate = useNavigate();
  const db = getFirestore();
  const userId = useUserId()
  const {targetCourseId} = useParams()

  const appDocRef = doc(db, `users/${userId}/${targetCourseId}/sectionLeaderApplication`);

  const [appData, appDataLoading, appDataError] = useDocumentDataOnce(appDocRef);

  if(appDataLoading){
    return <Loading/>
  }
  if(appDataError){
    return <ErrorPage msg = "Error loading application. Please try again later"/>
  }

  const appHomeUrl = `/public/applyteach/${targetCourseId}`

  const safeAppData = appData || {}

  async function onSubmit(data) {
    await setDoc(appDocRef, {
      karelDebug:data.karelDebug,
      pythonDebug:data.pythonDebug
    }, { merge: true });
    Swal.fire({
      title:"Success",
      heightAuto: false,
      text:"Your debugging answers have been recorded!",
      icon:"success",
      preConfirm:() => {
        navigate(appHomeUrl)
      }
    })
    
  }
  return <DebuggingForm
    serverData={safeAppData}
    onSubmit={onSubmit}
    backUrl={appHomeUrl}
  />
}


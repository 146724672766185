import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/functions";
import { firebaseConfig } from "./config";

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const database = firebase.database();
const functions = firebase.app().functions("us-central1");
const auth = firebase.auth;

export default firebase;
export { firestore, database, auth, functions };

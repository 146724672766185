import { SpeechBubbleLesson } from "../../components/duolessons/lessontypes/SpeechBubbles";


export const welcomeData = [
  {
    component: SpeechBubbleLesson,
    data: {
      text: <>Hello, my name is Karel!<br/> I have been used to teach programming at Stanford since 1980s</>
    }
  },
  {
    component: SpeechBubbleLesson,
    data: {
      text: <>Let's get this party started!</>
    }
  },
]
import styled from "styled-components";
import { Nav, Navbar as BootstrapNavbar, NavDropdown } from "react-bootstrap";
// import {LinkContainer} from 'react-router-bootstrap'
import { Link } from "react-router-dom";
import { useCourseId } from "../hooks/router/useUrlParams";
import { signOut, getAuth } from "firebase/auth";
import { Brand } from "./Brand";
import { ProfileContext } from "../contexts/ProfileContext";
import { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";

type NavbarProps = {
  to?: string;
  children?: React.ReactNode;
  title?: string;
};

const Navbar = ({ to, title, children }: NavbarProps) => {
  const courseId = useCourseId();
  return (
    <NavbarWrapper bg="dark" variant="dark">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-between"
      >
        <Nav>
          <BootstrapNavbar.Brand>
            <Link to={to || `/${courseId}/home`}>
              <Brand stanfordTo={to} title={title || "Code in Place"} university={"Stanford"} />
            </Link>
          </BootstrapNavbar.Brand>
        </Nav>

        {children && (
          <NavIcons
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {children}
          </NavIcons>
        )}
      </BootstrapNavbar.Collapse>
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled(BootstrapNavbar)`
  background-color:rgb(89, 64, 203) !important;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 0;
  height:45px;
`;

// rgb(208, 224, 255)
// rgb(89, 64, 203) nice purple
// #353a3f

const NavIcons = styled(Nav)`
  display: flex;
  alignitems: center;
`;

/* ProfileNav is a navigation icon so that users can manage their log-in
 * state and view their profile. It can only be used on a page that's wrapped
 * in ProfileProvider.
 */
export const ProfileNav = () => {
  const auth = getAuth();
  const { userData } = useContext(ProfileContext);

  const photoUrl = userData ? userData.photoURL : "";
  const courseId = useCourseId();
  return (
    <NavDropdown
      className="course-profile-dropdown"
      title={
        <img
          src={photoUrl}
          width="30"
          height="30"
          className="rounded-circle"
          style={{ objectFit: "cover" }}
        />
      }
      
    >
      <LinkContainer to={`/${courseId}/profile`}>
        <NavDropdown.Item>Profile</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Item
        onClick={() => {
          signOut(auth);
        }}
      >
        Log Out
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default Navbar;

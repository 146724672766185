const StudentFeedbackListing = ({ feedback }) => {
  return (
    <div>
      <h1>Student Feedback</h1>
      <p>
        We also asked your students to provide optional, anonymous feedback
        after every section. Read it here!
      </p>
      <div className="border rounded p-3 bg-light">
        {feedback.map((item, index) => (
          <div className="mb-4" key={index}>
            <p className="mb-1">{item.feedback}</p>
            <p className="m-0" style={{ fontStyle: "italic" }}>
              {item.studentName}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentFeedbackListing;

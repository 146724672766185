import ProgressChart from "./ProgressChart";

const StudentEngagement = ({
  sectionAverageValue,
  userValue,
  goalValue,
  lastWeekDifference,
  isLastWeekDifferencePositive,
}) => {
  return (
    <div>
      <h2>Your class talked for {userValue}% of the time.</h2>
      <div>
        <div className="mb-5">
          {lastWeekDifference && (
            <p style={{ fontSize: "1.1em" }}>
              This is <strong>{lastWeekDifference}%</strong>{" "}
              {isLastWeekDifferencePositive ? "more" : "less"} than last week.
            </p>
          )}

          <ProgressChart
            benchmarkText="CIP Average"
            benchmarkValue={sectionAverageValue}
            progressText="Your Section"
            progressValue={userValue}
            goalText="Goal"
            goalValue={goalValue}
            unit="%"
          />
          <p style={{ fontStyle: "italic" }}>
            Help you and your peers maximizing your learning experience by
            speaking up and sharing your ideas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentEngagement;

import { Button } from 'react-bootstrap';
import { FaSeedling } from 'react-icons/fa';

export const ConnectionsButton = ({
    viewerId, 
    userId, 
    loading, 
    status, 
    connectionData,
    toggle,
    setToggle
}) => {
    if (userId === viewerId) return <></>
    if (loading) return <></>

    if (status === 'connected') return <></>
    if (status === 'sentRequest') return <SentRequestButton toggle={toggle} setToggle={setToggle} />
    if (status === 'receivedRequest') return <></>
    if (status === 'dismissedRequest') return <DismissedRequestButton toggle={toggle} setToggle={setToggle} />
    if (status === 'notConnected') return <ConnectButton toggle={toggle} setToggle={setToggle} />
    return <></> // we should never get here
}

const ConnectButton = ({ toggle, setToggle }) => {
    const toggleShowSendRequestForm = (e) => {
        if (toggle === 'showSendRequestForm') {
            setToggle('')
        } else {
            setToggle('showSendRequestForm')
        }
    }
    return <Button variant='primary' onClick={toggleShowSendRequestForm}>
        {toggle === 'showSendRequestForm' ? 
        'Hide form' : <><FaSeedling/>&nbsp;&nbsp;Connect</>}
    </Button>
}

const SentRequestButton = ({ toggle, setToggle }) => {
    const toggleShowSentRequest = (e) => {
        if (toggle === 'showSentRequest') {
            setToggle('')
        } else {
            setToggle('showSentRequest')
        }
    }
    return <Button variant='success' onClick={toggleShowSentRequest}>{toggle === 'showSentRequest' ? 'Hide request' : 'Request sent'}</Button>
}

const DismissedRequestButton = ({ toggle, setToggle }) => {
    const toggleViewDismissedRequest = (e) => {
        if (toggle === 'viewDismissedRequest') {
            setToggle('')
        } else {
            setToggle('viewDismissedRequest')
        }
    }
    return <Button variant='secondary' onClick={toggleViewDismissedRequest}>{toggle === 'viewDismissedRequest' ? 'Hide request' : 'Show request'}</Button>
}
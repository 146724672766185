import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FormixFormWithData } from "components/Forms/FormixFormWithData";
import * as Yup from "yup";
import { TextAreaInput, TextInput } from 'components/Forms/FormixComponents';
import { FaPaperPlane, FaBullseye, FaLightbulb, FaQuestion } from 'react-icons/fa';
import Swal from "sweetalert2";
import { useFirebaseDocOnce } from 'hooks/database/useFirebaseDocOnce';
import { getFunctions, httpsCallable } from "firebase/functions"

export const SendRequestForm = ({
    userId, 
    viewerId, 
    userData,
    toggle,
    setStatus,
    setConnectionData,
    setToggle
}) => {
    const requestData = useFirebaseDocOnce(`users/${viewerId}/docs/protected/sentRequests/${userId}`, {})
    const functions = getFunctions();
    const sendConnectionRequest = httpsCallable(functions, "sendConnectionRequest");
    const [submitLoading, setSubmitLoading] = useState(false)

    if (toggle === 'showSendRequestForm') {
        const handleSubmit = async (e) => {
            setSubmitLoading(true)
            const res = await sendConnectionRequest({
                viewerId, 
                userId,
                viewerId_msg: e.message,
                viewerId_contact: e.contact,
            })
            // @ts-ignore
            if (res.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your connection request has been sent!',
                })
                setSubmitLoading(false)
                setStatus('sentRequest')
                // @ts-ignore
                setConnectionData(() => {return res.data.newConnectionData})
                setToggle('')
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
                setSubmitLoading(false)
            }
        }
        
        const MessageInput = ({name}) => {
            const description = <>
                Here are some ideas...
                <ul style={{ listStyleType: "none", padding:0 }}>
                    <li><FaBullseye/>&nbsp;&nbsp;Discuss a shared learning goal</li>
                    <li><FaLightbulb/>&nbsp;&nbsp;Share a common subject of interest</li>
                    <li><FaQuestion/>&nbsp;&nbsp;Ask a question</li>
                </ul>
            </>
            return <TextAreaInput
                label="Your Message"
                name={name}
                description={description}
                placeholder={`I'd love to learn more about...`}
                style={{backgroundColor: 'white'}}
            />
        }

        const ContactInfoInput = ({name}) => <TextInput
            label="Your Contact Info"
            description={<>We will only share this with {userData.displayName} if they accept your request.<br/>For your own privacy, we recommend sharing a Linkedin link or making an email alias.</>}
            name={name}
            type="text"
            placeholder="linkedin.com/in/your-username"
            style={{backgroundColor: 'white'}}
        />

        const formFormat = [
            {
                name: "message",
                input: MessageInput,
                default:'',
                validation: Yup.string()
                .required("Message is required"),
            },
            {
                name: "contact",
                input: ContactInfoInput,
                default:'',
                validation: Yup.string()
                .required("Contact info is required"),
            },
        ]

        return <>
            <Alert className='mt-3' style={{backgroundColor:"#EFF1FB"}}>
                <Alert.Heading>Send a connection request to {userData.displayName}!</Alert.Heading>
                <FormixFormWithData
                    serverData={requestData}
                    backUrl={null}
                    formFormat={formFormat}
                    onSubmit={(e, cb) => handleSubmit(e)}
                    showGlobalError={false}
                    autoSave={false}
                    submitButtonInner={submitLoading ? <>Sending...</> : <><FaPaperPlane/>&nbsp;&nbsp;Send</>}
                    customDisableSubmit={submitLoading}
                />
            </Alert>
        </>
    }
    return <></>
}

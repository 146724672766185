import React, { useRef, useEffect, useState, useContext, useCallback } from "react";
import useEffectExceptOnMount from "../hooks/useEffectExceptOnMount";
import { checkIsProjectKarel, checkIsProjectConsole } from "../utils/general";
import KarelPythonCompiler from "../utils/karelCompiler/karelPythonCompiler.js";
import { getDefaultWorldState } from "components/pyodide/KarelLib/util";
import { useCollection } from "react-firebase-hooks/firestore";
import useMediaQuery, { Breakpoints } from "utils/useMediaQuery"
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { getApp } from "firebase/app";
import { AssnContext } from "course/contexts/AssnContext";
import { useIDEChatLoaders } from "components/duolessons/lessonchats/IDEChatHooks";
import { useCourseId } from "hooks/router/useUrlParams";
import { getAuth } from "@firebase/auth";
import { useMoodSurveyLoadersAssn } from "components/duolessons/lessonchats/MoodSurveyHooks";
import { emptyTestResult } from "ide/UnitTest/UnitTestResults";
import { useUserId } from "hooks/user/useUserId";


const defaultData = {
  projectData: null,
  assnData: null,
  stepMode: false,
  editable: true,
  setStepMode: (val) => { },
  lineNo: 0,
  setLineNo: (val) => { },
  stepPtr: 0,
  setStepPtr: (val) => { },
  stepData: new Map(),
  setStepData: (val) => { },
  hasHistory: false,
  setHasHistory: (val) => { },
  editorRef: null,
  editorLoaded: false,
  setEditorLoaded: (val) => {},
  terminalRef: null,
  pyodideClientRef: null,
  karelWorldState: null,
  stepList: null,
  setStepList: (val) => { },
  stepLogs: null,
  setStepLogs: (val) => { },
  helpMode: false,
  setHelpMode: (val) => { },
  setKarelSleepTime: (val) => { },
  karelSleepTime: 0.2,
  runErrorOccurred: false,
  setRunErrorOccurred: (val) => { },
  runSuccessOccurred: false,
  setRunSuccessOccurred: (val) => { },
  loadingError: null,
  isProjectLoading: true,
  terminalViewState: 'standard',
  canvasViewState: 'standard',
  leftColViewState: 'standard',
  setTerminalViewState: (val) => { },
  setCanvasViewState: (val) => { },
  lastRunPassed: false,
  setLastRunPassed: (val) => { },
  consoleTestResults: null,
  setConsoleTestResults: (val) => { },
  isRunning: false,
  setIsRunning: (val) => { },
  codeToRun: null,
  defaultKarelWorldState: null,
  setDefaultKarelWorldState: null,
  selectedErrorMessageType: 'default',
  setSelectedErrorMessageType: (val) => { },
  forumLinks: null,
  errorLineNo: 0,
  editorFontSize:14,
  setEditorFontSize: (val) => {},
  setErrorLineNo: (val) => {},
  selectedTab:null,
  setSelectedTab:(val)=>{},
  isTabletOrLarger: true,
  setLeftColViewState: (val) => { },
  stepSaveOn: true,
  setStepSaveOn: (val) => { },
  isDiagnostic: false,
  showSoln: false,
  setShowSoln: (val) => { },
  screenReadableEditorOn: false,
  setScreenReadableEditorOn: (val) => { },
  karelType: null,
  setKarelType: (val) => { },
  isKarelSelectOpen: false,
  setIsKarelSelectOpen: (val) => {},
  initSetCode: (fn) => {},
  setCode: (val) => {},
  courseId: null,
  aiAutograderRunning: false,
  setAiAutograderRunning: (val) => {},
  chatType: undefined,
  chatMessages: [],
  tempMessages: [],
  setTempMessages: (val) => { },
  unreadMessageFlag: false,
  setUnreadMessageFlag: (val) => { },
  setChatTimestamp: (val) => { },
  runMoodSurvey: async () => { },
  loadChat: async () => {},
  unittestResults: emptyTestResult,
  setUnittestResults: (val) => {},
  isSubmitted: false,
  setIsSubmitted: (val) => {},
};
export const IDEContext = React.createContext(defaultData);

export const IDEProvider = ({ children, useProject, isDiagnostic = false }) => {

  const assnContext = useContext(AssnContext)

  const [projectData, assnData, loadingError, isProjectLoading] = useProject(assnContext);
  const isTabletOrLarger = useMediaQuery(Breakpoints.TAB);
  const courseId = useCourseId()

  // Replay-related states
  const [hasHistory, setHasHistory] = useState(defaultData.hasHistory);
  const [stepMode, setStepMode] = useState(defaultData.stepMode);
  const [lineNo, setLineNo] = useState(defaultData.lineNo);
  const [stepPtr, setStepPtr] = useState(defaultData.stepPtr);
  const [stepData, setStepData] = useState(defaultData.stepData);
  const [stepList, setStepList] = useState(defaultData.stepList);
  const [stepLogs, setStepLogs] = useState(defaultData.stepLogs);
  const [stepSaveOn, setStepSaveOn] = useState(defaultData.stepSaveOn);
  const [isSubmitted, setIsSubmitted] = useState(defaultData.isSubmitted);

  
  // Editor view
  // TODO: these could load from the database ("users", userId, "settings", "ide"). 
  const [screenReadableEditorOn, setScreenReadableEditorOn] = useState(defaultData.screenReadableEditorOn)
  const [editorFontSize, setEditorFontSize] = useState(defaultData.editorFontSize)

  // Left col
  const [selectedTab, setSelectedTab] = useState(null);
  const [editable, setEditable] = useState(defaultData.editable);
  const [helpMode, setHelpMode] = useState(defaultData.helpMode);
  const [showSoln, setShowSoln] = useState(false)
  const [karelType, setKarelType] = useState(defaultData.karelType);
  const [isKarelSelectOpen, setIsKarelSelectOpen] = useState(defaultData.isKarelSelectOpen);

  // Desktop view
  // "standard" "minimized" or "expanded"
  const [leftColViewState, setLeftColViewState] = useState("standard");
  const [terminalViewState, setTerminalViewState] = useState(defaultData.terminalViewState);
  const [canvasViewState, setCanvasViewState] = useState(defaultData.canvasViewState);
  const [lastRunPassed, setLastRunPassed] = useState(defaultData.lastRunPassed);
  const [consoleTestResults, setConsoleTestResultsState] = useState(defaultData.consoleTestResults);
  const [isRunning, setIsRunning] = useState(defaultData.isRunning)

  // Error messages
  const [selectedErrorMessageType, setSelectedErrorMessageType] = useState(defaultData.selectedErrorMessageType);
  // const [forumLinks] = useCollection(collection(db, 'forumLinks'));
  const forumLinks = null;
  const [errorLineNo, setErrorLineNo] = useState(defaultData.errorLineNo);
  const [runErrorOccurred, setRunErrorOccurred] = useState(defaultData.runErrorOccurred);
  const [runSuccessOccurred, setRunSuccessOccurred] = useState(defaultData.runSuccessOccurred);

  // Karel
  const [karelSleepTime, setKarelSleepTime] = useState(defaultData.karelSleepTime);
  const [karelWorldState, setKarelWorldState] = useState(defaultData.karelWorldState);
  const [defaultKarelWorldState, setDefaultKarelWorldState] = useState(defaultData.defaultKarelWorldState);

  // Unit test results. Juliette adding here for style feedback 
  const [unittestResults, setUnittestResults] = useState(emptyTestResult);

  // Refs
  const editorRef = useRef(null);
  const [editorLoaded, setEditorLoaded] = useState(defaultData.editorLoaded);
  const terminalRef = useRef(defaultData.terminalRef);
  const pyodideClientRef = useRef(defaultData.pyodideClientRef);
  const codeToRun = useRef(defaultData.codeToRun)

  // Ai Autograder
  const [aiAutograderRunning, setAiAutograderRunning] = useState(defaultData.aiAutograderRunning)

  // Set code function
  const [setCode, setCodeUpdater] = useState((v) => { });

  const {
    chatType,
    chatMessages,
    tempMessages,
    setTempMessages,
    unreadMessageFlag,
    setUnreadMessageFlag,
    setChatTimestamp,
    loadChat
  } = useIDEChatLoaders({ selectedTab, courseId });

  const { runMoodSurvey } = useMoodSurveyLoadersAssn();

  useEffect(() => {
    if(projectData && projectData.editors) {
      const userId = getAuth().currentUser.uid
      setEditable(projectData.editors.includes(userId))
    }

  }, [projectData])


  useEffect(() => {
    // gather relevant karel data
    if (checkIsProjectKarel(projectData, assnData)) {
      let karelStarterWorld = getKarelStarterWorld(assnData, projectData);
      setDefaultKarelWorldState(karelStarterWorld)
      setTerminalViewState('minimized');
      setKarelWorldState(karelStarterWorld);
    }

    // no unit tests
    // const unitTests = assnData?.unitTests?.unitTests
    // const noUnitTests = !unitTests || unitTests?.length === 0
    const assnId = assnData?.metaData?.uid
    const isAssnSubmitted = assnData?.submissionData?.isSubmitted
    setIsSubmitted(isAssnSubmitted)

    const noUnitTests = isDiagnostic || !assnId
    if (checkIsProjectConsole(projectData, assnData) && noUnitTests) {
      setCanvasViewState('minimized');
    }
  }, [assnData, projectData]);

  useEffectExceptOnMount(() => {
    // Upon leaving step mode
    if(terminalRef.current) {
      if(stepMode) {
        terminalRef.current.enterStep()
      } else {
        terminalRef.current.leaveStep()
      }
    }
  }, [stepMode]);

  const setConsoleTestResults = (val) => {
    // if the val array is empty do nothing
    if (!val || val.length != 0) {
      setCanvasViewState('standard');
    }
    setConsoleTestResultsState(val)
  }

  const initSetCode = useCallback((updateFn) => {
    setCodeUpdater(() => updateFn);
  }, []);

  return (
    <IDEContext.Provider
      value={{
        projectData,
        assnData,
        stepMode,
        setStepMode,
        lineNo,
        setLineNo,
        stepPtr,
        setStepPtr,
        stepData,
        setStepData,
        hasHistory,
        setHasHistory,
        terminalRef,
        editorRef,
        editorLoaded,
        setEditorLoaded,
        pyodideClientRef,
        karelWorldState,
        setKarelWorldState,
        editable,
        setEditable,
        stepList,
        setStepList,
        stepLogs,
        setStepLogs,
        helpMode,
        setHelpMode,
        karelSleepTime,
        setKarelSleepTime,
        setDefaultKarelWorldState,
        defaultKarelWorldState,
        runErrorOccurred,
        setRunErrorOccurred,
        runSuccessOccurred,
        setRunSuccessOccurred,
        loadingError,
        isProjectLoading,
        terminalViewState,
        setTerminalViewState,
        canvasViewState,
        setCanvasViewState,
        lastRunPassed,
        setLastRunPassed,
        consoleTestResults,
        setConsoleTestResults,
        isRunning,
        setIsRunning,
        codeToRun,
        selectedErrorMessageType,
        setSelectedErrorMessageType,
        forumLinks,
        errorLineNo,
        setErrorLineNo,
        selectedTab,
        setSelectedTab,
        isTabletOrLarger,
        leftColViewState,
        setLeftColViewState,
        stepSaveOn,
        setStepSaveOn,
        isDiagnostic,
        showSoln,
        setShowSoln,
        screenReadableEditorOn,
        setScreenReadableEditorOn,
        editorFontSize,
        setEditorFontSize,
        karelType,
        setKarelType,
        isKarelSelectOpen,
        setIsKarelSelectOpen,
        setCode,
        initSetCode,
        courseId,
        aiAutograderRunning,
        setAiAutograderRunning,
        chatType,
        chatMessages,
        tempMessages,
        setTempMessages,
        unreadMessageFlag,
        setUnreadMessageFlag,
        setChatTimestamp,
        runMoodSurvey,
        loadChat,
        unittestResults,
        setUnittestResults,
        isSubmitted,
        setIsSubmitted
      }}
    >
      {children}
    </IDEContext.Provider>
  );
};

export function getKarelStarterWorld(assnData, projectData) {
  let unitTests = assnData?.unitTests?.unitTests;
  // the user made a karel world
  let userKarel = projectData?.userKarel;

  // assignment with no unit tests
  let assnWithNoUnitTests = unitTests?.length == 0;

  if (!unitTests && !userKarel || assnWithNoUnitTests) {
    return getDefaultWorldState(10, 10);
  } else if (!unitTests) {
    return userKarel;
  }
  let firstTest = unitTests[0];
  return firstTest?.pre;
}

import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom"
import { getFirestore, doc, setDoc } from "@firebase/firestore";
import { useUserId } from "hooks/user/useUserId";



export const useReferralHook = () => {

    // get search params
    const [searchParams, setSearchParams] = useSearchParams();
    const referrer = searchParams.get("r");
    const db = getFirestore();
    const userId = useUserId();
    const {targetCourseId} = useParams()



    useEffect(() => {
        if (referrer) {
            const docRef = doc(db, "applicationReferrals", targetCourseId, "hashes", referrer);
            setDoc(docRef, { 
                [userId]: userId 
            }, { merge: true })
            
        }
    }, [referrer])


}

import React, { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import OnboardForm from "./OnboardForm";
import { useNavigate } from "react-router";
import { StudentJoinNav } from "onboarding/studentOnboarding/StudentJoinNav";
import { StudentOnboardingContext } from "onboarding/studentOnboarding/contexts/StudentOnboardingContext";
import { useCourseId } from "hooks/router/useUrlParams";

export const StudentAccommodations = (props) => {
  document.title = "Accommodations";
  const courseId = useCourseId()
  const {onboardingData, editOnboarding} = useContext(StudentOnboardingContext);

  const user = props.user;
  const navigate = useNavigate();

  


  const accommodations = onboardingData?.accommodations || '';
  console.log("accommodations", accommodations)

  const setAccommodations = (accommodations) => {
    editOnboarding({accommodations: accommodations.accommodations});
    navigate(`/${courseId}/onboarding/student`);
  };

  return (
    <div>
      <div
        style={{
          width: "720px",
          maxWidth: "90vw",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Card style={{ margin: "auto", padding: "20px" }}>
          <OnboardForm
            accommodations={accommodations}
            onSubmit={setAccommodations}
          />
        </Card>
      </div>
    </div>
  );
};

import Swal from "sweetalert2";



export const itemSwitchSpeedbump = async (warningMessage) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: warningMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, switch it!',
        cancelButtonText: 'No, keep it'
    })

    return result.isConfirmed;
}
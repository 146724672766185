import React, { useState, useContext, useEffect, useRef } from "react";
import { useComponentSize } from "react-use-size";
import { IDEContext } from "../contexts/IDEContext";
import { ExpandContractButton, MinMaxButtonVertical } from "../ResizeButtons";
import { XTermTerminal } from "./Terminal/XTermTerminal";
import { AccessibleTerminal } from "./AccTerminal/AccTerminal";

export const TerminalPane = (props) => {
  let outerSize = useComponentSize();
  const replayTerminal = useRef(null);
  const { stepSaveOn, stepMode, stepPtr, stepList, stepLogs, screenReadableEditorOn } = useContext(IDEContext);
  const [stepperData, setStepperData] = useState([]);
  useEffect(() => {
    if (stepMode && stepSaveOn) {
      setStepperData(stepLogs.slice(0, stepList[stepPtr]["logptr"]));

      if (replayTerminal.current) {
        replayTerminal.current.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
    }
  }, [stepPtr]);

  useEffect(() => {
    if (props.terminalRef.current) {
      props.terminalRef.current.updateCols(outerSize.width);
    }
  }, [outerSize.width]);

  useEffect(() => {
    if (props.terminalRef.current) {
      props.terminalRef.current.updateRows(outerSize.height + 20);
    }
  }, [outerSize.height]);

  useEffect(() => {
    if (props.terminalRef.current) {
      props.terminalRef.current.updateRows(outerSize.height + 20);
      props.terminalRef.current.updateCols(outerSize.width);
    }

  }, [props.terminalRef.current]);


  if(stepMode){
    return (
      <div
        className="w-100 h-100 d-flex flex-column"
        ref={outerSize.ref}
        style={{
          backgroundColor: "#2d2e2c",
          position: "relative",
          zIndex: "1",
        }}
      >
        <TerminalToolbar {...props} />
        <div style={{ flexGrow: 2, overflow: "auto", paddingLeft: "10px", position: "relative" }}>
          <div
            style={{
              fontFamily: "Menlo",
              fontSize: "14px",
              color: "white",
              lineHeight: "1rem",
              marginTop: "2px",
              height: "auto",
            }}
            ref={replayTerminal}
          >
            {stepperData.map((line) => {
              return <div>{line}</div>;
            })}
          </div>
        </div>
      </div>
    )
  }


  if( screenReadableEditorOn){
    return (
      <div
        className="w-100 h-100 d-flex flex-column"
        ref={outerSize.ref}
        style={{
          backgroundColor: "#2d2e2c",
          position: "relative",
          zIndex: "1",
        }}
      >
        <TerminalToolbar {...props} />
        <AccessibleTerminalContainer/>
      </div>
    )
  }

  const terminalContainerStyle = {
    width: `${outerSize.width}px`,
    height: `${outerSize.height}px`,
    overflow: 'hidden', // To prevent scrollbars if the terminal is exactly the size of the container
  };

  return (
    <div
      className="w-100 d-flex flex-column"
      ref={outerSize.ref}
      style={{
        backgroundColor: "#2d2e2c",
        position: "relative",
        height: "calc(100% - 30px)",
      }}
    >
      <TerminalToolbar {...props} />
      <div style={terminalContainerStyle}>
        <XTermContainer/>
      </div>
    </div>

  );
};


export const TerminalToolbar = (props) => {
  const { stepMode } = useContext(IDEContext);

  return (
    <div
      className="w-100"
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#2d2e2c",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "10px",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          gap: 10,
        }}
      >
        <span style={{ color: "white" }} className="ideHeadingDark">
          {stepMode ? "Replay Terminal" : "Terminal"}
        </span>
      </div>

      {!stepMode ? (
        <div>
          <ExpandContractButton {...props} variant="btn-dark" />
          <MinMaxButtonVertical {...props} variant="btn-dark" />
        </div>
      ) : null}
    </div>
  );
};




const AccessibleTerminalContainer = () => {

  return <div style={{
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
    height: "100%",
  }}>
    <AccessibleTerminal/> 
  </div>
}



const XTermContainer = () => {
  // Default width and height could be set based on expected container size or screen size


  const style = {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    top: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    overflow: "scroll",
    height: "100%",
    scrollbars: "none",

  };

  return (
    <XTermTerminal style={style} />
  );
};
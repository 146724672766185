

export const englishGraphicsStarterCode = `from graphics import Canvas
    
CANVAS_WIDTH = 400
CANVAS_HEIGHT = 400

def main():
    canvas = Canvas(CANVAS_WIDTH, CANVAS_HEIGHT)
    # TODO: your code here!

if __name__ == '__main__':
    main()`

export const englishKarelStarterCode = `from karel.stanfordkarel import *

def main():
    put_beeper()

# don't change this code
if __name__ == '__main__':
    main()`

export const englishConsoleStarterCode = `def main():
    print("Hello World!")

if __name__ == "__main__":
    main()`

// ------------- SPANISH TRANSLATIONS -------------

export const spanishGraphicsStarterCode = `from graphics import Lienzo

ANCHURA_LIENZO = 400
ALTURA_LIENZO = 400

def main():
    lienzo = Lienzo(ANCHURA_LIENZO, ALTURA_LIENZO)

if __name__ == '__main__':
    main()`

export const spanishKarelStarterCode = `from karel.stanfordkarel import *

def main():
    poner_cono()

# no cambies este código
if __name__ == '__main__':
    main()`

export const spanishConsoleStarterCode = `def main():
    print("¡Hola Mundo!")

if __name__ == "__main__":
    main()`
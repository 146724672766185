
export const Responsibilities = () => {
  return (
    <div>
      <h1>Responsibilities</h1>

      <ol>
      <li>Complete 3.5 hours of teacher training.</li>
        <li>Teach a 50-minute section once a week for 7 weeks.</li>
        <li>Prepare for your section.</li>
        <li>Spend a small amount of time (around one hour a week) helping your students.</li>
      </ol>
      
      <p>
        You’ll be teaching about 10-15 students from all over the world, and
        we’ll be providing you support and training distilled from decades of
        Stanford CS experience. 
      </p>

      <p>
        At the beginning of the course, you'll go through 3.5 hours of teacher
        training to make sure that you go into your first section prepared.
      </p>
      <p>
        Following that, each week you'll need to prepare for, and teach, your
        50-minute section. To help you prepare we will provide you with a full
        lesson plan. In addition you should spend some time answering questions
        from your students. If you have experience teaching in Python, we expect
        that these responsibilities will amount to about 3 hours a week. If this
        is your first time, you will need a few extra hours to
        familiarize yourself with the material. You will teach:
        <ol>
          <li>Python with <a target="_blank" href="https://compedu.stanford.edu/karel-reader/docs/python/en/chapter1.html">Karel the Robot</a></li>
          <li>Console Programming</li>
          <li>Graphics Programming</li>
        </ol>
        <p>The course is in standard Python, but for Karel and Graphics we use easy-to-learn libraries. You are not expected to know them before the course starts.</p>
      </p>

      <p>
        The position is voluntary, so while it's unpaid, you'll get to be
        part of a fantastic community of teachers (with a LinkedIn group) and
        you will be given training on how to become a more confident teacher. If
        you'd like to go above and beyond and help out in other ways, there will
        be plenty of opportunities. 
      </p>
    </div>
  );
};

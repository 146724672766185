import { ProfileContext } from "contexts/ProfileContext"
import { useContext } from "react"
import { Navigate } from "react-router"
import {useCourseId} from "hooks/router/useUrlParams"

export const ProjectForumRedirect = ({}) => {
  const {userData} = useContext(ProfileContext)

  const courseId = useCourseId()
  let nGroups = 0;
  let projectForumIds;
  const finalProjectCommunities = userData?.finalProjectCommunities
  if (finalProjectCommunities && courseId in finalProjectCommunities){
    projectForumIds = finalProjectCommunities[courseId]
    nGroups = projectForumIds ? projectForumIds.length : 0
  }

  if(nGroups > 1){
    return <>You are in multiple project communities!</>
  }

  if(nGroups === 1){
    const forumId = projectForumIds[0]
    return <Navigate to={`/${courseId}/projectForum/${forumId}`} />
  }


  return <>You are not in a project community yet!</>
}

import { doc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Swal from "sweetalert2";

export const ApplicationVersionMonitor = () => {
    const [currVersion, setCurrVersion]= useState(null)

    const db = getFirestore()
    const appDocRef = doc(db, `global/webapp`);
    const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef)

    useEffect(() => {
        if(appData) {
            if(currVersion === null){
                setCurrVersion(appData.version)
            }
            else if(currVersion !== appData.version){
                suggestPageReload()
            }
        }
    }, [appData])

    if(appDataLoading || appDataError){
        return <></>
    }

    return <></>
}

function suggestPageReload() {
    Swal.fire({
        title:"Code in Place website updated",
        position: 'top-end',
        confirmButtonText: 'Reload',
        cancelButtonText: 'Ignore',
        toast: true,
        showCancelButton: true,
        icon:"info",
        preConfirm:() => {
            window.location.reload()
        }   
    })
}

import NoAccess from "components/errors/NoAccess"
import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol"
import { ProfileContext } from "contexts/ProfileContext"
import { Role } from "types/role";
import { isMinimumRole } from "contexts/profile_util"
import { getApp } from "firebase/app"
import { collection, getFirestore, orderBy, query, where } from "firebase/firestore"
import BootstrapTable from 'react-bootstrap-table-next';
import { useContext, useEffect, useState } from "react"
import { Accordion } from "react-bootstrap"
import { useCollection, useCollectionOnce, useDocument, useDocumentData } from "react-firebase-hooks/firestore"
import { get, getDatabase, off, onChildAdded, ref } from "firebase/database"
import { CopyButton } from "components/reusableButtons/CopyButton"
import { mean, variance } from 'mathjs'
import { MessageWithMarkdown } from "components/teachnowchat/ChatRoom"
import { lazy } from "react"
import { FaVideo } from "react-icons/fa"
import { useCourseId } from "hooks/router/useUrlParams"

const Plot = lazy(() => import("react-plotly.js"))




const ERRORBAR_OPTIONS = {
  width: 2,
  thickness: 0.3,
  zorder: 0,
}

export const TeachNowDashboard = () => {
  const { userData } = useContext(ProfileContext)
  const courseRole = userData?.courseRole
  const [timeIntervalHours, setTimeIntervalHours] = useState(24)
  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return <NoAccess />
  }

  console.log("TeachNowDashboard", timeIntervalHours)

    return (
        <CoursePageSingleCol
          column = {
            <div className="mt-4">
              <h2> Statistics </h2>
              <div className="mb-2 mt-2">
                <label>Time interval (hours): </label>
                {/* slider */}
                <input className="ml-1 mr-1" type="range" min="2" max="24"  step="2"
                value={timeIntervalHours} onChange={(e) => setTimeIntervalHours(parseInt(e.target.value))} />
                <span>{timeIntervalHours}</span>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>TeachNow Sessions</Accordion.Header>
                  <Accordion.Body>
                  <TeachNowSessions timeIntervalHours={timeIntervalHours}/>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Nudge Statistics</Accordion.Header>
                  <Accordion.Body>
                    <NudgeStatistics timeIntervalHours={timeIntervalHours}/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <h2 className="mt-4"> Session browser </h2>
              <TeachNowSessionBrowser />
            </div>
          }
        />
    )
}

const TeachNowSessionBrowser = () => {
    const courseId = useCourseId()
    const db = getFirestore(getApp())
    const ticketsRef = collection(db, "teachnow", courseId, "tickets")
    const ticketsQuery = query(ticketsRef, where("matched", "==", true))
    // const ticketsQuery = query(ticketsRef, orderBy("timeCreatedServer", "desc"))
    const [roomRows, setRoomRows] = useState([])

    const [ticketsCollection, ticketsLoading, ticketsError] = useCollectionOnce(ticketsQuery)


    useEffect(() => {
      console.log("ticketsLoading", ticketsCollection)
      if (ticketsLoading) {
        return
      }
      const ticketsData = ticketsCollection.docs.map(doc => doc.data())
        .sort((a, b) => b.timeCreatedServer - a.timeCreatedServer).
        // filter((ticket) => {
          // console.log('========>', ticket)
          // return ticket.zoomAllocation && ticket.zoomAllocation.zoomId === "0s6KzZwVS16_VQqTqFBNDQ"
        // }).
        slice(0, 20)

      Promise.all(ticketsData.map((ticketData) => getRoomInfo(ticketData, courseId))).then((roomRowsPromise) =>
        setRoomRows(roomRowsPromise)
      )
    }, [ticketsLoading, ticketsCollection])


    if(ticketsLoading) return <div>Loading...</div>


    const defaultSorted = [{
      dataField: 'timeCreatedServer', // Field to sort by
      order: 'desc',           // Sort order: 'asc' for ascending, 'desc' for descending
    }];

    const columns = [
      {
        dataField: 'roomId',
        text: 'Room ID',
        sort: false
      },
      {
        dataField: 'zoomId',
        text: 'Zoom',
        sort: false
      },
      // {
      //   dataField: 'allocId',
      //   text: 'Alloc ID',
      //   sort: false
      // },
      {
        dataField: 'members',
        text: 'Members',
        sort: false,
      },
      {
        dataField: 'messages',
        text: 'Messages',
        sort: false,
      },
      {
        dataField: 'timeCreated',
        text: 'Time Created',
        sort: true
      }
    ]

    return (
      <>
       <BootstrapTable
          key={'teachnow-sessions'}
          data={roomRows}
          columns={columns}
          bordered={false}
          striped={true}
          hover={true}
          bootstrap4={true}
          keyField='timeCreated'
          defaultSorted={defaultSorted}
        />
      </>
    )
}

const getRoomInfo = async (ticketData, courseId) => {
  const {ticketId, timeCreatedServer} = ticketData;
  const timeCreated = timeCreatedServer.toDate();

  const db = getDatabase(getApp());
  const meetingInfoSnap = await get(ref(db, `${courseId}/teachnow/rooms/info/${ticketId}`));
  const roomMembersSnap = await get(ref(db, `${courseId}/teachnow/rooms/members/${ticketId}`));

  const meetingInfo = meetingInfoSnap.val();
  const roomMembers = roomMembersSnap.val();

  console.log("meetingInfo", meetingInfo);
  const membersFlat = Object.entries(roomMembers).map(([uid, value]) => {
    return {
      uid,
      ...value
    }
  }).sort((a, b) => a.role == "sl" ? -1 : 1)


  return {
    roomId: <CopyButton toCopy={ticketId} icon={ticketId.slice(0, 7)} />,
    zoomId: <CopyButton toCopy={meetingInfo.zoomInfo.zoomId} icon={<FaVideo style={{width: "20px"}}/>} />,
    // allocId: <CopyButton toCopy={ticketData.zoomAllocation.allocationId} icon={ticketData.zoomAllocation.allocationId.slice(0, 7)} />,
    members: <RoomMembers members={membersFlat} />,
    messages: <RoomMessages roomInfo={{ticketId, courseId, roomMembers}} />,
    timeCreated: timeCreated.toLocaleString()
  }
}

const RoomMessages = ({roomInfo}) => {
  const {ticketId, courseId, roomMembers} = roomInfo;
  const [messages, setMessages] = useState(undefined)

  // only listen to messages on anchor expan and close the listener when the accordion is closed
  useEffect(() => {
    if(!ticketId || !courseId) {
      return
    }
    const db = getDatabase(getApp());
    const messagesRef = ref(db, `${courseId}/teachnow/rooms/messages/${ticketId}`);
    setMessages([])
    const messagesListener = onChildAdded(messagesRef, (snapshot) => {
      const message = snapshot.val();
      setMessages((prevMessages) => {
        return prevMessages ? [...prevMessages, message] : [message]
      })
    })

    return () => {
      off(messagesRef, "child_added", messagesListener)
    }
  }, [ticketId, courseId])

  if (messages === undefined) {
    return <div>Loading...</div>
  }

  console.log(roomMembers)
  return (
    <Accordion style={{width: '400px'}}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Messages</Accordion.Header>
        <Accordion.Body>
          <div >
            {messages.map((message, idx) => {
              const dispName = roomMembers[message.senderId]?.displayName
              const marker = roomMembers[message.senderId]?.role === "sl" ?  "🎓" : "🌱"
              const dispNameWithMarker = `${marker} ${dispName}`
              const ts = message.timestamp ? message.timestamp : message.timespamp;
                const time = new Date(ts)
                const hours = time.getHours().toString().padStart(2, '0')
                const minutes = time.getMinutes().toString().padStart(2, '0')
                const timeOnly = `${hours}:${minutes}`
              return (
                <div key={idx}>
                  <div className="mt-1" style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <span>
                      <span style={{fontWeight: "bold"}}>{dispNameWithMarker}</span>:
                    <MessageWithMarkdown message={message.text} />
                    </span>  <small style={{marginLeft: "auto", color: "lightgray", fontSize: "12px"}}>{timeOnly} </small>
                  </div>
                </div>
              )
            })}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const RoomMembers = ({members}) => {
  return (
    <div style={{width: "250px"}}>
      {members.map((member) => {
        const marker = member.role === "sl" ?  "🎓" : "🌱"
        // console.log(member.role, member.displayName, marker, member.presence)
        const ts = member.presence?.timestampMSv2
        const timestamp = ts ? new Date(member.presence.timestampMSv2).toLocaleTimeString() : "unknown"
        const icon = <> {marker} {member.displayName} (<small>{member.status}</small>) </>
        return (
          <div key={member.uid}>
            <CopyButton toCopy={member.uid} icon={icon} />
            <div>
            <small> {timestamp} </small>
            </div>

          </div>
        )
      })}
    </div>
  )
}

const TESTING_UIDS = [
    "ZpLvkKW72JgHrq9bJWeovNXLfsi1",// tj
    "34yo8FSi2AbsT5tEst9FbOBHxxH3", // ali
    "BkxKgaz6v3hir1QJ5bPdBW0DaYj1", // juliette
    "9fLSKXY6QXdMSRpl2osilNDoKV73", //
]

const NudgeStatistics = ({timeIntervalHours}) => {
    const courseId = useCourseId()
    const db = getFirestore(getApp())
    const nudgeRef = collection(db, "teachnow", courseId, "nudges")
    const nudgeQuery = query(nudgeRef, where("studentId", "not-in", TESTING_UIDS), orderBy("studentId"))
    // console.log(nudgeQuery)

    const [nudgesCollection, nudgesLoading, nudgesError] = useCollectionOnce(nudgeQuery)

    if(nudgesLoading) return <div>Loading...</div>

    const nudgesData = nudgesCollection.docs.map(doc => doc.data())
    // sort by timeJoinedMS, with latest joined first
    const nudgesDataSorted = nudgesData.sort((a, b) =>  b.timeJoinedMS - a.timeJoinedMS).map(
        (nudge) => {
            return {
                studentId: nudge.studentId,
                timeJoined: nudge.timeJoined,
                timeJoineMS: nudge.timeJoinedMS,
                studentResponse: nudge.studentStatus,
                rejectReasonInfo: nudge.rejectReasonInfo,
                nudgeId: nudge.nudgeId,
            }
        }
    ).filter((nudge) => nudge.studentResponse !== "nudged") // we weren't updating the status to "nudged" in the past, so we need to filter out the old ones and also current people being nudged

    // const [stat, setStat] = useState("rate_accepted")
    const nudgesDataGroupedTime = nudgesDataSorted.reduce((acc, nudge) => {
      const date = new Date(nudge.timeJoined)
      const intervalSizeHours = timeIntervalHours
      const hour = Math.floor(date.getHours() / intervalSizeHours) * intervalSizeHours
      const midHour = Math.floor(hour + intervalSizeHours / 2)
      const group = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${midHour}}`

      // const group = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, 0, 0, 0)
      // const group = `${date.getMonth() + 1}/${date.getDate()}`

      if (!acc[group]) {
          acc[group] = []
      }
      acc[group].push(nudge)
      return acc
    }, {})
    return (
        <div>
            <h1>Nudge Statistics</h1>
            <NudgeSummary nudgesDataSorted={nudgesDataSorted} />
            <div className="text-center">
              <NudgeGraphOverTime nudgesDataGroupedTime={nudgesDataGroupedTime} />
              <NudgeRejectReasonOverTime nudgesDataGroupedTime={nudgesDataGroupedTime} />
            </div>

        </div>
    )
}

const padNumber = (num) => {
    return `${num}`.length === 1 ? `0${num}` : `${num}`
}

const NudgeRejectReasonOverTime = ({nudgesDataGroupedTime}) => {
    // group by 6 hour intervals, and plot average acceptance rate, and num nudges per day

    const dailyStats = Object.entries(nudgesDataGroupedTime).map(([key, nudges]) => {
      const rejectNudges = nudges.filter((nudge) => nudge.studentResponse === "rejected")
      const [year, month, day, hour] = key.split("/").map((num) => parseInt(num))
      const date = new Date(year, month - 1, day, hour, 0, 0, 0)
      const numRejectNudges = rejectNudges.length;

      if (numRejectNudges === 0) {
          return {
              day: date,
              numNudges: 0
          }
      }
      const rejectReasons = rejectNudges.reduce((acc, nudge) => {
          const rejectReason = nudge.rejectReasonInfo?.rejectReasonWhyOption || "unknown"
          if (!acc[rejectReason]) {
              acc[rejectReason] = 0
          }
          acc[rejectReason] += 1/numRejectNudges
          return acc
      } , {})

      return {
          day: date,
          numNudges: numRejectNudges,
          ...rejectReasons
      }
    }).filter((dailyStat) => dailyStat.numNudges > 1)

    const dailyStatsSorted = dailyStats.sort((a, b) => a.day < b.day ? -1 : 1)
    // const allStats = Object.keys(dailyStatsSorted[0]).filter((key) => key !== "day" && key !== "numNudges")

    const allStats = Object.keys(dailyStatsSorted.reduce((acc, dailyStat) => {
      Object.keys(dailyStat).forEach((key) => {
        acc[key] = 0
      })
      return acc
    }, {})).filter((key) => key !== "day" && key !== "numNudges")
    return (
        <div>
            <Plot
              data={[
                ...allStats.map((stat) => {
                    return {
                        x: dailyStatsSorted.map((dailyStat) => dailyStat.day),
                        y: dailyStatsSorted.map((dailyStat) => (dailyStat[stat] || 0)),
                        type: 'scatter',
                        mode: 'lines+markers',
                        // marker: {color: 'black'},
                        name: stat,
                        error_y: {
                          type: 'data',
                          array: dailyStatsSorted.map((dailyStat) => Math.sqrt(dailyStat[stat] * (1 - dailyStat[stat]) / dailyStat.numNudges)),
                          visible: true,
                          ...ERRORBAR_OPTIONS,
                        },
                    }

                })
              ]}
              layout={{
                width: 800,
                height: 600, title: 'Rejection reasons over time',
                legend: {
                  x: 1,
                  xanchor: 'left',
                  y: 1
                },
                yaxis: {tickformat: ',.0%'}}}
            />
        </div>
    )
}

const NudgeGraphOverTime = ({nudgesDataGroupedTime}) => {


  const dailyStats = Object.entries(nudgesDataGroupedTime).map(([key, nudges]) => {
      const [year, month, day, hour] = key.split("/").map((num) => parseInt(num))
      const date = new Date(year, month - 1, day, hour, 0, 0, 0)
      const numNudges = nudges.length;
      if (numNudges === 0) {
          return {
              day: date,
              numNudges: 0
          }
      }
      const nudgeRates = nudges.reduce((acc, nudge) => {
          const statkey = `rate_${nudge.studentResponse}`
          if (!acc[statkey]) {
              acc[statkey] = 0
          }
          acc[statkey] += 1/numNudges
          return acc
      }, {})

      return {
          day: date,
          numNudges: numNudges,
          ...nudgeRates
      }
    }).filter((dailyStat) => dailyStat.numNudges > 10)

    const dailyStatsSorted = dailyStats.sort((a, b) => a.day < b.day ? -1 : 1)
    console.log('~~~', dailyStatsSorted)
    const allStats = Object.keys(dailyStatsSorted.reduce((acc, dailyStat) => {
      Object.keys(dailyStat).forEach((key) => {
        acc[key] = 0
      })
      return acc
    }, {})).filter((key) => key !== "day" && key !== "numNudges")

    console.log(dailyStatsSorted.map((dailyStat) => dailyStat['numNudges']))
    return (
        <div>
            <Plot
              data={[
                ...allStats.map((stat) => {
                    return {
                        x: dailyStatsSorted.map((dailyStat) => dailyStat.day),
                        y: dailyStatsSorted.map((dailyStat) => (dailyStat[stat] || 0)),
                        type: 'scatter',
                        mode: 'lines+markers',
                        // marker: {color: 'black'},
                        name: stat.replace("rate_", ""),
                        // marker size
                        marker: {
                          // size: 10,
                          zorder: 10,
                        },
                        line: {
                          // width: 2,
                          zorder: 5,
                        },
                        error_y: {
                          type: 'data',
                          array: dailyStatsSorted.map((dailyStat) => Math.sqrt(dailyStat[stat] * (1 - dailyStat[stat]) / dailyStat.numNudges)),
                          visible: true,
                          ...ERRORBAR_OPTIONS
                        },
                    }
                })
              ]}
              layout={{
                width: 800,
                height: 600,
                title: 'Nudge rates over time',
                legend: {
                  x: 1,
                  xanchor: 'left',
                  y: 1
                },
                yaxis: {tickformat: ',.0%'}}}
            />
        </div>
    )

}


const NudgeSummary = ({nudgesDataSorted}) => {
    // group by studentResponse
    const nudgesDataGrouped = nudgesDataSorted.reduce((acc, nudge) => {
      const studentResponse = nudge.studentResponse
      if (!acc[studentResponse]) {
          acc[studentResponse] = []
      }
      acc[studentResponse].push(nudge)
      return acc
  }, {})

  const responsePercentages = Object.keys(nudgesDataGrouped).reduce((acc, key) => {
      acc[key] = (nudgesDataGrouped[key].length / nudgesDataSorted.length) * 100
      return acc
  }, {})

    return (
        <div>
            <h3>Overall summary</h3>
            <ul>
                {Object.keys(nudgesDataGrouped).map((key) => {
                    return (
                      <li key={key}>
                          {key}: {responsePercentages[key].toFixed(2)}%
                      </li>
                    )
                })}
            </ul>
        </div>
    )
}

const TeachNowSessions = ({timeIntervalHours}) => {
    const courseId = useCourseId()
    const db = getFirestore(getApp())
    const ticketsRef = collection(db, "teachnow", courseId, "tickets")
    const ticketsQuery = query(ticketsRef, orderBy("timeCreatedServer"))
    // console.log(nudgeQuery)

    const [ticketsCollection, ticketsLoading, ticketsError] = useCollectionOnce(ticketsQuery)

    if(ticketsLoading) return <div>Loading...</div>

    // TODO: this filters me out even though I helped students, but its ok
    const ticketsData = ticketsCollection.docs.map(doc => doc.data()).filter((ticket) => !TESTING_UIDS.includes(ticket.teacherId))

    const matchedTickets = ticketsData.filter((ticket) => ticket.matched)
    // tickets which teachers gave up on
    const leftTickets = ticketsData.filter((ticket) => ticket.teacherStatus === "left")

    const numMatchedTickets = matchedTickets.length
    const numStudentsMatched = new Set(matchedTickets.map((ticket) => ticket.matchedInfo.studentId)).size // unique students
    const numTeachersUsed = new Set(matchedTickets.map((ticket) => ticket.teacherId)).size // unique teachers

    return (
        <div>
            <h3>TeachNow Sessions</h3>
            <ul>
                <li># of TeachNow sessions: {numMatchedTickets}</li>
                <li># unique students helped: {numStudentsMatched}</li>
                <li># teachers participated: {numTeachersUsed}</li>
            </ul>
            <div className="text-center">
              < TicketsOverTime ticketsData={ticketsData} timeIntervalHours={timeIntervalHours} />
            </div>
        </div>
    )
}

const TicketsOverTime = ({ticketsData, timeIntervalHours}) => {
    const [stat, setStat] = useState("numMatched")



    const ticketsGroupedByTime = ticketsData.reduce((acc, ticket) => {
      const date = ticket.timeCreatedServer.toDate()
      const intervalSizeHours = timeIntervalHours
      const hour = Math.floor(date.getHours() / intervalSizeHours) * intervalSizeHours
      const midHour = Math.floor(hour + intervalSizeHours / 2)
      const group = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${midHour}}`
      console.log('===============.', group, timeIntervalHours)

      if (!acc[group]) {
          acc[group] = []
      }
      acc[group].push(ticket)
      return acc
    }, {})


    const dailyStats = Object.entries(ticketsGroupedByTime).map(([key, tickets]) => {
        const [year, month, day, hour] = key.split("/").map((num) => parseInt(num))
        const date = new Date(year, month - 1, day, hour, 0, 0, 0)
        const numTickets = tickets.length;
        if (numTickets === 0) {
            return {
                day: date,
                numTickets: 0
            }
        }
        const ticketStats = tickets.reduce((acc, ticket) => {
            const statkey = 'numMatched'
            if (acc[statkey] === undefined) {
                acc[statkey] = 0
            }
            if (ticket.matched) {
                acc[statkey] += 1
            }

            let [timeStart, timeEnd, statkey2] = [null, null, null]
            if(ticket.teacherStatus === "joined" && ticket.matchedInfo !== undefined) {
                timeEnd = ticket.matchedInfo.timeMatchedServer.toDate()
                timeStart = ticket.timeCreatedServer.toDate()
                statkey2 = `waitTimeToMatchMins`
            } else if (ticket.teacherStatus === "left") {
                timeEnd = ticket.timeLeftServer.toDate()
                timeStart = ticket.timeCreatedServer.toDate()
                statkey2 = `waitTimeToGiveupMins`
            } else {
              console.error("unknown teacher status", ticket.teacherStatus, ticket)
              return acc
            }

            const timeDiff = timeEnd - timeStart
            const timeDiffMin = timeDiff / 1000 / 60

            if (acc[statkey2] === undefined) {
                acc[statkey2] = []
            }
            acc[statkey2].push(timeDiffMin)

            if (acc['timeInQueueMins'] === undefined) {
              acc['timeInQueueMins'] = []
            }
            acc['timeInQueueMins'].push(timeDiffMin)

            return acc
        }, {})

        // if (ticketStats.waitTimeToMatchMins !== undefined) {
        //     ticketStats.waitTimeToMatchMins = mean(ticketStats.waitTimeToMatchMins)
        // }
        // if (ticketStats.waitTimeToGiveupMins !== undefined) {
        //   ticketStats.waitTimeToGiveupMins = ticketStats.waitTimeToGiveupMins.reduce((acc, time) => acc + time, 0) / ticketStats.waitTimeToGiveupMins.length
        // }

        return {
            day: date,
            numTickets: numTickets,
            ...ticketStats
        }
    }).filter((dailyStat) => dailyStat.numTickets > 3)

    const dailyStatsSorted = dailyStats.sort((a, b) => a.day > b.day ? -1 : 1)
    const allStats = Object.keys(dailyStatsSorted.reduce((acc, dailyStat) => {
      Object.keys(dailyStat).forEach((key) => {
        acc[key] = 0
      })
      return acc
    }, {})).filter((key) => key !== "day")

    // console.log('allStats', dailyStatsSorted)
    return (
        <div>
            <select value={stat} onChange={(e) => setStat(e.target.value)}>
                {allStats.map((stat) => {
                    return <option key={stat} value={stat}>{stat}</option>
                })}
            </select>
            <Plot
              data={[{
                        x: dailyStatsSorted.map((dailyStat) => dailyStat.day),
                        y: dailyStatsSorted.map((dailyStat) => {
                          if (dailyStat[stat] === undefined) {
                            return 0
                          }
                          const val = dailyStat[stat]
                          // if list then compute mean
                          if (Array.isArray(val)) {
                            return mean(val)
                          } else {
                            return val
                          }
                        }),
                        type: 'scatter',
                        mode: 'lines+markers',
                        // marker: {color: 'black'},
                        name: stat,
                        error_y: {
                          type: 'data',
                          array: dailyStatsSorted.map((dailyStat) => {
                            if (dailyStat[stat] === undefined) {
                              return 0
                            }
                            const val = dailyStat[stat]
                            // if list then compute variance
                            if (Array.isArray(val)) {
                              const vv = variance(val)
                              //@ts-ignore
                              return Math.sqrt(vv / val.length)
                            } else {
                              return 0
                            }
                          }),
                          visible: Array.isArray(dailyStatsSorted[0][stat]),
                          ...ERRORBAR_OPTIONS
                        },
                    }]
                }
              layout={{
                width: 800,
                height: 600,
                title: `${stat}`
              }}
            />
        </div>
    )
}

export default TeachNowDashboard
import { Badge, Dropdown, Form} from "react-bootstrap";
import Stack from 'react-bootstrap/Stack';
import { FaMinus } from "react-icons/fa";


export const MultiSelectDropdown= ({
  selectableTags,
    selectedData,
    setSelectedData,
    style,
    placeholder
}) => {
    const selectedValues = selectedData.map((item)=>item.value)

    const handleCheckboxChange = (option) => {
      setSelectedData((prevSelectedOptions) => {
        const prevValues = prevSelectedOptions.map((item)=>item.value)
        
        if (prevValues.includes(option.value)) {
            return prevSelectedOptions.filter(
              (item) => item.value != option.value
            );
        } else {
            return [...prevSelectedOptions, option];
        }
      });
    };

    const handleClose = (option, e) => {
      e.stopPropagation()
      setSelectedData((prevSelectedOptions) => {
        return prevSelectedOptions.filter(
          (item) => item.value != option.value
        );
      })
    }

    const Tag = ({option}) => {
      return (
        <Badge 
        className="justify-content-center"
        pill
        >
          {option.label}
          <button
            onClick={(e)=>handleClose(option,e)}
            aria-label={"Remove " + option.label}
            className="border-0 bg-transparent ml-1 text-light"
          >
            <FaMinus 
            size="12px"
            />
          </button>
        </Badge>        
  
      )
    }
    return (
        <Dropdown>

          <Dropdown.Toggle 
          variant="light" 
          className="btn bg-white border d-flex align-items-center justify-content-between overflow-auto" 
          style = {style}
          id="dropdown-split-basic">
            <Stack direction="horizontal" gap={1}>
            {selectedData.length == 0 ? placeholder :
            selectedData.map((option)=>
            <Tag option={option} />)
            }
            </Stack>
            
          </Dropdown.Toggle>
          
              
          <Dropdown.Menu className="px-2" style = {style}>
              {selectableTags.map((option) => (
                <Form.Check
                  key={option.value}
                  type="checkbox"
                  label={option.label}
                  id={option.value}
                  checked={selectedValues.includes(option.value)}
                  onChange={() => handleCheckboxChange(option)}
                />
              ))}
          </Dropdown.Menu>
        </Dropdown>
    );
  };
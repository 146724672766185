import { useState, useEffect } from "react"
import { query, orderBy, onSnapshot, where, limit } from "firebase/firestore"
import { NOTIFICATION_CONVERTER } from "./Types"
import { NotificationDataFetcher } from "./Types"
import { useUserId } from "hooks/user/useUserId"
import { useCourseId } from "hooks/router/useUrlParams"


export const useNotifications = () => {
    const [notifications, setNotifications] = useState([])
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const notificationDataFetcher = useNotificationDataFetcher()
    const courseId = useCourseId()


    const notificationQuery = query(
        notificationDataFetcher.getNotificationsPath(),
        where("courseId", "==", courseId),
        orderBy("timestamp", "desc"),
        limit(20)
    );


    useEffect(() => {
        const snap = onSnapshot(notificationQuery, (snapshot) => {
            // Map notifications
            try {
                const notificationsData = snapshot.docs.map(doc => NOTIFICATION_CONVERTER.fromFirestore(doc))
                setNotifications(notificationsData)
                // count unread notifications
                const unreadNotifications = notificationsData.filter(notification => !notification.acknowledged).length
                setUnreadNotifications(unreadNotifications)
            } catch (error) {
                console.error("Error fetching notifications", error)
            }
        })

        return () => snap() // unsubscribe on unmount

    }, [])




    return {
        notifications,
        unreadNotifications,
    }
}




export const useNotificationDataFetcher = () => {
    const userId = useUserId()

    return new NotificationDataFetcher(userId);
}
//import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Route, Routes } from 'react-router-dom';
import { LessonEditor } from './LessonEditor';
import { LessonEditorSplash } from './LessonEditorSplash';
import { LessonItemsEditor } from './editors/LessonItemsEditor';

export const LessonEditorContainer = () => {
  return (
    <Routes>
      <Route index element={
        <div style={{ width: "100%", height: "100vh", overflowY: "visible" }}>
          <LessonEditorSplash />
        </div>
      } />
      <Route path="/:lessonId/" element={
        <LessonItemsEditor />
      } />
      <Route path="/:lessonId/:docId" element={
        <LessonEditor />
      } />
    </Routes>
  )
}
import {
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import React from "react";
import "firebase/compat/auth";
import { useUserId } from "hooks/user/useUserId";
import {
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams";



interface IExpContext {
  expLoading: boolean;
  expData: any | undefined; // but really {}
}

const defaultData = {
  expLoading: true,
  expData: undefined,
};

export const ExperimentContext = React.createContext<IExpContext>(defaultData);

export const ExperimentProvider = ({ children }) => {
  const db = getFirestore();
  const userId = useUserId();
  const courseId = useCourseId();

  const path = `users/${userId}/${courseId}/experimentFlags`;
  const expRef = doc(db, path);

  const [expDataRaw, expLoading, expError] = useDocumentData(expRef);


  const expData = expDataRaw;

  if (expError) {
    console.error(expError);
  }

  return (
    <ExperimentContext.Provider
      value={{
        expData,
        expLoading,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};

export async function setExperimentFlag(userId, courseId, experimentKey, value){
  const path = `users/${userId}/${courseId}/experimentFlags`;
  const docRef = doc(getFirestore(), path)
  const data = { [experimentKey]: value }
  setDoc(docRef, data, { merge: true })
}
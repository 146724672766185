// for the cursors of collaborative users
const USER_COLORS = [
    "#958DF1",
    "#F98181",
    "#FBBC88",
    "#FAF594",
    "#70CFF8",
    "#94FADB",
    "#B9F18D",
];
export const randomUserColor = () => {
    const index = Math.floor(Math.random() * USER_COLORS.length);
    return USER_COLORS[index];
};
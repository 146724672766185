
import { FaCamera } from "react-icons/fa";
import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import 'firebase/compat/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import {
  doc,
  updateDoc,
} from 'firebase/firestore';

import Swal from 'sweetalert2';
import { useFilePicker } from 'use-file-picker';

export const StoryPicEditor =(props) => {
    const {user, db, storyData, storyPath} = props
    const [isUploading, setIsUploading] = useState(false);

    let initialPhotoUrl = ""
    if(storyData?.photoUrl){
      initialPhotoUrl = storyData.photoUrl
    }

    const [localStoryPhotoUrl, setLocalStoryPhotoUrl] = useState(initialPhotoUrl)
    
    const [openFileSelector, { plainFiles, loading }] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        readFilesContent: false,
    });

    useEffect(() => {
        if (plainFiles.length > 0 && !isUploading) {
          const firstFile = plainFiles[0];
          setIsUploading(true);
          uploadImg(firstFile, user, db, storyPath, onUploadComplete, onUploadError);
        }
    }, [plainFiles]);
    
    const onPicClicked = () => {
        if (!isUploading) {
          openFileSelector();
        }
    };

    const onUploadError = () => {
        console.log('error')
        setIsUploading(false);
    }

    const onUploadComplete = (newUrl) => {
        console.log('onUploadComplete',newUrl)
        // in addition to writing it to the firebase, we 
        // write it locally
        setLocalStoryPhotoUrl(newUrl)
        setIsUploading(false);
    }

    return <div className="d-flex flex-column" style={{width:'100%'}}>
        <Card.Img variant="top" className="story-edit-img" src={localStoryPhotoUrl} key={localStoryPhotoUrl}></Card.Img>
        <button onClick={onPicClicked}className="btn btn-light"><FaCamera/> Change </button>
    </div>
}

const uploadImg = (file, user, db, storyPath, onUploadComplete, onError) => {
    const storage = getStorage();
    const storageRef = ref(storage, 'stories/' + user.uid);
    // const db = getFirestore()
    const uploadTask = uploadBytesResumable(storageRef, file);

    Swal.fire({
      title: `Uploading new image...`,
      toast: true,
      showClass: {
        popup: 'none',
      },
      showConfirmButton: false,
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = Math.min(99, progress);
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        onError(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

          updateDoc(doc(db, storyPath), {
            photoUrl: downloadURL
          }).then(() => {
            onUploadComplete(downloadURL);
            Swal.fire({
              title: `Success!`,
              toast: true,
              showClass: {
                popup: 'none',
              },
              icon: 'success',
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
            });
          });
        });
      },
    );
  };
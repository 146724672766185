import { Button, Spin } from "antd";
import { QuestionAndAnswer } from "./types";
import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import { FinalProjectContext } from "./FinalProjectContext";
import styled from "styled-components";
import { lightPurple } from "styles/colors";

export const FinalProjectConversation = (props: {
  questions: QuestionAndAnswer[];
  setQuestionsState: (questions: QuestionAndAnswer[]) => void;
  getNextQuestion: (questionsAndAnswers: QuestionAndAnswer[]) => void;
  markConversationComplete: () => void;
  getNextQuestionLoading: boolean;
  getProjectGuideLoading: boolean;
  dirtyBit: boolean;
  readonly: boolean;
}) => {
  const {
    questions,
    getNextQuestion,
    markConversationComplete,
    getNextQuestionLoading,
    getProjectGuideLoading,
    readonly,
    dirtyBit,
  } = props;

  // fancy states to help me know when to scroll to the answers...
  const [waitingForAnswers, setWaitingForAnswers] = useState(false);

  const { reflectRef, milestoneRef } = useContext(FinalProjectContext);

  const setAnswer = (index: number, answer: string) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = answer;
    props.setQuestionsState(newQuestions);
  }

  useEffect(() => {
    if(getProjectGuideLoading) {
      setWaitingForAnswers(true)
    }
  }, [getProjectGuideLoading])

  useEffect(() => {
    if(waitingForAnswers && milestoneRef.current) {
      setWaitingForAnswers(false)
      milestoneRef.current.scrollIntoView({ behavior: "smooth" });
    }
  },  [milestoneRef.current])


  if (!questions) {
    return <Spin />;
  }

  

  const isFull = questions.length >= 4 || readonly

  return (
    <div className="mt-4">
      <h3 ref={reflectRef}>Step 2: Reflect</h3>
      <p>
        Fantastic! That sounds like a well scoped project. We have some questions to help you think about your project idea. Take
        some time to think about them and write your answers below.
      </p>
      <p>
        Feel free to write as much or as little as you want, or to say if you're
        not sure about anything. The more accurate your answers are, the better
        we can help you come up with a plan.
      </p>
      <hr />
      {questions.length === 0 && <Spin />}
      {questions.map((question, i) => {
        return (
          <QuestionAnswer
            key={i}
            setAnswer = {(a) => setAnswer(i, a)}
            question={question}
            readonly={readonly}
          />
        );
      })}

      <ButtonRow>
        <Button
          onClick={(e) => {
            e.preventDefault();
            getNextQuestion(questions);
          }}
          type="primary"
          loading={getNextQuestionLoading}
          disabled={getProjectGuideLoading}
        >
          {isFull ? <>More Questions Please</> : <>Next Question</>}
        </Button>
        {(isFull) && (
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              markConversationComplete();
            }}
            loading={getProjectGuideLoading}
            disabled={!dirtyBit}
          >
            {readonly ? <>Save Answers and Regenerate</> : <>Create Milesones</>}
          </Button>
        )}
      </ButtonRow>

      {getProjectGuideLoading && <div className="alert mt-3" style={{backgroundColor:lightPurple}}>
        <b>Generating project guide</b>: this could take up to two minutes. Thanks for your patience! <Spin className="ml-2" />
        </div>
        }

    </div>
  );
};

const QuestionAnswer = (props: {
  question: QuestionAndAnswer;
  readonly: boolean;
  setAnswer: (a: string) => void;
}) => {
  const { question, readonly } = props;

  // if its not valid then we should not show it
  if (!question.question) {
    return <></>;
  }


  return <div style={{ marginBottom: "20px" }} key={question.question}>
    <p>{question.question}</p>
    <textarea
      onChange={(e) => {
        // console.log("trying to change...")
        // question.answer = e.target.value;
        props.setAnswer(e.target.value)
      }}
      value={question.answer}
      style={{
        width: "100%",
        height: "100px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        padding: "20px",
      }}
      disabled={
        false
      }
    />
  </div>
}

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  // if mobile, stack the buttons
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
      
`
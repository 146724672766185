import { FaCalendarCheck, FaCheck, FaTimes } from "react-icons/fa"
import { Button, ButtonGroup, Dropdown, DropdownButton, Spinner } from "react-bootstrap"
import { useParams } from "react-router"
import { CourseContext } from "contexts/CourseContext"
import { useContext, useEffect, useState } from "react"
import { useUserId } from "hooks/user/useUserId"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import {
  getLocalUTCTimezone,
  formateDateWithSuffix,
} from "components/timezones/timezoneHelper";
import { getApp } from "firebase/app"

export const RSVP = ({ timeIndex,section }) => {
  const minWidth = 248
  const userId = useUserId()
  const userTz = getLocalUTCTimezone()
  const { nCourseWeeks, firstSectionTimestamp, getNextSectionIndex, getNextSectionDate } = useContext(CourseContext)
  // figure out which week of the course we are on, 
  // in other words what section number (e.g. the second section -> section 2) is this week
  const [sectionNumber, setSectionNumber] = useState(-1)
  const [nextSectionDate, setNextSectionDate] = useState(null)
  useEffect(()=> {
    if (!firstSectionTimestamp ) return
    setSectionNumber(getNextSectionIndex(userTz, timeIndex) + 1)
    setNextSectionDate(getNextSectionDate(userTz, timeIndex))
  },[firstSectionTimestamp])
  const dateWithSuffix = formateDateWithSuffix(nextSectionDate)

  console.log('sectionNumber', sectionNumber)
  const sectionId = useParams().sectionId
  const path = `sections/${sectionId}/rsvp/${userId}`
  const db = getFirestore(getApp())
  const [rsvp, isLoading] = useDocumentData(doc(db, path))
  
  if(sectionNumber > nCourseWeeks) {
    return <NoMoreSections/>
  }

  // i need to know if you are the current section leader

  const isSectionLeader = section?.sectionLeader === userId
  function setRsvp(newValue) {
    setDoc(doc(db, path), {
      [sectionNumber]: newValue,
      isSectionLeader,
    }, { merge: true })
  }

  if (isLoading) {
    return <div className="mt-1">
      <Button variant="primary" disabled style={{ width: minWidth }}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button>
    </div>
  }

  let variant = 'primary'
  let title = <><FaCalendarCheck style={{ marginTop: '-5px' }} /> Can you attend {dateWithSuffix}?</>
  if (rsvp) {
    if (rsvp[sectionNumber] === true) {
      title = <><FaCheck style={{ marginTop: '-5px' }} /> I can attend {dateWithSuffix}</>
    } else if (rsvp[sectionNumber] === false) {
      title = <><FaTimes style={{ marginTop: '-5px' }} /> I can't attend {dateWithSuffix}</>
      variant = 'danger'
    }
  }

  return <div className="mt-1">
    <DropdownButton
      as={ButtonGroup}
      id={`dropdown-variants`}
      variant={variant}
      title={title}
      style={{ width: minWidth }}
    >
      <Dropdown.Item onClick={() => setRsvp(true)} eventKey="2"><FaCheck /> I can attend {dateWithSuffix}</Dropdown.Item>
      <Dropdown.Item onClick={() => setRsvp(false)} eventKey="3"><FaTimes /> I can't attend {dateWithSuffix}</Dropdown.Item>
    </DropdownButton>
  </div>
}

const NoMoreSections = () => {
  return null
}
import BootstrapTable from 'react-bootstrap-table-next';

export const EditSectionMembersTable = ({ membersData }) => {
    const columns = [
        {dataField: 'uid', text:'key', hidden:true},
        {dataField: 'uidCopy', text: 'UID', sort:false},
        {dataField: 'displayName', text:'Name'},
        {dataField: 'removeMember', text: 'Remove', sort:false, editable:false},
    ]

    return <BootstrapTable 
      data={membersData} 
      columns={columns} 
      bordered={false} 
      striped={true} 
      hover={true} 
      bootstrap4={true} 
      keyField='uid' 
    />
}
import { useContext, useEffect } from "react"
import { ProfileContext } from "contexts/ProfileContext"
import Gate from "contexts/Gate"
import { useNavigate } from "react-router"
import { useCourseId } from "hooks/router/useUrlParams"



export const HomeRedirect = () => {
  // redirect to highest role home
  const navigate = useNavigate()
  const courseId = useCourseId()
  const {userData} = useContext(ProfileContext)
  const isMentor = Gate.hasMentorRole(userData)
  const isTeachingTeam = Gate.hasSectionLeaderRole(userData)
  const isAdmin = Gate.hasAdminRole(userData)

  useEffect(() => {
    if (isAdmin) {
      // redirect to admin home
      navigate(`/${courseId}/adminhome`, { replace: true })
      return
    }
    if (isTeachingTeam) {
      // redirect to teaching home
      navigate(`/${courseId}/teachinghome`, {replace: true})
      return
    }
    if (isMentor) {
      // redirect to mentor home
      navigate(`/${courseId}/mentorhome`, {replace: true})
      return
    }
    // redirect to student home
    navigate(`/${courseId}/studenthome`, {replace: true})
    return
  }, [isTeachingTeam, isAdmin])
  return <></>
}


import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useState } from "react"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { FaCheck, FaInfoCircle } from "react-icons/fa"
import { Link, useNavigate, useParams } from "react-router-dom"
import SplitPane from "react-split-pane"
import { instructionURL } from "review/contexts/ReviewUtil"
import { useUploadedVideo } from "course/application/sectionLeaderApplication/teachingDemo/useUploadedVideo"
import Swal from "sweetalert2"
import { GradingDropdown } from "./GradingPane"
import { ApplicationPane } from "./ReviewApplicant"

const calibrationMap = {
  1: {
    userId:'4mhGkuvaxnUG16zBRJssMqUlZbu2',
    overall: 'Some Handholding',
    python:'Strong',
    teaching:'Weak',
    atmosphere:'Medium',
    explanation:"Their Python ability seems very high. Atmosphere was solid. Their teaching could use a little work. They should have motivated the mars weight problem and they didn't interpret the debugging question correctly.\n\n These concerns make the candidate between Some handholding and Heavy handholding. We went with Some Handholding because we believe that teacher training will help them be a great teacher."
  },
  2: {
    userId:'5lkE0qoQZKgGGFljG8XgFCHqnuD2',
    overall: 'Heavy Handholding',
    python:'Weak',
    teaching:'Weak',
    atmosphere:'Medium',
    explanation:"Python ability is sufficiently strong, but teaching ability is weak (applicant simply comes with solution code and prepared definitions of variables, datatypes and reads them off / explains them from top to bottom) and atmosphere is impersonal -- immediately jumps into reading off of the material they have prepared and seems to be intent on covering all the content quickly, which may discourage a student from participating since a. Overall we would place this applicant in the Heavy Handholding category."
  },
  3: {
    userId:'kOobe50T7kMtgGmeTBHHfewuzHx1',
    overall: 'Strong Accept',
    python:'Strong',
    teaching:'Strong',
    atmosphere:'Strong',
    explanation:"An awesome returning Code in Place student. Solid atmosphere, strong teaching, and strong Python ability."
  },
  4: {
    userId:'0HioUqDMQMVgpb4YgSBZ8kY1GnB2',
    overall: 'Do Not Hire',
    python:'Medium',
    teaching:'Weak',
    atmosphere:'Medium',
    explanation:"They might be a fine teacher, but its a big red flag that they didn't teach the right topic."
  }
}

export const Calibration = ({}) => {

  const calibrationId = useParams().calibrationId
  const userId = calibrationMap[calibrationId].userId
  const courseId = useCourseId()

  const db = getFirestore();
  // calibration apps are from cip3
  const appDocRef = doc(db, `users/${userId}/cip3/sectionLeaderApplication`)

  const [appData, appDataLoading, appDataError] = useDocumentDataOnce(appDocRef);
  const [videoUrl, videoLoading, videoError] = useUploadedVideo(userId)

  if (appDataLoading || videoLoading) {
    return <></>
  }

  const props = {appData, videoUrl}

  return <SplitPane
    size={400}
    minSize={400}
    primary="second"
  >
    <ApplicationPane {...props} />
    <CalibrationPane/>
  </SplitPane>
}

const CalibrationPane = ({}) => {
  const graderId = useUserId()
  const courseId = useCourseId()
  const navigate = useNavigate()
  const [ratings, setRatings] = useState({
    overall: null,
    python: null,
    teaching: null,
    atmosphere: null
  })
  const complete = false
  const calibrationId = useParams().calibrationId

  const checkCalibrated = async () => {
    const groundTruth = calibrationMap[calibrationId]
    let calibrated = true
    for (const field in ratings) {
      if(field != 'overall') continue
      if (ratings[field] !== groundTruth[field]) {
        calibrated = false
      }
    }
    if(calibrated) {
      const db = getFirestore();
      const calPath = `/course/${courseId}/slReview/graders/calibration/${graderId}`
      const calibrationDocRef = doc(db, calPath)
      await setDoc(calibrationDocRef, {[calibrationId]: true}, {merge: true})
      // redirect to the home page
      const homeURL = `/${courseId}/review`
      Swal.fire({
        title: 'Calibrated!',
        text: groundTruth.explanation,
        icon: 'success',
        confirmButtonText: 'Onwards',
      }).then(() => {
        navigate(homeURL)
      })
    } else {
      Swal.fire({
        title: 'Not Calibrated',
        text: groundTruth.explanation,
        icon: 'error',
        confirmButtonText: 'Try Again'
      })
    }
  }

  const saveField = (field, value) => {
    setRatings((prev) => ({...prev, [field]: value}))
  }

  return <div className="p-3">
    <h3>Review {complete && <FaCheck className="text-success"/>}</h3>
    <a href={instructionURL} target="_blank"><FaInfoCircle/> Instructions for applicant review</a>
    <hr/>
    <b>Overall Evaluation:</b>
    <GradingDropdown 
      variant="primary"
      selectedOption={ratings?.overall}
      options={['Strong Accept', 'Some Handholding', 'Heavy Handholding', 'Do Not Hire']}
      onUpdate={(option) => saveField('overall', option)}
    />
    <br/>

    <b>Python Ability:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.python}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('python',option)}
    />
    <br/>

    <b>Teaching Ability:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.teaching}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('teaching',option)}
    />
    <br/>

    <b>Atmosphere Ability:</b>
    <GradingDropdown 
      variant="secondary"
      selectedOption={ratings?.atmosphere}
      options={['Strong', 'Medium', 'Weak']}
      onUpdate={(option) => saveField('atmosphere',option)}
    />
    <br/>
    <hr/>
    <button onClick={checkCalibrated} className="btn btn-primary">Check if I am Calibrated</button><br/>
    <Link to={`/${courseId}/review`} className="btn btn-light mt-2">Review Home</Link></div>
}
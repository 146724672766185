import React, { useState, useContext } from 'react';
import { LessonsContext } from 'course/contexts/LessonsContext'; 
import { TrainingContext } from 'course/contexts/TrainingContext'
import { useParams } from "react-router-dom";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { EditableImage } from 'components/editableResource/EditableImage';
import { WorldEditor } from 'components/pyodide/KarelLib/WorldEditor';
import { getDefaultWorldState } from 'components/pyodide/KarelLib/util';
import { Dropdown, Form } from 'react-bootstrap';
import { Editor } from '@monaco-editor/react';
import { EditableList } from './EditableList';
import { EditableLinkList } from './EditableLinkList';
import { EditablePDF } from 'components/editableResource/EditablePDF';
import { lessonSchemas } from 'components/duolessons/server/LessonServerHelper';
import { EditableMap } from './EditableMap';

export const SlideEditor = ({ slideData, lessonId, slideId, schema }) => {
  const {editorType, courseId} = useParams()
  const [currSlideData, setCurrSlideData] = useState(slideData);

  const [dirtyBit, setDirtyBit] = useState(false);

  const onValueChange = (key, newValue) => {
    setCurrSlideData({ ...currSlideData, [key]: newValue })
    setDirtyBit(true)
  }

  const saveData = () => {
    const db = getFirestore();
    const documentPath = `/${editorType == 'student' ? 'lessons' : 'training'}/${courseId}/lessonsList/${lessonId}`;
    setDoc(doc(db, documentPath), {
      slidesInfo: {
        [slideId]: currSlideData
      }
    }, { merge: true });
    setDirtyBit(false);
  }


  let buttonTxt = dirtyBit ? 'Save' : 'Saved'

  return (
    <div>
      <div className="courseTitle" style=
        {{
          fontWeight: 400,
          paddingBottom: "8px",
          marginBottom: "16px",
        }}
      >
        Lesson Slide Editor
        <br/>
        <button
        disabled={!dirtyBit}
        onClick={() => saveData()}
        style={{ width: 100 }}
        className="mt-2 btn btn-primary">{buttonTxt}
      </button>

      </div>

     

      {
        schema && schema.map((item) => {
          const currValue = currSlideData[item.key]
          return <div key={item.key} className='mt-4'>
            <h4>{item.label}</h4>
            <ItemEditor
              currValue={currValue}
              item={item}
              setItemValue={(e) => onValueChange(item.key, e)}
            />
          </div>
        })
      }

      

      <div style={{ height: "40px" }}></div>

    </div>
  )


}


const ItemEditor = ({ item, currValue, setItemValue }) => {
  const { lessonId, docId, editorType, courseId } = useParams();
  const { lessonsMap } = useContext(editorType == 'student' ? LessonsContext : TrainingContext);

  if (item.type === 'string') {
    return <input
      type="text"
      className="form-control"
      style={{ marginBottom: 20 }}
      value={currValue}
      placeholder='Enter a value'
      onChange={(e) => { setItemValue(e.target.value) }}
    />
  }
  if(item.type === 'number') {
    return <input
      type="number"
      className="form-control"
      placeholder='Enter a number'
      style={{ marginBottom: 20 }}
      value={currValue}
      onChange={(e) => { setItemValue(e.target.value) }}
    />
  }
  if(item.type === 'img') {
    const imageStoragePath = `${editorType == 'student' ? 'lessons' : 'training'}/${courseId}/${lessonId}/slides/${docId}/${item.key}`
    
    console.log('storagePath', imageStoragePath)
    
    return <>
      <OtherValuesDropdown 
        itemKey={item.key}
        itemType={item.type}
        setItemValue={setItemValue}
      />
      <EditableImage
        serverUrl={currValue}
        setServerUrl={setItemValue}
        imageStoragePath={imageStoragePath}
        editable={true}
        width={250}
        height={250}
      />
    </>
  }
  if(item.type === 'pdf') {
    const storagePath = `${editorType == 'student' ? 'lessons' : 'training'}/${courseId}/${lessonId}/slides/${docId}/${item.key}`
    
    
    return <>
      <OtherValuesDropdown 
        itemKey={item.key}
        itemType={item.type}
        setItemValue={setItemValue}
      />
      <EditablePDF
        serverUrl={currValue}
        setServerUrl={setItemValue}
        pdfStoragePath={storagePath}
        editable={true}
      />
    </>
  }
  if(item.type === 'boolean') {
    return <Form.Check
      type="switch"
      checked={currValue}
      onChange={(e) => { setItemValue(e.target.checked) }}
    />
  }
  if(item.type === 'karelworld') {
    if(!currValue) {
      return <button className='btn btn-secondary' onClick={() => {
        setItemValue(getDefaultWorldState(2,2))}}>Create world</button>
    }
    return <WorldEditor
      worldState={currValue}
      setWorldState={(updateFn) => {
        setItemValue(updateFn(currValue))
      }}
    />
  }
  if(item.type === 'code') {
    return <CodeEditor
      currValue={currValue}
      setItemValue={setItemValue}
    />
  }
  if(item.type === 'map') {
    if(!currValue) {
      return <button className='btn btn-secondary' onClick={() => {
        setItemValue({})}}>Create map</button>
    }
    return <EditableMap
      currValue={currValue}
      setItemValue={setItemValue}
    />
  }
  if(item.type === 'list') {
    if(!currValue) {
      return <button className='btn btn-secondary' onClick={() => {
        setItemValue([])}}>Create list</button>
    }
    return <EditableList
      currValue={currValue}
      setItemValue={setItemValue}
    />
  }
  if(item.type === 'url-list') {
    if(!currValue) {
      return <button className='btn btn-secondary' onClick={() => {
        setItemValue([])}}>Create list</button>
    }
    return <EditableLinkList
      currValue={currValue}
      setItemValue={setItemValue}
    />
  }
}



const CodeEditor = ({ currValue, setItemValue }) => {
  return <Editor
  width={"100%"}
  height={200}
  defaultValue={currValue}
  onChange={(e) => {setItemValue(e)}}
  defaultLanguage={"python"}
  options={{
    readOnly: false,
    fontSize: 14,
    padding: "0",
    scrollBeyondLastColomn: false,
    scrollBeyondLastLine: false,
    scrollbar: {
      vertical: 'hidden',
      horizontal: 'hidden',
    },
    minimap: {
      enabled: false
    },
  }}

  onMount={()=>{}}
/>
}

const OtherValuesDropdown = ({ itemKey, itemType, setItemValue }) => {
  const { lessonId, docId, editorType } = useParams();
  const { lessonsMap } = useContext(editorType == 'student' ? LessonsContext : TrainingContext);
  const slideId = docId
  const lessonData = lessonsMap[lessonId]
  const otherValues = getValueOnOtherSlides(lessonData, slideId, itemKey, itemType)
  if(otherValues.length === 0) {
    return <></>
  }
  return <Dropdown>
    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
      Use other value
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {otherValues.map((value, idx) => {
        return <Dropdown.Item
          key={idx}
          onClick={() => setItemValue(value)}
        >{value}</Dropdown.Item>
      })}
    </Dropdown.Menu>
  </Dropdown>
}

const getValueOnOtherSlides = (lessonData, currSlideId, key, itemType) => {
  const otherValues = new Set();

  // slidesInfo is a dictionary of slideId: slideData
  const slidesInfo = lessonData?.slidesInfo
  for (let slideId in slidesInfo) {
    if(slideId === currSlideId) {
      continue
    }
    const slide = slidesInfo[slideId]
    
    // check for a matching key
    if(slide[key]) {
      otherValues.add(slide[key])
    } 

    // next we are going to look for matching types
    const slideType = slide?.type
    const schema = lessonSchemas[slideType]
    for(let item of schema) {
      if(item.type === itemType && slide[item.key]) {
        otherValues.add(slide[item.key])
      }
    }
  }

  
  

  // Convert the Set back to an array before returning
  return Array.from(otherValues)

}
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { TextAreaInput } from "components/Forms/FormixComponents";

const OnboardForm = (props) => {

  
  const { accommodations, onSubmit } = props;

  const initialValues = { accommodations };

  const validationSchema = Yup.object().shape({
    accommodations: Yup.string(),
  });

  return (
    <div>
      <h5>Section Accommodations</h5>
      <p>
        Sections will involve a 50 minute video call with other students and a section leader. 
        If you would like to highlight a need you have that would otherwise <b>consistently prevent</b> you from being able to learn, or engage with section, please let us know here.
      </p>
      <p>
        Example needs include: I am blind and I need a way to learn without visuals, I am deaf and use American Sign Language as my primary language of communication.
      </p>

      <p>Accommodations do not include scheduling conflicts.</p>


    
    <div>
            <p>If you do have accommodations, please describe them here:</p>


      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(accommodations) => onSubmit(accommodations)}
      >
        {({ values }) => (
          <Form style={{ width: "100%" }}>
            
            <TextAreaInput
              name="accommodations"
              placeholder="If you do need special accommodations tell us about them here"
              type="text"
            />
            <Button onClick={()=>{onSubmit({"accommodations":""})}}variant="primary" className="mr-1">
              I don't need any special accommodations
            </Button>
            <Button variant="secondary" type="submit">
              Save accomodations
            </Button>
          </Form>
        )}
      </Formik>
      </div>
      
    </div>
  );
};

export default OnboardForm;

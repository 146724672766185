import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

/**
 * Edit button for overall feedback and inline comments
 */
export const EditButton = (props) => {
  return (
    <OverlayTrigger
      rootClose placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Edit
        </Tooltip>
      )}
    >
      <span>
        <FaEdit onClick={props.onClick} className="action-button" />{" "}
      </span>
    </OverlayTrigger>
  );
};


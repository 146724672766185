import { Button } from "antd";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { useCourseId } from "hooks/router/useUrlParams";
import {
  FaLaptop,
  FaLaptopCode,
  FaPaintBrush,
  FaPlus,
  FaTerminal,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Option = styled.div`
  padding: 10px;
  width: 80%;
  text-align: center;
`;

const UseCodeInPlaceIDE = () => {
  const courseId = useCourseId();
  return (
    <Option>
      <b>Use the Code in Place IDE</b>
      <br />{" "}
      <Link
        className="btn btn-primary"
        type="primary"
        to={`/${courseId}/create`}
      >
        Create my project in Code in Place
      </Link>
    </Option>
  );
};

const UseVSCode = () => {
  const courseId = useCourseId();
  return (
    <Option>
      <b>Download and learn to use VS Code</b>
      <br />

      <Link
        className="btn btn-primary"
        type="primary"
        to={`/${courseId}/learn/setting-up-environment/intro-to-editors`}
        target="_blank"
      >
        Learn More about VS Code
      </Link>
    </Option>
  );
};

export const GetStarted = (props: {}) => {
  const courseId = useCourseId();
  return (
    <div className="mt-4">
      <h3>Get Started!</h3>
      <p>
        Now that you have a plan for your project, it's time to jump right in:
      </p>
      <IdeChoice>
        <UseCodeInPlaceIDE />
        <UseVSCode />
      </IdeChoice>
      <div style={{ height: "20px" }} />
      <AdminRichTextEditor
        firebaseDocPath={`course/${courseId}/handouts/finalProjectProposalEnd`}
      />
    </div>
  );
};

const IdeChoice = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
`;

import { LandingNav } from "landing/components/LandingNav"
import { Takeaways } from "./Takeaways";
import { LearnQuotes } from "./LearnQuotes";
import { FAQ } from "./FAQ";
import { useNavigate, useParams } from "react-router-dom";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Coverage, Qualifications, Responsibilities } from "./SimpleOverview";

export const ExploreCourse = () => {
    return <div>
        <LandingNav applicationType={"student"} subpage={"About the course"}/>
        
        <Details />
    </div>
}

const Details = () => {

    return (
     <div>
        <Takeaways />
        <LearnQuotes/>
      
        
        <div className="d-flex flex-row justify-content-center w-100">
          <div style={{padding:'10px'}}>
            <div style={{maxWidth:'700px'}}>
            <Responsibilities />
            <div style={{height:'20px'}}/>
            <Coverage/>
            <div style={{height:'20px'}}/>
            <Qualifications/>
            
            <div style={{height:'20px'}}/>
            <FAQ/>
            <div style={{height:'20px'}}/>
            <AgreeButton/>
            </div>
          </div>

        </div>
      </div>
    );
  }

const AgreeButton = () => {
  const navigate = useNavigate();
  const userId = useUserId()
  const {targetCourseId} = useParams()
  const firebasePath = `users/${userId}/${targetCourseId}/studentApplication`
  const db = getFirestore();
  const docRef = doc(db, firebasePath);

  async function onClick() {
    await setDoc(docRef, {hasReadAboutCourse: true}, { merge: true });
    navigate(`/public/join/${targetCourseId}`)
  }
  return <div className="d-flex justify-content-center">
      <button onClick={onClick} className="btn btn-lg btn-primary">Got it!</button>
  </div>
}
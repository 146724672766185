import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import { FaPen, FaRocket, FaVideo, FaPython } from "react-icons/fa";



import { LandingNav } from "../../../../landing/components/LandingNav";
import { useWindowSize } from "react-use-size";
import { TodoCard } from "../../studentApplication/splash/StudentApplication";
import { useUploadedVideo } from "../teachingDemo/useUploadedVideo";
import Loading from "react-loading";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useUserId } from "hooks/user/useUserId";
import {
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { ErrorPage } from "components/errors/ErrorPage";
import {
  ApplicationStatus,
  computeApplicationStatus,
} from "./ApplicationStatus";
import { Role } from "types/role";
import { getApp } from "@firebase/app";
import { CompletionContext } from "contexts/CompletionContext";
import { useAppStatusServerRecorder } from "hooks/application/useAppStatusServerRecorder";
import { EnrollmentContext } from "contexts/EnrollmentContext";
import { useConfetti } from "components/confetti/useConfetti";
import styled from "styled-components";

const innerWidth = "640px";

const allChecks = [
  learnAboutJobComplete,
  aboutSelfComplete,
  videoComplete,
  (allData) => allData.assnProgress["fillkarel"],
  (allData) => allData.assnProgress["doubleit"],
  debuggingComplete,
];


export const SectionLeaderApplication = () => {
  const [confettiIndex, setConfettiIndex] = useState(0);
  const { targetCourseId } = useParams()
  const userId = useUserId();
  const windowSize = useWindowSize();
  const { confettiParticles, fireConfetti } = useConfetti({ windowSize })

  const db = getFirestore();
  const [videoUrl, isVideoLoading, isVideoError] = useUploadedVideo(userId);
  const [courseData, courseDataLoading, courseDataError] = useDocumentData(
    doc(db, `course/${targetCourseId}`)
  );
  const { isCompletionLoading } = useContext(CompletionContext)

  const appDataPath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`;
  const appDocRef = doc(db, appDataPath);

  const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef);

  if (
    isVideoLoading ||
    appDataLoading ||
    isCompletionLoading ||
    courseDataLoading
  ) {
    return <Loading />;
  }
  if (appDataError || courseDataError) {
    return (
      <ErrorPage msg="Error loading application. Please try again later" />
    );
  }
  return (
    <>
      {confettiParticles}
      <div style={backgroundStyle}>
        <LandingNav applicationType={"sectionLeader"} subpage={null} />
        <div className="d-flex justify-content-center">
          <MainCard
            videoUrl={videoUrl}
            appData={appData}
            appDataPath={appDataPath}
            courseData={courseData}
            fireConfetti={fireConfetti}
          />
        </div>
      </div>
    </>
  );
};

const MainCard = ({
  videoUrl,
  appData,
  appDataPath,
  courseData,
  fireConfetti
}) => {
  // this is to make sure that we only app status to db when it changes
  const [onAppStatusUpdate] = useAppStatusServerRecorder(appData, appDataPath, 'sl')
  const { assnProgress, selfCheckProgress } = useContext(CompletionContext)
  const allData = { appData, videoUrl, assnProgress, selfCheckProgress };
  const { targetCourseId } = useParams()

  const appStatus = computeApplicationStatus(allData, allChecks);
  onAppStatusUpdate(appStatus)



  return (
    <div
      className="container mb-2 mt-3"
      style={{ backgroundColor: makeWhiteAlpha(0.4), maxWidth: innerWidth }}
    >
      <div className="row mb-3 mt-3">
        <div className="col">
          <Header
            appData={appData}
            courseData={courseData}
            appStatus={appStatus}
            appDataPath={appDataPath}
            fireConfetti={fireConfetti}
          />
          <TodoCard
            title="1. Let's get to know each other"
            content={[
              {
                title: "Learn about the job",
                to: `/public/applyteach/${targetCourseId}/aboutjob`,
                icon: FaRocket,
                isComplete: learnAboutJobComplete(allData),
              },
              {
                title: "Tell us about yourself",
                to: `/public/applyteach/${targetCourseId}/aboutyou`,
                icon: FaPen,
                isComplete: aboutSelfComplete(allData),
              },
            ]}
          />
          <TodoCard
            title="2. Make a teaching demo"
            content={[
              {
                title: "Create demo",
                to: `/public/applyteach/${targetCourseId}/teachingdemo`,
                icon: FaVideo,
                isComplete: videoComplete(allData),
              },
            ]}
          />
          <TodoCard
            title="3. Solve some problems"
            content={[
              {
                title: "Fill Karel",
                to: `/public/ide/a/fillkarel`,
                icon: FaPython,
                isComplete: assnProgress["fillkarel"],
              },
              {
                title: "Double It",
                to: `/public/ide/a/doubleit`,
                icon: FaPython,
                isComplete: assnProgress["doubleit"],
              },
              {
                title: "Debugging",
                to: `/public/applyteach/${targetCourseId}/debugging`,
                icon: FaPython,
                isComplete: debuggingComplete(allData),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

function videoComplete(allData) {
  return allData.videoUrl !== null;
}

function codeComplete(allData) {
  return (
    allData.completionData !== undefined && allData.completionData["outline"]
  );
}

function debuggingComplete(allData) {
  return allData.appData !== undefined && allData.appData["pythonDebug"];
}

function learnAboutJobComplete(allData) {
  return allData.appData !== undefined && allData.appData["hasReadAboutJob"];
}

function aboutSelfComplete(allData) {
  return allData.appData !== undefined && allData.appData["agreeHonorable"];
}

const Header = ({ appData, appStatus, appDataPath, courseData, fireConfetti }) => {
  const { targetCourseId } = useParams()
  const userId = useUserId()
  const db = getFirestore(getApp());
  const userRef = doc(db, `users/${userId}`);
  const [userData, userDataLoading, userDataError] = useDocumentData(userRef)
  const { roles, isLoadingRoles } = useContext(EnrollmentContext)

  useEffect(() => {
    const courseRole = roles?.[targetCourseId]
    const isAdmit = courseRole === Role.SECTION_LEADER
    if (isAdmit) {
      fireConfetti()
    }
  }, [roles])

  const appOpen = courseData?.slAppOpen;
  const dueDate = courseData?.slAppDueDate;

  if (userDataLoading) return <></>
  if (isLoadingRoles) return <></>


  // don't use the functions is Gate or profile_util as this string
  // has not been processed by ProfileContext

  const courseRole = roles?.[targetCourseId]
  const isAdmit = courseRole === Role.SECTION_LEADER
  const isComplete = appStatus === "complete";
  const closedAndIncomplete = !appOpen && !isComplete;
  const isStaff = courseRole === Role.ADMIN
    || courseRole === Role.TA
    || courseRole === Role.INSTRUCTOR



  const bannerMessage = getBannerMessage(appData, appStatus, userData, courseData, courseRole, targetCourseId)

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card" style={{ maxWidth: innerWidth }}>
        <div className="card-body">
          <h1>Section Leader Application</h1>
          <DueDate appOpen={appOpen} dueDate={dueDate} />
          <div style={{ height: "20px" }}></div>
          {bannerMessage}

          

          {!isAdmit && <ApplicationStatus appStatus={appStatus} appDataPath={appDataPath} />}
        </div>
      </div>
    </div>
  );
};

const getBannerMessage = (appData, appStatus, userData, courseData, courseRole, targetCourseId) => {
  const slResultsDay = courseData?.slResultsDay;
  const appOpen = courseData?.slAppOpen;
  const dueDate = courseData?.slAppDueDate;
  const decisionsLive = courseData?.slAppDecisionsLive;

  console.log(appData)



  // don't use the functions is Gate or profile_util as this string
  // has not been processed by ProfileContext

  const isComplete = appStatus === "complete";
  const closedAndIncomplete = !appOpen && !isComplete;
  const isAdmit = courseRole === Role.SECTION_LEADER
  const isMentorAdmit = courseRole === Role.MENTOR;
  const isStaff = courseRole === Role.ADMIN
    || courseRole === Role.TA
    || courseRole === Role.INSTRUCTOR

  const reviewNote = appData?.reviewNote
  const hasReviewNote = reviewNote && reviewNote.length > 0
  const reviewNoteDiv = hasReviewNote? <div dangerouslySetInnerHTML={{ __html: reviewNote }} /> : null



  if (isAdmit) {
    return <>
      <div className=" mb-4">
        <p style={{textAlign:'justify'}}>To {userData?.displayName},<br/>
        <b className="text-primary">Congratulations!</b> You have been selected to be a Section Leader for Code in Place 2024. We were impressed by your application and look forward to teaching with you. Please confirm your place in the course by the end of the day <b>{courseData?.slAcceptDate}</b> by clicking the onboarding button:</p>
        <div className="d-flex justify-content-center"><Link to={`/${targetCourseId}/onboarding/teach`} className="btn btn-primary btn-lg">
          Continue to Section Leader Onboarding!
        </Link>
        </div>
      </div>
    </>
  }

  if(isMentorAdmit) {
    return <>
      <div className=" mb-4">
        <p style={{textAlign:'justify'}}>To {userData?.displayName},<br/>
        <b className="text-primary">Congratulations!</b> You have been selected to be a Section Leader (Mentor) for Code in Place 2024. We were impressed by your application and look forward to teaching with you. We will send your more instructions over the weekend. For now there isn't anything you need to do!
        </p>
      </div>
    </>
  }

  if (!appOpen && !decisionsLive) {
    return <div className="bordered mb-4">
      Section leader applications are closed and we are busy reviewing applications.

    </div>
  }

  if (isStaff) {
    return <div className="bordered mb-4">
      Wahoo! You are on the staff team. Thank you for everything you do for Code in Place!
    </div>
  }

  if (!isAdmit && isComplete && decisionsLive) {
    return <div className="bordered mb-4">
      <p>Thank you for taking the time to apply and for your desire to help other people.
      We do not require your volunteer effort at this time.
      As you can appreciate, there is a maximum
      number of section leaders that we can support.
      We understand this might be disappointing, but please know that your readiness to help is greatly valued. Thanks for doing what you do and keep rocking on.
      We wish you all the best on your teaching journey.  Please continue your wonderful work in education, and don't hesitate to apply again in the future.</p>

      {/* {hasReviewNote && <p>In case it is helpful, here is what a reviewer had to say about your application. <>
      <ReviewQuote>{reviewNoteDiv}</ReviewQuote></></p>} */}

    </div>
  }


  if (!isAdmit && !isComplete && !appOpen) {
    return <div className="bordered">
      We already have enough volunteers. Feel free to explore the application and all the best on your teaching journey!
    </div>
  }


  return <p>
    We are recruiting anyone with CS experience and a love of teaching.
    Each volunteer will teach a small group of around 10 students once a
    week. As a team, we hope to instruct people around the world as part
    of a great experiment in human-centric learning at scale. Learn more
    about{" "}
    <a target="_blank" href="/">
      Code in Place
    </a>
    .{" "}
  </p>
}


const ReviewQuote = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 1px;
  margin: 20px 0;
  border-left: 5px solid #ccc;
  background-color: #f9f9f9;
  font-style: italic;
  color: #555;`


const DueDate = ({ appOpen, dueDate }) => {
  if (appOpen) {
    return <h4>Due {dueDate}</h4>;
  } else {
    // we have the response cards on, so no need forthis
    return null
    // return <h4>Applications Closed</h4>;
  }
};

const backgroundStyle = {
  height: "100vh",
  backgroundImage: 'url("/stanford/gates.jpg")',
  overflow: "auto",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const recordCompleteDate = (appData, appDataPath) => {
  if (!appData?.completedDate) {
    const db = getFirestore();
    const appDocRef = doc(db, appDataPath);
    updateDoc(appDocRef, { completedDate: new Date() });
  }
};

const makeWhiteAlpha = (alpha) => {
  return `rgba(255,255,255,${1 - alpha})`;
};

const UnreleasedAlert = () => {
  return (
    <div style={backgroundStyle}>
      <LandingNav applicationType={"sectionLeader"} subpage={null} />
      <div className="d-flex justify-content-center">
        <div className="container mb-2 mt-3" style={{ maxWidth: innerWidth }}>
          <div className="row mb-3 mt-3">
            <div className="col">
              <div className="alert alert-primary" role="alert">
                <b>Coming soon!</b> Check back next week.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

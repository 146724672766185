import "./ForumSearch.css";

import {
  Highlight,
  InstantSearch,
  Pagination,
  Snippet,
  useHits,
  useInstantSearch,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import React, { useContext, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { CloseOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { TagFilters } from "../SearchComponents/TagFilters/TagFilters";
import { PostTags } from "../PostTags/PostTags";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { ForumContext } from "course/forum/ForumContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { typesenseServerConfig } from "config";
import { FaCheck, FaEyeSlash, FaFlag } from "react-icons/fa";
import { UserChip } from "../UserChip/UserChip";

const PLACEHOLDERS = [
  "How to find the length of a string",
  "Help with assignment 3",
  "Cool project ideas",
  "When to use a while loop",
  "What's a boolean?",
  "Lecture videos",
];

const DELAY_AFTER_ANIMATION = 1000;
const MIN_ANIMATION_DELAY = 50;
const MAX_ANIMATION_DELAY = 90;

const getRandomDelayBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const setPlaceholder = (placeholder, inputNode) => {
  if (inputNode === undefined) return;
  inputNode.setAttribute("placeholder", placeholder);
};

const onAnimationEnd = (placeholder, inputNode) => {
  setTimeout(() => {
    let newPlaceholder = "";

    do {
      newPlaceholder =
        PLACEHOLDERS[Math.floor(Math.random() * PLACEHOLDERS.length)];
    } while (placeholder === newPlaceholder);

    animatePlaceholder(newPlaceholder, inputNode);
  }, DELAY_AFTER_ANIMATION);
};

const animateLetters = (currentLetters, remainingLetters, inputNode) => {
  if (!remainingLetters.length) {
    onAnimationEnd(currentLetters.join(""), inputNode);
    return;
  }

  currentLetters.push(remainingLetters.shift());

  setTimeout(() => {
    setPlaceholder(currentLetters.join(""), inputNode);
    animateLetters(currentLetters, remainingLetters, inputNode);
  }, getRandomDelayBetween(MIN_ANIMATION_DELAY, MAX_ANIMATION_DELAY));
};

const animatePlaceholder = (placeholder, inputNode) => {
  if (inputNode !== null) {
    animateLetters([], placeholder.split(""), inputNode);
  }
};

function getPlaceholderSearch() {
  return PLACEHOLDERS[Math.floor(Math.random() * PLACEHOLDERS.length)];
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: typesenseServerConfig,
  additionalSearchParameters: {
    queryBy: "title,text,author",
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

function SearchResult(props: { hit: any; clickHandler: () => void }) {
  const { hit, clickHandler } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const navigateToPost = () => {
    clickHandler();
    navigate({
      pathname: location.pathname,
      search: `post=${hit.id}`,
    });
  };

  return (
    <div onClick={navigateToPost} className="searchResult">
      <div className="searchResultData" tabIndex={0}>
        <label className="visually-hidden">Title</label>
        <p>
          <b>
            <Highlight attribute="title" hit={hit}>
              {hit.title}
            </Highlight>
          </b>
        </p>

        <UserChip uid={hit.authorUid} showAvatar={true} name="" />

        <Snippet hit={hit} attribute="text" tabIndex={0} aria-label={`${hit.text}`}></Snippet>

        <PostTags currentTags={hit.tags} editable={false} />
      </div>
      <div className="searchResultStatus">
        {hit.isPrivate && <FaEyeSlash className="text-dark" size={24} />}
        {hit.isFlagged && <FaFlag className="text-dark" size={24} />}
        {hit.isResolved && <FaCheck className="text-success" size={24} />}
      </div>
    </div>
  );
}

function SearchResults(props: { clickHandler: () => void }) {
  const { clickHandler } = props;
  const { indexUiState } = useInstantSearch();
  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);
  const { privatePostThreshold } = useContext(ForumContext);
  const { userData } = useContext(ProfileContext);
  const { hits } = useHits();


  const filteredHits = hits.filter(
    (hit) =>
      (!hit.isPrivate &&
      !hit.isFlagged) ||
      hit.authorUuid == user.uid ||
      Gate.hasRole(privatePostThreshold)(userData)
  );

  if (!indexUiState.query) {
    return null;
  }

  return (
    <div className="hitsContainer">
      <div>
        {filteredHits.map((hit, index) => (
          <SearchResult hit={hit} clickHandler={clickHandler} key={index} />
        ))}
        {
          filteredHits.length === 0 &&
          <p className="alert alert-primary">
            No results found
          </p>
        }
      </div>
      <div className="paginationContainer">
        <Pagination />
      </div>
    </div>
  );
}

function SearchBox(props) {
  const { refine, clear } = useSearchBox();

  const { passRef, searchBoxRef, ...otherProps } = props;

  const clearText = () => {
    clear();
    searchBoxRef.value = "";
  };

  return (
    <div className="input-wrapper">
      <FaSearch className="input-icon" />
      <input
        className="forumSearchBox"
        ref={passRef}
        type="text"
        onChange={(e) => {
          refine(e.target.value);
        }}
        {...otherProps}
        aria-label="Forum Search Input"
      />
      <CloseOutlined className="close-button" onClick={clearText} />
    </div>
  );
}

const ForumSearchBox = React.forwardRef((props: { searchBoxRef: any }, ref) => (
  <SearchBox passRef={ref} {...props} />
));

export function ForumSearch(props: { clickHandler: () => void }) {
  const { clickHandler } = props;
  const [searchBoxRef, setSearchBoxRef] = useState<any>();

  const courseId = useCourseId();
  const { forumId } = useContext(ForumContext);

  useEffect(() => {
    if (searchBoxRef === null) return;
    animatePlaceholder(getPlaceholderSearch(), searchBoxRef);
  }, [searchBoxRef]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${courseId}_${forumId}_posts`}
    >
      <ForumSearchBox
        ref={(newRef) => setSearchBoxRef(newRef)}
        searchBoxRef={searchBoxRef}
      />
      <div className="searchContainer" aria-label="Forum Seach Results">
        <TagFilters />
        <SearchResults clickHandler={clickHandler} />
      </div>
    </InstantSearch>
  );
}

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Swal from 'sweetalert2'
import { FaPlus } from 'react-icons/fa';
import { getFunctions, httpsCallable } from 'firebase/functions'

export const AddMemberField = ({sectionId, courseId}) => {
    const [memberEmail, setMemberEmail] = useState('')
    const [addIsLoading, setAddIsLoading] = useState(false)
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    const functions = getFunctions()
    const addMemberFirebase = httpsCallable(functions, 'addMember')

    const handleChange = (event) => {
        setMemberEmail(event.target.value);
    }

    const onAdd = async () => {
        console.log('Adding member', memberEmail, sectionId, courseId)
        setAddIsLoading(true)
        try{
        const res = await addMemberFirebase({memberEmail, sectionId, courseId})
        // @ts-ignore
        if (!!res && res.data.success){
            Swal.fire('Member added!', '', 'success')
            setAddIsLoading(false)
            return
        }
        else{
            // @ts-ignore
            throw new Error(res.data.reason)
        }
        } catch (e) {
        Swal.fire('Something went wrong!', `Could not add member. ${e.message}`, 'error')
        setAddIsLoading(false)
        return
        }
    }

    return <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
        <Col>
            <Form.Control type="email" placeholder="name@email.com" value={memberEmail} onChange={handleChange}/>
        </Col>
        <Col>
            <Button variant="success" onClick={onAdd} disabled={addIsLoading}><FaPlus color={'white'}/></Button>
        </Col>
    </Form.Group>
}
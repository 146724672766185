export type CountryCode =
    "CIP"
  | "AD"
  | "AE"
  | "AF"
  | "AG"
  | "AI"
  | "AL"
  | "AM"
  | "AO"
  | "AQ"
  | "AR"
  | "AS"
  | "AT"
  | "AU"
  | "AW"
  | "AX"
  | "AZ"
  | "BA"
  | "BB"
  | "BD"
  | "BE"
  | "BF"
  | "BG"
  | "BH"
  | "BI"
  | "BJ"
  | "BL"
  | "BM"
  | "BN"
  | "BO"
  | "BQ"
  | "BR"
  | "BS"
  | "BT"
  | "BV"
  | "BW"
  | "BY"
  | "BZ"
  | "CA"
  | "CC"
  | "CD"
  | "CF"
  | "CG"
  | "CH"
  | "CI"
  | "CK"
  | "CL"
  | "CM"
  | "CN"
  | "CO"
  | "CR"
  | "CU"
  | "CV"
  | "CW"
  | "CX"
  | "CY"
  | "CZ"
  | "DE"
  | "DJ"
  | "DK"
  | "DM"
  | "DO"
  | "DZ"
  | "EC"
  | "EE"
  | "EG"
  | "EH"
  | "ER"
  | "ES"
  | "ET"
  | "FI"
  | "FJ"
  | "FK"
  | "FM"
  | "FO"
  | "FR"
  | "GA"
  | "GB"
  | "GD"
  | "GE"
  | "GF"
  | "GG"
  | "GH"
  | "GI"
  | "GL"
  | "GM"
  | "GN"
  | "GP"
  | "GQ"
  | "GR"
  | "GS"
  | "GT"
  | "GU"
  | "GW"
  | "GY"
  | "HK"
  | "HM"
  | "HN"
  | "HR"
  | "HT"
  | "HU"
  | "ID"
  | "IE"
  | "IL"
  | "IM"
  | "IN"
  | "IO"
  | "IQ"
  | "IR"
  | "IS"
  | "IT"
  | "JE"
  | "JM"
  | "JO"
  | "JP"
  | "KE"
  | "KG"
  | "KH"
  | "KI"
  | "KM"
  | "KN"
  | "KP"
  | "KR"
  | "KW"
  | "KY"
  | "KZ"
  | "LA"
  | "LB"
  | "LC"
  | "LI"
  | "LK"
  | "LR"
  | "LS"
  | "LT"
  | "LU"
  | "LV"
  | "LY"
  | "MA"
  | "MC"
  | "MD"
  | "ME"
  | "MF"
  | "MG"
  | "MH"
  | "MK"
  | "ML"
  | "MM"
  | "MN"
  | "MO"
  | "MP"
  | "MQ"
  | "MR"
  | "MS"
  | "MT"
  | "MU"
  | "MV"
  | "MW"
  | "MX"
  | "MY"
  | "MZ"
  | "NA"
  | "NC"
  | "NE"
  | "NF"
  | "NG"
  | "NI"
  | "NL"
  | "NO"
  | "NP"
  | "NR"
  | "NU"
  | "NZ"
  | "OM"
  | "PA"
  | "PE"
  | "PF"
  | "PG"
  | "PH"
  | "PK"
  | "PL"
  | "PM"
  | "PN"
  | "PR"
  | "PS"
  | "PT"
  | "PW"
  | "PY"
  | "QA"
  | "RE"
  | "RO"
  | "RS"
  | "RU"
  | "RW"
  | "SA"
  | "SB"
  | "SC"
  | "SD"
  | "SE"
  | "SG"
  | "SH"
  | "SI"
  | "SJ"
  | "SK"
  | "SL"
  | "SM"
  | "SN"
  | "SO"
  | "SR"
  | "SS"
  | "ST"
  | "SV"
  | "SX"
  | "SY"
  | "SZ"
  | "TC"
  | "TD"
  | "TF"
  | "TG"
  | "TH"
  | "TJ"
  | "TK"
  | "TL"
  | "TM"
  | "TN"
  | "TO"
  | "TR"
  | "TT"
  | "TV"
  | "TW"
  | "TZ"
  | "UA"
  | "UG"
  | "UM"
  | "US"
  | "UY"
  | "UZ"
  | "VA"
  | "VC"
  | "VE"
  | "VG"
  | "VI"
  | "VN"
  | "VU"
  | "WF"
  | "WS"
  | "XK"
  | "YE"
  | "YT"
  | "ZA"
  | "ZM"
  | "ZW"
  | "AC"
  | "EU"
  | "IC"
  | "TA";

export type CountryNames = {
  eng_name: string;
  native_name: string;
};

export type CountryCodeToNames = Record<CountryCode, CountryNames>;

const COUNTRY_CODE_TO_NAMES: CountryCodeToNames = {
  CIP: { eng_name: "Somewhere on Earth", native_name: "Somewhere on Earth" },
  AD: { eng_name: "Andorra", native_name: "Andorra" },
  AE: {
    eng_name: "United Arab Emirates",
    native_name:
      "\u062f\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
  },
  AF: {
    eng_name: "Afghanistan",
    native_name: "\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
  },
  AG: { eng_name: "Antigua and Barbuda", native_name: "Antigua and Barbuda" },
  AI: { eng_name: "Anguilla", native_name: "Anguilla" },
  AL: { eng_name: "Albania", native_name: "Shqip\u00ebria" },
  AM: {
    eng_name: "Armenia",
    native_name: "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576",
  },
  AO: { eng_name: "Angola", native_name: "Angola" },
  AQ: { eng_name: "Antarctica", native_name: "Antarctica" },
  AR: { eng_name: "Argentina", native_name: "Argentina" },
  AS: { eng_name: "American Samoa", native_name: "American Samoa" },
  AT: { eng_name: "Austria", native_name: "\u00d6sterreich" },
  AU: { eng_name: "Australia", native_name: "Australia" },
  AW: { eng_name: "Aruba", native_name: "Aruba" },
  AX: { eng_name: "\u00c5land", native_name: "\u00c5land" },
  AZ: { eng_name: "Azerbaijan", native_name: "Az\u0259rbaycan" },
  BA: {
    eng_name: "Bosnia and Herzegovina",
    native_name: "Bosna i Hercegovina",
  },
  BB: { eng_name: "Barbados", native_name: "Barbados" },
  BD: { eng_name: "Bangladesh", native_name: "Bangladesh" },
  BE: { eng_name: "Belgium", native_name: "Belgi\u00eb" },
  BF: { eng_name: "Burkina Faso", native_name: "Burkina Faso" },
  BG: {
    eng_name: "Bulgaria",
    native_name: "\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
  },
  BH: {
    eng_name: "Bahrain",
    native_name: "\u200f\u0627\u0644\u0628\u062d\u0631\u064a\u0646",
  },
  BI: { eng_name: "Burundi", native_name: "Burundi" },
  BJ: { eng_name: "Benin", native_name: "B\u00e9nin" },
  BL: {
    eng_name: "Saint Barth\u00e9lemy",
    native_name: "Saint-Barth\u00e9lemy",
  },
  BM: { eng_name: "Bermuda", native_name: "Bermuda" },
  BN: { eng_name: "Brunei", native_name: "Negara Brunei Darussalam" },
  BO: { eng_name: "Bolivia", native_name: "Bolivia" },
  BQ: { eng_name: "Bonaire", native_name: "Bonaire" },
  BR: { eng_name: "Brazil", native_name: "Brasil" },
  BS: { eng_name: "Bahamas", native_name: "Bahamas" },
  BT: { eng_name: "Bhutan", native_name: "\u02bcbrug-yul" },
  BV: { eng_name: "Bouvet Island", native_name: "Bouvet\u00f8ya" },
  BW: { eng_name: "Botswana", native_name: "Botswana" },
  BY: {
    eng_name: "Belarus",
    native_name: "\u0411\u0435\u043b\u0430\u0440\u0443\u0301\u0441\u044c",
  },
  BZ: { eng_name: "Belize", native_name: "Belize" },
  CA: { eng_name: "Canada", native_name: "Canada" },
  CC: {
    eng_name: "Cocos [Keeling] Islands",
    native_name: "Cocos (Keeling) Islands",
  },
  CD: {
    eng_name: "Democratic Republic of the Congo",
    native_name: "R\u00e9publique d\u00e9mocratique du Congo",
  },
  CF: {
    eng_name: "Central African Republic",
    native_name: "K\u00f6d\u00f6r\u00f6s\u00ease t\u00ee B\u00eaafr\u00eeka",
  },
  CG: {
    eng_name: "Republic of the Congo",
    native_name: "R\u00e9publique du Congo",
  },
  CH: { eng_name: "Switzerland", native_name: "Schweiz" },
  CI: { eng_name: "Ivory Coast", native_name: "C\u00f4te d'Ivoire" },
  CK: { eng_name: "Cook Islands", native_name: "Cook Islands" },
  CL: { eng_name: "Chile", native_name: "Chile" },
  CM: { eng_name: "Cameroon", native_name: "Cameroon" },
  CN: { eng_name: "China", native_name: "\u4e2d\u56fd" },
  CO: { eng_name: "Colombia", native_name: "Colombia" },
  CR: { eng_name: "Costa Rica", native_name: "Costa Rica" },
  CU: { eng_name: "Cuba", native_name: "Cuba" },
  CV: { eng_name: "Cape Verde", native_name: "Cabo Verde" },
  CW: { eng_name: "Curacao", native_name: "Cura\u00e7ao" },
  CX: { eng_name: "Christmas Island", native_name: "Christmas Island" },
  CY: {
    eng_name: "Cyprus",
    native_name: "\u039a\u03cd\u03c0\u03c1\u03bf\u03c2",
  },
  CZ: { eng_name: "Czech Republic", native_name: "\u010cesk\u00e1 republika" },
  DE: { eng_name: "Germany", native_name: "Deutschland" },
  DJ: { eng_name: "Djibouti", native_name: "Djibouti" },
  DK: { eng_name: "Denmark", native_name: "Danmark" },
  DM: { eng_name: "Dominica", native_name: "Dominica" },
  DO: {
    eng_name: "Dominican Republic",
    native_name: "Rep\u00fablica Dominicana",
  },
  DZ: {
    eng_name: "Algeria",
    native_name: "\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
  },
  EC: { eng_name: "Ecuador", native_name: "Ecuador" },
  EE: { eng_name: "Estonia", native_name: "Eesti" },
  EG: { eng_name: "Egypt", native_name: "\u0645\u0635\u0631\u200e" },
  EH: {
    eng_name: "Western Sahara",
    native_name:
      "\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629",
  },
  ER: { eng_name: "Eritrea", native_name: "\u12a4\u122d\u1275\u122b" },
  ES: { eng_name: "Spain", native_name: "Espa\u00f1a" },
  ET: { eng_name: "Ethiopia", native_name: "\u12a2\u1275\u12ee\u1335\u12eb" },
  FI: { eng_name: "Finland", native_name: "Suomi" },
  FJ: { eng_name: "Fiji", native_name: "Fiji" },
  FK: { eng_name: "Falkland Islands", native_name: "Falkland Islands" },
  FM: { eng_name: "Micronesia", native_name: "Micronesia" },
  FO: { eng_name: "Faroe Islands", native_name: "F\u00f8royar" },
  FR: { eng_name: "France", native_name: "France" },
  GA: { eng_name: "Gabon", native_name: "Gabon" },
  GB: { eng_name: "United Kingdom", native_name: "United Kingdom" },
  GD: { eng_name: "Grenada", native_name: "Grenada" },
  GE: {
    eng_name: "Georgia",
    native_name: "\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",
  },
  GF: { eng_name: "French Guiana", native_name: "Guyane fran\u00e7aise" },
  GG: { eng_name: "Guernsey", native_name: "Guernsey" },
  GH: { eng_name: "Ghana", native_name: "Ghana" },
  GI: { eng_name: "Gibraltar", native_name: "Gibraltar" },
  GL: { eng_name: "Greenland", native_name: "Kalaallit Nunaat" },
  GM: { eng_name: "Gambia", native_name: "Gambia" },
  GN: { eng_name: "Guinea", native_name: "Guin\u00e9e" },
  GP: { eng_name: "Guadeloupe", native_name: "Guadeloupe" },
  GQ: { eng_name: "Equatorial Guinea", native_name: "Guinea Ecuatorial" },
  GR: {
    eng_name: "Greece",
    native_name: "\u0395\u03bb\u03bb\u03ac\u03b4\u03b1",
  },
  GS: {
    eng_name: "South Georgia and the South Sandwich Islands",
    native_name: "South Georgia",
  },
  GT: { eng_name: "Guatemala", native_name: "Guatemala" },
  GU: { eng_name: "Guam", native_name: "Guam" },
  GW: { eng_name: "Guinea-Bissau", native_name: "Guin\u00e9-Bissau" },
  GY: { eng_name: "Guyana", native_name: "Guyana" },
  HK: { eng_name: "Hong Kong", native_name: "\u9999\u6e2f" },
  HM: {
    eng_name: "Heard Island and McDonald Islands",
    native_name: "Heard Island and McDonald Islands",
  },
  HN: { eng_name: "Honduras", native_name: "Honduras" },
  HR: { eng_name: "Croatia", native_name: "Hrvatska" },
  HT: { eng_name: "Haiti", native_name: "Ha\u00efti" },
  HU: { eng_name: "Hungary", native_name: "Magyarorsz\u00e1g" },
  ID: { eng_name: "Indonesia", native_name: "Indonesia" },
  IE: { eng_name: "Ireland", native_name: "\u00c9ire" },
  IL: {
    eng_name: "Israel",
    native_name: "\u05d9\u05b4\u05e9\u05b0\u05c2\u05e8\u05b8\u05d0\u05b5\u05dc",
  },
  IM: { eng_name: "Isle of Man", native_name: "Isle of Man" },
  IN: { eng_name: "India", native_name: "\u092d\u093e\u0930\u0924" },
  IO: {
    eng_name: "British Indian Ocean Territory",
    native_name: "British Indian Ocean Territory",
  },
  IQ: { eng_name: "Iraq", native_name: "\u0627\u0644\u0639\u0631\u0627\u0642" },
  IR: { eng_name: "Iran", native_name: "\u0627\u06cc\u0631\u0627\u0646" },
  IS: { eng_name: "Iceland", native_name: "\u00cdsland" },
  IT: { eng_name: "Italy", native_name: "Italia" },
  JE: { eng_name: "Jersey", native_name: "Jersey" },
  JM: { eng_name: "Jamaica", native_name: "Jamaica" },
  JO: {
    eng_name: "Jordan",
    native_name: "\u0627\u0644\u0623\u0631\u062f\u0646",
  },
  JP: { eng_name: "Japan", native_name: "\u65e5\u672c" },
  KE: { eng_name: "Kenya", native_name: "Kenya" },
  KG: {
    eng_name: "Kyrgyzstan",
    native_name: "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d",
  },
  KH: { eng_name: "Cambodia", native_name: "K\u00e2mp\u016dch\u00e9a" },
  KI: { eng_name: "Kiribati", native_name: "Kiribati" },
  KM: { eng_name: "Comoros", native_name: "Komori" },
  KN: {
    eng_name: "Saint Kitts and Nevis",
    native_name: "Saint Kitts and Nevis",
  },
  KP: { eng_name: "North Korea", native_name: "\ubd81\ud55c" },
  KR: { eng_name: "South Korea", native_name: "\ub300\ud55c\ubbfc\uad6d" },
  KW: {
    eng_name: "Kuwait",
    native_name: "\u0627\u0644\u0643\u0648\u064a\u062a",
  },
  KY: { eng_name: "Cayman Islands", native_name: "Cayman Islands" },
  KZ: {
    eng_name: "Kazakhstan",
    native_name: "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d",
  },
  LA: { eng_name: "Laos", native_name: "\u0eaa\u0e9b\u0e9b\u0ea5\u0eb2\u0ea7" },
  LB: { eng_name: "Lebanon", native_name: "\u0644\u0628\u0646\u0627\u0646" },
  LC: { eng_name: "Saint Lucia", native_name: "Saint Lucia" },
  LI: { eng_name: "Liechtenstein", native_name: "Liechtenstein" },
  LK: { eng_name: "Sri Lanka", native_name: "\u015br\u012b la\u1e43k\u0101va" },
  LR: { eng_name: "Liberia", native_name: "Liberia" },
  LS: { eng_name: "Lesotho", native_name: "Lesotho" },
  LT: { eng_name: "Lithuania", native_name: "Lietuva" },
  LU: { eng_name: "Luxembourg", native_name: "Luxembourg" },
  LV: { eng_name: "Latvia", native_name: "Latvija" },
  LY: {
    eng_name: "Libya",
    native_name: "\u200f\u0644\u064a\u0628\u064a\u0627",
  },
  MA: {
    eng_name: "Morocco",
    native_name: "\u0627\u0644\u0645\u063a\u0631\u0628",
  },
  MC: { eng_name: "Monaco", native_name: "Monaco" },
  MD: { eng_name: "Moldova", native_name: "Moldova" },
  ME: {
    eng_name: "Montenegro",
    native_name: "\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",
  },
  MF: { eng_name: "Saint Martin", native_name: "Saint-Martin" },
  MG: { eng_name: "Madagascar", native_name: "Madagasikara" },
  MH: { eng_name: "Marshall Islands", native_name: "M\u0327aje\u013c" },
  MK: {
    eng_name: "North Macedonia",
    native_name:
      "\u0421\u0435\u0432\u0435\u0440\u043d\u0430 \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
  },
  ML: { eng_name: "Mali", native_name: "Mali" },
  MM: {
    eng_name: "Myanmar [Burma]",
    native_name: "\u1019\u103c\u1014\u103a\u1019\u102c",
  },
  MN: {
    eng_name: "Mongolia",
    native_name: "\u041c\u043e\u043d\u0433\u043e\u043b \u0443\u043b\u0441",
  },
  MO: { eng_name: "Macao", native_name: "\u6fb3\u9580" },
  MP: {
    eng_name: "Northern Mariana Islands",
    native_name: "Northern Mariana Islands",
  },
  MQ: { eng_name: "Martinique", native_name: "Martinique" },
  MR: {
    eng_name: "Mauritania",
    native_name: "\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",
  },
  MS: { eng_name: "Montserrat", native_name: "Montserrat" },
  MT: { eng_name: "Malta", native_name: "Malta" },
  MU: { eng_name: "Mauritius", native_name: "Maurice" },
  MV: { eng_name: "Maldives", native_name: "Maldives" },
  MW: { eng_name: "Malawi", native_name: "Malawi" },
  MX: { eng_name: "Mexico", native_name: "M\u00e9xico" },
  MY: { eng_name: "Malaysia", native_name: "Malaysia" },
  MZ: { eng_name: "Mozambique", native_name: "Mo\u00e7ambique" },
  NA: { eng_name: "Namibia", native_name: "Namibia" },
  NC: { eng_name: "New Caledonia", native_name: "Nouvelle-Cal\u00e9donie" },
  NE: { eng_name: "Niger", native_name: "Niger" },
  NF: { eng_name: "Norfolk Island", native_name: "Norfolk Island" },
  NG: { eng_name: "Nigeria", native_name: "Nigeria" },
  NI: { eng_name: "Nicaragua", native_name: "Nicaragua" },
  NL: { eng_name: "Netherlands", native_name: "Nederland" },
  NO: { eng_name: "Norway", native_name: "Norge" },
  NP: { eng_name: "Nepal", native_name: "\u0928\u092a\u0932" },
  NR: { eng_name: "Nauru", native_name: "Nauru" },
  NU: { eng_name: "Niue", native_name: "Niu\u0113" },
  NZ: { eng_name: "New Zealand", native_name: "New Zealand" },
  OM: { eng_name: "Oman", native_name: "\u0639\u0645\u0627\u0646" },
  PA: { eng_name: "Panama", native_name: "Panam\u00e1" },
  PE: { eng_name: "Peru", native_name: "Per\u00fa" },
  PF: {
    eng_name: "French Polynesia",
    native_name: "Polyn\u00e9sie fran\u00e7aise",
  },
  PG: { eng_name: "Papua New Guinea", native_name: "Papua Niugini" },
  PH: { eng_name: "Philippines", native_name: "Pilipinas" },
  PK: { eng_name: "Pakistan", native_name: "Pakistan" },
  PL: { eng_name: "Poland", native_name: "Polska" },
  PM: {
    eng_name: "Saint Pierre and Miquelon",
    native_name: "Saint-Pierre-et-Miquelon",
  },
  PN: { eng_name: "Pitcairn Islands", native_name: "Pitcairn Islands" },
  PR: { eng_name: "Puerto Rico", native_name: "Puerto Rico" },
  PS: {
    eng_name: "Palestine",
    native_name: "\u0641\u0644\u0633\u0637\u064a\u0646",
  },
  PT: { eng_name: "Portugal", native_name: "Portugal" },
  PW: { eng_name: "Palau", native_name: "Palau" },
  PY: { eng_name: "Paraguay", native_name: "Paraguay" },
  QA: { eng_name: "Qatar", native_name: "\u0642\u0637\u0631" },
  RE: { eng_name: "R\u00e9union", native_name: "La R\u00e9union" },
  RO: { eng_name: "Romania", native_name: "Rom\u00e2nia" },
  RS: {
    eng_name: "Serbia",
    native_name: "\u0421\u0440\u0431\u0438\u0458\u0430",
  },
  RU: {
    eng_name: "Russia",
    native_name: "\u0420\u043e\u0441\u0441\u0438\u044f",
  },
  RW: { eng_name: "Rwanda", native_name: "Rwanda" },
  SA: {
    eng_name: "Saudi Arabia",
    native_name:
      "\u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
  },
  SB: { eng_name: "Solomon Islands", native_name: "Solomon Islands" },
  SC: { eng_name: "Seychelles", native_name: "Seychelles" },
  SD: {
    eng_name: "Sudan",
    native_name: "\u0627\u0644\u0633\u0648\u062f\u0627\u0646",
  },
  SE: { eng_name: "Sweden", native_name: "Sverige" },
  SG: { eng_name: "Singapore", native_name: "Singapore" },
  SH: { eng_name: "Saint Helena", native_name: "Saint Helena" },
  SI: { eng_name: "Slovenia", native_name: "Slovenija" },
  SJ: {
    eng_name: "Svalbard and Jan Mayen",
    native_name: "Svalbard og Jan Mayen",
  },
  SK: { eng_name: "Slovakia", native_name: "Slovensko" },
  SL: { eng_name: "Sierra Leone", native_name: "Sierra Leone" },
  SM: { eng_name: "San Marino", native_name: "San Marino" },
  SN: { eng_name: "Senegal", native_name: "S\u00e9n\u00e9gal" },
  SO: { eng_name: "Somalia", native_name: "Soomaaliya" },
  SR: { eng_name: "Suriname", native_name: "Suriname" },
  SS: { eng_name: "South Sudan", native_name: "South Sudan" },
  ST: {
    eng_name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe",
    native_name: "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
  },
  SV: { eng_name: "El Salvador", native_name: "El Salvador" },
  SX: { eng_name: "Sint Maarten", native_name: "Sint Maarten" },
  SY: { eng_name: "Syria", native_name: "\u0633\u0648\u0631\u064a\u0627" },
  SZ: { eng_name: "Swaziland", native_name: "Swaziland" },
  TC: {
    eng_name: "Turks and Caicos Islands",
    native_name: "Turks and Caicos Islands",
  },
  TD: { eng_name: "Chad", native_name: "Tchad" },
  TF: {
    eng_name: "French Southern Territories",
    native_name: "Territoire des Terres australes et antarctiques fr",
  },
  TG: { eng_name: "Togo", native_name: "Togo" },
  TH: {
    eng_name: "Thailand",
    native_name: "\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22",
  },
  TJ: {
    eng_name: "Tajikistan",
    native_name: "\u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d",
  },
  TK: { eng_name: "Tokelau", native_name: "Tokelau" },
  TL: { eng_name: "East Timor", native_name: "Timor-Leste" },
  TM: { eng_name: "Turkmenistan", native_name: "T\u00fcrkmenistan" },
  TN: { eng_name: "Tunisia", native_name: "\u062a\u0648\u0646\u0633" },
  TO: { eng_name: "Tonga", native_name: "Tonga" },
  TR: { eng_name: "Turkey", native_name: "T\u00fcrkiye" },
  TT: { eng_name: "Trinidad and Tobago", native_name: "Trinidad and Tobago" },
  TV: { eng_name: "Tuvalu", native_name: "Tuvalu" },
  TW: { eng_name: "Taiwan", native_name: "\u81fa\u7063" },
  TZ: { eng_name: "Tanzania", native_name: "Tanzania" },
  UA: {
    eng_name: "Ukraine",
    native_name: "\u0423\u043a\u0440\u0430\u0457\u043d\u0430",
  },
  UG: { eng_name: "Uganda", native_name: "Uganda" },
  UM: {
    eng_name: "U.S. Minor Outlying Islands",
    native_name: "United States Minor Outlying Islands",
  },
  US: { eng_name: "United States", native_name: "United States" },
  UY: { eng_name: "Uruguay", native_name: "Uruguay" },
  UZ: { eng_name: "Uzbekistan", native_name: "O\u2018zbekiston" },
  VA: { eng_name: "Vatican City", native_name: "Vaticano" },
  VC: {
    eng_name: "Saint Vincent and the Grenadines",
    native_name: "Saint Vincent and the Grenadines",
  },
  VE: { eng_name: "Venezuela", native_name: "Venezuela" },
  VG: {
    eng_name: "British Virgin Islands",
    native_name: "British Virgin Islands",
  },
  VI: {
    eng_name: "U.S. Virgin Islands",
    native_name: "United States Virgin Islands",
  },
  VN: { eng_name: "Vietnam", native_name: "Vi\u1ec7t Nam" },
  VU: { eng_name: "Vanuatu", native_name: "Vanuatu" },
  WF: { eng_name: "Wallis and Futuna", native_name: "Wallis et Futuna" },
  WS: { eng_name: "Samoa", native_name: "Samoa" },
  XK: { eng_name: "Kosovo", native_name: "Republika e Kosov\u00ebs" },
  YE: {
    eng_name: "Yemen",
    native_name: "\u0627\u0644\u064a\u064e\u0645\u064e\u0646",
  },
  YT: { eng_name: "Mayotte", native_name: "Mayotte" },
  ZA: { eng_name: "South Africa", native_name: "South Africa" },
  ZM: { eng_name: "Zambia", native_name: "Zambia" },
  ZW: { eng_name: "Zimbabwe", native_name: "Zimbabwe" },
  AC: { eng_name: "Ascension Island", native_name: "Ascension Island" },
  EU: { eng_name: "European Union", native_name: "European Union" },
  IC: { eng_name: "Canary Islands", native_name: "Islas Canarias" },
  TA: { eng_name: "Tristan da Cunha", native_name: "Tristan da Cunha" },
};

export default COUNTRY_CODE_TO_NAMES;

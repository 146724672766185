import { OverlayTrigger, Tooltip } from "react-bootstrap"

/**
 * props:
 * placement, defaults to right
 * overlayText, the text to display in the overlay
 * children, the element to wrap with the overlay
 */
export const OverlaySimple = ({ placement = 'right', overlayText, children }) => {

  return <OverlayTrigger
    placement={placement as import("react-bootstrap/esm/types").Placement}
    delay={{ show: 100, hide: 100 }}
    overlay={(props) => (
      <Tooltip {...props}>
        {overlayText}
      </Tooltip>
    )}
  >
    {children}
  </OverlayTrigger>
}
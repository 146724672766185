export const GETTING_STARTED = "Getting Started";
export const EDTHEORY = "Leveling Up Your Teaching";
// const LEVELING_UP = "Leveling Up Your Teaching";

export const modules = [
  // {
  //   id: "leading-effective-section",
  //   group: EDTHEORY,
  //   title: "Leading an Effective Section for Everyone",
  //   srtFile: undefined,
  //   videoUrl: undefined,
  //   items: [],
  //   completed: false,
  // },
  {
    id: "curiosity",
    group: EDTHEORY,
    title: "Why Curiosity?",
    srtFile: "WhyCuriosity.srt",
    videoUrl: "https://www.youtube.com/watch?v=2IT1-iMZsR4",
    items: [],
    completed: false,
  },
  {
    id: "getting-ideas",
    group: EDTHEORY,
    title: "Getting Students to Talk",
    srtFile: "GettingIdeasOnTheTable.srt",
    videoUrl: "https://www.youtube.com/watch?v=upLYd34LyYk",
    items: [],
    completed: false,
  },
  {
    id: "building-on-ideas",
    group: EDTHEORY,
    title: "Building On Ideas",
    srtFile: "BuildingOnIdeas.srt",
    videoUrl: "https://www.youtube.com/watch?v=a-vdr87xaYk",
    items: [],
    completed: false,
  },
  {
    id: "connecting-ideas",
    group: EDTHEORY,
    title: "Connecting Ideas",
    srtFile: "ConnectingIdeas.srt",
    videoUrl: "https://www.youtube.com/watch?v=MRVW6vzPBAs",
    items: [],
    completed: false,
  },
  {
    id: "bringing-together",
    group: EDTHEORY,
    title: "Bringing It All Together",
    srtFile: "BringingItAllTogether.srt",
    videoUrl: "https://www.youtube.com/watch?v=EjaHJvwCFgI",
    items: [],
    completed: false,
  },
];


export const modulesDictionary = modules.reduce((acc, mod) => {
  acc[mod.id] = mod;
  return acc;
}, {});

export type Question = {
  timestamp: string;
  choices: { text: string; isCorrect: boolean; reason: string }[];
  seen?: boolean;
};

export const gettinIdeasOnTheTable: Question[] = [
  {
    timestamp: "0:53",
    choices: [
      {
        text: "Make space for students to share about their experiences.",
        reason:
          "Asking students for stories can help get some of their experiences on the table.",
        isCorrect: true,
      },
      {
        text: "Repeat what you just said but louder this time.",
        reason:
          "This is unlikely to show you or anyone else what the students are thinking.",
        isCorrect: false,
      },
      {
        text: "Skip introductions and just start explaining today's assignment.",
        reason:
          "It may feel like you are getting more accomplished if you are talking more during section, but you won't know what students are learning without getting their ideas on the table.",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "1:55",
    choices: [
      {
        text: "Make it seem normal and expected that students have questions about it.",
        reason:
          "Hearing students' questions can help you and everyone else learn more about how they are thinking.",
        isCorrect: true,
      },
      {
        text: "Ask anyone who hasn't watched the lecture to raise their hand.",
        reason:
          "Knowing who watched the lecture doesn't give us specific information about how students are thinking.",
        isCorrect: false,
      },
      {
        text: "Re-explain the ideas from this week's lecture.",
        reason:
          "It would be better to find out what the students are thinking first so you can tailor your responses to be relevant for them.",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "3:07",
    choices: [
      {
        text: "Ask someone to rephrase what the problem is about.",
        reason:
          "Getting this idea on the table can help us understand more about how the speaker is thinking and give other students an opportunity to think about the problem in a new way.",
        isCorrect: true,
      },
      {
        text: "Remind them how easy this problem is to boost their confidence.",
        reason:
          "Wait! This won't boost their confidence if they don't also think the problem is easy. It might make them more reluctant to share ideas if they don't see it that way.",
        isCorrect: false,
      },
      {
        text: "Show them the solution right away.",
        reason:
          "They haven't had much of a chance to think about what the problem is or how to solve it yet. Let's find out what they are thinking first.",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "4:15",
    choices: [
      {
        text: "Find out what they've already tried first.",
        reason:
          "Asking students to explain what they've already done is a chance for you to learn about how they are thinking and for them to rethink their own ideas.",
        isCorrect: true,
      },
      {
        text: "Encourage them to quit the class if they need help the first week.",
        reason: "This class, and section, is a great place to ask for help.",
        isCorrect: false,
      },
      {
        text: "Answer the question.",
        reason:
          "Giving them the answer to one specific problem won't necessarily help them know why that is the answer. Let's find out more about what they think and why.",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "5:05",
    choices: [
      {
        text: "Ask what they've discovered that does not solve the problem.",
        reason:
          "Getting ideas about what doesn't work on the table can help everyone think through why they don't work. This can be just as important as understanding why solutions do work.",
        isCorrect: true,
      },
      {
        text: "Try saying 'please'.",
        reason:
          "It's unlikely that the reason no one is sharing is because the section leader wasn't polite enough.",
        isCorrect: false,
      },
      {
        text: "Skip this problem and find an easier one.",
        reason:
          "Wait! We could learn more about how the students approached this problem before skipping to another one.",
        isCorrect: false,
      },
    ],
  },
];

export const buildingOnIdeas: Question[] = [
  {
    timestamp: "1:03",
    choices: [
      {
        text: "Ask about her reasons why she did it that way.",
        reason:
          "Students have more opportunities to learn when they can consider their own (and each others') reasoning.",
        isCorrect: true,
      },
      {
        text: "Tell her we can all leave Section early now because the problem is finished.",
        reason:
          "There's still more to learn. How can we build on this one specific solution to one specific problem to make more general learning?",
        isCorrect: false,
      },
      {
        text: "Confirm that this is a correct solution.",
        reason:
          "She doesn't need a Section Leader to know whether it runs or not. Is there something the SL can do to foster more curiosity and discussion?",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "1:37",
    choices: [
      {
        text: "Ask students to consider what might happen if the conditions of the problem were different.",
        reason:
          "This will give students an opportunity to deepen their understanding by thinking about how these ideas generalize or not to new situations.",
        isCorrect: true,
      },
      {
        text: "Tell the students a story about a time you solved a problem and everyone was impressed with you.",
        reason:
          "How might we build on the ideas the student has put on the table instead?",
        isCorrect: false,
      },
      {
        text: "Stop talking about this problem and find a harder one.",
        reason:
          "Wait! We could learn more about why the students solved the problem this way before skipping to another one. What if they just made lucky assumptions?",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "2:44",
    choices: [
      {
        text: "Find out more about what Sierra is trying to say.",
        reason:
          "It is not totally clear what she means by 'them' and 'it'. Being more precise might help her and others think through her claim.",
        isCorrect: true,
      },
      {
        text: "Accuse Jim of not really knowing what Sierra is talking about.",
        reason:
          "No need to accuse anyone! Let's just clarify what Sierra means.",
        isCorrect: false,
      },
      {
        text: "Nod back to them to show you probably knew what she meant. ",
        reason:
          "Let's ask for clarification so we can be sure we know what each other means.",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "4:02",
    choices: [
      {
        text: "Discuss why it didn't work.",
        reason:
          "We should still build on incorrect and partially correct ideas to gain more understanding.",
        isCorrect: true,
      },
      {
        text: "Tell Jim he should be more careful about sharing his ideas next time.",
        reason:
          "We want students to share their ideas even if they are incorrect so we all can figure out how to improve them together.",
        isCorrect: false,
      },
      {
        text: "Tell Jim he had a nice try but to work harder next time.",
        reason:
          "This feedback doesn't help Jim see what he might do differently next time. How can we build on his idea instead?",
        isCorrect: false,
      },
    ],
  },
];

export const connectingIdeas: Question[] = [
  {
    timestamp: "1:22",
    choices: [
      {
        text: "Invite a different student to add on to the ideas that Gabriel has started.",
        reason:
          "Thinking about other people's thinking is a great way to learn more.",
        isCorrect: true,
      },
      {
        text: "Yell 'no cheating!' to let them know you're watching.",
        reason:
          "We want students to share ideas with each other and make connections between them.",
        isCorrect: false,
      },
      {
        text: "Remind everyone to pay attention.",
        reason:
          "How can we help students get curious about and respond to each others' ideas?",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "2:41",
    choices: [
      {
        text: "Ask if anyone else has ideas about the bug in Sierra's code.",
        reason:
          "Connecting to each others' ideas doesn't always mean agreement. Let's show students how we can disagree with a spirit of curiosity rather than judgement.",
        isCorrect: true,
      },
      {
        text: "Ask Sierra to delete her code immediately so the other students don't see it.",
        reason:
          "If she deletes the code then no one will get a chance to make sense of it and learn from why it does or doesn't work.",
        isCorrect: false,
      },
      {
        text: "Tell Sierra that the website isn't wrong but she is. ",
        reason:
          "Just pointing out that her code doesn't work doesn't help. How might we get some other perspectives about why it isn't working?",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "3:27",
    choices: [
      {
        text: "Ask if anyone else has a similar idea but would explain it differently.",
        reason:
          "This question gives students the opportunity to explain ideas in their own words as well as hear multiple kinds of explanations.",
        isCorrect: true,
      },
      {
        text: "Laugh and point at Sierra who still looks confused. ",
        reason:
          "How can you help students make connections between what Kaitlyn said and their own ideas?",
        isCorrect: false,
      },
      {
        text: "Confirm that Kaitlyn is correct.",
        reason:
          "How might we help Sierra (and others) make connections between how they are thinking and what Kaitlyn just said?",
        isCorrect: false,
      },
    ],
  },
  {
    timestamp: "4:21",
    choices: [
      {
        text: "Check if anyone else wants to add on to the idea.",
        reason:
          "Considering more than one way to get a solution helps build everyone's understanding. ",
        isCorrect: true,
      },
      {
        text: "Groan and say 'finally!'",
        reason:
          "Having a solution faster doesn't necessarily mean more learning occurred.",
        isCorrect: false,
      },
      {
        text: "Move to the next problem now that Sierra understands. ",
        reason:
          "There may still be more that Sierra or others can learn from this problem. Let's check for more connections before moving on.",
        isCorrect: false,
      },
    ],
  },
];

export type AnnotatedPiece = {
  timestampStart: string;
  timestampEnd: string;
  reason: string;
};

export const annotatedLastModuleData: AnnotatedPiece[] = [
  {
    timestampStart: "0:52",
    timestampEnd: "0:55",
    reason: "Getting Ideas on the Table - What happened when you tried...?",
  },
  {
    timestampStart: "1:00",
    timestampEnd: "1:02",
    reason: "Building on Ideas - What's a possible reason for...?",
  },
  {
    timestampStart: "1:07",
    timestampEnd: "1:11",
    reason: "Building on Ideas - What would happen if we changed...?",
  },
  {
    timestampStart: "1:24",
    timestampEnd: "1:27",
    reason:
      "Getting Ideas on the Table - How does this outcome compare to your expectations...?",
  },
  {
    timestampStart: "1:30",
    timestampEnd: "1:32",
    reason: "Building on Ideas - Can you clarify...?",
  },
  {
    timestampStart: "2:15",
    timestampEnd: "2:20",
    reason: "Building on Ideas - What would we need to do to test...?",
  },
  {
    timestampStart: "2:35",
    timestampEnd: "2:38",
    reason: "Building on Ideas - Can you explain in your own words...?",
  },
  {
    timestampStart: "2:49",
    timestampEnd: "2:53",
    reason:
      "Connecting Ideas - Does anyone agree but would explain differently?",
  },
  {
    timestampStart: "3:15",
    timestampEnd: "3:20",
    reason: "Connecting Ideas - Does anyone disagree and can explain why?",
  },
  {
    timestampStart: "4:20",
    timestampEnd: "4:25",
    reason: "Getting Ideas on the Table - What happened when you tried...?",
  },
  {
    timestampStart: "4:36",
    timestampEnd: "4:40",
    reason: "Getting Ideas on the Table - What happened when you tried...?",
  },
  {
    timestampStart: "5:10",
    timestampEnd: "5:15",
    reason: "Building on Ideas - What would happen if we changed...?",
  },
  {
    timestampStart: "5:46",
    timestampEnd: "5:52",
    reason: "Building on Ideas - What's a possible reason for...?",
  },
];

import { useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Swal from 'sweetalert2'
import { LessonsContext } from "../../contexts/LessonsContext"
import { TrainingContext } from "course/contexts/TrainingContext"
import { CoursePageBodyContainer } from "../../../components/layout/CoursePageBodyContainer"
import { isValidId } from "../OnEventAlerts"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LessonsTable } from "./LessonsTable"
import { Stack } from "react-bootstrap"
import { ProfileContext } from "../../../contexts/ProfileContext"
import Gate from "contexts/Gate"
import NoAccess from "components/errors/NoAccess"



export const LessonEditorSplash = () => {
  const { userData } = useContext(ProfileContext)
  const isAdmin = Gate.hasAdminRole(userData)
  if (!isAdmin) {
    return <NoAccess />
  }

  return <CoursePageBodyContainer
    mainColumn={<LessonEditorMain />}
    rightColumn={<></>}
    singleColumn={<LessonEditorMain />}
  />
}

const LessonEditorMain = () => {
  const { editorType, courseId } = useParams();
  const { lessonsMap, addLessonToDB } = useContext(editorType == 'student' ? LessonsContext : TrainingContext);
  const allLessonIds = new Set(Object.keys(lessonsMap))
  const navigate = useNavigate()

  const onSubmit = async (lessonTitle, lessonId, lessonType) => {
    // validate all entries -- lesson id must be unique
    if (lessonTitle === '' || lessonId === '' || lessonType === '') {
      Swal.fire('Oops...', 'One or more of the fields is empty, which is not allowed!', 'error')
      return
    }
    if (allLessonIds.has(lessonId)) {
      Swal.fire('Oops...', "There is already a lesson with this ID! Please pick another.", 'error')
      return
    }
    if (!isValidId(lessonId)) {
      Swal.fire('Oops...', "The Lesson ID you chose is invalid. Please use only lowercase letters, numbers and hyphens", 'error')
      return
    }

    // add lesson to db + navigate to new lesson editor page
    await addLessonToDB(lessonTitle, lessonId, lessonType) // note: this fn does no error handling
    const lessonEditUrl = `/${courseId}/lessoneditor/${editorType}/${lessonId}`
    navigate(lessonEditUrl)
  }

  return <>
    <AddLessonForm onSubmit={onSubmit} />
    <LessonsTable courseId={courseId} lessonsMap={lessonsMap} />
  </>
}

export const AddLessonForm = ({ onSubmit }) => {
  const { editorType } = useParams();
  const [lessonTitle, setLessonTitle] = useState('')
  const [lessonId, setLessonId] = useState('')
  const [lessonType, setLessonType] = useState('')

  return <>
    <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">{`Create new ${editorType =='student' ? 'student-facing' : 'teacher training'} lesson`}</div>
    <Stack direction="vertical" gap={3}>

      <Form.Control
        className="me-auto"
        type="text"
        id="lessonId"
        value={lessonId}
        onChange={(e) => { setLessonId(e.target.value) }}
        placeholder="Enter the new Lesson ID"
      />
      <Form.Control
        className="me-auto"
        type="text"
        id="lessonTitle"
        value={lessonTitle}
        onChange={(e) => { setLessonTitle(e.target.value) }}
        placeholder="Enter the new Lesson Title"
      />
      {editorType == 'student' ? 
        <Form.Select id="lessonType" value={lessonType} onChange={(e) => { setLessonType(e.target.value) }}>
          <option value="">Select a lesson type</option>
          <option value="karel">Karel</option>
          <option value="console">Console</option>
          <option value="graphics">Graphics</option>
          <option value="data">Data</option>
        </Form.Select>
      :
        <Form.Select id="lessonType" value={lessonType} onChange={(e) => { setLessonType(e.target.value) }}>
          <option value="">Select a lesson type</option>
          <option value="teacher">Teacher</option>
        </Form.Select>
      }
      <Button className='mt-3' onClick={(e) => { onSubmit(lessonTitle, lessonId, lessonType) }} disabled={lessonTitle === '' || lessonId === '' || lessonType === ''}>Submit</Button>
    </Stack>
  </>
}

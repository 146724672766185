import React from 'react';

const OverallReadiness = ({ overallReadiness, setOverallReadiness, feedbackForStudent, setFeedbackForStudent }) => (
  <div>
    <div className="mb-3">
      <label htmlFor="overallReadiness" className="form-label" style={{fontSize: '1rem'}}>
        Overall Readiness:
      </label>
      <select 
        id="overallReadiness"
        className="form-select"
        value={overallReadiness}
        onChange={(e) => setOverallReadiness(e.target.value)}
      >
        <option value="" disabled>Select status</option>
        <option value="ready">Ready to teach</option>
        <option value="not_ready">Not yet ready to teach</option>
      </select>
    </div>
    <div className="mb-3">
      <label htmlFor="feedbackForStudent" className="form-label" style={{fontSize: '1rem'}}>
        Feedback to Share with the Student:
      </label>
      <textarea
        id="feedbackForStudent"
        className="form-control"
        value={feedbackForStudent}
        onChange={(e) => setFeedbackForStudent(e.target.value)}
        rows="4"
      ></textarea>
    </div>
  </div>
);

export default OverallReadiness;

/**
 * @author tj jefferson - tjj[at]stanford.edu -- tjefferson401 (github)
 * @date 11-10-2023
 * @description This file contains helper functions for syncing collaborator mice
 */





/**
 * @description Renders collaborator mouse onto any non-canvas, non-ace element
 * @param {*} mouseElement 
 * @param {*} mouse 
 * @param {string} color 
 * @returns {void}
 */
export const syncMouseGeneral = (mouseElement, mouse, color) => {
  
    const parentElement = document.getElementById(mouse.type);
    if(!mouseElement || !parentElement) return;

    // Add div to parent element
    parentElement.style.position = "relative";
    parentElement.appendChild(mouseElement)
    // Get parent element width and height
    const rect = parentElement.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    // need to compute ratio with collaborator
    // If collaborator pane is wider, mouse should be scaled down etc.
    let x = width/mouse.width*mouse.x;
    let y = height/mouse.height*mouse.y;
    buildInBoundsMouse(mouseElement, x, y, color)
    // Get last 5 letters of parenId and check if its a docs tiptap
    const parentIdTiptap = mouse.type.slice(0, 6)
    if(parentIdTiptap === "tiptap") {
      syncTipTapMouse(mouse, color)
      return;
    }

}



const syncTipTapMouse = (mouseElement, color) => {
  // const parentElement = document.getElementById(mouse.type);
  // if(!parentElement) return;

  // // make background light color
  // parentElement.style.backgroundColor = "lightblue";

  // setTimeout(() => {
  //   parentElement.style.backgroundColor= "transparent"
  // }, 500)
  const docsSplash =  document.getElementById("docs-content-ide")
  if(!docsSplash) return;
  // Compute if mouse is out of bounds
  const docsSplashBottomViewPort = docsSplash.scrollTop + docsSplash.clientHeight
  const docsSplashTopViewPort = docsSplash.scrollTop
  const {x, y } = getNestedRelativePosition(mouseElement, docsSplash)
  const { outUp, outDown } = { outUp: y < docsSplashTopViewPort, outDown: y > docsSplashBottomViewPort }

  // Build oob mouse if needed
  if( outUp|| outDown) {
      docsSplash.appendChild(mouseElement)
      buildOutOfBoundsMouse(mouseElement, x, y, docsSplash.clientWidth, docsSplash.clientHeight, color, { outLeft: false, outRight: false, outUp, outDown }, docsSplash.scrollTop)
  } else {
    buildInBoundsMouse(mouseElement, x, y, color)
  }
}


/**
 * @description sync mouse on canvas
 * @param {*} mouseElement 
 * @param {*} mouse 
 * @param {string} color 
 * @returns {void}
 */
export const syncCanvasMouse = (mouseElement, mouse, color)  => {
    const canvas = document.getElementById("canvas");
    if(!canvas) return;
    // No need to scale :)
    const offsetLeft = canvas.offsetLeft;
    const offsetTop = canvas.offsetTop;
    canvas.parentElement.appendChild(mouseElement)
    buildInBoundsMouse(mouseElement, mouse.x + offsetLeft, mouse.y + offsetTop, color)
}


/**
 * @description Builds in bounds mouse
 * @param {any} customCursor 
 * @param {number} x 
 * @param {number} y 
 * @param {string} color 
 * @returns {void}
 */
export const buildInBoundsMouse = (customCursor, x, y, color) => {
  const width = 30;
  const height = 30;
  customCursor.textContent = ""
  customCursor.style.transform = "rotate(0deg)"
  customCursor.style.position = "absolute";
  customCursor.style.left = `${x}px`;
  customCursor.style.top = `${y}px`;
  customCursor.style.zIndex = "1000";
  customCursor.style.width = `${width}px`;
  customCursor.style.height = `${height}px`;
  customCursor.style.backgroundColor = color;
  customCursor.style.borderRadius = " 0 50% 50% 50%";
  customCursor.style.opacity = "0.7";
  customCursor.style.stroke = "transparent";
}


/**
 * @description Builds out of bounds mouse
 * @param {*} customCursor 
 * @param {number} x Relative x
 * @param {number} y Relative y
 * @param {number} width Width of parent
 * @param {number} height height of parent
 * @param {string} color color of mouse
 * @param {{outUp: boolean, outRight: boolean, outDown: boolean, outLeft: boolean}} dirs directions that the mouse is out
 * @param {number} vetricalOffset any vertical offset
 */
export const buildOutOfBoundsMouse = (customCursor, x, y, width, height, color, dirs, vetricalOffset = 0) => {

  // Set position
  customCursor.style.position = "absolute";
  customCursor.style.borderRadius = "0"
  customCursor.style.display = "flex";
  customCursor.style.alignItems = "center";
  customCursor.style.justifyContent = "center";
  customCursor.textContent = "➤"
  customCursor.style.color = color
  customCursor.style.fontSize = "60px"
  customCursor.style.backgroundColor = "transparent";
  customCursor.style.left = `${x}px`;
  customCursor.style.top = `${y}px`;

  if (dirs.outLeft) {
    customCursor.style.left = `0px`;
    customCursor.style.transform = "rotate(180deg)"
  }

  if (dirs.outRight) {
    customCursor.style.left = `${width - 40}px`;
    customCursor.style.transform = "rotate(0deg)"
  }

  if (dirs.outUp) { 
    customCursor.style.top = `${vetricalOffset}px`;
    customCursor.style.transform = "rotate(-90deg)"
  }

  if (dirs.outDown) {
    customCursor.style.top = `${vetricalOffset+ height - 60}px`;
    customCursor.style.transform = "rotate(90deg)"
  }

  // Set size
  customCursor.style.width = `30px`;
  customCursor.style.height = `30px`;

  // Set color
  customCursor.style.opacity = 0.7
}


/**
 * @description gets first parent with an ID
 * @param {any} element 
 * @returns {any} parent
 */
export const getFirstParentWithId = (element) => {
  // Start with the parent element of the provided element
  let parent = element.parentElement;

  // Keep traversing up the DOM tree until a parent with an id is found or the root is reached
  while (parent && !parent.id) {
    parent = parent.parentElement;
  }

  // Return the found parent or null if none was found
  return parent;
}

/**
 * @description gets the relative position of a child within an ancestor
 * @param {any} child 
 * @param {any} ancestor 
 * @returns the relative x,y of the child within the ancestor 
 */
export const getNestedRelativePosition = (child, ancestor) => {
  // Base case: if the current child is the ancestor,
  // the absolute position within the ancestor is (0,0).
  if (child === ancestor) {
    return { x: 0, y: 0 };
  }

  // If we have reached the top of the document without finding the ancestor,
  // the ancestor is not an actual ancestor of the child.
  if (!child || child === document.body) {
    return { x: null, y: null };
  }

  // Get the position of the child within its parent.
  const childPos = { x: child.offsetLeft, y: child.offsetTop };

  // Recursively find the position of the parent within the ancestor.
  const parentPos = getNestedRelativePosition(child.offsetParent, ancestor);

  // Adjust the position by the current child's scroll offsets if any.
  // This is necessary if the child itself can scroll.
  const scrollAdjustment = {
    x: child.scrollLeft ? -child.scrollLeft : 0,
    y: child.scrollTop ? -child.scrollTop : 0,
  };

  // Add the current child's position to the parent's position to get the total.
  return {
    x: parentPos.x + childPos.x + scrollAdjustment.x,
    y: parentPos.y + childPos.y + scrollAdjustment.y
  };
}

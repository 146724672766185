import { getLocalUTCTimezone } from 'components/timezones/timezoneHelper';
import React, { useState, useEffect } from 'react';

export const ShowLocalTime = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  const localTime = time.toLocaleString(navigator.language, options);
  const timeZone = getLocalUTCTimezone();
  const timeZoneLink = `https://dayspedia.com/time/zones/${timeZone.toLowerCase()}/`
  return <div className="question-spacing">
    <label className="form-label">
    Verify your clock is correct
      </label> 
      <div className="description">
        The current time on your computer is <div style={{fontSize:18, paddingLeft:8}} className='badge bg-primary'>{localTime}</div> and your local timezone is <a target="_blank" href={timeZoneLink}>{timeZone}</a>. 
        If that is <b>not</b> the correct time, you must fix your computer time, or manually convert time zones. 
        {" "}<a target="_blank" href="https://support.microsoft.com/en-us/windows/how-to-set-your-time-and-time-zone-dfaa7122-479f-5b98-2a7b-fa0b6e01b261">PC Instructions</a>. 
        {" "}<a target="_blank"  href="https://support.apple.com/guide/mac-help/set-the-date-and-time-automatically-mchlp2996/">Mac Instructions</a>.
        {" "}⚠️ Times reported in this form and on this website will be with respect to this computer time (unless otherwise stated).
      </div>
      </div>
};

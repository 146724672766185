import { collection, getDocs, getFirestore, query, where } from "@firebase/firestore";
import { functions } from "firebaseApp";
import Swal from "sweetalert2";

const END_OF_SECTION_MINUTES = 10;
const START_OF_SECTION_MINUTES = 45;


export const hasHitMakeupLimit = (makeupData, ALLOWED_MAKEUPS) => {
  return "makeupCount" in makeupData && makeupData.makeupCount >= ALLOWED_MAKEUPS;
}

// week index must be 0 for the first week (to match the server)
export async function findMakeupSection(timeIndex,weekIndex,courseId,isMinor){
  // find the first section with the smallest number of members
  // and also has an active teacher
  const sectionRef = collection(getFirestore(), "sections")

  // Get all appropriate sections
  const sectionQuery = query(sectionRef,
    where("courseId", "==", courseId),
    where("timeIndex", "==", timeIndex),
    where("isMinor", "==", isMinor)
  );
  const sectionSnapshot = await getDocs(sectionQuery)
  const sectionDocs = sectionSnapshot.docs

  let bestSectionId = null
  let bestSectionSize = Infinity
  for (const doc of sectionDocs) {
    const sectionId = doc.id
    const section = doc.data()

    const nMembers = section.members.length
    const nGuests = getNumGuests(section, weekIndex)

    const meetingSize = nMembers + nGuests

    const isValid = nMembers > 2
    // determine if it is the section with the LEAST number of students
    if (isValid) {
      if (meetingSize < bestSectionSize) {
        bestSectionSize = meetingSize
        bestSectionId = sectionId
      }
    }
  }

  return bestSectionId
}

function getNumGuests(section, weekIndex) {
  if(!section?.guests) return 0
  const guests = section.guests
  if(!(weekIndex in guests)) return 0
  const guestList = guests[weekIndex]
  return guestList.length
}


export async function findAlternativeSection(realSectionId, timeIndex, weekIndex, courseId, isMinor) {
  // find the first section with the smallest number of members
  // and also has an active teacher
  const sectionRef = collection(getFirestore(), "sections")

  // Get all appropriate sections
  const sectionQuery = query(sectionRef,
    where("courseId", "==", courseId),
    where("timeIndex", "==", timeIndex),
    where("isMinor", "==", isMinor)
  );
  const sectionSnapshot = await getDocs(sectionQuery)
  const sectionDocs = sectionSnapshot.docs

  let bestSectionId = null
  let bestSectionSize = Infinity
  for (const doc of sectionDocs) {
    const sectionId = doc.id
    const section = doc.data()

    // Check to determine its not the student's section AND it has an active teacher
    const notCurrSection = section.sectionId !== realSectionId
    const nMembers = section.members.length
    const nGuests = getNumGuests(section, weekIndex)
    const isActive = section.isActive
    const sectionSize = nMembers + nGuests

    const isValid = notCurrSection && isActive && nMembers > 2
    // determine if it is the section with the LEAST number of students
    if (isValid) {
      if (sectionSize < bestSectionSize) {
        bestSectionSize = sectionSize
        bestSectionId = sectionId
      }
    }
  }
  return bestSectionId

}


export const joinSpecificZoom = async (sectionId, courseId, isSectionLeader, preJoin) => {
  try {
    const createZoomMeeting = functions.httpsCallable('createZoomMeeting');
    Swal.fire({
      title: 'Joining Zoom meeting...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    })

    const response = await createZoomMeeting({
      sectionId,
      courseId
    });
    if ('errorMessage' in response.data) {
      Swal.fire({
        title: 'Error',
        text: `Unable to join the zoom meeting: ${response.data.errorMessage}`,
        icon: 'error',
      })
      return
    }
    const { joinUrl, startUrl } = response.data;

    Swal.close();

    const zoomUrl = isSectionLeader ? startUrl : joinUrl
    
    if (zoomUrl) {
      // if this is a makeup section, record that
      await preJoin(zoomUrl)
      window.location.href = zoomUrl;
    } else {
      // Handle the case when the join URL is not provided
      Swal.fire({
        title: 'Error',
        text: 'Unable to join the zoom meeting, missing URL',
        icon: 'error',
      })
    }
  } catch (error) {
    console.error('Error calling createZoomMeeting:', error);
    Swal.fire({
      title: 'Error',
      text: `Unable to join the zoom meeting: ${error}`,
      icon: 'error',
    })
  }
};


export function hasActiveTeacher(section, currWeek) {
  const teacherAttendance = section.teacherAttendance
  return teacherAttendance && (currWeek in teacherAttendance) && teacherAttendance[currWeek]
}

/**
 * export async function findAlternativeSection(realSectionId, timeIndex, courseId, currWeek, isMinor) {
  // find the first section with the smallest number of members
  // and also has an active teacher
  const sectionRef = collection(getFirestore(), "sections")
  const sectionQuery = query(sectionRef, 
    where("courseId", "==", courseId),
    where("timeIndex", "==", timeIndex),
    where("isMinor", "==", isMinor)
  );
  const sectionSnapshot = await getDocs(sectionQuery)
  const sectionDocs = sectionSnapshot.docs
  
  let bestSectionId = null
  let bestSectionSize = Infinity
  for(const doc of sectionDocs) {
    const sectionId = doc.id
    const section = doc.data()

    // Check to determine its not the student's section AND it has an active teacher
    const notCurrSection = section.sectionId !== realSectionId
    const hasTeacher = hasActiveTeacher(section, currWeek)
    const nMembers = section.members.length
    const nGuests = 
    const isActive = section.isActive



    const isValid = hasTeacher && notCurrSection && isActive && nMembers > 2
    // determine if it is the section with the LEAST number of students
    if (isValid) {
      if(nMembers < bestSectionSize) {
        bestSectionSize = nMembers
        bestSectionId = sectionId
      }
    }
  }
  return bestSectionId

}


 */
import styled from "styled-components";
import { HorizontalSpeechBubble } from "./SpeechBubbles";
import { useNavigate } from "react-router";
import { ReactComponent as MissionIcon } from "icons/mission.svg";


export const LearningGoalsLesson = ({ data }) => {
  console.log('data', data)
  const goals = data?.learningGoals
  const iconPath = data?.imgUrl || '/karel192.png'
  return <FullContent>
    <CenteredContent>
      <div className="mb-3">
        <HorizontalSpeechBubble text="By the end of this lesson you will be able to:" iconPath={iconPath} />
      </div>
      {goals?.map((goal, i) => {
        return <ButtonCard
          key={goal.id}
          to={goal.value}
          text={goal.value}
          icon={<MissionIcon style={{ width: '50px', height: '50px' }} aria-hidden="true"/>}
        />
      })}

    </CenteredContent>
  </FullContent>
}

const ButtonCard = ({ text, icon, to }) => {

  const state = {
    from: '/welcome/choice'
  }
  // on click you should navigate to the href passed as "to"
  const navigate = useNavigate();
  return <ButtonCardOuter className="card mb-3">


    <ButtonCardInner>
      
      <div  style={{ width: 80, display: 'flex', justifyContent: 'center' }}>
        {icon}
      </div>
      <CardText>
        <h5 className="card-text">{text}</h5>
      </CardText>
    </ButtonCardInner>


  </ButtonCardOuter>
}

const CardText = styled.div`
  flex: 1;
  padding: 10px;
  `


const ButtonCardOuter = styled.div`
  max-width: 500px;
  text-align:left;
  width: 100%;
  `;

const ButtonCardInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;


const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width:100%;
`;
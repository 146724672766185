import { createContext, useRef, useState } from "react";




const defaultData = {
    startState: null,
    setStartState: (val) => { },
    worldState: null,
    setWorldState: (val) => { },
    karelSleepTime: 0.5,
    setKarelSleepTime: (val) => { },
    terminalRef: null,
    graphicsState: null,
    setGraphicsState: (val) => { },
    isStepping: false,
    setIsStepping: (val) => { },
    stepPtr: 0,
    stepList: [],
    stepLine: -1,
    stepLogs: [],
    setStepPtr: (val) => { },
    setStepList: (val) => { },
    setStepLine: (val) => { },
    setStepLogs: (val) => { }

}


export const CodeExampleContext = createContext(defaultData)


export const CodeExampleProvider = ({ children }) => {
    const [startState, setStartState] = useState(null)
    const [worldState, setWorldState] = useState(null)
    const [karelSleepTime, setKarelSleepTime] = useState(0.5)
    const [graphicsState, setGraphicsState] = useState(null)
    const [isStepping, setIsStepping] = useState(false)
    const [stepPtr, setStepPtr] = useState(0)
    const [stepList, setStepList] = useState([])
    const [stepLine, setStepLine] = useState(-1)
    const [stepLogs, setStepLogs] = useState([])
    const terminalRef = useRef(null);


    return (
        <CodeExampleContext.Provider value={{ 
            startState, 
            setStartState, 
            worldState, 
            setWorldState, 
            karelSleepTime, 
            setKarelSleepTime, 
            terminalRef, 
            graphicsState, 
            setGraphicsState, 
            isStepping, 
            setIsStepping,
            stepPtr,
            setStepPtr,
            stepList,
            setStepList,
            stepLine,
            setStepLine,
            stepLogs,
            setStepLogs
        }}>
            {children}
        </CodeExampleContext.Provider>
    )
}
const systemPrompt = `You are evaluating potential TAs for an introductory computer programming course. You job is to assess them on two critical learning goals: 1. They are welcoming and respectful For this learning goal it is absolutely imperative that the applicant is welcoming and extrememly kind and respectful. 2. They give a hint without giving away the answer. For this learning goal, it is crucial that the TA does not give away the answer. To meet this, they should ask a question or walk through the code but they should NOT just tell the student how to fix it.  

Your task is to filter and evaluate students until they are ready to help students in a respectful and helpful way. You should ensure the potential TA meets a very high standard for both learning goals. It would be terrible if we let a TA who was rude or condescending to students help out in the course. It would also be terrible if we let a TA who just gave away the answer help out in the course. Their response cannot just be mediocre, it must be overtly welcoming and respectful. It must also be a hint that helps the student learn and think for themselves. If it is missing either of these, it is not good enough. If it has them both but not to the standard we need, it is not good enough. If it meets both of these standards, it is good enough.

We will give as input a student's buggy code and message for what the TA would say to this student. You should return a JSON with three keys: 'learning_goal_1', 'learning_goal_2', and 'feedback'. Each of the learning goal keys should map to a score between 0 and 1 for how well the response meets each learning goal as well as a response with an explanation for why you chose this score. A 1 for learning goal 1 would mean this is the kindest most respectful possible way to help out this student. A 0 would mean the applicant was rude,condescending, or otherwise very unwelcomning. Your scores should almost always be some number between a 0 and 1. A 0 for learing goal 2 would mean that the applicant direclty told them the answer and they have to do no independent thought. A 1 would mean they made the most perfect teaching move to help the student learn and do all the independent thinking themselves. Your output should almost always be between a 0 and 1. Please write a thorough exlanation for how you came to this score given the applicants response. The 'feedback' key should be feedback to the applicant on their response. Pick one positive thing to say about their response and one way they could improve. Output should be in this JSON parsable format: {"learning_goal_1":    { "score": score,      "explanation": explanation}, "learning_goal_2":    { "score": score,      "explanation": explanation}, "feedback": feedback} and MUST be formatted as a JSON that can be parsed with JSON.parse. YOU MUST FORMAT THE OUTPUT AS A JSON WITH THE CORRECT JSON SYNTAX.`

const createInnerPrompt = (buggyCode, applicantResponse) => {
    return "Student's buggy code:\n```\n" + buggyCode + "\n```\nTA's response to student:\n```\n" + applicantResponse + "\n```\n";
}

export const createMessages = (buggyCode, applicantResponse) => {
    const innerPrompt = createInnerPrompt(buggyCode, applicantResponse);
    return [{ role: 'system', content: systemPrompt }, { role: 'user', content: innerPrompt }];
}

function fixJSON(jsonString) {
    // This regular expression finds commas that are followed by a closing brace (})
    // or closing bracket (]) with optional whitespace in between.
    const regex = /\,(?=\s*[\}\]])/g;
    const fixedStr = jsonString.replace(regex, '');
    return fixedStr;
}

export const checkResponse = (response) => {
    let jsonResponse = ''
    // first check if it is a valid json 
    try {
      jsonResponse = JSON.parse(response);
    } catch (error) {
      console.log("Error parsing JSON", error)
      const fixedStr = fixJSON(response)
        try {
            console.log("Trying to parse again after removing trailing commas")
            jsonResponse = JSON.parse(fixedStr);
        } catch (error) {
            console.log("Errored still after removing trailing commas", error)
            return false;
        }
    }
    // now check that it has keys: learning_goal_1 and learning_goal_2
    const keys = Object.keys(jsonResponse);
    if (!keys.includes('learning_goal_1') || !keys.includes('learning_goal_2') || !keys.includes('feedback')) {
      return false;
    }
    const lg1 = jsonResponse['learning_goal_1'];
    const lg2 = jsonResponse['learning_goal_2'];
    const lg1Keys = Object.keys(lg1);
    const lg2Keys = Object.keys(lg2);
    if (!lg1Keys.includes('score') || !lg1Keys.includes('explanation') || !lg2Keys.includes('score') || !lg2Keys.includes('explanation')) {
      return false;
    }
    return jsonResponse;
  }

export const THRESHOLD = 0.8 // The threshold for the applicant score to be considered correct

export const parseResponse = (response) => {
    const lg1 = response['learning_goal_1'];
    const lg2 = response['learning_goal_2'];
    const lg1Score = lg1['score'];
    const lg2Score = lg2['score'];
    const lg1Explanation = lg1['explanation'];
    const lg2Explanation = lg2['explanation'];
    const gptFeedback = response['feedback']
    const passedEval = lg1Score >= THRESHOLD && lg2Score >= THRESHOLD;
    return {
      lg1Score,
      lg1Explanation,
      lg2Score,
      lg2Explanation,
      gptFeedback,
      passedEval
    }
  }
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

export const TodoStep = ({ text, detail = "", successCond = null, href, icon, onClick=()=>{} }) => {
  return (
    <div className="row">
      <div className="col">
        <div className="d-flex justify-content-center w-100">
          <div className="card mb-3 " style={{ width: "540px" }}>
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="card-body">
                  <Link to={href} onClick={onClick}>
                    <h4 style={{margin:0}}>
                      {icon}
                      {text}
                      {successCond ? (
                        <FaCheck
                        />
                      ) : null}
                    </h4>
                  </Link>
                  <span className="todoDetail">{detail}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
import { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useComponentSize } from "react-use-size";
import { useNavigate } from "react-router";
import { useCourseId } from "hooks/router/useUrlParams";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CalendarContext, CalendarProvider } from "./CalendarContext";
import { ProfileContext } from "contexts/ProfileContext";
import { CourseContext } from "contexts/CourseContext";
import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";



const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
// must be a sunday

const eventHeight = 35
const eventPaddingX = 7;
const eventPaddingY = 9;
const lowerEventTop = eventHeight + eventPaddingY * 2;
const cellHeight = eventHeight * 2 + eventPaddingY * 3;
const borderColor = "#aaa"
const todoListEventColor = 'rgb(208, 224, 255)'
const todayHeaderBackground = '#EFF1FC'
const minCellWidth = 0

export const Calendar = ({ events, startWeek,isStudentCal, firstCalendarDay = 23, nWeeks }) => {
  return <CalendarProvider startWeek={startWeek} isStudentCal={isStudentCal} events={events} firstCalendarDay={firstCalendarDay}>
    <CalendarInner />
  </CalendarProvider>
}

const CalendarInner = ({ }) => {
  const { ref, width, height } = useComponentSize()

  const { currWeek, setCurrWeek, firstDay, nWeeks } = useContext(CalendarContext)


  const setCurrWeekSafe = (newWeek) => {
    if (newWeek < 0) { return }
    if (newWeek > nWeeks) { return }
    setCurrWeek(newWeek)
  }



  const daysSinceStart = currWeek * 7
  const weekFirstDay = new Date(firstDay);
  weekFirstDay.setDate(firstDay.getDate() + daysSinceStart);
  weekFirstDay.setMinutes(0)
  weekFirstDay.setHours(0)
  weekFirstDay.setSeconds(0)
  weekFirstDay.setMilliseconds(0)


  return <>
    {/* <img src={prototypeImg} className="w-100"/> */}
    <div style={calendarOuterStyle} className="w-100" ref={ref} id="calendar-home" aria-label={`Calendar week ${currWeek}`}>
      <ButtonBar currWeek={currWeek} setCurrWeek={setCurrWeekSafe} />
      <div style={tableOuterStyle}>
        <TableHeader weekFirstDay={weekFirstDay} />
        <Events calendarWidth={width} weekFirstDay={weekFirstDay} />
        <TableBody />
      </div>
    </div>
  </>
}

const Events = ({ calendarWidth, weekFirstDay }) => {
  const { userData, sectionData } = useContext(ProfileContext)
  const {isStudentCal} = useContext(CalendarContext)

  
  const courseId = useCourseId()

  const { events } = useContext(CalendarContext)
  const userTz = getLocalUTCTimezone()

  const { firstSectionTimestamp, getFirstDayOfSection} = useContext(CourseContext)
  const [firstDayOfSection, setFirstDayOfSection] = useState(null)
  const [sectionEvents, setSectionEvents] = useState([])


  useEffect(() => {
    // TODO @miranda: section events are empty for some reason -- need to debug
    if (!userData || !sectionData) return
    const sectionId = userData?.courseSections?.[0]?.id
    const section = sectionData[sectionId]
    if (firstSectionTimestamp) {
      const firstDay = getFirstDayOfSection(userTz, section?.timeIndex)
      setFirstDayOfSection(firstDay)
      setSectionEvents(prev => getSectionEvents(userData, sectionData, courseId, firstDay))
    }
  }, [firstSectionTimestamp, sectionData])

  
  let allEvents = [...events, ...sectionEvents]


  return <>
    

    {
      allEvents.map((event, i) => {
        return <Event
          event={event}
          key={`${event.key}-${event.start}`}
          calendarWidth={calendarWidth}
          weekFirstDay={weekFirstDay}
        />
      })
    }
    {
      isStudentCal && <>
        <MaterialsReleasedEvent calendarWidth={calendarWidth}/>
        <LearningGoalEvent calendarWidth={calendarWidth}/>
      </>
    }
  </>
}

const Event = ({ event, calendarWidth, weekFirstDay }) => {

  const { currWeek, firstDay, getDayHeightIndex, registerEvent } = useContext(CalendarContext)
  const [heightIndex, setHeightIndex] = useState(0)

  const navigate = useNavigate()

  const weekLastDay = new Date(weekFirstDay.getTime() + 1000 * 60 * 60 * 24 * 7);
  const startStr = event.start
  const startDate = new Date(startStr)
  const weekDayIndex = startDate.getDay();

  const weekIndex = Math.floor((startDate - firstDay) / (1000 * 60 * 60 * 24 * 7))

  useEffect(() => {
    setHeightIndex(getDayHeightIndex(weekIndex, weekDayIndex))
    registerEvent(weekIndex, weekDayIndex, 1)
  }, [])

  // draw the section event
  if (calendarWidth <= 0) { return <></> }

  const navigateToEvent = () => {
    navigate(event.to)
  }

  // check if its in the week range
  if (startDate < weekFirstDay) { return <></> }
  if (startDate > weekLastDay) { return <></> }



  let hourString = startDate.toLocaleString('en-US', { hour: 'numeric', hour12: true }); // format the time as "4pm" using the "en-US" locale
  hourString = hourString.replace(" AM", "a")
  hourString = hourString.replace(" PM", "p")
  const showTime = 'showTime' in event ? event.showTime : true
  if (!showTime) {
    hourString = ""
  }


  const length = event.length || 1
  const eventTop = heightIndex * eventHeight + eventPaddingY * (heightIndex + 1);

  const cellWidth = calendarWidth / 7;
  const left = eventPaddingX + cellWidth * weekDayIndex;
  const width = cellWidth * length - eventPaddingX * 2;
  const backgroundColor = event.isLight ? 'rgb(208, 224, 255)' : ''
  const style = {
    ...eventStyleBase,
    marginTop: `${eventTop}px`,
    marginLeft: `${left}px`,
    width: `${width}px`,
    color: event.isLight ? 'black' : 'white',
    cursor: 'pointer',
    lineHeight: '14px',
    backgroundColor
  }
  let className = "d-flex justify-content-center align-items-center"
  if (!event.isLight) {
    className += " bg-primary"
  }
  const content = <div onClick={navigateToEvent} className={className} style={style}>
    {cellWidth > 70 ? `${event.titleShort} ${hourString}` : event.titleMobile}
  </div>

  return <ToolTipEvent content={content} tooltip={event.tooltip} />


}

function getSectionEvents(userData, sectionData,courseId, firstDayOfSection) {

  const sectionId = userData?.courseSections?.[0]?.id
  if (!sectionId) return []

  const section = sectionData[sectionId]
  if(section == undefined) return []
  if(section?.timeIndex == undefined) return []
  if(section?.timeIndex == "none") return []
  const startTime = firstDayOfSection
  
  const nSections = 6
  const events = []
  for (let i = 0; i < nSections; i++) {
    // add i weeks to the start time
    const sectionEvent = {
      titleShort: "Section",
      titleMobile: "Sect.",
      title: "Section",
      key:`section-${i}`,
      start: startTime.toISOString(),
      tooltip: 'Weekly discussion section',
      to: `/${courseId}/section`
    }
    events.push(sectionEvent)
    startTime.setDate(startTime.getDate() + 7)
  }

  return events
}

const MaterialsReleasedEvent = ({  calendarWidth }) => {
  const {currWeek, nWeeks} = useContext(CalendarContext)
  // draw the materials released event
  if (calendarWidth <= 0) { return <></> }
  if (currWeek >= nWeeks) { return <></> }

  const cellWidth = calendarWidth / 7;
  const left = cellWidth + eventPaddingX;
  const width = cellWidth - eventPaddingX * 2;
  const height = eventHeight * 2 + eventPaddingY;
  const style = {
    ...eventStyleBase,
    height: `${height}px`,
    marginTop: `${eventPaddingY}px`,
    marginLeft: `${left}px`,
    width: `${width}px`,
    backgroundColor: todoListEventColor
  }


  const content = <div className="d-flex justify-content-center align-items-center" style={style}>
    <span>
      {cellWidth > 70 && <span>{getWeekReleaseEventStr(currWeek + 1)}</span>}
      <span className="ml-1">🚀</span>
    </span>
  </div>

  return <ToolTipEvent content={content} tooltip="Materials are released Monday, Stanford time" />
}

const LearningGoalEvent = ({calendarWidth}) => {
  const {currWeek, nWeeks} = useContext(CalendarContext)
  // draw the long event for working on learning goals
  if(calendarWidth <=0) {return <></>}
  if(currWeek > nWeeks) {return <></>}
  const eventLength = 5
  const cellWidth = calendarWidth / 7;
  let left = cellWidth*2 + eventPaddingX;
  let width = cellWidth * eventLength - eventPaddingX * 2;
  
  var eventStr = `Complete Week ${currWeek+1} Tasks`
  if(currWeek == nWeeks) {
      eventStr = `Work on your final project`
      left -= cellWidth 
      width += cellWidth 

  }

  const style = {
    ...eventStyleBase,
    marginTop:`${lowerEventTop}px`,
    marginLeft: `${left}px`,
    width: `${width}px`,
    backgroundColor:todoListEventColor
}

  const content = <div className="d-flex justify-content-center align-items-center" style={style}>
     {eventStr}
   </div>

   return <ToolTipEvent content={content} tooltip={`Complete Week ${currWeek+1} Tasks by Sunday`}/>
}

const ButtonBar = ({ currWeek, setCurrWeek }) => {
  const { nWeeks } = useContext(CalendarContext)
  let displayWeek = currWeek + 1
  if (nWeeks >= 8) {
    displayWeek = currWeek
  }
  return <div className="d-flex justify-content-end align-items-center">
    <span style={{ marginRight: 5, marginTop: 1 }}>Week {displayWeek} </span>
    <button
      className="btn btn-sm btn-light"
      onClick={() => setCurrWeek(currWeek - 1)}
      aria-label="Previous week"
    >
      <FaArrowLeft />
    </button>
    <button
      className="btn btn-sm btn-light"
      onClick={() => setCurrWeek(currWeek + 1)}
      aria-label="Next week"
    >
      <FaArrowRight />
    </button>
  </div>
}

const TableHeader = ({ weekFirstDay }) => {

  return <div style={tableHeaderRow}>
    {days.map((day, index) => {
      // add index number of days to currDay
      const date = new Date(weekFirstDay);
      date.setDate(weekFirstDay.getDate() + index);
      return <HeaderCell day={day} key={index} date={date} />
    })}
  </div>
}

const TableBody = () => {
  return <div style={tableBodyRow}>
    {days.map((day, index) => <DayBodyCell key={index} day={day} index={index} />)}
  </div>
}

const HeaderCell = ({ day, date }) => {

  const size = useComponentSize()

  // gets the month name in three chars from the date
  const month = date.toLocaleString('default', { month: 'short' });

  // special style if its today
  const isToday = date.toDateString() == new Date().toDateString();

  const hasBreak = size.width < 80

  const style = {
    ...headerStyle,
    backgroundColor: isToday ? todayHeaderBackground : 'rgba(0,0,0,0)',
    color: isToday ? 'grey' : '#959595',
  }

  return <div ref={size.ref} style={style}>
    {day} {hasBreak && <br />}{month} {date.getDate()}
  </div>
}

const DayBodyCell = ({ day, index }) => {
  const { maxHeight } = useContext(CalendarContext)
  const bodyStyle = {
    ...cellStyle,
  }
  if (index != 0) {
    bodyStyle.borderLeft = `1px solid ${borderColor}`
  }
  const nEvents = Math.max(2, maxHeight)
  const height = eventHeight * nEvents + eventPaddingY * (nEvents + 1);
  bodyStyle.height = `${height}px`
  return <div style={bodyStyle}>
  </div>
}

const ToolTipEvent = ({ content, tooltip }) => {
  return <OverlayTrigger
    placement="bottom"
    delay={{ show: 100, hide: 100 }}
    overlay={(props) => (
      <Tooltip id="button-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    )}
  >{content}</OverlayTrigger>
}


const getWeekReleaseEventStr = (week) => {
  switch (week) {
    case 1: return <>Karel Control Flow</>
    case 2: return <>Karel The Art of Coding</>
    case 3: return <>Console Programs</>
    case 4: return <>Python Control Flow</>
    case 5: return <>Graphics</>
    case 6: return <>Lists + Dicts</>
    case 7: return <>Understanding Variables</>
  }
  return `Week ${week} Materials`
}


const headerStyle = {
  textAlign: "center",
  width: "14.28%",
  fontWeight: 700,
  color: '#959595',
  // letterSpacing: '1px',
  fontSize: "0.8rem",
  textTransform: 'uppercase',
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: "5px",
  minWidth: `${minCellWidth}px`
}

const cellStyle = {
  // border: "1px solid grey",
  height: `${cellHeight}px`,
  flex: 1,
  minWidth: `${minCellWidth}px`
}

const eventStyleBase = {
  textAlign: "center",
  position: "absolute",
  height: `${eventHeight}px`,
  zIndex: 1,
  borderRadius: "5px",
  fontSize: "0.9rem",
  cursor: 'pointer',
}

const tableOuterStyle = {
  border: `1px solid ${borderColor}`,
  borderRadius: '10px',
  position: 'relative',
}

const tableBodyRow = {
  // boxShadow: `0 0 0 1px ${borderColor}`,
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  width: '100%',
  display: 'flex',
}

const calendarOuterStyle = {
  // marginTop: '-10px'
}

const tableHeaderRow = {
  // boxShadow: `0 0 0 1px grey`,
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  borderBottom: `1px solid ${borderColor}`,
}
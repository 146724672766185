import { LandingNav } from "landing/components/LandingNav"
import Loading from "react-loading"
import { TeachingDemoView } from "./TeachingDemoView"
import { useUploadedVideo } from "./useUploadedVideo"
import { useUserId } from "hooks/user/useUserId"


// @TODO: 
// Note to future developers. Save the URL of the video please :-)

export const TeachingDemo = () => {
    return  <div>
    <LandingNav applicationType={"sectionLeader"} subpage={"Teaching Demo"}/>
    <div
      style={outerStyle}
    >
      <TeachingDemoInner/>
    </div>
  </div>
}

const TeachingDemoInner = () => {
  const userId = useUserId()
  const [videoUrl, isLoading, error] = useUploadedVideo(userId)
  

  if(isLoading) {
    return <Loading/>  
  }

  const serverData = {
    videoUrl: videoUrl,
    error: error
  }

  return <TeachingDemoView serverData={serverData}/>
}

const outerStyle = {
    width: "100vw",
    height:'calc(100vh - 57px)'
}
export const studentHomeUiTraining = [
  {
    title: "Welcome",
    intro: "Let me take you around!",
  },
  {
    title: "Todo List",
    element: "#todo-container",
    intro: "Your step-by-step path to learning to code.",
  },
  {
    title: "Lessons",
    element: "#nav-learn",
    intro: "Find the readings and lessons.",
  },
  {
    title: "Code",
    element: "#nav-code",
    intro: "See all your code and make new projects.",
  },
  {
    title: "Discussion Forum",
    element: "#nav-forum",
    intro: "Ask questions and find answers.",
  }
];


export function filterTraining(trainingList, userAge) {
  // remove the element with title: "Discussion Forum" if the user is under 16
  let training = trainingList
  if (userAge < 16) {
    training = training.filter((step) => step.title !== "Discussion Forum")
  }
  return training
}
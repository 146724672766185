import { useEffect } from 'react';
import useState from 'react-usestateref';
import { ResizableBox } from 'react-resizable';

import { NodeViewWrapper } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import Swal from "sweetalert2";
import styled from 'styled-components';
import { FaPause, FaPlay, FaVideo } from 'react-icons/fa';

export const ResizableImage = Node.create({
  name: 'resizableImage',

  group: 'block',
  
  inline: false,
  
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      width: {
        default: 'auto',
      },
      height: {
        default: 'auto',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'resizableImage',
        getAttrs: dom => ({
          src: dom.getAttribute('src'),
          alt: dom.getAttribute('alt'),
          width: dom.style.width || 'auto',
          height: dom.style.height || 'auto',
        }),
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ResizableImageView)
  },
})

const ResizableImageView = (props) => {

  const [isGif, setIsGif] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: parseInt(props.node.attrs.width, 10) || 'auto',
    height: parseInt(props.node.attrs.height, 10) || 'auto',
  });
  // we should not need this. workaround beacuse "editable" is not reactive
  const [isEditable, setIsEditable, editableRef] = useState(props.editor.isEditable)
  useEffect(() => {
    props.editor.on('transaction', () => {
        if(props.editor.isEditable != editableRef.current) {
          setIsEditable(props.editor.isEditable)
        }
      })
  }, [])


  useEffect(() => {
    const checkIfGif = async (url) => {
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const bytes = new Uint8Array(arrayBuffer.slice(0, 3));
        // Convert the bytes to an ASCII string and check if it matches 'GIF'
        const header = String.fromCharCode(...bytes);
        setIsGif(header === 'GIF');
      } catch (error) {
        console.error('Error checking image format:', error);
        setIsGif(false);
      }
    };

    checkIfGif(props.node.attrs.src);
  }, [props.node.attrs.src]);

  const handleResize = (event, { size }) => {
    const { width, height } = size;

    setDimensions({ width, height });

    props.updateAttributes({
      width: `${width}`,
      height: `${height}`,
    });
  };

  

  
  if(!isEditable && isGif) {
    return <PausableGif props={props} dimensions={dimensions}/>
  }

  if(!isEditable) {
    return <NodeViewWrapper>
    <div style={getOuterStyle(dimensions)}>
      <StyledImg 
        src={props.node.attrs.src} 
        alt={props.node.attrs.alt} 
        width={props.node.attrs.width}
        height={props.node.attrs.height}
      />
    </div>
  </NodeViewWrapper>
  }

  const handleChangeAltText = async (event) => {
    const {value: newAltText} = await Swal.fire({
      title: "Change Alt Text",
      input: "text",
      inputLabel: "Alt Text",
      inputValue: props.node.attrs.alt,
      showCancelButton: true,
    })
    props.updateAttributes({
      alt: newAltText,
    });
  }

  // get the parent width and height
  const parentWidth = props.editor.view.dom.parentNode.offsetWidth;

  const aspectRatio = dimensions.width / dimensions.height;
  const displayWidth = Math.min(parentWidth,dimensions.width);
  const displayHeight = displayWidth / aspectRatio;

  return (
    <NodeViewWrapper>
      <ResizableBox 
        width={displayWidth} 
        height={displayHeight} 
        onResize={handleResize}
      >
        <img 
          src={props.node.attrs.src} 
          alt={props.node.attrs.alt} 
          style={{ width: '100%', height: '100%' }} 
        />
        <AltTextButton 
          onClick={handleChangeAltText}
          className="btn btn-primary btn-sm"
        >
          Change Alt Text
        </AltTextButton>
      </ResizableBox>
    </NodeViewWrapper>
  );
};

const PausableGif = ({props, dimensions}) => {
  // State to manage whether the GIF is paused or not
  const [isPaused, setIsPaused] = useState(false);
  // State to manage whether the mouse is over the GIF
  const [isHovered, setIsHovered] = useState(false);

  console.log('isPaused', isPaused);

  // Function to toggle the GIF pause state
  const toggleGif = () => {
    setIsPaused(!isPaused);
  };

  // Define styles for the GIF container
  const getOuterStyle = (dimensions) => ({
    width: dimensions.width, // Use dimensions from props
    height: dimensions.height, // Use dimensions from props
    position: 'relative', // Needed to position the pause icon correctly
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  // Define styles for the pause icon overlay
  const pauseIconStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)', // Semi-transparent black
    color: 'white',
    fontSize: '2em', // Adjust the size of the pause icon
    cursor: 'pointer',
    visibility: isHovered && !isPaused ? 'visible' : 'hidden', // Show only when hovered and not paused
  };

  // Define styles for the pause icon overlay
  const playIconStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white', // Semi-transparent black
    border: '1px solid black',
    borderRadius: '5px',
    color: 'black',
    fontSize: '2em', // Adjust the size of the pause icon
    cursor: 'pointer',
    visibility: isPaused ? 'visible' : 'hidden', // Show only when hovered and not paused
  };

  return (
    <NodeViewWrapper>
      <div 
        style={getOuterStyle(dimensions)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={toggleGif}
      >
        
          <>
            <StyledImg
              src={props.node.attrs.src} 
              alt={props.node.attrs.alt}
              width={props.node.attrs.width}
              height={props.node.attrs.height}
              style={{ cursor: 'pointer', width: '100%', height: '100%' }} // Ensure the image fills the container
            />
            <div style={pauseIconStyle}><FaPause/></div> {/* This is the pause icon overlay */}
            <div style={playIconStyle}><FaPlay/></div>
          </>
        
      </div>
    </NodeViewWrapper>
  );
};


const PausedImg = ({toggleGif}) => {
  return <button 
    className="btn btn-light btn-sm w-100"
    onClick={toggleGif}
  ><FaPlay/> Play Gif</button>
}

function getOuterStyle(dimensions) {
  return {
    width: `min(100%, ${dimensions.width}px`, 
    height: `min(100%, ${dimensions.height}px`,
  }
}

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const AltTextButton = styled.button`
  margin-top: -4rem;
  margin-left: 5px;
`

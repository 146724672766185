import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";

import "./ResolveButton.css";

export function ResolveButton(props: {
  isResolved: boolean;
  canMarkResolved: boolean;
  onClick: () => Promise<void>;
}) {
  const { onClick, canMarkResolved, isResolved } = props;
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Mark Resolved
        </Tooltip>
      )}
    >
      <span>
        <FaCheck
          onClick={onClick}
          size={32}
          className="markResolvedButton"
          color={isResolved ? "#198754" : "#bbbbbb"}
        />{" "}
      </span>
    </OverlayTrigger>
  );
}

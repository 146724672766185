import { useNavigate, Link } from 'react-router-dom';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { ProfilePicEditor } from "./editors/ProfilePicEditor"
import { FormixFormWithData } from "components/Forms/FormixFormWithData";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Role } from "types/role";
import * as Yup from "yup";
import { roleToDisplayString } from "contexts/profile_util";
import { useCourseId } from 'hooks/router/useUrlParams';
import { FaEdit, FaEye } from 'react-icons/fa';
import { 
  NameInput, 
  AboutMeInput, 
  ConnectionsConsentCheckbox, 
  GoalsInput,
} from "./ProfileInputs"
import { PortfolioInput } from "./editors/PortfolioInput";
import { CountryInput } from "./editors/CountryInput";
import { Spacer } from "components/layout/Spacer";

export const Profile = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<ProfileMain />}
      rightColumn={<></>}
      singleColumn={<ProfileMain />}
    />
  );
}

const ProfileMain = () => {
  const {userData, setUserData} = useContext(ProfileContext);
  const courseId = useCourseId()
  const hasConnections = courseId === 'cip4' // TODO miranda!
  let courseRoleEnum = userData.courseRole
  if(courseId === "public" && courseRoleEnum === Role.UNREGISTERED) {
    courseRoleEnum = Role.STUDENT
  }
  const courseRole = roleToDisplayString(courseRoleEnum)
  const handleSubmit = async (values) => {
    // we are using autoSave, so this is called automatically when anything in the form changes.
    // debouncing is done by the form itself in FormixFormWithData -> AutoSubmitToken
    await setUserData(values)
  }
  const navigate = useNavigate()
  return <div className="mt-4">
    <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
    <h4>Profile</h4>
    <Link to={`/${courseId}/user/${userData.id}`} className="btn btn-primary">
      <FaEye/>&nbsp;View public profile
    </Link>
    </div>

    <label className="form-label">Profile Picture</label>
    <ProfilePicEditor />

    <FormixFormWithData
      serverData={userData}
      backUrl={null}
      formFormat={hasConnections ? formStructureWithConnections : preferencesFormStructure}
      onSubmit={(e, cb) => handleSubmit(e)}
      autoSave={true}
    />

    <hr/>

    <h4>Account</h4>
    <label className="form-label">
      Course role
    </label>
    <span>{courseRole}</span>
    <label className="form-label">
      Email
    </label>
    <span>{userData.email} <button onClick={() => navigate(`/${courseId}/changeemail`)} className="btn btn-light "><FaEdit/></button></span>
    <label className="form-label">Password</label>
      <button onClick={() => navigate(`/${courseId}/changepassword`)} className="btn btn-light">Change password</button>
    
    <Spacer/>
  </div>
}

const preferencesFormStructure = [
  {
    name: "displayName",
    input: NameInput,
    default:'',
    validation: Yup.string()
      .min(3, "Display Name must be at least 3 characters long")
      .required("Display Name is required"),
  },
  {
    name:"country",
    input: CountryInput,
    validation: Yup.string()
      .required("Country is required"),
  },
  {
    name:"portfolio",
    input: PortfolioInput
  },
]

const formStructureWithConnections= [
  {
    name: "displayName",
    input: NameInput,
    default:'',
    validation: Yup.string()
      .min(3, "Display Name must be at least 3 characters long")
      .required("Display Name is required"),
  },
  {
    name:"country",
    input: CountryInput,
    validation: Yup.string()
      .required("Country is required"),
  },
  {
    name:"wantToConnect",
    default:true,
    input: ConnectionsConsentCheckbox,
    validation: Yup.boolean()
  },
  {
    name:"aboutMe",
    default:'',
    input:AboutMeInput,
    validation: Yup.string()
  },
  {
    name:"goals",
    default:'',
    input: GoalsInput,
    validation: Yup.string()
  },
  // TODO: uncomment when portfolio bug is fixed--
  // for users in multiple courses, these links don't work for all but the course in which they set the portfolio
  // {
  //   name:"portfolio",
  //   input: PortfolioInput
  // },
]
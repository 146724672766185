import { useState } from "react"
import Confetti from "react-confetti"

export const useConfetti = ({ windowSize }) => {
  const [confettiState, setConfettiState] = useState('off')

  function fireConfetti() {
    setConfettiState('on')
    // turn off the confetti after 5 seconds
    setTimeout(() => {
      setConfettiState('finishing')
    }, 4000)

  }

  const confettiParticles = <Confetti
    run={confettiState !== 'off'}
    width={windowSize.width}
    height={windowSize.height}
    recycle={confettiState === 'on'}
    numberOfPieces={500}

  />

  return { confettiParticles, fireConfetti }
}
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { useCourseId } from "hooks/router/useUrlParams";
import { Link } from "react-router-dom";

export const AboutCourse = ({ }) => {
  return <CoursePageBodyContainer
    mainColumn={<AboutMain />}
    rightColumn={<></>}
    singleColumn={<AboutMain />}
  />
}

export const AboutMain = () => {
  const courseId = useCourseId()
  return <div className="mt-3">
    <h1>General Information</h1>
    <p>
      If you have any questions after reading this Syllabus, head on
      over to our <Link to={`/${courseId}/forum`}>Discussion Forum</Link>.
    </p>
    <hr />
    <AboutHandoutContent />
  </div>
}

const AboutHandoutContent = () => {
  const courseId = useCourseId()
  const forumLink = `${courseId}/forum`
  const tipTapPath = `course/${courseId}/handouts/aboutcourse`
  return <div>
    <p><b>Code in Place</b> is a free introductory programming course using the Python language, based on material from the first half of Stanford University's established intro course, CS106A.

      It assumes no prior programming experience.
      You won't receive a grade in the course, and completing it doesn't earn university credit.
      Instead, the main outcome is that you will have acquired a new and wonderful skill: how to program.</p>

    <h3>Teaching Team</h3>

    <div className="row mb-2">
      <div className="col-md-4 col-6 mb-3">
        <div className="card">
          <div className=" card-body">
            <div className="">
              <img src={process.env.PUBLIC_URL + "/people/chris.jpg"} className="w-100" alt="Chris Piech" />
            </div>
            <div className="p-0" >
              <p className="card-text">
                <b>Chris Piech</b> is a professor of Code in Place
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-6">
        <div className="card">
          <div className=" card-body">
            <div className="">
              <img src={process.env.PUBLIC_URL + "/people/mehran.jpg"} className="w-100" alt="Mehran Sahami" />
            </div>
            <div className="p-0" >
              <p className="card-text">
                <b>Mehran Sahami</b> is a professor of Code in Place
              </p>
            </div>
          </div>
        </div>
      </div>


    </div>

    
    <AdminRichTextEditor
      firebaseDocPath={tipTapPath}
    />
    <hr/>
    <div style={{height:20}}/>
  </div>

  
}

import { createContext } from "react";
import { useState } from "react";


type FiltersContextType = {
    showFilters: boolean,
    setShowFilters: (show: boolean) => void,
    tagFilters: string[],
    setTagFilters: (tags: string[]) => void,
    hideResolved: boolean,
    toggleHideResolved: () => void,
    flaggedOnly: boolean,
    toggleFlaggedOnly: () => void
}

const defaultData = {
    showFilters: false,
    setShowFilters: (show: boolean) => {},
    tagFilters: [],
    setTagFilters: (tags: string[]) => {},
    hideResolved: false,
    toggleHideResolved: () => {},
    flaggedOnly: false,
    toggleFlaggedOnly: () => {}
}


export const FiltersContext = createContext<FiltersContextType>(defaultData);

export const FiltersProvider = ({children}) => {
    const [showFilters, setShowFilters] = useState(false);
    const [tagFilters, setTagFilters] = useState<string[]>([]);
    const [hideResolved, setHideResolved] = useState(false);
    const [flaggedOnly, setFlaggedOnly] = useState(false);

    const toggleFlaggedOnly = () => {
        setFlaggedOnly(!flaggedOnly);
    }

    const toggleHideResolved = () => {
        setHideResolved(!hideResolved);
    }

    return (
        <FiltersContext.Provider value={{showFilters, setShowFilters, tagFilters, setTagFilters, hideResolved, toggleHideResolved, flaggedOnly, toggleFlaggedOnly}}>
            {children}
        </FiltersContext.Provider>
    );
}

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail, updatePassword} from 'firebase/auth';

export const ChangePasswordPage = () => {
  return (
    <>
    <CoursePageBodyContainer
      mainColumn={<ChangePasswordMain/>}
      rightColumn={<></>}
      singleColumn={<ChangePasswordMain/>}
    />
    </>
  );
}

const ChangePasswordMain = () => {
  const auth = getAuth();
  const [currentScreen, setCurrentScreen] = useState('changePassword');

  const goToScreen = (screenName) => {
    setCurrentScreen(screenName);
  };

  return (
    <div>
      {currentScreen == 'changePassword' && <ChangePassword auth = {auth}  goToScreen={goToScreen} />}
      {currentScreen == 'forgotPassword' && <ForgotPasword auth = {auth} goToScreen={goToScreen} />}
    </div>
  );
};

const ChangePassword = ({auth, goToScreen}) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordMatch, setNewPasswordMatch] = useState('')
  const [error, setError]  = useState('')
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const user = auth.currentUser;
  const email = user.email;

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      setSent(false)
      setError('')
      setLoading(true)

      // Check if old password is valid
      const credential = EmailAuthProvider.credential(email, oldPassword)
      await reauthenticateWithCredential(user, credential)

      // check if new password is valid
      if (newPassword.length < 8) {
        throw new Error("invalid-password")
      }
      if (newPassword != newPasswordMatch) {
        throw new Error("no-match")
      }

      await updatePassword(user,newPassword)
      setSent(true)
      setLoading(false)
    } catch(error){
      setError(error.code ?? error.message)
      setLoading(false)
    }
  };

  return (
    <div className="update-password">
      <h3>Change password</h3>
      <p>To change your password, provide your current password and your new password.</p>
      <p>If you receive an error that the request requires recent authentication, please log out and log back in before performing this operation.</p>
      <Form className = "px-4" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formOldPassword">
            <Form.Control 
              type="password" 
              placeholder="Enter old password" 
              onChange={(e)=>setOldPassword(e.target.value)}
              isInvalid = {error == "auth/wrong-password"}
            />
            <Form.Control.Feedback type="invalid">
            Incorrect password.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control 
            type="password" 
            placeholder="Enter new password" 
            onChange={(e)=>setNewPassword(e.target.value)}
            isInvalid = {error == "invalid-password"}
          />
          <Form.Control.Feedback type="invalid">
            Invalid password. Passwords should be at least 8 characters long.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPasswordVerification">
          <Form.Control 
            type="password" 
            placeholder="Re-enter new Password"
            onChange = {(e)=>setNewPasswordMatch(e.target.value)}
            isInvalid={error == "no-match"}
            required
          />
          <Form.Control.Feedback type="invalid">
            Passwords don't match.
          </Form.Control.Feedback>
        </Form.Group> 
        <Button variant="primary" type="submit" style={{width:"100%"}} disabled={loading}>
          {loading? 'Loading...' : 'Submit'}
        </Button>
        <Button variant = "link" style={{width:"100%"}} onClick = {()=> goToScreen('forgotPassword')}>
          Forgot Password?
        </Button>
      </Form>
      {sent && (
        <div className="mt-2">
          <p>Password reset!</p>
        </div>
      )}
    </div>
  )
}

const ForgotPasword = ({
  auth,
  goToScreen
}) => {
  const [magicLinkEmail, setMagicLinkEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setIsLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const email = auth.currentUser.email

  const sendEmail =  async(e, isInitial) => {
    e.preventDefault()
    setError('')
    setIsLoading(true)
    if (isInitial) setSent(false)
    try {
      if (isInitial && email != magicLinkEmail) {
        throw new Error('incorrect-email')
      }

      await sendPasswordResetEmail(auth, magicLinkEmail,{
        url: window.location.origin + window.location.pathname,
        handleCodeInApp: true,
      })
      setIsLoading(false)
      if (isInitial) setSent(true)
    } catch (error) {
      setIsLoading(false)
      setError(error.message)
    }
  } 


  return (
    <div className = "d-flex flex-column align-items-center">
      {!sent ? 
        <>
        <h2>Reset Password</h2>
        <p className="mt-2 mb-2 px-2 text-center">Enter your email.</p>
      <Form onSubmit={(e)=>{
        sendEmail(e, true)
        }}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control 
          style={{width:300}}
          type="email" 
          placeholder="Enter email" 
          onChange={(e)=>setMagicLinkEmail(e.target.value)}
          isInvalid={error.length > 0}
          aria-label="Enter email"
        />
        <Form.Control.Feedback type = 'invalid'>
          Error sending email.
        </Form.Control.Feedback>
      </Form.Group>
      <Button 
      variant="primary" 
      type="submit" 
      style={{width:"100%"}} 
      className="mb-1"
      disabled = {loading}>
      {loading ? 'Loading…' : 'Send Email'}
      </Button>
      <Button 
      variant="light" 
      style = {{width:"100%"}}
      onClick={()=>goToScreen('changePassword')}
      >
        Go Back
      </Button>
      </Form>     
    </>
      
      :
       <>
       <div className = "d-flex flex-column align-items-center px-2">
          <h3>Email sent!</h3>
          <p className='text-center'>Please check your email for further instructions. If you don't see the email, check your spam folder. </p>
          <div className = "d-flex flex-row justify-content-center">
          <Button 
            variant="link" 
            onClick = {()=> goToScreen('changePassword')} 
            >
              Go back
            </Button>
            <Button variant = "link" onClick = {(e)=> sendEmail(e, false)}>
            {loading ? 'Loading' : 'Resend email'}
            </Button>
          </div>
          {error && <p style = {{color: "red"}}>Error sending email. Please try again.</p>}
          
        </div>
       </>
      }

    </div>
  
  )
}





import type { CountryCode } from "./country_code_to_names";

export type Country = {
  code: CountryCode;
  eng_name: string;
  native_name: string;
};

const COUNTRIES: Array<Country> = [
  { code: "CIP", eng_name: "Somewhere on Earth", native_name: "Somewhere on Earth" },
  { code: "AD", eng_name: "Andorra", native_name: "Andorra" },
  {
    code: "AE",
    eng_name: "United Arab Emirates",
    native_name: "دولة الإمارات العربية المتحدة",
  },
  { code: "AF", eng_name: "Afghanistan", native_name: "افغانستان" },
  {
    code: "AG",
    eng_name: "Antigua and Barbuda",
    native_name: "Antigua and Barbuda",
  },
  { code: "AI", eng_name: "Anguilla", native_name: "Anguilla" },
  { code: "AL", eng_name: "Albania", native_name: "Shqipëria" },
  { code: "AM", eng_name: "Armenia", native_name: "Հայաստան" },
  { code: "AO", eng_name: "Angola", native_name: "Angola" },
  { code: "AQ", eng_name: "Antarctica", native_name: "Antarctica" },
  { code: "AR", eng_name: "Argentina", native_name: "Argentina" },
  { code: "AS", eng_name: "American Samoa", native_name: "American Samoa" },
  { code: "AT", eng_name: "Austria", native_name: "Österreich" },
  { code: "AU", eng_name: "Australia", native_name: "Australia" },
  { code: "AW", eng_name: "Aruba", native_name: "Aruba" },
  { code: "AX", eng_name: "Åland", native_name: "Åland" },
  { code: "AZ", eng_name: "Azerbaijan", native_name: "Azərbaycan" },
  {
    code: "BA",
    eng_name: "Bosnia and Herzegovina",
    native_name: "Bosna i Hercegovina",
  },
  { code: "BB", eng_name: "Barbados", native_name: "Barbados" },
  { code: "BD", eng_name: "Bangladesh", native_name: "Bangladesh" },
  { code: "BE", eng_name: "Belgium", native_name: "België" },
  { code: "BF", eng_name: "Burkina Faso", native_name: "Burkina Faso" },
  { code: "BG", eng_name: "Bulgaria", native_name: "България" },
  { code: "BH", eng_name: "Bahrain", native_name: "\u200fالبحرين" },
  { code: "BI", eng_name: "Burundi", native_name: "Burundi" },
  { code: "BJ", eng_name: "Benin", native_name: "Bénin" },
  { code: "BL", eng_name: "Saint Barthélemy", native_name: "Saint-Barthélemy" },
  { code: "BM", eng_name: "Bermuda", native_name: "Bermuda" },
  { code: "BN", eng_name: "Brunei", native_name: "Negara Brunei Darussalam" },
  { code: "BO", eng_name: "Bolivia", native_name: "Bolivia" },
  { code: "BQ", eng_name: "Bonaire", native_name: "Bonaire" },
  { code: "BR", eng_name: "Brazil", native_name: "Brasil" },
  { code: "BS", eng_name: "Bahamas", native_name: "Bahamas" },
  { code: "BT", eng_name: "Bhutan", native_name: "ʼbrug-yul" },
  { code: "BV", eng_name: "Bouvet Island", native_name: "Bouvetøya" },
  { code: "BW", eng_name: "Botswana", native_name: "Botswana" },
  { code: "BY", eng_name: "Belarus", native_name: "Белару́сь" },
  { code: "BZ", eng_name: "Belize", native_name: "Belize" },
  { code: "CA", eng_name: "Canada", native_name: "Canada" },
  {
    code: "CC",
    eng_name: "Cocos [Keeling] Islands",
    native_name: "Cocos (Keeling) Islands",
  },
  {
    code: "CD",
    eng_name: "Democratic Republic of the Congo",
    native_name: "République démocratique du Congo",
  },
  {
    code: "CF",
    eng_name: "Central African Republic",
    native_name: "Ködörösêse tî Bêafrîka",
  },
  {
    code: "CG",
    eng_name: "Republic of the Congo",
    native_name: "République du Congo",
  },
  { code: "CH", eng_name: "Switzerland", native_name: "Schweiz" },
  { code: "CI", eng_name: "Ivory Coast", native_name: "Côte d'Ivoire" },
  { code: "CK", eng_name: "Cook Islands", native_name: "Cook Islands" },
  { code: "CL", eng_name: "Chile", native_name: "Chile" },
  { code: "CM", eng_name: "Cameroon", native_name: "Cameroon" },
  { code: "CN", eng_name: "China", native_name: "中国" },
  { code: "CO", eng_name: "Colombia", native_name: "Colombia" },
  { code: "CR", eng_name: "Costa Rica", native_name: "Costa Rica" },
  { code: "CU", eng_name: "Cuba", native_name: "Cuba" },
  { code: "CV", eng_name: "Cape Verde", native_name: "Cabo Verde" },
  { code: "CW", eng_name: "Curacao", native_name: "Curaçao" },
  { code: "CX", eng_name: "Christmas Island", native_name: "Christmas Island" },
  { code: "CY", eng_name: "Cyprus", native_name: "Κύπρος" },
  { code: "CZ", eng_name: "Czech Republic", native_name: "Česká republika" },
  { code: "DE", eng_name: "Germany", native_name: "Deutschland" },
  { code: "DJ", eng_name: "Djibouti", native_name: "Djibouti" },
  { code: "DK", eng_name: "Denmark", native_name: "Danmark" },
  { code: "DM", eng_name: "Dominica", native_name: "Dominica" },
  {
    code: "DO",
    eng_name: "Dominican Republic",
    native_name: "República Dominicana",
  },
  { code: "DZ", eng_name: "Algeria", native_name: "الجزائر" },
  { code: "EC", eng_name: "Ecuador", native_name: "Ecuador" },
  { code: "EE", eng_name: "Estonia", native_name: "Eesti" },
  { code: "EG", eng_name: "Egypt", native_name: "مصر\u200e" },
  { code: "EH", eng_name: "Western Sahara", native_name: "الصحراء الغربية" },
  { code: "ER", eng_name: "Eritrea", native_name: "ኤርትራ" },
  { code: "ES", eng_name: "Spain", native_name: "España" },
  { code: "ET", eng_name: "Ethiopia", native_name: "ኢትዮጵያ" },
  { code: "FI", eng_name: "Finland", native_name: "Suomi" },
  { code: "FJ", eng_name: "Fiji", native_name: "Fiji" },
  { code: "FK", eng_name: "Falkland Islands", native_name: "Falkland Islands" },
  { code: "FM", eng_name: "Micronesia", native_name: "Micronesia" },
  { code: "FO", eng_name: "Faroe Islands", native_name: "Føroyar" },
  { code: "FR", eng_name: "France", native_name: "France" },
  { code: "GA", eng_name: "Gabon", native_name: "Gabon" },
  { code: "GB", eng_name: "United Kingdom", native_name: "United Kingdom" },
  { code: "GD", eng_name: "Grenada", native_name: "Grenada" },
  { code: "GE", eng_name: "Georgia", native_name: "საქართველო" },
  { code: "GF", eng_name: "French Guiana", native_name: "Guyane française" },
  { code: "GG", eng_name: "Guernsey", native_name: "Guernsey" },
  { code: "GH", eng_name: "Ghana", native_name: "Ghana" },
  { code: "GI", eng_name: "Gibraltar", native_name: "Gibraltar" },
  { code: "GL", eng_name: "Greenland", native_name: "Kalaallit Nunaat" },
  { code: "GM", eng_name: "Gambia", native_name: "Gambia" },
  { code: "GN", eng_name: "Guinea", native_name: "Guinée" },
  { code: "GP", eng_name: "Guadeloupe", native_name: "Guadeloupe" },
  {
    code: "GQ",
    eng_name: "Equatorial Guinea",
    native_name: "Guinea Ecuatorial",
  },
  { code: "GR", eng_name: "Greece", native_name: "Ελλάδα" },
  {
    code: "GS",
    eng_name: "South Georgia and the South Sandwich Islands",
    native_name: "South Georgia",
  },
  { code: "GT", eng_name: "Guatemala", native_name: "Guatemala" },
  { code: "GU", eng_name: "Guam", native_name: "Guam" },
  { code: "GW", eng_name: "Guinea-Bissau", native_name: "Guiné-Bissau" },
  { code: "GY", eng_name: "Guyana", native_name: "Guyana" },
  { code: "HK", eng_name: "Hong Kong", native_name: "香港" },
  {
    code: "HM",
    eng_name: "Heard Island and McDonald Islands",
    native_name: "Heard Island and McDonald Islands",
  },
  { code: "HN", eng_name: "Honduras", native_name: "Honduras" },
  { code: "HR", eng_name: "Croatia", native_name: "Hrvatska" },
  { code: "HT", eng_name: "Haiti", native_name: "Haïti" },
  { code: "HU", eng_name: "Hungary", native_name: "Magyarország" },
  { code: "ID", eng_name: "Indonesia", native_name: "Indonesia" },
  { code: "IE", eng_name: "Ireland", native_name: "Éire" },
  { code: "IL", eng_name: "Israel", native_name: "יִשְׂרָאֵל" },
  { code: "IM", eng_name: "Isle of Man", native_name: "Isle of Man" },
  { code: "IN", eng_name: "India", native_name: "भारत" },
  {
    code: "IO",
    eng_name: "British Indian Ocean Territory",
    native_name: "British Indian Ocean Territory",
  },
  { code: "IQ", eng_name: "Iraq", native_name: "العراق" },
  { code: "IR", eng_name: "Iran", native_name: "ایران" },
  { code: "IS", eng_name: "Iceland", native_name: "Ísland" },
  { code: "IT", eng_name: "Italy", native_name: "Italia" },
  { code: "JE", eng_name: "Jersey", native_name: "Jersey" },
  { code: "JM", eng_name: "Jamaica", native_name: "Jamaica" },
  { code: "JO", eng_name: "Jordan", native_name: "الأردن" },
  { code: "JP", eng_name: "Japan", native_name: "日本" },
  { code: "KE", eng_name: "Kenya", native_name: "Kenya" },
  { code: "KG", eng_name: "Kyrgyzstan", native_name: "Кыргызстан" },
  { code: "KH", eng_name: "Cambodia", native_name: "Kâmpŭchéa" },
  { code: "KI", eng_name: "Kiribati", native_name: "Kiribati" },
  { code: "KM", eng_name: "Comoros", native_name: "Komori" },
  {
    code: "KN",
    eng_name: "Saint Kitts and Nevis",
    native_name: "Saint Kitts and Nevis",
  },
  { code: "KP", eng_name: "North Korea", native_name: "북한" },
  { code: "KR", eng_name: "South Korea", native_name: "대한민국" },
  { code: "KW", eng_name: "Kuwait", native_name: "الكويت" },
  { code: "KY", eng_name: "Cayman Islands", native_name: "Cayman Islands" },
  { code: "KZ", eng_name: "Kazakhstan", native_name: "Қазақстан" },
  { code: "LA", eng_name: "Laos", native_name: "ສປປລາວ" },
  { code: "LB", eng_name: "Lebanon", native_name: "لبنان" },
  { code: "LC", eng_name: "Saint Lucia", native_name: "Saint Lucia" },
  { code: "LI", eng_name: "Liechtenstein", native_name: "Liechtenstein" },
  { code: "LK", eng_name: "Sri Lanka", native_name: "śrī laṃkāva" },
  { code: "LR", eng_name: "Liberia", native_name: "Liberia" },
  { code: "LS", eng_name: "Lesotho", native_name: "Lesotho" },
  { code: "LT", eng_name: "Lithuania", native_name: "Lietuva" },
  { code: "LU", eng_name: "Luxembourg", native_name: "Luxembourg" },
  { code: "LV", eng_name: "Latvia", native_name: "Latvija" },
  { code: "LY", eng_name: "Libya", native_name: "\u200fليبيا" },
  { code: "MA", eng_name: "Morocco", native_name: "المغرب" },
  { code: "MC", eng_name: "Monaco", native_name: "Monaco" },
  { code: "MD", eng_name: "Moldova", native_name: "Moldova" },
  { code: "ME", eng_name: "Montenegro", native_name: "Црна Гора" },
  { code: "MF", eng_name: "Saint Martin", native_name: "Saint-Martin" },
  { code: "MG", eng_name: "Madagascar", native_name: "Madagasikara" },
  { code: "MH", eng_name: "Marshall Islands", native_name: "M̧ajeļ" },
  {
    code: "MK",
    eng_name: "North Macedonia",
    native_name: "Северна Македонија",
  },
  { code: "ML", eng_name: "Mali", native_name: "Mali" },
  { code: "MM", eng_name: "Myanmar [Burma]", native_name: "မြန်မာ" },
  { code: "MN", eng_name: "Mongolia", native_name: "Монгол улс" },
  { code: "MO", eng_name: "Macao", native_name: "澳門" },
  {
    code: "MP",
    eng_name: "Northern Mariana Islands",
    native_name: "Northern Mariana Islands",
  },
  { code: "MQ", eng_name: "Martinique", native_name: "Martinique" },
  { code: "MR", eng_name: "Mauritania", native_name: "موريتانيا" },
  { code: "MS", eng_name: "Montserrat", native_name: "Montserrat" },
  { code: "MT", eng_name: "Malta", native_name: "Malta" },
  { code: "MU", eng_name: "Mauritius", native_name: "Maurice" },
  { code: "MV", eng_name: "Maldives", native_name: "Maldives" },
  { code: "MW", eng_name: "Malawi", native_name: "Malawi" },
  { code: "MX", eng_name: "Mexico", native_name: "México" },
  { code: "MY", eng_name: "Malaysia", native_name: "Malaysia" },
  { code: "MZ", eng_name: "Mozambique", native_name: "Moçambique" },
  { code: "NA", eng_name: "Namibia", native_name: "Namibia" },
  { code: "NC", eng_name: "New Caledonia", native_name: "Nouvelle-Calédonie" },
  { code: "NE", eng_name: "Niger", native_name: "Niger" },
  { code: "NF", eng_name: "Norfolk Island", native_name: "Norfolk Island" },
  { code: "NG", eng_name: "Nigeria", native_name: "Nigeria" },
  { code: "NI", eng_name: "Nicaragua", native_name: "Nicaragua" },
  { code: "NL", eng_name: "Netherlands", native_name: "Nederland" },
  { code: "NO", eng_name: "Norway", native_name: "Norge" },
  { code: "NP", eng_name: "Nepal", native_name: "नपल" },
  { code: "NR", eng_name: "Nauru", native_name: "Nauru" },
  { code: "NU", eng_name: "Niue", native_name: "Niuē" },
  { code: "NZ", eng_name: "New Zealand", native_name: "New Zealand" },
  { code: "OM", eng_name: "Oman", native_name: "عمان" },
  { code: "PA", eng_name: "Panama", native_name: "Panamá" },
  { code: "PE", eng_name: "Peru", native_name: "Perú" },
  {
    code: "PF",
    eng_name: "French Polynesia",
    native_name: "Polynésie française",
  },
  { code: "PG", eng_name: "Papua New Guinea", native_name: "Papua Niugini" },
  { code: "PH", eng_name: "Philippines", native_name: "Pilipinas" },
  { code: "PK", eng_name: "Pakistan", native_name: "Pakistan" },
  { code: "PL", eng_name: "Poland", native_name: "Polska" },
  {
    code: "PM",
    eng_name: "Saint Pierre and Miquelon",
    native_name: "Saint-Pierre-et-Miquelon",
  },
  { code: "PN", eng_name: "Pitcairn Islands", native_name: "Pitcairn Islands" },
  { code: "PR", eng_name: "Puerto Rico", native_name: "Puerto Rico" },
  { code: "PS", eng_name: "Palestine", native_name: "فلسطين" },
  { code: "PT", eng_name: "Portugal", native_name: "Portugal" },
  { code: "PW", eng_name: "Palau", native_name: "Palau" },
  { code: "PY", eng_name: "Paraguay", native_name: "Paraguay" },
  { code: "QA", eng_name: "Qatar", native_name: "قطر" },
  { code: "RE", eng_name: "Réunion", native_name: "La Réunion" },
  { code: "RO", eng_name: "Romania", native_name: "România" },
  { code: "RS", eng_name: "Serbia", native_name: "Србија" },
  { code: "RU", eng_name: "Russia", native_name: "Россия" },
  { code: "RW", eng_name: "Rwanda", native_name: "Rwanda" },
  { code: "SA", eng_name: "Saudi Arabia", native_name: "العربية السعودية" },
  { code: "SB", eng_name: "Solomon Islands", native_name: "Solomon Islands" },
  { code: "SC", eng_name: "Seychelles", native_name: "Seychelles" },
  { code: "SD", eng_name: "Sudan", native_name: "السودان" },
  { code: "SE", eng_name: "Sweden", native_name: "Sverige" },
  { code: "SG", eng_name: "Singapore", native_name: "Singapore" },
  { code: "SH", eng_name: "Saint Helena", native_name: "Saint Helena" },
  { code: "SI", eng_name: "Slovenia", native_name: "Slovenija" },
  {
    code: "SJ",
    eng_name: "Svalbard and Jan Mayen",
    native_name: "Svalbard og Jan Mayen",
  },
  { code: "SK", eng_name: "Slovakia", native_name: "Slovensko" },
  { code: "SL", eng_name: "Sierra Leone", native_name: "Sierra Leone" },
  { code: "SM", eng_name: "San Marino", native_name: "San Marino" },
  { code: "SN", eng_name: "Senegal", native_name: "Sénégal" },
  { code: "SO", eng_name: "Somalia", native_name: "Soomaaliya" },
  { code: "SR", eng_name: "Suriname", native_name: "Suriname" },
  { code: "SS", eng_name: "South Sudan", native_name: "South Sudan" },
  {
    code: "ST",
    eng_name: "São Tomé and Príncipe",
    native_name: "São Tomé e Príncipe",
  },
  { code: "SV", eng_name: "El Salvador", native_name: "El Salvador" },
  { code: "SX", eng_name: "Sint Maarten", native_name: "Sint Maarten" },
  { code: "SY", eng_name: "Syria", native_name: "سوريا" },
  { code: "SZ", eng_name: "Swaziland", native_name: "Swaziland" },
  {
    code: "TC",
    eng_name: "Turks and Caicos Islands",
    native_name: "Turks and Caicos Islands",
  },
  { code: "TD", eng_name: "Chad", native_name: "Tchad" },
  {
    code: "TF",
    eng_name: "French Southern Territories",
    native_name: "Territoire des Terres australes et antarctiques fr",
  },
  { code: "TG", eng_name: "Togo", native_name: "Togo" },
  { code: "TH", eng_name: "Thailand", native_name: "ประเทศไทย" },
  { code: "TJ", eng_name: "Tajikistan", native_name: "Тоҷикистон" },
  { code: "TK", eng_name: "Tokelau", native_name: "Tokelau" },
  { code: "TL", eng_name: "East Timor", native_name: "Timor-Leste" },
  { code: "TM", eng_name: "Turkmenistan", native_name: "Türkmenistan" },
  { code: "TN", eng_name: "Tunisia", native_name: "تونس" },
  { code: "TO", eng_name: "Tonga", native_name: "Tonga" },
  { code: "TR", eng_name: "Turkey", native_name: "Türkiye" },
  {
    code: "TT",
    eng_name: "Trinidad and Tobago",
    native_name: "Trinidad and Tobago",
  },
  { code: "TV", eng_name: "Tuvalu", native_name: "Tuvalu" },
  { code: "TW", eng_name: "Taiwan", native_name: "臺灣" },
  { code: "TZ", eng_name: "Tanzania", native_name: "Tanzania" },
  { code: "UA", eng_name: "Ukraine", native_name: "Україна" },
  { code: "UG", eng_name: "Uganda", native_name: "Uganda" },
  {
    code: "UM",
    eng_name: "U.S. Minor Outlying Islands",
    native_name: "United States Minor Outlying Islands",
  },
  { code: "US", eng_name: "United States", native_name: "United States" },
  { code: "UY", eng_name: "Uruguay", native_name: "Uruguay" },
  { code: "UZ", eng_name: "Uzbekistan", native_name: "O‘zbekiston" },
  { code: "VA", eng_name: "Vatican City", native_name: "Vaticano" },
  {
    code: "VC",
    eng_name: "Saint Vincent and the Grenadines",
    native_name: "Saint Vincent and the Grenadines",
  },
  { code: "VE", eng_name: "Venezuela", native_name: "Venezuela" },
  {
    code: "VG",
    eng_name: "British Virgin Islands",
    native_name: "British Virgin Islands",
  },
  {
    code: "VI",
    eng_name: "U.S. Virgin Islands",
    native_name: "United States Virgin Islands",
  },
  { code: "VN", eng_name: "Vietnam", native_name: "Việt Nam" },
  { code: "VU", eng_name: "Vanuatu", native_name: "Vanuatu" },
  {
    code: "WF",
    eng_name: "Wallis and Futuna",
    native_name: "Wallis et Futuna",
  },
  { code: "WS", eng_name: "Samoa", native_name: "Samoa" },
  { code: "XK", eng_name: "Kosovo", native_name: "Republika e Kosovës" },
  { code: "YE", eng_name: "Yemen", native_name: "اليَمَن" },
  { code: "YT", eng_name: "Mayotte", native_name: "Mayotte" },
  { code: "ZA", eng_name: "South Africa", native_name: "South Africa" },
  { code: "ZM", eng_name: "Zambia", native_name: "Zambia" },
  { code: "ZW", eng_name: "Zimbabwe", native_name: "Zimbabwe" },
  { code: "AC", eng_name: "Ascension Island", native_name: "Ascension Island" },
  { code: "TA", eng_name: "Tristan da Cunha", native_name: "Tristan da Cunha" },
];

export default COUNTRIES;

/* Right now we have a straight-forward permissions model: admins have
 * strictly more access than section leaders, who have strictly more
 * access than students. We can gate content by marking the least restrictive
 * user group that has access to it (e.g. SECTION_LEADER), which will
 * ensure any groups with more permissions (e.g. ADMIN) will also have
 * access to it.
 */
export enum Role {
  UNREGISTERED = 0,
  STUDENT = 1,
  SECTION_LEADER = 2,
  TA = 3,
  ADMIN = 4,
  INSTRUCTOR = 5,
  EXPERIENCED_STUDENT = 6,
  MENTOR = 7,
}

export type RoleDisplayString =
  | "Unregistered"
  | "Student"
  | "Section Leader"
  | "TA"
  | "Admin"
  | "Instructor"
  | "Experienced Student"
  | "Section Leader (Mentor)"
  | "Unknown";

export type RoleForumString = "SL" | "TA" | "Instructor" | "Admin" | "";

export type RoleAdminString =
  | "Unregistered"
  | "Student"
  | "Section Leader"
  | "TA"
  | "Instructor"
  | "Admin"
  | "Section Leader (Mentor)"
  | "Experienced Student"
  | "Unknown";

// See https://dev.to/hansott/how-to-check-if-string-is-member-of-union-type-1j4m
// for an explanation of this type guard pattern.
export const ALL_FIREBASE_ROLES = [
  "student",
  "sl",
  "ta",
  "admin",
  "instructor",
  "mentor",
  "experiencedstudent",
  "unregistered",
] as const;
export type RoleFirebaseString = typeof ALL_FIREBASE_ROLES[number];

export function isFirebaseRole(role: string): role is RoleFirebaseString {
  return ALL_FIREBASE_ROLES.includes(role as RoleFirebaseString);
}

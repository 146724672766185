import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import "firebase/compat/firestore";
import {
  createAssnStarterCodeFiles,
  createKarelStarterCodeFiles,
  createConsoleStarterCodeFiles,
  createGraphicsStarterCodeFiles
} from "./general";

export async function createNewProjectWithType(
  type,
  projectName,
  courseId,
  userId,
  onCreate
) {
  if(type === 'karel') {
    createNewKarelProject(projectName, courseId, userId, onCreate)
  }
  if(type === 'console') {
    createNewConsoleProject(projectName, courseId, userId, onCreate)
  }
  if(type === 'graphics') {
    createNewGraphicsProject(projectName, courseId, userId, onCreate)
  }
}

export async function createNewKarelProject(
  projectName,
  courseId,
  userId,
  onCreate
) {
  const db = getFirestore();
  const collectionRef = collection(db, "projects");
  const projectRef = await addDoc(collectionRef, {
    editors: [userId],
    title: projectName,
    files: [
      {
        type: "folder",
        name: projectName,
        files: [],
      },
    ],
    type:'karel'
  });
  const projectId = projectRef.id;

  await createKarelStarterCodeFiles(projectName, projectId);

  // Add to creative projects
  const userRef = doc(db, `users/${userId}/${courseId}/creativeProjects`);
  const currentUser = (await getDoc(userRef)).data();
  let creativeProjects = []
  if(currentUser && currentUser.creativeProjects) {
    creativeProjects = currentUser.creativeProjects
  }
  await setDoc(userRef, {
    creativeProjects: [...(creativeProjects), projectId],
  });

  onCreate(projectRef.id);
}

export async function createNewConsoleProject(
  projectName,
  courseId,
  userId,
  onCreate
) {
  console.log(projectName, courseId, userId, onCreate)
  const db = getFirestore();
  const collectionRef = collection(db, "projects");
  const projectRef = await addDoc(collectionRef, {
    editors: [userId],
    title: projectName,
    files: [
      {
        type: "folder",
        name: projectName,
        files: [],
      },
    ],
    type:'console'
  });
  const projectId = projectRef.id;

  await createConsoleStarterCodeFiles(projectName, projectId);

  // Add to creative projects
  const userRef = doc(db, `users/${userId}/${courseId}/creativeProjects`);
  const currentUser = (await getDoc(userRef)).data();
  let creativeProjects = []
  if(currentUser && currentUser.creativeProjects) {
    creativeProjects = currentUser.creativeProjects
  }
  await setDoc(userRef, {
    creativeProjects: [...(creativeProjects), projectId],
  });

  onCreate(projectRef.id);
}

export async function createNewGraphicsProject(
  projectName,
  courseId,
  userId,
  onCreate
) {
  console.log(projectName, courseId, userId, onCreate)
  const db = getFirestore();
  const collectionRef = collection(db, "projects");
  const projectRef = await addDoc(collectionRef, {
    editors: [userId],
    title: projectName,
    files: [
      {
        type: "folder",
        name: projectName,
        files: [],
      },
    ],
    type:'graphics'
  });
  const projectId = projectRef.id;

  await createGraphicsStarterCodeFiles(projectName, projectId);

  // Add to creative projects
  const userRef = doc(db, `users/${userId}/${courseId}/creativeProjects`);
  const currentUser = (await getDoc(userRef)).data();
  let creativeProjects = []
  if(currentUser && currentUser.creativeProjects) {
    creativeProjects = currentUser.creativeProjects
  }
  await setDoc(userRef, {
    creativeProjects: [...(creativeProjects), projectId],
  });

  onCreate(projectRef.id);
}

export async function createNewProject(
  projectName,
  courseId,
  userId,
  onCreate
) {
  console.log(projectName, courseId, userId, onCreate)
  const db = getFirestore();
  const collectionRef = collection(db, "projects");
  const projectRef = await addDoc(collectionRef, {
    editors: [userId],
    title: projectName,
    files: [
      {
        type: "folder",
        name: projectName,
        files: [],
      },
    ],
  });
  const projectId = projectRef.id;

  await createConsoleStarterCodeFiles(projectName, projectId);

  // Add to creative projects
  const userRef = doc(db, `users/${userId}/${courseId}/creativeProjects`);
  const currentUser = (await getDoc(userRef)).data();
  let creativeProjects = []
  if(currentUser && currentUser.creativeProjects) {
    creativeProjects = currentUser.creativeProjects
  }
  await setDoc(userRef, {
    creativeProjects: [...(creativeProjects), projectId],
  });

  onCreate(projectRef.id);
}

export async function createNewProjectForAssn(
  userId,
  courseId,
  assnId,
  assnData,
  onCreate
) {
  console.log('create a new project for assn')
  const title = assnData.metaData.title;
  const db = getFirestore();
  const collectionRef = collection(db, "projects");

  const projectRef = await addDoc(collectionRef, {
    editors: [userId],
    assnId: assnId,
    title: title,
  });
  const projectId = projectRef.id;

  console.log('create starter code')
  await createAssnStarterCodeFiles(assnData, projectId);

  console.log('update users assn map')
  // Update the user's assn -> project map
  const assnProjectMapRef = doc(db, `users/${userId}/${courseId}/assnMap`);
  const newMap = {};
  newMap[assnId] = projectId;
  await setDoc(assnProjectMapRef, newMap, { merge: true });
  console.log('here')
  onCreate(projectId);
}

import { JSX } from "react";
import { Dropdown } from "react-bootstrap";

/**
 * An option that can be selected from a dropdown menu.
 */
export interface SelectOption<TValue,> {
  /** A user-friendly label for the option. */
  label: string;
  /** A unique value to identify the option. */
  value: TValue;
}

/**
 * Props for the {@link SelectDropdown} component.
 */
interface SelectDropdownProps<TValue,> {
  /** An array of {@link SelectOption}s. */
  selectableOptions: SelectOption<TValue>[];
  /** The currently selected option. */
  selectedOption: SelectOption<TValue>;
  /** A function to expose the parent component to the selected option. */
  setSelectedOption: (option: SelectOption<TValue>) => void;
  /** Optional title to display on the dropdown toggle, defaults to the selected option's label if missing.*/
  toggleTitle?: JSX.Element | string;
  /** Style classes to apply to the dropdown toggle button. */
  toggleStyleClasses?: string;
  /** Inline size styling to apply to the dropdown toggle button. */
  toggleSize?: "sm" | "lg";
}

/**
 * A select dropdown menu that allows the user to choose from a list of options.
 * The specified type parameter `TValue` is the type of the `value` (i.e. unique
 * id) property of the {@link SelectOption}.
 * @param __namedParameters - {@inheritdoc SelectDropdownProps} 
 */
export function SelectDropdown<TValue = string,>({ selectableOptions, selectedOption, setSelectedOption, toggleTitle, toggleStyleClasses, toggleSize }: SelectDropdownProps<TValue>) {
  return (
    <Dropdown>
      <Dropdown.Toggle className={toggleStyleClasses} size={toggleSize}>
        {toggleTitle ?? selectedOption.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {selectableOptions.map((option) => (
          <Dropdown.Item key={String(option.value)} onClick={() => setSelectedOption(option)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );  
}
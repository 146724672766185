/* tslint:disable */
// @ts-disable

import React, { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import _ from "lodash";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * Here is example formData
 
const formData = [
  {
    name: "name",
    input: NameInput,
    default:"",
    validation:Yup.string().trim().min(3).required("Name is required"),
    postProcess: (value) => value.trim(),
  }
]
 */

const AutoSubmitToken = ({ debounceMs }) => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();

  const debouncedSubmit = React.useCallback(
    _.debounce(submitForm, debounceMs), // debounce; default 300 ms
    [] // dependencies array
  );

  React.useEffect(() => {
    // Call the debounced version of submitForm
    debouncedSubmit();
    // Cancel the debounce timer on cleanup to immediately call any waiting submitForm
    return debouncedSubmit.cancel;
  }, [values, debouncedSubmit]);

  return null;
};

export const FormixFormWithData = ({
  serverData,
  backUrl,
  formFormat,
  onSubmit,
  showGlobalError = true,
  autoSave = false,
  debounceMs = 300,
  submitButtonInner = null,
  customDisableSubmit = false,
}) => {
  const onFormikSubmit = (values) => {
    // apply the post process on data
    const data = formFormat.reduce((acc, formItem) => {
      const key = formItem.name;
      const value = values[key];
      const postProcess = formItem.postProcess;
      if (postProcess) {
        const processedValue = postProcess ? postProcess(value) : value;
        return { ...acc, [key]: processedValue };
      }
      return { ...acc, [key]: value };
    }, {});
    onSubmit(data);
  };
  const [initValues, setInitValues] = useState(getInitialValues(formFormat, serverData));
  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={getValidationSchema(formFormat)}
      onSubmit={onFormikSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        validateForm,
      }) => {
        // console.log(errors)

        useEffect(() => {
          validateForm();
        }, []);

        return (
          <Form style={{ width: "100%" }} autoComplete="off">
            { autoSave ? <AutoSubmitToken debounceMs={debounceMs}/> : <></>}
            {formFormat.map((formItem, index) => {
              const key = formItem.name;
              return (
                <div key={index}>
                  {formItem.input({
                    name: key,
                    // @ts-ignore
                    values: values,
                    // @ts-ignore
                    setFieldValue: setFieldValue,
                    // @ts-ignore
                    errors: errors,
                    // @ts-ignore
                    touched: touched,
                    // @ts-ignore
                    setFieldTouched: setFieldTouched,
                  })}
                </div>
              );
            })}

            {/* <hr/> */}

            {Object.keys(errors).length !== 0  && showGlobalError? (
              <div className="mt-4" style={{ color: "blue" }}>
                Can't save yet. {getFormErrorMessage(formFormat, errors)}
              </div>
            ) : null}
            {/* if we are doing auto save we do not need a save button */}
            { autoSave ? <></> : 
              <div className="formActionContainer">
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={Object.keys(errors).length !== 0 || customDisableSubmit}
                >
                  {submitButtonInner ? submitButtonInner : "Submit"}
                </Button>

                {backUrl && <BackButton url={backUrl} />}
              </div>
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export const HandleSubmitFormixFormWithData = forwardRef(({
  serverData, 
  backUrl, 
  formFormat, 
  onSubmit, 
  showGlobalError = true, 
}, ref) => {
  const onFormikSubmit = (values) => {
    // apply the post process on data
    const data = formFormat.reduce((acc, formItem) => {
      const key = formItem.name;
      const value = values[key];
      const postProcess = formItem.postProcess;
      if (postProcess) {
        const processedValue = postProcess ? postProcess(value) : value;
        return { ...acc, [key]: processedValue };
      }
      return { ...acc, [key]: value };
    }, {});
    onSubmit(data);
  };
  const initValues = getInitialValues(formFormat, serverData);
  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={getValidationSchema(formFormat)}
      onSubmit={onFormikSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        validateForm,
        submitForm
      }) => {
        // console.log(errors)

        useEffect(() => {
          validateForm();
        }, []);
        
        // expose submitForm to parent
        if (ref) {
          useImperativeHandle(ref, () => ({
            submitForm,
            errors
          }));
        }

        return (
          <Form style={{ width: "100%" }} autoComplete="off">
            {formFormat.map((formItem, index) => {
              const key = formItem.name;
              return (
                <div key={index}>
                  {formItem.input({
                    name: key,
                    // @ts-ignore
                    values: values,
                    // @ts-ignore
                    setFieldValue: setFieldValue,
                    // @ts-ignore
                    errors: errors,
                    // @ts-ignore
                    touched: touched,
                    // @ts-ignore
                    setFieldTouched: setFieldTouched,
                  })}
                </div>
              );
            })}

            {/* <hr/> */}

            {Object.keys(errors).length !== 0  && showGlobalError? (
              <div className="mt-4" style={{ color: "blue" }}>
                Can't save yet. {getFormErrorMessage(formFormat, errors)}
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
})


const BackButton = ({ url }) => {
  return (
    <Link to={url} className="btn btn-secondary ml-2">
      Back
    </Link>
  );
};

export const getFormErrorMessage = (formFormat, errors) => {
  for (var itemData of formFormat) {
    const key = itemData.name;
    if (key in errors) {
      // could be a dictionary
      const errorData = errors[key];
      if (typeof errorData === "object") {
        for (var errorKey in errorData) {
          return errorData[errorKey] + ".";
        }
      }
      return errors[key] + ".";
    }
  }
  console.log(errors);
  return "unknown error";
};

const getValidationSchema = (formFormat) => {
  const schemaData = {};
  for (var formItem of formFormat) {
    const validation = formItem.validation;
    if (validation) {
      schemaData[formItem.name] = validation;
    }
  }
  // @ts-ignore
  return Yup.object(schemaData);
};

const getInitialValues = (formFormat, serverData) => {
  const initValues = {};
  for (var formItem of formFormat) {
    const key = formItem.name;
    if (key in serverData) {
      initValues[key] = serverData[key];
    } else {
      const defaultValue = formItem.default;
      if (defaultValue) {
        initValues[key] = defaultValue;
      }
    }
  }
  return initValues;
};

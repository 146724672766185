import React, { useState, useEffect } from 'react';
import { getMostRecentDocumentsByPuzzleIndex, getPuzzleCodes } from "../teacherPuzzles/TNFirebase"
import { Editor } from "@monaco-editor/react";

const WrittenResponse = ({puzzleList, puzzleCodes}) => {
  return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {puzzleList.map((puzzle, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                  <h3>Puzzle {index}</h3>
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Editor
                          width="50%"
                          height="250px"
                          language="javascript"
                          theme="vs-dark"
                          value={puzzleCodes[index]}
                          options={{ readOnly: true, trimAutoWhitespace: true}}
                      />
                      <div style={{ marginLeft: '20px', flex: 1 }}>
                          <p><strong>Student Noticing:</strong> {puzzle?.studentNoticing || 'No noticing available'}</p>
                          <p><strong>Student Response to Student:</strong> {puzzle?.studentResponse || 'No response available'}</p>
                      </div>
                  </div>
              </div>
          ))}
      </div>
  );
};


const LeftSide = ({ name, videoUrl, puzzleList, puzzleCodes, viewMode, isAlreadyReviewed }) => {

  return (
    <div className="w-100 p-3 h-100 d-flex justify-content-center" style={{ maxHeight: 'calc(100vh - 57px)', overflow: 'auto' }}>
      <div style={{ width: '100%' }}>
      <div className="d-flex align-items-center">
          <h3>{name}</h3>
          {isAlreadyReviewed && (
            <div style={{
              marginLeft: '10px',
              marginBottom: '5px',
              backgroundColor: 'green',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '8px',
              fontSize: '0.9em',
            }}>
              Reviewed Already
            </div>
          )}
        </div>
        <hr />
        <div className="d-flex justify-content-end align-items-center mb-2">
          {/* <select onChange={(e) => handleSelect(e.target.value)} value={selectState} className='form-select'>
            {puzzleList.map((_, index) => (
              <option key={index} value={index}>{`Puzzle ${index}`}</option>
            ))}
            <option value="video">Video</option>
            <option value="submit">Submit</option>
          </select> */}
        </div>
        {viewMode === 'video' && <video src={videoUrl} key={videoUrl} className="w-75" style={{maxHeight: '500px'}} controls={true}></video>}
        {viewMode != 'video' && viewMode != 'submit' && (
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <h3>Puzzle {viewMode}</h3>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Editor
                width="50%"
                height="400px"
                language="python"
                theme="vs-dark"
                value={puzzleCodes[viewMode]}
                options={{ readOnly: true, trimAutoWhitespace: true }}
              />
              <div style={{ marginLeft: '20px', flex: 1 }}>
                <p><strong>Student Noticing:</strong> {puzzleList[viewMode]?.studentNoticing || 'No noticing available'}</p>
                <p><strong>Student Response to Student:</strong> {puzzleList[viewMode]?.studentResponse || 'No response available'}</p>
              </div>
            </div>
          </div>
        )}
        {viewMode === 'submit' && <div><h3>Submit</h3></div>}
        <div style={{ height: 20 }}></div>
      </div>
    </div>
  );
};

export default LeftSide;
/* tslint:disable */

import * as Yup from "yup";
import { GenderInput, InterestInput, NameInput, DateOfBirthInput, CityInput, TimeAvailibleInput, IndendToCompleteInput, CurrentOccupationInput, CodingExperience, AgreeHonorable, AgreeOneSubmission, LanguageInput, AboutYourselfInput, DiverseInput, FriendsInput, CountryInput, EnglishProficiencyInput, AgreeToBetaInput, NameRecordingInput } from "./AboutFormInputs";

/**
 * Learn about Yup here
 * https://github.com/jquense/yup#stringtrimmessage-string--function-schema
 * 
 * 
 * Still missing
 * - coding experience (fix)
 * - acknowledge english?
 * - internet?
 */


// note that yup uses "name" as the key to a form field
// each formItem has a name and input. 
// It can optionally have default, validation, and postProcess
export const studentAppStructure = [
  
  {
    name: "name",
    input: NameInput,
    default:"",
    validation:Yup.string().trim().min(3).required("Name is required"),
    postProcess: (value) => value.trim(),
  },
  {
    name: "interest",
    input: InterestInput,
    default:"",
    validation:Yup.array().of(Yup.string())
  },
  {
    name: "gender",
    input: GenderInput,
    default:"",
    validation:Yup.string().required("Gender is required")
  },
  {
    name: "dateOfBirth",
    input: DateOfBirthInput,
    validation: Yup.object({
      year: Yup.number().integer('Year must be a four digit number.')
      .min(1900, 'Birth year is before 1900. Make sure it is four digits long.')
      .max(2008, 'You must be at least 16 years old to apply.')
      .required("Year is required in date of birth"),
      month: Yup.number().integer('Month must be a number.')
      .min(1, 'Month value must be greater than or equal to 1')
      .max(12, 'Month value must be less than or equal to 12')
      .required("Month is required in date of birth"),
      day: Yup.number().integer('Day must be a number.')
      .min(1, 'Day value must be greater than or equal to 1')
      .max(31, 'Day value must be less than or equal to 31')
      .required("Day is required in date of birth")
    }).required("Date of birth is required"),
    default:{}
  },
  
  {
    name:"city",
    input:CityInput,
    validation: Yup.string().required("City is required")
  },
  {
    name:"country",
    input:CountryInput,
    validation: Yup.object({
      eng_name: Yup.string().required("Country is required")
    }).required('Country is required (and must be selected from the dropdown)'),
  },
  {
    name:"currentOccupation",
    input:CurrentOccupationInput,
    validation: Yup.string().required("Current occupation is required")
  },
  {
    name:"nameRecording",
    input:NameRecordingInput,
    validation: Yup.string().min(1).required("Record your name or opt out")
  },
  {
    name:"timeAvailible",
    input:TimeAvailibleInput,
    validation: Yup.number().required("Number of hours availible is required (enter a number)")
  },
  
  {
    name:"intendToComplete",
    input: IndendToCompleteInput,
    validation: Yup.array().of(Yup.string())
      .length(1, "You must intend to complete the course")
      .required("You must intend to complete the course")
  },
  {
    name:"agreeHonorable",
    input: AgreeHonorable,
    validation: Yup.array().of(Yup.string())
      .length(1, "You must agree to be honorable")
      .required("You must agree to be honorable")
  },
  {
    name:"agreeOneSubmission",
    input: AgreeOneSubmission,
    validation: Yup.array().of(Yup.string())
      .length(1, "You must agree to only submit one application")
      .required("You must agree to only submit one application")
  },
  {
    name:"agreeBeta",
    input: AgreeToBetaInput,
    validation: Yup.array().of(Yup.string())
      .length(1, "Missing tools acknowledgement")
      .required("Missing tools acknowledgement")
  },
  {
    name:"previousExperience",
    input:CodingExperience,
    validation:Yup.string().required("Tell us about your coding experience. Write none if you have never programmed before.")
  },
  {
    name:"aboutYourself",
    input:AboutYourselfInput,
    validation:Yup.string().required("About yourself is required")
  },
  {
    name:"referrals",
    input:FriendsInput,
    validation:Yup.array().of(Yup.string().email("Must be valid email")),
    default:[]
  }
]



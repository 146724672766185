import styled from "styled-components"
import { RunnableTeacherPuzzle } from "./RunnableTeacherPuzzles"
import { RightPuzzlePane } from "./BugIdentificationPuzzle";
import { useContext, useEffect, useState } from "react";
import { TeachNowTrainingContext } from "./TeachNowTrainingContext";
import { ApplicantFeedbackPane } from "./MovesPuzzle";
import { getNextPuzzle, loadPuzzles, logPuzzleStartTime, useNumTeacherPuzzlesCompleted } from "./TNFirebase";
import { useUserId } from "hooks/user/useUserId";
import { useCourseId } from "hooks/router/useUrlParams";
import { Loading } from "components/loading/Loading";
import { StudentTeacherPuzzles } from "./StudentTeacherPuzzles";

const BUG_IDENTIFICATION = "bugIdentification"
const MOVES = "moves"
const STUDENT = "student"
const BUGS_PROBLEM = "diag4"
const MOVES_PROBLEM = "diag1"


export const TeacherPuzzles = () => {
  const { currSlideData, nextSlide } = useContext(TeachNowTrainingContext)
  const puzzleType = currSlideData.puzzleType

  if (puzzleType === BUG_IDENTIFICATION) {
    return <TeacherPuzzlesNeedsType 
    nextSlide={nextSlide}
    puzzleType={BUG_IDENTIFICATION}
    puzzleProblem={BUGS_PROBLEM}
    />
  }
  else if (puzzleType === MOVES) {
    return <TeacherPuzzlesNeedsType 
    nextSlide={nextSlide}
    puzzleType={MOVES}
    puzzleProblem={MOVES_PROBLEM}
    />
  } else if (puzzleType == STUDENT) {
    return <StudentTeacherPuzzles
    nextSlide={nextSlide}
    />
  }

}

const TeacherPuzzlesNeedsType = ({ nextSlide, puzzleType, puzzleProblem}) => {
  const userId = useUserId();
  const courseId = useCourseId();
  const [isLoadingPuzzles, setIsLoadingPuzzles] = useState(true)
  const [currentPuzzle, setCurrentPuzzle] = useState({})
  const [puzzleLogDocId, setPuzzleLogDocId] = useState(null);
  const [isLoadingNextPuzzle, setIsLoadingNextPuzzle] = useState(false)

  const [numCompleted, loading, error] = useNumTeacherPuzzlesCompleted(puzzleType, userId, courseId);

  async function moveToNextPuzzle() {
    setIsLoadingNextPuzzle(true)
    const currPuzzle = await getNextPuzzle(userId, puzzleProblem, courseId, puzzleType);
    const fbpuzzleLogDocId = await logPuzzleStartTime(userId, courseId, currPuzzle.docId)
    setCurrentPuzzle(currPuzzle);
    setPuzzleLogDocId(fbpuzzleLogDocId)
    setIsLoadingNextPuzzle(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingPuzzles(true);  // Start loading

        // When the page laods, we either create an ordered list in fb for you, or if it is already there we do nothing 
        await loadPuzzles(puzzleProblem, courseId, userId, puzzleType);

        // Then we set the current puzzle to the first one that has not been completed
        await moveToNextPuzzle();
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoadingPuzzles(false);  // End loading
      }
    };
  
    fetchData();
  }, [userId, courseId]);


  if (isLoadingPuzzles || loading || isLoadingNextPuzzle) {
    return <Loading/>
  }

  const starterCode = currentPuzzle?.code
  const puzzleDocumentid = currentPuzzle?.docId
  const bugOptions = currentPuzzle?.bugOptions
  const trueBugs = currentPuzzle?.trueShortLabels
  

  const data = {
    'starterCode': starterCode,
    'type': "consolerunnable",
  }

  if (puzzleType === BUG_IDENTIFICATION) {
    const puzzleInfo = {
      puzzleProblem: BUGS_PROBLEM,
      puzzleId: puzzleDocumentid,
      puzzleType,
      starterCode,
      bugOptions,
      trueBugs
    }
    return <TeacherPuzzlesOuter>
        <RunnableTeacherPuzzle data={data}/>
        <RightPaneBug>
          <RightPuzzlePane
            puzzleInfo={puzzleInfo}
            numPuzzlesCompleted={numCompleted}
            moveToNextPuzzle={moveToNextPuzzle}
            puzzleLogDocId={puzzleLogDocId}
            nextSlide={nextSlide} 
          />
        </RightPaneBug>
    </TeacherPuzzlesOuter>
  }
  else if (puzzleType === MOVES) {
    const puzzleInfo = {
      puzzleProblem: MOVES_PROBLEM,
      puzzleId: puzzleDocumentid,
      puzzleType,
      starterCode,
      trueBugs
    }
    return <TeacherPuzzlesOuter>
      <RunnableTeacherPuzzle data={data}/>
        <RightPaneGPT>
          <ApplicantFeedbackPane 
            puzzleInfo={puzzleInfo}
            numPuzzlesCompleted={numCompleted}
            moveToNextPuzzle={moveToNextPuzzle}
            puzzleLogDocId={puzzleLogDocId}
            nextSlide={nextSlide} 
          />
        </RightPaneGPT>
    </TeacherPuzzlesOuter>
  }
}

const RightPaneGPT = styled.div`
  height:100%;
  width:800px;
  border-left: 2px solid lightgray;
  overflow-y: auto;
  `

const RightPaneBug = styled.div`
  height:100%;
  width:800px;
  border-left: 2px solid gray;
  margin: 3px;
  overflow-y: auto;
  `

const CodeAndOutput = styled.div`
  background-color: blue;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  height:100%
`


const CenterPane = styled.div`
  background-color: green;
  flex-grow: 1;
  flex-basis: 0;
  height:100%;
  width:100%;
`

const LeftPane = styled.div`
  background-color: yellow;
  flex-grow: 1;
  flex-basis: 0;
  height:100%;
  width:100%;
`

const TeacherPuzzlesOuter = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 10px); 
  height: 100vh; 
  margin: 0px 5px;
`
import Swal from "sweetalert2";
import { getDoubleValue} from "../OnEventAlerts";
import { lessonSlideTypes } from "components/duolessons/server/LessonServerHelper";

export const getNewLessonElementInputs = async (currItemsList) => {
  console.log(currItemsList)
  const { value: selection } = await Swal.fire({
    title: 'Select New Slide Type',
    input: 'select',
    inputOptions: lessonSlideTypes,
    inputPlaceholder: 'Select an Element Type',
    showCancelButton: true,
  })

  if (selection == undefined) {
    return null;
  }
  const userResponses = await getDoubleValue("Chose a slideId and title", "slideId", "title")
  console.log(userResponses)
  const docId = userResponses['item1']
  const title = userResponses['item2']
  if (docId == undefined) {
    return null;
  }
  if(currItemsList.includes(docId)) {
    console.log("ID already in use")
    Swal.fire({
      title: 'Error',
      text: 'This slide ID is already in use. ',
      icon: 'error',
    })
    return null;
  }
  return { type: selection, docId: docId, title: title }
}




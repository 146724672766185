// tiptap
import { NodeViewWrapper } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { getLocalUTCTimezone } from 'components/timezones/timezoneHelper'
import Swal from 'sweetalert2'
import { useState } from 'react'

export const LocalDateTime = Node.create({
  name: 'dateTime',
  group: 'inline',
  inline: true,
  atom: false,
  draggable: true,

  addAttributes() {
    return {
      dateTime: {
        default: null
      },
      showDate: {
        default: false
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'dateTime'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes)]
  },


  addNodeView() {
    return ReactNodeViewRenderer(DateTimeView)
  }
})

const convertToDateObj = ({ date, time }) => {
  let selectedDate
  let showDate = false

  if (date) {
    showDate = true
    selectedDate = new Date(date + "T" + time) // Combine date and time inputs
  } else {
    var today = new Date()
    var timeParts = time.split(":")
    selectedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), timeParts[0], timeParts[1])
  }
  // @ts-ignore
  if (selectedDate == 'Invalid Date') {
    Swal.showValidationMessage(
      'You must fill out all fields for the time.'
    )
  }

  return { selectedDate, showDate }

}


const DateTimeView = (props) => {
  const { dateTime, showDate } = props.node.attrs
  
  const [isEditable, setIsEditable] = useState(props.editor.isEditable)

  const parsedDateTime = dateTime ? new Date(dateTime) : null;
  const getUTCComponents = (dateObj) => {
    if (!dateObj) return { time: '', date: '' };

    // Extract time components
    const utcOffset = 0 // dont adjust 
    const utcHours = dateObj.getHours() 
    const adjustedHours = utcHours.toString().padStart(2, '0');
    const adjustedMinutes = dateObj.getMinutes().toString().padStart(2, '0');
    const initialTime = `${adjustedHours}:${adjustedMinutes}`;

    // Extract date component if `showDate` is true
    const initialDate = showDate ? dateObj.toISOString().slice(0, 10) : '';

    return { time: initialTime, date: initialDate };
  };

  const { time: initialTime, date: initialDate } = getUTCComponents(parsedDateTime);



  if (dateTime) {
    const dateObj = new Date(dateTime)
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      ... (showDate && {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    }

    const getDatePickerHTML = (timezone, initialTime, initialDate) => `
    <p>This is the date and time in your local timezone, which is ${timezone}.</p>
    <label for="time">Time:</label>
    <input type="time" id="time" name="time" value="${initialTime}" required><br><br>

    <label for="date">Date (Optional):</label>
    <input type="date" id="date" name="date" value="${initialDate}"><br><br>
  `;

    const handleChangeDateTime = () => {
      if(!isEditable) return
      
      const datePickerSwal = Swal.mixin({
        title: 'Pick a time',
        html: getDatePickerHTML(getLocalUTCTimezone(), initialTime, initialDate),
        preConfirm: () => {
          // @ts-ignore
          const time = document.getElementById("time").value  // time will be in 24-hour time
          // @ts-ignore
          const date = document.getElementById("date").value

          convertToDateObj({ date, time })
          return { date, time }
        }
      })


      datePickerSwal.fire().then((e) => {
        if (e.isConfirmed) {
          const { date, time } = e.value
          const { selectedDate, showDate } = convertToDateObj({ date, time })
          props.updateAttributes({
            dateTime: selectedDate.toString(),
            showDate
          })
        }
      })
    }

    return (
      <NodeViewWrapper className='d-inline'>
        <span className='badge text-bg-secondary' onClick={handleChangeDateTime}>
          {dateObj.toLocaleString(navigator.language, options)}
        </span>
      </NodeViewWrapper>
    )
  } else {
    return (
      <NodeViewWrapper className='d-inline'>
        <span className='badge text-bg-secondary'>
          Invalid Date
        </span>
      </NodeViewWrapper>
    )
  }
}


export const onInsertDateTime = (editor) => {

  const datePickerHTML = (timezone) => `
      <p>This is the date and time in your local timezone, which is ${timezone}.</p>
      <label for="time">Time:</label>
      <input type="time" id="time" name="time" required><br><br>

      <label for="date">Date (Optional):</label>
      <input type="date" id="date" name="date"><br><br>
      `
  const datePickerSwal = Swal.mixin({
    title: 'Pick a time',
    html: datePickerHTML(getLocalUTCTimezone()),
    preConfirm: () => {
      // @ts-ignore
      const time = document.getElementById("time").value  // time will be in 24-hour time
      // @ts-ignore
      const date = document.getElementById("date").value

      convertToDateObj({ date, time })
      return { date, time }
    }
  })

  datePickerSwal.fire().then((e) => {
    if (e.isConfirmed) {
      const { date, time } = e.value
      const { selectedDate, showDate } = convertToDateObj({ date, time })
      editor
        .chain()
        .focus()
        .insertContent(`<dateTime dateTime="${selectedDate}" showDate=${showDate}></dateTime>`)
        .run()
    }
  })
}



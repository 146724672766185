
import { useState, useContext, useEffect } from 'react'
import { LessonsContext, LessonMetadataType } from 'course/contexts/LessonsContext';
import { TrainingContext } from 'course/contexts/TrainingContext';
import { Link, useParams } from "react-router-dom"
import { FaTrash, FaEdit, FaUpload, FaBan, FaGripVertical } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import Swal from "sweetalert2"
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
  import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { itemSwitchSpeedbump } from './utils';


export const LessonsTable = ({courseId, lessonsMap}) => {
    const { editorType } = useParams()
    const [updateTrigger, setUpdateTrigger] = useState(0)
    const { deleteLessonFromDB, setLessonPublishedStatus } = useContext(editorType == 'student' ? LessonsContext : TrainingContext)

    const togglePublished = async (e, lessonId, publish) => {
        e.stopPropagation();
        await setLessonPublishedStatus(lessonId, !publish)
        Swal.fire("Success!", 'Toggled visibility for lesson with ID ' + lessonId + ' to ' + !publish + '.', 'success')
        setUpdateTrigger(updateTrigger + 1)
      }
    
      const publishButton = (
        <>
        <FaUpload/>{" "}Publish
        </>
      )
    
      const unpublishButton = (
        <>
        <FaBan/> Unpublish
        </>
      )

    const onDelete = async (lessonId) => {
        Swal.fire({
            title: 'Are you sure you want to delete the lesson with ID ' + lessonId + '?',
            text: 'This action is irreversible!',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete this lesson!',
            icon: 'warning'
          }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteLessonFromDB(lessonId) 
                    setUpdateTrigger(updateTrigger + 1)
                    Swal.fire('Success!', 'Lesson deleted.', 'success')
                } catch (e) {
                    Swal.fire('Oh no...', 'Something went wrong!', e.message)
                }
            } 
          })
       
    }



    const data = Object.entries(lessonsMap)
    .sort((a: [string, LessonMetadataType], b: [string, LessonMetadataType]) => {
        //sort a[1], b[1] by lessonNum
        return a[1].lessonNum - b[1].lessonNum
    })
    .map(([lessonId, lessonData] : [string, LessonMetadataType]) => {
        const editLink = `/${courseId}/lessoneditor/${editorType}/${lessonId}`
        return {
            'lessonId': lessonId,
            'title': lessonData.title,
            'type': lessonData.type,
            'togglePublished': <>
            <Button
                onClick={(e) => {togglePublished(e, lessonId, lessonData.publish)}}
                variant='outline-primary'
            >{lessonData.publish ? unpublishButton : publishButton}</Button>
            </>,
            'editButton': <Button variant='primary'><Link to={editLink} className='text-white'><FaEdit/></Link></Button>,
            'deleteButton': <>
            <Button
                onClick={() => {onDelete(lessonId)}}
                variant='danger'
                className='text-white'
            >
                <FaTrash className="" />
            </Button>
            </>
        }
    })



    return (
        <LessonsTableSortable data={data}/>
    )  
}



const LessonsTableSortable = ({ data }) => {
    const { editorType } = useParams();
    const { onLessonsOrderChange } = useContext(editorType == 'student' ? LessonsContext : TrainingContext)
    const [lessonsList, setLessonsList] = useState<string[]>(data.map((row) => row["lessonId"]))
    const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            delay: 100, // delay in ms
            tolerance: 3 // minimum movement to start the drag operation
          }
        }),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
      );


      useEffect(() => {
        setLessonsList(data.map((row) => row["lessonId"]))
      }, [data])
    
    
      async function handleDragEnd(event) {
        const speedbump = await itemSwitchSpeedbump("Are you sure you want to switch these two items?")
        if (!speedbump) {
            return
        }
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
    
            setLessonsList((prevLL) => {
            const oldIndex = prevLL.indexOf(active.id);
            const newIndex = prevLL.indexOf(over.id);
    
            const newList = arrayMove(prevLL, oldIndex, newIndex);
            onLessonsOrderChange(newList)
            return newList
          });
        }
      }
    

        return <>
        <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">All Lessons</div>
        <table  className='table table-responsive table-striped'>
          <thead>
            <tr>
              <th></th> 
              <th>ID</th>
              <th >Title</th>
              <th >Type</th>
              <th>Toggle visibility</th>
              <th >Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={lessonsList}
                    strategy={verticalListSortingStrategy}
                >
                    {data.map((row, index) => (
                        <LessonsTableSortableRows row={row} index={index} key={index}/>
                    ))}
                </SortableContext>
            </DndContext>
          </tbody>
        </table>
        </>
}




const LessonsTableSortableRows = ({ row, index }) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        } = useSortable({ id: row["lessonId"] });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        width: "100%"
      };

    return (      
        <tr key={index} ref={setNodeRef} style={style} >
            <td {...attributes} {...listeners}><FaGripVertical/></td>
            <td >{row.lessonId}</td>
            <td >{row.title}</td>
            <td >{row.type}</td>
            <td >{row.togglePublished}</td>
            <td >{row.editButton}</td>
            <td >{row.deleteButton}</td>
        </tr>
    )
}





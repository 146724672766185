import React, {useContext} from "react"; // every page needs to import react

import "react-tabs/style/react-tabs.css";

import { Steps } from "intro.js-react";
import { IDEContext } from "./contexts/IDEContext";
import { UiTrainingContext } from "contexts/UiTrainingContext";

import "intro.js/introjs.css";

import { helpTimeline } from "./constants/education";
import { useIsMobile } from "utils/general";


export const UiTraining = () => {
    const uiKey = 'hasSeenIDETraining'
    // if the server doesn't have this training marked as complete
    const { markComplete, hasTraining, uiTrainingLoading } = useContext(UiTrainingContext)
    // if someone has hit the helpMode button
    const { helpMode, setHelpMode } = useContext(IDEContext)
    const isMobile = useIsMobile()
  
    const showInitialTraining = !hasTraining(uiKey)
      && !uiTrainingLoading
      && !isMobile
  
    const show = showInitialTraining || helpMode
  
    return <Steps
      enabled={show}
      steps={helpTimeline}
      initialStep={0}
      options={{
        showBullets: false,
      }}
      onExit={() => {
        setHelpMode(false)
        markComplete(uiKey)
      }}
    />
  }
  
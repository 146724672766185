import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { LikeButton } from "./LikeButton";
import { useState } from "react";

export const LikeButtonFast = ({ likedBy, likeHandler, iconSize, showNumber }) => {
  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);
  const [isLocalLiked, setIsLocalLiked] = useState(likedBy[user.uid] ?? false);


  const fastLikeHandler = (newLike) => {
    setIsLocalLiked(newLike);
    likeHandler(newLike);
  };
  
  const numLikes = countLikes(likedBy, user.uid, isLocalLiked)
  
  return <LikeButton
    isLiked={isLocalLiked}
    numLikes={numLikes}
    likeHandler={fastLikeHandler}
    iconSize={iconSize}
    showNumber={showNumber}
    disabled={false}
  />
}

const countLikes = (likedBy, userId, isLocalLiked) => {
  
  let numLikes = 0;
  if(isLocalLiked){
    numLikes++;
  }
  for (const key in likedBy) {
    if(key != userId){
      if (likedBy[key]) {
        numLikes++;
      }
    }
  }
  return numLikes
}

// const liked = likedBy[user.uid] ?? false;
  // // only counts likes that are true
  // 
const hostname = window.location.hostname;
const isLocalHost = hostname === "localhost";
const isCS106A = hostname === "cs106a.stanford.edu";
const isCodeInPlace = hostname === "codeinplace.stanford.edu";

// if you are at cs106a.stanford.edu, use that domain
// if you are at codeinplace.stanford.edu, use that domain
// otherwise, use the firebaseapp domain

let prodAuthDomain = "codeinplace-production.firebaseapp.com";
if (isCS106A || isCodeInPlace) {
  prodAuthDomain = hostname;
}

// fake data that anyone can write to
const developmentFirebaseConfig = {
  apiKey: "AIzaSyBuVNOhgT29akbMEqmDxC7JpmAPMPT9DG8",
  authDomain: "codingplace2023.firebaseapp.com",
  projectId: "codingplace2023",
  storageBucket: "codingplace2023.appspot.com",
  messagingSenderId: "1078516885903",
  appId: "1:1078516885903:web:6c76352a03b62d53c483fa",
  measurementId: "G-B1GM87LSSM",
};

const productionFirebaseConfig = {
  apiKey: "AIzaSyC5R06hZdnowglrzoD_RVw7EVH3nSGE0Yo",
  authDomain: prodAuthDomain,
  storageBucket: "codeinplace-production.appspot.com",
  projectId: "codeinplace-production",
  messagingSenderId: "809987214626",
  appId: "1:809987214626:web:7b84cbf8637ff7456de97b",
  measurementId: "G-NDJSLLEQW2",
};

const developmentTypesenseConfig = {
  apiKey: "fYyZJsb5QjeWoaxbCB0yCxr66jC4DbxQ",
  nodes: [
    {
      host: "3il5x7982gnc4a1mp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
};

const productionTypesenseConfig = {
  apiKey: "39t7GJLtPpsVIGXloCrmFx6Avwr8t4iq",
  nodes: [
    {
      host: "vt0hoib23975n4rlp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
    {
      host: "vt0hoib23975n4rlp-2.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
    {
      host: "vt0hoib23975n4rlp-3.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
};

// WARNING: do not change this unless you know what you are doing.
export const isProduction = true;
export const isApplicationLive = true;

export const typesenseServerConfig = isProduction
  ? productionTypesenseConfig
  : developmentTypesenseConfig;

export const firebaseConfig = isProduction
  ? productionFirebaseConfig
  : developmentFirebaseConfig;

import { ZoomMeeting } from "./pages/ZoomMeeting";

export const GPTeachZoomContainer = () => {
  return (
    <div
      className="h-100 d-flex flex-row justify-content-center"
      style={{ flexGrow: 2 }}
    >
      <div style={{ flexGrow: 1 }}>
        <ZoomMeeting />
      </div>
    </div>
  );
};

import { useState, useEffect, useContext } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { PartialLoading } from "components/loading/Loading";
import { ProfileContext } from "contexts/ProfileContext";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useCourseId } from "hooks/router/useUrlParams"
import { Spacer } from "components/layout/Spacer";
import { getLocalUTCTimezone } from 'components/timezones/timezoneHelper';
import { FaCheck, FaWindowClose} from 'react-icons/fa';
import { parseAndFormatISODate } from 'components/timezones/timezoneHelper';
import { CompletionContext } from "contexts/CompletionContext";
import { RoadmapContext } from 'course/contexts/RoadmapContext'
import { collectAssignments } from 'course/code/RoadmapAssignments';
import { useNavigate } from "react-router-dom";


const functions = getFunctions();
const getGradesForOneStudent = httpsCallable(functions, 'getGradesForOneStudent');
const getAllAssnGradesForOneStudent = httpsCallable(functions, 'getAllAssnGradesForOneStudent');


export const StudentGradesPage= () => {
    return (
        <CoursePageBodyContainer
            mainColumn={<StudentGradesMain/>}
            singleColumn={<StudentGradesMain/>}
            rightColumn={<></>}
        />
    )

}


const StudentGradesMain = () => {
    const courseId = useCourseId()
    const {userData} = useContext(ProfileContext);
    const userId = userData.id
    const [studentCurrentScore, setStudentCurrentScore] = useState(-1)
    const [studentCurrentGrade, setStudentCurrentGrade] = useState("")
    const [studentAssnGrades, setStudentAssnGrades] = useState([])
    const { assnProgress } = useContext(CompletionContext) // assn id: bool tests passed or not
    const { getAssignmentsInRoadmap, roadmapItemMap } = useContext(RoadmapContext)
    const [assignments, setAssignments] = useState([]) // [
    const [projectsList, metaData] = collectAssignments(assignments)

    useEffect(() => {
        const assns = getAssignmentsInRoadmap()
        setAssignments(assns)
    }, [roadmapItemMap])

    useEffect(() => {

        const fetchGrades = async () => {
            console.log("Fetching grades")
            const response = await getGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching grades")
                return
            }
            setStudentCurrentScore(response.data.grades.current_score)
            setStudentCurrentGrade(response.data.grades.current_grade)

        }
        fetchGrades()

        const fetchAssnGrades = async () => {
            console.log("Fetching assignment grades")
            const response = await getAllAssnGradesForOneStudent({
                userId: userId,
                courseId: courseId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching assignment grades")
                return
            }
            console.log("Assignment grades")
            console.log(response.data)
            setStudentAssnGrades(response.data)
        }
        fetchAssnGrades()
    }, [userId, courseId])

    if (studentCurrentScore < 0 || studentAssnGrades.length === 0) {
        return <PartialLoading />
    }

    return <>
        <Spacer />
        <h3>Your Grades</h3>
        <h4>
            Current Grade: {studentCurrentScore}% 
            {studentCurrentGrade && ` (${studentCurrentGrade})`}
        </h4>
        <StudentGradesTable 
            studentAssnGrades={studentAssnGrades} 
            assnProgress={assnProgress}
            assnMetaData={metaData}
            courseId={courseId}
        />
    </>
}


const StudentGradesTable = ({ studentAssnGrades, assnProgress, assnMetaData, courseId }) => {
    const userTz = getLocalUTCTimezone()
    const navigate = useNavigate()

    const columns = [
        { 
            dataField: 'assnId', 
            text: 'assnId',
            hidden: true
        },
        { 
            dataField: 'name', 
            text: 'Assignment' 
        },
        { 
            dataField: 'due_at', 
            text: 'Due',
            formatter: (cell) => {
                if (!cell) {
                    return "N/A";
                }
                return parseAndFormatISODate(cell, userTz);
            }
        },
        { 
            dataField: 'submitted', 
            text: 'Submitted',
            formatter: (cell) => {
                return cell ? <FaCheck /> : <FaWindowClose />
            }
        },
        { 
            dataField: 'testsPassed', 
            text: 'Tests Passed',
            formatter: (cell) => {
                return cell ? <FaCheck /> : <FaWindowClose />
            }
        },
        { dataField: 'score', text: 'Score' },
    ]

    // TODO: we could also add more UI elements to show, e.g., grade_matches_current_submission, late, workflow_state (all in assn.submission)
    // TODO: change to map over metadata (released assns in roadmap)
    // add link to IDE for each assignment
    const data = Object.values(assnMetaData).map(assn => {
        const firestoreAssnId = assn.assnId
        const canvasAssnId = assn?.canvasAssnId
        const name = assn.title

        const testsPassed = firestoreAssnId in assnProgress && assnProgress[firestoreAssnId]

        if (!canvasAssnId) {
            return {
                name: name,
                testsPassed: testsPassed,
                assnId: firestoreAssnId,
                // due_at: assn.due_at,
                // submitted: false,
                // score: "N/A"
            }
        }

        const canvasAssn = studentAssnGrades.find(assn => assn.id === canvasAssnId)   
        const submitted = canvasAssn !== undefined && 'submission' in canvasAssn
        const dueAt = canvasAssn?.due_at
        const pointsPossible = canvasAssn?.points_possible
        let scoreString = pointsPossible ? `- / ${pointsPossible}` : "N/A"
        if (submitted && 'grade' in canvasAssn.submission && canvasAssn.submission.grade !== null) {
            scoreString = `${canvasAssn.submission.grade} / ${pointsPossible}`
        }

        return {
            name: name,
            testsPassed: testsPassed,
            assnId: firestoreAssnId,
            due_at: dueAt,
            submitted: submitted,
            score: scoreString,
        }
    })

    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            navigate(`/${courseId}/ide/a/${row.assnId}`)
        },
        onMouseEnter: (e, row, rowIndex) => {
            e.target.style.cursor = 'pointer';
        }
     }

    return <BootstrapTable
        bordered={false}
        striped={true}
        hover={true}
        bootstrap4={true}
        keyField='name'
        data={data}
        columns={columns} 
        rowEvents={tableRowEvents}
        />

}
import { doc, getFirestore, setDoc } from "firebase/firestore";
import React, { useContext} from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { useUserId } from "hooks/user/useUserId";
import { getApp } from "firebase/app";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

const defaultData = {
  onboardingData:{},
  onboardingLoading:true,
  editOnboarding:(v)=>{},
  isError:false
};

export const StudentOnboardingContext = React.createContext(defaultData);

export const StudentOnboardingProvider = ({children }) => {
  
  const userId = useUserId()
  const courseId = useCourseId()
  // the user already has logic for loading their section data!
  // you are garunteed these are loaded from the server at the start
  const {userData} = useContext(ProfileContext)

  const onboardingDocPath = `users/${userId}/${courseId}/studentonboarding`

  const db = getFirestore(getApp())
  const onboardingDocRef = doc(db, onboardingDocPath)
  const [onboardingData, onboardingLoading, onboardingError] = useDocumentData(onboardingDocRef)
  


 
  const editOnboarding = async (newData) => {
    console.log(newData)
    console.log(onboardingDocPath)
    await setDoc(doc(db, onboardingDocPath), newData, {merge:true})
  }

  const nSections = userData.courseSections.length || 0
  const isLoading = onboardingLoading

  const isError = onboardingError

  return (
    <StudentOnboardingContext.Provider
      value={{
        onboardingData:onboardingData || {},
        onboardingLoading:isLoading,
        onboardingError:isError,
        editOnboarding:editOnboarding,

      }}
    >
      {children}
    </StudentOnboardingContext.Provider>
  );
};

import { useContext, useState, useEffect } from "react";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { LeftColTitle } from "../../ide/LeftCol";
import { SimpleButtonBar } from "components/richTextEditor/TipTap/buttonbars/SimpleButtonBar";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { IDEContext } from "ide/contexts/IDEContext";
import { CourseContext } from "contexts/CourseContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useParams } from "react-router";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import _ from "lodash";
import { PartialLoading } from "components/loading/Loading";
import { Submission } from "course/grading/CanvasTypes";
import { CommentVisibilityToggle } from "./components/CommentVisibilityToggle";

import { EditButton } from "./components/EditButton";
import { FeedbackUserInfo } from "./components/FeedbackUserInfo";
import { ScoreComponent } from "./components/ScoreComponent";

const functions = getFunctions();
const getAssnInfo = httpsCallable(functions, 'getAssnInfo');
const getAssnSubmission = httpsCallable(functions, 'getAssnSubmission');

/**
 * Instructor feedback component
 * This component displays the overall feedback for the project
 * and allows the instructor to edit the feedback
 */
export const InstructorFeedback = () => {
  const { courseId } = useParams()
  const { userData } = useContext(ProfileContext);
  const { projectData } = useContext(IDEContext);
  const { courseHasGrades } = useContext(CourseContext)
  const assnId = projectData.assnId
  const authorId = projectData.editors[0]
  const overallFeedbackDocPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/overall`
  const visibilityDocPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/visibility`

  const db = getFirestore()

  const [feedbackEditable, setFeedbackEditable] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const [canvasAssnInfo, setCanvasAssnInfo] = useState({});
  const [canvasAssnSubmission, setCanvasAssnSubmission] = useState({});
  const [currentCanvasScore, setCurrentCanvasScore] = useState(-1);
  const [score, setScore] = useState(0);
  const [scoreLoading, setScoreLoading] = useState(true);

  // fetch comment visibility document from firestore 
  useEffect(() => {
    const fetchVisibility = async () => {
      const visibilityDocRef= doc(db, visibilityDocPath);
      const docSnap = await getDoc(visibilityDocRef);
      if (docSnap.exists()) {
        setVisibility(docSnap.data().visibility);
      } else {
        console.log("No visibility document found");
        // create grading document
        try {
          await setDoc(visibilityDocRef, {
            visibility: false
          });
          console.log("Visibility document created");
        } catch (error) {
          console.error("Error creating visibility document: ", error);
        }
      }
    }

    fetchVisibility();

  }, [visibilityDocPath]);

  // fetch canvas data and author ID if the course has grades
  useEffect(() => {
    if (!courseHasGrades) return; // no need to fetch canvas data if course doesn't have grades
    // get canvas assignment info -- has points possible, due date, etc.
    const fetchAssnInfo= async () => {
        try{
            const response = await getAssnInfo({
                adminId: userData.id,
                courseId: courseId,
                assnId: assnId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching assn info")
                return
            }
            setCanvasAssnInfo(response.data)
        } catch(error){
            console.error('Error fetching assignment info: ', error);
        }
    }
    fetchAssnInfo();

    // fetch assn submission from canvas
    const fetchAssnSubmission= async () => {
        try{
            const response = await getAssnSubmission({
                callerId: userData.id,
                userId: authorId,
                courseId: courseId,
                assnId: assnId
            })
            if(!response || !response.data) {
                // handle error
                console.log("Error fetching assn submission")
                return
            }
            const submission = response.data as Submission
            setCanvasAssnSubmission(submission)
            setCurrentCanvasScore(submission.score)
            if (submission.score){
              setScore(submission.score)
            }
            setScoreLoading(false)
        } catch(error){
            console.error('Error fetching assignment submisson: ', error);
            setScoreLoading(false)
        }
    }
    fetchAssnSubmission();
  }, []);

  if (!overallFeedbackDocPath) return <PartialLoading />;

  if (!Gate.hasSectionLeaderRole(userData) && !visibility) {
    return <>
    <div className="m-2">
      Your instructor has not released feedback yet!
    </div>
    </>
  }

  return <>
    <LeftColTitle title={"Feedback"} />
    <div className="m-2">
      <FeedbackUserInfo authorId={authorId} userData={userData} />

      <div className="splashCardRightTitle mt-2" >Overall feedback</div>
      <TipTap
        firebaseDocPath={overallFeedbackDocPath}
        editable={feedbackEditable}
        collaborative={true}
        buttonBar={SimpleButtonBar}
        showLoadingSkeleton={true}
      />
      {Gate.hasSectionLeaderRole(userData) && (
        <>
          <EditButton onClick={() => setFeedbackEditable(!feedbackEditable)} />
          <CommentVisibilityToggle 
            db={db}
            courseId={courseId}
            assnId={assnId}
            studentId={authorId}
            currentVisibility={visibility}
          />
        </>
      )}


      <ScoreComponent 
        courseHasGrades={courseHasGrades} 
        scoreLoading={scoreLoading} 
        score={score} 
        setScore={setScore} 
        canvasAssnInfo={canvasAssnInfo} 
        currentCanvasScore={currentCanvasScore}
        setCurrentCanvasScore={setCurrentCanvasScore}
        userData={userData}
        authorId={authorId}
        assnId={assnId}
        courseId={courseId}
      />

    </div>
  </>
}

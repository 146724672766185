import { useEffect, useRef, useContext } from "react"
import { doc, setDoc, serverTimestamp, getFirestore, addDoc, collection, Timestamp, writeBatch } from "firebase/firestore";
import { useLocation } from "react-router";
import { getAuth } from "firebase/auth";
import { ProfileContext } from "contexts/ProfileContext";
import { TimeContext } from "contexts/TimeContext";
import { date } from "yup";



export const useEngagement = (loading, userData) => {
    // init interval, db, time, and location
    const intervalRef = useRef(null)
    const db = getFirestore()
    const currentTime = Date.now();
    const auth = getAuth()
    const location = useLocation()
    const { getServerTimeMs } = useContext(TimeContext)



    useEffect(() => {
        // If the user is not signed in, don't log
        if(loading) { return }
        const userId = auth?.currentUser?.uid;
        if(!userId) {
            return
        }

        // get the session doc
        // note: we set the session doc to the time when this hook is initialized
        const sessionDocRef = doc(db, "engagement", userId,  "sessions", Timestamp.now().toMillis().toString())
        const userDocRef = doc(db, "engagement", userId)
        const currentServerTime = getServerTimeMs()

        
        const setStartTime = async () => {
            // Set start time
            const batch = writeBatch(db);
            try {
                batch.set(sessionDocRef, {
                    startTime: serverTimestamp(),
                    endTime: serverTimestamp(),
                }, { merge: true })
                batch.set(userDocRef, {
                    lastTimeOnSite: serverTimestamp(),
                    lastLoginTime: serverTimestamp()   
                }, {merge: true})
                const isOkToEmail = userData?.okToEmail;
                const firstEmailSent = userData?.sentFirstEmail;
                if(isOkToEmail && !firstEmailSent) {
                    const emailBucket = getEmailBucket(new Date(currentServerTime))
                    const emailDocRef = doc(db, "autoEmails", "general", "24hourNudge", emailBucket)
                    batch.set(emailDocRef, {
                        [userId]: true
                    }, {merge: true})
                }
                batch.commit();
            } catch(e) {
                console.log("Error", e)
            } 

        }

        const resetEndTime = async () => {
            // Reset end time
            const batch = writeBatch(db);
            try {

                batch.set(sessionDocRef, {
                    endTime: serverTimestamp(),
                }, { merge: true })
                batch.set(userDocRef, {
                    lastTimeOnSite: serverTimestamp()     
                }, {merge: true})
                await batch.commit();
            } catch(e) {}
        }


        // Do once then set interval
        setStartTime()

        // intervals are in ms
        // 1000ms (1 seccond) * 60 (seconds in a minute) * 2 (minutes)
        intervalRef.current = setInterval(() => {
            resetEndTime()
        }, 1000 * 60 * 2)


        return  () => {
            // Remove timed write
            clearInterval(intervalRef.current)
        }
    }, [loading])


    const logLocationMoment = async () => {
        const userId = auth?.currentUser?.uid
        if(!userId) {
            return
        }
        const locationDocRef = doc(db, "engagement", userId,  "visitedLogs", Timestamp.now().toMillis().toString())
        try {
            await setDoc(locationDocRef, {
                timestamp: serverTimestamp(),
                path: location.pathname
            }, {merge: true})
        } catch(e) {}
    }

    useEffect(() => {

        logLocationMoment()

    }, [location])



    return []
}


const getEmailBucket = (date) => {
    const roundDate = getRoundDate(date)
    const year = roundDate.getFullYear()
    const month = roundDate.getMonth().toString().padStart(2, "0")
    const day = roundDate.getDate().toString().padStart(2, "0")
    const hour = roundDate.getHours().toString().padStart(2, "0")

    return `${year}-${month}-${day}-${hour}`


}

const getRoundDate = (date) => {
    const minutes = date.getMinutes();
    if(minutes < 30) {
      date.setMinutes(0);
    } else {
      date.setMinutes(60);
    }
    date.setSeconds(0);
    return date;
}
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaGripVertical, FaTrash } from 'react-icons/fa';
import { FaPlayCircle, FaCode, FaBook, FaFilePdf, FaMapMarked, FaCircle } from 'react-icons/fa';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useContext, useEffect } from 'react';
import { LessonsContext } from 'course/contexts/LessonsContext';
import Swal from 'sweetalert2';




export const EditorRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const { } = useContext(LessonsContext)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100%"
  };


  let className = 'd-flex align-items-center sidebarButton'
  if (props.type === "header") {
    className = 'd-flex align-items-center' 
  }



  return (
    <div className='d-flex align-items-center' >
      <div key={props.id} ref={setNodeRef} {...attributes} {...listeners} style={style} className={className}>
        <FaGripVertical/>
        <RowTitle type={props.type} title={props.title} />
      </div>
      <div>
        {getRowButtons(props.type, props.id, props.deleteItem, props.title)}
      </div>
    </div>
  )
}


const RowTitle = ({ type, title }) => {


  if (type === "header") {
    return (
      <div className='d-flex align-items-center'
        style={{ fontWeight: 600 }}>
        {title}
      </div>

    )
  }


  return (
    <div className='d-flex align-items-center'>
      {getLogo(type)}
      <div style={{ marginLeft: "10px" }}>
        {title}
      </div>
    </div>
  )
}


const getRowButtons = (type, docId, deleteItem, title, saveItemTitleInDB) => {
  const navigate = useNavigate()
  const { lessonId } = useParams();


  return (
    <span className='d-flex'>
      <button
        onClick={() => {

          // first make sure they want it deleted
          Swal.fire({
            title: 'Are you sure?',
            text: 'Slides can not be recovered after deletion. Are you sure you want to delete this slide?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes! Delete',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              deleteItem(docId, lessonId)
            }
          })
        }}
        className='btn  btn-sm '
      >
        <FaTrash />
      </button>

      <button
        className='btn  btn-sm '
        onClick={async (e) => {
          e.stopPropagation();
          navigate(docId)
        }}
      >
        <FaEdit />
      </button>


    </span>
  )

}



const getLogo = (type) => {
  if (type === "video") {
    return <FaPlayCircle color='rgb(31, 131, 84)' />;
  }

  if (type == "reading") {
    return <FaBook color='rgb(31, 131, 84)' />
  }

  if (type == "example") {
    return <FaCode color='rgb(31, 131, 84)' />
  }


  if (type == "checkpoint" || type == "splash") {
    return <FaMapMarked color='rgb(31, 131, 84)' />
  }

  if (type == "resource") {
    return <FaFilePdf color='rgb(31, 131, 84)' />
  }

  else {
    return <FaCircle color='rgb(31, 131, 84)' />;
  }
}



import { ForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { QueryDocumentSnapshot, SnapshotOptions, FirestoreDataConverter, DocumentData, getFirestore, collection, doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";



export type CourseNotification = {
  timestamp: Date;
  type: string;
  metaData: ForumNotification
  id: string;
  acknowledged: boolean;
}

export type ForumNotification = {
  forumId: string;
  from: string;
  postId: string;
  replyId: string;
};



export const NOTIFICATION_CONVERTER: FirestoreDataConverter<CourseNotification> = {
  toFirestore: (notification: CourseNotification): DocumentData => notification,
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): CourseNotification {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      type: data.type,
      acknowledged: data.acknowledged,
      metaData: data.metaData,
      timestamp: data.timestamp.toDate(),
    };
  },
};



export class NotificationDataFetcher {

  private db = getFirestore();

  constructor(private readonly userId: string) { }

  getNotificationsPath() {
    return collection(this.db, "users", this.userId, "notifications");
  }


  getPostDocRef(postId: string, forumId: string, courseId: string) {
    const dataFetcher = new ForumDataFetcher(courseId, forumId);
    return dataFetcher.get_post_doc_ref(postId);
  }

  getReplyDocRef(replyId: string, forumId: string, courseId: string) {
    const dataFetcher = new ForumDataFetcher(courseId, forumId);
    return dataFetcher.get_reply_doc_ref(replyId);
  }


  getUserDocRef(userId: string) {
    return doc(this.db, "users", userId);
  }
}



export const acknowledgeNotification = async (courseId, forumId, notificationId, userId) => {
  const db = getFirestore();
  const docRef = doc(db, "users", userId, "notifications", notificationId);

  try {
    await updateDoc(docRef, {
      acknowledged: true
    }
    )
  } catch (e) {
    console.error("Something went wrong");
  }
}


import { createContext } from "react";
import { usePostLists } from "course/forum/components/PostList/PostListHooks";
import { UseInfiniteQueryResult } from "react-query";
import { QuerySnapshot, FirestoreError, DocumentData } from "firebase/firestore";


// React context

const defaultData = {
    drafts: [],
    posts: [],
    announcements: [],
    postsData: {} as UseInfiniteQueryResult<QuerySnapshot<DocumentData>, FirestoreError>,
    refreshDrafts: () => { },
    refreshPosts: () => { }
}


export const PostContext = createContext(defaultData);

export const PostProvider = ({ children }) => {
    const { drafts, posts, announcements, postsData, refreshDrafts, refreshPosts } = usePostLists();



    return (
        <PostContext.Provider value={
            {
                drafts,
                posts,
                announcements,
                postsData,
                refreshDrafts,
                refreshPosts
            }
        }>
            {children}
        </PostContext.Provider>
    )
}
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { useParams } from "react-router-dom"

export const CareHoursHandout = () => {
    const { courseId } = useParams()
    const firebaseDocPath = `/course/${courseId}/handouts/whatiscarehours`
    return (
        <CoursePageBodyContainer
        mainColumn={<CareHoursHandoutMain firebaseDocPath={firebaseDocPath}/>}
        rightColumn={<></>}
        singleColumn={<CareHoursHandoutMain firebaseDocPath={firebaseDocPath}/>}
        />
    )
}

const CareHoursHandoutMain = ({firebaseDocPath}) => {
    return (
        <AdminRichTextEditor firebaseDocPath={firebaseDocPath}/>
    )
}

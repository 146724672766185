import { LandingNav } from "landing/components/LandingNav"
import { Takeaways } from "./Takeaways";
import { TeachQuotes } from "./TeachQuotes";
import { FAQ } from "./FAQ";
import { useCourseId } from "../../../../hooks/router/useUrlParams";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserId } from "hooks/user/useUserId";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { Responsibilities } from "./Responsibilities";
import { JobQualifications } from "./JobQualifications";
export const Explore = () => {
    return <div>
        <LandingNav applicationType={"sectionLeader"} subpage={"About the job"}/>
        
        <Details />
    </div>
}

const Details = () => {

    return (
     <div>
        <Takeaways />
        <TeachQuotes/>
      
        
        <div className="d-flex flex-row justify-content-center w-100">
          <div style={{padding:'10px'}}>
            <div style={{maxWidth:'700px'}}>
            <Responsibilities />
            <div style={{height:'20px'}}/>
            <JobQualifications/>
            <div style={{height:'20px'}}/>
            <FAQ/>
            <div style={{height:'20px'}}/>
            <AgreeButton/>
            </div>
          </div>

        </div>
      </div>
    );
  }

const AgreeButton = () => {
  const navigate = useNavigate();
  const userId = useUserId()
  const {targetCourseId} = useParams()
  const firebasePath = `users/${userId}/${targetCourseId}/sectionLeaderApplication`
  const db = getFirestore();
  const docRef = doc(db, firebasePath);

  async function onClick() {
    await setDoc(docRef, {hasReadAboutJob: true}, { merge: true });
    navigate(`/public/applyteach/${targetCourseId}`)
  }
  return <div className="d-flex justify-content-center">
      <button onClick={onClick} className="btn btn-lg btn-primary">Got it!</button>
  </div>
}
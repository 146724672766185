import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp, FaCompressAlt, FaExpandAlt } from "react-icons/fa"

export const MinMaxButtonHorizontal = ({viewState, setViewState, variant=''}) => {

    if(viewState === 'minimized') {
      return <button
        className={getClassName(variant)}
        onClick={() => setViewState('standard')}
        aria-label="expand"
        tabIndex={0}
      ><FaChevronLeft /></button>
    }
    return <button
      className={getClassName(variant)}
      onClick={() => setViewState('minimized')}
      aria-label="minimize"
      tabIndex={0}
    ><FaChevronRight /></button>
  }
  
 export const MinMaxButtonVertical = ({viewState, setViewState, variant=''}) => {
    
    if(viewState === 'minimized') {
      return <button
        className={getClassName(variant)}
        onClick={() => setViewState('standard')}
        aria-label="expand"
        tabIndex={0}
      ><FaChevronUp /></button>
    }
    return <button
      className={getClassName(variant)}
      onClick={() => setViewState('minimized')}
      aria-label="minimize"
      tabIndex={0}
    ><FaChevronDown /></button>
  }
  
  export const ExpandContractButton = ({ viewState, setViewState,variant='' }) => {
    if (viewState === 'minimized') {
      return <></>
    }
    if (viewState === 'expanded') {
      return <button
        className={getClassName(variant)}
        onClick={() => setViewState('standard')}
        aria-label="compress"
        tabIndex={0}
      ><FaCompressAlt /></button>
    }
    return <button
      className={getClassName(variant)}
      onClick={() => setViewState('expanded')}
      aria-label="expand"
      tabIndex={0}
    ><FaExpandAlt /></button>
  }

  function getClassName(variant) {
    let className = "btn btn-sm"
    if(variant) {
        className += " " + variant
    }
    return className
  }

import { DateTime } from "luxon";
import { useCallback, useState } from "react";

import "./PostSplash.css";
import { FaMoon, FaMugHot, FaQuestion, FaRocket, FaStar, FaSun } from "react-icons/fa";

export function PostSplash(props: { createPost: () => void }) {
  const { createPost } = props;

  // this is to make sure we can scroll correctly
  const [offsetPixels, setOffsetPixels] = useState(0);

  // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
  const handleRect = useCallback((node) => {
    setOffsetPixels(node?.getBoundingClientRect().top);
  }, []);

  const currentTime = DateTime.local();
  const currentTimeHour = currentTime.hour;

  let greeting = "";
 let Icon = FaQuestion
  let color = "";
  if (5 <= currentTimeHour && currentTimeHour <= 12) {
    greeting = "Good morning!";
    Icon = FaMugHot;
    color = "rgb(45, 105, 180)";
  } else if (12 < currentTimeHour && currentTimeHour <= 17) {
    greeting = "Good afternoon";
    Icon = FaSun;
    color = "rgb(240, 180, 60)";
  } else if (18 < currentTimeHour && currentTimeHour <= 22) {
    greeting = "Good evening";
    Icon = FaMoon;
    color = "rgb(80, 120, 180)";
  } else {
    greeting = "Good evening";
    Icon = FaStar;
    color = "rgb(0, 0, 45)";
  }

  const postSplashStyle = {
    height: `calc(100vh - ${offsetPixels}px)`,
  };

  return (
    <div ref={handleRect} className="postSplash" style={postSplashStyle}>
      <Icon
        className="fa-10x splash-icon"
        color={color}
      />
      <div className="spiel">
        <h1>{greeting}</h1>
        <h2>
          Select a post on the left to get started, or{" "}
          <button className="btn btn-primary" onClick={() => createPost()}>
            <FaRocket className="mr-1" /> New Post
          </button>
        </h2>
      </div>
    </div>
  );
}


// export function PostSplash(props: { createPost: () => void }) {
//   const { createPost } = props;
//   const { forumType } = useContext(ForumContext)
//   const firestoreDocPath = forumType;
//   const { userData } = useContext(ProfileContext);
//   const role = userData?.courseRole;
//   const courseId = useCourseId();
//   const firebaseDocPath =  `forumStaticData/${courseId}/splash/${forumType}`


//   return (
//     <div>
//       <TipTap
//         firebaseDocPath={firebaseDocPath}
//         editable={isStaffRole(role)}
//         buttonBar={DiscussionButtonBar}
//       />
      
//     </div>
//   )

// }
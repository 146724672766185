import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { FaICursor, FaPlay, FaStop, FaTerminal } from 'react-icons/fa';
import { PyodideClient } from '../../../pyodide/PyodideProvider';
import { MonacoTipTap } from './runnableKarel/TipTapCodeEditor';


// tiptap
import { NodeViewWrapper } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import styled from 'styled-components';

const MAX_OUTPUT_LINES = 200

export const RunnableCode = Node.create({
  name: 'runnable-code',
  group: 'block',
  atom: true,
  draggable: true,
  addAttributes() {

    return {
      // we need to make output a state (not an attribute)
      // so that a big for loop doesn't cause too many server writes
      // If we do want it to have persistent output, we can debounce it.
      // Another note: for textbooks (which also use tiptap) not showing
      // output at the start can be a feature.
      code: {
        default: '# your code here\n',
      }
    }
  },

  parseHTML() {

    return [
      {
        tag: 'runnable-code',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {

    return ['runnable-code', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    const node = ReactNodeViewRenderer(RenderRunableCode)
    return node

  },
})

export const RenderRunableCode = (props) => {
  // props for keeping track of the output. 
  const outputRef = useRef(null);

  // for controlling inputs
  const [isInput, setIsInput] = useState(false);
  const [resolveInputPromise, setResolveInputPromise] = useState(null); // State to hold the function that resolves the promise
  const inputRef = useRef(null);



  const [isRunning, setIsRunning] = useState(false);
  const [output, setViewedOutput] = useState('');
  const [showOutput, setShowOutput] = useState(false);
  // we should not need this. workaround beacuse "editable" is not reactive
  const [isEditable, setIsEditable] = useState(props.editor.isEditable)
  const pyodideClientRef = useRef(null);

  // Update the onInputEntered to resolve the promise
  const onInputEntered = (input) => {
    if (resolveInputPromise) {
      resolveInputPromise(input);
      setResolveInputPromise(null); // Reset for the next input
      // do we need to clear the input?
    }
  };

  const setPyodideHandlers = () => {
    pyodideClientRef.current.setHandlers(
      () => {
        // Func called on end
        setIsRunning(false)
      },
      (stdout) => {
        // Func called on stdout
        appendOutput(stdout)
      },
      (stderr) => {
        // Func called on error
        appendOutput(stderr);
        setIsRunning(false)
      },
      (inputPrompt) => {
        setIsInput(true);
        // Set up a new promise that will be resolved when the input is entered
        const promise = new Promise((resolve) => {
          setResolveInputPromise(() => resolve);
        });
        return promise.then((input) => {
          setIsInput(false); // Reset input state
          appendOutput(input, false); // Append the input to the output (without a newline)
          return input; // Return the input value that was set
        });
      },
      null,
      null
    )
  }

  let runCode = () => {
    setOutput('');
    let pyodideClient = pyodideClientRef.current;
    setPyodideHandlers();
    const code = getCode();
    setIsRunning(true)
    pyodideClient.runCode(code, { name: "main.py" }, false, 500);
    setShowOutput(true);
  };


  useEffect(() => {
    pyodideClientRef.current = new PyodideClient();
    pyodideClientRef.current.setHandlers(
      () => {
        // Func called on end
        setIsRunning(false)
      },
      (stdout) => {
        // Func called on stdout
        appendOutput(stdout)
      },
      (stderr) => {
        // Func called on error
        appendOutput(stderr);
        setIsRunning(false)
      },
      () => {/*Input run*/ },
      null
    )
  }, [])

  // this is fired by writing an empty string each time editable changes :(
  props.editor.on('transaction', () => {
    setIsEditable(props.editor.isEditable)
  })


  const onClick = (e) => {
    // e.stopPropagation();
  };

  const getCode = () => {
    return props.node.attrs.code || ""
  };

  const getOutput = () => {
    return output
  }

  const getShowOutput = () => {
    return showOutput
  }

  const setOutput = (newValue) => {
    let lines = newValue.split('\n')
    // the off by one is for the final return
    let nLines = lines.length - 1
    if (nLines > MAX_OUTPUT_LINES) {
      // off by one here for the same reason
      let toKeep = lines.slice(-(MAX_OUTPUT_LINES + 1))
      newValue = `last ${MAX_OUTPUT_LINES} lines...\n` + toKeep.join('\n')
    }
    setViewedOutput(newValue)
    // scroll to the bottom of the output
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }

  var currOutput = ''
  const appendOutput = (newValue, nl = true) => {
    if (nl && currOutput.length > 0) {
      currOutput = `${currOutput}\n${newValue}`;
    } else {
      currOutput = `${currOutput}${newValue}`;
    }
    setOutput(currOutput)
  }

  const onValueChange = (newCode) => {
    props.updateAttributes({
      code: newCode
    })
  };

  return (
    <NodeViewWrapper data-drag-handle>
      <div
        style={{
          border: 'lightgrey',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
        onClick={(e) => onClick(e)}
        spellCheck="false"
        draggable="true"
      >
        <MonacoTipTap
          mode="python"
          value={getCode()}
          onChange={(e) => onValueChange(e)}
          readOnly={!isEditable}
        />
        {getShowOutput() && (
          <pre
            ref={outputRef}
            spellCheck="false"
            style={{
              backgroundColor: 'black',
              color: 'white',
              height: '200px',
              marginBottom: '0px',
              padding: '5px',
            }}
          >
            {getOutput()}
            {isInput && (
              <ConsoleInput
                autoFocus
                type="text"
                id="input"
                name="input"
                ref={inputRef}
                // on enter call onInputEntered (onkeypress is depricated)
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onInputEntered(e.target.value)
                  }
                }}

              />
            )}
          </pre>
        )}
        <RunButtonRow
          isRunning={isRunning}
          runCode={() => runCode()}
          showOutput={getShowOutput()}
          setShowOutput={setShowOutput}
          pyodideClient={pyodideClientRef.current}
          resolveInputPromise={resolveInputPromise}
          setResolveInputPromise={setResolveInputPromise}
          disabled={false}
        />

      </div>
    </NodeViewWrapper>
  );
};

const RunButtonRow = ({
  isRunning,
  resolveInputPromise,
  setResolveInputPromise,
  pyodideClient,
  runCode,
  disabled,
  showOutput,
  setShowOutput,
}) => {
  let runStopText = (
    <span>
      <FaPlay /> {'Run'}
    </span>
  );
  if (isRunning) {
    runStopText = (
      <span>
        <FaStop /> {'Stop'}
      </span>
    );
  }

  let showHideOutput = (
    <span>
      <FaTerminal /> Show
    </span>
  );
  if (showOutput) {
    showHideOutput = (
      <span>
        <FaTerminal /> Hide
      </span>
    );
  }

  const onRunStopClick = () => {
    if (isRunning) {
      pyodideClient.raisePyStopFlag()
      if (resolveInputPromise) {
        resolveInputPromise(''); // Or some special value indicating a stop
        setResolveInputPromise(null);
      }
      // window.pyodide.runPython(`raise Exception('Stop')`);
      // setIsRunning(false); // Add this line
    } else {
      runCode();
    }
  };

  return (
    <>
      <button
        onClick={() => onRunStopClick()}
        className="btn btn-light btn-sm "
        style={{ width: '80px' }}
        disabled={disabled}
      >
        {runStopText}
      </button>

      <button
        onClick={() => setShowOutput(!showOutput)}
        style={{ width: '80px' }}
        className="btn btn-light btn-sm "
        disabled={disabled}
      >
        {showHideOutput}
      </button>
    </>
  );
};

const ConsoleInput = styled.input`
  background-color: black;
  color: white;
  border: none;
  width: 100%;
  min-width: 50px;
  width: fit-content;
  margin: 0;
  font-size: 1em;
  &:focus {
    outline: none;
  }
`;
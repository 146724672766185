import { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { getSingleValue } from 'course/editors/OnEventAlerts';

export const EditableMap = ({ currValue, setItemValue }) => {
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const handleAddItem = () => {
    if (newKey && !currValue.hasOwnProperty(newKey)) { // Add only if key is new
      setItemValue({ ...currValue, [newKey]: newValue });
      setNewKey('');
      setNewValue('');
    } else {
      // Optionally handle the case where the key already exists
    }
  };

  const handleDeleteItem = (key) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const { [key]: deletedValue, ...remaining } = currValue; // Destructuring to remove the key-value pair
        setItemValue(remaining);
      }
    });
  };

  const handleEditItem = async (key) => {
    const oldValue = currValue[key];
    const newValue = await getSingleValue('Edit Map Item', 'Value', oldValue);

    if (newValue !== null && newValue !== undefined) {
      setItemValue({ ...currValue, [key]: newValue });
    }
  };

  return (
    <div>
      <div className="input-group mb-3">
        <input 
          type="text" 
          className="form-control" 
          placeholder="New key" 
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
        />
        <input 
          type="text" 
          className="form-control" 
          placeholder="New value" 
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <div className="input-group-append">
          <button 
            className="btn btn-outline-secondary" 
            type="button"
            onClick={handleAddItem}
          >Add Item</button>
        </div>
      </div>
      {Object.entries(currValue).map(([key, value]) => (
        <MapItem
          key={key}
          id={key}
          value={value}
          handleDeleteItem={() => handleDeleteItem(key)}
          handleEditItem={() => handleEditItem(key)}
        />
      ))}
    </div>
  );
};

const MapItem = ({ id, value, handleDeleteItem, handleEditItem }) => {
  return (
    <div className='d-flex justify-content-between my-2'>
      <div key={id}>
        <span>{id}: {value}</span>
      </div>
      <div>
        <span className='d-flex'>
          <button
            onClick={() => handleDeleteItem(id)}
            className='btn btn-sm '
          >
            <FaTrash />
          </button>
          <button
            className='btn btn-sm '
            onClick={() => handleEditItem(id)}
          >
            <FaEdit />
          </button>
        </span>
      </div>
    </div>
  );
};

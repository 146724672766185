

export const Collab = {
    JOIN: "join",
    LEAVE: "leave",
    CLIENT_ID: "clientId",
    KAREL: "karel",
    CONSOLE: "console",
    GRAPHICS: "graphics",
    INTERRUPT: "interrupt",
    MOUSE: "mouse",
    TAB_CHANGE: "tabChange",
    CANVAS_VIEW: "canvasView",
    TERMINAL_VIEW: "terminalView",
    LEFT_COL_VIEW: "codeView",
}

export const SPECIAL_CASE_ELEMENTS = ["terminal", "root", "canvas"]
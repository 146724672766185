import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { getAuth, verifyBeforeUpdateEmail } from 'firebase/auth';

export const ChangeEmailPage = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<ChangeEmailMain/>}
      rightColumn={<></>}
      singleColumn={<ChangeEmailMain />}
    />
  );
}
const ChangeEmailMain = () => {
    const auth = getAuth();
    const user = auth.currentUser;
//   // Replace 'YOUR_API_KEY' with your Firebase project API key
//   const actionCodeSettings = {
//     url: `${process.env.REACT_APP_FIREBASE_PROJECT_URL}/email-change?apiKey=${YOUR_API_KEY}`,
//     handleCodeInApp: true,
//   };

    const oldEmail = user.email;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUpdateEmail = async () => {
    setLoading(true);
    setMessage('');

    try {
        await verifyBeforeUpdateEmail(user, email);
        setMessage(`A verification email has been sent to ${email}. Please check your email and follow the instructions to update your email address. If this was a mistake, please check ${oldEmail} for an email from Code in Place and follow the instructions to cancel the email change.`);
    } catch (error) {
        setMessage(`Error: ${error.message}`);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="update-email">
        <h3>Change email</h3>
        <p>Your current email address with Code in Place is <b>{user.email}</b>. To change your email, please type your new email address below and click the blue button. This will send an email to the new email address you provide; please note <b>your email address will not be changed in our system until you click the verification link in the email we send you.</b> If you can't find the email, check your spam folder!</p>
        <p>If you receive an error that the request requires recent authentication, please log out and log back in before performing this operation.</p>
      <input
        type="email"
        value={email}
        onChange={handleChange}
        placeholder="Enter new email"
      />
      <div className="mt-2">
        <Button variant="primary" onClick={handleUpdateEmail} disabled={loading}>
          {loading ? 'Updating...' : 'Update Email'}
        </Button>
      </div>
      {message && (
        <div className="mt-2">
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};
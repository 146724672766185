import IDE from "ide/IDE"
import { IDEProvider } from "ide/contexts/IDEContext"
import { diagnosticProjectLoader } from "ide/hooks/loadIdeData"
import { CollabProvider } from "ide/sync/CollabContext"
import { useParams } from "react-router"

export const DiagnosticQuestion = ()=>{
  const {questionId} = useParams()
  return ( 
  <IDEProvider 
    key={questionId}
    useProject={diagnosticProjectLoader} 
    isDiagnostic={true}
  >
    <CollabProvider>
      <DiagnosticIDE/>
    </CollabProvider>
  </IDEProvider>
)}

const DiagnosticIDE = () => {
  return <IDE/>
}
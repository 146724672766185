import styled from "styled-components";

export const TextBlockLesson = ({ data }) => {
  return <FullContent>
    <CenteredContent>
        <p>{data.text}</p>
    </CenteredContent>
  </FullContent>
}

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width:100%;
`;
import { SplashCoverpage } from "../lessontypes/SplashCoverpage"
import { KarelCommandsLesson } from "../lessontypes/KarelCommandsLesson"
import { YouTubeLesson } from "../lessontypes/YoutubeLesson"
import { SpeechBubbleLesson } from "../lessontypes/SpeechBubbles"
import { LearningGoalsLesson } from "../lessontypes/LearningGoalsLesson"
import { CompletePage } from "../lessontypes/CompletePage"
import { RunnableLesson } from "../lessontypes/codeExamples/RunnableLesson"
import { SkippableVideo } from "../lessontypes/SkippableVideo"
import { TextBlockLesson } from "../lessontypes/TextBlock"
import { CuriosityWonder } from "../lessontypes/CuriosityWonder"
import { GPTeachLesson } from "../lessontypes/GPTeachLesson"

export function filterLessonData(rawData) {
  const lessonNum = rawData?.lessonNum || 0
  const lessonTitle = rawData?.title || '' 
  const slides = rawData?.slides || []
  const examples = rawData?.examples || []
  const readings = rawData?.readings || []
  const slidesInfo = rawData?.slidesInfo || {}
  const title = 'Lesson ' + lessonNum + '. ' + lessonTitle
  const lessonData = {
    title: title,
    shortTitle: lessonTitle,
    readings: rawData?.readings || [],
    examples: rawData?.examples || [],
    slides: []
  }

  // loop over the slides and add to our pages
  for (const slideId of slides) {
    if (!(slideId in slidesInfo)) {
      console.log(`slideId ${slideId} not in slideData`)
      continue
    }
    const rawSlideData = slidesInfo[slideId]
    const slideData = convertSlide(lessonData, rawSlideData, slideId)
    if(slideData) {
      lessonData.slides.push(slideData)
    }
  }

  return lessonData
}

function convertSlide(lessonData, slideData, slideId) {

  const type = slideData.type.toLowerCase()
  if (type === 'splash') {
    return {
      component: SplashCoverpage,
      title: slideData.title,
      id: slideId,
      continueButtonText: 'Start',
      data: slideData
    }
  }
  if (type === 'karelcommand') {
    return {
      component: KarelCommandsLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'video') {
    return {
      component: YouTubeLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'speechbubble') {
    return {
      component: SpeechBubbleLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'karelrunnable' || type === 'consolerunnable' || type === 'graphicsrunnable') {
    return {
      component: RunnableLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'learninggoals') {
    return {
      component: LearningGoalsLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'skippablevideo') {
    return {
      component: SkippableVideo,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'textblock') {
    return {
      component: TextBlockLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if(type === 'curiositywonder') {
    return {
      component: CuriosityWonder,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type == 'gpteach') {
    return {
      component: GPTeachLesson,
      title: slideData.title,
      id: slideId,
      data: slideData
    }
  }
  if (type === 'complete') {
    return {
        component: CompletePage,
        title: 'Complete',
        id: 'complete',
        continueButtonText: 'Mark Lesson Complete!',
        data: {
          ...slideData,
          readings: lessonData.readings,
          examples: lessonData.examples,
        }
      }
    }
  return null
}

const completeSchema = [
  {
    key: 'speechText',
    type: 'string',
    label: 'Text',
  },
  {
    key: 'speechIcon',
    type: 'img',
    label: 'Image',
  }
]

const videoSchema = [
  {
    key: 'videoId',
    type: 'string',
    label: 'Video URL',
  },
  {
    key: 'translations',
    type: 'map',
    label: 'Translations'
  },
  {
    key: 'readings',
    type: 'url-list',
    label: 'Readings'
  },
  {
    key: 'slides',
    type: 'pdf',
    label: 'Slides'
  }
]

const skippableVideoSchema = [
  {
    key: 'videoId',
    type: 'string',
    label: 'Video URL',
  },
  {
    key: 'translations',
    type: 'map',
    label: 'Translations'
  },
  {
    key: 'skippableMessage',
    type: 'string',
    label: 'Skippable Message'
  },
  {
    key: 'readings',
    type: 'url-list',
    label: 'Readings'
  },
  {
    key: 'slides',
    type: 'pdf',
    label: 'Slides'
  }
]

const splashSchema = [
  {
    key: 'lectureName',
    type: 'string',
    label: 'Lecture Name',
  },
  {
    key: 'authors',
    type: 'string',
    label: 'Authors',
  },
  {
    key: 'course',
    type: 'string',
    label: 'Course Name',
  },
  {
    key: 'lessonLengthMins',
    type: 'number',
    label: 'Lesson Length (minutes)',
  }
]

const speechbubbleSchema = [
  {
    key: 'text',
    type: 'string',
    label: 'Text',
  },
  {
    key: 'imgUrl',
    type: 'img',
    label: 'Image',
  }

]

const karelRunnableSchema = [
  {
    key: 'startState',
    type: 'karelworld',
    label: 'Start State',
  },
  {
    key: 'starterCode',
    type: 'code',
    label: 'Code',
  }

]

const consoleRunnableSchema = [
  {
    key: 'starterCode',
    type: 'code',
    label: 'Code',
  }
]

const graphicsRunnableSchema = [
  {
    key: 'starterCode',
    type: 'code',
    label: 'Code',
  }
]

const learningGoalsSchema = [
  {
    key: 'learningGoals',
    type: 'list',
    label: 'Learning Goals',
  },
  {
    key: 'imgUrl',
    type: 'img',
    label: 'Image'
  }
]

const curiosityWonderSchema = [
  {
    key: 'imgUrl',
    type: 'img',
    label: 'Image'
  }
]

const karelcommandSchema = [
  {
    key: 'startState',
    type: 'karelworld',
    label: 'Start State',
  },
  {
    key: 'goalState',
    type: 'karelworld',
    label: 'Goal State',
  },
  {
    key: 'commands',
    type: 'list',
    label: 'Commands',
  }
]

const TextBlockSchema = [
  {
    key: 'text',
    type: 'string',
    label: 'Text'
  }
]

const GPTeachSchema = [
  {
    key: 'scenario',
    type: 'string',
    label: 'Scenario'
  }, 
  {
    key: 'personas',
    type: 'map',
    label: 'Student Personas'
  }
]



export const lessonSchemas = {
  video: videoSchema,
  splash: splashSchema,
  speechbubble: speechbubbleSchema,
  karelcommand: karelcommandSchema,
  karelrunnable: karelRunnableSchema,
  consolerunnable: consoleRunnableSchema,
  graphicsrunnable: graphicsRunnableSchema,
  learninggoals: learningGoalsSchema,
  complete: completeSchema,
  skippablevideo: skippableVideoSchema,
  textblock: TextBlockSchema,
  curiositywonder: curiosityWonderSchema,
  gpteach: GPTeachSchema

}

export const lessonSlideTypes = {
  'splash': 'Splash',
  'speechbubble': 'Speechbubble',
  'video': 'Video',
  'karelcommand': 'Karel Command',
  'karelrunnable': 'Karel Runnable',
  'consolerunnable': 'Console Runnable',
  'graphicsrunnable': 'Graphics Runnable',
  'learninggoals': 'Learning Goals',
  'complete': 'Complete',
  'skippablevideo': 'Skippable Video',
  'textblock': 'Text Block',
  'curiositywonder': 'Curiosity Wonder',
  'gpteach': "GPTeach"
}
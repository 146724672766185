import { Spin, Tag } from "antd";
import Gate from "contexts/Gate";
import { ProfileContext } from "contexts/ProfileContext";
import { useTags } from "course/forum/firebase/ForumStaticDataFetcher";
import { ForumContext } from "course/forum/ForumContext";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useHits,
  useInstantSearch,
  useRefinementList,
} from "react-instantsearch-hooks-web";

import "./TagFilters.css";

export function TagFilters(props) {
  const { indexUiState } = useInstantSearch();
  const { items, refine } = useRefinementList({
    attribute: "tags",
    sortBy: ["name:asc"],
  });
  const { tags, isLoading } = useTags();
  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);
  const { userData } = useContext(ProfileContext);
  const { forumId, privatePostThreshold } = useContext(ForumContext);
  const { hits } = useHits();

  const filteredHits = hits.filter(
    (hit) =>
      !hit.isPrivate ||
      hit.authorUuid == user.uid ||
      Gate.hasRole(privatePostThreshold)(userData)
  );

  const filteredTags = filteredHits.reduce(
    (accumulator: string[], currentValue: any) => {
      return accumulator + currentValue.tags;
    },
    []
  );

  if (!indexUiState.query) {
    return null;
  }

  if (isLoading) {
    return <Spin />;
  }

  const selectedFilters = items.filter(
    (item) => item.isRefined && filteredTags.includes(item.value)
  );
  const unselectedFilters = items.filter(
    (item) => !item.isRefined && filteredTags.includes(item.value)
  );

  const hasTags = selectedFilters.length != 0 || unselectedFilters.length != 0;

  return (
    <div className="filterContainer">
      {hasTags && <span className="filterTitle"> Filter by Tag </span>}
      <div className="filterList">
        {selectedFilters.map((tag) => (
          <Tag
            color={tags[tag.value].bgColor}
            onClick={() => refine(tag.value)}
            className="filterTag"
            style={{ opacity: 1 }}
          >
            {tags[tag.value].name} ({tag.count})
          </Tag>
        ))}
      </div>
      <div className="filterList">
        {unselectedFilters.map((tag) => (
          <Tag
            color={tags[tag.value].bgColor}
            onClick={() => refine(tag.value)}
            className="filterTag"
            style={{ opacity: 0.5 }}
          >
            {tags[tag.value].name} ({tag.count})
          </Tag>
        ))}
      </div>
    </div>
  );
}

import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FaBook, FaEdit } from 'react-icons/fa';
import { useWindowSize } from "react-use-size";
import { TipTap } from "components/richTextEditor/TipTap/TipTap"
import { HandoutButtonBar } from "components/richTextEditor/TipTap/buttonbars/HandoutButtonBar"
import { TodoList } from './TodoList'
import "./module-styles.css"
import { useCourseId } from 'hooks/router/useUrlParams';

export const ModuleCard = ({ courseId, moduleId, moduleItem, innerRef, hasEditPermissions }) => {
  const [showModal, setShowModal] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768

  return (
    <div id={moduleId} ref={innerRef} className='mb-3 mt-3' aria-label={`Module ${moduleItem.title}`}>
      <Card key={moduleId} id={moduleId} className='module-card'>
        <Card.Img
          variant="top"
          style={{ height: '10rem', objectFit: 'cover' }}
          src={moduleItem.thumbnailUrl}
        />
        <Card.ImgOverlay style={{ 
          height: '10rem', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'flex-end'}}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <ModuleTitle title={moduleItem.title} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
              {isMobile ?
                <Button
                  variant='light'
                  className='custom-light-button'
                  onClick={() => setShowModal(true)}
                  style={{ width: '45px', height: '45px', padding: '0' }}
                >
                  <FaBook />
                </Button>
                :
                <Button
                  variant='light'
                  className='custom-light-button'
                  onClick={() => setShowModal(true)}
                >
                  <FaBook /> &nbsp;Overview
                </Button>
              }
            </div>

            <OverviewModal
              courseId={courseId}
              moduleId={moduleId}
              moduleItem={moduleItem}
              show={showModal}
              onHide={() => setShowModal(false)}
              hasEditPermissions={hasEditPermissions}
            />
          </div>

        </Card.ImgOverlay>
        <Card.Body>

          <TodoList todoItemList={moduleItem.items} />
        </Card.Body>
      </Card>
    </div>

  );
}

const ModuleTitle = ({ title }) => {
  return (
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.85)', // white with transparency
      borderRadius: '5px', // rounded corners
      padding: '5px', // padding between the edges and the text
    }}>
      <span style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '0px' }}>
        {title}
      </span>
    </div>
  )
}


const OverviewModal = ({ courseId, moduleId, moduleItem, show, onHide, hasEditPermissions }) => {
  const firebaseDocPath = `roadmap/${courseId}/descriptions/${moduleId}`
  const [isEditing, setIsEditing] = useState(false)

  return <Modal
    show={show}
    onHide={onHide}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {moduleItem.title} Overview
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TipTap
        firebaseDocPath={firebaseDocPath}
        editable={isEditing}
        collaborative={isEditing}
        buttonBar={HandoutButtonBar}
        showLoadingSkeleton={true}
      />
      {hasEditPermissions && <button className="btn btn-light" onClick={() => setIsEditing(!isEditing)}>
        {isEditing ? "Done" : <FaEdit />}
      </button>}
    </Modal.Body>
  </Modal>
}

export const CertificateCard = ({ certificateUrl, certificateActive }) => {
  const courseId = useCourseId()


  if(courseId !== "cip3" && courseId !== "cip4") { 
    return <EndOfCourseCard/>
  }
  

  const buttonStyle = { height: 'fit-content', padding: '1rem', marginTop: '2rem' }
  if (!certificateActive) {
    buttonStyle['opacity'] = '90%'
  }
  return <Card>
    <Card.Img src='/stanford/jacaranda.jpg'></Card.Img>
    <Card.ImgOverlay style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <Card.Title>
                Congratulations on finishing the course!
            </Card.Title> */}
      {certificateActive &&  <Button
        variant="light"
        href={certificateUrl}
        style={buttonStyle}
        disabled={!certificateActive}
      >
        {certificateActive ? "Congratulations on finishing the course! 🎉 Click to view your certificate." : "Your certificate will be available once you've completed all the modules!"}
      </Button>
      }
    </Card.ImgOverlay>

  </Card>
}

export const EndOfCourseCard = () => { 
  return <Card>
    <Card.Img aria-label="A picture of jacaranda trees on Stanford campus" src='/stanford/jacaranda.jpg'></Card.Img>
    <Card.ImgOverlay style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
      <Card.Title style={{backgroundColor:'#ffffffdd', padding:10, borderRadius:5}}>
                That's all for now!
            </Card.Title> 
     
    </Card.ImgOverlay>
  </Card>
}
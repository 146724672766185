import styled from "styled-components";
import { NewProjectProposalButton } from "./NewProjectProposalButton";
import { lightPurple } from "styles/colors";

export const ProjectIdeaContainer = ({ idea }) => {
  return <>
    <ProjectIdeaOuter className="alert">
      {idea}
    </ProjectIdeaOuter>
    

  </>;
}

const ProjectIdeaOuter = styled.div`
  background-color: ${lightPurple}
`
import { ConnectedWidget } from './components/ConnectedWidget';
import { SentRequestWidget } from './components/SentRequestWidget';
import { ViewRequestWidget } from './components/ViewRequestWidget';
import { DismissedRequestWidget } from './components/DismissedRequestWidget';
import { SendRequestForm } from './components/SendRequestForm';

export const ConnectionProfileWidget= (props) => {
    const {userId, viewerId, loading, status} = props
    if (userId === viewerId) return <></>
    if (loading) return <></>

    if (status === 'connected') {
        return <ConnectedWidget {...props}/>
    }
    if (status === 'sentRequest') {
        return <SentRequestWidget {...props}/>
    }
    if (status === 'receivedRequest') {
        return <ViewRequestWidget {...props}/>
    }
    if (status === 'dismissedRequest') {
        return <DismissedRequestWidget {...props}/>
    }
    if (status === 'notConnected') {
        return <SendRequestForm {...props}/>
    }
    return <></> // we should never get here
}

/*
    Exports hooks to track and log the presence of a user.
    This is used to determine if a user is online on a certain component or not.

    The way we track presence is via the notion of a "presence heartbeat".
    A presence heartbeat is a timestamp that is updated every k seconds.
    If the timestamp is older than some t seconds, then the user is considered offline.

    While this hook is active, it will update the presence heartbeat every k seconds via
    a chain of setTimeouts and a useIdle. If the user is idle, then we will clear
    the setTimeouts and stop updating the presence heartbeat.
*/

import { useIdleTimer } from 'react-idle-timer';

import { database } from "../../firebaseApp";
import { serverTimestamp } from "firebase/database";
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface IActivePresence {
    timestampMS: number;
    timestamp: string;
    isIdle: boolean;
    status: "connected" | "disconnected";
    data: any;
}

export const useActivePresence = ({data, idleTiemoutMS, firebasePath, dataDebounceMS = 200}) => {
    const ref = database.ref(firebasePath);

    // const dataDebounced = data;
    const updateDataDebounced = useDebouncedCallback((data) => {
        ref.update({
            data: data
        });
    }, dataDebounceMS);

    // CASE: user is idle. This happens when the user is not interacting with the page.
    const onIdle = () => {
        // if data contains timestampMS or timestamp, output a warning log

        ref.update({
            isIdle: true,
            timestampMS: Date.now(),
            timestampMSv2: serverTimestamp(),
            timestamp: (new Date()).toISOString(),
        });
    }

    // CASE: user resumes activity.
    const onActive = () => {
        ref.update({
            isIdle: false,
            status: "connected",
            timestampMS: Date.now(),
            timestampMSv2: serverTimestamp(),
            timestamp: (new Date()).toISOString(),
        });
    }

    useIdleTimer({
        onIdle: onIdle,
        onActive: onActive,
        timeout: idleTiemoutMS,
    });

    // update data immediately when it changse
    useEffect(() => {
        updateDataDebounced(data);
    }, [data])


    useEffect(() => {
        const listener = database.ref('.info/connected').on('value', (snap) => {
            if (snap.val() == false) {
                return;
            };


            // CASE: user realtime database connections closes. This happens when the user closes the tab or internet connection is lost.
            ref.onDisconnect().update({
                status: "disconnected",
                timestampMS: Date.now(),
                timestampMSv2: serverTimestamp(),
                timestamp: (new Date()).toISOString(),
            });

            // CASE: user first connects to the database on the parent componenet
            ref.update({
                status: "connected",
                isIdle: false,
                timestampMS: Date.now(),
                timestampMSv2: serverTimestamp(),
                timestamp: (new Date()).toISOString(),
            });

            });

        return () => {
            // CASE: parent component unmounts. there is no disconnect in this case
            database.ref('.info/connected').off('value', listener);
            ref.update({
                status: "disconnected",
                timestampMS: Date.now(),
                timestampMSv2: serverTimestamp(),
                timestamp: (new Date()).toISOString(),
            });
        }
    }, []);

}

const Checkbox = ({ label, value, setValue }) => {

  const toggleCheckboxChange = () => {
    setValue(!value);
  };

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id="customCheck1"
        checked={value}
        onChange={toggleCheckboxChange}
      />
      <label className="form-check-label" htmlFor="customCheck1">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

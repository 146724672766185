import { deepCopy } from "@firebase/util"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useState } from "react"
import { getProjectType } from "ide/utils/general"
import { KarelUnitTest, makeDefaultWorld } from "./KarelUnitTest"
import { ConsoleUnitTest } from "./ConsoleUnitTest"


export const EditUnitTests = ({ serverAssnData, courseId, assnId }) => {
  const serverUnitTests = serverAssnData?.unitTests?.unitTests
  const initUnitTests = serverUnitTests ? serverUnitTests : []
  const [unitTests, setUnitTests] = useState(initUnitTests)
  const [dirtyBit, setDirtyBit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const assnType = getProjectType({}, serverAssnData)

  const saveUnitTests = () => {
    setIsSaving(true)
    saveToDatabase(courseId, assnId, unitTests, () => {
      setDirtyBit(false)
      setIsSaving(false)
    })
  }

  const deleteUnitTest = (i) => {
    setUnitTests(oldUnitTests => {
      const newUnitTest = deepCopy(oldUnitTests)
      newUnitTest.splice(i, 1)
      return newUnitTest
    })
    setDirtyBit(true)
  }

  const editUnitTest = (i, key, value) => {
    setUnitTests(oldUnitTests => {
      const newUnitTest = deepCopy(oldUnitTests)
      newUnitTest[i][key] = value
      return newUnitTest
    })
    setDirtyBit(true)
  }

  const setUnitTestIndex = (oldIndex, newIndex) => {
    setUnitTests(oldUnitTests => {
      const newUnitTest = deepCopy(oldUnitTests)
      newUnitTest.splice(newIndex, 0, newUnitTest.splice(oldIndex, 1)[0])
      return newUnitTest
    })
    setDirtyBit(true)

  }

  const addUnitTest = () => {
    let pre, post

    console.log(assnType)

    switch (assnType) {
      case "karel":
        pre = makeDefaultWorld()
        post = makeDefaultWorld()
        setUnitTests(oldUnitTests => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const newUnitTest = deepCopy(oldUnitTests)
          newUnitTest.push({
            'pre': pre,
            'post': post,
            'name': `${pre.nRows}x${pre.nCols}`
          })
          return newUnitTest
        })
        break
      case 'console':
        pre = []
        post = []
        setUnitTests(oldUnitTests => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const newUnitTest = deepCopy(oldUnitTests)
          newUnitTest.push({
            'pre': pre,
            'post': post,
            'description': "",
            'name': `New Console Test`,
            'key': Date.now(),
            'dialogue': []
          })
          return newUnitTest
        })
        break
      case 'graphics':
        // graphics use Hidden Examples
        break
    }
    setDirtyBit(true)
  }

  let saveButtonTxt = dirtyBit ? 'Save' : 'Saved'
  if (isSaving) {
    saveButtonTxt = isSaving ? 'Saving...' : saveButtonTxt
  }


  return <div className="">
    <div className='d-flex flex-column mt-3'>
      <h3>Unit Tests</h3>
      <p>These unit tests will be run on the student's submitted code, and results will be shown to the student.</p>
      {unitTests.map(function (unitTestData, i) {
        if (!unitTests[i].key) {
          unitTests[i].key = i + Date.now()
        }
        switch (assnType) {
          case "karel":
            return <KarelUnitTest
              unitTestData={unitTestData}
              serverAssnData={serverAssnData}
              deleteUnitTest={() => deleteUnitTest(i)}
              editUnitTest={(k, v) => editUnitTest(i, k, v)}
              setUnitTestIndex={(newIndex) => setUnitTestIndex(i, newIndex)}
              unitTests={unitTests}
              key={unitTests[i].key}
              index={i}
            />
          case "console":
            return <ConsoleUnitTest
              unitTestData={unitTestData}
              serverAssnData={serverAssnData}
              deleteUnitTest={() => deleteUnitTest(i)}
              editUnitTest={(k, v) => editUnitTest(i, k, v)}
              setUnitTestIndex={(newIndex) => setUnitTestIndex(i, newIndex)}
              unitTests={unitTests}
              key={unitTests[i].key}
              index={i}
            />
          case "graphics":
            break
        }
      })}

    </div>
    <button onClick={() => addUnitTest()} className="btn btn-secondary ">Add Unit Test</button>
    <button
      disabled={!dirtyBit || isSaving}
      onClick={() => saveUnitTests()}
      style={{ width: 100 }}
      className="btn btn-primary ml-2"
    >{saveButtonTxt}</button>
  </div>
}

async function saveToDatabase(
  courseId,
  assnId,
  unitTests,
  callbackFn
) {
  const db = getFirestore()
  const path = `assns/${courseId}/assignments/${assnId}/docs/unitTests`
  console.log('saveUnitTests', path)
  const assnRef = doc(db, path)
  await setDoc(assnRef, { unitTests: unitTests }, { merge: true })
  callbackFn(unitTests)
}






// const ConsoleUnitTest = ({unitTestData, serverAssnData,unitTests,deleteUnitTest, editUnitTest,index,setUnitTestIndex}) => {
//   const [outputList, setOutputList] = useState(unitTestData["post"].map((testStr, idx) => {return { text: testStr, id: Date.now() + idx}}))
//   const [inputList, setInputList] = useState(unitTestData["pre"].map((testStr, idx) => {return { text: testStr, id: Date.now() + idx}}))

//   // const [outputList, setOutputList] = useState([])
//   // const [inputList, setInputList] = useState([])
//   const [testName, setTestName] = useState(unitTestData.name)

//   const addIndex = ( output) => {
//     if(output) {
//       outputList.push({text :"", id: Date.now()})
//     }
//     else {
//       inputList.push({text :"", id: Date.now()})
//     }
//     editConsoleUnitTest()
//   }



//   const editConsoleUnitTest = () => {
//     editUnitTest("post", outputList.map((el)=>{return el.text}))
//     editUnitTest("pre", inputList.map((el)=>{return el.text}))
//     editUnitTest("name", testName)
//   }

//   const removeIndex = (index, output) => {

//     if(output) {
//       outputList.splice(index, 1);
//     } else {
//       inputList.splice(index, 1)
//     }
//     editConsoleUnitTest()
// }

// const updatePost = (idx, value, output) => {
//   if(output) {
//     outputList[idx].text = value
//   } else {
//     inputList[idx].text = value
//   }
//   editConsoleUnitTest()
// }


// const testInputList = inputList.map((input, idx) => {
//   return <TestList
//   idx={idx}
//   removeIndex={(idx) => removeIndex(idx, false)}
//   updatePost={(idx, value) => updatePost(idx, value, false)}
//   key={input.id}
//   recentVal={input.text}
//   id={input.id}
//   output={false}

//   />
// })

//   const testOutputList = outputList.map((output, idx) => {
//     return <TestList
//     idx={idx}
//     removeIndex={(idx) => removeIndex(idx, true)}
//     updatePost={(idx, value) => updatePost(idx, value, true)}
//     key={output.id}
//     recentVal={output.text}
//     id={output.id}
//     output={true}
//     />
//   })

//   return(
//   <div className="form-control mt-3 ">
//     <label><strong>Name: </strong></label>
//     <div className="d-flex">
//       <input type={"text"} className="form-control" placeholder={`Test Name`} value={testName} onChange={(e) => setTestName(e.target.value)}/>
//       <button onClick={deleteUnitTest} className="btn btn-dark"><FaTrash/></button>
//     </div>
//     <div className="mt-3">
//       <b>Test Input:</b>
//       {testInputList}
//       <button onClick={() => addIndex(false)} className="btn btn-light"><FaPlus/></button>
//     </div>
//     <div className="mt-3">
//       <b>Expected Output:</b>
//       {testOutputList}
//       <button onClick={() => addIndex(true)} className="btn btn-light"><FaPlus/>Output</button>
//       <button onClick={() => addIndex(true)} className="btn btn-light"><FaPlus/>Output</button>
//     </div>
//   </div>
//   )
// }

// const TestList = ({idx, removeIndex, updatePost, recentVal, id, output}) => {
//     const [content, setContent] = useState(recentVal);
//     const updateOutput = (e) => {
//       setContent(e.target.value)
//     }

//     useEffect(() => {
//         updatePost(idx, content)
//     }, [content])


//     return (
//         <div className="d-flex mt-3" key={id}>
//           <input type={"text"} className="form-control mb-2" placeholder={`Place ${output ? "expected output" : "test input"} here!`} value={content} onChange={updateOutput}/>
//           <button onClick={() => removeIndex(idx)} className="btn btn-light mb-2"><FaEraser/></button>
//         </div>
//     )
// }

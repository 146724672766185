export const SplashCardRight = ({ title, content }) => {
  return (
    <>
      <div className="splashCardRight">
        {title ? <div className="splashCardRightTitle">{title}</div> : <></>}
        {content}
      </div>
      <hr />
    </>
  );
};
import { useCourseId } from "hooks/router/useUrlParams"
import { useParams } from "react-router-dom"
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer"
import { FormixFormWithData } from "components/Forms/FormixFormWithData"
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext"
import { PartialLoading } from "components/loading/Loading"
import { SurveyComponent } from "./SurveyComponent"
import Swal from "sweetalert2"
import { hashString } from "react-hash-string";
import { Alert } from "react-bootstrap"

export const CourseSurvey = () => {
    const courseId = useCourseId()
    const surveyId = useParams().surveyId

    const {userData} = useContext(ProfileContext);
    const userId = userData.id
    const db = getFirestore();

    // fetch form data from server (e.g. survey questions, whether to autosave, etc.)
    const formDataRef = doc(db, `course/${courseId}/surveys/${surveyId}`);
    const [formData, formDataLoading, formDataError] = useDocumentDataOnce(formDataRef);
    // fetch survey response data for this user
    const surveyResponseRef = doc(db, `users/${userId}/docs/surveys/${courseId}/${surveyId}`);
    const [responseData, responseDataLoading, responseDataError] = useDocumentData(surveyResponseRef);

    if (formDataLoading || responseDataLoading ) {
        return <PartialLoading />
    }

    return <CoursePageBodyContainer
        mainColumn={<CourseSurveyInner 
            formData={formData}
            responseData={responseData}
            surveyResponseRef={surveyResponseRef}
            courseId={courseId}
            surveyId={surveyId}
            userId={userId}
        />}
        rightColumn={<></>}
        singleColumn={<CourseSurveyInner
            formData={formData}
            responseData={responseData}
            surveyResponseRef={surveyResponseRef}
            courseId={courseId}
            surveyId={surveyId}
            userId={userId}
        />}
    />
}

export const CourseSurveyInner = ({
    formData,
    responseData,
    surveyResponseRef,
    courseId,
    surveyId,
    userId,
}) => {

    if (!formData) {
        return <div>Survey not found</div>
    }

    if (formData.formIsClosed){
        return <div>Oops... this form is closed!</div>
    }

    const serverData = responseData ?? {}

    const handleSubmit = async (values) => {
        // if we are using autosave this is called automatically when anything in the form changes.
        // debouncing is done by the form itself in FormixFormWithData -> AutoSubmitToken so we don't need to worry about it
        
        // filter out all undefined values
        const filteredValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== undefined))
        await setDoc(surveyResponseRef, filteredValues, {merge: true})
        if (!formData.autoSave) {
            // only show swal if we are saving manually 
            Swal.fire({
                title: 'Saved!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false
            })
        }
    }

    const formFormat = makeFormFormat(formData, courseId, userId, surveyId)

    return <>
        <FormixFormWithData
            serverData={serverData}
            backUrl={formData?.backUrl?? null}
            formFormat={formFormat}
            onSubmit={handleSubmit}
            autoSave={formData?.autoSave ?? false}
        />
        {(formData?.autoSave ?? false) && <Alert variant="info" className="mb-3">
            This form is set to autosave. You do not need to click save 🙂.
        </Alert>}
    </>
}

const makeFormFormat = (formData, courseId, userId, surveyId) => {
    // creates formFormat for FormixFormWithData
    let questions= formData.questions.map((question) => {
        return {
            ...question,
            input: SurveyComponent(question, courseId, userId, surveyId),
        };
    });

    // qIndices starts off as a list of the indices of the questions, e.g. [0,1,2,3,4]
    // we use it for shuffling only
    let qIndices = Array.from(Array(questions.length).keys());
    // console.log(qIndices)
    if (formData.randomShuffle) {
        // randomShuffle will be an array of arrays of indices of questions to shuffle
        for (let shuffleMapping of formData.randomShuffle) {
            // firebase doesn't let you store an array of arrays so we store a map with only the "shuffle" key which contains the arr of indices to shuffle
            let indicesToShuffle = shuffleMapping['shuffle'];

            // get array of new indices for questions (after shuffle)
            let shuffledIndices = getShuffledIndices(indicesToShuffle, courseId, surveyId, userId);
            // console.log(`got shuffle ${shuffledIndices}`);

            // update qIndices so that elements at indices indicesToShuffle are mapped to shuffledIndices
            // basically, qIndices is now the list of the order in which we want the questions to be shown
            qIndices = updateQIndices(qIndices, indicesToShuffle, shuffledIndices);
            // console.log(`updated qIndices to ${qIndices}`);
        }
    }

    // for random sampling, we store an array of the names of the questions we want to hide
    // so that we can hide them after we've shuffled them without having to do confusing index math
    // NOTE: it MUST be the case that names are unique and that all questions have a "name" field!!!
    let hiddenQNames = [];
    if (formData.randomSample) {
        // get questions to show and hide based on randomSample
        const { show, hide } = getQuestions(courseId, surveyId, userId, formData.randomSample);
        // console.log("show");
        // console.log(show);
        // console.log("hide");
        // console.log(hide);

        // map to names
        for (let questionIdx of hide) {
            if (questionIdx < 0) continue;
            hiddenQNames.push(questions[questionIdx]['name']);
        }
    }

    let updatedQuestions = questions
    if (formData.randomShuffle){
        // reorder questions if randomShuffle was specified
        updatedQuestions= qIndices.map(idx => questions[idx]);
    }

    // hide questions that weren't sampled
    // if there was no sampling, hiddenQNames is an empty array so this does nothing
    updatedQuestions = updatedQuestions.filter(question => !hiddenQNames.includes(question['name']));
    // console.log("filtered questions");
    // console.log(updatedQuestions.map(q => q['name']));
    // console.log(updatedQuestions.length);
    return updatedQuestions
}

export function getShuffledIndices(indicesToShuffle, courseId, surveyId, userId) {
    let shuffledIndices = [];
    let i = 0;
    while (shuffledIndices.length < indicesToShuffle.length) {
        // choose an element in indicesToShuffle
        let nextIdx = indicesToShuffle[Math.abs(hashString(`${courseId}${surveyId}${userId}${i}`)) % indicesToShuffle.length];
        if (!shuffledIndices.includes(nextIdx)) {
            shuffledIndices.push(nextIdx);
        }
        i += 1;
    }

    return shuffledIndices;
}

export function updateQIndices(qIndices, indicesToShuffle, shuffledIndices) {
    let newQIndices = [];
    for (let qIdx of qIndices) {
        if (indicesToShuffle.includes(qIdx)) {
            // get the position of this question index in the indicesToShuffle array
            // fetch the question at the same position in the shuffledIndices array --
            // this is the new question that should be at this position in newQIndices.
            let newQIdx = shuffledIndices[indicesToShuffle.indexOf(qIdx)];
            newQIndices.push(newQIdx);
        } else {
            newQIndices.push(qIdx);
        }
    }
    return newQIndices;
}

const getQuestions = (courseId, surveyId, userId, randomShuffle) => {
    // randomShuffle is an array of objects, each with the following properties:
    // * chooseFrom: array of indices of questions to choose from
    // * chooseN: number of questions to choose
    // * percentages: array of percentages (integers) out of 100 that sum to 100,
    //      representing the chance of choosing each question
    // returns an object with two arrays: show and hide
    // show contains the indices of questions to show
    // hide contains the indices of questions to hide
    let show = [];
    let hide = [];
    randomShuffle.forEach(randomizedSet => {
        let chosen = [];
        let chooseFrom = randomizedSet.chooseFrom;
        let chooseN = randomizedSet.chooseN;
        let percentages = randomizedSet.percentages;

        // i is an arbitrary index so that if we need to sample more than 1 question, 
        // we can hash different values each time so as to randomly sample however many questions we need,
        // but each user will still receive the same questions each time they take the survey
        let i = 0;
        while (chosen.length < chooseN) {
            // hash the concatenated courseId, surveyId, userId, and arbitrary index i and bin into 100 to get a number 0-99 inclusive
            let bin = Math.abs(hashString(`${courseId}${surveyId}${userId}${i}`)) % 100;
            // retrieve the question to show based on the bin
            let q = binToQuestion(bin, percentages, chooseFrom);
            if (!chosen.includes(q)) {
                // if the question is not already chosen, add it to the list of chosen questions    
                // otherwise, hash a new value and try again in the next iteration of the loop
                chosen.push(q);
            }
            i += 1; // increment i
        }
        // get the questions that are not chosen -- these must be hidden from the user
        let hiddenQuestions = chooseFrom.filter(q => !chosen.includes(q));
        // add the chosen and hidden questions to the show and hide arrays
        hide = hide.concat(hiddenQuestions);
        show = show.concat(chosen);
    });
    return { show, hide };
}

function binToQuestion(bin, percentages, chooseFrom) {
    // given a bin number  in range [0,99], 
    // a list of percentages in range [0,100], 
    // and a list of questions to choose from which should be the same length as percentages,
    // return the question to show based on the bin number

    if (percentages.reduce((a, b) => a + b, 0) != 100){
        console.error("percentages do not sum to 100");
        return false;
    }
    if (percentages.length != chooseFrom.length){
        console.error("percentages and chooseFrom are not the same length");
        return false;
    }
    
    // this is best understood with a picture, which I will try my best to draw below:
    // |A|B|B|B|B|B|C|C|C|.. | D | say each |cell| is a bin
    // |0|1|2|3|4|5|6|7|8|...|99| is the corresponding bin number
    // then we have, say, percentages = [1, 5, 30, 64] for the segments A, B, C, D
    // let bin = 4, then we should return a question from segment C.
    // we start at the beginning of the percentages array.
    // subtract 1 (the percentage in segment A) from bin to yield 3 -- since this is positive, bin is not in segment A,
    // so bin is now 3 and we examine segment B.
    // subtract 5 from bin to yield -2 -- since this is negative, bin is in segment B.
    // we can think of the changing value of bin as "index past the end of the segment array for the next segment to examine"
    for (let i = 0; i < percentages.length; i++) {
        if (bin - percentages[i] < 0){
            // bin is in this question's segment
            // return this question number
            return chooseFrom[i];
        }
        bin -= percentages[i];
    }
    return false; // we should never get here
}
import styled from "styled-components";

const Greeting = styled.h1`
  font-size: 27px; // Adjust the size as needed
  font-weight: bold; // Makes the text bolder
`;

const Emoji = styled.span`
  font-size: 3.5rem; /* Adjust size as needed
  display: flex; /* This ensures that the emoji centers vertically */
  align-items: center; /* Aligns vertically */
  justify-content: center; /* Centers horizontally in the column if there's extra space */
  margin-right: 20px; /* Adds space to the right of the emoji */
`;

const SLFeedbackHeaderMessage = ({ slName }) => {
  return (
    <div>
      <div className="d-flex">
        <div className="text-center">
          <Emoji role="img" aria-label="waving hand" className="emoji">
            🥳
          </Emoji>
        </div>
        <div>
          <Greeting>Hi, {slName}!</Greeting>
          <p className="subtext">
            You can view your feedback from your section here. You rock! You've
            done the most important part of the job: showing up! Here's some
            feedback to help you improve.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SLFeedbackHeaderMessage;

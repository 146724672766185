import { Outlet } from "react-router"
import { DiagnosticContext, DiagnosticProvider } from "./DiagnosticContext"
import { ProfileProvider } from "contexts/ProfileContext"
import { useContext } from "react"

export const DiganosticPage = () => {

  return (
      <ProfileProvider>
        <DiagnosticProvider>
          <DiagnosticInner/>
        </DiagnosticProvider>
      </ProfileProvider>
)}

const DiagnosticInner = () => {
  const {diagnosticLoading} = useContext(DiagnosticContext)
  if(diagnosticLoading) {
    return <></>
  }
  return <Outlet/>
}

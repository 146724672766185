import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useUserId } from "hooks/user/useUserId"
import { useEffect } from "react"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import Swal from "sweetalert2"




export const useSectionPopup = (isEnabled) => {
    // const userId = useUserId()
    // const db = getFirestore()
    // const uiTrainingRef = doc(db, `users/${userId}/docs/uiTraining`)
    // const [uiTrainingData, loading, error] = useDocumentDataOnce(uiTrainingRef)

    useEffect(() => {
        // if (loading) {
        //     return
        // }
        // const doPopup = async () => {
        //     if(uiTrainingData && uiTrainingData.sectionsPopupShown){
        //         return
        //     }
        //     Swal.fire({
        //         title: "Live section is ready!",
        //         text: `click Join Section Zoom to start. Keep your camera on if possible”`
        //     })
        //     await setDoc(uiTrainingRef, {
        //         sectionsPopupShown: true
        //     }, {merge: true})
        // }

        // doPopup()

        if (isEnabled) {
            Swal.fire({
                title: "Live section is ready!",
                text: `Click Join Section Zoom to start. Keep your camera on if possible”`
            })
        }
    }, [isEnabled])

}
import { useCourseId } from "hooks/router/useUrlParams";
import { useUserId } from "hooks/user/useUserId";
import { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReviewContext } from "review/contexts/ReviewContext";
import { checkGradingLock, getCurrStudentIndex, getNextUngraded } from "review/contexts/ReviewUtil";
import Swal from "sweetalert2";
import styled from "styled-components";

export const BottomNav = ({ }) => {

  let { userId } = useParams();
  const courseId = useCourseId();
  const graderId = useUserId()
  const { applicantList, gradebook, secondReviewRequests } = useContext(ReviewContext)

  const hasGradingLock = checkGradingLock(gradebook, graderId, userId)
  let currIndex = getCurrStudentIndex(applicantList, userId)

  const navigate = useNavigate();

  const onNextUngraded = () => {
    const nextUngraded = getNextUngraded(applicantList, gradebook, secondReviewRequests, userId, graderId)
    if (nextUngraded != null) {
      // console.log(nextUngraded)
      navigate(`/${courseId}/review/app/${nextUngraded}`);
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Phase done!',
        showConfirmButton: true,
      });
    }
  }

  const linkClass = hasGradingLock ? 'disabled-link' : ''

  return (
    <div className="mt-10" style={bottomNavStyle}>
      <div />
      <div className="">
        <BreadcrumbStyled className="bg-white">

          <Breadcrumb.Item className={linkClass} style={{margin:0}}>
            <Link style={{margin:0}} className={linkClass} to={hasGradingLock ? "#" : `/${courseId}/review/`}>All Applications</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={{margin:0}} active>Applicant {currIndex}: {userId}</Breadcrumb.Item>
        </BreadcrumbStyled>
      </div>
      <button
        className={'btn btn-info ' + (hasGradingLock ? ' disabled' : '')}

        onClick={() => onNextUngraded()}
      >
        Next Ungraded
      </button>

    </div>
  );
}

const bottomNavStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
  height: '100%',
  alignItems: 'center',
}

const BreadcrumbStyled = styled(Breadcrumb)`
  margin: 0 !important;

  // any ol children also have no margin
  & > ol {
    margin: 0 !important;
  }
`


/*************** OLD CODE ***************************************** */

// function getNextStudent(studentList, currStudentId) {
//   for (let index = 0; index < studentList.length; index++) {
//     const studentId = studentList[index];
//     if (studentId == currStudentId) {
//       if (index + 1 < studentList.length) {
//         return studentList[index + 1]
//       } else {
//         // end of the list
//         return null
//       }
//     }
//   }
//   console.error('could not find student ', currStudentId)
//   return null
// }

// function getPreviousStudent(studentList, currStudentId) {
//   for (let index = 0; index < studentList.length; index++) {
//     const studentId = studentList[index];
//     if (studentId == currStudentId) {
//       if (index != 0) {
//         return studentList[index - 1]
//       } else {
//         // start of the list
//         return null
//       }
//     }
//   }
//   console.error('could not find student ', currStudentId)
//   return null
// }

// Note: I turned off key handlers!
// // onKeyDown handler function
// const keyDownHandler = (event) => {
//   // be careful that the text box isn't active

//   let keyCode = event.code
//   if (keyCode === 'ArrowRight' && event.shiftKey) {
//     if (nextId) {
//       navigate(`/${courseId}/review/app/${nextId}`);
//     }
//   }
//   if (keyCode === 'ArrowLeft' && event.shiftKey) {
//     if (previousId) {
//       navigate(`/${courseId}/review/app/${previousId}`)
//     }
//   }
// };
// handle keyboard events
// useEffect(() => {
//   // only run this code once
//   document.addEventListener("keydown", keyDownHandler, false);
//   // return the destructor
//   return () => {
//     document.removeEventListener("keydown", keyDownHandler, false)
//   }
// }, [nextId, previousId])

{/* <Link
            className={'btn btn-info ' + (previousId == null || hasGradingLock ? ' disabled' : '')}
            style={{ marginRight: '5px' }}
            to={`/${courseId}/review/app/${previousId}`}
            onClick={(event) => { if (previousId == null) event.preventDefault() }}
          >
            <i className="fa fa-angle-left" /> Previous
          </Link>

          <Link
            
            className={'btn btn-info ' + (nextId == null || hasGradingLock ? ' disabled' : '')}
            style={{ marginRight: '5px' }}
            onClick={(event) => { if (nextId == null) event.preventDefault() }}
            to={hasGradingLock ? '' : `/${courseId}/review/app/${nextId}`}
          >
            Next <i className="fa fa-angle-right" />
          </Link> */}
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import { GuideStep, QuestionAndAnswer } from "./types";

const functions = getFunctions();

type SubmitProjectIdeaRequest = {
  projectIdea: string;
  courseId: string;
  projectId: string;
  skipScopeCheck?: boolean;
};
export async function submitProjectIdea(
  projectIdea: string,
  courseId: string,
  projectId: string,
  skipScopeCheck?: boolean
): Promise<any> {
  const submitProjectIdea = httpsCallable<
    SubmitProjectIdeaRequest,
    QuestionAndAnswer[]
  >(functions, "onProjectIdeaSubmit");
  const response = await submitProjectIdea({
    projectIdea,
    skipScopeCheck,
    projectId,
    courseId,
  });
  return response.data;
}

type GetGuideRequest = {
  projectIdea: string;
  courseId: string;
  projectId: string;
  questionsAndAnswers: QuestionAndAnswer[];
};
export async function getProjectGuide(
  projectIdea: string,
  questionsAndAnswers: QuestionAndAnswer[],
  projectId: string,
  courseId: string
): Promise<GuideStep[]> {
  const getProjectGuide = httpsCallable<GetGuideRequest, GuideStep[]>(
    functions,
    "getProjectGuide"
  );
  const response = await getProjectGuide({
    projectIdea,
    projectId,
    questionsAndAnswers,
    courseId,
  });
  return response.data;
}

// type ToggleStepCompletionRequest = {
//   step: number;
//   courseId: string;
// };
// export async function toggleStepCompletion(
//   step: number,
//   courseId: string,
//   projectId: string
// ): Promise<void> {
//   const toggleStepCompletion = httpsCallable<ToggleStepCompletionRequest, void>(
//     functions,
//     "toggleGuideStepCompletion"
//   );
//   await toggleStepCompletion({ step, courseId });
// }

type GetQuestionRequest = {
  projectIdea: string;
  questionsAndAnswers?: QuestionAndAnswer[];
  projectId: string;
  courseId: string;
};
export async function getQuestion(
  projectIdea: string,
  courseId: string,
  projectId: string,
  questionsAndAnswers?: QuestionAndAnswer[]
): Promise<QuestionAndAnswer> {
  const getQuestionAboutProject = httpsCallable<
    GetQuestionRequest,
    QuestionAndAnswer
  >(functions, "getQuestionAboutProject");
  const response = await getQuestionAboutProject({
    projectIdea,
    projectId,
    questionsAndAnswers: questionsAndAnswers ?? [],
    courseId,
  });
  return response.data;
}

import React, { useState, useEffect, useContext } from "react";

import { Card } from "react-bootstrap";

import { useCollection, useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";



import { getLocalUTCTimezone } from "components/timezones/timezoneHelper";
import { useNavigate } from "react-router";
import {SchedulingForm} from "./scheduling/SchedulingForm";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext } from "contexts/ProfileContext";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { StudentJoinNav } from "onboarding/studentOnboarding/StudentJoinNav";
import { StudentOnboardingContext } from "onboarding/studentOnboarding/contexts/StudentOnboardingContext";
import { useUserId } from "hooks/user/useUserId";
import { CourseContext } from "contexts/CourseContext";
import { SLOnboardingContext } from "onboarding/sectionLeaderOnboarding/contexts/SLOnboardingContext";
import { ResponsiveCard, SetupOuter } from "onboarding/components/SetupOuter";

/**
 * In this component, students are simply chosing the *time* of their section
 */

export const SLSmallGroupTime = (props) => {
  document.title = "Training Time";
  const courseId = useCourseId();
  const db = getFirestore(getApp());
  const {userData} = useContext(ProfileContext)

  // we dont use useDocumentData because we fear it will change too often.
  const [chosenTimesMap, chosenTimesLoading, chosenTimesError] =useDocumentDataOnce(doc(db, 
    `course/${courseId}/slTrainingSignups/main`))
  const [minorsTimesMap, minorsLoading, minorsError] =useDocumentDataOnce(doc(db, 
    `course/${courseId}/slTrainingSignups/minor`))
  const [trainingSlotCounts, trainingSlotsLoading, trainingSlotsError] = useDocumentData(doc(db, 
    `/course/${courseId}/slTrainingSignups/mainTimes`))
  const [minorsSlotCouts, minorsSlotsLoading, minorsSlotsError] = useDocumentData(doc(db, 
    `/course/${courseId}/slTrainingSignups/minorTimes`))


  const {onboardingData, editOnboarding} = useContext(SLOnboardingContext)

  // different fields for minors and adults
  const trainingTimeAdult = onboardingData?.trainingTime ?? "none"
  const trainingTimeMinor = onboardingData?.trainingTimeMinor ?? "none"

  console.log('trainingTime', trainingTimeAdult)

  if(chosenTimesLoading || trainingSlotsLoading || minorsLoading || minorsSlotsLoading) {
    return <></>
  }
  if(chosenTimesError || trainingSlotsError || minorsError || minorsSlotsError) {
    return <div>There was an error loading section availibility, please refresh</div>
  }

  const isMinor = ('isMinor' in userData) ? userData.isMinor : false


  const trainingTime = isMinor ? trainingTimeMinor : trainingTimeAdult
  const timesMap = isMinor ? minorsTimesMap : chosenTimesMap
  const slotCounts = isMinor ? minorsSlotCouts : trainingSlotCounts
  const availableTimes = computeAvailableTimes(timesMap, slotCounts, trainingTime)

  console.log('availableTimes',availableTimes)
  const handleTrainingChange =  async (e) => {
    const selection = e.target.value
    if(isMinor){
      editOnboarding({trainingTimeMinor:selection})
    } else {
      editOnboarding({trainingTime:selection})
    }
  };

  return (
    <SetupOuter>
        <ResponsiveCard style={{ margin: "auto", padding: "20px" }}>
          <h1>Sign up for a Live Practice Time {isMinor && "HS"}</h1>
          <p>Before your first section, you will meet with a group of other section leaders and a Head TA for <b>Live Section Practice</b>. Get to know more of the teaching team, learn pro tips and more. Live section practice is required for all section leaders. If you already have a lot of teaching experience, come and you can help other section leaders master the craft.</p>
    <p>Each live session is 1.5 hours.</p>
    <p><b>All times are in your local timezone</b> based off your computer clock</p>
    <p>Friday Midnight is the start of Friday and comes before Friday 4am.</p>
          <div>
            <SchedulingForm
              availableTimes={availableTimes}
              currSelection={trainingTime}
              handleChange={handleTrainingChange}
              onSubmit={() => {}}
            />
          </div>
        </ResponsiveCard>
      </SetupOuter>
  );
};

function computeAvailableTimes(chosenTimesMap, trainingSlotCounts, trainingTime) {
  const usedTimes = countUsedTimes(chosenTimesMap)
  console.log("usedTimes", usedTimes)
  const availableTimes = []
  // trainingSlotCounts is a dictionary of times to numSlots
  for(let time in trainingSlotCounts) {

    const numStudents = usedTimes[time] ?? 0
    const numSlots = trainingSlotCounts[time] ?? 0
    
    let isValidTime = numStudents < numSlots || time === trainingTime
    if(isValidTime){
      availableTimes.push(time)
    }
  }

  return sortByTime(availableTimes)
}

function sortByTime(times) {
  // times is a list of  ISO 8601 standard time strings
  // we want to sort the strings by actual time
  return times.sort((a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)
    return dateA.getTime() - dateB.getTime()
  })
}

function countUsedTimes(chosenTimesMap) {
  const counts = {}
  for(let studentId in chosenTimesMap) {
    const trainingTime = chosenTimesMap[studentId]
    if(trainingTime === "none") continue
    if(!counts[trainingTime]) counts[trainingTime] = 0
    counts[trainingTime] += 1
  }
  return counts
}
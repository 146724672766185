import { callStyleFeedbackRequest } from "utils/gpt";
import { studentIdentifiersPrompt, profIdentifiersPrompt } from './IdentifierPrompts.js'
import { StudentCommentsPrompt, ProfCommentsPrompt } from "./CommentsPrompts";
import { getMagicNumbersFeedback } from "./MagicNumbersFeedback.js";
import { collection, doc, getFirestore, query, limit, orderBy, onSnapshot, updateDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";
import { getDecompFeedback } from "./DecompFeedback.js";

export const removeComments = (code) => {
    const pattern = /(\"\"\"[\s\S]*?\"\"\")|(\'\'\'[\s\S]*?\'\'\')|#[^\r\n]*|#[^\r\n]*\r?\n|\/\/[^\r\n]*|\/\*[\s\S]*?\*\//gm;
    return code.replace(pattern, "");
}

export function extractComments(program) {
    const lines = program.split('\n');
    const results = {};
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        const commentRegex = /#.*/;
        const tripleQuoteCommentRegex = /("""|''').*?(\1|$)/;
        const commentMatch = line.match(commentRegex);
        const tripleQuoteCommentMatch = line.match(tripleQuoteCommentRegex);
        if (commentMatch) {
            const commentText = commentMatch[0].replace('#', '').trim();
            results[i+1] = commentText
        }
        if (tripleQuoteCommentMatch) {
            let commentText = tripleQuoteCommentMatch[0].replace(/"""|'''/g, '').trim();
            for (let j = i + 1; j < lines.length; j++) {
                const endMatch = lines[j].match(tripleQuoteCommentRegex)
                if (endMatch !== null) {
                    results[i+1] = commentText
                    i = j
                    break;
                } else {
                    commentText += lines[j].trim();
                }
            }
        }
    }
    return results;
}

export const extractLineNumsToComments = (code) => {
    const pattern = /(\"\"\"[\s\S]*?\"\"\")|(\'\'\'[\s\S]*?\'\'\')|#[^\r\n]*|#[^\r\n]*\r?\n|\/\/[^\r\n]*|\/\*[\s\S]*?\*\//gm;
    const lines = code.split(/\r?\n/);
    const comments = {};
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      const match = pattern.exec(line);
      if (match !== null) {
        const comment = match[0].trim();
        if (comment.startsWith('"""') || comment.startsWith("'''")) {
          // multi-line comment
          comments[i + 1] = comment;
          const endPattern = new RegExp(`${comment.endsWith('"""') ? '"""' : "'''"}`, 'g');
          for (let j = i + 1; j < lines.length; j++) {
            const endMatch = endPattern.exec(lines[j]);
            if (endMatch !== null) {
              break;
            }
          }
          i = j;
        } else {
          // single-line comment
          comments[i + 1] = comment;
        }
      }
    }
    return comments;
  };

// This function is used to extract the variables and functions from the code and create a dictionary where the key is the variable and the value is the value that variable is assigned. It stores only the last defition of each var. Functions each have key FUNC
export function extractVariables(code) {
    const variableRegex = /([a-zA-Z_]\w*)\s*=\s*(.+?)\s*(#.*)?$/gm;
    const functionRegex = /def\s+([a-zA-Z_]\w*)/gm;
    const variables = {};

    let match;
    while ((match = functionRegex.exec(code)) !== null) {
      const functionName = match[1];
      variables[functionName] = "FUNC";
    }
    
    while ((match = variableRegex.exec(code)) !== null) {
        const variableName = match[1];
        const variableValue = JSON.stringify(match[2]);
        variables[variableName] = JSON.parse(variableValue);
    }
    

    return variables;
}

// This function creates a dictionary where each key is a variable defined in the program and the value is a dictionary where the key is the line number and the value is the value that variable is assigned on that line.
export function extractVariablesToLineNums(code) {
    const variableRegex = /([a-zA-Z_]\w*)\s*=\s*(.+?)\s*(#.*)?$|([a-zA-Z_]\w*)\s*\+=\s*(.+?)\s*(#.*)?$/gm;
    const variables = {};
  
    let match;
    while ((match = variableRegex.exec(code)) !== null) {
      const variableName = match[1] || match[4];
      let variableValue = match[2] || `${variableName} + ${match[5]}`;
      variableValue = variableValue.trim()
      const lineNumber = code.substr(0, match.index).split('\n').length;
      if (!variables[variableName]) {
        variables[variableName] = {};
      }
      if(isNumericLike(variableValue)) {
        const currentValue = variables[variableName][lineNumber] || '';
        const parsed = parseFloat(variableValue)
        variables[variableName][lineNumber] = `${currentValue}${parsed}`;
      } else {
        const currentValue = variables[variableName][lineNumber] || '';
        variables[variableName][lineNumber] = `${currentValue} ${variableValue}`;
      }
    }
  
    return variables;
}

// This function is used to extract the magic numbers from the code and create a dictionary where the key is the magic number and the value is a list of line numbers that the magic number is on.
export function parsePythonNumbers(program) {
    const lines = program.split('\n');
    const results = {};
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      const regex = /(?:^|[^A-Za-z_])\.?\d+(\.\d+)?\b/g; // match digits with optional decimal points not preceded by letters or underscores
      const matches = line.match(regex);
      if (matches !== null) {
        if (line.includes('=')) {
          // extract numbers on the right hand side of the equal sign
          const rhsMatches = line.split('=')[1].match(regex);
          if (rhsMatches !== null) {
            rhsMatches.forEach((num) => {
              const parsedNum = parseFloat(num);
              if (results[parsedNum]) {
                results[parsedNum].push(i+1);
              } else {
                results[parsedNum] = [i+1];
              }
            });
          }
        } else {
          // extract all numbers on the line
          matches.forEach((num) => {
            const parsedNum = parseFloat(num);
            if (results[parsedNum]) {
              results[parsedNum].push(i+1);
            } else {
              results[parsedNum] = [i+1];
            }
          });
        }
      }
    }
    return results;
}

export function isNumericLike(value) {
    return (typeof value === 'number' || typeof value === 'string') && !isNaN(value);
}

const logNonGPTFeedback = async (loggingLocation, loggingType, feedbackDict) => {
      try {
          const db = getFirestore();
          // Get the document reference for the style feedback request
          const styleFeedbackRequestRef = doc(db, loggingLocation);
          // Update the document with the new API response data
          updateDoc(styleFeedbackRequestRef, { [loggingType]: feedbackDict, timestamp: serverTimestamp() });
      } catch (error) {
          console.log(error);
      }
}

const composeMessagesAdditionalDict = (variables, code, studentPrompt, profPrompt) => {
  return [{role: "user", content: studentPrompt + "\n" + "```" + code + "```"}, {role: "system", content: profPrompt + "\n" + JSON.stringify(variables)}] // couldn't use student and prof for roles, needed to use useer and system following their examples
}

export const onStyleFeedbackClick = (code, courseId, projectId, feedbackId) => {
  const loggingLocation = `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests/${feedbackId}`
  const noCommentCode = removeComments(code)
  const variables = extractVariables(noCommentCode)
  const lineNumsToComments = extractComments(code)

  // Line numbers are computed for the code with no comments !! 
  const variablesToLineNums = extractVariablesToLineNums(noCommentCode)
  const magicNumsToLineNums = parsePythonNumbers(noCommentCode)

  // Create messages for each type of feedback
  const identifierMessages = composeMessagesAdditionalDict(variables, code, studentIdentifiersPrompt, profIdentifiersPrompt)
  const commentsMessages = composeMessagesAdditionalDict(lineNumsToComments, code, StudentCommentsPrompt, ProfCommentsPrompt)
  
  // Used to check how many times the user has gotten feedback for this project
  const pathToProjectFeedbackRequests = `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`
  
  // Start firebase function for each type of feedback that uses GPT-3
  const promise1 = callStyleFeedbackRequest(identifierMessages, loggingLocation, "identifier", pathToProjectFeedbackRequests)
  const promise2 = callStyleFeedbackRequest(commentsMessages, loggingLocation, "comments", pathToProjectFeedbackRequests)
  
  // Need to use a promise queue here because the firebase function to check the timestamp needs to finish before the timestamp is written in logConstantsFeedback. Otherwise it takes the timestamp of itself and denies access
  Promise.all([promise1, promise2]).then(() => {
    
    // Get feedback from front end for magic numbers and decomp since these do not use GPT-3
    const magicNumbersfeedback = getMagicNumbersFeedback(variablesToLineNums, magicNumsToLineNums)
    const decompFeedback = getDecompFeedback(noCommentCode)
    
    // log magic numbers feebdack from front end because it is not sent to FB function since no GPT-3 is used 
    logNonGPTFeedback(loggingLocation, "magicNumbers", magicNumbersfeedback)
    logNonGPTFeedback(loggingLocation, "decomp", decompFeedback)
  
  }).catch((error) => {
    console.log(error)
  })
}

export const logStudentFeedback = (feedbackVal, courseId, projectId, feedbackId) => {
  const loggingLocation = `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`
  const db = getFirestore();
  const styleFeedbackRequestRef = doc(db, loggingLocation, feedbackId);
  updateDoc(styleFeedbackRequestRef, { studentFeedback: feedbackVal, studentFeedbackTmestamp: serverTimestamp() });
}

export const useHasStudentGivenFeedback = (courseId, projectId, feedbackId) => {
  // this is a hook to check if a student has given feedback for a particular feedbackId and studentFeedback
  const db = getFirestore();
  const styleFeedbackRequestRef = doc(db, `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`, feedbackId);
  const [feedbackData, feedbackLoading, feedbackError] = useDocumentData(styleFeedbackRequestRef);
  if (feedbackData) {
    return feedbackData.studentFeedback !== undefined;
  } else {
    return false;
  }
}

export function loadStyleFeedback(courseId, projectId, feedbackId) {
  // this function runs faster by making two parallel requests.
  // projectRef uses document data (it updates when the project does)
  const db = getFirestore();
  const feedbackRef = doc(db, `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`, feedbackId);
  const [feedbackData, feedbackLoading, feedbackError] = useDocumentData(feedbackRef);
  return [feedbackData, feedbackLoading, feedbackError];
}

export async function setAcknowledgedStyleFeedback(courseId, projectId, feedbackId) {
  const db = getFirestore();
  const feedbackRef = doc(db, `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`, feedbackId);
  try {
    // Update the acknowledged status for this feedback request
    await updateDoc(feedbackRef, {
      acknowledged: true
    });
  } catch (error) {
    console.log(`Error setting acknowledged status for feedback ID ${feedbackId}:`, error);
  }
}


export function useHasStyleFeedbackTimeElapsed(courseId, projectId) {
  const [hasTimeElapsed, setHasTimeElapsed] = useState(false);

  useEffect(() => {
    const db = getFirestore();
    const styleFeedbackCollection = collection(db, `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`);

    const now = Date.now();
    const tenMinutes = 1000 * 60 * 10; // 10 minutes in milliseconds

    const queryVal = query(
      styleFeedbackCollection,
      orderBy("timestamp", "desc"),
      limit(1)
    );

    onSnapshot(queryVal, (snapshot) => {
      const lastUsedTime = snapshot.docs[0]?.data()?.timestamp
      if(lastUsedTime) {
        const timeSinceLastUse = now - lastUsedTime.toDate();
        setHasTimeElapsed(timeSinceLastUse > tenMinutes);
      } else {
        setHasTimeElapsed(true); // if there is no last used time, then it has been 10 minutes
      }
    });

  }, [courseId, projectId]);

  return hasTimeElapsed;
}


// Checks if a student is in the treatment group. Only need to check once per session.
// Intentionally NOT a hook to check this because it is the same once you check it once per session.
export async function getIsInStyleTreatmentGroup(courseId, userId) {
  const db = getFirestore();
  const userExpFlags = doc(db, `users/${userId}/${courseId}/experimentFlags`);

  try {
    const userExpFlagsSnapshot = await getDoc(userExpFlags);
    if (userExpFlagsSnapshot.exists()) {
      const userExpFlagsData = userExpFlagsSnapshot.data();
      return userExpFlagsData.styleFeedback;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching user experiment flags:", error);
    return false;
  }
}


export function useStyleFeedbackHistory(courseId, projectId) {
  const [styleFeedbackRequests, setStyleFeedbackRequests] = useState([]);
  useEffect(() => {
    const db = getFirestore();
    const ref = collection(db, `/styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`);
    const unsubscribe = onSnapshot(ref, (querySnapshot) => {
      const styleFeedbackRequests2 = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.timestamp) {
          styleFeedbackRequests2.push({
            id: doc.id,
            ...data
          });
        }
      });
      // sort by timestamp. Extra if cases are for the newly pushed doc that is generating style feedback. Isn't populated with data yet
      styleFeedbackRequests2.sort((a, b) => {
        if(a.timestamp && b.timestamp) {
          return b.timestamp.toDate() - a.timestamp.toDate();
        }
        if(a.timestamp) {
          return -1;
        }
        if(b.timestamp) {
          1;
        }
        return 0;
      });
      setStyleFeedbackRequests(styleFeedbackRequests2);
    }
    );
    return unsubscribe;
  }, [courseId, projectId]);
  return styleFeedbackRequests;
}



import { Button, Nav, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import useMediaQuery, { Breakpoints } from "../utils/useMediaQuery";
import Swal from "sweetalert2";
import { StartStopButton } from "ide/NavIDE";
import { DiagnosticContext } from "./DiagnosticContext";
import { questions } from "./Exam";
import { useCourseId } from "hooks/router/useUrlParams";


const navBackgroundColor = "black" //#ccddff
const sliderColor = '#3273f6'
const runBtnColor = "#bb34fe"
const disabledSliderColor = "#cdd5e8"

export const DiagnosticNav = (props) => {
  const courseId = useCourseId()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(Breakpoints.TAB);
  const {timeLeft} = useContext(DiagnosticContext)
  console.log(timeLeft)
  if(!isTablet) {
    return <MobileNav {...props}/>
  }

  return (
    <Navbar
      style={{ backgroundColor: navBackgroundColor}}
      variant="dark"
      className="fixed-top pl-1 pr-1"
    >
      <Navbar.Brand style={{fontWeight:600 }}>
        
        <code style={{color:'white'}}>Diagnostic</code>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{ justifyContent: "space-between" }}
      >
        {/* This is the left side */}

        <Nav>
        <Nav.Item className="mt-1"><TimeLeft timeLeft={timeLeft}/></Nav.Item>
        </Nav>

        {/* Center */}
        <Nav style={{alignItems:'center'}}>
        <button onClick={()=>{navigate(`/${courseId}/diagnostic/intro`)}}className="btn btn-light mr-2">Intro</button>
          {
            questions.map((q, i) => {
              return <button key={q} onClick={() => {navigate(`/${courseId}/diagnostic/${q}`)}} 
              className="btn btn-light mr-1">{i+1}</button>
            })
          }
          <button onClick={() => onSubmitClick(navigate, courseId)}className="btn btn-primary mr-2">Submit</button>
          
        </Nav>

        {/* This is the right side */}
        <Nav>
        <StartStopButton {...props}/>
        </Nav>
        
        
      </Navbar.Collapse>
    </Navbar>
  )
}

const MobileNav = (props) => {
  return <Navbar
      style={{ backgroundColor: navBackgroundColor }}
      variant="dark"
      className="fixed-top"
    >
      <Navbar.Brand style={{ color: "black", fontWeight:600 }}>
        <code>Diagnostic</code>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{ justifyContent: "space-between" }}
      >
        
        <Nav>
        <StartStopButton {...props}/>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
}

const TimeLeft = ({timeLeft}) => {
  // timeLeft is in seconds
  // show hours and minutes
  const hours = Math.floor(timeLeft / 3600)
  const minutes = Math.floor((timeLeft - hours * 60 * 60 ) / 60)

  if(hours>0){
    const s = hours > 1 ? 's' : ''
    return <span style={{color:'white'}}>Time left: {hours} hour{s}, {minutes} mins</span>
  }
  if(minutes>0){
    const s = minutes > 1 ? 's' : ''
    return <span style={{color:'white'}}>Time left: {minutes} min{s}</span>
  }
  return <span style={{color:'white'}}>Time left: {Math.floor(timeLeft)} seconds</span>
}
 
const onSubmitClick = (navigate, courseId) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "info",
    showCancelButton: true,
    confirmButtonText: "Yes, submit it!",
    cancelButtonText: "No, cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(`/${courseId}/diagnostic/finished`)
    }

  })
}
import { Dropdown, DropdownButton } from "react-bootstrap";

const emojis = "😃😂🤔👍🌱💙🎉";

export const EmojiDropdown = ({ editor }) => {
  const emojiList = [];
  for (const emoji of emojis) {
    emojiList.push(emoji);
  }
  return (
    <DropdownButton
      id="text-align-dropdown"
      flip={true}
      variant="light"
      size="sm"
      title="😃"
    >
      {emojiList.map((emoji, index) => {
        return (
          <Dropdown.Item
            style={{ padding: "0px !important" }}
            key={index}
            onClick={() => editor.chain().focus().insertContent(emoji).run()}
          >
            {emoji}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

import React, { useContext, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { Checkmark } from "react-checkmark";
import { firestore } from "firebaseApp";
import { getAuth } from "firebase/auth";
import { useCourseId } from "hooks/router/useUrlParams";
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate";
import { CompletionContext } from "contexts/CompletionContext";

type LearningCenterModuleHeader = { header: string };
type LearningCenterModuleContent = {
  type: string;
  title: string;
  id: string;
};
type LearningCenterModuleIntro = LearningCenterModuleContent & {
  type: "intro";
};
type LearningCenterModuleExample = LearningCenterModuleContent & {
  type: "example";
};
type LearningCenterModuleVideo = LearningCenterModuleContent & {
  type: "video";
  videoLength: string;
};

export type LearningCenterModuleData = {
  completed: boolean;
  id: string;
  group: string,
  title: string;
  items: Array<
    | LearningCenterModuleHeader
    | LearningCenterModuleIntro
    | LearningCenterModuleExample
    | LearningCenterModuleVideo
  >;
};

type LearningCenterSectionProps = {
  children?: React.ReactNode;
  header?: React.ReactNode;
};

export const LearningCenterSection = ({
  children,
  header,
}: LearningCenterSectionProps) => {
  return (
    <SectionWrapper>
      {header && <SectionHeader>{header}</SectionHeader>}
      {children}
    </SectionWrapper>
  );
};

const SectionHeader = styled.div`
  text-align: center;
  font-size: 1.75rem;
`;

const SectionWrapper = styled.div`
  padding-top: 48px;

`;

const useCompletedModules = (uid, courseId) => {
  const [completedModules, setCompletedModules] = useState<{ [key: string]: boolean }>(undefined);

  useEffect(() => {
    if (uid) {
      const ref = firestore
        .collection("users")
        .doc(uid)
        .collection(courseId)
        .doc("completedTrainingModules");

      const unsubscribe = ref.onSnapshot((doc) => {
        if (doc.exists) {
          const docData = doc.data();
          const updatedCompletedModules = {};
          Object.entries(docData).forEach(([key, value]) => {
            updatedCompletedModules[key] = !!value;
          });
          setCompletedModules(updatedCompletedModules);
        } else {
          setCompletedModules({});
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [uid, courseId]);

  return completedModules;
}

type LearningCenterModulesSectionProps = {
  header?: React.ReactNode;
  modules: Array<LearningCenterModuleData>;
  generateLink: (mod: LearningCenterModuleData) => string;
  generateTitle?: (mod: LearningCenterModuleData) => React.ReactNode;
};
export const LearningCenterModulesSection = ({
  generateLink,
  generateTitle,
  modules,
  ...rest
}: LearningCenterModulesSectionProps) => {
  const courseId = useCourseId();
  const auth = getAuth();
  const authUser = auth.currentUser;
  const navigate = useHistoryNavigate()

  const { isLessonCompleted } = useContext(CompletionContext)
  
  const moduleData = modules.map((mod) => {
    return {
      ...mod,
      completed: isLessonCompleted(mod.id),
    };
  });


  const groupModules = (modules) => {
    return modules.reduce((acc, mod) => {
      if (!acc[mod.group]) {
        acc[mod.group] = [];
      }
      acc[mod.group].push(mod);
      return acc;
    }, {});
  };

  const groupedModules = groupModules(moduleData);

  return (
    <LearningCenterSection>
      <ModuleSectionContents>
        {Object.keys(groupedModules).map((group) => {
          
          return <div key={group} style={{ marginBottom: "50px" }}>
            <div style={{
              fontSize: "1.5rem",
              marginBottom: "5px",
              // fontWeight: "bold",
              textAlign: "left"
            }}>
              {group}
            </div>
            {groupedModules[group].map((mod) => {
              const link = generateLink(mod)
              return <ModuleLink key={mod.id}  onClick={() => navigate(link)}>
                <ModuleLinkButton variant="light" size="lg">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>{mod.title}</div>
                    {mod.completed && <Checkmark size="medium" />}
                  </div>
                </ModuleLinkButton>
              </ModuleLink>
            })}
          </div>
        })}
      </ModuleSectionContents>
    </LearningCenterSection>
  );
};

const CompletedModuleBox = styled.div`
  margin: 10px;
`;

const ModuleSectionContents = styled.div`
  text-align: left;
`;

const ModuleLink = styled.a`
  margin-bottom: 10px;
  display: block;
`;

const ModuleLinkButton = styled(Button)`
  max-width: 700px;
  width:100%;
  height: 60px;
  text-align: left;
  background-color: #eff1fc;
  font-size: 1rem;
`;

type LearningCenterContainerProps = {
  children?: React.ReactNode;
};

const LearningCenterContainer = ({
  children,
}: LearningCenterContainerProps) => {
  return <div>{children}</div>;
};

export default LearningCenterContainer;

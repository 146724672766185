import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { useContext, useState } from "react";
import { EditButton } from "./components/EditButton";
import Gate from "contexts/Gate";
import { ProfileContext } from "contexts/ProfileContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { deleteFeedback } from "./FirebaseUtils";
import { SimpleButtonBar } from "components/richTextEditor/TipTap/buttonbars/SimpleButtonBar";
import Swal from "sweetalert2";
import { useCourseId } from "../../hooks/router/useUrlParams";

/**
 * Inline comment component for displaying inline comments in the editor
 */
export const InlineComment = ({ isEdit, userData, projectData, lineNumber, updateHeight, removeViewZone, courseId}) => {
  const [editable, setEditable] = useState(isEdit);

  const projectId = projectData.uid;
  const assnId = projectData.assnId;
  const authorId = projectData.editors[0]
  const lastOpenedFileId = projectData && projectData.lastOpenedFile ? projectData.lastOpenedFile.id : null;

  const inlineCommentDocPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/inlineComments/${lastOpenedFileId}/${lineNumber}`
  console.log("inlineCommentDocPath:", inlineCommentDocPath);

  // Toggle the edit mode for the inline comment
  // The edit function also updates the height of the view zone
  const handleEditToggle = async () => {
    setEditable((prevEditable) => !prevEditable);
    setTimeout(updateHeight, 0);
  };

  // Delete the inline comment
  // This function removes the view zone from the editor
  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setEditable(false);
        setTimeout(removeViewZone, 0);
        deleteFeedback(inlineCommentDocPath);
        Swal.fire(
          'Deleted!',
          'Your comment has been removed.',
          'success'
        )
      }
    })

  };

  return (
    <div className="feedback-block">
      <div className={`${editable ? "bg-white" : ""}`}>
        <TipTap
          firebaseDocPath={inlineCommentDocPath}
          editable={editable}
          collaborative={true}
          buttonBar={SimpleButtonBar}
          showLoadingSkeleton={true}
        />
      </div>
      {Gate.hasSectionLeaderRole(userData) && (
        <>
          <EditButton onClick={handleEditToggle} />
          <DeleteButton onClick={handleDelete} />
        </>
      )}
    </div>
  );
}

/**
 * Delete button for inline comments
 */
export const DeleteButton = (props) => {
  return (
    <OverlayTrigger
      rootClose placement="bottom"
      delay={{ show: 400, hide: 0 }}
      overlay={(props) => (
        <Tooltip id="resolve-tooltip" {...props}>
          Delete
        </Tooltip>
      )}
    >
      <span>
        <FaTrash onClick={props.onClick} className="action-button" />{" "}
      </span>
    </OverlayTrigger>
  );
};



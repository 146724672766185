import { Page404 } from "components/errors/Page404";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router";
import { ReviewProvider } from "review/contexts/ReviewContext";
import { Loading } from "components/loading/Loading";
import { getApprovedCourses } from "utils/general";

export const ReviewPage = (props) => {
  const courseId = useCourseId()
  const [approvedCourses, setApprovedCourses] = useState({})
  const [loaded, setLoaded] = useState(false)
  // make sure its a real course
  useEffect(() => {
    const getAc = async () => {
      const ac = await getApprovedCourses()
      setApprovedCourses(ac)
      setLoaded(true)
    }
    getAc();
  }, [])

  if(!loaded){
    <Loading/>
  }

  if(!(courseId in approvedCourses)){
    return <Page404 message={`Unknown url path: ${courseId}`}/>
  }
  
  return (
    <ProfileProvider>
      <Inner/>
    </ProfileProvider>
  );
}

const Inner = () => {
  const {loading, userData} = useContext(ProfileContext)
  if(loading){return <></>}
  return <ReviewProvider><Outlet/></ReviewProvider>
}
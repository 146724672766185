import { useParams } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ProfileProvider } from "contexts/ProfileContext";
import { Role } from "types/role";
import styled from "styled-components";
import { ProSidebarProvider } from "react-pro-sidebar";
import firebase from "firebase/compat/app";
import { Loading } from "components/loading/Loading";
import { ChatGPTInterface } from "./chatgptinterface";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { ExperimentContext } from "contexts/ExperimentContext";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  line-height: 1.5;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ChatGPTDisclaimer = ({ courseId, uid, disclaimerGroup }) => {
  return (
    <CoursePageBodyContainer
      mainColumn={
        <ChatGPTDisclaimerMain
          courseId={courseId}
          uid={uid}
          disclaimerGroup={disclaimerGroup}
        />
      }
      rightColumn={<></>}
      singleColumn={
        <ChatGPTDisclaimerMain
          courseId={courseId}
          uid={uid}
          disclaimerGroup={disclaimerGroup}
        />
      }
    />
  );
};

const ChatGPTDisclaimerMain = ({ courseId, uid, disclaimerGroup }) => {
  if (disclaimerGroup === "") {
    return <Loading />;
  }

  const toHome = `/${courseId}/home`;
  const toChatgpt = `/${courseId}/chatgpt/chat`;
  const title = "Try CHATGPT";
  const end = true;
  const setActiveOverride = false;

  return (
    <div className="mt-3">
      <AdminRichTextEditor
        firebaseDocPath={`chatgpt/${courseId}/handouts/shared`}
        minRole={Role.ADMIN}
      />
      <GroupButtonsDiv>
        <ButtonDiv className="btn btn-primary mb-3 mt-3">
          <NavLink
            end={end}
            to={toChatgpt}
            className={`nav-link asideLink ${
              setActiveOverride ? "active" : ""
            }`}
            aria-current="page"
            title={title}
          >
            {" "}
            <WhiteButtonText>Launch GPT Within Code in Place</WhiteButtonText>
          </NavLink>
        </ButtonDiv>
      </GroupButtonsDiv>
      <AdminRichTextEditor
        firebaseDocPath={`chatgpt/${courseId}/handouts/${disclaimerGroup}`}
        minRole={Role.ADMIN}
      />
    </div>
  );
};

export const ChatGPTSplash = () => {
  const { expData, expLoading } = useContext(ExperimentContext);
  const { courseId } = useParams();
  const [disclaimerGroup, setDisclaimerGroup] = useState("");

  const [user, loading] = useAuthState(firebase.auth());

  if (loading || expLoading) {
    return <Loading />;
  }

  useEffect(() => {
    // Handle the case when expData is undefined
    if (!expData) {
      console.log("No experiment data available");
      return;
    }

    // Access the values from expData
    const { exp2_showGPTHandout } = expData;

    if (exp2_showGPTHandout == 1) {
      setDisclaimerGroup("positive");
    } else {
      setDisclaimerGroup("negative");
    }
  }, [expData, expLoading]);

  return (
    <ChatGPTDisclaimer
      courseId={courseId}
      uid={user.uid}
      disclaimerGroup={disclaimerGroup}
    />
  );
};

export const ChatGPTEditor = () => {
  const [user, loading] = useAuthState(firebase.auth());

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <ProfileProvider>
        <ProSidebarProvider>
          <ChatGPTInterface />
        </ProSidebarProvider>
      </ProfileProvider>{" "}
    </div>
  );
};

const TotalDisclaimerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25%;
  margin-right: 25%;
`;

const WarningSign = styled.h3`
  display: flex;
  font-weight: bold;
  justify-content: center;
`;

const WarningDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GroupButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const WhiteButtonText = styled.div`
  color: white;
`;

const AgreementDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;

const AgreeToShareDiv = styled.div`
  width: 600px;
  margin-left: 10px;
`;

import { useTags } from "course/forum/firebase/ForumStaticDataFetcher";


import "./PostTags.css";
import { useState, useEffect, useRef } from "react";
import { MultiSelectDropdown } from "components/reusableButtons/MultiselectDropdown";




export function PostTags(props: {
  currentTags: string[];
  editable: boolean;
  changeHandler?: (tags: string[]) => void;
  placeholder?: string;
}) {
  const { tags, isLoading } = useTags();
  const { currentTags, editable, changeHandler } = props;
  const selectableTags = Object.keys(tags).map((tag) => {
    return { value: tag, label: tags[tag].name, color: tags[tag].bgColor };
  });

  const [selectedData, setSelectedData] = useState([]);
  const placeholder = props.placeholder ?? "Tag your post";


  useEffect(() => {
    if (currentTags && !isLoading) {
      setSelectedData(
        currentTags.map((tag) => {
          return {
            value: tag,
            label: tags[tag].name,
            color: tags[tag].bgColor,
          };
        })
      );
    }
  }, [tags]); 

  useEffect(() => {
    if(isLoading) { return }
    if (changeHandler) {
      changeHandler(selectedData.map((tag) => tag.value));
    }
  }, [selectedData]);

  if (isLoading) {
    return <></>;
  }

  
  return (
    <>
    {editable ? 
    <MultiSelectDropdown 
      selectableTags={selectableTags} 
      selectedData={selectedData} 
      setSelectedData={setSelectedData} 
      style={{width:"100%"}}
      placeholder={placeholder}
    /> : 
    <div className="tagsContainer">
      {currentTags.map((tag) => (
        tags[tag] && tags[tag].name && tags[tag].bgColor &&
        <span className="badge" style={{
          color: "white",
          backgroundColor: tags[tag].bgColor
        }}>{tags[tag].name}</span>
      ))}
    </div>}
    </>
  );
}

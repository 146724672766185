/** See firebase:src/users.ts */

import { useCipHttpsCallable } from "./use_cip_https_callable";

/** The email to check. */
type GetUserIdFromEmailRequest = string;
/** The Firebase User ID associated with the provided email. */
type GetUserIdFromEmailResponse = Promise<string>;
export const useGetUserIdFromEmail = () =>
  useCipHttpsCallable<GetUserIdFromEmailRequest, GetUserIdFromEmailResponse>(
    "getUserIdFromEmail"
  );

interface GetUserEmailRequest {
  /** The UID of the user whose email is to be retrieved. */
  uid: string;
}
type GetUserEmailResponse = Promise<string>;
export const useGetUserEmail = () =>
  useCipHttpsCallable<GetUserEmailRequest, GetUserEmailResponse>(
    "getUserEmail"
  );

type DoesUserExistInFirestoreRequest = string;
type DoesUserExistInFirestoreResponse = Promise<boolean>;
export const useDoesUserExistInFirestore = () =>
  useCipHttpsCallable<
    DoesUserExistInFirestoreRequest,
    DoesUserExistInFirestoreResponse
  >("doesUserExistInFirestore");

type GetUserDisplayNameRequest = string;
type GetUserDisplayNameResponse = Promise<string>;
export const useGetUserDisplayName = () => {
  return useCipHttpsCallable<
    GetUserDisplayNameRequest,
    GetUserDisplayNameResponse
  >("getUserDisplayName");
};

import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { FaCheck, FaQuestion, FaWindowClose} from 'react-icons/fa';
import { useState } from "react"

export const PopoverTable = ({totalAttendance, attendanceDetails}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  
  const columns = [{
    dataField: 'week',
    text: 'Week'
  },{
    dataField: 'rsvpstatus',
    text: 'RSVP status',
    formatter: yesNoMaybeFormatter,
  },{
    dataField: 'attendancestatus',
    text: 'Attendance status',
    formatter: yesNoMaybeFormatter,
  },{
    dataField: 'coverstatus',
    text: 'Cover status',
    formatter: yesNoMaybeFormatter,
  },{
    dataField: 'attendees',
    text: 'Num. attendees',
  },{
  }]

  return (
    <>
      <Button onClick={handleClick} variant="light">{totalAttendance}</Button>
      <Overlay
        show={show}
        target={target}
        placement="left"
        containerPadding={20}
      >
        <Popover style={{maxWidth:'unset'}}>
        <Popover.Body>
            <BootstrapTable 
            bordered={false} 
            striped={true} 
            hover={true} 
            bootstrap4={true} 
            keyField='id' 
            data={attendanceDetails} 
            columns={columns} />
        </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

const yesNoMaybeFormatter = (cell, row) => {
    if (cell == 'y'){
        return <FaCheck/>
    }
    if (cell == 'n'){
        return <FaWindowClose/>
    }
    if (cell == '?'){
        return <FaQuestion/>
    }
}
import React, { useEffect, useState } from 'react';
import { 
    checkActionCode, 
    applyActionCode, 
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions"
import Alert from 'react-bootstrap/Alert';

type UpdateEmailTypesenseParams = {
    oldEmail : string
    newEmail : string
}

type ChangePermissionsResult = {
    success: boolean
    reason?: string
}

export const VerifyEmailHandler = ({ auth, actionCode }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const customVerifyEmail = async () => {
        const info = await checkActionCode(auth, actionCode)
        const oldEmail = info['data']['previousEmail']
        const newEmail = info['data']['email']
        const functions = getFunctions();
        const updateEmailTypesense = httpsCallable<UpdateEmailTypesenseParams,ChangePermissionsResult>(functions, "updateEmailTypesense");
        
        try {
            const res = await updateEmailTypesense({oldEmail, newEmail})
            if (!res.data.success){
                throw new Error(res.data.reason)
            }
            await applyActionCode(auth, actionCode)
            setSuccessMessage(`Your email ${newEmail} has been verified and will now be the primary email for your account.`)
        } catch (error) {
            setErrorMessage(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect( () => { customVerifyEmail() }, [])

    if (isLoading) {
        return <Alert variant={'primary'}>Loading...</Alert>;
    }

   if (errorMessage) {
       return <Alert variant={'danger'}>Error: {errorMessage}</Alert>
    } 

    return <Alert variant={'success'}>Success! {successMessage}</Alert>;
}
import { BEEPER_IMG_PCT, getCornerSize, getCornerX, getCornerY, isValidRowCol, parseRowColString } from "../util";

export const Beepers = ({state}) => {
    const beepersData = state.worldState.beepers
    // do nothing if beepers are not defined
    if(!beepersData) {
        return <></>
    }
    return (
      <div>
        {Object.keys(beepersData).map((rowColKey, index) => {
          const count = beepersData[rowColKey]
          if(count <= 0) return <></>
          const [row, col] = parseRowColString(rowColKey)
          if(!isValidRowCol(state, row, col)){
            return <></>
          }
          return <Beeper r={row} c={col} n={count} state={state} key = {rowColKey}/>
        })}
      </div>
    );
  }

const Beeper = ({r,c,n,state})=>{
  let size = BEEPER_IMG_PCT * getCornerSize(state);
  let offset = (getCornerSize(state) - size) / 2;
  let x = getCornerX(state,r, c) + offset;
  let y = getCornerY(state,r, c) + offset;
  let text = n > 1 ? n : "";

  return <div
    className="karel-stone"
    style={{
      width: size,
      height: size,
      marginLeft: x,
      marginTop: y,
    }}
  >
    <div className="karel-stoneDiamond" />
    <span
      className="karel-stoneText"
      style={{
        fontSize: size * 0.75,
        marginTop: -size * 1.05,
        width: size,
      }}
    >
      {text}
    </span>
  </div>
}
import { Skeleton } from "antd";
import { getDocFromServer } from "firebase/firestore";
import { Link } from "react-router-dom";
import { DraftPost, Post } from "../../firebase/ForumDataModels";

import "./PostEntry.css";
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher";
import { FaCheck, FaEyeSlash, FaThumbtack, FaFlag } from "react-icons/fa";
import { useComponentSize } from "react-use-size";
import { GhostUserChip, UserChip } from "../UserChip/UserChip";
import { useQuery } from "react-query";
import styled from "styled-components";

export function PostEntry(props: {
  active: boolean;
  data: Post | DraftPost;
  isDraft?: boolean;
}) {
  const { active, data, isDraft } = props;

  const postId = data.id;

  const dataFetcher = useForumDataFetcher();

  const entrySize = useComponentSize();
  const leftWidth = entrySize.width - 50;


  const postQuery = isDraft ? useQuery(["draft", postId], () =>
  getDocFromServer(dataFetcher.get_draft_doc_ref(postId))
) : useQuery(["post", postId], () =>
    getDocFromServer(dataFetcher.get_post_doc_ref(postId))
  );

  if (postQuery.isLoading || postQuery.data.data() === undefined) {
    return (
      <div
        ref={entrySize.ref}
        className={active ? "activeContainer" : "inactiveContainer"}
      >
        {" "}
        <div className="postListEntryLeft" style={{ width: `${leftWidth}px` }}>
          <div
            className="postListEntryTitle"
            style={{ width: `${leftWidth}px` }}
          >
            <Skeleton paragraph={false} />
          </div>
        </div>
      </div>
    );
  }

  const { title, author, authorUid, isPrivate, isResolved, isFlagged } =
    postQuery.data.data();

  if(isFlagged) {
    return (
      <div
        ref={entrySize.ref}
        className={active ? "activeContainer" : "inactiveContainer"}
      >
        <div className="postListEntry">
          <Link
            to={{ search: isDraft ? `?draft=${data.id}` : `?post=${data.id}` }}
            className="postListEntryInner"
          >
            <div
              className="postListEntryLeft"
              style={{ width: `${leftWidth}px` }}
            >
              <div
                className="postListEntryTitle"
                style={{ width: `${leftWidth}px` }}
              >
                <em>Removed</em>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }

  const shortTitle = title.length > 40 ? `${title.substring(0, 50)}...` : title;

  return (
    <div
      ref={entrySize.ref}
      className={active ? "activeContainer" : "inactiveContainer"}
      key={`${postId}-post`}

    >
      <div className="postListEntry">
        <Link
          to={{ search: isDraft ?`?draft=${data.id}` : `?post=${data.id}` }}
          className="postListEntryInner"
        >
          <div
            className="postListEntryLeft"
            style={{ width: `${leftWidth}px` }}
          >
            <div
              className="postListEntryTitle"
              style={{ width: `${leftWidth}px` }}
            >
              {title ? shortTitle : <em>New Post</em>}
            </div>
            <PostListEntryAuthor
              style={{ width: `${leftWidth}px` }}
            >
              { data.isAnonymous ? <GhostUserChip showAvatar={false}/> : <UserChip showAvatar={false} uid={authorUid} name={author} /> }
            </PostListEntryAuthor>
          </div>
          <div className="float-right">
            {isPrivate && <FaEyeSlash className="text-dark" size={18} />}
            {isResolved && <FaCheck className="text-success" size={18} />}
          </div>
        </Link>
      </div>
    </div>
  );
}


const PostListEntryAuthor = styled.div`
  font-size: 0.9em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  border:0px;
  white-space: nowrap;
  margin-top: 3px;
`
import { FaPython, FaGlobe, FaHtml5, FaCss3, FaJs} from "react-icons/fa";

const FILE_ICONS = {
  js: <FaJs />,
  css: <FaCss3 />,
  html: <FaHtml5 />,
  py: <FaPython />
};

export default FILE_ICONS;

import { SubmitProjectIdea } from "./SubmitProjectIdea";
import * as api from "./api";
import { Loading } from "components/loading/Loading";
import { FinalProjectGuide } from "./FinalProjectGuide";
import {
  ProjectGuidePhase,
  ProjectGuideState,
  QuestionAndAnswer,
} from "./types";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
import { NarrowProjectScope } from "./NarrowScope";
import { FinalProjectConversation } from "./FinalProjectConversation";
import { GetStarted } from "./FinalProjectGetStarted";
import { CoursePageBodyContainer } from "components/layout/CoursePageBodyContainer";
import { FinalProjectNav } from "./FinalProjectNav";
import { useCourseId } from "hooks/router/useUrlParams";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { NewProjectProposalButton } from "./NewProjectProposalButton";
import { useContext, useEffect, useState } from "react";
import { FinalProjectContext } from "./FinalProjectContext";
import { ProjectIdeaContainer } from "./ProjectIdeaContainer";
import { FinalProjectDropdown, FinalProjectList } from "./FinalProjectList";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";

const Inner = (props: {
  projectGuideState: Partial<ProjectGuideState> & { phase: ProjectGuidePhase };
}) => {
  const { projectGuideState } = props;

  if (!projectGuideState) {
    return <Loading />;
  }



  return (
    <div>

      <p>
      Your final project is a chance to make any interesting application you would like. Design a final project that you can complete in two weeks.
        Follow these steps:
      </p>
      <FinalProjectNav projectGuideState={projectGuideState} />
      <hr />
      <InnerPage projectGuideState={projectGuideState} />
    </div>
  );
};

const InnerPage = (props: {
  projectGuideState: Partial<ProjectGuideState> & { phase: ProjectGuidePhase };
}) => {
  const projectId = useParams<{ proposalId: string }>().proposalId;
  const courseId = useCourseId();
  const { projectGuideState } = props;

  const { ideateRef } = useContext(FinalProjectContext)

  // we need questions to be managed as a useState. However, if the value ever changes
  // from the server, that should take precedence. 

  const { phase, projectIdea, questions, guide, feasability } =
    projectGuideState;

  const [questionsState, setQuestionsState] = useState<QuestionAndAnswer[]>(questions);
  const [dirtyBit, setDirtyBit] = useState(false);

  useEffect(() => {

    if (!questions) {
      if(questionsState) {
        setQuestionsState([])
      }
      return
    }
    const newQuestions = [...questions]
    if (phase > ProjectGuidePhase.PROJECT_CONVERSATION) {
      const questionText = "Anything else we should know when building milestones?"
      const hasQuestion = questions.find(q => q.question === questionText)
      if (!hasQuestion) {
        newQuestions.push({
          question: "Anything else we should know when building milestones?",
          answer: ""
        })
      }
    }
    setQuestionsState(newQuestions)
    setDirtyBit(false)
  }, [questions, projectId])

  const setQuestionsStateWrapper = (questions: QuestionAndAnswer[]) => {
    setQuestionsState(questions);
    setDirtyBit(true);
  };

  const { mutate: submitProjectIdea, isLoading: submitProjectIdeaLoading } =
    useMutation(
      async ({
        projectIdea,
        skipScopeCheck,
      }: {
        projectIdea: string;
        skipScopeCheck?: boolean;
      }) => {
        await api.submitProjectIdea(projectIdea, courseId, projectId, skipScopeCheck);
      }
    );

  const { mutate: getNextQuestion, isLoading: getNextQuestionLoading } =
    useMutation(async (questionsAndAnswers: QuestionAndAnswer[]) => {
      await api.getQuestion(projectIdea, courseId, projectId, questionsAndAnswers);
    });

  const { mutate: endConversation, isLoading: getProjectGuideLoading } =
    useMutation(async () => {
      await api.getProjectGuide(projectIdea, questionsState, projectId, courseId);
    });

  if (phase === ProjectGuidePhase.GET_PROJECT_IDEA_FROM_USER) {
    return (
      <SubmitProjectIdea
        recordProjectIdea={(projectIdea) => submitProjectIdea({ projectIdea })}
        loading={submitProjectIdeaLoading}
      />
    );
  }

  if (phase === ProjectGuidePhase.GET_SCOPE_DECISION_FROM_USER) {
    return (
      <NarrowProjectScope
        originalProjectIdea={projectIdea}
        feedback={feasability}
        chooseAlternative={(alternative: string, skipScopeCheck?: boolean) =>
          submitProjectIdea({ projectIdea: alternative, skipScopeCheck })
        }
        loading={submitProjectIdeaLoading}
      />
    );
  }

  const renderedGuide: any = [];

  if (phase >= ProjectGuidePhase.PROJECT_CONVERSATION) {
    renderedGuide.push({
      children: (
        <FinalProjectConversation
          questions={questionsState}
          setQuestionsState={setQuestionsStateWrapper}
          getNextQuestion={(questionsAndAnswers) => {
            getNextQuestion(questionsAndAnswers);
          }}
          markConversationComplete={endConversation}
          getNextQuestionLoading={getNextQuestionLoading}
          getProjectGuideLoading={getProjectGuideLoading}
          readonly={phase > ProjectGuidePhase.PROJECT_CONVERSATION}
          dirtyBit={dirtyBit}
        />
      ),
      label: "Tell us a little more",
      key: "conversation",
    });
  }

  if (phase >= ProjectGuidePhase.SHOW_PROJECT_GUIDE) {
    renderedGuide.push({
      children: <FinalProjectGuide guide={guide} />,
      label: "Your project guide",
      key: "guide",
    });
    renderedGuide.push({
      children: <GetStarted />,
      label: "Get Started",
      key: "getStarted",
    });
  }

  return (
    <div className="mt-4 mb-4">
      {/* attach the ideate ref here */}
      <h3 ref={ideateRef}>Step 1: Think of an idea</h3>
      <ProjectIdeaContainer idea={projectIdea} />
      <NewProjectProposalButton />
      <>
        {/* render each component of the guide */}
        {renderedGuide.map((item) => (
          <div key={item.key}>
            {item.children}
          </div>
        ))}
      </>
      {/* footer */}
      <div style={{ height: 40 }} />
    </div>
  );
};





export const FinalProjectPortal = () => {
  const navigate = useNavigate()
  const courseId = useCourseId();
  const queryClient = new QueryClient();
  const { projects, projectsLoading } = useContext(FinalProjectContext)
  const projectId = useParams<{ proposalId: string }>().proposalId

  if (projectsLoading) return <></>

  console.log(projects, projectId)

  if (projects && !projects[projectId]) {
    navigate(`/${courseId}/finalProjectProposal`)
  }

  if (!projects || !projects[projectId]) return <>Unknown Project {projectId}</>

  // the doc for the current project!
  const finalProjectGuideState = projects[projectId]

  const name = finalProjectGuideState.name;
  const title = name ? `${name}` : "Final Project Proposal";

  return (
    <QueryClientProvider client={queryClient}>
      <CoursePageBodyContainer
        mainColumn={<div className="mt-4">
          <h1>{title}</h1>
          <Inline><AdminRichTextEditor 
            inline={true}
            firebaseDocPath={`course/${courseId}/handouts/finalProjectProposalDeadline`} 
          /></Inline>
          <hr />
          <Inner projectGuideState={finalProjectGuideState} />
        </div>}
        singleColumn={<div className="mt-4">
          <TitleRow>
            <span>{title}</span>
            <FinalProjectDropdown />
          </TitleRow>
          <Inline><AdminRichTextEditor inline={true} firebaseDocPath={`course/${courseId}/handouts/finalProjectProposalDeadline`} /></Inline>
          <hr />
          <Inner projectGuideState={finalProjectGuideState} />

        </div>}
        rightColumn={<FinalProjectList />}
      />
    </QueryClientProvider>
  );
};


const TitleRow = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
`;

const Inline = styled.div`
  p {
    margin: 0 !important;
  }
`;
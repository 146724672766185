import React, { createContext } from "react";
import 'firebase/compat/auth';


export const PeerSessionContext = createContext({
    // roomId : undefined as string,
    // roomStatus: undefined as string,
    meetingType: null,
    isMeeting: false,
    endSession: async () => {},
    meetingRole: null,
    roomInfo: null
})

interface Props {
    // roomId: string,
    meetingType: string,
    onEndSession: any,
    children: React.ReactNode,
    meetingRole: 'pilot' | 'learner' | 'sl' | 'extra-sl' | null,
    roomInfo: any
}

export const PeerSessionProvider = ({
    // courseId,
    // roomId,
    meetingType,
    onEndSession,
    children,
    meetingRole,
    roomInfo
}: Props) => {
  // @ts-ignore
  // const [user, loading] = useAuthState(firebase.auth());
  // const roomStatus = useRoomStatus(courseId, roomId, user.uid)

    return (
        <PeerSessionContext.Provider
        value={{
            // roomId,
            // roomStatus,
            meetingType: meetingType,
            isMeeting: true,
            endSession: onEndSession,
            meetingRole: meetingRole,
            roomInfo: roomInfo
        }}>
            { children }
        </PeerSessionContext.Provider>
    )
}

import React, { useRef, useState, useContext } from "react";
import { CourseContext } from "contexts/CourseContext";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";

const defaultData = {
  maxHeight: 0,
  events:[],
  firstDay: null,
  currWeek: 0,
  setCurrWeek: (e) => {},
  registerEvent: (e, h) => {},
  nWeeks:0,
  isStudentCal:false
}

export const CalendarContext = React.createContext(defaultData);

export const CalendarProvider = ({events, startWeek, isStudentCal, firstCalendarDay, children}) => {
  const auth = getAuth();
  const user = auth.currentUser;

  const [maxHeight, setMaxHeight] = useState(0);
  const [currWeek, setCurrWeek] = useState(startWeek);

  // nWeeks x 7 matrix
  const { nCourseWeeks } = useContext(CourseContext)
  const nWeeks = nCourseWeeks
  const zeros = Array.from({ length: nWeeks }, () => Array(7).fill(0));
  const currWeekHeightsRef = useRef(zeros);

  const getDayHeightIndex = (week,day) => {
    // console.log(week, day)
    // console.log(currWeekHeightsRef.current)
    // if(!currWeekHeightsRef.current || currWeekHeightsRef.current.length === 0 || currWeekHeightsRef.current[week] || !currWeekHeightsRef.current[week][day]) {
    //   return 0;
    // }
    // return currWeekHeightsRef.current[week][day];
    return 0
  }

  const registerEvent = (weekIndex, dayIndex, height) => {
    // if(!currWeekHeightsRef.current || currWeekHeightsRef.current.length === 0 || currWeekHeightsRef.current[weekIndex] || !currWeekHeightsRef.current[weekIndex][dayIndex]) { 
    //   return 0;
    // }
    // currWeekHeightsRef.current[weekIndex][dayIndex] += height;
 
    setMaxHeight(0);
  }

  const year = 2024
  const firstMonth = 4 // April
  const firstDay = new Date(year, firstMonth - 1, firstCalendarDay, 0, 0, 0, 0);

  return (
    <CalendarContext.Provider
      value={{
        maxHeight,
        events,
        isStudentCal,
        currWeek,
        setCurrWeek,
        getDayHeightIndex,
        registerEvent,
        firstDay,
        nWeeks
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

function findLargestElement(grid) {
  let largest = grid[0][0]; // start with the first element

  // loop through each row and column to find the largest element
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid[i].length; j++) {
      if (grid[i][j] > largest) {
        largest = grid[i][j];
      }
    }
  }

  return largest;
}
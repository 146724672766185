import { useEffect } from "react";
import {
  addDoc,
  collection,
  getFirestore,
  Timestamp,
} from "firebase/firestore";
import { useRouteError } from "react-router-dom";

import { useCourseId } from "../../hooks/router/useUrlParams";
import { useUserId } from "../../hooks/user/useUserId";



const ErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    console.log(error);

    const logErrorToFirestore = async () => {
      const url = window.location.href;
      // check if localhost is in the url
      if (url.includes("localhost")) {
        return;
      }

      const db = getFirestore();
      let userId = "";
      let courseId = "";
      try {
        userId = useUserId();
      } catch (e) {}

      try {
        courseId = useCourseId();
      } catch (e) {}

      const stackTrace = getStackTrace(error);
      await addDoc(collection(db, "errorLogs"), {
        url,
        // @ts-ignore Property 'message' does not exist on type 'unknown'
        errorMessage: error.message,
        // @ts-ignore Property 'stack' does not exist on type 'unknown'
        errorStack: stackTrace,
        userId,
        courseId,
        createdAt: Timestamp.now(),
      });
    };

    logErrorToFirestore();
  }, [error]);

  return (
    <div className="landingContainer">
      <div
        className="splashBackground"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/splash3.jpeg')`,
        }}
      >
        <div className="card">
          <div className="card-body">
            <h1>Something went wrong</h1>
            <h4>A log has been sent to the course staff</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

function getStackTrace(error) {
  if (Error.captureStackTrace) {
    let stackTrace = "";
    Error.captureStackTrace(error);
    if (error.stack) {
      stackTrace = error.stack;
    }
    return stackTrace;
  } else {
    return "";
  }
}

export default ErrorBoundary;

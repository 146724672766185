import React, { Component, useContext } from "react";
// import { Router, Link } from "@reach/router"
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCourseId } from "hooks/router/useUrlParams";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { LandingNav, ProfileNav } from "landing/components/LandingNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { FaChevronLeft, FaHome, FaTimes } from "react-icons/fa";
import { OnboardingNav } from "onboarding/components/OnboardingNav";

export const SLJoinNav = () => {
  return <OnboardingNav onboardingKey={"teach"} title={"SL Onboarding"}/>
}
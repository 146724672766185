import { FaBolt, FaBook, FaBookOpen, FaPython, FaUsers, FaCheck, FaRocket } from "react-icons/fa";
import { ROADMAP } from "course/editors/roadmapEditor/RoadmapSchema";


export const TodoIcon = ({ icon, isComplete=false }) => {
    const size = 24
    const iconStyle = {}

    if(isComplete) {
      return <FaCheck style={iconStyle} size={size} />
    }


    switch (icon) {
      case ROADMAP.LESSON.key: return <FaBookOpen style={iconStyle} size={size} aria-label={`${ROADMAP.LESSON.title} icon`}/>
      case ROADMAP.SECTION.key: return <FaUsers style={iconStyle} size={size} aria-label={`${ROADMAP.SECTION.title} icon`} />
      case ROADMAP.ASSIGNMENT.key: return <FaPython style={iconStyle} size={size} aria-label={`${ROADMAP.ASSIGNMENT.title} icon`} />
      case ROADMAP.COMPLETION_LINK.key: return <FaBolt style={iconStyle} size={size} aria-label={`${ROADMAP.COMPLETION_LINK.title} icon`} />
      case ROADMAP.UNTIED_LINK.key: return <FaRocket style={iconStyle} size={size} aria-label={`${ROADMAP.UNTIED_LINK.title} icon`} />
      case ROADMAP.AUTO_CHECK.key: return <FaCheck style={iconStyle} size={size} aria-label={`${ROADMAP.AUTO_CHECK.title} icon`} />
      default: return <FaBook style={iconStyle} size={size} aria-label="Reading Icon" />
    }
  }
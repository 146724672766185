import styled from "styled-components"

export const GenericBackdrop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background-image: url('/splash4.jpg');
    background-size: cover;
    background-position: center;
`


export const GenericCenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: white;
`

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import styled from "styled-components";

const DisclaimerContainer = styled.div`
  right: 60px;
  bottom: 30px;
  z-index: 10;
`;

const TranscriptFeedbackDisclaimer = () => {
  return (
    <DisclaimerContainer className="position-fixed">
      <p
        data-tooltip-id="disclaimer"
        data-tooltip-variant="info"
        className="badge text-bg-info"
      >
        ℹ️ Disclaimer
      </p>
      <Tooltip clickable={true} id="disclaimer">
        <div className="tooltipContainer">
          <p className="mb-0">
            We use state of the art AI to provide you with feedback to help
            improve your teaching. The AI isn't perfect, so please expect some
            mistakes. We would love to hear from you now (email{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="mailto:ddemszky@stanford.edu"
            >
              ddemszky@stanford.edu
            </a>
            ) or at the end of course survey by the end of the course about how
            you think we can improve this feedback.
          </p>
        </div>
      </Tooltip>
    </DisclaimerContainer>
  );
};

export default TranscriptFeedbackDisclaimer;

import { getFunctions, httpsCallable} from "firebase/functions"
import Swal from 'sweetalert2';

const functions = getFunctions()
const createPracticeZoomMeeting = httpsCallable(functions, 'createPracticeZoomMeeting')

export const joinZoom = async () => {
    try {
      Swal.fire({
        title: 'Joining Zoom meeting...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })

      const response = await createPracticeZoomMeeting();

      if('errorMessage' in response.data) {
        Swal.fire({
          title: 'Error',
          text: `Unable to join the zoom meeting: ${response.data.errorMessage}`,
          icon: 'error',
        })
        return
      }

      const { startUrl } = response.data;

      // Close the Swal after receiving the response
      Swal.close();

      if (startUrl) {
        // Redirect to the Zoom meeting URL in a new tab
        window.open(startUrl, '_blank');
      } else {
        // Handle the case when the join URL is not provided
        Swal.fire({
          title: 'Error',
          text: 'Unable to join the zoom meeting, missing URL',
          icon: 'error',
        })
      }
    } catch (error) {
      // Close the Swal and handle the error when calling the Firebase function
      Swal.close();
      console.error('Error calling createPracticeZoomMeeting:', error);
      Swal.fire({
        title: 'Error',
        text: `Unable to join the zoom meeting: ${error}`,
        icon: 'error',
      })
    }
  }
import { useContext, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { LessonContext } from "./LessonContext";
import { FaBars, FaBookOpen } from "react-icons/fa";
import styled from "styled-components";
import { FaCheckCircle, FaCircle, FaPlayCircle } from "react-icons/fa";
import { CompletionContext } from "contexts/CompletionContext";
import { useCourseId } from "hooks/router/useUrlParams";

export const LessonDrawer = () => {
  const [show, setShow] = useState(false);
  const { slides, lessonData, gotoSlide } = useContext(LessonContext);
  const courseId = useCourseId();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="light" className="mr-1 ml-1" onClick={handleShow} aria-label="open lesson outline">
        <FaBars />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{lessonData?.title ? lessonData.title : "Lesson Outline"}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <b>Lesson Outline</b>
          { slides && 
            slides.map((slide, i) => {
              const title = slide.title;
              const id = slide.id;
              if (!title) return <></>
              const isHidden = slide?.data?.hideFromOutline
              if (isHidden) return <></>
              return <DrawerItem
                itemIndex={i}
                onClick={() => {
                  gotoSlide(slide.id)
                  // handleClose()
                }}
                key={title}
                itemData={slide}
                isSlide={true}
              />

            })


          }
          <div style={{ height: 20 }}></div>
          <b>Textbook</b>
          {lessonData.readings && 
            lessonData.readings.map((reading, i) => {
              const title = reading.title;
              const itemData = { ...reading }
              itemData["iconType"] = "book"
              if (!title) return <></>
              return <DrawerLink
                key={title} 
                itemData={itemData}
              />

            })
          }
          <div style={{ height: 20 }}></div>
          <b>Extra Examples</b>
          { lessonData.examples && 
            lessonData.examples.map((example, i) => {
              const title = example.title;
              const itemData = { ...example }
              itemData["iconType"] = "python"
              itemData["url"] = `/${courseId}/ide/a/${example.assnId}`
              if (!title) return <></>
              return <DrawerLink
                key={title} 
                itemData={itemData}
              />

            })
          }
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const DrawerLink = ({ itemData }) => {
  const onClick = () => {
    const url = itemData.url;
    // open the url in a new tab
    window.open(url, "_blank");
  }

  return <DrawerButton 
    onClick={() => onClick()}
    variant="light"
    className={"sidebarButton"}
  >

    <div style={{ display: "flex", alignItems: "center" }}>

      <CompleteIcon
        isCompleted={false}
        iconType={itemData?.iconType} />

      <div style={{ paddingLeft: 10 }}>
        {itemData.title}

        {itemData?.data?.lengthMins ?
          (
            <div style={{ fontSize: "12px" }} className="text-muted">
              {`${itemData.data.lengthMins} min`}
            </div>
          )
          :
          <div style={{ fontSize: "12px" }} className="text-muted"></div>}
      </div>
    </div>
  </DrawerButton>
}


// creates the sub-lesson link, e.g. to a video or reading
const DrawerItem = ({ itemData, itemIndex, onClick, isSlide }) => {
  const { isLessonSlideCompleted } = useContext(CompletionContext)
  const { lessonId, slides, currSlideIndex, getOutlineSlideId } = useContext(LessonContext);
  const currOutlineId = getOutlineSlideId(currSlideIndex);
  const itemOutlineId = getOutlineSlideId(itemIndex);
  let isActiveSlide = false
  let isCompleted = false
  if (isSlide) {
    isActiveSlide = currOutlineId === itemOutlineId
    isCompleted = checkIfOutlineItemCompleted(lessonId, itemIndex, slides, isLessonSlideCompleted);
  }
  return (


    <DrawerButton variant="light"
      onClick={onClick}
      className={`${isActiveSlide ? "activeSidebarButton" : "sidebarButton"}`}
    >

      <div style={{ display: "flex", alignItems: "center" }}>

        <CompleteIcon
          isCompleted={isCompleted}
          iconType={itemData?.iconType} />

        <div style={{ paddingLeft: 10 }}>
          {itemData.title}

          {itemData?.data?.lengthMins ?
            (
              <div style={{ fontSize: "12px" }} className="text-muted">
                {`${itemData.data.lengthMins} min`}
              </div>
            )
            :
            <div style={{ fontSize: "12px" }} className="text-muted"></div>}
        </div>
      </div>
    </DrawerButton>
  )
}

// Adds the completed /not completed / video icon to the side bar list
const CompleteIcon = ({ isCompleted, iconType }) => {

  if (isCompleted) {
    return (
      <FaCheckCircle size='20' color='rgb(31, 131, 84)' />
    )
  }

  if (iconType === "book") {
    return (
      <FaBookOpen size='20' color='rgb(0, 0, 0, 0.4)' />
    )
  }

  if (iconType === "video") {
    return (
      <FaPlayCircle size='20' color='rgb(0, 0, 0, 0.4)' />
    )
  }

  return (
    <FaCircle size='20' color='rgb(0, 0, 0, 0.2)' />
  )

}

function checkIfOutlineItemCompleted(lessonId, itemIndex, slides, isSlideComplete) {

  // there could be multiple slides that this outline item represents
  // you need to make sure they are all complete

  // check the first slide
  const itemSlideId = slides[itemIndex]?.id
  console.log("checking...", lessonId, itemSlideId)
  if (!isSlideComplete(lessonId, itemSlideId)) {
    console.log("not complete", itemSlideId)
    return false
  }

  // continue checking until you find a non-hidden slide
  let i = itemIndex + 1
  while (i <= slides.length) {
    const currSlideId = slides[i]?.id
    const hide = slides[i]?.data?.hideFromOutline
    if (hide) {
      if (!isSlideComplete(lessonId, currSlideId)) {
        console.log("not complete", currSlideId)
        return false
      }
    } else {
      break
    }
    // look at next slide
    i++;
  }

  return true
}


const DrawerButton = styled(Button)`
  width: 100%;
  border: none;
  text-align: left;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: system-ui,-apple-system,Segoe UI, Roboto,Helvetica Neue;
  font-size: 14px;
  border-radius: 0;
`;

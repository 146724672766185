import Swal from "sweetalert2";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { uuidv4 } from "lib0/random";

const imageUploadSwal = Swal.mixin({
  title: "Select image",
  html: `
  <input type="file" name="image" id="upload-image" accept="image/*" required>
  <br>
  <label for="altText">Alt Text:</label>
  <input type="text" name="altText" id="alt-text" required>
  `,
  focusConfirm: false,
  preConfirm: () => {
    // console.log(document.getElementById("upload-image").files)
    return {
      // @ts-ignore
      file: document.getElementById("upload-image").files[0],
      // @ts-ignore
      altText: document.getElementById("alt-text").value
    }
  }
})

export const onInsertImage = (editor, userId) => {
  imageUploadSwal.fire().then((e) => {
    if (e.isConfirmed) {
      const {file, altText} = e.value;
      console.log(file)
      if (file) {
       uploadImg(editor,userId,file, altText);
      }
    }
  });
};

const insertImage = async (editor, imgUrl, altText) => {
  const img = new Image();
  
  img.onload = function () {
    const { naturalWidth: width, naturalHeight: height } = this;

    editor
      .chain()
      .focus()
      .insertContent(`<resizableImage src="${imgUrl}" alt="${altText}" width="${width}" height="${height}"></resizableImage><p></p>`)
      .run();
  };
  
  img.src = imgUrl;
};


export const uploadImg = (editor,userId,file, altText) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const uniqueId = uuidv4();
    const uploadTask = storageRef
      .child(`richTextImages/${userId}/${uniqueId}`)
      .put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress, snapshot.bytesTransferred, snapshot.totalBytes);
        if (progress == 100) {
          Swal.fire({
            title: `Uploading image: Complete`,
            toast: true,
            timer: 2000,
            showConfirmButton: false,
            timerProgressBar: true,
            showClass: {
              popup: "none",
            },
          });
        } else {
          Swal.fire({
            title: `Uploading image: ${progress}%`,
            toast: true,
            timer: 10000,
            showClass: {
              popup: "none",
            },
            showConfirmButton: false,
          });
        }
      },
      (error) => {
        throw error;
      },
      () => {
        console.log('done uploading') 
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          console.log(url)
          console.log(editor)
          insertImage(editor, url, altText);
        });
      }
    );
  };



  
import { Link, useParams } from "react-router-dom"
import { database } from "firebaseApp";
import { useEffect, useState } from "react";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { updateKarma } from "course/PeerLearning/KarmaUtils";
import { Loading } from "components/loading/Loading";
import { useRoomMembers, submitGratitude, updateStudentComment, submitSLFeedback, useRoomInfoOnce } from "../firebase/realtime/Rooms";
import { FaCode, FaHome } from "react-icons/fa";
import { isMentor, isMinimumRole, isSectionLeader, isStaffRole } from "contexts/profile_util";
import { ProfileContext } from "contexts/ProfileContext";
import { Role } from "types/role";
import { useContext } from "react";
import ErrorBoundary from "components/errors/ErrorBoundary";
import { useUserId } from "hooks/user/useUserId";
import { IDEContext } from "./contexts/IDEContext";
import { getIsTeachNowStudent } from "course/teacherPuzzles/TNFirebase";



const FirstFeedbackButtons = ({ sendFeedback, setShowCommentBox }) => {

  const onClick = (feedback) => {
    sendFeedback(feedback);

    // If they sent thanks, show the exceptional message option
    if(feedback === 1) {
      setShowCommentBox(true);
    }

  }

  return (
    <>
    <div className="alert-heading">Would you like to send thanks to the people you worked with?</div>
    <div className="mt-4 d-flex justify-content-center">
      <button className="btn mr-2 sendthanks-yes-bt" onClick={() => onClick(1)}>Send thanks!</button>
      <button className="btn sendthanks-no-btn" onClick={() => onClick(0)}>Not now</button>
    </div>
    </>
  )
}

const ExceptionalMessage = ({ sendMessage }) => {
  const [comment, setComment] = useState('');

  const handleComment = () => {
    if (comment === '') return
    sendMessage(comment);
    setComment('')
  };

  return (
    <>
      <div className="mt-3 text-center">Had an exceptional session? Send a message to the section leader!</div>
      <div className="row d-flex justify-content-center mt-3" style={{width: "90%"}}>
        <textarea
          className="form-control"
          placeholder=""
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="mt-3 d-flex justify-content-center">
        <button className="btn sendthanks-extra-yes-bt" onClick={() => handleComment()}>Send message</button>
      </div>
    </>
  )
}

const ContinueWorkingCard = ({projectUrl, courseId }) => {
  const styleRetButton = {borderRadius: 5, padding: "10px", textDecoration: "none"}
  return (
    <div className="mt-3">
      <div className="d-flex justify-content-center">
        <Link to={projectUrl} className="text-primary text-center mr-3"  style={{ ...styleRetButton}}>
          <FaCode size={50}/>
          <div>Continue working</div>
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link to={`/${courseId}`} className="text-purple text-center"  style={{ ...styleRetButton, color: "#b757f6"}}>
          <FaHome size={50}/>
          <div>Go to home page</div>
        </Link>
      </div>
    </div>
  );
};


const FeedbackCard = ({ sendFeedback, sendMessage, showCommentBox, setShowCommentBox }) => {

  return (
    <div className="alert alert-warning d-flex flex-column align-items-center mx-auto mt-3" role="alert" style={{ maxWidth: "30rem"}}>
      {!showCommentBox ? <FirstFeedbackButtons sendFeedback={sendFeedback} setShowCommentBox={setShowCommentBox} /> : <></>}
      {showCommentBox ? <ExceptionalMessage sendMessage={sendMessage} /> : <></>}
      <div className="mt-4 text-center">If you want to report anything about this experience to the course staff, <a href="https://forms.gle/maUVMNnTZLL86UXf7">fill out this form</a>.</div>
    </div>
  );

}

export const EndOfSessionContainer = () => {
    const { courseId, roomId } = useParams()
    const  { userData, loading } = useContext(ProfileContext)
    const { projectData } = useContext(IDEContext);
    const userId = useUserId()

    const courseRole = userData?.courseRole
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [showSLFeedback, setShowSLFeedback] = useState(true);
    const roomInfo = useRoomInfoOnce(courseId, roomId)

    const [projectUrl, setProjectUrl] = useState(undefined)

    // This is used to delay showing the continue buttons so they fill out feedback
    const [timeoutOver, setTimeoutOver] = useState(false)

    // This is used to check if the user has already sent feedback for this roomId (refreshed the page)
    const [hasSentFeedback, setHasSentFeedback] = useState(undefined)

    // This is used for submitting karm to everyone in the room
    const roomMembers = useRoomMembers(courseId, roomId)

    // This is for the student teach now experiemnt 
    const [isStudentTeachNow, setIsStudentTeachNow] = useState(null)

    const sendFeedbackStudent = (val) => {
      submitGratitude(val, roomId, userId, courseId).then(() => {
        // Only update karma if they are saying thank you
        if(val == 1) {
          // Call firebase function that takes in: courseId, senderId, roomMembersDict, value
          updateKarma(courseId, userId, roomMembers, 'gratitude')
        }

      })
    }

    const sendFeedbackSL = async (val, text, comment, stuckLevel) => {
      await submitSLFeedback(val, text, comment, stuckLevel, roomId, userId, courseId)
      setShowSLFeedback(false)
    }

    const sendMessage = (comment) => {
      updateStudentComment(comment, roomId, userId, courseId).then(() => {
        setShowCommentBox(false)
      }).catch((err) => {
        console.error("Error updating comment: ", err)
        setShowCommentBox(false)
      })
    }

    useEffect(() => {
      const checkExpGroup = async () => {
        const isInExpGroup = await getIsTeachNowStudent(courseId, userId)
        setIsStudentTeachNow(isInExpGroup)
      }
      checkExpGroup()
    }, [])

    // Make a use effect that sets a timeout for 5 seconds. After 5 seconds, it sets showContinueButtons to true
    useEffect(() => {
      const tout = setTimeout(() => {
        if(!timeoutOver) {
          setTimeoutOver(true)
        }
      }, 5000)
      return () => clearTimeout(tout)
    }, [])

    // Use effect to check if the user has already given feedback for this room
    // Feedback is stored in firestore in matching/cip3/rooms/roomId/gratitude/uid
    useEffect(() => {
      const db = getFirestore()
        if (userId) {
            const docRef = doc(db, `teachnow/${courseId}/tickets/${roomId}/gratitude/${userId}`);
            onSnapshot(docRef, (doc) => {
              setHasSentFeedback(doc.exists());
          });
        }
    }, [userId, courseId, roomId])

    useEffect(() => {
      if(!roomInfo || !projectData) return
      console.log("project data: : : :: :: ", projectData)
      const projId = projectData.uid
      const assnId = projectData.assnId
      if (assnId) {
        setProjectUrl(`/${courseId}/ide/a/${assnId}`)
      } else {
        setProjectUrl(`/${courseId}/ide/p/${projId}`)
      }

    }, [roomInfo, projectData])

  if (loading || isStudentTeachNow === null) {
    return <Loading/>
  }


  // Gracefully handle if they don't have userData yet
  if (!loading && userData === undefined) {
    const msg = `userData for ${userId} is undefined`
    console.error(msg)
    return <ErrorBoundary/>
  }

  if (hasSentFeedback === undefined || roomMembers === undefined || roomInfo === undefined || courseRole === undefined || projectUrl === undefined) {
    console.log(hasSentFeedback, roomMembers, roomInfo)
    return <Loading/>
  }

  const showFeedbackCard = (!hasSentFeedback) || (hasSentFeedback && showCommentBox)

  const role = userData?.courseRole
  const isStaff = isStaffRole(role)
  const isMentorRole = isMentor(role)
  const isSLRole = isSectionLeader(role)


  if(isStaff || isMentorRole || isSLRole || isStudentTeachNow) {
    return (
      <div style={{padding: "5vh"}}>
        {showSLFeedback && <SLFeedbackCard sendFeedback={sendFeedbackSL} />}
        {(!showSLFeedback || timeoutOver) && <GoHome courseId={courseId}/>}
      </div>
    );
  } else {
    return (
      <div style={{padding: "5vh"}}>
        {showFeedbackCard && <FeedbackCard sendFeedback={sendFeedbackStudent} sendMessage={sendMessage} showCommentBox={showCommentBox} setShowCommentBox={setShowCommentBox} />}
        {(hasSentFeedback || timeoutOver) && <ContinueWorkingCard courseId={courseId} projectUrl={projectUrl} />}
      </div>
    );
  }
}

const LikertScale = ({ stuckLevel, setStuckLevel }) => {
  return (
    <div className="mt-4">
      <h5>How stuck was the student when you first joined?</h5>
      <div className="d-flex align-items-center justify-content-center">
        <span>Not stuck at all</span>
        <div className="d-flex flex-grow-1 justify-content-around">
          {[...Array(5)].map((_, i) => (
            <div key={i} style={{margin: "0 10px"}}>
              <input
                type="radio"
                name="stuckLevel"
                value={i}
                checked={stuckLevel === `${i}`}
                onChange={() => setStuckLevel(`${i}`)}
              />
            </div>
          ))}
        </div>
        <span>Very stuck</span>
      </div>
    </div>
  )
}


const SLFeedbackCard = ({ sendFeedback }) => {
  const [comment, setComment] = useState('');
  const [feedbackChoice, setFeedbackChoice] = useState(undefined);
  const [stuckLevel, setStuckLevel] = useState('');

  const handleFeedback = () => {
    if (feedbackChoice === undefined) return
    sendFeedback(feedbackChoice, indicesToText[feedbackChoice], comment, stuckLevel);
    setComment('');
    setStuckLevel('');
  };

  const indicesToText = {
    0: "Not now",
    1: "It was not good",
    2: "It was good, but could have been better",
    3: "It was great!"
  }

  return (
    <div className="card d-flex flex-column align-items-center mx-auto mt-3" role="alert" style={{ maxWidth: "30rem", backgroundColor: "#fff1d8" }}>
      <div className="card-body mt-1 text-center">
      <h5 className="card-title">How did that session go?</h5>
        <div className="d-flex flex-column justify-content-center">
          {/* Loop over indices to ext and make buttons for each of them*/}
          {Object.keys(indicesToText).reverse().map((index) => {
            return (
              <button
                key={index}
                className={`btn btn-outline-primary mt-2 ${
                  feedbackChoice === index ? "active" : ""
                }`}
                onClick={() => setFeedbackChoice(index)}
              >
                {indicesToText[index]}
              </button>
            );
          })}
        </div>
        <LikertScale stuckLevel={stuckLevel} setStuckLevel={setStuckLevel} />
        <div className="text-center" style={{marginTop: "50px"}}>Let us know if you have any feedback</div>
        <div className="row d-flex justify-content-center mt-2">
          <textarea
            className="form-control"
            placeholder=""
            value={comment}
            rows={3}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="mt-3 d-flex justify-content-center">
            <button className="btn btn-primary" onClick={handleFeedback}>Submit feedback</button>
          </div>
        </div>
      </div>
    </div>
    );
}

const GoHome = ({courseId}) => {
  const styleRetButton = {borderRadius: 5, padding: "10px", textDecoration: "none"}
  return (
    <div className="mt-3">
      <div style={{ width: "100%", textAlign: "center" }}>
        <p>Head back to the home page to do more TeachNow</p>
      </div>
      <div className="d-flex justify-content-center">
        <Link to={`/${courseId}`} className="text-purple text-center"  style={{ ...styleRetButton, color: "#b757f6"}}>
          <FaHome size={50}/>
          <div>Go to home page</div>
        </Link>
      </div>
    </div>
  );
};


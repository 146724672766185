import { createRoot } from "react-dom/client";
import { InlineComment } from "course/instructorFeedback/InlineComment";


/**
  * Update the height of the view zone based on the content
  * This function is called after the tiptap comment is loaded
  * and also when the comment is edited
  */
export const updateViewZoneHeight = (editorRef, lineNumber, domNodeRefs, viewZoneIds) => {
  const lineHeight = 18;

  setTimeout(() => {

    // Retrieve the domNode and viewZoneId of the line number
    const domNode = domNodeRefs.current[lineNumber];
    if (viewZoneIds.current[lineNumber] && editorRef.current && domNode) {

      // This line makes sure the latest height is calculated
      requestAnimationFrame(() => {
        domNode.style.height = 'auto';

        // Calculate the real height of the loaded content
        const calculatedHeight = domNode.scrollHeight;
        editorRef.current.changeViewZones((changeAccessor) => {

          // Remove the temporary view zone
          changeAccessor.removeZone(viewZoneIds.current[lineNumber]);

          // Add the view zone with the calculated height
          viewZoneIds.current[lineNumber] = changeAccessor.addZone({
            afterLineNumber: lineNumber,
            heightInLines: calculatedHeight / lineHeight,
            domNode: domNode,
          });
        });
      });
    }
  }, 500);
};

/**
 * Remove the view zone from the editor at the specified line number
 * and delete the corresponding viewZoneId and domNodeRef
 * This function is called when the delete button is clicked
 */
export const removeViewZone = (editorRef, lineNumber, viewZoneIds, domNodeRefs) => {
  if (viewZoneIds.current[lineNumber] && editorRef.current) {
    editorRef.current.changeViewZones((changeAccessor) => {
      changeAccessor.removeZone(viewZoneIds.current[lineNumber]);
      delete viewZoneIds.current[lineNumber];
      delete domNodeRefs.current[lineNumber];
    });
  }
};


/**
  * Add a view zone to the editor to display a comment box
  * at the specified line number. The comment box is a Tiptap
  * editor that allows the user to add inline comments.
  */
export const addCommentBox = (
  isEdit, 
  editorRef, 
  lineNumber, 
  viewZoneIds, 
  domNodeRefs, 
  projectData, 
  userData, 
  courseId
) => {
  // Prevent adding multiple comment boxes on the same line
  if (viewZoneIds.current[lineNumber]) return;

  editorRef.current.changeViewZones((changeAccessor) => {

    // Create a new div to contain the comment box
    const domNode = document.createElement("div");
    domNode.style.position = "relative";
    domNode.style.zIndex = "10";
    domNode.style.pointerEvents = "auto";
    domNode.setAttribute("data-line-number", lineNumber);
    domNode.setAttribute("data-view-zone-id", viewZoneIds.current[lineNumber]);

    domNodeRefs.current[lineNumber] = domNode;

    // Adding a temporary height to the div
    // The final height will be calculated after rendering
    viewZoneIds.current[lineNumber] = changeAccessor.addZone({
      afterLineNumber: lineNumber,
      heightInLines: 5,
      domNode: domNode,
    });

    // Render content inside domNode
    // The inlineComment is the Tiptap editor to add comments
    const root = createRoot(domNode);
    root.render(<InlineComment
      isEdit={isEdit}
      userData={userData}
      projectData={projectData}
      lineNumber={lineNumber}
      updateHeight={() => updateViewZoneHeight(editorRef, lineNumber, domNodeRefs, viewZoneIds)}
      removeViewZone={() => removeViewZone(editorRef, lineNumber, viewZoneIds, domNodeRefs)}
      courseId={courseId}
    />);

    // Measure and adjust the height after rendering
    updateViewZoneHeight(editorRef, lineNumber, domNodeRefs, viewZoneIds);

    // Moving the editor to the line number where the comment box was added
    // Otherwise, all the comment boxes below the current view are collapsed
    editorRef.current.revealLineInCenter(lineNumber);
  });
};

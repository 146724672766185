import { Dropdown, DropdownButton } from "react-bootstrap";
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaAsterisk, FaBold, FaCode, FaFont, FaHeading, FaImage, FaItalic, FaLink, FaMap, FaPaintBrush, FaParagraph, FaPython, FaQuoteLeft, FaRemoveFormat, FaSquare, FaSquareRootAlt, FaTextHeight, FaUnderline, FaShapes, FaTerminal, FaCalendarDay } from "react-icons/fa";
import FaKarel from "../../../pyodide/KarelLib/images/classic/karelEast.png"
import { createEditableLink } from "../extensions/EditableLink";
import { onInsertImage } from "../extensions/Image";
import { useUserId } from "hooks/user/useUserId";
import { EmojiDropdown } from "../components/EmojiDropdown";
import { onInsertDateTime } from "../extensions/LocalDateTime";

export const HandoutButtonBar = ({ editor, editable }) => {

  // users need to be logged in only if they are going to upload images
  const userId = useUserId()

  const textStyleColor = editor?.getAttributes('textStyle')?.color
  const dividerRight = { borderRight: "1px solid lightgrey" }
  if (!editable) return <></>;
  return (
    <div
      className="discussion-button-bar d-flex align-items-center"
      style={{
        top: "0",
        background: "white",
        border: "1px solid lightgrey",
        borderRadius: "5px 5px 0px 0px",
      }}
    >

      {/* Basic text */}
      <div style={dividerRight} >

        <button
          onClick={() => editor.chain().focus().insertContent(`<p></p>`).run()}
          className={"btn btn-sm btn-light "}
        >
          <FaParagraph />
        </button>
        <button
          onClick={() => editor.chain().focus().insertContent(`<h3></h3>`).run()}
          className={"btn btn-sm btn-light "}

        >
          <FaHeading />
        </button>

        <button
          onClick={() => editor.chain().focus().insertContent(`<h4></h4>`).run()}
          className={"btn btn-sm btn-light "}

        >
          H4
        </button>
        <button
          onClick={() => editor.chain().focus().insertContent(`<br/>`).run()}
          className={"btn btn-sm btn-light "}

        >
          Br
        </button>
      </div>

      {/* Bold, italic, underline */}
      <div
        style={dividerRight}
      >
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className="btn btn-sm btn-light"
        ><FaBold /></button>

        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className="btn btn-sm btn-light"
        ><FaItalic /></button>

        <button
          onClick={() => {
            editor.chain().focus().setNode('terminal').run();
          }}
          className="btn btn-sm btn-light"
        >
          <FaTerminal /> {/* Assuming you have an icon component FaTerminal */}
        </button>


        <input
          className="btn btn-sm btn-light"
          type="color"
          style={{ width: 32, height: 31, paddingTop: '9px', paddingBottom: '5px' }}
          onInput={event => editor.chain().focus().setColor(event.target.value).run()}
          value={textStyleColor ? textStyleColor : 'black'}
        />

        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="btn btn-sm btn-light"
        ><FaRemoveFormat /></button>
      </div>


      <DropdownButton id="text-align-dropdown" flip={true} variant="light" size="sm" title={<FaAlignLeft />}>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('left').run()}><FaAlignLeft /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('center').run()}><FaAlignCenter /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('justify').run()}><FaAlignJustify /></Dropdown.Item>
        <Dropdown.Item onClick={() => editor.chain().focus().setTextAlign('right').run()}><FaAlignRight /></Dropdown.Item>
      </DropdownButton>


      <EmojiDropdown editor={editor} />





      {/* fancy tools */}


      <button
        onClick={() =>
          editor.chain().focus()
            .insertContent("<karelworld></karelworld><p></p>")
            .run()
        }
        className="btn btn-sm btn-light"
      >
        <FaMap />
      </button>

      <button
        onClick={() => createEditableLink(editor)}
        className={"btn btn-sm btn-light "}
      >
        <FaLink />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={"btn btn-sm btn-light "}
      >
        <FaCode />
      </button>

      <div style={dividerRight}>

        <button onClick={() => onInsertImage(editor, userId)} className="btn btn-sm btn-light">
          <FaImage />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={"btn btn-sm btn-light "}
        >
          <FaQuoteLeft />
        </button>


        <button
          onClick={() =>
            editor.chain().focus()
              .insertContent("<runnable-karel></runnable-karel><p></p>")
              .run()
          }
          className="btn btn-sm btn-light karel-icon-img"
        >
          <img src={FaKarel} className="karel-icon" />
        </button>

        <button
          onClick={() =>
            editor.chain().focus()
              .insertContent("<runnable-graphics></runnable-graphics><p></p>")
              .run()
          }
          className={"btn btn-sm btn-light "}
        >
          <FaShapes />
        </button>

        <button 
          onClick={() => {
            onInsertDateTime(editor)
          }}
          className={"btn btn-sm btn-light "}
        >
          <FaCalendarDay />
        </button>
      </div>

      {/* <button
          onClick={() =>
            editor.chain().focus()
              .insertContent("<runnable-code></runnable-code><p></p>")
              .run()
          }
          className="btn btn-sm btn-light "
        >
          <FaPython />
        </button> */}





      {/* <button onClick={() => editor.chain().focus().insertContent('<karelworld></karelworld>').run()}className='btn btn-sm btn-light'><FaPaintBrush/></button> */}







      {/* see the final example here https://casesandberg.github.io/react-color/ */}

      {/* <input
        type="color"
        style={{width:25,height:25}}
        onInput={event => editor.chain().focus().setColor(event.target.value).run()}
        value={textStyleColor ? textStyleColor : 'black'}
      /> */}



      {/* <Dropdown>
          <Dropdown.Toggle size="sm" variant="light" id="dropdown-basic">
            <FaHeading />
          </Dropdown.Toggle>

          <Dropdown.Menu >
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h1></h1>").run()
              }
            >
              Title
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                editor.chain().focus().insertContent("<h3></h3>").run()
              }
            >
              Subtitle
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}


    </div>
  );
};

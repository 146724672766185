import React from 'react';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { CoursePageBodyContainer } from '../../components/layout/CoursePageBodyContainer';
import { FaHandshake, FaPlus, FaBookOpen, FaVideo, FaPuzzlePiece, FaUnlock } from 'react-icons/fa';

export const HelpSplash = (props) => {
  return <CoursePageBodyContainer 
    title={<span>One on one help</span>}
    mainColumn = {<HelpSplashColumn/>}
    rightColumn = {<HelpRightColumn/>}
  />
};

const HelpButton = (props) => {
  const onClick = ()=> {
    window.open("https://www.w3schools.com",'popUpWindow',toolbar=0,menubar=0,titlebar=0);
  }

  return <>
  <button onClick = {onClick}className='btn btn-primary btn-lg'>Get Human Help, Now</button>
  </>
}

const HelpSplashColumn = (props) => {
  return <>
  <HelpButton/>

  <div className='alert alert-primary mt-3'>When you hit the "Get Human Help, Now" button we will either have you colearn with a peer who is at a similar conceptual point in the course, or, if a section leader is available you will get to talk to them. It may take up to 3 mins to find a good pairing. You should expect to spend around 15 minutes in the session.</div>
    

  <div className="splashCard">
      <div className="splashCardMainTitle">Past Connections</div>
  
      <table className="table  table-hover">
        <tbody>
          <tr>
            <td>
              <img className="" src="/fakeFaces/face2.jpeg" style={{width:100}}></img>
            </td>
            <td><a href="">Benjamin O.</a>, Sat 23rd, 7pm</td>
            <td>
              <p>
                <a href="">
                  <FaVideo/> View recording
                </a>
              </p>
              <p></p>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <img className="" src="/fakeFaces/face8.jpeg" style={{width:100}}></img>
            </td>
            <td><a href="">Ayesha Z.</a>, Sat 23rd, 7pm</td>
            <td>
              <p>
                <a href="">
                  <FaVideo/> View recording
                </a>
              </p>
              <p></p>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
  </div>
  </>
};

const HelpRightColumn = (props) => {
  return <>
    <div className="splashCardRight">
      <div className="splashCardRightTitle">Learn how to teach</div>
      We think you could become a great teacher! Curious about the art and science of teaching coding? Explore our resources:<br/>
      <a href=""><FaBookOpen/> Teacher Reader</a><br/>
      <a href=""><FaPuzzlePiece/> Teacher Puzzles</a><br/>

    </div>

    <hr/>

    <div className="splashCardRight">
      <div className="splashCardRightTitle">Teach! <FaUnlock/></div>
      The best way to learn how to teach is to practice. Want to help someone else out in class? We will pair you with a student who hasn't reached the same milestones you have and you can help them get started. Unlocked.<br/>
      <button className='btn btn-secondary'>Teach a fellow student</button>

    </div>
    <hr/>
  </>
}
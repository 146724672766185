import { createNewKarelProject, createNewConsoleProject, createNewGraphicsProject } from "ide/utils/createNewProject"

export const codeCopy = {
    "karel" : {
        title: "Karel",
        createNewProject: createNewKarelProject
    },
    "console": { 
        title: "Console",
        createNewProject: createNewConsoleProject
    },
    "graphics": {
        title: "Graphics",
        createNewProject: createNewGraphicsProject
    },
}
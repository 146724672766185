/**
 * This page was neccessary when we accidentallty commited a service worker that prevented images from loading.
 * We needed a way to clear the cache for all users, so we would send them to this page to clear their cache.
 */

import { CoursePageSingleCol } from "components/layout/CoursePageSingleCol";



export const FixCachePage = () => {
    return (
        <CoursePageSingleCol
            column={<FixCache />}
        />
    )

}

export const FixCache = () => {

    const clearServiceWorkers = async () => {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (let registration of registrations) {
            await registration.unregister();
        }
        window.location.reload();
      }


    return (
        <div>
            <h1>Reset your cache</h1>
            <p>This may be helpful if you are having trouble seeing images and videos on the platform</p>
            <button className="btn btn-primary" onClick={clearServiceWorkers}>Reset Cache</button>
        </div>
    )

}
import SLFeedbackHeader from "./components/SLFeedbackHeader";
import styled from "styled-components";
import SLTalkTime from "./components/SLTalkTime";
import LearnerEngagement from "./components/LearnerEngagement";
import TalkMovesComponent from "./components/TalkMovesComponent";
import GPTFeedback from "./components/GPTFeedback";
import SLReflection from "./components/SLReflection";
import MessageBoard from "./components/MessageBoard";
import StudentFeedbackListing from "./components/StudentFeedbackListing";
import StudentQuestionsForSL from "./components/StudentQuestionsForSL";
import { useCourseId } from "hooks/router/useUrlParams";
import firebase from "firebase/compat/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import Swal from "sweetalert2";
import { Loading } from "components/loading/Loading";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import SLFeedbackHeaderMessage from "./components/SLFeedbackHeaderMessage";
import { Link } from "react-router-dom";
import { FaDownload, FaFile, FaScroll } from "react-icons/fa";

const TALKTIME_GOAL = 25

const SectionContainer = styled.div`
  margin-bottom: 50px;
`;

function convertObjectToArray(object) {
  let result = [];
  for (const key in object) {
    result.push(object[key]);
  }
  return result;
}

function getPreviousWeek(currentWeek) {
  switch (currentWeek) {
    case "week1":
      return "week0";
    case "week2":
      return "week1";
    case "week3":
      return "week2";
    case "week4":
      return "week3";
    case "week5":
      return "week4";
    case "week6":
      return "week5";
    default:
      return null;
  }
}

function getTalktimeDifference(talkTime, previousTalkTime) {
  if (!talkTime || !previousTalkTime) {
    return null;
  }

  return Math.abs(talkTime - previousTalkTime);
}

function checkIfDifferenceIsPositive(talkTime, previousTalkTime) {
  if (!talkTime || !previousTalkTime) {
    return null;
  }

  return talkTime > previousTalkTime;
}

// This is a container for all the feedback components for the Section Leader
const SectionLeaderFeedbackComponents = ({ week, sectionId }) => {
  const db = getFirestore();
  const { userData } = useContext(ProfileContext);
  const userId = userData.id;
  const courseId = useCourseId();
  const previousWeek = getPreviousWeek(week);

  // Load the mood survey document for this user
  const feedbackDataPath = `/transcriptFeedback/${courseId}/${week}/feedback/${sectionId}/data`;
  const previousFeedbackDataPath = `/transcriptFeedback/${courseId}/${previousWeek}/feedback/${sectionId}/data`;

  const feedbackDataDocRef = doc(db, `${feedbackDataPath}`);
  const previousFeedbackDataDocRef = doc(db, `${previousFeedbackDataPath}`);
  const [feedbackData, feedbackLoading] = useDocumentData(feedbackDataDocRef);
  const [previousFeedbackData, previousFeedbackLoading] = useDocumentDataOnce(
    previousFeedbackDataDocRef
  );

  postprocess(feedbackData);

  async function submitReflectionToFirestore(reflection) {
    try {
      await setDoc(
        doc(db, `${feedbackDataPath}`),
        { slReflection: { submittedReflection: reflection } },
        { merge: true }
      );

      Swal.fire({
        icon: "success",
        title: "Reflection submitted successfully!",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to submit reflection",
        text: error.message,
      });
    }
  }

  if (feedbackLoading || previousFeedbackLoading) {
    return <Loading />;
  }

  if (feedbackData === undefined) {
    return (
      <div>
        <MessageBoard message={"Feedback not available."} />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <SectionContainer>
        <SLFeedbackHeaderMessage slName={userData.displayName} />
      </SectionContainer>
      {feedbackData?.studentQuestions && (
        <div className="mb-4">
          <StudentQuestionsForSL
            questions={convertObjectToArray(feedbackData.studentQuestions)}
          />
        </div>
      )}
      {feedbackData?.studentFeedback && (
        <div>
          {/* Display student feedback if available */}
          <StudentFeedbackListing
            feedback={convertObjectToArray(feedbackData.studentFeedback)}
          />
        </div>
      )}

      {/*displays student talk time component, graph*/}
      {feedbackData.talkTime && (
        <SectionContainer>
          <div className="mt-1">
            <h1>Engagement Overview</h1>
            <p>
              Let's begin by looking at some basic numbers from your latest
              session on active learning. Seeing these numbers go up is a good
              sign that you're getting better at engaging your students. These stats do not include breakouts so they could be incorrect 📊
            </p>
          </div>
          <SLTalkTime
            learner={feedbackData.talkTime.learner}
            sl={feedbackData.talkTime.sl}
            bootcampAverage={TALKTIME_GOAL}
            lastTalkTimeDifference={getTalktimeDifference(
              feedbackData.talkTime.learner,
              previousFeedbackData?.talkTime?.learner
            )}
            isDifferencePositive={checkIfDifferenceIsPositive(
              feedbackData.talkTime.learner,
              previousFeedbackData?.talkTime?.learner
            )}
          />
          {feedbackData.learnerEngagementForSL && (
            <div className="mt-2">
              <LearnerEngagement
                engagedLearners={
                  feedbackData.learnerEngagementForSL.engagedLearners >
                  feedbackData.learnerEngagementForSL.totalLearners
                    ? feedbackData.learnerEngagementForSL.totalLearners
                    : feedbackData.learnerEngagementForSL.engagedLearners
                }
                totalLearners={
                  feedbackData.learnerEngagementForSL.totalLearners
                }
                engagedLearnersLastSession={
                  previousFeedbackData?.learnerEngagementForSL
                    ?.engagedLearners >
                  previousFeedbackData?.learnerEngagementForSL?.totalLearners
                    ? previousFeedbackData?.learnerEngagementForSL
                        ?.totalLearners
                    : previousFeedbackData?.learnerEngagementForSL
                        ?.engagedLearners
                }
                totalLearnersLastSession={
                  previousFeedbackData?.learnerEngagementForSL?.totalLearners
                }
              />
            </div>
          )}
        </SectionContainer>
      )}
      {/*new*/}

      {feedbackData.talkMoves && (
        <SectionContainer>
          <TalkMovesComponent
            weekName={feedbackData.weekName}
            themeName={feedbackData.themeName}
            talkMoveDemonstrations={
              feedbackData.talkMoves.talkMoveDemonstrations
            }
            transcriptDownloadLink={
              feedbackData.talkMoves.transcriptDownloadLink
            }
          />
        </SectionContainer>
      )}

      {feedbackData.gptFeedback && (
        <SectionContainer>
          <GPTFeedback
            topic={feedbackData.themeName}
            feedbackText={feedbackData.gptFeedback.feedbackText}
          />
        </SectionContainer>
      )}
      <SectionContainer>
        <SLReflection
          topic={feedbackData.themeName}
          submittedReflection={feedbackData.slReflection?.submittedReflection}
          previousReflection={
            previousFeedbackData?.slReflection?.submittedReflection
          }
          onSubmission={(reflection) => {
            submitReflectionToFirestore(reflection);
          }}
        />
      </SectionContainer>
    </div>
  );
};
export default SectionLeaderFeedbackComponents;

function postprocess(feedbackData) {
  if (!feedbackData) return;

  postprocessThemeName(feedbackData);

  console.log(feedbackData);
}

function postprocessThemeName(feedbackData) {
  if (!feedbackData.themeName) return;

  const themeName = feedbackData.themeName;

  // if the themeName was "Getting Ideas on the Table", change it to
  // getting students to talk
  if (themeName === "Getting Ideas on the Table") {
    feedbackData.themeName = "Getting Students to Talk";
  }
}

import "firebase/compat/firestore"
import {
    getFirestore,
    doc,
    setDoc,
} from "firebase/firestore"



export const extractJSON = (jsonString) => {
    const trimmedJsonString = jsonString.replace(/^```json/, '').replace(/```$/, '')
    return JSON.parse(trimmedJsonString)
}

export const getScoreFromGPTResponse = (response) => {
    const jsonString = response.choices[0].message.content;
	const { score } = extractJSON(jsonString)
    const isCorrect = score >= 8
    return {score, isCorrect}
}
import { useContext } from "react"
import { DiagnosticContext } from "./DiagnosticContext"
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor"
import { useCourseId } from "hooks/router/useUrlParams"
import { ProfileContext } from "contexts/ProfileContext"
import { useNavigate } from "react-router"

export const DiagnosticSplash = () => {
  const navigate = useNavigate()
  const courseId = useCourseId()
  const { loading: userIsLoading, userData } = useContext(ProfileContext)
  const {startExam, hasStarted} = useContext(DiagnosticContext)

  if(userIsLoading) {
    return <></>
  }

  console.log(hasStarted)
  if(hasStarted) {
    navigate(`/${courseId}/diagnostic/intro`)
  }

  return <div className="d-flex justify-content-center mt-4">
    <div style={{maxWidth:700, flexGrow:2}}>
      <h1>Code in Place Diagnostic</h1>
      <hr/>
      <button onClick={startExam} className="btn btn-primary btn-lg mb-3">Start Diagnostic</button>
    <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/diagnostic`}/>
    
    </div>
  </div>
}
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { collection, getDocs, getFirestore } from "firebase/firestore";

const GPTEACH_ROOT = "teacher-training-module-GPteach";
const CIP_TEACHER_TRAINING = "cip-teacher-training";
const db = getFirestore();

// Get all the student names from Firebase
export async function getAllStudents() {
  const querySnapshot = await getDocs(
    collection(db, GPTEACH_ROOT, CIP_TEACHER_TRAINING, "students")
  );

  let students = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    // students.push({ name: doc.id, pronouns: doc.data().pronouns });
    students.push({
      name: doc.id,
      studentBio: data["studentBio"],
      keywords: data["keywords"],
    });
  });

  return students;
}

// Get all the scenarios from Firebase
export async function getScenarios() {
  const querySnapshot = await getDocs(
    collection(db, GPTEACH_ROOT, CIP_TEACHER_TRAINING, "scenarios")
  );

  let scenarios = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    scenarios.push({
      scenarioNum: doc.id,
      text: data["Section Plan"],
      title: data["Title"],
      keywords: data["keywords"],
    });
  });

  return scenarios;
}

import { useContext, useEffect } from "react";
import { CourseContext } from "contexts/CourseContext";
import { useNavigate } from "react-router-dom";

export const TranscriptFeedbackRedirect = () => {
  const { currCourseWeek } = useContext(CourseContext);
  const currentWeek = `week${currCourseWeek}`;
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`${currentWeek}`);
  }, [currCourseWeek, navigate]);

  return null;
};

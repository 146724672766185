const GPTFeedback = ({ topic, feedbackText }) => (
  <div>
    <p
      style={{
        fontSize: "1.1em",
        fontWeight: "600",
        marginBottom: "5px",
      }}
    >
      Here's some personalized advice from GPT-4 on <strong>{topic}</strong>.
      This advice comes from your session transcript and strategies that
      previous section leaders have found helpful.
    </p>
    <div className="border rounded p-2 bg-light">
      <p>{feedbackText}</p>
    </div>
  </div>
);

export default GPTFeedback;

import { ProfileContext } from "contexts/ProfileContext";
import { Role } from "types/role";
import { isMinimumRole } from "contexts/profile_util";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { findMakeupSection, joinSpecificZoom } from "./makeupUtil";
import { FaVideo } from "react-icons/fa";
import Swal from "sweetalert2";

export const MakeupZoomSectionButton = ({ disabled, setUsedMakeups, nextSectionTimeIndex, weekIndex }) => {
  
  const courseId = useCourseId();
  const { userData } = useContext(ProfileContext);
  const isTeacher = isMinimumRole(userData.courseRole, Role.MENTOR);
  const isMinor = userData.isMinor || false;

  const onMakeupZoomClicked = async () => {
    let sectionId = await findMakeupSection(
      nextSectionTimeIndex,
      weekIndex,
      courseId,
      isMinor
    )

    console.log("sectionId", sectionId)

    async function preJoin(zoomLink) {
      await setUsedMakeups(zoomLink, nextSectionTimeIndex)
    }

    // 
    if(!sectionId) {
      Swal.fire({
        title: 'All sections are full',
        text: 'Please try again later.',
        icon: 'error',
      })
      return 
    }

    await joinSpecificZoom(sectionId, courseId, isTeacher, preJoin)
  }

  return (
    <button
      className="btn btn-danger btn-lg mr-1"
      disabled={disabled}
      onClick={onMakeupZoomClicked}
    >
      <FaVideo/> Join Makeup Section Zoom
    </button>
  )
}

import { getApp } from "firebase/app"
import { doc, getFirestore } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router"
import { CertificateViewV2 } from "./CertificatePageV2"

const MINIMIZED_BREAKPOINT = 600

export const CertificatePage = () => {
  const certificateId = useParams().certificateId
  const courseId = useCourseId()

  if(!certificateId) return <>No certificate</>

  const db = getFirestore(getApp())
  const certificatePath = `course/${courseId}/certificates/${certificateId}`
  const [certificateDoc, certificateLoading, certificateError] = useDocumentDataOnce(doc(db, certificatePath))

  if(certificateLoading) return <></>
  if(certificateError) {
    return <CertificateError msg="Can't load certificate"/>
  }
  if(!certificateDoc) return <CertificateError msg="Can't find certificate"/>

  return <CertificateViewV2 certificateDoc={certificateDoc}/>

}


const CertificateError = ({msg}) => {
  return <>Error Loading Certificate: {msg}</>
}
import { CourseId } from "types/common";
import { RoleFirebaseString } from "types/role";
import { useCipHttpsCallable } from "./use_cip_https_callable";
/** Compare to firebase:functions/src/set_role.ts */

export interface SetUserRoleRequest {
  /** The ID of the user to set, either this or userEmail must be specified. */
  userId: string;
  /** The ID of the course to set the role in. */
  courseId: CourseId;
  /** The role to set the user to. */
  role: RoleFirebaseString;
}

export type SetUserRoleResponse = Promise<void>;

export const useSetUserRole = () =>
  useCipHttpsCallable<SetUserRoleRequest, SetUserRoleResponse>("setUserRole");

export interface GetUserRoleRequest {
  /** The ID of the user to get, either this or userEmail must be specified. */
  userId: string;
  /** The ID of the course to get the role in. */
  courseId: CourseId;
}

export type GetUserRoleResponse = Promise<RoleFirebaseString>;

export const useGetUserRole = () =>
  useCipHttpsCallable<GetUserRoleRequest, GetUserRoleResponse>("getUserRole");

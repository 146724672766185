import React, { useState, useEffect, useRef } from "react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import Placeholder from "@tiptap/extension-placeholder";
import Blockquote from "@tiptap/extension-blockquote";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Link from "@tiptap/extension-link";
import TextAlign from '@tiptap/extension-text-align'
import { lowlight } from "lowlight";
import python from "highlight.js/lib/languages/python";
import { Color } from "@tiptap/extension-color";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import FontFamily from '@tiptap/extension-font-family'
import Youtube from "@tiptap/extension-youtube";

import { RunnableCode } from "./extensions/RunnableCodeBlock";
import { RunnableKarel } from "./extensions/RunnableKarel";
import { RunnableGraphics } from "./extensions/RunnableGraphics";
import { Paper } from "./extensions/Paper";
import { InlineTex } from "./extensions/InlineTex";
import { BlockTex } from "./extensions/BlockTex";

import { KarelWorldTipTap } from "./extensions/KarelWorldTipTap";
import Iframe from "./extensions/Iframe";
import { ResizableImage } from "./extensions/ResizableImage";
import { Terminal } from "./extensions/Terminal";
import { ConsoleDemo } from "./extensions/ConsoleDemo";
import { LocalDateTime } from './extensions/LocalDateTime'


export const editorExtensions = [
  StarterKit.configure({
    // The Collaboration extension comes with its own history handling
    // (you don't undo other peoples changes)
    history: false,
  }),
  Dropcursor,
  LocalDateTime,
  Paper,
  InlineTex,
  BlockTex,
  Iframe,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  ConsoleDemo,
  TableCell,
  KarelWorldTipTap,
  ResizableImage,
  Blockquote,
  Terminal,
  Text,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Link.configure({
    openOnClick: false,
    linkOnPaste: false,
  }),
  TextStyle,
  TaskList,
  Youtube.configure({
    inline: false,
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  TaskItem.configure({
    nested: true,
  }),
  Color,
  RunnableCode,
  RunnableKarel,
  RunnableGraphics,
  CodeBlockLowlight.configure({
    lowlight,
    defaultLanguage: "python",
  }),
  Placeholder.configure({
    placeholder: "Write something …",
  }),
  Image.configure({
    inline: false,
  }),
];


import { useContext, useEffect, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { checkResponse, createMessages, parseResponse, THRESHOLD } from './gptEvaluation';
import { callGptChat } from 'utils/gpt';
import { handlePuzzleSubmission, logPuzzleStartTime } from "./TNFirebase";
import { useUserId } from "hooks/user/useUserId";
import { useCourseId } from "hooks/router/useUrlParams";
import { CompletionContext } from "contexts/CompletionContext";
import { ContinueBugsButton } from './BugIdentificationPuzzle';
import { DIAG1 } from './UpdatedLabels';

export const ApplicantFeedbackPane = ({ puzzleInfo, numPuzzlesCompleted, moveToNextPuzzle, puzzleLogDocId, nextSlide }) => {
    const { starterCode, trueBugs } = puzzleInfo;
    const [slResponseToStudent, setSlResponseToStudent] = useState('')
    const [gptFeedbackToApplicant, setGptFeedbackToApplicant] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [puzzlePassed, setPuzzlePassed] = useState(false)
    const [lg1, setLg1] = useState(0)
    const [lg2, setLg2] = useState(0)
    const [showEvalaution, setShowEvaluation] = useState(false)
    const [userTrySubmitAgain, setUserTrySubmitAgain] = useState(false)

    // TODO: load true bugs from async handler 

    const { updateTeacherPuzzlesCompleted } = useContext(CompletionContext)

    const userId = useUserId();
    const courseId = useCourseId();

    const resetPuzzle = () => {
        setSlResponseToStudent('');
        setGptFeedbackToApplicant(null);
        setPuzzlePassed(false);
        setLg1(0);
        setLg2(0);
        setShowEvaluation(false);
        moveToNextPuzzle();
    };

    async function getGPTResponse() {
        const messages = createMessages(starterCode, slResponseToStudent);
        // const response = await callGptChat("gpt-3.5-turbo", messages);
        const response = await callGptChat("gpt-4", messages);
        const jsonResponse = checkResponse(response);
        const isValidResponse = jsonResponse !== false;
        if (!isValidResponse) {
              console.log("Invalid response format from GPT")
              // TODO: Need to let the user try again I think... 
              setUserTrySubmitAgain(true);
              return;
        }
        setUserTrySubmitAgain(false);
        return jsonResponse;
    }
    
    async function handleSubmit(event) {
        setIsSubmitting(true);
        event.preventDefault();  // Prevent the form from causing a page reload
        const jsonResponse = await getGPTResponse();
        // If GPT response fails... 
        if(!jsonResponse) {
          setIsSubmitting(false);
          return;
        }
        const parsedResponse = parseResponse(jsonResponse);
        setLg1(parsedResponse.lg1Score);
        setLg2(parsedResponse.lg2Score);
        setPuzzlePassed(parsedResponse.passedEval);
        setGptFeedbackToApplicant(parsedResponse.gptFeedback);
        // make a dictionary with all values in parsedResponse + puzzleInfo and slResponseToStudent in order to log this
        const data = {
          ...parsedResponse,
          puzzleInfo,
          slResponseToStudent
        }
        if(parsedResponse.passedEval) {
          await updateTeacherPuzzlesCompleted('moves');
        }
        await handlePuzzleSubmission(userId, courseId, 'moves', data, puzzleLogDocId);
        setIsSubmitting(false);
        setShowEvaluation(true);
      };


    if (!showEvalaution && !userTrySubmitAgain) {
        return (
          <div style={{ overflowY: 'auto', maxHeight: '98vh', padding: '10px' }}>
            <h4>Bugs present in the code:</h4>
            <ul>{trueBugs.map((bug, index) => <li key={index}>{DIAG1[bug]}</li>)}</ul>
            <h4>What would you say to this student to help them learn?</h4>
            <p>You can check out <a href="link_to_handout">this handout</a> for strategies.</p>
            <form >
              <textarea
                style={{ width: '100%', height: '100px', fontSize: '16px' }}
                placeholder="Type your response to the student here..."
                value={slResponseToStudent}
                onChange={(e) => setSlResponseToStudent(e.target.value)}
              ></textarea>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                  <Button onClick={handleSubmit} disabled={isSubmitting || slResponseToStudent === ''}>
                      {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Response'}
                  </Button>
              </div>
            </form>
          </div>
        )
    } else if(userTrySubmitAgain) {
      return (
        <div style={{ overflowY: 'auto', maxHeight: '90vh', padding: '10px' }}>
          <h4>Bugs present in the code:</h4>
          <ul>{trueBugs.map((bug, index) => <li key={index}>{bug}</li>)}</ul>
          <h4>What would you say to this student to help them learn?</h4>
          <p>You can check out <a href="link_to_handout">this handout</a> for strategies.</p>
          <form >
            <textarea
              style={{ width: '100%', height: '100px', fontSize: '16px' }}
              placeholder="Type your response to the student here..."
              value={slResponseToStudent}
              onChange={(e) => setSlResponseToStudent(e.target.value)}
            ></textarea>
            <div style={{color: 'red'}}>There was an error on our end evaluating your response. Please try again.</div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                <Button onClick={handleSubmit} disabled={isSubmitting || slResponseToStudent === ''}>
                    {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Response'}
                </Button>
            </div>
          </form>
        </div>
      )

    } else {
      return (
        <div style={{ overflowY: 'auto', maxHeight: '90vh', padding: '10px' }}>
          <h4>Your response</h4>
          <form >
            <textarea
              style={{ width: '100%', height: '100px', fontSize: '16px' }}
              placeholder="Type your response to the student here..."
              value={slResponseToStudent}
              readOnly={true}
            ></textarea>
          </form>
          <hr/>
          <ResponseToShowSL puzzlePassed={puzzlePassed} lg1={lg1} lg2={lg2} gptFeedbackToApplicant={gptFeedbackToApplicant} />
          <ContinueBugsButton
            nextSlide={nextSlide}
            numPuzzlesCompleted={numPuzzlesCompleted}
            puzzleTypeWording={"'Teaching Moves'"}
            resetPuzzle={resetPuzzle}
          />
        </div>
      )
    }
}


const ResponseToShowSL = ({ puzzlePassed, lg1, lg2, gptFeedbackToApplicant }) => {
  if (puzzlePassed) {
      return <p>Excellent job! Your response was both welcoming and respectful. You effectively guided the student without simply giving them the answers.</p>;
  } else {
      let feedbackMessages = [];
      if (lg1 > THRESHOLD) {
          feedbackMessages.push("is welcoming and respectful");
      }
      if (lg2 > THRESHOLD) {
          feedbackMessages.push("guided the student instead of giving away the answer");
      }
      if (feedbackMessages.length > 0) {
          return <p>Great job writing a response that {feedbackMessages.join(' and ')}</p>;
      } else {
          return (
            <>
              <p>We think that parts of your response could use some improvement. Here is some feedback:</p>
              <p>{gptFeedbackToApplicant}</p>
            </>
          );
      }
  }
}

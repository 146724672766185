import COUNTRIES from "../../../../data/countries/country_names";
import { FieldArray } from "formik";
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { FaMicrophone, FaStop, FaTrash } from "react-icons/fa";
import {
    TextInput,
    TextAreaInput,
    SelectInput,
    MultipleCheckbox,
    AgreeCheckbox,
    Checkbox,
    DateOfBirth,
  } from "../../../../components/Forms/FormixComponents";
  import { useAudioRecorder } from 'react-audio-voice-recorder';
import { useEffect, useRef, useState } from "react";
import { uploadAudioFile } from "./uploadAudio";
import { useUserId } from "hooks/user/useUserId";
import { useCourseId } from "hooks/router/useUrlParams";
export const NameInput = ({name}) => <TextInput
  label="What is your name?"
  name={name}
  type="text"
  placeholder="Jane Doe"
/>

export const GenderInput = ({name}) => <SelectInput label="Gender" name={name}>
    <option value=""></option>
    <option value="female">Female</option>
    <option value="male">Male</option>
    <option value="non-binary">Non-binary</option>
    <option value="other">Other</option>
    <option value="na">Prefer not to say</option>
</SelectInput>

export const EnglishProficiencyInput = ({name}) => <SelectInput 
label="How comfortable are you with spoken English" 
description={<>Please use the <a target="_blank" href="https://www.linkedin.com/pulse/language-proficiency-linkedin-duaa-ali/">LinkedIn proficiency definitions</a></>}
name={name}>
    <option value=""></option>
    <option value="none">I don't speak any english</option>
    <option value="beginner">Limited Working (Beginner)</option>
    <option value="intermediate">Minimum Professional (Intermediate)</option>
    <option value="advanced">Full Professional (Advanced)</option>
    <option value="native">Native or Bilingual</option>
</SelectInput>

export const DateOfBirthInput = ({name}) => <DateOfBirth name={name}/>

export const CityInput = ({name}) => <TextInput
  label="What city are you in?"
  name={name}
  type="text"
  placeholder="New York City"
/>

export const CountryInput = ({name, values, touched, errors, setFieldValue, setFieldTouched}) => {
  
  // value for typeahead must be a list
  const currValue = values.country ? [values.country] : []
  let error = errors.country ? errors.country : null
  const isTouched = touched.country ? touched.country : false
  if(error && !(typeof error === 'string' || error instanceof String)) {
    error = error.eng_name
  }
  
  const onChange = (e) => {
    console.log('here')
    // returns a list of countries
    const newValue = e[0]
    // if its an undefined country, dont save it
    if(!newValue) {
      return
    }
    setFieldValue("country", newValue) 
  }

  const setCountryFromText = (text) => {
    setFieldTouched("country", true)
    const country = COUNTRIES.find((country) => country.eng_name === text)
    if(country) {
      setFieldValue("country", country)
    } else {
      if(currValue) {
        setFieldValue("country", null)
      }
    }
  }

  const onInputChange = (text) => {
    setCountryFromText(text)
  }

  const onBlur = (e) => {
    const text = e.target.value
    setCountryFromText(text)
  }
    
  const countryKey = "eng_name"

  if(name != 'country') {
    return <>Error: name must be country</>
  }
  
  return <div className="question-spacing">
    <label className="form-label">
      What country are you in?
    </label>
    <div>
    <Typeahead
      id="country-input"
      className="about-text-input p-0"
      labelKey={countryKey}
      onChange={onChange}
      options={COUNTRIES}
      placeholder="United States"
      defaultSelected={currValue}
      onInputChange={(text) => onInputChange(text)}
      onBlur={onBlur}
    />
    </div>
    {isTouched && error && <div className="error">{error}</div>}
  </div> 

}

export const InterestInput = ({name}) => <MultipleCheckbox
    name={name}
    label="Why are you interested in taking this class?"
    >
    <Checkbox
    name={name}
    value="learn"
    label="I want to learn how to program in Python"
    />
    <Checkbox
    name={name}
    value="community"
    label="I want to be part of a community of other students who are also learning"
    />
    <Checkbox
    name={name}
    value="sectionLeader"
    label="I want to have a section leader who I meet with once a week"
    />
    <Checkbox
    name={name}
    value="fun"
    label="For fun and enlightenment"
    />
    <Checkbox
    name={name}
    value="heardOf106a"
    label="I have heard that CS106A has a fun + useful teaching style"
    />
    <Checkbox
    name={name}
    value="job"
    label="I want to get a job as a programmer"
    />
    <Checkbox
    name={name}
    value="newExperiment"
    label="I want to be part of a new experiment in online-learning"
    />
    {/*<Checkbox name="interest" value="other" label="Other" />*/}
</MultipleCheckbox>


export const TimeAvailibleInput = ({name}) => <TextInput
label="How many hours per week will you have for this course?"
name={name}
type="number"
placeholder="10"
/>

export const IndendToCompleteInput = ({name}) => <AgreeCheckbox
  name={name}
  description="Learning takes time. We estimate you will need at least 7 hours a week."
  label="If accepted, I intend to complete this six week course"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const AgreeToBetaInput = ({name}) => <AgreeCheckbox
  name={name}
  description="As with any new technology, there may be some bumps along the way. We will do our best to make sure you have a great experience, but we may need your help to make sure everything is working smoothly."
  label="This course includes new learning experiences and tools. "
>
  <Checkbox name={name} value="yes" label="I understand! Let's learn." />
</AgreeCheckbox>

export const CurrentOccupationInput = ({name}) => <TextInput
  label="What is your current occupation?"
  name={name}
  type="text"
  placeholder="Community College Student, Retired Landscaper, Fire-fighter, etc"
/>


export const CodingExperience = ({name}) => <TextAreaInput
label="Describe your previous coding experience."
description="What have you already built? Have you taken other programming courses? Do you know other coding languages? Recall that Code in Place is for beginners!"
name={name}
type="text"
placeholder="I have never coded before!"
/>

// export const CodingExperience = ({name}) => <MultipleCheckbox
//   description="Code in Place is intended for beginners"
//   label="How much coding experience do you have?"
//   name={name}
// >
//   <Checkbox
//     name={name}
//     value="neverProgrammed"
//     label="I have never coded before."
//   />

//   <Checkbox
//     name={name}
//     value="badClass"
//     label="I have tried to learn to code before, but I am still a beginner."
//   />
//   <Checkbox
//     name={name}
//     value="intermediateOrAdvancedOther"
//     label="I am intermediate or advanced in a programming language other than python"
//   />
//   <Checkbox
//     name={name}
//     value="intermediateOrAdvancedPython"
//     label="I am intermediate or advanced in my understanding of Python"
//   />
// </MultipleCheckbox>

export const LanguageInput = ({name}) => <TextInput
  label="What is your preferred language for section?"
  name={name}
  type="text"
  placeholder="The course materials will be in English"
/>

export const AgreeHonorable = ({name}) => <AgreeCheckbox
  description="It is fine to discuss strategies with others or to look up syntax information online. You may not copy any code from any other person or from online sources outside of the course materials."
  name={name}
  label="I promise that all the work in this application is my own"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const AgreeOneSubmission = ({name}) => <AgreeCheckbox
  description="Only one submission per person. Thanks!"
  name={name}
  label="I promise to only submit one application"
>
  <Checkbox name={name} value="yes" label="Yes" />
</AgreeCheckbox>

export const AboutYourselfInput = ({name}) => <TextAreaInput
  label="Tell us a little about yourself:"
  name={name}
  description="Anything goes. What do you do for fun? Want to tell us a quick anecdote about something that makes you unique—a talent, an unusual experience, or anything of that sort?"
/>

export const DiverseInput = ({name}) => <TextAreaInput
  label="We value having a diverse learning community so we can all learn from each other. What background and experiences would you bring to our online classroom?"
  name={name}
  description="Everyone is welcome here!"
/>

export const FriendsInput = ({name, values}) => {
  if(name !== "referrals") {
    return <>Error key must be "referrals"</>
  }
  const referrals = values.referrals || [];
  const getButtonText = (numReferrals) => {
    if (numReferrals === 0) {
      return "Add a friend's email";
    } else {
      return "Add another email";
    }
  };

  return <>
  <label className="form-label" htmlFor="referrals">
    Do you have any friends or family who are applying to Code in
    Place as students? Enter their emails here. Please make sure the
    emails entered here match the emails they are using to apply.
  </label>
  <FieldArray name="referrals">
    {({ remove, push }) => (
      <div>
        {referrals.length > 0 &&
          referrals.map((referral, index) => (
            <div className="referralContainer" key={index}>
              <div className="refInput">
                <TextInput
                  label={`Email ${index + 1}`}
                  name={`referrals[${index}]`}
                  placeholder="Student email"
                  type="text"
                />
              </div>
              <div className="deleteButtonContainer">
                <button
                  className="deleteRef"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        {referrals.length < 3 && (
          <Button
            className="addButton"
            variant="outline-primary"
            onClick={() => push("")}
          >
            {getButtonText(referrals.length)}
          </Button>
        )}
      </div>
    )}
  </FieldArray>
  </>
}

export const NameRecordingInput = ({name, values, touched, setFieldValue, setFieldTouched}) => {
  const value = values[name];
  const userId = useUserId()
  const courseId = useCourseId()
  const db = getFirestore()
  const [optOut, setOptOut] = useState(value === 'optout');
  const [deleted, setDeleted] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
  } = useAudioRecorder();
  const startTimeRef = useRef(0);

  const setErrorState = (error) => {
    setUploadProgress(0)
  }

  const onUploadComplete = (url) => {
    setFieldValue(name, url);
    setUploadProgress(0)
    const endTime = Date.now();
    const startTime = startTimeRef.current;
    const duration = endTime - startTime;
    const blobSize = recordingBlob.size;
    const bitRate = blobSize / (duration / 1000);
    const applicationPath = `users/${userId}/${courseId}/studentApplication`
    updateDoc(doc(db, applicationPath), {bitRate})
  }

  const onClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      setUploadProgress(1)
      startRecording();
    }
  }

  useEffect(() => {
    setFieldTouched(name, true);
  }, [recordingBlob, optOut]);

  useEffect(() => {
    if (recordingBlob) {
      console.log(recordingBlob);
      setDeleted(false);
      setFieldValue(name, null);
      startTimeRef.current = Date.now();
      uploadAudioFile(recordingBlob, onUploadComplete, (e) => console.log(e), setErrorState) 
    }
  }, [recordingBlob]);

  useEffect(() => {
    if(optOut)  {
      setFieldValue(name, 'optout');
    } 
  }, [optOut]);

  useEffect(() => {
    if(deleted) {
      setFieldValue(name, null);
    }
  }, [deleted]);

  let audioBlob = value !== 'optout' ? value : null;
  audioBlob = deleted ? null : audioBlob;


  const isUploading = !isRecording && uploadProgress > 0 && uploadProgress < 100;
  const recordText = (audioBlob && !isUploading) ? 'Re-record' : 'Record';
  const buttonText = (isRecording || isUploading) ? <FaStop/> : <><FaMicrophone/> {recordText} Name</>;
  const canOptOut = !isRecording && !audioBlob && !isUploading;
  const optOutText = optOut ? 'Opt back in' : 'Opt out';
  const showTrash = audioBlob && !isUploading && !isRecording;

  return <div className="question-spacing">
  <label className="form-label">
    Mic check: How do you pronounce your name?
  </label>
  <div className="description">
    A microphone is not necessary for the course. However, Code in Place has many experiences where it is helpful to be able to talk to others. 
  </div>
  <div className="d-flex align-items-center" style={{height:60}}>
  {!optOut && <button disabled={isUploading} className="btn btn-primary mr-2" type="button" onClick={onClick}>{buttonText}</button>}
  {canOptOut && <button className="btn btn-secondary mr-2" type="button" onClick={()=>setOptOut(!optOut)}>{optOutText}</button>}
  {isRecording && <span>Recording...</span>}
  {!optOut && !isUploading && !isRecording && <span className="mr-2"><AudioBlob  audioBlob={audioBlob}/></span>}
  {isUploading && <span>Uploading...</span>}
  {showTrash && <button className="btn btn-secondary btn-sm" type="button" onClick={()=>setDeleted(true)}><FaTrash/></button>}

  </div>
</div>
  
  
}

const AudioBlob = ({audioBlob}) => {
  if (!audioBlob) {
    return null;
  }
  // it might actually be a url
  if (typeof audioBlob === 'string') {
    return <audio src={audioBlob} controls/>
  }
  const url = URL.createObjectURL(audioBlob);
  return  <audio src={url} controls/>
}
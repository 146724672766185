import 'firebase/compat/firestore';
import { Route, Routes } from 'react-router-dom';
import { EventsEditorSplash } from './EventsEditorSplash';
import { MeetingsProvider } from 'course/contexts/MeetingsContext';
import { EventSplash } from './EventSplash';
import { useCourseId } from 'hooks/router/useUrlParams';


export const EventsEditorContainer = (props) => {
  const courseId = useCourseId();
  return (
    <MeetingsProvider courseId={courseId}>
      <EventsEditorRoutes />
    </MeetingsProvider>
  )


}

export const EventsEditorRoutes = () => {
  return (
    <Routes>
          <Route index element={
            <div style={{ width: "100%", height: "100vh", overflowY: "visible" }}>
                  <EventsEditorSplash />
            </div>
          } />
          <Route path="/:eventKey/" element={
              <EventSplash/>
          } />
    </Routes>
    
  )
}
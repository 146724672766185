import { FaBolt,  FaCheck, FaLightbulb, FaRocket } from "react-icons/fa"
import styled from "styled-components"
import { ProjectGuidePhase, ProjectGuideState } from "./types"
import { useContext } from "react"
import { FinalProjectContext } from "./FinalProjectContext"

export const iconLockedColor = '#56b4f8'
export const iconCurrColor = '#56b4f8'
export const iconCompleteColor = "#7AC142"
export const todoCircleDiameter = 50
export const todoConnectorWidth = 5

export const FinalProjectNav = (props: {
  projectGuideState: Partial<ProjectGuideState> & { phase: ProjectGuidePhase };
}) => {
  const { projectGuideState } = props;
  const phase = projectGuideState.phase;
  const {ideateRef, reflectRef, milestoneRef} = useContext(FinalProjectContext)

  return <NavContainer>
    <TodoItem
      todo={{
        title: 'Ideate',
        icon: FaLightbulb,
        stepIndex: 1,
      }}
      isLocked={false}
      scrollTo={ideateRef}
      isComplete={phase >= ProjectGuidePhase.PROJECT_CONVERSATION}
    />
    <TodoItem
      todo={{
        title: 'Reflect',
        icon: FaBolt,
        stepIndex: 2
      }}
      isLocked={false}
      scrollTo={reflectRef}
      isComplete={phase >= ProjectGuidePhase.SHOW_PROJECT_GUIDE}
    />
    <TodoItem
      todo={{
        title: 'Milestone',
        icon: FaRocket,
        stepIndex: 3,
      }}
      isLocked={false}
      scrollTo={milestoneRef}
      isComplete={phase >= ProjectGuidePhase.SHOW_PROJECT_GUIDE}
    />

  </NavContainer>
}


const TodoItem = ({ todo, isComplete, isLocked, scrollTo }) => {
  const onClick = () => {
    // scrollTo is a ref
    // first check that it is not null
    if (!scrollTo.current) {
      return
    }
    scrollTo.current.scrollIntoView({ behavior: 'smooth' })
  }

  return <TodoContainerButton onClick= {onClick} disabled={isLocked} className="btn btn-primary btn-sm " >
    <TodoCircle iconData={todo} isComplete={isComplete} isLocked={isLocked} />
    <TodoLabel iconData={todo} />
  </TodoContainerButton>
}


const TodoCircle = ({ iconData, isComplete, isLocked }) => {

  const style = {
    ...todoCircleStyle(isComplete, isLocked)
  }

 

  return <div style={style}>
    <TodoIcon icon={iconData.icon} isComplete={isComplete} />
  </div>
}


const TodoLabel = ({ iconData }) => {
  return (
    <>
      <div style={todoLabelStyle}>
        {iconData.stepIndex}. {iconData.title}
      </div>
    </>
  )
}

export const TodoIcon = ({ icon: Icon, isComplete = false }) => {
  const size = 24
  if(isComplete) {
    return <FaCheck size={size} />
  }

  return <Icon size={size} />
}

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
`

const TodoContainerButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  z-index: 3;
  border: none !important;
  background-color: transparent !important;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')}; /* Optional: disable pointer events */

`


const todoLabelStyle = {
  fontSize: '18px',
  color: "rgba(70, 120, 178, 1)",
  fontWeight: 'bold',
}

const todoCircleStyle = (isComplete, isLocked) => {
  const color = getTodoColor(isComplete, isLocked)
  return {
    width: `${todoCircleDiameter}px`,
    height: `${todoCircleDiameter}px`,
    borderRadius: '50%',
    backgroundColor: color,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    zIndex: 2
  }
}

const getTodoColor = (isComplete, isLocked) => {
  if (isComplete) {
    return iconCompleteColor
  }

  if (isLocked) {
    return iconLockedColor
  }

  return iconCurrColor
}

export const todoOuter = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: '50px'
}

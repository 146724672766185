import { formateDateWithSuffix } from "components/timezones/timezoneHelper"
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";


/**
 * Takes in a date, and spits out a date in the user's default timezone, that can also show other timezones
 * @param {*} date  
 */

let timeZones = [
    { city: "San Francisco", timeZone: "America/Los_Angeles" },
    { city: "New York", timeZone: "America/New_York" },
    { city: "London", timeZone: "Europe/London" },
    { city: "Istanbul", timeZone: "Europe/Istanbul" },
    { city: "Singapore", timeZone: "Asia/Singapore" },
  ];

export const KindDate = ({date}) => {
    const formattedDate = formateDateWithSuffix(date)
    const formattedTime = getFormattedTime(date)


    // return <div style={{ position: 'relative', width: 'max-content'}} >
    //     <span >{formattedDate}, {formattedTime}</span>
    //     </div>

    return <OverlayTrigger
    placement="top"
    delay={{ show: 100, hide: 100 }}
    overlay={(props) => (
      <Tooltip id="button-tooltip" {...props}>
        <KindDatePopup date={date} />
      </Tooltip>
    )}
    >
        <span >{formattedDate}, {formattedTime}</span>
    </OverlayTrigger>

}


const KindDatePopup = ({date}) => {


    return <div>
        <table>
        {
        timeZones.map((value) => (
            <tr key={value.city}>
                <td>{value["city"]}</td>
                <td>{formattedTzDate(date, value["timeZone"])}</td>
            </tr>
        ))
    }
    </table>
    </div>
}


const getFormattedTime = (date, ampm=true) => {
    return date.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: ampm})
}


const formattedTzDate = (date, timeZone) => {
    // Given a date and timezone, return a string in Month Day, Hour:Minute AM/PM
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        day: 'numeric',
        month: 'short',
        weekday: 'short',
        timeZone: timeZone
    };
    return date.toLocaleString('en-US', options)

}



import { FaQuestion } from "react-icons/fa";
import "./PostNotFoundSplash.css";

export function PostNotFoundSplash(props: {}) {
  return (
    <div className="postSplash">
      <FaQuestion
        className="fa-10x"
        color="lightcoral"
      />
      <div className="spiel">
        <h1>We couldn't find that post!</h1>
        <h2>
          We'll keep searching for it, but in the meanwhile, choose another post
          on the left.
        </h2>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { StyleFeedback } from "./ShowFeedback";
import { getCodeForStyleFeedback } from "ide/utils/general";
import { loadStyleFeedback, logStudentFeedback, setAcknowledgedStyleFeedback, useHasStudentGivenFeedback } from "./Utils";
import { useParams } from "react-router-dom";
import { getIdentifierJsonFromGPTResponse } from "./IdentifierFeedback";
import { getCommentJsonFromGPTResponse } from "./CommentsFeedback";
import { Loading } from "components/loading/Loading";

export const StyleSplashPage = () => {
  // For the top splash page 
  const [acknowledged, setAcknowledged] = useState(false);

  // For the feedback page
  const [code, setCode] = useState(undefined)
  const [identifierResponseJson, setIdentifierResponseJson] = useState(undefined)
  const [magicNumbersResponseJson, setMagicNumbersResponseJson] = useState(undefined)
  const [isIdentifierLoading, setIsIdentifierLoading] = useState(true)
  const [isMagicNumberLoading, setIsMagicNumberLoading] = useState(true)
  const [isCommentsLoading, setIsCommentsLoading] = useState(true)
  const [commentsResponseJson, setCommentsResponseJson] = useState(undefined)
  const [isDecompLoading, setIsDecompLoading] = useState(true)
  const [decompResponseJson, setDecompResponseJson] = useState(undefined)
  const [studentCode, setStudentCode] = useState(undefined)

  // get info from url to read from firebase
  const { courseId, projectId, feedbackId } = useParams();

  const hasStudentGivenFeedback = useHasStudentGivenFeedback(courseId, projectId, feedbackId)

  useEffect(() => {
      // read from firestore to get student code at /stylefeedback/${courseId}/projects/${projectid}/styleFeedbackRequests/${feedbackId}
      getCodeForStyleFeedback(courseId, projectId, feedbackId).then((data) => {
          setStudentCode(data)
      })

  }, [courseId, projectId, feedbackId])

  const [feedbackData, feedbackLoading, feedbackError] = loadStyleFeedback(courseId, projectId, feedbackId)

  useEffect(() => {
    if (feedbackData) {
      if (feedbackData['identifier']) {
        const identifierGPTResponse = feedbackData['identifier']
        getIdentifierJsonFromGPTResponse(identifierGPTResponse).then((json) => {
          setIdentifierResponseJson(json)
          setIsIdentifierLoading(feedbackData['identifier'] === undefined);
        })
      }
      if (feedbackData['magicNumbers']) {
        setMagicNumbersResponseJson(feedbackData['magicNumbers'])
      }
      if (feedbackData['comments']) {
        const commentsGPTResponse = feedbackData['comments']
        getCommentJsonFromGPTResponse(commentsGPTResponse).then((json) => {
          setCommentsResponseJson(json)
          setIsCommentsLoading(feedbackData['comments'] === undefined);
        })
      }
      if (feedbackData['decomp']) {
        setDecompResponseJson(feedbackData['decomp'])
      }
      if (feedbackData['acknowledged']) {
        setAcknowledged(feedbackData['acknowledged'])
      }
      setIsMagicNumberLoading(feedbackData['magicNumbers'] === undefined);
      setIsDecompLoading(feedbackData['decomp'] === undefined);
    }
  }, [feedbackData])

  const isLoading = isIdentifierLoading || isMagicNumberLoading || isCommentsLoading || isDecompLoading


  // feedbackVal is 1 if user clicked thumbs up and 0 if user clicked thumbs down
  const handleThumbClick = async (feedbackVal) => {
    logStudentFeedback(feedbackVal, courseId, projectId, feedbackId)
  }

  const onAcknowledgeReceivingFeedback = async () => {
    // const newFeedbackId = "saKE4IC3HBq65vCRUiBS"
    await setAcknowledgedStyleFeedback(courseId, projectId, feedbackId)
  }

  // check if any value in the feedback data dictionary is TIME_ELAPSED_NOT_MET
  // If any of them are, display a message that this user needs to wait at least 10 mins btw style feedback requests
  if (feedbackData) {
    const values = Object.values(feedbackData)
    if (values.includes("TIME_ELAPSED_NOT_MET")) {
      return (
        <div className="container text-center mt-5">
          <h1>Sorry, you must wait 10 minutes between requests to get style feedback.</h1>
        </div>
      )
    }
  }

  if (studentCode === null || studentCode === undefined) {
    return <Loading/>;
  }

  if (!acknowledged) {
    return (
      <StyleSplashPageTop isLoading={isLoading} handleClick={onAcknowledgeReceivingFeedback} />
    )
  } else {
    const responseData = {
      identifierResponseJson: identifierResponseJson,
      magicNumbersResponseJson: magicNumbersResponseJson,
      commentsResponseJson: commentsResponseJson,
      decompResponseJson: decompResponseJson
    }
    return (
      <StyleFeedback setCode={setCode} studentCode={studentCode} responseData={responseData} handleThumbClick={handleThumbClick} hasStudentGivenFeedback={hasStudentGivenFeedback} />
    )
  }
};

const StyleSplashPageTop = ({isLoading, handleClick}) => {
  return (
    <div className="container text-center mt-5">
    <h1>Welcome to the Style Feedback Tool!</h1>
    <Card className="mx-auto mt-5" style={{ maxWidth: "500px" }}>
      <Card.Body>
        <Card.Title>About the Tool</Card.Title>
        <Card.Text>
          This tool provides AI-generated feedback to help you improve the style
          of your code. Take this feedback as inspiration, but please note that it was generated by a machine and may not be perfect.
        </Card.Text>
      </Card.Body>
    </Card>
    <div className="mt-5">
      {isLoading ? (
        <div>
          <Spinner animation="border" />{" "}
          <span>Generating feedback... This could take up to 10 minutes.</span>
        </div>
      ) : (
          <div>
              <span>Your style feedback is ready.</span>
          </div>
      )}
      <br/>
      <div>
          <Button disabled={isLoading} onClick={handleClick}>
              I understand, take me to my feedback
          </Button>
      </div>
    </div>
  </div>
);
}

export default StyleSplashPage;

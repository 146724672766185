
export const instructionURL = "https://docs.google.com/document/d/15TD1rQW3soqMbHZnITj7iMzE70fIfgwLMc54OXn799g/edit?usp=sharing"

export function getNextUngraded(applicantList, gradebook, secondReviewRequests, currAppId, graderId){
  // If you have a grading lock, use that first
  const studentWithLock = getNextGradingLock(gradebook, graderId)
  if(studentWithLock){
    return studentWithLock
  }

  // nGradedCounts is a map from studentId to the number of grades they have
  // minGrades is the minimum number of grades for any student (eg 0 until everyone has a grade)
  const [nGradedCounts, minGrades] = calcNGradedCounts(applicantList,gradebook)

  if(minGrades == 2){
    // everyone has two grades, so we're done
    return null
  }

  // first, phase1: grade everyone once
  if(minGrades == 0) {
    const phase1 = getPhaseOne(applicantList, nGradedCounts, gradebook, currAppId, graderId)
    if(phase1) return phase1
  }
  
  // second, phase2: grade everyone with a second review request
  const phase2 = getPhaseTwo(nGradedCounts, secondReviewRequests, gradebook, currAppId, graderId)
  if(phase2) return phase2

  // third, phase3: grade everyone twice
  const phase3 = getPhaseThree(applicantList, nGradedCounts, gradebook, currAppId, graderId)
  if(phase3) return phase3

  return null
}

function getPhaseTwo(nGradedCounts, secondReviewRequests, gradebook, currAppId, graderId){
  // start at a random location in the map
  const keys = Object.keys(secondReviewRequests)
  const startIndex = Math.floor(Math.random() * keys.length)
  for (let i = 0; i < keys.length; i++) {
    // wrap around the list
    const index = (startIndex + i) % keys.length
    const studentId = keys[index]
    const hasRequest = secondReviewRequests[studentId]
    if(hasRequest){
      const nGrades = nGradedCounts[studentId]
      if(nGrades === 1){
        // this student has a second look request and only one grade
        const hasGraded = checkHasGraded(gradebook, graderId, studentId)
        const otherHasLock = checkOtherGraderHasLock(gradebook, graderId, studentId)
        if(!hasGraded && !otherHasLock && studentId !== currAppId){
          return studentId
        }
      }
    }
  }
  return null
}

function getPhaseOne(applicantList, nGradedCounts, gradebook, currAppId, graderId){
  return getWithExactlyNGrades(applicantList, nGradedCounts, gradebook, currAppId, graderId, 0)
}

function getPhaseThree(applicantList, nGradedCounts, gradebook, currAppId, graderId){
  return getWithExactlyNGrades(applicantList, nGradedCounts, gradebook, currAppId, graderId, 1)
}

function getWithExactlyNGrades(applicantList, nGradedCounts, gradebook, currAppId, graderId, targetGrades){
  const startIndex = Math.floor(Math.random() * applicantList.length)
  for (let i = 0; i < applicantList.length; i++) {
    // wrap around the list
    const index = (startIndex + i) % applicantList.length
    const studentId = applicantList[index]
    const nGraded = nGradedCounts[studentId]
    const hasTargetGrades = nGraded === targetGrades
    const isCurr = studentId === currAppId
    if(hasTargetGrades && !isCurr){
      // has this grader already graded this student?
      const hasGraded = checkHasGraded(gradebook, graderId, studentId)
      const otherHasLock = checkOtherGraderHasLock(gradebook, graderId, studentId)
      if(!hasGraded && !otherHasLock){
        return studentId
      }
    }
  }
  return null
}

// export function getNextUngradedOld(applicantList, gradebook, secondReviewRequests, currAppId, graderId) {
//   // applicantList is an array of studentIds
//   // gradebook is a map from studentId to a map from graderId to grade
//   // secondReviewRequests is a map from studentId to a boolean (true if they need a second look)
//   // currStudentId is the studentId of the student currently being graded
//   // returns the studentId of the next student to grade

//   // if you have a grading lock, you grade one of those students first
//   // Phase 1: grade eveyone once
//   // Phase 2: grade everyone who has a second look flag
//   // Phase 3: grade everyone twice
//   // never give back the current student
  
//   // this function is a little expensive, so we only compute it when the button is clicked,
//   // not every time the gradebook changes (often)

//   // If you have a grading lock, use that first
//   const studentWithLock = getNextGradingLock(gradebook, graderId)
//   if(studentWithLock){
//     return studentWithLock
//   }

//   // nGradedCounts is a map from studentId to the number of grades they have
//   // minGrades is the minimum number of grades for any student (eg 0 until everyone has a grade)
//   const [nGradedCounts, minGrades] = calcNGradedCounts(applicantList,gradebook)
  
  
//   // this is set to stop when we have given everyone a second look
//   if(minGrades === 2){
//     // we are done!!
//     return null
//   }

//   // if everyone has one grade, look out for the second look flag
//   if(minGrades === 1){
//     // here we don't wrap arround. The race condition of two people
//     // requesting at the same time is rare, and an extra review for a borderline
//     // case is not a bad idea
//     for (const studentId in secondReviewRequests) {
//       const hasRequest = secondReviewRequests[studentId]
//       if(hasRequest){
//         const nGrades = nGradedCounts[studentId]
//         if(nGrades === 1){
//           // this student has a second look request and only one grade
//           const hasGraded = checkHasGraded(gradebook, graderId, studentId)
//           const otherHasLock = checkOtherGraderHasLock(gradebook, graderId, studentId)
//           if(!hasGraded && !otherHasLock){
//             return studentId
//           }
//         }
//       }
//     }
//   }

//   // starting at a random index reduces the chance of collision at the start
//   const startIndex = Math.floor(Math.random() * applicantList.length)
//   for (let i = 0; i < applicantList.length; i++) {
//     // wrap around the list
//     const index = (startIndex + i) % applicantList.length
//     const studentId = applicantList[index]
//     const nGraded = nGradedCounts[studentId]

//     const hasMinGrades = nGraded === minGrades
//     const isCurr = studentId === currAppId
//     const hasGraded = checkHasGraded(gradebook, graderId, studentId)
    

//     if(!hasGraded && hasMinGrades && !isCurr){
//       // this student has the minimum number of grades and is not the current student

//       // make sure nobody has the lock
//       const otherHasLock = checkOtherGraderHasLock(gradebook, graderId, studentId)
//       if(!otherHasLock){
//         return studentId
//       }
//     }
//   }

  
//   return null
// }

export function checkHasGraded(gradebook, graderId, studentId) {
  const grades = gradebook[studentId]
  if(grades){
    const score = grades[graderId]
    if(score){
      return isGradeScore(score)
    }
  }
  return false
}

export function checkOtherGraderHasLock(gradebook, currGraderId, studentId) {
  const grades = gradebook[studentId]
  if(grades){
    for(const graderId in grades){
      if(graderId !== currGraderId){
        const score = grades[graderId]
        if(isGradeLocked(score)){
          return true
        }
      }
    }
  }
  return false
}

export function getNextGradingLock(gradebook, graderId) {
  // if you have a grading lock, you grade one of those students first
  // returns the studentId of the next student to grade
  for (const studentId in gradebook) {
    const grades = gradebook[studentId]
    if(graderId in grades){
      const grade = grades[graderId]
      if(isGradeLocked(grade)){
        return studentId
      }
    }
  }
  // null means you did not find a grading lock for graderId
  return null
}

export function calcNGradedCounts (applicantList, gradebook)  {
  const nGradedCounts = {}
  // the minimum number of grades for any student
  let minGraded = Number.POSITIVE_INFINITY
  for (const studentId of applicantList) {
    const inGradebook = studentId in gradebook && gradebook[studentId]
    if (!inGradebook) {
      nGradedCounts[studentId] = 0
      minGraded = 0
      continue
    }
    const grades = gradebook[studentId]
    let nGraded = 0
    for (const graderId in grades) {
      const score = grades[graderId]
      if (isGradeScore(score)) {
        nGraded++
      }
    }
    nGradedCounts[studentId] = nGraded
    minGraded = Math.min(minGraded, nGraded)

  }
  return [nGradedCounts, minGraded]
}

export function getCurrStudentIndex(studentList, currStudentId) {
  // this is expensive and all it buys us in the number on the bottom of the bar
  for (let index = 0; index < studentList.length; index++) {
    const studentId = studentList[index];
    if (studentId == currStudentId) {
      return index + 1
    }
  }
  console.error('could not find student ', currStudentId)
  return null
}

export function checkGradingLock(gradebook, graderId, studentId) {
  console.log('here')
  let hasSelect = false
  if (studentId in gradebook) {
    const grades = gradebook[studentId]
    if(graderId in grades){
      const grade = grades[graderId]
      if(isGradeLocked(grade)){
        console.log(grade)
        return true
      }
    }
  }
  console.log(hasSelect)
  return hasSelect
}

export function isGradeScore(score) {
  // not every score is a grade
  return score !== 'Released' && score !== 'Select'
}

export function isGradeLocked(score){
  // 'Select' is a special value that means the student is locked
  return score === 'Select'
}

const scoreMapOverall = {
  'Strong Accept':3, 
  'Some Handholding':2, 
  'Heavy Handholding':1, 
  'Do Not Hire':0
}

export const gradeToNumber = (gradeStr) => {
  return scoreMapOverall[gradeStr]
}

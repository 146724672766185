import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  doc,
  getFirestore,
} from "firebase/firestore";
import { ProjectSubmission } from "./ProjectSubmission";
import { getApp } from "firebase/app";

const PROJECT_CONVERTER: FirestoreDataConverter<ProjectSubmission> = {
  toFirestore: (project: ProjectSubmission) => project,
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) =>
    snapshot.data(options) as ProjectSubmission,
};

export const get_project_collection_ref = (courseId: string) =>
  collection(
    getFirestore(getApp()),
    "projectSubmissions",
    courseId,
    "submissions"
  ).withConverter(PROJECT_CONVERTER);

export const get_user_project_submission_ref = (
  courseId: string,
  userId: string
) => {
  return doc(get_project_collection_ref(courseId), userId);
};

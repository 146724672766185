import React, { useCallback, useContext, useEffect, useState } from "react";

import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { getUserDocumentRef } from "../../firebase/models";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useCourseId } from "../../hooks/router/useUrlParams";
import {
  doc,
  getDoc,
  getDocFromServer,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { isUndefined, omitBy } from "lodash";
import { ProfileContext } from "contexts/ProfileContext";
import { Button } from "react-bootstrap";
import {
  FaClock, FaInfoCircle, FaScroll, FaUser
} from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom";
import { SLOnboardingContext } from "./contexts/SLOnboardingContext";
import { useQuery } from "react-query";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import Loading from "react-loading";
import { on } from "events";
import { useUserId } from "hooks/user/useUserId";
import { confirmRole } from "onboarding/components/ConfirmRole";
import { TodoStep } from "onboarding/components/TodoStep";

export const SLOnboardingSplash = (props) => {
  const courseId = useCourseId();
  const navigate = useNavigate();
  const userId = useUserId()
  const { userData, setUserData } = useContext(ProfileContext);
  const { onboardingData, editOnboarding } = useContext(SLOnboardingContext);
  const { sectionData } = useContext(ProfileContext);

  const auth = getAuth(getApp());
  const [user, loading, error] = useAuthState(auth);

  const userQuery = useQuery(["userData", user.uid], () =>
    getDocFromServer(getUserDocumentRef(user.uid))
  );

  if (userQuery.isLoading) {
    return <Loading />;
  }

  const firstSectionId = Object.keys(sectionData)[0];
  const firstSectionData = sectionData[firstSectionId];
  const sectionTimeIndex = firstSectionData?.timeIndex;

  const agreementDone = onboardingData?.agreementData?.signed;
  const sectionTimeDone =
    sectionTimeIndex !== undefined && sectionTimeIndex != "none";
  const confirmedSectionLeader = userData?.confirmedSectionLeader?.[courseId];
  const isMinor = userData?.isMinor || false;

  const smallGroupTimes = userQuery.data.data()?.smallGroups ?? {};
  const smallGroupTimeDone = onboardingData?.trainingTime || onboardingData?.trainingTimeMinor;

  const profileVisited = onboardingData?.visitedProfile

  const confirmSectionLeader = async () => {
    await confirmRole(courseId, userId, "confirmedSectionLeader")
    navigate(`/${courseId}`);
  };

  const slOnboardingOpen = true;
  return (
    <div>
      <div className="container container-narrow mt-2">
        <div className="row">
          <div className="col mt-4 ">
            {!slOnboardingOpen ? (
              <OnboardingClosed />
            ) : (
              <div className="container container-narrow">
                <ApplicationStatus
                  codeOfConductDone={agreementDone}
                  sectionTimeDone={sectionTimeDone}
                  smallGroupTimeDone={smallGroupTimeDone}
                  confirmedSectionLeader={confirmedSectionLeader}
                />
                {confirmedSectionLeader && (
                  <p className="subtleHeading">
                    Welcome back. If you need to change any of your preferences,
                    you can do so here until Friday, April 19th at 11:59pm
                    Anywhere on Earth.
                  </p>
                )}

                <TodoStep
                  text="Agree to volunteer"
                  successCond={agreementDone}
                  icon={FaScroll}
                  href={`/${courseId}/onboarding/teach/volunteeragreement`}
                />

                <TodoStep
                  text="Choose your section time"
                  successCond={sectionTimeDone}
                  icon={FaClock}
                  href={`/${courseId}/onboarding/teach/schedule`}
                />

                <TodoStep
                  text="Choose your live section practice time"
                  successCond={smallGroupTimeDone}
                  icon={FaClock}
                  href={`/${courseId}/onboarding/teach/smallgroup`}
                />

                <TodoStep
                  text="Fill in your user profile"
                  successCond={profileVisited}
                  icon={FaUser}
                  href={`/${courseId}/onboarding/teach/profile`}
                  onClick={() => editOnboarding({ visitedProfile: true })}
                />
                
                <TodoStep
                  text="Set section preferences (optional)"
                  detail="Accommodations for yourselves and students."
                  icon={FaInfoCircle}
                  href={`/${courseId}/onboarding/teach/sectionpreferences`}
                />

                <div className="d-flex align-items-center flex-column mt-4 mb-5">
                  {!confirmedSectionLeader && (
                    <p className="text-secondary">
                      When you click this button, you become a volunteer section
                      leader.
                    </p>
                  )}
                  <Button
                    onClick={() => confirmSectionLeader()}
                    size="lg"
                    disabled={
                      !agreementDone || !sectionTimeDone || !smallGroupTimeDone
                    }
                  >
                    {confirmedSectionLeader
                      ? "Enter Course"
                      : "Generate Section"}
                    {/* {getFinishBtnText(confirmedSectionLeader)} */}
                  </Button>
                  <hr />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardingClosed = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 mb-4">
      <h1 className="mb-3" style={{ margin: "auto", textAlign: "center" }}>
        We are no longer accepting new Section Leaders.
      </h1>
      <h3 style={{ margin: "auto" }}>We're sorry we missed you!</h3>
    </div>
  );
};




const ApplicationStatus = ({
  codeOfConductDone,
  sectionTimeDone,
  smallGroupTimeDone,
  confirmedSectionLeader,
}) => {
  if (confirmedSectionLeader) {
    return <div />;
  }

  if (!sectionTimeDone) {
    return <ApplicationMissingSectionTime />;
  }

  if (!smallGroupTimeDone) {
    return <ApplicationMissingSmallGroupTime />;
  }

  if (!codeOfConductDone) {
    return <ApplicationMissingCodeOfConduct />;
  }

  return (
    <div className="alert alert-success" role="alert">
      <b>Onboarding complete!</b> Click 'Generate Section' below to make it
      official.
    </div>
  );
};

const ApplicationMissingSectionTime = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> Not submitted yet. Please choose a section time.
    </div>
  );
};

const ApplicationMissingSmallGroupTime = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> Not submitted yet. Please choose a live section practice
      time.
    </div>
  );
};

const ApplicationMissingCodeOfConduct = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> Almost there! Please agree to volunteer.
    </div>
  );
};

const ApplicationMissingDemographics = () => {
  return (
    <div className="alert alert-info" role="alert">
      <b>Status:</b> So close!. Please fill out the demographics form.
    </div>
  );
};

const getFinishBtnText = function (confirmedSectionLeader) {
  if (confirmedSectionLeader) {
    return "Enter Course";
  } else {
    return "Generate My Section";
  }
};

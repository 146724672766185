import { getAuth } from "firebase/auth";
import { useCourseId } from "hooks/router/useUrlParams";
import { LeftColTitle } from "ide/LeftCol";
import { getAllFileNamesWithoutImages, getProjectFilesCode, setCodeForStyleFeedback } from "ide/utils/general";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useStyleFeedbackHistory, onStyleFeedbackClick, useHasStyleFeedbackTimeElapsed } from "./Utils";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { IDEContext } from "ide/contexts/IDEContext";
import { allTestsPassed } from "ide/UnitTest/UnitTestResults";


const StyleGuideShort = () => {
    return (
        <>
        <div><h5>Style Best Practices</h5>
            <ul>
                <li>Choose meaningful names for functions and variables</li>
                <li>Replace magic numbers with constants</li>
                <li>Write clear comments to explain your code</li>
                <li>Decompose complex tasks into smaller functions</li>
            </ul>
        </div>
        <Link to="/cip3/textbook/style" target="_blank">See comprehensive style guide here</Link>
        </>
    )
}


const StyleFeedbackHistory = ({ courseId, projectId, isInStyleRealTime=true }) => {
    const styleFeedbackHistory = useStyleFeedbackHistory(courseId, projectId);

    // Filter this list to remove any style feedbacks that have TIME_ELAPSED_NOT_MET as a value for any key
    const filteredStyleFeedbackHistory = styleFeedbackHistory.filter((item) => {
        return Object.values(item).every((value) => value !== "TIME_ELAPSED_NOT_MET");
    });

    // Only want to show the 3 most recent style feedbacks to real time
    // and only 1 most recent to not real time
    const sliceIndex = isInStyleRealTime ? 3 : 1;
    const slicedStyleFeedbackHistory = filteredStyleFeedbackHistory.slice(0, sliceIndex);
   
    if (styleFeedbackHistory === undefined) {
      return <div>Loading style feedback history...</div>;
    }

    if (styleFeedbackHistory.length === 0) {
      return (
        <div className="alert alert-warning" style={{ width: "100%"}}>
          <div>
                <p>Your history of style feedback responses for this problem will be saved and accessible here.</p>
          </div>
        </div>
      );
    }
  
    return (
      <div className="card" style={{ width: "100%"}}>
        <div className="card-body">
            <div className="card-text">
                <ul>
                    {slicedStyleFeedbackHistory.map((item, index) => (
                    <li key={item.id}>
                        {
                            item.timestamp ?
                            <a href={`/${courseId}/stylefeedback/${projectId}/${item.id}`} target="_blank" rel="noopener noreferrer">{item.timestamp.toDate().toLocaleString()}</a> 
                            :
                            <></>
                        }
                        
                    </li>
                    ))}
                </ul>
            </div>
        </div>
      </div>
    );
  }


export const StyleFeedbackTab = (props) => {
    const { projectData, fileStructure } = props;
    const courseId = useCourseId();
    const auth = getAuth();
    const user = auth.currentUser;
    const hasTimeElapsed = useHasStyleFeedbackTimeElapsed(courseId, projectData.uid);

    const { unittestResults } = useContext(IDEContext);

    const getStudentCode = async (projectData, chosenFileId) => {
        return getProjectFilesCode(projectData.uid).then((filesCode) => {
            const studentCode = filesCode[chosenFileId].content ?? "";;
            return studentCode;
        });
    };

    const onClick = async () => {
        const files = getAllFileNamesWithoutImages(fileStructure);

        // Figure out which file they want to use for style feedback
        if (files.length == 0) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You need to have at least one file to get Style Feedback!",
        });
        return;
        }

        const hasMoreThanOneFile = files.length > 1;
        if (hasMoreThanOneFile) {
            const listFiles = {};
            for (let i = 0; i < files.length; i++) {
              listFiles[files[i].id] = files[i].name;
            }

            const { value: mainFile, isConfirmed: isConfirmed } =
              await Swal.fire({
                title: "Select Your Main File!",
                text: "What is the main file you want to get style feedback on?",
                input: "select",
                confirmButtonText: "Get style feedback",
                inputOptions: listFiles,
                inputPlaceholder: "Select a file",
                showCancelButton: true,
                inputValidator: (result) =>
                  !result && "You need to select a file!",
            });
            if (isConfirmed) {
                // use mainFile to get the student code
                return getStudentCode(projectData, mainFile).then((studentCode) => {
                    // Write student code to somewhere in firebase and we will read from it in stylefeedback page
                    return setCodeForStyleFeedback(courseId, user.uid, projectData.uid, studentCode).then((newDocId) => {
                        onStyleFeedbackClick(studentCode, courseId, projectData.uid, newDocId)
                        window.open(`/${courseId}/stylefeedback/${projectData.uid}/${newDocId}`, "_blank")
                    });
                });
            }
        } else { // There is only one file, so use that one as main file
            const mainFile = files[0].id;
            return getStudentCode(projectData, mainFile).then((studentCode) => {
                // Write student code to somewhere in firebase and we will read from it in stylefeedback page
                return setCodeForStyleFeedback(courseId, user.uid, projectData.uid, studentCode).then((newDocId) => {
                    onStyleFeedbackClick(studentCode, courseId, projectData.uid, newDocId)
                    window.open(`/${courseId}/stylefeedback/${projectData.uid}/${newDocId}`, "_blank")
                });
            });
        };

    };

    const styleFeedbackDisabled = !hasTimeElapsed || !allTestsPassed(unittestResults);

    return (
        <StyleFeedbackRealTimeTab 
            styleFeedbackDisabled={styleFeedbackDisabled} 
            onClick={onClick} 
            courseId={courseId} 
            projectData={projectData} 
        />
    )
}

// const StyleFeedbackNotRealTimeTab = ({courseId, projectData}) => {
//     return (
//         <>
//             <LeftColTitle title="Style Feedback"/>
//             <div
//             style={{
//                 flexGrow: 2,
//                 minWidth: "200px",
//                 overflow: "scroll",
//                 width:'100%',
//             }}
//             >
//             <div className="container">
//             <div className="col">
//                 <div className="row m-2">
//                 <div style={{ width: "100%"}}>
//                     <div className="alert alert-primary row">
//                         <div className="col-12">Style feedback will be generated for you every Monday. Students receive this feedback on different days, and in your case, it's scheduled for Mondays. Every Monday, the algorithm evaluates the projects you completed in the previous week and provides you with valuable style feedback. To check your feedback, simply visit this tab within each project on Mondays. </div>
//                     </div>
//                 </div>
//                 </div>
//                 <div className="row m-2">
//                         <StyleGuideShort />  
//                 <div style={{width: "100%", paddingTop: "2vh" }}>
//                     <h5>Style Feedback History</h5>
//                     <StyleFeedbackHistory courseId={courseId} projectId={projectData.uid} isInStyleRealTime={false} />
//                 </div>
//                 </div>
//             </div>
//             </div>
//             </div>
//         </>
//     );
// }

const StyleFeedbackRealTimeTab = ({styleFeedbackDisabled, onClick, courseId, projectData}) => {
    return (
        <>
            <LeftColTitle title="Style Feedback"/>
            <div
            style={{
                flexGrow: 2,
                minWidth: "200px",
                overflow: "scroll",
                width:'100%',
            }}
            >
            <div className="container">
            <div className="col">
                <div className="row m-2">
                <div style={{ width: "100%"}}>
                    <div className="alert alert-primary row">
                        <div className="col-12">Once you solve the problem we will give you style feedback. Note that you can only request style feedback once every 10 minutes.</div>
                        <div className="col-12 d-flex justify-content-center mt-2">
                            <Button disabled={styleFeedbackDisabled} onClick={onClick}>Get Style Feedback</Button>
                        </div>
                    </div>
                </div>
                </div>
                <div className="row m-2">
                        <StyleGuideShort />  
                <div style={{width: "100%", paddingTop: "2vh" }}>
                    <h5>Style Feedback History</h5>
                    <StyleFeedbackHistory courseId={courseId} projectId={projectData.uid} />
                </div>
                </div>
            </div>
            </div>
            </div>
        </>
    );

}

// Three groups: in nudge treatment and gets realtime feedback, not in nudge treatment but gets real time feedback, not in nudge treatment and doesn't get realtime feedback (control group)

// if styleFeedback == 0: show them tab that says "See your style feedback on Mondays!"
// if styleFeedback > 0: show them tab that says "See your style feedback now!"
// if styleFeedback == 2: that is how we nudge them to get style feedback
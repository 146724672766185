import { useState, useRef } from 'react';
import { Button, Modal} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { HandleSubmitFormixFormWithData } from "components/Forms/FormixFormWithData";
import * as Yup from "yup";
import { TextAreaInput, TextInput } from 'components/Forms/FormixComponents';
import { FaBullseye, FaLightbulb, FaQuestion } from 'react-icons/fa';

export const EditInfoModal = ({
    userId, 
    viewerId, 
    userData,
    status,
    connectionData,
    setConnectionData,
    buttonDisabled=false
}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const functions = getFunctions();
    const updateRequestInfo = httpsCallable(functions, "updateRequestInfo");
    
    const formRef = useRef();
    const handleSubmitForm = () => {
        if (formRef.current){
            // @ts-ignore
            const errors = formRef.current.errors
            if (errors && Object.keys(errors).length !== 0){
                // if there are errors, show them (e.g. fields unpopulated)
                setShowErrors(true)
            }
            // @ts-ignore
            formRef.current.submitForm()
        }
    }

    const handleUpdateInfo = async (e) => {
        setLoading(true)

        const res = await updateRequestInfo({
            viewerId, 
            userId,
            viewerId_msg: e.message,
            viewerId_contact: e.contact,
        })
        // @ts-ignore
        if (res.data.success) {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Info updated.',
            })
            setLoading(false)
            // @ts-ignore
            setConnectionData(() => {return {...connectionData, ...res.data.newConnectionData}})
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            setLoading(false)
        }
        handleClose()
    }

    const MessageInput = ({name}) => {
        const description = status === 'connected' ?
            <></> :
            <>
                Here are some ideas...
                <ul style={{ listStyleType: "none", padding:0 }}>
                    <li><FaBullseye/>&nbsp;&nbsp;Discuss a shared learning goal</li>
                    <li><FaLightbulb/>&nbsp;&nbsp;Share a common subject of interest</li>
                    <li><FaQuestion/>&nbsp;&nbsp;Ask a question</li>
                </ul>
            </>
        return <TextAreaInput
            label="Your Message"
            name={name}
            description={description}
            placeholder={`Nice to meet you! I'd like to learn more about...`}
            style={{backgroundColor: 'white'}}
        />
    }

    const ContactInfoInput = ({name}) => <TextInput
        label="Your Contact Info"
        description={ status === 'connected' ? 
            <>
                For your own privacy, we recommend sharing a Linkedin link or making an email alias.
            </> :
            <>
                We will only share this with {userData.displayName} if they accept your request.
                <br/>
                For your own privacy, we recommend sharing a Linkedin link or making an email alias.
            </>
        }
        name={name}
        type="text"
        placeholder="linkedin.com/in/your-username"
        style={{backgroundColor: 'white'}}
    />

    const formFormat = [
        {
            name: "message",
            input: MessageInput,
            default:'',
            validation: Yup.string()
            .required("Message is required"),
        },
        {
            name: "contact",
            input: ContactInfoInput,
            default:'',
            validation: Yup.string()
            .required("Contact info is required"),
        },
    ]

    const formData = {
        message: connectionData[`${viewerId}_msg`],
        contact: connectionData[`${viewerId}_contact`],
    }

    return <>
        <Button variant="outline-secondary" onClick={handleShow} disabled={buttonDisabled}>
            Edit info
        </Button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            
            { status === 'connected' ?
            <>
                <label className="form-label">Their Message</label>
                <p>
                    {connectionData[`${userId}_msg`]}
                </p>
                <label className="form-label">Their Contact Info</label>
                <p>
                    {connectionData[`${userId}_contact`]}
                </p> 
            </>
            : <></>}
            <HandleSubmitFormixFormWithData
                ref={formRef}
                serverData={formData}
                backUrl={null}
                formFormat={formFormat}
                onSubmit={(e, cb) => handleUpdateInfo(e)}
                showGlobalError={showErrors}
            />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmitForm} disabled={loading}>
                    {loading ? 'Updating...' : 'Update'}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

// useCustomNavigate.js
import { useNavigationHistory } from 'contexts/NavigationContext';
import { useNavigate, useLocation } from 'react-router-dom';

export function useHistoryNavigate() {
  const { pushToHistory } = useNavigationHistory();
  const navigate = useNavigate();
  const location = useLocation();

  // Return a function that first updates the navigation history, then navigates
  function navigateWithState(to, options = {}) {
    // curr location
    const currLocation = location.pathname;

    // Initialize a new state object from the state provided in options if it exists, or create a new object
    const newState = options.state ? { ...options.state } : {};

    // Check if 'from' property does not exist in the newState object and then set it
    if (typeof newState.from === 'undefined') {
      newState.from = location.pathname;
    }

    pushToHistory(currLocation);
    // Perform the navigation with the new state and any other options
    navigate(to, { ...options, state: newState });
  }

  return navigateWithState;
}
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  ActionsWrapper,
  Collapse,
  StyledName,
  VerticalLine,
} from "../Tree.style";
import { StyledFolder } from "./TreeFolder.style";
import { v4 } from "uuid";

import { FILE, FOLDER } from "../state/constants";
import { useTreeContext } from "../state/TreeContext";
import { PlaceholderInput } from "../TreePlaceholderInput";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useFilePicker } from "use-file-picker";
import {
  FaEdit,
  FaFileMedical,
  FaFolder,
  FaFolderOpen,
  FaFolderPlus,
  FaTrash,
  FaUpload,
} from "react-icons/fa";

const FolderName = ({ isOpen, name, handleClick }) => (
  <StyledName onClick={handleClick}>
    {isOpen ? <FaFolderOpen /> : <FaFolder />}
    &nbsp;&nbsp;{name}
  </StyledName>
);

const Folder = ({
  id,
  name,
  children,
  node,
  rootFolder = false,
  projectId,
  fullControl,
}) => {
  const { dispatch, onNodeClick } = useTreeContext();
  const [isEditing, setEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(rootFolder ?? false);
  const [childs, setChilds] = useState([]);
  const [recentlyUploadedFile, setRecentlyUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [openFileSelector, { plainFiles, loading }] = useFilePicker({
    accept: ["image/*",".txt", ".json", ".py"],
    multiple: false,
    readFilesContent: false,
  });

  useEffect(() => {
    if (recentlyUploadedFile) {
      const fileId = v4();
      if(recentlyUploadedFile.type=== "text/plain" || recentlyUploadedFile.type === "text/x-python-script" || recentlyUploadedFile.type==="application/json") {
        dispatch({
          type: FILE.CREATE,
          payload: {
            id,
            fileId,
            format: "doc",
            name: recentlyUploadedFile.name,
            content: recentlyUploadedFile.content
          },
        });

      }
      else {
        dispatch({
          type: FILE.CREATE,
          payload: {
            id,
            fileId,
            format: "image",
            url: recentlyUploadedFile.url,
            name: recentlyUploadedFile.name,
          },
        });
      }
    }
  }, [recentlyUploadedFile]);

  useEffect(() => {
    if (plainFiles.length > 0 && !isUploading) {
      const firstFile = plainFiles[0];
      setIsUploading(true);
      if(firstFile.type=== "text/plain" || firstFile.type === "text/x-python-script" || firstFile.type==="application/json") {
        uploadTxt(firstFile);

      } else {
        uploadImg(firstFile);
      }

    }
  }, [plainFiles]);

  const uploadImg = (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `projects/${projectId}/${file.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    Swal.fire({
      title: `Uploading new file...`,
      toast: true,
      showClass: {
        popup: "none",
      },
      showConfirmButton: false,
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progress = Math.min(99, progress);
      },
      (error) => {
        setIsUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setRecentlyUploadedFile({
            name: file.name,
            url: downloadURL,
            type: file.type
          });
          Swal.fire({
            title: `Success!`,
            toast: true,
            showClass: {
              popup: "none",
            },
            icon: "success",
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        });
        setIsUploading(false);
      }
    );
  };

  const uploadTxt = (file) => {
    Swal.fire({
      title: `Uploading new file...`,
      toast: true,
      showClass: {
        popup: "none",
      },
      showConfirmButton: false,
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      const content = reader.result
      setRecentlyUploadedFile({
        name: file.name,
        content: content,
        type: file.type
      });


      Swal.fire({
        title: `Success!`,
        toast: true,
        showClass: {
          popup: "none",
        },
        icon: "success",
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setIsUploading(false);

    };
    reader.readAsText(file);
    // var reader = new FileReader();



  };

  useEffect(() => {
    setChilds([children]);
  }, [children]);

  const commitFolderCreation = (name) => {
    dispatch({ type: FOLDER.CREATE, payload: { id, name } });
  };
  const commitFileCreation = (name) => {
    dispatch({ type: FILE.CREATE, payload: { id, fileId: v4(), name } });
  };
  const commitDeleteFolder = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: FOLDER.DELETE, payload: { id } });
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };
  const commitFolderEdit = (name) => {
    dispatch({ type: FOLDER.EDIT, payload: { id, name } });
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setChilds([children]);
  };

  const handleNodeClick = React.useCallback(
    (event) => {
      event.stopPropagation();
      onNodeClick({ node });
    },
    [node]
  );

  const handleFileUpload = (event) => {
    event.stopPropagation();

    if (!isUploading) {
      openFileSelector();
    }
  };

  const handleFileCreation = (event) => {
    event.stopPropagation();
    setIsOpen(true);
    setChilds([
      ...childs,
      <PlaceholderInput
        defaultValue=""
        type="file"
        onSubmit={commitFileCreation}
        onCancel={handleCancel}
      />,
    ]);
  };

  const handleFolderCreation = (event) => {
    event.stopPropagation();
    setIsOpen(true);
    setChilds([
      ...childs,
      <PlaceholderInput
        type="folder"
        defaultValue=""
        onSubmit={commitFolderCreation}
        onCancel={handleCancel}
      />,
    ]);
  };

  const handleFolderRename = () => {
    setIsOpen(true);
    setEditing(true);
  };

  return (
    <StyledFolder id={id} onClick={handleNodeClick} className="tree__folder">
      <VerticalLine>
        <ActionsWrapper>
          {isEditing ? (
            <PlaceholderInput
              name={name}
              type="folder"
              style={{ paddingLeft: 0 }}
              defaultValue={name}
              onCancel={handleCancel}
              onSubmit={commitFolderEdit}
            />
          ) : (
            <FolderName
              name={name}
              isOpen={isOpen}
              handleClick={() => (!rootFolder ? setIsOpen(!isOpen) : null)}
            />
          )}

          <div className="actions">
            {!rootFolder ? <FaEdit onClick={handleFolderRename} /> : null}
            <FaUpload onClick={handleFileUpload} />
            {fullControl ? (
              <>
                <FaFileMedical onClick={handleFileCreation} />
                <FaFolderPlus onClick={handleFolderCreation} />
              </>
            ) : null}
            {!rootFolder ? <FaTrash onClick={commitDeleteFolder} /> : null}
          </div>
        </ActionsWrapper>
        <Collapse className="tree__folder--collapsible" isOpen={isOpen}>
          {childs}
        </Collapse>
      </VerticalLine>
    </StyledFolder>
  );
};

export { Folder, FolderName };

import { useState, useContext } from "react";
import { useWindowSize } from "react-use-size";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import firebase from "firebase/compat/app";
import { doc } from "firebase/firestore";
import { Container, Navbar, Nav } from "react-bootstrap";
import { FaCode, FaFile, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { AdminRichTextEditor } from "course/components/AdminRichTextEditor";
import { useCourseId } from "hooks/router/useUrlParams";
import { ProfileContext, ProfileProvider } from "contexts/ProfileContext";
import { QUESTION1_RUBRIC, QUESTION2_RUBRIC, QUESTION3_RUBRIC, QUESTION4_RUBRIC, QUESTION5_RUBRIC } from "./Constants";
import { Link } from "react-router-dom";
import { logStudentGivingDiagnosticFeedback } from "./Utils";
import "./ThumbStyle.css"
import { Loading } from "components/loading/Loading";
import { useUserId } from "hooks/user/useUserId";
import { MonacoTipTap } from "components/richTextEditor/TipTap/extensions/runnableKarel/TipTapCodeEditor";

const UID_TO_RUBRIC_PRETEXT = {
    "diagnostic1": QUESTION1_RUBRIC,
    "diagnostic2": QUESTION2_RUBRIC,
    "diagnostic3": QUESTION3_RUBRIC,
    "diagnostic4": QUESTION4_RUBRIC,
    "diagnostic5": QUESTION5_RUBRIC,
}

const QID_TO_LINKS = {
    "diagnostic1": {
        "student": "ide/a/diagnostic1",
        "solution": "ide/a/diagnostic1soln",
    },
    "diagnostic2": {
        "student": "ide/a/diagnostic2",
        "solution": "ide/a/diagnostic2soln",
    },
    "diagnostic3": {
        "student": "ide/a/diagnostic3",
        "solution": "ide/a/diagnostic3soln",
    },
    "diagnostic4": {
        "student": "ide/a/diagnostic4",
        "solution": "ide/a/diagnostic4soln",
    },
    "diagnostic5": {
        "student": "ide/a/diagnostic5",
        "solution": "ide/a/diagnostic5soln",
    }
}

const PANEL_OFFSET = 150
const RIGHT_SIDE_BOTTOM_OFFSET = 137


// IF YOU ARE TESTING, UNCOMMENT AND USE THSE UIDS
// const UIDS =  ["0G99qZUq0UWnVVi0I8qDFheF8d53", "1G6rhhOttGMZtrS6xu96QzStRzp2", "2gugYaySpaaT0peeM7yuAyoEQfF3", "2oHx8BdMiGUplGMQLFtiDQFcCFz2", "2oTzvrOFfYZP48D9A8mzgtPyZ1B2", "5mPMPYDvCUXIe4tIobv2xQDzPE93", "81KahM90vaSDXdOPXuB7dCpXaQ02", "95x12S1aSOQnVRCmqsWvJrbUTc12", "AfmXAbVQTINaGFOmB1reUJS1HKj1", "BNPqxNSfhTdyeNNcv6EHJ1RjI8o2", "BOJTI1dHKCVQLQHHv9K1eJ01Zoi2", "Byxy2znh4ERILS45PQiinVC5taa2", "CqMNH3x7nHZojGOu0r5oWZwcIK22", "F6ix0PT9W4hZJRLuHNJ0S94foKX2", "Gg7Svx1P20N25vFbiUj2CuiIqWI2", "JTdGBKRVPOctE9eizy7INCXYH0z2", "JeNNW6duugdnZiDrREkEbfYRP1x1", "K6zdqrg5P7bo1DRh91AKB5Adc5z1", "NUokdIpmWOWyshT86YB8NHwhZnl2", "QKlcd9YXPSVPiK94ArHDa3K8haC3", "QsBLEQqwJPVvhqnET6NsoE8KNvg1", "RZ7WTPkkmyNIG7SiUM3cxwt7wGq2", "RhJE7cX03DULUNTvrk8xoSXExEL2", "SFYwMwPTtZbb6yJFSuaGpXmmkC93", "STmBzoT79dTxcncQXuM04TybWpG3", "T7S59OLeWuXkNC4ZtJQT8CR6YiZ2", "TV6mzqL6PVUiulPYy0xMrT2JtQU2", "UENR0WO48pQmoOTSdfurPelKdQT2", "WYruOIzzcuaazP9MS77GdvuTAII2", "YhAaaIJbk0WuOg4dTD8SuBTdCd53", "a69TM7RlJCZaHCZxeIhomOBwgOl1", "b4uDgTYWbkQN26OiUrRAvhKPT0X2", "bpClPn3TlMOddhQKTj7vWASTlKU2", "eBDMp4BGY0aBQuAfG0eW0BiFlaa2", "egNSer65PUZEv3J50aGI5Flupe83", "gwwFmdGTUAVcUWl8QKYSVpNtUal1", "j6byFReCQsWWDigUiH0IqjFMGQV2", "jKc0fHEPB4RTS0rm8p4RLAJGOA82", "kAqTo4bDFpatiDGsg8cWU9aRNWK2", "l33GL0Zx9ZhsRdPHC1jOFaqJJuJ3", "lQ59xSv5QKPZeiGsmW94rI5T0t02", "mqenQyayKVNFdgIJ6dq3O4hhKP82", "oE2IHhy7V9TKwWy3y4sZEa0bjIe2", "pzpIiRJvFlU1SfzpqWmtFrt9Iot1", "q8OwrU7KGyW0Bq491qSI4dZHYAy2", "qAN5n0NrbkPPVcAwKRkcWT9UwKM2", "t9evZ1PG4PWuPqXBDhCLpdkSJou2", "uU7muW1nCXciUSCUkO3Qy0SgxGK2", "v6e4MSH8MaTSyCcYZHyEJrUHmfv2", "w71MdP9cEUf1gCnzDQh0L0urHGH2"]
// const UID = "WYruOIzzcuaazP9MS77GdvuTAII2" //UIDS[Math.floor(Math.random() * UIDS.length)]
// console.log("UID", UID)

export const DiagnosticFeedback = () => {
    return (
        <ProfileProvider>
            <DiagnosticFeedbackInner/>
        </ProfileProvider>

    )
}

const DiagnosticFeedbackInner = () => {
    const db = firebase.firestore();
    const [qId, setqId] = useState(0)
    const userId = useUserId();
    const courseId = useCourseId();

    let feedbackRef; 
    if (courseId === "cip3") {
        feedbackRef = doc(db, "diagnostic_feedback", userId)
    } else {
        feedbackRef = doc(db, "diagnostic_feedback_v2", "cip4", "users", userId)
    }

    // Need to put in a use effect that changes whenever qId changes
    // const hasStudentGivenFeedback = hasStudentGivenDiagnosticFeedback(userId, qId)
    const [data, dataLoading, dataError] = useDocumentDataOnce(feedbackRef)
    const [studentThumbsData, studentThumbsDataLoading, studentThumbsDataError] = useDocumentData(doc(db, `studentDiagnosticFeedback/${userId}`))

    const { loading: userIsLoading } = useContext(ProfileContext)

    if (userIsLoading || dataLoading || studentThumbsDataLoading) {
        return <Loading/>
    } 

    if (dataError || studentThumbsDataError) {
        return <div>Error: {JSON.stringify(dataError)}</div>
    }



    console.log("DATA", data)

    const questionThumbData = studentThumbsData?.questionFeedback[qId]

    return (
        <div className="container" style={{maxWidth: "1500px"}}>
            <NavBarz setqId={setqId}/>
            {
                qId === 0 ? <IntroPage courseId={courseId}/> : <DiagnosticFeedbackQuestion userId={userId} data={data} qId={qId} courseId={courseId} questionThumbData={questionThumbData}/>
            }
        </div>
    )
}

const IntroPage = ({courseId}) => {
    return (
        <div style={{margin: "auto", width: "50%"}}>
            <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/diagnosticFeedback/questions/intro`} />
        </div>
    );
}



const DiagnosticFeedbackQuestion = ({userId, data, qId, courseId, questionThumbData}) => {
    
    const link_data = QID_TO_LINKS[qId]
    const questionIdeLink = `/${courseId}/${link_data["student"]}`
    // if qId is not in data, then we should return "You did not attempt this question"
    if (!data?.[qId]) {
        return (
            <div className="container text-center mt-5">
                <h1>We weren't able to generate feedback for you on this question.</h1>
                <p>You can <a href={questionIdeLink} target="_blank">check out your solution here</a>.</p>
            </div>
        )
    }

    const feedbackData = data[qId]

    return (
        <div className="row">
            <div className="col-12 col-md-3 pt-3" >
                <div style={{
                    width:'100%',
                    overflow: "scroll"
                }}>
                    <QuestionPrompt courseId={courseId} qId={qId}/>
                </div>
            </div>
            <div className="col-12 col-md-5 pt-3" >
                <div style={{
                    width:'100%',
                    overflow: "scroll"
                }}>
                    <DisplayCode 
                        qId={qId} 
                        studentCode={feedbackData.code} 
                        courseId={courseId}
                    />
                </div>
            </div>
            <div className="col-12 col-md-4 pt-3">
                <div className="" style={{  height:`100%`}}>
                    <RightHandSide userId={userId} data={feedbackData} qId={qId} questionThumbData={questionThumbData}/>
                </div>
            </div>
        </div>
    )
}

const NavBarButton = ({qid, activeQid, handleClick}) => {
    const isActive = qid === activeQid
    const title = qid === 0 ? 'Intro' : `Question ${qid}`
    return (
        <button className={`btn btn-outline-primary mx-1 ${isActive ? "active" : ""}`} data-qid={qid} onClick={handleClick}>
            {title}
        </button>
    )
}

const NavBarz = ({setqId}) => {
    const [activeQid, setActiveQid] = useState(0)
    const handleClick = (event) => {
        const qnum = parseInt(event.target.getAttribute("data-qid"));
        setActiveQid(qnum)
        // For the intro page, we are going to have a qid of 0 rather than diagnostic0
        if (qnum == 0) {
            setqId(0)
            return
        }
        setqId('diagnostic' + qnum)
    }

    return (
        // <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
            {/* <div className="container collapse navbar-collapse"> */}
                    <Nav>
                {/* <div className="navbar-nav"> */}
                        <NavBarButton qid={0} activeQid={activeQid} handleClick={handleClick}/>
                        <NavBarButton qid={1} activeQid={activeQid} handleClick={handleClick}/>
                        <NavBarButton qid={2} activeQid={activeQid} handleClick={handleClick}/>
                        <NavBarButton qid={3} activeQid={activeQid} handleClick={handleClick}/>
                        <NavBarButton qid={4} activeQid={activeQid} handleClick={handleClick}/>
                        <NavBarButton qid={5} activeQid={activeQid} handleClick={handleClick}/>
                    </Nav>
                {/* </div> */}
                </Navbar.Collapse>
            {/* </div> */}
            </Container>
        </Navbar>
    )
}

const QuestionPrompt = (props) => {
    const { courseId, qId } = props
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;
    const height = isMobile ? `100%` : `calc(100vh - ${RIGHT_SIDE_BOTTOM_OFFSET}px)`
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className="d-flex justify-content-between align-items-center">
                <h4>Question Overview</h4>
            </div>
            <div style={{height: height, paddingRight:'15px', overflow:'auto'}}>
                <AdminRichTextEditor firebaseDocPath={`course/${courseId}/handouts/diagnosticFeedback/questions/${qId}`} />
            </div>
        </div>
    )
}

const RubricOption = ({rubricId, rubricItemTitle, rubricPreText, explanation, userId, qId, questionThumbData}) => {
    const hasSubmittedThumbForRubricItem = questionThumbData !== undefined && questionThumbData[rubricId] !== undefined
    const handleThumbClick = (thumbVal) => {
        logStudentGivingDiagnosticFeedback(thumbVal, userId, qId, rubricId)
    }
    return (
        <div className="card mx-auto mb-3">
            <div className="card-body d-flex flex-column">
                <h5 className="card-title">{rubricItemTitle}</h5>
                <p>{rubricPreText}</p>
                <p><b>Your Feedback:</b> {explanation}</p>
                
                <div className="mt-auto d-flex justify-content-end align-items-center">
                { hasSubmittedThumbForRubricItem ? 
                    <p className="m-2"  style={{ color: '#6A6C6E' }}><i>Feedback submitted</i></p> 
                :
                        <>
                        <p className="m-2"  style={{ color: '#6A6C6E' }}><i>Did you find this helpful?</i></p>
                        <span className="diagnosticFeedbackThumb" onClick={() => handleThumbClick(1)}><FaThumbsUp size={16} style={{ opacity: "0.6", color: 'green', marginLeft: '5px', marginBottom: "5px" }} /></span>
                        <span className="diagnosticFeedbackThumb" onClick={() => handleThumbClick(0)}><FaThumbsDown size={16} style={{ opacity: "0.7", color: 'salmon', marginLeft: '5px' }} /></span>
                        </>
                    }
                </div>
                
            </div>
        </div>
    )

}

const DisplayFeedback = ({data, qId, userId, questionThumbData}) => {
    const gptFeedback = data.gptFeedback
    const question_rubric_pretext = UID_TO_RUBRIC_PRETEXT[qId]
    const allCorrect = Object.entries(gptFeedback).every(([_, value]) => value['option'] === 0)
    if (allCorrect) {
        return (
            <div style={{height: "80%", overflow: "scroll", marginTop: "5px"}}>
            <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Great job!</h4>
                <p>
                    You got all the rubric items correct!
                </p>
            </div>
            </div>
        )
    }
    return (
        <div style={{height: "80%", overflow: "scroll", marginTop: "5px"}}>
        {
            Object.keys(gptFeedback).map((rubricItem) => {
                const title = question_rubric_pretext[rubricItem]["title"]
                const rubricPreText = question_rubric_pretext[rubricItem]["rubricPreText"]
                const expl = gptFeedback[rubricItem]['explanation']
                const option = gptFeedback[rubricItem]['option']
                if(option > 0) {
                    return <RubricOption 
                                key={rubricItem} 
                                rubricId={rubricItem} 
                                rubricItemTitle={title} 
                                rubricPreText={rubricPreText} 
                                explanation={expl}
                                userId={userId}
                                qId={qId}
                                questionThumbData={questionThumbData}
                            />
                } else {
                    return null
                }
            })
        }
        </div>
    )
}

const RightHandSide = (props) => {
    const { userId, data, qId, questionThumbData } = props
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;
    const height = isMobile ? `100%` : `calc(100vh - ${RIGHT_SIDE_BOTTOM_OFFSET}px)`

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className="d-flex justify-content-between align-items-center">
                <h4>Feedback</h4>
            </div>
            <div style={{height: height, paddingRight:'15px'}}>
                <InstructionsNoFeedback/>
                <DisplayFeedback userId={userId} data={data} qId={qId} questionThumbData={questionThumbData}/> 
            </div>
        </div>
    )
}

const InstructionsNoFeedback = () => {
    return (
        <div>
            <div className="alert alert-primary d-flex">
                <div><b>Auto Generated Feedback</b> Take this feedback as inspiration, but note that it was generated by a machine.</div>
            </div>
        </div>
    )
}


const CodeEditorEditable = (props) => {
    const { studentCode } = props

    return (
        <div className="card" style={{paddingTop: "2vh"}}>
                <MonacoTipTap
                    mode="python"
                    value={studentCode + "\n"}
                    onChange={(e) => {}}
                    readOnly={true}
                />
        </div>
    )
}


const DisplayCode = (props) => {
    const { qId, courseId }  = props
    const link_data = QID_TO_LINKS[qId]
    const questionIdeLink = `/${courseId}/${link_data["student"]}`
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className="d-flex justify-content-between align-items-center">
                <div style={{fontSize: "24px"}}>Your Solution</div>
                    <Link to={questionIdeLink} target="_blank"><FaCode/> Continue working on your solution</Link>
            </div>
            <CodeEditorEditable {...props}/>
        </div>
    )
}

import { QRCode } from "antd"
import { getApp } from "firebase/app"
import { doc, getFirestore, updateDoc } from "firebase/firestore"
import { useCourseId } from "hooks/router/useUrlParams"
import { useUserId } from "hooks/user/useUserId"
import { useContext, useEffect, useRef, useState } from "react"
import { useDocumentDataOnce, useDocument } from "react-firebase-hooks/firestore"
import { FaCertificate, FaCheckCircle, FaEdit, FaLink, FaPrint, FaTimesCircle } from "react-icons/fa"
import { useParams } from "react-router"
import { get_user_project_submission_ref } from "course/finalproject/server/ProjectDataFetcher"
import { Loading } from "components/loading/Loading"
import { useCertificateCompletion } from "./useCertificateCompletion"
import { NoCertificatePage } from "./NoCertificatePage"
import { CourseContext } from "contexts/CourseContext"
import { getNumTeachNowSessionsAndGratitude } from "../../firebase/realtime/TeachNow"


export const CertificateViewV2 = ({certificateDoc}) => {
  const db = getFirestore()
  const certificateName = certificateDoc.name
  const userId = certificateDoc.studentId
  const courseId = useCourseId()


  const [certUserRoleDoc, certUserRoleLoading, certUserRoleError] = useDocumentDataOnce(doc(db, `users/${userId}/roles/${courseId}`))
  if (certUserRoleLoading) return <Loading />


  if(!("role" in certUserRoleDoc)) {
    return <CertificateError msg="Can't load user role"/>
  }


  return <CertificateWithCourseRole certificateName={certificateName} userId={userId} certUserRole={certUserRoleDoc["role"]}/>

}



const CertificateWithCourseRole = ({certificateName, userId, certUserRole}) => {
  const { certificateId } = useParams()
  const db = getFirestore(getApp())
  const currId = useUserId()
  const courseId = useCourseId()
  const assnProgressDocPath = `users/${userId}/${courseId}/assnProgress`
  
  // State for teachnow session informations per student 
  const [sessionInfo, setSessionInfo] = useState([]);
  const [isLoadingSessionInfo, setIsLoadingSessionInfo] = useState(true);

  // Fetch session information for the user and course
  useEffect(() => {
    const fetchSessionInfo = async () => {
      try {
        // Replace with your actual function to fetch session info
        const data = await getNumTeachNowSessionsAndGratitude(userId, courseId);
        setSessionInfo(data.sessionInfo || []);
        setIsLoadingSessionInfo(false);
      } catch (error) {
        console.error('Error fetching session information:', error);
        // Handle error state if needed
      }
    };

    fetchSessionInfo();
  }, [userId, courseId]);
  
  const [certificateFormatDoc, certificateFormatLoading, certificateFormatError] = useDocumentDataOnce(doc(db, `course/${courseId}/certificateFormat/${certUserRole}`))
  const [userDoc, userLoading, userError] = useDocumentDataOnce(doc(db, `users/${userId}`))
  const [assnProgressDoc, assnProgressLoading, assnProgressError] = useDocumentDataOnce(doc(db, assnProgressDocPath))
  const [sectionParticipationDoc, sectionParticipationLoading, sectionParticipationError] = useDocumentDataOnce(doc(db, `users/${userId}/${courseId}/sectionAttendance`))
  const [diagnosticDoc, diagnosticLoading, diagnosticError] = useDocumentDataOnce(doc(db, `users/${userId}/${courseId}/diagnostic`))
  const [finalProjectDoc, finalProjectLoading, finalProjectError] = useDocument(get_user_project_submission_ref(courseId, userId))
  
  const [certificateCompletionMap, isComplete] = useCertificateCompletion(certificateFormatDoc, assnProgressDoc, sectionParticipationDoc, finalProjectDoc, diagnosticDoc, sessionInfo)

  if(assnProgressLoading || userLoading || sectionParticipationLoading || finalProjectLoading || certificateFormatLoading || isLoadingSessionInfo) return <Loading/>
  if(assnProgressError || userError) {
    console.error(assnProgressError)
    return <CertificateError msg="Can't load progress"/>
  }

  const isSelf = currId === userId

  const certificateInfo = {
    userDoc,
    diagnostic:diagnosticDoc?.hasStarted,
    id:certificateId,
    name: certificateName,
    section:sectionParticipationDoc,
    isSelf,
    finalProject: finalProjectDoc ? finalProjectDoc.exists() : false,
    certificateCompletionMap
  }



  document.title = `Certificate of Completion`


  if(!isComplete) {
    return <NoCertificatePage certificateInfo={certificateInfo}/>
  }

  return <>
  <ChangeName
    certificateId={certificateId}
    isSelf={isSelf}
  />
  <Handout element={<div 
    style={{textAlign:"center"}} 
    className="stanford-sans-font d-flex flex-column justify-content-center"
  >
    <CompletionCertificate certificateInfo={certificateInfo}/>   <div style={{height:25}}/>
  {/* <hr/> */}
  <div style={{height:25}}/>
  <Authenticity certificateId={certificateInfo.id}/>
  </div>}/>
  </>
}

const CompletionCertificate = ({certificateInfo}) => {
  const { courseStartDate, courseEndDate } = useContext(CourseContext)

  if(!certificateInfo || ! certificateInfo.certificateCompletionMap) {
    return <></>
  }


  return <div className="d-flex flex-column align-items-center">
      <h1>Certificate of Completion</h1>
      <h2>Stanford University's Code in Place </h2>
      <h4>{formatDate(courseStartDate)} to {formatDate(courseEndDate)}, {getYear(courseEndDate)}</h4>
      <img src="/stanford.png" style={{width:150}}/>
      <div style={{height:10}} />
      
      <h1 className="mb-2 blue">{certificateInfo.name}</h1>
      <p>Has completed all the requirements of Stanford University's Code in Place course. </p>
      <p className="purpleBox" style={{padding:20}}>Code in Place is an introduction to programming course that covers the material from the first half of Stanford's CS106A Programming Methodologies. The class emphasizes the fundamentals of programming including: control flow, loops and conditionals, console programs with variables, graphics, lists and dictionaries. The course is taught in the Python programming language. To complete the course students must program a significant implementation in each of the following units:</p>
      <p>
      <table style={{marginLeft:"auto", marginRight:"auto", textAlign:"left"}}>
      {
          "units" in certificateInfo.certificateCompletionMap && certificateInfo.certificateCompletionMap["units"].map((unit, index) => {
            return <CheckMark key={index} label={unit.name} correct={unit.isComplete}/>
          })
        }
       {"attendance" in certificateInfo.certificateCompletionMap ? <CheckMark label = "Section Participation" correct={certificateInfo.certificateCompletionMap["attendance"].isComplete}/>: <></>}

       </table>
      </p>
      {/* <Portfolio  certificateInfo={certificateInfo}/> */}

      <div className="mt-3">
        {
          "badges" in certificateInfo.certificateCompletionMap && certificateInfo.certificateCompletionMap["badges"].map((badge, index) => {
            return <CertificateBadge key={index} badgeInfo={badge}/>
          })
        }
      </div>
      <Signature/>
      
  </div>

}

const Signature = () => {
  return <div className="d-flex flex-column align-items-center mt-4">
    <img src={process.env.PUBLIC_URL + "/signature.png"} style={{width:250}}/>
    <div style={{height:20}} />
    <div style={{textAlign:"center"}}>
    Christopher James Piech<br/>
    Assistant Professor, Computer Science<br/>
    Stanford University
    </div>
  </div>
}


const CertificateBadge = ({badgeInfo}) => {
  const { name, description, isComplete } = badgeInfo
  if(isComplete) {
    return <div><FaCertificate className="blue"/> <b>{name}</b>: {description}</div>
  }
  return <></>
}


const ChangeName = ({certificateId, isSelf }) => {



  const courseId = useCourseId()
  const changeName = async () => {
    const newName = prompt("Enter the name you would like to appear on the certificate")
    if(newName) {
      const db = getFirestore()
      const certificatePath = `course/${courseId}/certificates/${certificateId}`
      try {
        await updateDoc(doc(db, certificatePath), {name:newName})
        // reload
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
  }

  if (!isSelf) return <></>

  return <button className="btn btn-light d-print-none"
    style={{
      position: "absolute",
      right: 0,
      top: 0
    }}
    onClick={changeName}
  >
    <FaEdit/> Change Name
  </button>
}
  

const Portfolio = ({certificateInfo}) => {
  const courseId = useCourseId()
  const portfolio = certificateInfo.userDoc.portfolio
  const name = certificateInfo.name
  if(!portfolio || portfolio.length == 0) return <></>
  return <>
  Here is a select portfolio of projects written by {name}:
  <div className="d-flex flex-column flex-wrap justify-content-center">
  {portfolio.map((project, index) => 
    <div key={index}><a href={`/${courseId}/share/${project}`}><FaLink/> Portfolio Project {index+1}</a></div>
  )}
  </div>
  
  </>
}


const Authenticity = ({certificateId}) => {
  const courseId = useCourseId()
  const url = `https://codeinplace.stanford.edu/${courseId}/certificate/${certificateId}`
  return <div className="w-100 d-flex flex-column justify-content-center align-items-center">
  <p>Verify this certificate by visiting the URL:<br/> 
    <a 
      target="_blank"
      href={url}>{url}
    </a>
  </p>
  <QRCode value={url} />
  </div>
}

const Handout = ({element}) => {
  const navHeight = 0
  const [height, setHeight] = useState('100vh');

    const handoutRef = useRef(null);

    useEffect(() => {
        const handoutHeight = handoutRef.current.offsetHeight;
        const newHeight = `max(${handoutHeight}px, 100vh - ${navHeight}px)`;
        setHeight(newHeight);
    }, [element]);
    return <div className="greyBackground pb-4" style={{ minHeight: height}}>
      
  <div className="container-fluid">
      <div className="row handoutRow">
          <div className="col handout" ref={handoutRef}>
              <div className="pb-2 mb-2">
              <button onClick={() => printPage()} className="btn btn-light" style={{marginTop:'-0px',float:'right', marginRight:'-20px'}}><FaPrint/></button>
                  {element}
              </div>
          </div>
      </div>
  </div>
</div>
}



const CertificateError = ({msg}) => {
  return <>Error Loading Certificate: {msg}</>
}

const CheckMark = ({correct, label}) => {
  const icon = correct ? 
  <FaCheckCircle size="24" className="blue" /> : 
  <FaTimesCircle size="24"  />
  return <tr>
    <td className="pr-2">{icon} </td>
    <td>{label}</td>
    
  </tr>
}



function formatDate(date) {
  // takes date written in YYYY-MM-DD format and returns Month DD
  const dateObj = new Date(date + "T00:00:00")

  // Always in English
  return dateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
}

function getYear (date) {
  const dateObj = new Date(date)
  return dateObj.getFullYear()
}


function printPage() {
  window.print();
}



import { useState, useContext } from "react";
import { FaCheckCircle, FaPaperPlane } from "react-icons/fa";
import styled from 'styled-components';
import { LessonContext } from "../LessonContext";

export const CuriosityWonder = ({data }) => {
  const title = data.lectureName
  const authors = data.authors
  const course = data.course


  const { currSlideId, lessonData, nextSlide } = useContext(LessonContext);
  
  // const lessonData = lessonsMap[currSlideId]
  const lessonLength = computeLength(lessonData)
  console.log('lessonLength', lessonLength)
  console.log('lessonData', data)
  return <SplashOuter>
    <ImageOuter>
      <BannerImg src={data.imgUrl} alt="What do you notice, what do you wonder?" />
    </ImageOuter>
    <QuizQuestion
      question={"What do you notice?"}
      response={""}
      // correctAnswer={question.answerText}
      onComplete={(response) => { }}
    />
    <QuizQuestion
      question={"What do you wonder?"}
      response={""}
      // correctAnswer={question.answerText}
      onComplete={(response) => { }}
    />
    <NextButton onClick={nextSlide} className="btn btn-primary">Next</NextButton>

  </SplashOuter>
}

const QuizQuestion = ({ question, response, onComplete }) => {
  const [userAnswer, setUserAnswer] = useState(response);

  const isAnswered = response !== "";

  const handleSubmit = () => {
    onComplete(userAnswer);
  };

  return (
    <QuizWrapper>
      <p>{question}</p>
      <FormWrapper>
        <TextArea
          rows={3}
          cols={30}
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          disabled={isAnswered}
        />
        <IconButton disabled={isAnswered} onClick={handleSubmit}>
          {isAnswered ? <FaCheckCircle /> : <FaPaperPlane />}
        </IconButton>
      </FormWrapper>
    </QuizWrapper>
  );
};

const ImageOuter = styled.div`
  width: 100%;
  height: auto; /* Adjust this to ensure the container fits content but does not exceed its max height */
  flex-grow: 1;
  flex-basis: 0; /* new */
  max-height: 600px;
  min-height: 50px; /* Ensure there's a minimum height */
  display: flex;
  align-items: center;
  justify-content: center;

`


const BannerImg = styled.img`
  max-width: 100%;
  max-height: 100%; /* This respects the container's max-height */
  object-fit: contain; /* Keeps aspect ratio without dictating container size */
`


const NextButton = styled.button`
  margin-top: 20px;
  margin-bottom: 20px;
`

const BannerText = styled.div`
  margin-top: 20px;
  border: 3px solid black;
  padding: 40px;
  min-width: 600px;
  margin-bottom: 40px;
  border-radius: 10px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    min-width: 100px;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-height: 800px) {
    padding: 20px;
  }
`


const SplashOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


function computeLength(data) {
  // for slide in data.slides
  let totalMins = 0
  for (var i = 0; i < data.slides.length; i++) {
    const slide = data.slides[i]
    const slideData = slide?.data
    const slideLength = slideData?.lengthMins
    if (slideLength) {
      // parse string
      totalMins += parseInt(slideLength)
    }
  }

  return totalMins
}

const ModuleQuestionHeader = styled.div`
  font-size: 1.5rem;
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: left;

`;


const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fdfdfd;
  resize: none;
`;



const SubmitButton = styled.button`
  margin-left: 523px;
  margin-bottom: 50px;
`;



const QuizWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: none;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 24px;
  margin-top: 12px;
  color: ${props => (props.disabled ? "#11a952" : "#0d6efd")};
  transition: color 0.3s ease;

  &:hover {
    color: ${props => (props.disabled ? "#ccc" : "#555")};
  }
`;
import { 
    TextInput, 
    TextAreaInput, 
    DescriptionField,
    CheckboxBoolean,
    LikertScale,
    Dropdown,
    MultiCheckbox,
    MultipleChoice,
} from "./FormixComponents";
import { getShuffledIndices, updateQIndices } from "./CourseSurvey";

const COMPONENTS = {
    text: TextInput,
    textarea: TextAreaInput,
    description: DescriptionField,
    checkbox: CheckboxBoolean,
    likert: LikertScale,
    dropdown: Dropdown,
    multicheckbox: MultiCheckbox,
    multiplechoice: MultipleChoice
};

// This component creates a component based on inputType, which corresponds to a component in the FormixComponents file (see COMPONENTS above for supported types)
// It passes on all provided props to the component (e.g. label, name, placeholder, etc.)
// we return the component as a function that takes a name prop and returns the InputComponent with the otherProps because that is what is required of formFormat
// (see FormixFormWithData)
export const SurveyComponent = (question, courseId, surveyId, userId) => {
    const {inputType, ...otherProps} = question;
    const {randomizeChildren, children} = otherProps;

    const InputComponent = COMPONENTS[inputType];
    if (InputComponent) {
        if (randomizeChildren && children){
            // randomly shuffle order of children
            // we reuse the logic for shuffling the question order here!
            // child indices starts off as a list of the indices of the children, e.g. [0,1,2,3,4]
            let childIndices = Array.from(Array(children.length).keys());
            // get array of new indices for questions (after shuffle)
            let shuffledIndices = getShuffledIndices(childIndices, courseId, surveyId, userId);

            // update childIndices so original childIndices are mapped to shuffledIndices
            // basically, childIndices is now the list of the order in which we want the questions to be shown
            // here, we use childIndices as both the equivalent of questionIndices (set of all options)
            // and of indicesToShuffle (set of options to shuffle) since we are shuffling all the children
            childIndices = updateQIndices(childIndices, childIndices, shuffledIndices);

            let updatedChildren = children 
            // reorder questions if randomShuffle was specified
            updatedChildren = childIndices.map(idx => children[idx]);
            return (({name}) => <InputComponent {...otherProps} children={updatedChildren}/>)
        }
        return (({name}) => <InputComponent {...otherProps}/>)
    }
}
import { useForumDataFetcher } from "course/forum/firebase/ForumDataFetcher"
import { useEffect, useState } from "react"
import { transferPostToDraft, validatePostUpdate } from "./utils"
import { getDoc, updateDoc } from "firebase/firestore"
import { DraftPost } from "course/forum/firebase/ForumDataModels"
import { useDebounce } from "use-debounce"




export const useDraftData = ({draftId, isReporting}) => {
    const [postTitle, setPostTitle] = useState('')
    const [postAuthor, setPostAuthor ] = useState('')
    const [postAuthorId, setPostAuthorId] = useState('')
    const [postTime, setPostTime] = useState(null)
    const [postText, setPostText] = useState('')
    const [postContents, setPostContents] = useState({
      text: '',
      html: ''
    })
    const [isPostPrivate, setIsPostPrivate] = useState(isReporting ? true : false)
    const [postTags, setPostTags] = useState([])
    const [isPostResolved, setIsPostResolved] = useState(false)
    const [isPostPinned, setIsPostPinned] = useState(false)
    const [isPostPosted, setIsPostPosted] = useState(false)
    const [isPostAnonymous, setIsPostAnonymous] = useState(false)
    const dataFetcher = useForumDataFetcher();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [postNotFound, setPostNotFound] = useState(false);
    const [dataIsFetched, setDataIsFetched] = useState(false);
    const [draftData, setDraftData] = useState(null);
    const [draftExists, setDraftExists] = useState(false);
    const [debouncedTitle] = useDebounce(postTitle, 250);
    const [debouncedContent] = useDebounce(postContents, 250);



    const draftQuery = dataFetcher.get_draft_doc_ref(draftId);

    // Need to initially pull the draft data from the database
    // Once we pull, we update that it has been fetched
    useEffect(() => {
      const getDraftData = async () => {
        const draftData = await getDoc(draftQuery)
        setDraftExists(draftData.exists())
        setDraftData(draftData.data())
        setDataIsFetched(true)
      }
      getDraftData()
    }, [])


    useEffect(() => {
      setPostText(postContents.text)
    }, [postContents])



    useEffect(() => {

      const setDraftData = (draftData) => {
        const { title, tags, contents, isPosted, isPinned, isPrivate } = draftData;
        setPostTitle(title);
        setPostTags(!!(tags) ? tags : []);
        setPostContents(contents);
        if(!isReporting) setIsPostPrivate(!!(isPrivate))
        setIsPostPinned(!!(isPinned));
        setIsPostPosted(!!(isPosted));
        setDataLoaded(dataIsFetched)

      }
        // When draft data is fetched
        if (dataIsFetched) {
          if (draftExists) {
            setDraftData(draftData)
          } else if(!draftExists) {
            transferPostToDraft(draftId, dataFetcher).then((resp) => {
              if(resp) {
                setDraftData(resp)
              } else {
                setPostNotFound(true)
              }
            })
          }
        } 
    }, [dataIsFetched]);


    useEffect(() => {
      if(!dataLoaded) return;
      updateDraftPost({
        postChange: { title: debouncedTitle },
        reloadPostList: false,
        stopEditing: false,
        validateContentsLength: false,
      });

    }, [debouncedTitle])

    useEffect(() => {
      if(!dataLoaded) return;
      updateDraftPost({
        postChange: { contents: debouncedContent },
        reloadPostList: false,
        stopEditing: false,
        validateContentsLength: true,
      });

    }, [debouncedContent])


    useEffect(() => {
      if(!dataLoaded) return;
      updateDraftPost({
        postChange: { isPrivate: isPostPrivate, isPinned: isPostPinned, isAnonymous: isPostAnonymous, tags: postTags},
        reloadPostList: false,
        stopEditing: true,
        validateContentsLength: false,
      });
    }, [isPostPinned, isPostPrivate, isPostAnonymous, postTags])


      /**
   * Mutator to update a post. The mutation function accepts
   * a partial post (some subset of a post's fields), updates
   * the post, and globally updates the post in the react-query
   * cache.
   */
  const updateDraftPost = async (data: {
    postChange: Partial<DraftPost>;
    reloadPostList?: boolean;
    stopEditing?: boolean;
    validateContentsLength?: boolean;
  }) => {
    // @TJ-forum this is an issue
    const { postChange, validateContentsLength } = data;

    const validated = validatePostUpdate(postChange, postText, validateContentsLength);
    if (!validated) return;

    await updateDoc(dataFetcher.get_draft_doc_ref(draftId), postChange);
  };


  // This gets run when a user clicks the publish button
  const onPublishUpdateMetadata = (fromEdit: boolean) => {
    if(fromEdit) {
      updateDraftPost({
        postChange: { title: postTitle },
        reloadPostList: false,
        stopEditing: true,
        validateContentsLength: false,
      });
    } else {
      updateDraftPost({
        postChange: {
          title: postTitle,
          time: postTime || new Date(Date.now()),
          isPrivate: isPostPrivate,
          isPinned: isPostPinned,
        },
        reloadPostList: true,
        stopEditing: true,
        validateContentsLength: false,
      });
    }
  }





  return {
    postTitle,
    setPostTitle,
    postAuthor,
    setPostAuthor,
    postAuthorId,
    setPostAuthorId,
    postTime,
    setPostTime,
    postText,
    isPostPrivate,
    setIsPostPinned,
    isPostAnonymous,
    setIsPostAnonymous,
    postTags,
    setPostTags,
    isPostResolved,
    setIsPostResolved,
    isPostPinned,
    setIsPostPosted,
    isPostPosted,
    dataLoaded,
    postNotFound,
    setPostContents,
    onPublishUpdateMetadata,
    draftQuery,
    setIsPostPrivate,
    dataFetcher
  }





}

import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  arrayUnion,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { uuidv4 } from "lib0/random";
import { selectScenario, selectStudents } from "./getStaticData";
import { Constants } from "./constants";

// Constants
const GPTEACH_ROOT = "teacher-training-module-GPteach";
const DATA_RECORDING = "cip-data-recording";
const db = getFirestore();

// Generate new sessionID, choose the students
export async function setUpNewParticipant(cipUserData) {
  const sessionID = uuidv4();
  // console.log("GPTeach sessionID: " + sessionID);

  const students = await selectStudents(Constants.NUM_STUDENTS);
  const scenario = await selectScenario();

  return { uuid: cipUserData.id, students, scenario, sessionID };
}

export function saveMessageToFirebase(Participant, message) {
  setDoc(
    doc(
      db,
      GPTEACH_ROOT,
      DATA_RECORDING,
      Participant.uuid,
      Participant.sessionID
    ),
    {
      messages: arrayUnion(message),
    },
    { merge: true }
  );
}

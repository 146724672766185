const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
	apiKey: "",
});
const openai = new OpenAIApi(configuration);

export async function callGPT3(myPrompt, onResponse) {
	console.log("Prompt is: \n\n" + myPrompt);

	openai
		.createCompletion({
			model: "text-davinci-003",
			prompt: myPrompt,
			temperature: 0.7,
			max_tokens: 256,
			top_p: 1,
			frequency_penalty: 0,
			presence_penalty: 0,
			stop: ["TA: "],
		})
		.then(response => {
			if (response.data.choices[0].text.trim().replace(/[\n]/gm, "") === "") {
				console.log("didn't get a response back...");
				onResponse("hmmm");
			} else {
				onResponse(response.data.choices[0].text);
			}
		});
}

// need to have some logic for the case where we don't get any response back...
// we need to just send the prompt again?

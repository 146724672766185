import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const manualCreateTeachNowRoomAndNotify = httpsCallable(functions, "manualCreateTeachNowRoomAndNotify");

export const AdminNudgeButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    slId: "BkxKgaz6v3hir1QJ5bPdBW0DaYj1",
    studentId: "34yo8FSi2AbsT5tEst9FbOBHxxH3",
    projectId: "YJUEWOjlRsXMxjX4yzYE",
  });

  const handleClick = () => {
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    manualCreateTeachNowRoomAndNotify({
      slId: formData.slId,
      studentId: formData.studentId,
      projId: formData.projectId,
      roomId: "room_where_it_happens",
      zoomId: "Csfc5AdnSJCB89SQo9hU9w",
      expiryDurationMin: 60
    });
    setShowModal(false);
  };

  return (
    <div>
      <Button variant="success" onClick={handleClick}>Send Test Match</Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Test Match Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>This will send a test match notification to a user and then you both can join a real session with a zoom to test out different features.</p>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>SL ID:</Form.Label>
              <Form.Control type="text" name="slId" value={formData.slId} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Student ID:</Form.Label>
              <Form.Control type="text" name="studentId" value={formData.studentId} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Project ID:</Form.Label>
              <Form.Control type="text" name="projectId" value={formData.projectId} onChange={handleChange} />
            </Form.Group>
            <Button variant="success" type="submit">Send Match</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
import COUNTRIES from "data/countries/country_names"
import { Typeahead } from "react-bootstrap-typeahead"

export const CountryInput = ({name, values, touched, errors, setFieldValue, setFieldTouched}) => {
  
  // value for typeahead must be a list


  const currCode = values.country ? [values.country] : []
  let error = errors.country ? errors.country : null
  const isTouched = touched.country ? touched.country : false
  if(error && !(typeof error === 'string' || error instanceof String)) {
    error = error.eng_name
  }
  
  const onChange = (e) => {
    if(e.length === 0) {
      setFieldValue("country", null)
      return
    }
    const newCountry = e[0]
    setFieldValue("country", newCountry.code)
    // console.log('here')
    // // returns a list of countries
    // const newValue = e[0]
    // // if its an undefined country, dont save it
    // if(!newValue) {
    //   return
    // }
    // setFieldValue("country", newValue.code) 
  }

  const setCountryFromText = (text) => {
    setFieldTouched("country", true)
    const country = COUNTRIES.find((country) => country.eng_name === text)
    if(country) {
      setFieldValue("country", country.code)
    } else {
      if(currCode) {
        setFieldValue("country", null)
      }
    }
  }

  const onInputChange = (text) => {
    setCountryFromText(text)
  }

  const onBlur = (e) => {
    const text = e.target.value
    setCountryFromText(text)
  }
    
  const countryKey = "eng_name"

  // get the english name 
  const currCountry = COUNTRIES.find((country) => country.code === values.country)
  let defaultSelected = []
  if(currCountry) {
    defaultSelected = [currCountry]
  } 
  
  return <div className="question-spacing">
    <label className="form-label">
      Country
    </label>
    <div className="description">Students in Code in Place come from over 150 different countries.</div>
    <div>
    <Typeahead
      id="country-input"
      className="about-text-input p-0"
      labelKey={countryKey}
      onChange={onChange}
      options={COUNTRIES}
      placeholder="Somewhere on Earth"
      defaultSelected={defaultSelected}
      onInputChange={(text) => onInputChange(text)}
      onBlur={onBlur}
    />
    </div>
    {isTouched && error && <div className="error">{error}</div>}
  </div> 

}
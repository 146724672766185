import { useUserId } from "hooks/user/useUserId"
import { RunnableTeacherPuzzle } from "./RunnableTeacherPuzzles"
import styled from "styled-components"
import { useCourseId } from "hooks/router/useUrlParams"
import { useEffect, useState } from "react"
import { handleStudentPuzzleSubmission, logPuzzleStartTime, useStudentPuzzleDataDoc, useStudentPuzzles } from "./TNFirebase"
import { Loading } from "components/loading/Loading"
import { Button, Spinner } from "react-bootstrap"
import { FaSeedling } from "react-icons/fa"

const STUDENT_PUZZLE_TYPE = "studentTeacherPuzzles"

const TeacherPuzzlesOuter = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 10px);
  height: 100vh;
  margin: 0px 5px;
`

const RightPane= styled.div`
  height:100%;
  width:800px;
  border-left: 2px solid lightgray;
  margin-bottom: 100px;
  overflow-y: scroll;
  `

export const StudentTeacherPuzzles = ({nextSlide}) => {
    const [currentPuzzle, setCurrentPuzzle] = useState(null)
    const [currentPuzzleIndex, setCurrentPuzzleIndex] = useState(0)
    const [puzzleLogDocId, setPuzzleLogDocId] = useState(null);
    const [isLoadingNextPuzzle, setIsLoadingNextPuzzle] = useState(false)
    const userId = useUserId()
    const courseId = useCourseId()

    const [puzzles, puzzlesLoading, puzzleLogDocError] = useStudentPuzzles()
    const [userPuzzleDoc, userPuzzlesDocLoading, userPuzzlesDocError] = useStudentPuzzleDataDoc()

    const [doneAfterNext, setDoneAfterNext] = useState(false)


    useEffect(() => {
        if(userPuzzlesDocLoading) return;
        if(!userPuzzleDoc.exists()) return;
        const data = userPuzzleDoc.data();
        const seenPuzzles = data.seenPuzzles ? data.seenPuzzles : [];
        const currentPuzzleIndex = seenPuzzles.length;
        setCurrentPuzzleIndex(currentPuzzleIndex)

    }, [userPuzzlesDocLoading])


    useEffect(() => {
        async function logStartTime() {
            const docId = await logPuzzleStartTime(userId, courseId, currentPuzzleIndex, 'students');
            setPuzzleLogDocId(docId);
        }

        if (currentPuzzleIndex !== null) {
            logStartTime();
        }
    }, [currentPuzzleIndex]);


    useEffect(() => {
      if(puzzlesLoading) {
        return
      }
      setIsLoadingNextPuzzle(true)

      // If we reach the end of the puzzles, we are done
      const puzzlesLen = Object.keys(puzzles).length
      if (currentPuzzleIndex >= puzzlesLen) {
        const currentPuzzle = puzzles[puzzlesLen - 1]
        setCurrentPuzzle((_) => currentPuzzle)
        setIsLoadingNextPuzzle(false)
        return
      }
      if (currentPuzzleIndex >= puzzlesLen - 1) {
        setDoneAfterNext(true)
      }
      const currentPuzzle = puzzles[currentPuzzleIndex]
      setCurrentPuzzle((_) => currentPuzzle)
      setIsLoadingNextPuzzle(false)


    }, [currentPuzzleIndex, puzzlesLoading])

    async function moveToNextPuzzle() {

        setIsLoadingNextPuzzle(true)
        // increase currPuzzle index by 1
        setCurrentPuzzleIndex((prevIndex) => prevIndex + 1)

        setIsLoadingNextPuzzle(false)
    }

    if(puzzlesLoading || userPuzzlesDocLoading || isLoadingNextPuzzle || currentPuzzle === null) {
        return <Loading />
    }

    const puzzlesLen = Object.keys(puzzles).length
    if (currentPuzzleIndex >= puzzlesLen) {
        return (
            <ContinueButton
                doneAfterNext={true}
                nextSlide={nextSlide}
                moveToNextPuzzle={() => {}}
            />
        )
    }

    const starterCode = currentPuzzle.code
    const data = {
        'starterCode': starterCode,
        'type': "consolerunnable",
    }
    const ourAnswer = currentPuzzle['our_answer']
    const ourNoticings = currentPuzzle['our_noticings']

    return (
        <TeacherPuzzlesOuter>
      <RunnableTeacherPuzzle data={data}/>
        <RightPane>
          <UserResponsePane
            ourAnswer={ourAnswer}
            ourNoticings={ourNoticings}
            moveToNextPuzzle={moveToNextPuzzle}
            puzzleLogDocId={puzzleLogDocId}
            nextSlide={nextSlide}
            doneAfterNext={doneAfterNext}
            puzzleIndex={currentPuzzleIndex}
            userId={userId}
            courseId={courseId}
            puzzlesLen={puzzlesLen}
          />
        </RightPane>
    </TeacherPuzzlesOuter>
    )
}


const ContinueButton = ({ doneAfterNext, nextSlide, moveToNextPuzzle }) => {
    if (doneAfterNext) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <hr/>
            <p>Great work! You have completed all the teaching practice in this module!</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', gap: '10px'}}>
            <Button onClick={() => nextSlide()}>Continue with Training</Button>
            </div>
          </div>
        )
    } else {
        return (
            <Button onClick={moveToNextPuzzle}>
                Next Puzzle
            </Button>
        )
    }
}

const UserResponsePane = ({ ourAnswer, ourNoticings, nextSlide, moveToNextPuzzle, puzzleLogDocId, doneAfterNext, puzzleIndex, userId, courseId, puzzlesLen }) => {
    const [slResponseToStudent, setSlResponseToStudent] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showOurAnswer, setShowOurAnswer] = useState(false)
    const [studentNoticing, setStudentNoticing] = useState('')



    const handleSubmit = async (event) => {
        event.preventDefault();  // Prevent the form from causing a page reload
        setIsSubmitting(true);
        await handleStudentPuzzleSubmission(userId, courseId, "student", puzzleIndex, puzzleLogDocId, slResponseToStudent, studentNoticing)
        setShowOurAnswer(true)
        setIsSubmitting(false);
    }

    const resetPuzzle = () => {
        setSlResponseToStudent('')
        setStudentNoticing('')
        setShowOurAnswer(false)
        moveToNextPuzzle()
    }

    if (!showOurAnswer) {
        return (
            <div style={{ overflowY: 'scroll', paddingLeft: '10px', paddingRight: '10px', marginBottom: '100px', minHeight: 'fit-content' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Practice: Guiding a Student</h4>
                    <div style={{ fontSize: '18px', marginTop: '5px' }}>
                        <p><FaSeedling style={{ color: 'green', marginBottom: '5px' }} /> {puzzleIndex + 1} / {puzzlesLen}</p>
                    </div>
                </div>
                <p>(1) Look over the code and note any issues or misunderstandings you observe. This can include bugs or misconceptions the student might have.</p>
                <form>
                    <textarea
                        style={{ width: '100%', height: '100px', fontSize: '16px' }}
                        placeholder="Write down the things you notice that are wrong here..."
                        value={studentNoticing}
                        onChange={(e) => setStudentNoticing(e.target.value)}
                    ></textarea>
                    <p>(2) Give this student advice on what they should work on next in this problem. For example, you could guide them towards finding a bug, clarify a misunderstanding, or offer encouragement. Remember to be welcoming and respectful when writing your response.</p>
                    <textarea
                        style={{ width: '100%', height: '100px', fontSize: '16px' }}
                        placeholder="Type your response to the student here..."
                        value={slResponseToStudent}
                        onChange={(e) => setSlResponseToStudent(e.target.value)}
                    ></textarea>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button onClick={handleSubmit} disabled={isSubmitting || slResponseToStudent === '' || studentNoticing === ''}>
                            {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Submit Response'}
                        </Button>
                    </div>
                </form>
            </div>
        )
    } else if (showOurAnswer) {
        // Show their answer in a non-editable textarea and then below it will say "here is what we would say!" and then a block quote of our answer
        return (
            <div style={{ overflowY: 'scroll', paddingLeft: '10px', paddingRight: '10px', marginBottom: '100px', minHeight: 'fit-content' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Practice: Guiding a Student</h4>
                    <div style={{ fontSize: '18px', marginTop: '5px' }}>
                        <p><FaSeedling style={{ color: 'green', marginBottom: '5px' }} /> {puzzleIndex + 1} / {puzzlesLen}</p>
                    </div>
                </div>
                
                <p>Here is what you noticed:</p>
                <div style={{
                    backgroundColor: '#e0f7fa',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    fontStyle: 'italic',
                    textAlign: 'left',
                    overflowY: 'auto',
                    marginBottom: '10px',
                    textWrap: 'wrap',
                    wordWrap: 'break-word',
                    maxHeight: '80px',

                }}>
                    {studentNoticing}
                </div>
                
                <p>Here is what you advised:</p>
                <div style={{
                    backgroundColor: '#e0f7fa',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    fontStyle: 'italic',
                    textAlign: 'left',
                    overflowY: 'auto',
                    marginBottom: '10px',
                    textWrap: 'wrap',
                    wordWrap: 'break-word',
                    maxHeight: '80px',

                }}>
                    {slResponseToStudent}
                </div>
                
                <p>Here is what we noticed:</p>
                <div style={{
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    fontStyle: 'italic',
                    textAlign: 'left',
                    overflowY: 'auto',
                    marginBottom: '10px',
                    textWrap: 'wrap',
                    wordWrap: 'break-word',
                    maxHeight: '80px',

                }}>
                    {ourNoticings}
                </div>
                
                <p>Here is what we would say:</p>
                <div style={{
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    fontStyle: 'italic',
                    textAlign: 'left',
                    overflowY: 'auto',
                    marginBottom: '10px',
                    textWrap: 'wrap',
                    wordWrap: 'break-word',
                    maxHeight: '80px',
                }}>
                    {ourAnswer}
                </div>
                
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <ContinueButton
                        doneAfterNext={doneAfterNext}
                        nextSlide={nextSlide}
                        moveToNextPuzzle={resetPuzzle}
                    />
                </div>
            </div>
        )               
    }
}


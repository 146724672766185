import { FaRemoveFormat } from "react-icons/fa";
import { useIsMobile } from "utils/general";

export const ClearFiltersButton = (props) => {
    const {text, icon, onClick, ...restProps} = props;
    const mobile = useIsMobile();
    // Accessibility enhancement: Use aria-label to provide a clear label for screen readers.
    const ariaLabel = restProps['aria-label'] || text; // Use provided aria-label or default to text prop

    return (
        <>
        <button 
            style={{
                width: "max-content"
            }}
            onClick={onClick} 
            className="btn btn-outline-secondary"
            {...restProps}
            aria-label={ariaLabel} // Add aria-label for accessibility
            >
            {icon} {!mobile && text}
        </button>
        </>
    );
}

ClearFiltersButton.defaultProps = {
    text: "Clear Filters", 
    icon: <FaRemoveFormat />, 
    onClick: () => console.log("Clear Filters button clicked!"), 
} 
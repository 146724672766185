import { ProfileProvider, ProfileContext } from "contexts/ProfileContext";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { GenericBrandedWrapper } from "./Login";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Loading } from "components/loading/Loading";

export const Unsubscribe = () => {

  return (
    <ProfileProvider>
      <GenericBrandedWrapper>
        <UnsubscribeInner />
      </GenericBrandedWrapper>
    </ProfileProvider>
  );

}



const UnsubscribeInner = () => {
  const { loading, userData } = useContext(ProfileContext)
  const [okToEmail, setOkToEmail] = useState(true)
  const [okToSendNotifications, setOkToSendNotifications] = useState(true);
  const [okToSendSectionReminders, setOkToSendSectionReminders] = useState(true);
  const db = getFirestore();


  useEffect(() => {
    if (!loading) {
      setOkToEmail(userData.okToEmail)
      setOkToSendNotifications(userData.okToSendNotifications)
    }
  }, [loading])


  const updateUserEmailPreference = async () => {
    const isOk = !okToEmail;
    const auth = getAuth();
    if (!auth.currentUser) {
      return
    }

    const userDoc = doc(db, "users", auth.currentUser.uid)

    await updateDoc(userDoc, {
      okToEmail: isOk
    })
    setOkToEmail(isOk)
  }

  const updateUserNotificationPreference = async () => {
    const isOk = !okToSendNotifications;
    const auth = getAuth();
    if (!auth.currentUser) {
      return
    }

    const userDoc = doc(db, "users", auth.currentUser.uid)

    await updateDoc(userDoc, {
      okToSendNotifications: isOk
    })
    setOkToSendNotifications(isOk)
  }

  const updateUserSectionReminderPreference = async () => {
    const isOk = !okToSendSectionReminders;
    const auth = getAuth();
    if (!auth.currentUser) {
      return
    }

    const userDoc = doc(db, "users", auth.currentUser.uid)

    await updateDoc(userDoc, {
      okToSendSectionReminders: isOk
    })
    setOkToSendSectionReminders(isOk)
  }




  if (loading) {
    return <Loading />
  }

  return (
    <div className="d-flex flex-column align-items-center text-center">
      <h3>Unsubscribe</h3>
      <hr />
      <p>Send me important emails:{" "}
        <b className={okToEmail ? "text-success" : "text-danger"}>{okToEmail ? "Yes" : "No"} </b>
        <br />
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            aria-label="Mark this switch if it is OK for Code in Place to send you emails"
            checked={okToEmail}
            onChange={updateUserEmailPreference}
          />
        </Form>
      </p>
      <hr />
      <p>Send me forum response emails: {" "}
        <b className={okToSendNotifications ? "text-success" : "text-danger"}>{okToSendNotifications ? "Yes" : "No"}</b>
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            aria-label="Mark this switch if it is OK for Code in Place to send you notifications"
            checked={okToSendNotifications}
            onChange={updateUserNotificationPreference}
          />
        </Form>
      </p>
      <p>Send me section reminders: {" "}
        <b className={okToSendNotifications ? "text-success" : "text-danger"}>{okToSendSectionReminders ? "Yes" : "No"}</b>
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            aria-label="Mark this switch if it is OK for Code in Place to send you notifications"
            checked={okToSendNotifications}
            onChange={updateUserNotificationPreference}
          />
        </Form>
      </p>
    </div>
  )

}
import { primaryPinkDark } from "styles/styleConstants"


const iconPurple = '#c490d8'
const tile = '#b87963'
const popyLight = '#F9A44A'
const lightTile = '#F4795B'
const stanfordBlue = '#006CB8'
const cardinalRef ='#8C1515'
const spirited = '#E04F39'
const darkPoppy =  '#D1660F'
const poppy = '#E98300'
const youtubeplay = '#e83323'

// export const iconIncompleteColor = 'salmon'
export const iconIncompleteColor = '#56b4f8'
export const iconCompleteColor = "#7AC142"
export const todoCircleDiameter = 50
export const todoConnectorWidth = 5
export const iconActiveBackgroundColor = youtubeplay


import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useComponentSize, useWindowSize } from 'react-use-size';

export const CoursePageBodyContainer = (props) => {

  const windowSize = useWindowSize();

  const tooNarrow = windowSize.width < 1075

  if(tooNarrow) return <div style={{paddingLeft:'10px',paddingRight:'10px'}}>{props.singleColumn}</div>
  

  return (
      <div className="splashContainer" id="splash-container-broad">
        <div className='splashMainColContainer'>
          <div className='splashMainCol'>
            {props.mainColumn}
          </div>
        </div>
        <RightCol {...props}/>
        </div>
  );
};

const RightCol = (props) => {
  const size = useComponentSize()

  return <div  style={calcRightColStyle(size)} className='splashRightCol'>
    <div className='col' ref={size.ref}>
        {props.rightColumn}
      </div>
  </div>
}

const calcRightColStyle = (size) => {
  return {
    height: size.height,
    position:'sticky',
    top: `min(10px, 100% - ${size.height}px)`,
    paddingRight:'8px'
  }
}
import styled from "styled-components";
import { useHistoryNavigate } from "./useHistoryNavigate";

export const HistoryLink = ({ to, children, ...props }) => {
  const navigate = useHistoryNavigate();
  return (
    <StyledLink
      href={to}
      onClick={(e) => {
        e.preventDefault();
        navigate(to);
      }}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  cursor: pointer;
  color: rgb(51, 122, 183);
  `
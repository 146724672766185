import React, { useContext, useState } from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { LandingNav } from "landing/components/LandingNav";
import { getAuth } from "firebase/auth";
import { getApp } from "firebase/app";


import { Card } from "react-bootstrap";
import { FormixFormWithData } from "components/Forms/FormixFormWithData";
import { slAppAboutStructure } from "./AboutForm";
import { doc, getFirestore } from "firebase/firestore";
import { ErrorPage } from "components/errors/ErrorPage";
import { safeInject } from "course/application/studentApplication/about";
import { ProfileContext } from "contexts/ProfileContext";





export const AboutSLPage = () => {

  return <div>
    <LandingNav applicationType={"sectionLeader"} subpage={"About You"}/>
    <div
      style={outerStyle}
    >
      <AboutSLInner/>
    </div>
  </div>
}

const AboutSLInner = () => {

  const {targetCourseId} = useParams()
  const navigate = useNavigate();
  const auth = getAuth(getApp());
  const [user] = useAuthState(auth);

  const {userProtectedData, loading} = useContext(ProfileContext)

  
  
 


  // useDocumentDataOnce says "get the document at this path once (dont update it if server data updates)"
  // serverData = the current document from the database (undefined if still loading)
  const firebasePath = `users/${user.uid}/${targetCourseId}/sectionLeaderApplication`
  const db = getFirestore();
  const docRef = doc(db, firebasePath);
  const [serverData, appDataLoading, error] = useDocumentDataOnce(docRef);

  if (loading || appDataLoading) {
    return <></>;
  }

  if(error){
    return <ErrorPage msg={'Error loading data from server. Please refresh the page.'}/>
  }

  let aboutData =
    serverData !== undefined ? serverData : {};

  // inject dob if it is know
  safeInject(aboutData, userProtectedData, 'dateOfBirth')
  safeInject(aboutData, userProtectedData, 'gender')

  
  const setAboutData = function (newFields) {
    
    firebase.firestore().doc(firebasePath).set({ ...newFields },{ merge: true })
      .then(() => {
        Swal.fire({
          title:"Success",
          heightAuto: false,
          text:"Your form is filled out!",
          icon:"success",
          preConfirm:() => {
            navigate(`/public/applyteach/${targetCourseId}`)
          }
        })
      });
  };

  return (
      <AboutWithData aboutData={aboutData} setAboutData={setAboutData} />
  );
};

const AboutWithData = ({ aboutData, setAboutData }) => {
  const {targetCourseId} = useParams()
  const backUrl = `/public/applyteach/${targetCourseId}`

    return (
      <>
  
        
          <Card style={{ margin: "auto", padding: "20px" }}>
            <h1>Tell us about yourself</h1>
            <p>
              We want to get to know you better; tell us a little about
              yourself! We will use the answers in this form to figure out your
              background and determine whether this class is a good fit for you.
            </p>
            <div>
            <FormixFormWithData
                serverData={aboutData}
                backUrl={backUrl}
                formFormat={slAppAboutStructure}
                onSubmit={(e, cb) => setAboutData(e)}
              />
            </div>
          </Card>
      </>
    );
  }



const outerStyle = {
    width: "720px",
    maxWidth: "90vw",
    margin: "auto",
    marginTop: "50px",
    marginBottom: "50px",
}